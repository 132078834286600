import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { AthlinksMedal } from '../../../shared/icons'

const mapStateToProps = () => ({
  dispatch: undefined
})

const UnclaimEntrySuccessComponent = (
  props
) => {
  const { t } = props
  return (
    <div style={style.medalContainer}>
    <div style={style.medal}>
      <AthlinksMedal />
    </div>
    <div style={style.modalTitle}>
      {t('Entry unclaimed!')}
    </div>
    <div style={{ ...style.modalSubtitle, ...style.subMessage }}>
      {t(`This entry and any results associated with it are no longer a part of your profile.`)}
    </div>
  </div>
  )
}

export const UnclaimEntrySuccess = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnclaimEntrySuccessComponent)

const UnclaimEntrySuccessFooterComponent = (
  props
) => {
    const {
      dispatch,
      isMobile,
      t
    } = props

    const footerStyle = isMobile ? style.footerMobile : {}

    return (
      <div style={footerStyle}>
        <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
          <div style={style.doneButtonText}>
            {t('Done')}
          </div>
        </button>
      </div>
    )
}
export const UnclaimEntrySuccessFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnclaimEntrySuccessFooterComponent);