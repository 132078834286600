import React from 'react';
import PropTypes from 'prop-types';

import {
  isLoggedIn,
  getClaim
} from '../../utils/isLoggedIn';
import {
  setEventSearchFilterValues,
  setProfileFilterValues
} from '../../data/SearchFilters';
import {getInitialTypes} from '../../data/SearchFilters';
import { setTermValue } from '../../data/SearchTerm';
import { styles } from './styles';

const minDate = () => {
  const date = new Date();
  const year =  date.getUTCFullYear();
  const day =  date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  return year + '-' + month + '-' + day;
};

const maxDate = () => {
  const date = new Date();
  const year =  date.getUTCFullYear() + 1;
  const day =  date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  return year + '-' + month + '-' + day;
};

export const eventSearchClick = (ev, t, history) => {
  const filterValuesEvent = {
    dateRangeFilter: {
      enabled: false,
        value: {from: minDate(), to: maxDate()}
    },
    locationFilter: {
      enabled: false,
        value: {location: '',range: 50}
    },
    typeFilter: getInitialTypes(false, t)
  };

  setEventSearchFilterValues(filterValuesEvent, {isDefault: true});
  history.push('/search/events');
};

export const athleteSearchClick = (ev, t, history) => {
  const filterValuesAthletes = {
    ageFilter: {
      enabled: false,
      value: [5, 99]
    },
    genderFilter: {
      enabled: false,
      value: ''
    },
    locationFilter: {
      enabled: false,
      value: {location: '', range: 50}
    },
    typeFilter: getInitialTypes(false, t)
  };

  setProfileFilterValues(filterValuesAthletes, {isDefault: true});
  history.push('/search/athletes');
};

export const unclaimedSearchClick = (history) => {
  if (isLoggedIn()) {
    const keycloak = window.kc || {};
    setTermValue(getClaim('name', keycloak.idToken || ''));
  }
  history.push('/search/unclaimed');
};

export const QuickLinks = ({notFoundPage, t}) => (
  <div className="quick-links">
    { !notFoundPage &&
    <div>
      <div style={styles.quickLinks.titleRow}>
        {t('QUICK LINKS')}
      </div>
      {/*<div style={styles.quickLinks.linkContainer}>*/}
        {/*<Link*/}
          {/*id='event-quick-link'*/}
          {/*className='quick-link'*/}
          {/*style={styles.quickLinks.link}*/}
          {/*onClick={(ev) => eventSearchClick(ev, t)}>*/}
            {/*{t('Events')}*/}
        {/*</Link>*/}
        {/*<Link*/}
          {/*id='athlete-quick-link'*/}
          {/*className='quick-link'*/}
          {/*style={styles.quickLinks.link}*/}
          {/*onClick={(ev) => athleteSearchClick(ev, t)}>*/}
            {/*{t('Athletes')}*/}
        {/*</Link>*/}
        {/*<Link*/}
          {/*id='unclaimed-quick-link'*/}
          {/*className='quick-link'*/}
          {/*style={styles.quickLinks.link}*/}
          {/*onClick={unclaimedSearchClick}>*/}
            {/*{t('Unclaimed Results')}*/}
        {/*</Link>*/}
      {/*</div>*/}
    </div>
    }
    {
      notFoundPage &&
      <div>
        {/*<div style={styles.quickLinks.linkContainer} >*/}
          {/*<Link id='home-not-found' to={ isLoggedIn() ? `/athletes/${getTokenRacerId()}` : '/'} style={styles.quickLinks.link404}>*/}
            {/*{ isLoggedIn() ? t('My Profile') : t('Home') }*/}
          {/*</Link>*/}
          {/*{isLoggedIn() &&*/}
            {/*<Link id='results-not-found' className='quick-link' onClick={unclaimedSearchClick} style={styles.quickLinks.link404}>*/}
              {/*{t('Results')}*/}
            {/*</Link>*/}
          {/*}*/}
          {/*<Link id='profile-not-found' className='quick-link' onClick={(ev) => athleteSearchClick(ev, t)} style={styles.quickLinks.link404}>*/}
            {/*{t('Athletes')}*/}
          {/*</Link>*/}
          {/*<Link id='event-not-found' className='quick-link' onClick={(ev) => eventSearchClick(ev, t)} style={styles.quickLinks.link404}>*/}
            {/*{t('Events')}*/}
          {/*</Link>*/}
          {/*<a id='support' href='https://athlinks.zendesk.com/hc/en-us' style={styles.quickLinks.link404} target="_blank">*/}
            {/*{t('Support')}*/}
          {/*</a>*/}
        {/*</div>*/}
      </div>
    }

  </div>
);

QuickLinks.propTypes = {
  searchTerm: PropTypes.string
};
