import { connectStream } from '../../lib/bastetjs/utils/connectStream'
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { styles } from './styles';
import { PageTemplate } from '../home/PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import {QuickLinks} from '../../components/shared/QuickLinks';
import { SearchResults } from '../searchResults/SearchResults';
import { getSearchTerm } from '../../data/SearchTerm';
import MetaTags from 'react-meta-tags';


export class NotFoundComponent extends Component {
  static propTypes = {
    auth: PropTypes.object,
    errorStatus: PropTypes.number,
    isMobile: PropTypes.bool,
    noRedirect: PropTypes.bool,
    errorOverride: PropTypes.object,
    t: PropTypes.func,
  };

  static defaultProps = {
    noRedirect: false,
    errorOverride: {},
    errorStatus: 404,
    location: null,
  };

  state = {
    headerIsFixed: false,
    anyHeaderInputsFocused: false,
  };

  onUnifiedInputsVisible = () => {
    this.setState({
      headerIsFixed: false,
      anyHeaderInputsFocused: false,
    })
  }

  onUnifiedInputsScrolledOff = () => {
    this.setState({
      headerIsFixed: true,
    })
  }

  getErrorStatus() {
    const {
      errorStatus,
      errorOverride,
      t
    } = this.props;

    if(errorOverride.status) {
      return errorOverride.status;
    }

    return `${parseInt(errorStatus)} ${t('Error')}`;
  };

  getErrorMessage() {
    const {
      errorStatus,
      errorOverride,
      t
    } = this.props;

    if(errorOverride.message) {
      return errorOverride.message;
    }

    switch(parseInt(errorStatus)) {
      case 400 :
        return t('Sorry, entry doesn\'t exist.');
      case 404 :
        return t('Sorry, we couldn\'t find that page.');
      case 503 :
        return t('Sorry, something went wrong.');
      case 403 :
        return t('This athlete\'s profile is private. ');
      default:
        return t('Sorry, something went wrong.');
    }
  };

  render() {
    const {
      isMobile,
      errorStatus,
      t
    } = this.props;

    const {
      headerIsFixed,
      anyHeaderInputsFocused,
    } = this.state;

    const showUnifiedSearchDropdown = headerIsFixed && anyHeaderInputsFocused;
    const errorMessageText = this.getErrorMessage();
    const errorStatusText = this.getErrorStatus();

    return (
      <PageTemplate
        unifiedSearchMode={true}
        headerIsFixed={true}
        paddingTop={0}
        isMobile={isMobile}
        style={styles.pageTamplateStyle}
        noFooter={true}>

          <MetaTags>
            <meta name="prerender-status-code" content={this.props.errorStatus || 500} />
          </MetaTags>

          <StandardHeaderTags
            title={errorMessageText}
            description={errorMessageText}
          />

          <OpenGraphTags
            ogType='website'
            title={errorMessageText}
            description={errorMessageText}
          />

          <div className="container">
              <div className="row">
                <div style={{textAlign:'center', margin: '0 auto'}}>
                  <div style={{display:'inline-block'}}>
                      <div style={styles.searchResultsContainer(isMobile)}>
                        {
                          parseInt(errorStatus) &&
                          <div>
                            {
                              parseInt(errorStatus) !== 403 &&
                              <span style={styles.error} id="error-message">
                                {errorStatusText}
                              </span>
                            }
                            <span style={styles.message}>
                              {errorMessageText}
                            </span>
                          </div>
                        }
                        <div style={{ margin: '25px 0px' }} id="search-field">
                          <SearchResults
                            type="notFound"
                            onInputsVisible={this.onUnifiedInputsVisible}
                            onInputsScrolledOff={this.onUnifiedInputsScrolledOff}
                            hideResults={showUnifiedSearchDropdown}
                            { ...this.props }
                          />
                        </div>
                        <QuickLinks notFoundPage={true} t={t}/>
                      </div>
                  </div>
                </div>
              </div>
          </div>

      </PageTemplate>
    );
  }
}

export const NotFound = withTranslation()(connectStream({
  searchTerm: getSearchTerm
})(NotFoundComponent));
