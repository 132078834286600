import Axios from 'axios'
import { logInfo } from './Logging'
import { configs } from '../configs'
import { getCORSConfig } from './getCORSConfig'

export const unclaimARP = (
  {
    entryId
  },
  token
) => new Promise(async (resolve, _reject) => {
  const data = {
    entryId
  }
  const url = `${configs.athleteApiUrl}/unclaim`
  logInfo('hitting', url)
  Axios.post(url, data, getCORSConfig(token))
    .then(response => {
      if (response.status === 202) {
        resolve({ success: true })
      }
      resolve({
        success: false
      })
    })
    .catch((error) => {
      if (error.response?.status === 403) {
        resolve({ success: false, notLoggedIn: true })
      }

      resolve({
        success: false,
        error: `Unclaim failed for entryId "${entryId}": "${error.response?.data}"`
      })

    })
})