import { partial, xor, flow, pick, keys} from 'lodash'

/**
 * Can be curried to find params sans stripped
 * 
 * object1 = {
 *  keep: 1,
 *  lose: 2
 * }
 * 
 * const stripLose = differenceOfParams('lose')
 * 
 * const object2 = stripLose(object1)
 * 
 * returns ['keep']
 *  
 * @param { list of parameters to remove } paramsToStrip 
 */
export const differenceOfParams = (...paramsToStrip) => partial(
  xor,
  ...paramsToStrip
)

/**
 * Can be curried to return a copy of an object sans params
 * 
 * object1 = {
 *  keep: 1,
 *  lose: 2
 * }
 * 
 * const copySansLose = copyWithoutParams('lose')
 * 
 * const object2 = copySansLose(object1)
 * 
 * returns {
 *  keep: 1
 * }
 * 
 * @param { list of params to remove } paramsToStrip 
 */
export const copyWithoutParams = (...paramsToStrip) => (obj) => flow(
  keys,
  differenceOfParams(paramsToStrip),
  partial(
   pick,
   obj
  )
 )(obj)
