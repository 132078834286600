import React, {Component} from 'react';
import PropTypes from 'prop-types';
import  ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Style from 'style-it';
import { withTranslation } from 'react-i18next';

import {athleteProfileResultShare, eventShare} from '../../../utils/facebook';
import {
  calculatePaceArrForResult,
  getResultsUrl
} from '../../../utils/resultsHelpers';
import { colors } from '../../../shared/styles';
import {  ShareIcon } from '../../shared/icons';
import { DateTime } from '../../shared/DateTime';
import { eventStyles, styles } from '../styles';
import { fetchAthleteRaces } from '../../../data/AthleteStreams';
import { getClaimStatusMessage } from '../../../utils/ClaimUtil';
import { Location } from '../../shared/Location';

class UpcomingEventComponent extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    liveEvent: PropTypes.array,
    type: PropTypes.string,
    event: PropTypes.object,
    athleteId: PropTypes.number,
    t: PropTypes.func
  };

  state = {
    editing: false
  };

  render() {
    const {
      type,
      event,
      isMobile,
      t
    } = this.props;

    const {
      Race,
      Ticks,
      Gender,
      CountO,
      CountA,
      CountG,
      ClassName,
      TargetTime,
      MasterId,
      CourseName,
      Name,
      StartDate,
      City,
      StateProv,
      Country,
      RaceGoals,
      ticksString
    } = event;

    let gender = (Gender === 'M'  || Gender === 'F' || Gender === 'NB') ? t(`genderMap#${Gender}`) : ''
    let pace = [];
    let courses = [];
    if (Race) {
      // eslint-disable-next-line
      Race.Courses.map((course) => {
        courses.push(course);
        pace.push(calculatePaceArrForResult({
          raceCatDesc: course.RaceCatDesc, distUnit: course.DistUnit, ticks: Ticks
        }));
      });
    }
    const resultsUrl = getResultsUrl(event.MasterId, event.RaceId);
    const goalTitle = type === 'upcomingEvents' ? t('Goal Time')
      : type === 'liveEvents'
        ? CourseName
        : t('Final Time');
    const row = isMobile ? 'row' : 'row no-gutters';
    const display = type !== 'latestUpdate' && RaceGoals === '' && TargetTime === 0 ? 'none' : 'flex';

    if (!isMobile) {
      // TODO: get event masterId from somewhere?
      return (
        Style.it(styles.pseudoStyles(),
        <Link
          className={row}
          style={styles.cleanRowStyle}
          to={type ==='liveEvents' ? resultsUrl : `/event/${MasterId}`}
        >
          <div className="col-12">
            <div className="col-12" style={{ padding: 0 }}>
              <div className="col-8 pr-0" style={{ padding: 0, float: 'left' }}>
              {
                (type !== 'latestUpdate') ?
                <EventHeader
                  Name={Name}
                  StartDate={StartDate}
                  City={City}
                  StateProv={StateProv}
                  Country={Country}
                  isMobile={isMobile}
                />
                :
                <EventHeader
                  Name={Race.MasterName }
                  StartDate={Race.RaceDate}
                  City={Race.City}
                  StateProv={Race.StateProvAbbrev}
                  Country={Race.CountryID}
                  isMobile={isMobile}
                />
              }
              </div>
            </div>
            <div style={eventStyles.goals(isMobile)} className="pr-0 final-time">
                {
                  TargetTime !== 0 &&
                  <Goals
                    RaceGoals={TargetTime !== 0 && moment.utc(TargetTime).format('HH:mm:ss')}
                    title={goalTitle}
                    titleStyle={styles.titleStyle(isMobile)}
                    goalResStyle={styles.goalNumber}
                    value={TargetTime !== 0 && moment.utc(TargetTime).format('HH:mm:ss')}
                    type={type}
                    isMobile={isMobile}
                  />
                }
            </div>
            {
              type !== 'liveEvents' &&
              <div style={{...eventStyles.eventMain, display, paddingLeft: 0}} className="col-md-12 px-0">
                <div style={{...eventStyles.eventBodyLeft, paddingLeft: 0}} className="col-xs-12 col-md-10">
                  <EventInfo
                    type={type}
                    CountO={CountO}
                    CountA={CountA}
                    CountG={CountG}
                    Gender={gender}
                    ClassName={ClassName}
                    pace={pace}
                    RaceGoals={decodeURI(RaceGoals)}
                    isMobile={isMobile}
                    t={t}
                  />
                </div>
              </div>
            }
          </div>
          <ArrowRight
            size={35}
            className="upcomingRightArrow"
            style={eventStyles.upcomingRowArrow}
          />
        </Link>
        )
      );
    }
    else {
      const [value] = pace[0] || [];
      const {TicksString} = this.props.event;

      return (
        <div style={eventStyles.updateContainer}>
          <div style={{marginBottom:10}}>
            <EventHeader
              Name={Name}
              link={`/event/${MasterId}`}
              StartDate={StartDate}
              City={City}
              StateProv={StateProv}
              Country={Country}
              isMobile={isMobile}
            />
          </div>
          <div style={eventStyles.eventBorderContMobile}>
            {
              type !== 'upcomingEvents' ?
              <div className='row'>
                <Goal title={t('Overall')}
                      className={'col-4 col-lg-4'}
                      value={CountG}
                      footer={`of ${CountO}`}
                      isTime={false}
                />
                <Goal title={t('Pace')}
                      className={'col-4 col-lg-4'}
                      value={value}
                      footer={'Min/Mi'}
                      style={eventStyles.goalPaceStyle}
                      isMobile={isMobile}
                      isTime={true}
                />
                <Goal title={goalTitle}
                      className={'col-4 col-lg-4'}
                      value={ticksString || TicksString || ''}
                      isMobile={isMobile}
                      isTime={true}
                      isFinalTime={true}
                />
              </div>
              :
              <div className='row' style={{alignItems: 'center'}}>
                <div className='col-8'>
                  <EventInfo
                    type={type}
                    CountO={CountO}
                    CountA={CountA}
                    CountG={CountG}
                    Gender={gender}
                    ClassName={ClassName}
                    pace={pace}
                    RaceGoals={decodeURI(RaceGoals)}
                    isMobile={isMobile}
                    t={t}
                  />
                </div>
                <div style={eventStyles.goals(isMobile)} className='col-4'>
                  <Goal
                    className={''}
                    title={goalTitle}
                    value={ TargetTime !== 0 ? moment.utc(TargetTime).format('HH:mm:ss') : '--'}
                    isMobile={isMobile}
                    isTime={true}
                    isFinalTime={true}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      );
    }

  }
}

export default withTranslation()(UpcomingEventComponent);


export const Goal = ({ title, value, footer, className, style = {}, isTime = false, claimMessage = ''}) => {
  return (
    <div className={className} style={{textAlign: 'center', ...style}}>
      <div style={eventStyles.mobileGoalTitle}>{title}</div>
      { value ?
        [
          <div key={1} style={isTime ? eventStyles.mobileTimeValue : eventStyles.mobileRankValue}>{value}</div>,
          <div key={2} style={eventStyles.mobileDenominator}>{ claimMessage || footer }</div>
        ]
        :
        <div style={eventStyles.mobileRankValue}>--</div>
      }
    </div>
  );
};

/**
 * Component for race goals
 *
 * @param RaceGoals
 * @param title
 * @param titleStyle
 * @param value
 * @param isMobile
 * @param rank
 * @param count
 * @param goalResStyle
 * @returns {XML}
 * @constructor
 */
const GoalsComponent = ({RaceGoals, title, titleStyle, value, goalResStyle, rank, count, claimStatus = '', type, t}) => {
  return (
    <div style={{position: 'relative', marginRight: 10}}>
      <div style={titleStyle}>{title}</div>
      <div style={goalResStyle}>
        {RaceGoals ?
          RaceGoals.toLocaleString() :
          value ?
            value.toLocaleString() :
            rank ?
              (count ?
                <div>
                  <div style={eventStyles.goalResNumerator}>{rank}</div><div style={eventStyles.goalResDenominator}>{t('of {{count}}', {count})}</div>
                </div> :
                (<div><div style={eventStyles.goalResNumerator}>{rank}</div></div>))
              :
              '--'
        }
      </div>
      { (type === 'latestUpdate') && claimStatus &&
        <div style={eventStyles.unofficialTag}>
          {getClaimStatusMessage(claimStatus, t)}
        </div>
      }
    </div>
  );
};

GoalsComponent.defaultProps = {
  isUnofficial: false,
};

export const Goals = withTranslation()(GoalsComponent);

/**
 *
 * List of social share icons
 *
 * @param type
 * @param props
 * @param pace
 * @param liveEvent
 * @returns {XML}
 * @constructor
 */
class SocialsComponent extends Component {

  state = {
    value: '',
    modalIsOpen: false
  };

  openModal = () =>
    this.setState({modalIsOpen: true});

  closeModal = () => {
    this.setState({modalIsOpen: false});
  };

  onDone = (response) => {
    if (response.Success) {
      fetchAthleteRaces(this.props.event.racerID);
      this.setState({
        value: '',
        modalIsOpen: false,
      });
    }
  };

  render() {
    const {type, props, pace, isMobile, raceRankings} = this.props;

    let onClick = (type === 'latestUpdate') ?
          () => athleteProfileResultShare(props.event, props.race, props.result, pace, props.isMobile, props.liveEvent) :
        (type !== 'latestUpdate') ?
          () => eventShare(props.event, props.isMobile) : null
    if (!isMobile) {
      return (
        <div style={eventStyles.socialDiv} className='latest-update-social'>
          { !raceRankings &&
            <span style={{...eventStyles.socialIcon, display: 'inline-block', marginLeft: '5px'}}>
              <ShareIcon fill={colors.grey} onClickCapture={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onClick();
              }}/>
            </span>
          }
        </div>
      );
    } else {
      return (
        <div style={eventStyles.socialDiv}>
          <span
            style={{...eventStyles.socialIcon, display: 'inline-block', marginLeft: '5px'}}
            onClick={onClick}
          >
            <ShareIcon height="18" fill={colors.grey}/>
          </span>
        </div>
      );
    }
  }
}

export const Socials = withTranslation()(SocialsComponent);

/**
 *
 * Info section for events
 *
 * @param type
 * @param CountO
 * @param CountA
 * @param CountG
 * @param Gender
 * @param ClassName
 * @param isMobile
 * @param rankA
 * @param rankG
 * @param rankO
 * @param pace
 * @param RaceGoals
 * @returns {XML}
 * @constructor
 */
export const EventInfo =
  ({type, CountO, CountA, CountG, Gender, ClassName, pace, RaceGoals, isMobile, rankA, rankG, rankO, t}) => {

    if (type === 'upcomingEvents' || type === 'liveEvents') {
      return (
        <div style={eventStyles.goalText(isMobile)}>
          { RaceGoals !== '' ?
            RaceGoals : ''
          }
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-12 col-md-8" style={eventStyles.rankingsCell}>
            {ClassName && <div className="col-12 col-md-4 age-score">
              <Goals title={ClassName}
                     titleStyle={eventStyles.goalTitleCenter}
                     rank={rankA}
                     count={CountA}
                     type={type}
                     goalResStyle={eventStyles.goalResStyle}
                     />
            </div>}
            {Gender && <div className="col-12 col-md-4 gender-score">
              <Goals title={ Gender }
                     titleStyle={eventStyles.goalTitleCenter}
                     rank={rankG}
                     count={CountG}
                     type={type}
                     goalResStyle={eventStyles.goalResStyle}
                     />
            </div>}
            <div className="col-12 col-md-4 race-score">
              <Goals title={t('Overall')}
                     titleStyle={eventStyles.goalTitleCenter}
                     rank={rankO}
                     count={CountO}
                     type={type}
                     goalResStyle={eventStyles.goalResStyle}
                     />
            </div>
          </div>

          <div className="col-12 col-md-4 pace-value" style={eventStyles.paceCell}>
            <Goals title={`${t('Pace')} ${pace[0][1] || ''}`}
                   titleStyle={eventStyles.goalTitleCenter}
                   value={pace[0][0]}
                   type={type}
                   goalResStyle={eventStyles.goalNumberStyle(colors.textColor)}
            />
          </div>
        </div>
      );
    }
  };

/**
 *
 * Common header for all events
 *
 * @param Name
 * @param StartDate
 * @param City
 * @param StateProv
 * @param Country
 * @param link
 * @param isMobile
 * @param isUnofficial
 * @returns {XML}
 * @constructor
 */
export function EventHeader({Name, StartDate, City, StateProv, Country, link, isMobile}) {
  return (
    <div>
      {
        link
          ?
          <Link to={link} key="link" style={eventStyles.eventTitle(isMobile)} className='latest-event-title'>
            {Name}
          </Link>
          :
          <div key="link" style={eventStyles.eventTitle(isMobile)} className='latest-event-title'>
            {Name}
          </div>
      }

      <div key="value">
        <div style={eventStyles.eventDetail(isMobile)}>
          <DateTime date={StartDate} showTime={false} icon={null}/>
        </div>
        <Location
          style={eventStyles.eventDetailPipe(isMobile)}
          city={City}
          state={StateProv}
          country={Country}
          searchType={null}
          icon={null}
        />
      </div>
    </div>
  );
}

EventHeader.defaultProps = {
  isUnofficial: false,
};
