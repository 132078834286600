import { configs } from '../configs';
import { getResultUrl } from './resultsHelpers';
import shareContent from './shareContent';

export function athleteProfileShare(athlete, isMobile){
    const shareUrl = `${configs.bragiUrl}/athletes/${athlete.RacerID}`;
    shareContent(shareUrl, isMobile);
}

export function athleteProfileResultShare(event, race, result, pace, isMobile) {
    const eventId = race.RaceId || race.raceID;
    const eventCourseId = result.EventCourseID || event.eventCourseID;
    const entryId = event.EntryID || event.entryID;

    const shareUrl = configs.bragiUrl + getResultUrl(eventId, eventCourseId, entryId);
    shareContent(shareUrl, isMobile);
}

export function resultShare(event, race, result, isMobile) {
  return () => {
    const eventId = result.eventId;
    const eventCourseId = result.eventCourseId;
    const entryId = result.entryId;
    const shareUrl = configs.bragiUrl + getResultUrl(eventId, eventCourseId, entryId);
    shareContent(shareUrl, isMobile);
  }
}

export function eventShare(event, isMobile) {
    const shareUrl = `${configs.bragiUrl}/Event/${event.athlinksMasterId}`;
    shareContent(shareUrl, isMobile);
}
