import React from 'react'
import { Header } from '../../components/shared/Header'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Loading } from '../../components/shared/Loading'
import connectStream from '../../lib/bastetjs/utils/connectStream'
import { getVrEntryForPost } from '../../data/VRStreams'
import { VRTimeEntry } from './VRTimeEntry'
import { NotFound } from '../notFound/NotFound'


function VRLandingPageComponent ({entryUniqueId, vrEntry, t}) {

  const wrapit = (children) => (<section>
    <Header
      isLightMode={false}
      onSwitchMode={(mode) => null}
      themeSwitcherEnabled={false}
      t={t}
    />
    {children}
  </section>)

  if (!vrEntry || vrEntry.loading || vrEntry.fetching) {
    return <Loading centered={true}/>
  }
  else if (vrEntry) {
    if (vrEntry.ErrorMessage) {
      return wrapit(<div className="alert alert-error">{vrEntry.ErrorMessage}</div>)
    }
    else if (!vrEntry.eventId) {
      return <NotFound />
    }
    else {
      return  wrapit(<VRTimeEntry entryUniqueId={entryUniqueId} vrEntry={vrEntry} eventId={vrEntry.eventId} />)
    }
  }
}

export const VRLandingPage = compose(
  withTranslation(),
  connectStream({
    vrEntry: getVrEntryForPost,
  })
)(VRLandingPageComponent)
