import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nobles } from '../../lib/anuket';
import { of as ObservableOf } from 'rxjs';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { withTranslation } from 'react-i18next';

import { friendButtons } from './styles';
import { isLoggedIn } from '../../utils/isLoggedIn';

class AddFriendComponent extends Component {
  static propTypes = {
    friends: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    friends: {isLoading: true}
  };

  state = {
    isFriend: false,
    open: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.friends.isLoading) {
      this.setState({isFriend: false});
    }
    else {
      this.setState({isFriend: this.checkIfFriend(nextProps.friends)});
    }
  }

  checkIfFriend = (friends) => {
    const racerId = this.props.modal.props.result.racerId;
    return (friends.Result || []).filter((friend) => {
      return friend.RacerLogoPath.EntryId === racerId;
    }).length > 0;
  };

  addFriend = () => {
    const racerId = this.props.modal.props.result.racerId;
    this.setState({isFriend: true, open: true});
    Nobles.AthletesNoble.addFriend({racerId});
  };

  removeFriend = () => {
    const racerId = this.props.modal.props.result.racerId;
    this.setState({isFriend: false, open: true});
    Nobles.AthletesNoble.removeFriend({racerId});
  };

  onTouchTap = () => {
    if (this.props.modal.props.isLoggedIn) {
      this.state.isFriend ? this.removeFriend() : this.addFriend();
    }
    else {
      this.props.modal.handleTouchTap();
    }
  };

  handleRequestClose = () => this.setState({open: false});

  renderButton = () => {
    const {
      t
    } = this.props;

    const text = this.state.isFriend ? t('- unfollow') : t('+ follow');

    return (
      <div>
        <span onClick={this.onTouchTap}
              style={ this.state.isFriend ? friendButtons.isFriendButton : friendButtons.notFriendButton }>
          {text}
        </span>
        {/* <Snackbar
          open={this.state.open}
          message={msg}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}/> */}
      </div>
    );
  };

  render() {
    return this.props.modal.props.result.isClaimed && !this.props.friends.isLoading
      ? this.renderButton()
      : null;
  }
}


export const AddFriend = withTranslation()(connectStream({
  friends: () => {
    return isLoggedIn()
      ? Nobles.AthletesNoble.getFriends()
      : ObservableOf({Success: true, Result: []});
  }
})(AddFriendComponent));
