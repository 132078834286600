import { updatePending } from "./updatePending"

const canClaim = (
  claimed
) => ({
  claimed,
  claimable: true,
  unclaimable: false
})

const canUnclaim = (
  _claimed
) => ({
  claimed: true,
  claimable: false,
  unclaimable: true
})

const cantClaim = (
  claimed
) => ({
  claimed,
  claimable: false,
  unclaimable: false
})

export const computeClaimable = (
  claimed,
  isMe,
  isLoggedIn
) =>
  (claimed && !isMe && isLoggedIn ? cantClaim :
    claimed && !isMe && !isLoggedIn ? cantClaim : // impossible state
      claimed && isMe && !isLoggedIn ? canClaim : // impossible state
        claimed && isMe && isLoggedIn ? canUnclaim :
          !claimed && !isMe && isLoggedIn ? canClaim :
            !claimed && !isMe && !isLoggedIn ? canClaim : // impossible state
              !claimed && isMe && isLoggedIn ? canClaim :
                !claimed && isMe && !isLoggedIn ? canClaim :
                  cantClaim)(claimed)

export const updateClaimPending =
  updatePending('athleteCardModel.claimingPending')
export const updateUnclaimingPending =
  updatePending('athleteCardModel.unclaimingPending')
