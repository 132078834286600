import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { claimStyles as styles } from './styles';
import { DialogModal } from './common/DialogModal';
import User from 'react-icons/lib/ti/user-outline';
import {Button as FlatButton} from '../shared/Button'
import {Button} from '../shared/Elements';
import {Loading} from '../shared/Loading';
import {unclaimResult} from '../../data/ResultsStreams';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {
  checkEntries,
  getCheckedEntriesStream,
  claimEntry,
  getClaimEntryStream,
} from '../../data/UnclaimedSearchStreams'
import _ from 'lodash';

export const propTypes = {
  entryId: PropTypes.number.isRequired,
  eventCourseId: PropTypes.number.isRequired,
  ctEntryId: PropTypes.number,
  bib: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  racerId: PropTypes.number.isRequired
};

/**
 * Modal for claiming results
 */
class ClaimModalComponent extends Component {

  static propTypes = {
    ...propTypes,
    afterClaim: PropTypes.func,
    checkedEntries: PropTypes.object,
    claimedEntry: PropTypes.object,
    mergedEntries: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    afterClaim: () => {},
    checkedEntries: {checking: true},
    claimedEntry: {claiming: true},
    mergedEntries: {merging: true}
  };

  state = {
    isGoodMatch: false,
    checked: false,
    claimed: false,
    processing: false,
    pendingClaim: false,
  };

  componentDidMount() {
    const {
      entryId,
      ctEntryId
    } = this.props;

    checkEntries(entryId, ctEntryId);
  }

  UNSAFE_componentWillReceiveProps({checkedEntries, claimedEntry}) {
    this.afterCheck(this.props.checkedEntries, checkedEntries);
    this.afterClaim(this.props.claimedEntry, claimedEntry);
  }

  /**
   * Actions taken after check is done. Call claim if result is good
   * @param {*} prevChecked
   * @param {*} nextChecked
   * @return {boolean}
   */
  afterCheck = (prevChecked, nextChecked) => {
    if (!prevChecked || !nextChecked) {
      return false;
    }
    else if (prevChecked.checking === true && !!nextChecked.results) {
      const claimInfo = this.checkClaim(nextChecked);
      const match = claimInfo.match;
      const alreadyClaimed = claimInfo.pendingClaim;

      this.setState({
        isGoodMatch: match,
        pendingClaim: alreadyClaimed,
        checked: true
      });

      if (match && !alreadyClaimed) {
        this.claim(false);
      }
    }
  };

  /**
   * Execute claim action.
   */
  claim = (isMergeRequest = false) => {
    const {
      entryId,
      bib,
      fullName,
      eventCourseId,
      ctEntryId
    } = this.props;

    this.setState(
      {processing: true},
      () => claimEntry(entryId, ctEntryId, eventCourseId, fullName, bib, isMergeRequest))
  };

  /**
   * Actions taken after claim request is completed
   * @param {*} prevClaimed
   * @param {*} nextClaimed
   * @return {boolean}
   */
  afterClaim = (prevClaimed, nextClaimed) => {
    if (!prevClaimed || !nextClaimed) {
      return false;
    }
    else if (prevClaimed.claiming === true && !!nextClaimed.results) {
      const {
        Success,
        ErrorMessage
      } = nextClaimed.results;

      this.setState({
        claimed: Success,
        errorMsg: ErrorMessage,
        processing: false
      }, this.afterClaim);
    }
  };

  /**
   * Send request for result not matching names. Called when check entries fails
   */
  claimResultNotMatchingName = () => {
     this.claim(true);
  };

  /**
   * Check if result is good match.
   */
  checkClaim = (checkedEntries) => {
    const results = checkedEntries.results;

    if (checkedEntries.checking || _.isEmpty(results)) {
      return {match: false, pendingClaim: false};
    }

    const result = results[0];
    return {match: result.IsStrongMatch, pendingClaim: result.PendingClaimExists};
  };

  /**
   * Is modal in loading state?
   * @return {boolean}
   */
  isLoading = () => {
    const {
      checked,
      claimed,
      isGoodMatch
    } = this.state;

    if (checked) {
      return false;
    }

    return !(checked && isGoodMatch && claimed);
  };

  /**
   * Builds gender and year string
   * @return {string}
   */
  genderYear = () => {
    const gender = this.props.gender ?  this.props.gender.toUpperCase() : 'U';
    return gender + ' ' + this.props.age || '';
  };

  /**
   * Builds title for claim modal
   * @return {{text: *, separator: boolean}}
   */
  getTitle = () => {
    const {
      ctEntryId,
      t
    } = this.props;

    const {
      isGoodMatch,
      claimed,
      pendingClaim
    } = this.state;

    const ctMismatch = !isGoodMatch && ctEntryId;

    const allGood = isGoodMatch || claimed || pendingClaim;

    return ({
      text: allGood ? t('Claim Result')
        : (ctMismatch ? t('Unable to verify that this result is yours')
          : t('This result might not be yours')),
      separator: !allGood
    });
  };

  /**
   * Builds body for claim modal
   * @return {XML}
   */
  getBody = () => {
    const {
      isMobile,
      fullName,
      ctEntryId,
      t
    } = this.props;

    const {
      processing,
      errorMsg,
      isGoodMatch,
      claimed,
      pendingClaim
    } = this.state;

    const style = isMobile ? styles.mobile : styles.desktop;

    const ctMismatch = ctEntryId && !isGoodMatch;

    if (processing) {
      return (
        <div style={{width:'100%', height:70}}>
          <Loading timeoutMessage={t('Still thinking...')}/>
        </div>
      );
    }
    else if (pendingClaim) {
      return (
        <div style={{fontSize: 14}}>
          {t(
            'You\'ve already put in a claim for a result in this race, please check back later.')
          }
        </div>
      );
    }
    else if (claimed) {
      return (
        <div style={{fontSize: 14}}>
          {
            isGoodMatch
              ? t('You\'ve successfully claimed this result to your profile.')
              : t('We are reviewing your claim. Once approved this result '
                + 'will appear on your profile within 24-48 hours.')
          }
        </div>
      );
    }
    else if (ctMismatch) {
      return (
        <div style={{fontSize: 14}}>
          {t('Please check back after results have been made official.')}
        </div>
      );
    }
    else {
      return (
        <div style={{color: '#9b9b9b', fontSize: '15px', fontFamily: 'ProximaNovaRegular'}}>
          <div>
            <div style={{fontSize: 13, paddingRight: 70}}>
              {
                t('If you\'re sure you want to proceed, claims can take 24-48'
                  + ' hours to appear in your profile if approved.')
              }
            </div>
          </div>
          {
            isGoodMatch &&
            <div style={style.nameCompare}>
              <div style={style.name}>
                <User
                  className="user-icon"
                  style={{marginTop: -3}}
                  color="#909090"/>
                {fullName}
              </div>
              <div style={style.genderYear}>
                {this.genderYear()}
              </div>
              <div>
                {errorMsg}
              </div>
            </div>
          }
        </div>
      );
    }
  };

  /**
   * Builds action buttons.
   * @return {*}
   */
  getActions = () => {
    const {
      isMobile,
      handleClose,
      afterClaim,
      claimedEntry,
      ctEntryId,
      t
    } = this.props;

    const {
      processing,
      claimed,
      pendingClaim,
      isGoodMatch
    } = this.state;

    const modalButton = {
      minWidth: 100,
      height: 40,
      textTransform: 'capitalize',
      color: '#16A9E1',
      backgroundColor: '#fff',
      border: '2px solid #16A9E1',
      paddingBottom: 10,
      fontSize: 17,
      marginTop: 15
    };
    const mergeRequestButton = {
      fontSize: 16,
      color: '#fff',
      fontFamily: 'ProximaNovaSemibold'
    };

    const style = isMobile ? styles.mobile : styles.desktop;

    const ctMismatch = ctEntryId && !isGoodMatch;

    if (processing) {
      return null;
    }
    else if (pendingClaim || ctMismatch) {
      return (
        <div style={style.actionContainer}>
          <div style={style.actionButtonContainer}>
            <FlatButton
              id='ok-button'
              style={modalButton}
              text={t('OK')}
              onClick={handleClose}
            />
          </div>
        </div>
      );
    }
    else if (claimed) {
      return (
        <div style={style.actionContainer}>
          <div style={style.actionButtonContainer}>
            <FlatButton
              id='ok-button'
              style={modalButton}
              text={t('OK')}
              onClick={() => afterClaim(true, _.get(claimedEntry, 'results.Result.isMergeRequest', false))}
            />
          </div>
        </div>
      );
    }

    return (
      <div style={style.actionContainer}>
        <div style={style.actionButtonContainer}>
            <FlatButton
              id='cancel-button'
              style={{
                ...mergeRequestButton,
                backgroundColor: '#ffffff',
                marginRight: 10,
                color: '#4a4a4a'
              }}
              text={'CANCEL'}
              variant={'text'}
              onClick={handleClose}
            />
          <FlatButton
            id='add-to-my-profile'
            style={{
              ...mergeRequestButton,
              backgroundColor: '#16a9e1'
            }}
            text={t('CLAIM RESULT')}
            onClick={this.claimResultNotMatchingName}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      isUnclaimedSearch = false,
      t
    } = this.props;

    if(this.isLoading()) return (
      <div style={{width: 50, height: 50, margin: '0 auto'}}>
        <Loading isSearchPage={isUnclaimedSearch} timeoutMessage={t('Still thinking...')}/>
      </div>
    );

    const title = this.getTitle();

    return (
      <DialogModal
        isMobile={this.props.isMobile}
        hideCloseIcon={this.state.claimed}
        title={
          <h1 id='claim-modal-title'
              style={{  fontSize: 25, float: 'left', width: '100%', color: '#4a4a4a', fontFamily: 'ProximaNovaRegular'  }}>
            {
              !this.state.claimed
              ? title.text
              : t('Nice work!')
            }
          </h1>
        }
        separator={title.separator}
        body={this.getBody()}
        bodyStyle={{padding: '0 36px 24px'}}
        actions={this.getActions()}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
      />
    );
  }
}

export const ClaimModal = withTranslation()(connectStream({
  checkedEntries: ({entryId, ctEntryId}) => getCheckedEntriesStream(entryId, ctEntryId),
  claimedEntry: ({entryId, ctEntryId}) => getClaimEntryStream(entryId, ctEntryId),
})(ClaimModalComponent));

class UnclaimModalComponent extends Component {

  static propTypes = {
    ...propTypes,
    afterUnclaim: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    afterUnclaim: () => {}
  };

  state = {
    processing: false
  };

  /**
   * Unclaim result action.
   */
  unclaim = () => this.setState(
    {processing: true},
    async () => {
      const unclaimResultStream = await unclaimResult(this.props);
      return unclaimResultStream.subscribe(this.afterUnclaim)
    });

  /**
   * Actions taken after result is unclaimed
   * @param Success
   * @param ErrorMessage
   */
  afterUnclaim = ({ErrorMessage}) => this.setState(
    {processing: false, errorMsg: ErrorMessage}, this.props.afterUnclaim);

  /**
   * Builds body for unclaimed result modal
   * @return {XML}
   */
  getBody = () => {
    const {
      t
    } = this.props;

    return (
      <div style={{fontSize: 14, color: '#888'}}>
        {t('It may take a moment for this update to appear in our system.')}
      </div>
    );
  };

  /**
   * Builds actions button for modal
   * @return {XML}
   */
  getActions = () => {
    const {
      handleClose,
      t
    } = this.props;

    const {
      processing
    } = this.state;

    if(processing) {
      return (
        <div style={{width:'100%', height:70}}>
          <Loading timeoutMessage={t('Still thinking...')}/>
        </div>
      );
    }
    else return (
      <div>
        <span
          id='unclaim-cancel'
          onClick={handleClose}
          style={{
            cursor: 'pointer',
            marginRight: 45,
            fontSize: 14,
            display: 'inline-block',
            color: '#16A9E1',
            fontFamily: 'ProximaNovaRegular'
          }}
        >
          {t('No, I Want to Keep This Result')}
        </span>
        <Button
          id="unclaim-confirm"
          type='blueAthlinksXL'
          onClick={this.unclaim}
          style={{textTransform: 'none'}}
          label={t('Yes, Unclaim This Result')}
        />
      </div>
    );
  };

  /**
   * Render title for unclaim modal.
   * @return {XML}
   */
  getTitle = () => {
    const {
      t
    } = this.props;

    return (
      <div style={{fontSize: 32, color: '#4a4a4a'}} id="claim-modal-title">
        {t('Are you sure you want to unclaim this result?')}
      </div>
    );
  };

  render() {
    const {isMobile, open, handleClose} = this.props;
    return (
      <DialogModal
        isMobile={isMobile}
        title={this.getTitle()}
        body={this.getBody()}
        actions={this.getActions()}
        actionsContainerStyle={{
          borderTop: 'none',
          padding: '55px 40px 35px 40px'
        }}
        open={open}
        onRequestClose={handleClose}
        bodyStyle={{marginLeft: '12px'}}
      />
    );
  }
}

export const UnclaimModal = withTranslation()(UnclaimModalComponent);
