export const styles = {
  container: {
    backgroundSize: 'cover',
    backgroundColor: 'white',
    position: 'fixed',
    marginTop: 50,
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%'
  },
  wrapper: {
    textAlign:'center',
    margin: '0 auto',
    padding: 50
  },
  message: {
    display: 'block',
    marginTop: 15,
    fontSize: 20,
    fontFamily: 'ProximaNovaRegular'
  },
  img: {
    width: '150px'
  }
};
