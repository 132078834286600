import React from 'react';
import { Link } from 'react-router-dom';
import { athleteCard } from './styles';

const wrapperNode = document.getElementsByClassName('site-content-wrapper')[0] || {};

export const HeroCardName = (props) => {

  /**
   * Extracts name from result. Sometimes can happen that first name and
   * last name are not set. In wich case we pull display name.
   * @return {string}
   */
  const getName = () => {
    const {result: {firstName, lastName, displayName}} = props;
    //If user has a display name, they are a member, and we should show it
    if (displayName) return displayName;
    else if (firstName && lastName) return `${firstName} ${lastName}`;
    else if (firstName) return firstName;
    else if (lastName) return lastName;
    else return '';
  };

  if (props.isPublicProfile && !props.linkToIRP && props.result.racerId) {
    const url = `/athletes/${props.result.racerId}`;

    return (
      <Link
        id="irp-athlete-profile-link"
        to={url}
        onClick={() => wrapperNode.scrollTop = 0}
        style={{display:'block', textDecoration:'none'}}
      >
        <div itemProp="name" style={athleteCard.name} id='athlete-profile-link'>
          {getName()}
        </div>
      </Link>
    );
  }

  return (
    <div itemProp="name" style={athleteCard.name} id='athlete-profile-link'>
      {getName()}
    </div>
  );
};
