import { Theme, makeStyles } from '@material-ui/core/styles';

import { textStyles } from '../../shared/styles';

const useStyles = makeStyles<Theme>((theme) => ({
  divider: {
    backgroundColor: theme.palette.grey[500],
    height: '10px',
    width: '1px',
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    margin: 0,
  },
  detailsContainer: {
    alignItems: 'center',
    color: theme.palette.grey[500],
    display: 'flex',
    fontSize: '14px',
    fontWeight: 400,
    gap: '12px',
    lineHeight: '20px',
    letterSpacing: '0.17px',
  },
  descContainer: {
    color: theme.palette.grey[600],
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.17px',
    paddingTop: '24px',
  },
  link: {
    ...textStyles.textLink2Blue,
    textAlign: 'center',
    fontSize: 14,
  },
  moreButtonWrap: {
    marginTop: '8px',
    marginBottom: '-8px',
    position: 'relative',
  },
  moreImg: {
    marginLeft: 5,
    width: 10,
    height: 10,
  },
}));

export { useStyles };
