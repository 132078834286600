import React, { Component} from 'react';
import {withTranslation} from 'react-i18next';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux'
import Done from 'react-icons/lib/io/checkmark';
import Close from 'react-icons/lib/io/close';
import {DialogModal} from '../../components/modal/common/DialogModal';
import { styles } from './styles';
import { configs } from '../../configs';
import { colors } from '../../shared/styles';
import {handlePrint} from '../../utils/handlePrint';
import {trackGoogleEvent} from  '../../utils/googleEvents';
import {PrinterActionTypes} from '../../actions/types';
import _ from 'lodash';

const ICON_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  NONE: 'none',
  HIDDEN: 'hidden'
}

// Check browser support
const checkSupport = () => !!(window?.navigator?.usb)

// All this will have to go thru translate
const instructionsText = `
  Our Athlinks Kiosk supports printed results! 
  If you are attempting to print a Result Receipt, 
  please ensure the following…
`
function isVowel(string) {
  return string && string.length && /^[aeiou]$/.test(string.charAt(0).toLowerCase());
}

const ModalButton = ({label, active, onClick, type}) => {
  const isPlain = type === 'plain';
  return(
    <div style={styles.buttonContainer(active)}>
      <div onClick={onClick} style={styles.button(active, isPlain)}>
        <div style={styles.buttonText(active, isPlain)}>
          {label}
        </div>
      </div>
    </div>
  )
}

const printStatusSendingPrint = {
  label: 'Sending To Printer'
}
const printStatusPrintSent = {
  label: 'Print Again'
}
const printStatusDefault = {
  label: 'Test Print'
}

class KioskPrinterComponent extends Component {

  state = {
    open: false,
    device: null,
    printStatus: printStatusDefault,
    chromeStep: {
      title: () => (
        <div>
          {'Printing is supported on ChromeOS, MacOS and Windows. Ensure you are using the latest version of '}
          <a style={{color: colors.darkBlue3}} target="_blank" rel="noopener noreferrer" href="https://www.google.com/chrome/">Google Chrome</a>
          {' (ChromeOS, MacOS), or '}
          <a style={{color: colors.darkBlue3}} target="_blank" rel="noopener noreferrer" href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>
          {' (MacOS, Windows).'}
        </div>
      ),
      status: ICON_STATUS.FAIL
    },
    deviceStep: {
      title: () => (
        <div>
          {isVowel(_.get(this.state, 'device.productName', 'approved thermal printer')) ? 'An ' : 'A '}
          {
            !!this.state.device
              // eslint-disable-next-line
              ? <a style={{color: colors.darkBlue3, cursor: 'pointer'}} onClick={this.requestDevice}>{this.state.device.productName}</a> // this is just to highlight the device
              : <a style={{color: colors.darkBlue3}} target="_blank" rel="noopener noreferrer" href="https://knowledge.chronotrack.com/hc/en-us/articles/360030465352#printer">{'approved thermal printer'}</a> // this may lead somewhere
          }
          {' is connected.'}
        </div>
      ),
      status: ICON_STATUS.FAIL
    },
    helpStep: {
      title: () => (
        <a style={{color: colors.darkBlue3}} target="_blank" rel="noopener noreferrer" href="https://knowledge.chronotrack.com/hc/en-us/articles/360030465352#faq">Need Help?</a>
      ),
      status: ICON_STATUS.HIDDEN
    }
  };

  constructor(props, context) {
    super(props, context);
    this.showPrintModal = this.showPrintModal.bind(this);
    this.handleRequestClose = this.handleRequestClose.bind(this);
    this.requestDevice = this.requestDevice.bind(this);
    this.renderPrinterIconStatus = this.renderPrinterIconStatus.bind(this);
    this.runTestPrint = this.runTestPrint.bind(this)
  }

  mockPrinter = {
    productId: 'TESTPRINTER',
    productName: 'TESTPRINTER',
    vendorId: 'TESTPRINTER',
  }

  componentDidMount() {
    const vendorId = localStorage.getItem('vendorId')
    const productId = localStorage.getItem('productId')
    this.setState({chromeStep: {...this.state.chromeStep, status: checkSupport() ? ICON_STATUS.SUCCESS : ICON_STATUS.FAIL }})

    if (navigator.usb) {
      navigator.usb.getDevices().then((devices) => {
        const connectedDevices = configs.useMockPrinter ? [this.mockPrinter] : devices.filter((_) => _.productId.toString() === productId && _.vendorId.toString() === vendorId)
        if (connectedDevices.length) {
          this.setState({
            device: connectedDevices[0],
            deviceStep: { ...this.state.deviceStep, status: ICON_STATUS.SUCCESS }
          })
          this.props.dispatch({
            type: PrinterActionTypes.PRINTER_SET,
            printerSet: true
          })
        }
      }).catch((e) => console.log(e))

      navigator.usb.onconnect = (event) => {
        const vendorId = localStorage.getItem('vendorId')
        const productId = localStorage.getItem('productId')
        const device = event.device
        if (device.productId.toString() === productId && device.vendorId.toString() === vendorId) {
          this.setState({
            device,
            deviceStep: { ...this.state.deviceStep, status: ICON_STATUS.SUCCESS }
          })
          this.props.dispatch({
            type: PrinterActionTypes.PRINTER_SET,
            printerSet: true
          })
        }
      }
      navigator.usb.ondisconnect = (event) => {
        const vendorId = localStorage.getItem('vendorId')
        const productId = localStorage.getItem('productId')
        const device = event.device
        if (device.productId.toString() === productId && device.vendorId.toString() === vendorId) {
          this.setState({
            device: null,
            deviceStep: { ...this.state.deviceStep, status: ICON_STATUS.FAIL }
          })
          this.props.dispatch({
            type: PrinterActionTypes.PRINTER_SET,
            printerSet: false
          })
        }
      }
    }
  }

  showPrintModal() {
    this.setState({
      open: true
    });
  }

  handleRequestClose() {
    this.setState({
      open: false,
      printStatus: printStatusDefault
    });
  }

  requestDevice = () => {
    // Basic boilerplate

    navigator.usb.requestDevice({ filters: [{ }] })
      .then((selectedDevice) => {
        const {productName, vendorId, productId, serialNumber} = selectedDevice
        localStorage.setItem('vendorId', vendorId)
        localStorage.setItem('productId', productId)
        trackGoogleEvent('Printer', 'connect', productName)
        trackGoogleEvent('Printer', 'connect', `${vendorId}-${productId}-${serialNumber}`)
        this.setState({
          device: selectedDevice,
          deviceStep: { ...this.state.deviceStep, status: ICON_STATUS.SUCCESS }
        })
        this.props.dispatch({
          type: PrinterActionTypes.PRINTER_SET,
          printerSet: true
        })
      }).catch((e) => console.log(e))
    }

  renderItemIcon(status) {

    if(status === ICON_STATUS.SUCCESS) {
      return (<Done color={colors.success} />)
    }

    if(status === ICON_STATUS.FAIL) {
      return (<Close color={colors.darkRed} />)
    }

    if(status === ICON_STATUS.HIDDEN) {
      return (<div style={{...styles.marker, backgroundColor: 'transparent'}}></div>)
    }

    return <div style={styles.marker}></div>
  }

  renderPrinterIconStatus(connected) {
    return (
      <img
        onClick={() => this.showPrintModal()}
        style={{ cursor: 'pointer', marginRight: '3rem' }}
        src={`${configs.bragiUrl}/svg/${ 
          connected ? 'printer_connected.svg' : 'printer_disconnected.svg' }`}
        alt={''}
      />
    )
  }

  renderListItem(item) {
    return (
      <div style={styles.listItemContainer}>
        { this.renderItemIcon(item.status) }
        <div style={styles.listItem} >
          {item.title()}
        </div>
      </div>
    )
  }

  runTestPrint() {
    const {
      intl,
      eventId,
      masterEvent,
      t
    } = this.props
    this.setState({printStatus: printStatusSendingPrint})
    const eventIndex = masterEvent.eventRaces.findIndex((_) => _.raceID === eventId)
    const race = masterEvent.eventRaces[eventIndex]
    const result = {
      eventId: eventId,
      eventCourseId: race.eventCourses[0].eventCourseID
    }

    handlePrint(result, masterEvent, intl, t).then(() =>
      this.setState({printStatus: printStatusPrintSent})
    )
  }

  render() {

    const {
      chromeStep,
      deviceStep,
      helpStep,
      device,
      printStatus
    } = this.state;

    const isSupported = checkSupport();
    return (
      <div style={styles.kioskPrinterContainer}>
        {this.renderPrinterIconStatus(!!device)}
        <DialogModal
          title={
            <div style={styles.headerContainer}>
              <img style={{}} src={`${configs.bragiUrl}/svg/receipt.svg`} alt={''} />
              <div style={styles.headerText}>
                Printing Results
              </div>
              <div style={styles.headerSubText}>
                {instructionsText}
              </div>
            </div>}
          separator={false}
          closeStyle={{padding: '15px 15px 0 0'}}
          overlayStyle={{zIndex: 'auto'}}
          body={
            <div style={{padding: '0px 10px 25px 10px'}}>
              <div style={styles.subHeader}>SET UP STEPS</div>
              <div style={styles.textSeparator}></div>
              { this.renderListItem(chromeStep) }
              { this.renderListItem(deviceStep) }
              { this.renderListItem(helpStep) }
              <div style={styles.buttonsContainer}>
                  <ModalButton
                    active={!!device}
                    onClick={this.runTestPrint}
                    label={printStatus.label}
                  />
                  {
                      <ModalButton
                        onClick={this.requestDevice}
                        active={isSupported}
                        label={!!device ? 'Change Device' : 'Connect Printer'}
                      />
                  }
              </div>
            </div>
          }
          open={this.state.open}
          onRequestClose={this.handleRequestClose}
          isMobile={false}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  dispatch: undefined,
  print: state.print
});

export const KioskPrinter = connect(mapStateToProps)(withTranslation()(injectIntl(KioskPrinterComponent)));
