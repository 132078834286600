import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { latestSsePayload, getIrpResultsSelector } from '../../reducers/reigniteSseSlice'
import ReigniteServerSentEvents from '../../components/shared/ReigniteServerSentEvents'
import { ReigniteSsePayload } from '../../utils/types'

export function ReigniteIndividualSseResult(props: {
  athlinksEventId: number
  bib?: string
  eventCourseId: number
  refetch: () => void
}) {
  const {
    athlinksEventId,
    bib,
    eventCourseId,
    refetch,
  } = props
  const path = (
    athlinksEventId && eventCourseId && bib
      ? `event/${athlinksEventId}/race/${eventCourseId}/bib/${bib}`
      : ''
  )
  const sseState = useSelector(getIrpResultsSelector(path))
  const dispatch = useDispatch()

  React.useEffect(() => {
    console.log('sseState', {
      athlinksEventId,
      eventCourseId,
      bib,
      sseState,
    })
    if (sseState?.lastUpdated &&
      sseState.lastPayload?.type === 'results' && (
        !eventCourseId || eventCourseId === sseState.lastPayload.payload.raceId
      ) && (
        !bib || bib === sseState.lastPayload.payload.bib
      )
    ) {
      try {
        refetch()
      }
      catch (err) {
        console.warn(err)
      }
    }
  }, [
    athlinksEventId,
    eventCourseId,
    bib,
    refetch,
    sseState,
    dispatch
  ])

  const onMessage = React.useCallback(
    (data: ReigniteSsePayload) =>
      dispatch(
        latestSsePayload({
          path,
          ...data
        })
      ),
    [
      dispatch,
      path,
    ]
  )

  // Do not render until we have some result due to how IRPBody deals with mounting/updating
  // it's okay to update with an empty object to clear stats once the component has already been mounted
  return <>
    {path && <ReigniteServerSentEvents
      onMessage={onMessage}
      path={path}
    />
    }
  </>
}
