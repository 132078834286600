import React from 'react'
import { profileTagStyles as styles } from './styles'
import {AthletePhoto} from '../athlete/AthletePhoto'

export const ProfileTag = (props) => {
  const {
    courseName,
    url,
    photos,
    racerId,
    displayName,
    isMobile,
    isLoggedIn,
    showAthletePhoto
  } = props;

  return (
    <div style={styles.profileCell}>
      {
        !isMobile &&
        <div style={styles.profilePhotoContainer}>
          {
            showAthletePhoto &&
            <AthletePhoto
              isMember={!!racerId}
              photo={photos}
              name={displayName}
              width={32}
            />
          }
        </div>
      }
     <div style={styles.profileNameContainer(isMobile)} className='row'>
        <div className='col- starter-row'>
          <div className="athName" style={styles.profileNameLink(isMobile)}>
            <span className={url ? 'athName-display' : null}>
              {displayName}
            </span>
          </div>
        </div>
        <div className='col-12 pl-0' style={styles.profileCourse}>
          {courseName}
        </div>
      </div>
      <div style={styles.profileFollow(isLoggedIn)}>
        +
      </div>
    </div>
    )
};

ProfileTag.defaultProps = {
  useLink: true,
};
