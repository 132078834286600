import React from 'react';
import { styles } from './styles';
import { colors } from '../../shared/styles';
import AngleRight from 'react-icons/lib/fa/angle-right';
import _ from 'lodash';

export function TypeaheadOption(props) {
  const {
    eventMetadata,
    index,
    isFocused,
    isHovered,
    isLightMode,
    option,
  } = props;
  if (!!_.size(option)) {
    let eventCourseName = (eventMetadata.eventCourseMetadata.find((course) => course.eventCourseId === option.eventCourseId) || {}).eventCourseName;

    return (<div style={{
      ...styles.typeaheadKioskContainer(isHovered, isFocused, isLightMode),
      borderTop: index !== 0 ? `1px solid ${colors.lightGrey4}` : 'none'
    }}>
      <div style={styles.kisokResultRow('11%', true, true)}>
        {option.bib || ''}
      </div>
      <div style={styles.kisokResultRow('42%', true)}>
        {option.displayName || ''}
      </div>
      <div style={styles.kisokResultRow('10%', true)}>
        {option.gender || ''}{option.age || ''}
      </div>
      <div style={styles.kisokResultRow('31%')}>
        {eventCourseName || ''}
      </div>
      {isHovered && <AngleRight size={30} style={{
        alignSelf: 'center',
        marginLeft: 10
      }} />}
    </div>);
  }

  return null;
}
