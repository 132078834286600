import { makeStyles, Theme } from "@material-ui/core";

import { fonts } from "../../shared/styles";

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
  chipsContainer: {
    marginTop: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
  },
  chipsItem: {
    color: '#0077b3',
    borderColor: '#0077b3',

    '& svg': {
      color: '#0077b3',
    }
  },
  container: {
    display: 'flex',
    gap: '12px',

    [breakpoints.up('sm')]: {
      gap: '24px',
    }
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dialogHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 12px 12px',

    [breakpoints.up('sm')]: {
      padding: '24px',
    }
  },
  dialogHeaderTitle: {
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '24px',
    lineHeight: '29px',

    '& svg': {
      margin: '0 0 2px 4px',
    }
  },
  dialogHeaderCloseIcon: {
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
    padding: '4px',
  },
  dialogFiltersContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '16px',
    marginTop: '12px',
    padding: '0 12px 12px',

    [breakpoints.up('sm')]: {
      minWidth: '600px',
      padding: '0 24px 48px',
    },

    '& .MuiInput-underline:after, .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #0077b3',
    },
  },
  dialogFooterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 12px 12px 12px',
    boxShadow: '0px -2px 10px 0px #0000001A',

    [breakpoints.up('sm')]: {
      gap: '12px',
      justifyContent: 'flex-end',
      padding: '12px 24px',
    }
  },
  dialogFooterButton: {
    borderRadius: '2px',
    padding: '8px',
    lineHeight: '24px',
    fontSize: '14px',
    fontFamily: fonts.bold.fontFamily,

    '&:hover': {
      color: 'inherit',
      backgroundColor: 'inherit',
    }
  },
  dialogFooterApplyButton: {
    color: palette.common.white,
    backgroundColor: palette.brandColor,
    padding: '8px 16px 8px 16px',

    '&:hover': {
      color: palette.common.white,
      backgroundColor: palette.brandColor,
    }
  },
  filterButton: {
    textTransform: 'none',
    borderRadius: '4px',
    color: '#0077b3',
    borderColor: '#0077b3',
    padding: '0 12px',
    fontFamily: fonts.default.fontFamily,

    '& .MuiButton-startIcon': {
      marginRight: '4px',
    }
  },
}))

export { useStyles }