import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {DateTime} from '../../shared/DateTime';
import {connectStream} from '../../../lib/bastetjs/utils/connectStream';
import {SearchField} from '../../shared/Elements';
import AngleLeftIcon from 'react-icons/lib/fa/angle-left';
import { Link } from 'react-router-dom';
import {rivalStyles} from '../styles';
import {PlusSign, RivalHand} from '../../shared/icons';
import {colors} from '../../../shared/styles';
import {
  getAthleteVsRivalStream,
  getAthleteVsRivalDetailsStream,
  fetchVsDetails,
  fetchVs
} from '../../../data/AthleteStreams';

class RivalVsComponent extends Component {
  static propTypes = {
    athlete: PropTypes.object.isRequired,
    rival: PropTypes.object.isRequired,
    fetching: PropTypes.bool,
    vs: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    rival: {
      DisplayName: '',
      Wins: '',
      Losses: '',
      RacerID: '',
      CourseIdList: []
    },
    fetching: false,
  };

  state = {
    searchTerm: '',
    hoverBack: false,
  };

  componentDidMount() {
    const {
      athlete: {RacerID: athleteId},
      rival: {RacerID, CourseIdList}
    } = this.props;

    if(athleteId && RacerID && CourseIdList) {
      fetchVs(athleteId, RacerID, CourseIdList);
    }
  }

  onSearch = ({ target: { value } }) => this.setState({ searchTerm: value });

  searchFilter = ({evt: {Name}}) =>
    Name.toLowerCase().includes(this.state.searchTerm.toLowerCase());


  render() {
    const {
      athlete,
      rival,
      vs = {},
      isMobile,
      fetching: rivalFetching,
      t
    } = this.props;

    const {
      FName,
    } = athlete;

    const {
      DisplayName,
      Losses,
      Wins
    } = rival;

    const {
      fetching: vsFetching,
      error
    } = vs;

    const {
      hoverBack,
      searchTerm
    } = this.state;

    const errorText = error
      ? (typeof error === 'string' ? error : t('Sorry, something went wrong.'))
      : null;

    const isFetching = vsFetching || rivalFetching;

    if (errorText) {
      return <div>{errorText}</div>;
    }

    const vsItems = vs.vs;

    if (!vsItems && !isFetching) {
      return <div>{t('No Vs Info')}</div>;
    }

    const athleteId = athlete.RacerID;
    const rivalId = rival.RacerID;

    return (
      <div>
        {
          // Back
        }
        <Link
          to={`/athletes/${athleteId}/rivals`}
          style={rivalStyles.vsBack(hoverBack)}
          onMouseOver={ () => this.setState({hoverBack: true}) }
          onMouseLeave={ () => this.setState({hoverBack: false}) }
        >
          <span style={rivalStyles.vsBackChevron}  id='back-to-all-rivals'>
            <AngleLeftIcon size={17}/>
          </span>
          <span>{t('back to all rivals')}</span>
        </Link>

        {
          // Title/search
        }
        <div className='row mx-0' style={rivalStyles.titleRow}>
          <div className='col-12 col-md-7' style={rivalStyles.title(isMobile)} id='rival-vs-title'>
            <div style={rivalStyles.vsTitleNameContainer}>
              <div>{FName}</div>
              <div style={rivalStyles.vsTitleScore}>{t('({{wins}}-{{losses}})', {wins: Wins, losses: Losses})}</div>
            </div>
            <span style={rivalStyles.vsWord}>{t('vs.')}</span>
            <div style={rivalStyles.vsTitleNameContainer}>
              <div>{DisplayName.split(' ')[0]}</div>
              <div style={rivalStyles.vsTitleScore}>{t('({{losses}}-{{wins}})', {losses: Losses, wins: Wins})}</div>
            </div>
          </div>

          { !isMobile &&
            <div className='col-12 col-md-5'>
              <SearchField
                onChange={this.onSearch}
                placeholder={t('Search by event name')}
                id='search-event'
                value={searchTerm}
              />
            </div>
          }
        </div>

        {
          // Table Header
        }
        <div className="row mx-0" style={rivalStyles.listHeader}>
          <div className="col-6" style={rivalStyles.vsHeaderLeft(isMobile)}>
            {t('Event')}
          </div>
          <div style={rivalStyles.vsHeaderCol(isMobile)}>
            {t('win/loss')}
          </div>
          {!isMobile &&
            <div style={rivalStyles.vsHeaderCol(isMobile)}>
              {t('Overall')}
            </div>
          }
          <div style={rivalStyles.vsHeaderCol(isMobile)}>
            {t('+diff')}
          </div>
          {!isMobile &&
            <div style={rivalStyles.vsHeaderCol(isMobile)}>
              {t('Final Time')}
            </div>
          }
        </div>

        {
          // vs Table
        }
        <div style={rivalStyles.rivalVSTableContainer(isFetching)} >
          {
            (vsItems || []).filter(this.searchFilter).map((vsItem, index) => (
              <RivalVsItem
                {...vsItem}
                key={index}
                athleteId={athleteId}
                rivalId={rivalId}
                courseId={vsItem.course.CourseID}
                isMobile={isMobile}/>
            ))
          }

          {
            // Loading Placeholder
          }
          <div style={rivalStyles.loadingContainer(isFetching)}>
            <VsLoadingRow isMobile={isMobile} />
            <VsLoadingRow isMobile={isMobile} />
          </div>
        </div>
      </div>
    );
  }
}

const VsLoadingRow = ({isMobile}) => (
  <div style={rivalStyles.loadingRow}>
    <div className="col-6" style={rivalStyles.vsPersonColLoading}>
      <div style={rivalStyles.loadingTitle}/>
      <div style={rivalStyles.loadingDetails}/>
    </div>

    <div style={rivalStyles.vsDataColLoading}>
      <div style={rivalStyles.loadingStat}/>
    </div>

    <div style={rivalStyles.vsDataColLoading}>
      <div style={rivalStyles.loadingStat}/>
    </div>

    <div style={rivalStyles.vsDataColLoading}>
      <div style={rivalStyles.loadingStat}/>
    </div>

    {!isMobile &&
      <div style={rivalStyles.vsDataColLoading}>
        <div style={rivalStyles.loadingTime}/>
      </div>
    }
  </div>
);

class RivalVsItemComponent extends Component {
  static propTypes = {
    athleteId: PropTypes.number,
    rivalId: PropTypes.number,
    course: PropTypes.object,
    evt: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    athleteId: '',
    rivalId: '',
    course: {},
    vsDetails: {}
  };

  state = {
    showDetails: false
  };

  componentDidMount() {
    const {athleteId, rivalId, courseId} = this.props;
    fetchVsDetails(athleteId, rivalId, courseId);
  }

  render() {
    const {
      vsDetails,
      evt: {
        Name,
        StartDateTime
      },
      isMobile,
      t
    } = this.props;

    const {
      showDetails,
      hover
    } = this.state;


    if (!vsDetails || vsDetails.fetching || !vsDetails.vsDetails) {
      return null;
    }

    const {
      Racer = {},
      Rival = {}
    } = vsDetails.vsDetails;

    const {
      Overall,
      Difference,
      Time
    } = Racer;

    const userWon = Overall < Rival.Overall;
    const matchOutcome = (userWon) ? t('Win') : t('Loss');

    // TODO: Error response

    return (
      <div
        className="row mx-0 rival-vs-item"
        style={rivalStyles.listRow(hover)}
        onClick={() => this.setState({showDetails: !this.state.showDetails})}
        onMouseEnter={() => this.setState({hover: true})}
        onMouseLeave={() => this.setState({hover: false})}
      >
        <div className="col-6" style={rivalStyles.vsPersonCol}>
          <PlusSign
            height={isMobile ? 12 : 15}
            style={rivalStyles.expandPlus(isMobile)}
          />
          <div style={rivalStyles.vsNameCol}>
            <div style={rivalStyles.rowName(isMobile)}>
              {Name}
            </div>
            <div style={rivalStyles.rowLocation}>
              <DateTime
                date={StartDateTime}
                showTime={false}
                icon={null}
              />
            </div>
          </div>
        </div>
        <div style={rivalStyles.vsDataCol(isMobile)}>
          {
            userWon ?
            <div>
              <span style={rivalStyles.winText}>{matchOutcome}</span>
              <RivalHand
                height={isMobile ? 20 : 25}
                style={{verticalAlign: 'top'}}
              />
            </div>
            :
            <div style={{color:colors.grey}}>
              {matchOutcome}
            </div>
          }
        </div>
        {
          !isMobile &&
          <div style={rivalStyles.vsDataCol(isMobile)}>
            {Overall}
          </div>
        }
        <div style={rivalStyles.vsDataCol(isMobile)}>
          {Difference}
        </div>
        {
          !isMobile &&
          <div style={rivalStyles.rowTime}>
            {Time}
          </div>
        }
        <div style={{width:'100%'}}>
          <RivalVsDetails
            isMobile={isMobile}
            showing={showDetails}
            vsDetails={vsDetails}
            racer={Racer}
            rival={Rival}
            userWon={userWon}
            t={t}
          />
        </div>
      </div>
    );
  }
}

const RivalVsDetails = ({vsDetails, racer, rival, userWon, isMobile, showing, t}) => {
  if (!vsDetails || vsDetails.fetching) {
    return null;
  }

  const {error} = vsDetails;
  if (error) {
    return (
      <div className="offset-sm-1 col-sm-11 col-md-11 col-lg-11"
           style={rivalStyles.rivalsVSDetailsContainer}>
        {
          typeof error === 'string' ? error : t('Something went wrong. Please try again later.')
        }
      </div>
    );

  }

  return (
    <div style={rivalStyles.vsDetailsContainer(showing, isMobile)}>
      {/* Table Header */}
      <div className='row mx-0' style={rivalStyles.vsDetailsHeader(isMobile)}>
        <div className="col-6" style={{textAlign:'left'}}>{t('Name')}</div>
        <div style={rivalStyles.vsDetailsCol(isMobile)}>{t('Races')}</div>
        {
          !isMobile &&
          [
          <div key='1' style={rivalStyles.vsDetailsCol(isMobile)}>{t('class')}</div>,
          <div key='2' style={rivalStyles.vsDetailsCol(isMobile)}>{t('+diff')}</div>
          ]
        }
        <div style={rivalStyles.vsDetailsCol(isMobile)}>{t('Final Time')}</div>
      </div>
      {/* Athlete Details */}
      <div className='row mx-0 rival-vs-details' style={rivalStyles.vsDetailsRow(isMobile)}>
        <div className="col-6" style={{textAlign:'left'}}>
          {
            userWon ?
            <span>
              {racer.Name}
              <RivalHand height={20} style={rivalStyles.vsDetailsHand}/>
            </span>
            : racer.Name
          }
        </div>
        <div style={rivalStyles.vsDetailsCol(isMobile)}>{racer.Races}</div>
        {
          !isMobile &&
          [
          <div key='1' style={rivalStyles.vsDetailsCol(isMobile)}>{racer.Class}</div>,
          <div key='2' style={rivalStyles.vsDetailsCol(isMobile)}>{racer.Difference}</div>
          ]
        }
        <div style={rivalStyles.vsDetailsTime(isMobile)}>{racer.Time}</div>
      </div>
      {/* Rival Details */}
      <div className='row mx-0' style={rivalStyles.vsDetailsRow(isMobile)}>
        <div className="col-6" style={{textAlign:'left'}}>
          {
            !userWon ?
            <span>
              {rival.Name}
              <RivalHand height={20} style={rivalStyles.vsDetailsHand}/>
            </span>
            : rival.Name
          }
        </div>
        <div style={rivalStyles.vsDetailsCol(isMobile)}>{rival.Races}</div>
        {
          !isMobile &&
          [
          <div key='1' style={rivalStyles.vsDetailsCol(isMobile)}>{rival.Class}</div>,
          <div key='2' style={rivalStyles.vsDetailsCol(isMobile)}>{rival.Difference}</div>
          ]
        }
        <div style={rivalStyles.vsDetailsTime(isMobile)}>{rival.Time}</div>
      </div>
    </div>
  );
};

export const RivalVsItem = withTranslation()(connectStream({
  vsDetails: ({athleteId, rivalId, courseId}) => getAthleteVsRivalDetailsStream(athleteId, rivalId, courseId),
})(RivalVsItemComponent));

export const RivalVs = withTranslation()(connectStream({
  vs: ({athlete = {}, rival = {}}) => getAthleteVsRivalStream(athlete.RacerID, rival.RacerID),
})(RivalVsComponent));
