import _ from 'lodash';
import React, {Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { EventResultsContainer } from '../../components/eventResultsContainer/EventResultsContainer';
import { styles } from './styles';
import {Pager} from '../pager/Pager';
import {isLoggedIn} from '../../utils/isLoggedIn';

class EventResultsSplitterComponent extends Component {
  static propTypes = {
    isCTLive: PropTypes.bool.isRequired,
    options: PropTypes.any,
    athleteRaces: PropTypes.array,
    results: PropTypes.object,
    meta: PropTypes.object,
    divisionId: PropTypes.number,
    t: PropTypes.func,
  };

  static defaultProps = {
    athlete: {isLoading: true},
    athleteRaces: []
  };

  render() {
    const {
      masterId,
      races,
      athlete,
      friends,
      onExpand,
      unofficial,
      isEventLive,
      style,
      eventId,
      isMobile,
      isCTLive,
      options,
      startEpoch,
      eventCity,
      eventState,
      eventCountry,
      results,
      t,
      onPage,
      from,
      paging,
      eventCourseId,
      divisionId,
      meta,
      athleteRaces
    } = this.props;

    const finalResults = (results || {}).data;
    const eventMetadata = (meta || {}).data;

    if (!_.isEmpty(finalResults)) {
      const eventName = eventMetadata.eventName;

      return (
        <div>
          <h3 id="eventName" style={styles.eventName}>
            {eventName}
          </h3>
          <EventResultsContainer
            athlete={athlete}
            courseName={''}
            selectedEventStatus={this.props.selectedEventStatus}
            eventCity={eventCity}
            eventCountry={eventCountry}
            eventCourseId={eventCourseId || 0}
            divisionId={divisionId}
            eventMainId={eventId}
            eventState={eventState}
            friends={friends}
            isCtlive={this.props.isCTLive}
            isCTLive={isCTLive}
            isEventLive={isEventLive}
            isMobile={isMobile}
            masterId={masterId}
            onExpand={onExpand}
            options={options.races}
            pageNumber={from}
            pageSize={paging.pageSize}
            races={races}
            results={finalResults}
            startEpoch={startEpoch}
            showingFriends={this.props.showingFriends}
            style={style}
            tableSubtitle={this.props.tableSubtitle}
            totalLabel={t('Results')}
            unofficial={unofficial}
            widthOffset={this.props.widthOffset}
            eventMetadata={eventMetadata}
            showSignUpBanner={!isLoggedIn()}
            athleteRaces={athleteRaces}
          />
          {
            !!eventCourseId ?
            <div style={styles.pagerContainer} id='pager'>
              <Pager
                pageNumber={from}
                pageSize={paging.pageSize}
                onPage={(pageNumber) => onPage(pageNumber)}
                total={finalResults[0].totalAthletes} // TODO: totalDivison
                pageButton={{cursor: 'pointer'}}
              />
            </div>
            : null
          }
        </div>
      );
    }

    return null;
  }
}

export const EventResultsSplitter = withTranslation()(EventResultsSplitterComponent);
