import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { PageTemplate } from '../home/PageTemplate'
import { styles } from './styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { AthleteRacePageCard } from './AthleteRacePageCard'
import { DotDotDot } from '../../components/shared/icons'
import { Loading } from '../../components/shared/Loading'
import { RaceSummary } from '../../components/ARP/RaceSummary'
import { Countdown } from '../../components/ARP/Countdown'
import { Goals } from '../../components/ARP/Goals'
import { getToken } from '../../utils/isLoggedIn'
import { ProfileTag } from '../../components/ARP/ProfileTag'
import { AthletePhoto } from '../../components/athlete/AthletePhoto'
import { goToPage, showModal } from '../../actions/modalActions'
import { ModalTypes } from '../../actions/types'
import { LogInOrSignUpIndependent } from '../../components/modal/LogInOrSignUpIndependent'
import { useArp } from '../../data/arpHook'
import { HeroCardAllDivisions } from '../../components/IRP/heroCard/AllDivisions';
import { FinalTime } from '../../components/IRP/body/IRPFinalTime';
import { IRPRaceDetails } from '../../components/IRP/body/IRPRaceDetails';
import { splitTable } from '../../components/IRP/body/styles';
import { getTime, getTimeLabel } from '../../utils/IRPUtil'

const AthleteRacePageComponent = (props) => {
  const { entryId } = useParams('/:entryId')
  const [viewAllSimilar, setViewAll] = useState(false)
  const [showRaceSummary, setShowRaceSummary] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [claimResponse, setClaimResponse] = useState(false)
  const [loginRequired, setLoginRequired] = useState(false)
  const [claimPending, setClaimPending] = useState(false)
  const [unclaiming, setUnclaiming] = useState(false)
  const [unclaimResponse, setUnclaimResponse] = useState(false)
  const [mouseOverClaimButton, setMouseOverClaimButton] = useState(false)

  const { arp, claim, unclaim, follow, unfollow } = useArp(entryId, {
    fetchToken: getToken
  })

  if (!arp) {
    return <Loading centered={true} />
  }

  const {
    athleteCardModel: {
      claimed,
      claimable,
      isLoggedIn,
      unclaimable,
    },
    results = []
  } = arp

  const {
    dispatch,
    isMobile,
    t
  } = props

  if (!claiming && claimResponse && isLoggedIn) {
    if (claimResponse.success) {
      dispatch(goToPage(2))
    } else if (claimResponse.claimIsPending) {
      dispatch(goToPage(3))
      setClaimPending(true)
    } else if (!claimResponse.isStrongMatch) {
      dispatch(goToPage(1))
    } else {
      dispatch(goToPage(4))
    }
    setClaimResponse(undefined)
  }

  if (!unclaiming && unclaimResponse && isLoggedIn) {
    if (unclaimResponse.success) {
      dispatch(goToPage(2))
    } else {
      dispatch(goToPage(3))
    }
    setUnclaimResponse(undefined)
  }

  const claimEntry = async () => {
    if (isLoggedIn) {
      if (claimable && !claiming) {
        setClaiming(true)
        claimEntryModal()

        const token = await getToken()
        const claimResponse = await claim(token, () => {
          setClaiming(false)
        })
        setClaimResponse(claimResponse)
      }
    } else {
      setLoginRequired(true)
    }
  }
  const claimEntryModal = () => {
    const {
      dispatch
    } = props

    dispatch(showModal({
      modalProps: {
        claim,
        currentPage: 0,
        noScroll: true,
        setClaiming
      },
      modalType: ModalTypes.CLAIM_ENTRY,
      pages: 5
    }))
  }

  const unclaimEntry = async () => {
    if (unclaimable && !unclaiming) {
      setUnclaiming(true)

      unclaimEntryModal(async () => {
        const token = await getToken()
        try {
          setUnclaiming(true)
          dispatch(goToPage(1))
          const unclaimConfirmation = await unclaim(token, () => {
            setUnclaiming(false)
          })
          setUnclaimResponse(unclaimConfirmation)

        } catch (err) {
          console.error(err)
          dispatch(goToPage(3))
        }
      })
    }
  }

  const unclaimEntryModal = (
    unclaimWrapper
  ) => {
    const {
      dispatch
    } = props

    dispatch(showModal({
      modalProps: {
        currentPage: 0,
        noScroll: true,
        unclaim: unclaimWrapper,
        setUnclaiming
      },
      modalType: ModalTypes.UNCLAIM_ENTRY,
      pages: 4
    }))
  }

  const getClaimButton = () => {
    const claimAction = claimable ? claimEntry : unclaimable ? unclaimEntry : () => { }
    const claimButtonText =
      claimable ? 'claim your race' :
        claimed ? 'claimed' :
          claimPending ? 'claim requested' : ''
    const enabled = (claimable || unclaimable) && !claimPending
    return (
      <div className={'col-12'} style={styles.claimButtonContainer}>
        <button
          disabled={!enabled}
          onClick={claimAction}
          onMouseOver={() => setMouseOverClaimButton(true)}
          onMouseOut={() => setMouseOverClaimButton(false)}
          style={styles.claimOrFollowButton(claimable, unclaimable, isMobile, mouseOverClaimButton)}
        >{t(claimButtonText).toUpperCase()}</button>
      </div>
    )
  }

  const renderLeftContent = () => {
    return (
      <div>
        <div style={styles.athleteContainer(isMobile)}>
          <AthleteRacePageCard
            athleteCardModel={arp.athleteCardModel}
            follow={follow}
            unfollow={unfollow}
            isMobile={isMobile}
            isPublicProfile={true}
            t={props.t} />
        </div>
        <div style={styles.friendsContainer}>
          <div style={styles.athleteHeader}>{t('Similar Athletes in this Event').toUpperCase()}</div>
          {arp.similarAthletesCardModel.athletes.slice(0, viewAllSimilar ? 8 : 3).map((athlete, index) => {
            return isMobile ? (
              athlete.showAthletePhoto && <div key={index} style={{ marginTop: '14px', display: 'inline-block' }}>
                <AthletePhoto
                  isMember={!!athlete.racerId}
                  photo={athlete.photos}
                  name={athlete.displayName}
                  width={32}
                /></div>) : <ProfileTag key={index} {...athlete} isLoggedIn={arp.isLoggedIn} courseName={arp.coursesCardModel[0].name} t={props.t} />
          })}
          <div style={styles.similarAthletesContainer(isMobile)}>
            <button
              disabled={false}
              onClick={() => setViewAll(!viewAllSimilar)}
              style={{ ...styles.clearButton(isMobile), display: arp.similarAthletesCardModel.athletes.length <= 3 ? 'none' : '' }}
            >{t(viewAllSimilar ? 'View Less' : 'View All')}</button>
          </div>
        </div>
      </div>
    )
  }

  const renderRaceContent = () => {
    const intervals = (results[0] || {
      intervalResults: []
    }).intervalResults
    const intervalFull = intervals
      .filter(x => x.intervalName === 'Full Course')[0] || {
      divisionRankings: []
    }
    const isUpcoming = results.length === 0 || ~window.location.search.indexOf('upcoming')
    // TODO: when we implement a course/reg choice toggle change the index for arp.courses
    return (
      <div style={styles.raceContentContainer(isMobile)}>
        <div style={styles.raceSelectionContainer(isMobile)}>
          {arp.coursesCardModel.map((course, index) => (
            <div key={'course_' + course.courseId} style={index === 0 ? styles.raceSelected : styles.raceDeselected}>{course.name}</div>
          ))}
        </div>
        <div style={styles.betaBar}>
          <span style={styles.betaBadge}>BETA</span>
        </div>
        {isMobile && <div>
          <hr style={{ margin: '18px 0px 8px' }} />
          <div className={isMobile ? 'col-12' : 'col-5'}
            onClick={() => setShowRaceSummary(!showRaceSummary)}
            style={{ cursor: 'pointer', overflow: 'hidden', height: showRaceSummary ? 175 : 25 }}>
            <div style={{ marginTop: '-2px', fontFamily: 'ProximaNovaBold', fontSize: '23px', float: 'right' }}>{showRaceSummary ? '-' : '+'}</div>
            <RaceSummary isUpcoming={isUpcoming} raceCardModel={arp.raceCardModel} />
          </div>
          <hr style={{ margin: '6px 0px 28px' }} />
        </div>}
        <div className='row'>
          <div className={isMobile ? 'col-12' : 'col-7'} style={styles.countdownContainer}>
            {isUpcoming
              ? <Countdown startDateTime={arp.raceCardModel.startDateTime} irp={arp.raceCardModel.irp} isMobile={isMobile} t={props.t} />
              : <div>
                <FinalTime
                  time={getTime({
                    timeWithPenalties: {
                      timeInMillis: Math.round(intervalFull.elapsedTime * 1000)
                    }
                  })}
                  timeLabel={getTimeLabel({}, undefined, t)}
                  pace={'???'}
                  paceUnits={'???'}
                  showPace={true}
                  isMobile={isMobile}
                  latestIntervalName={'???'}
                  t={t}
                />
                <HeroCardAllDivisions
                  title={t('Ranking')}
                  rankings={
                    intervalFull
                      .divisionRankings
                      .map(x => ({
                        rank: x.place,
                        total: '???',
                        label: x.divisionName,
                        type: x.divisionType
                      }))}
                  isUnranked={false}
                  t={t}
                />
              </div>
            }
          </div>
          <div className={isMobile ? 'col-12' : 'col-5'}>
            {!isMobile && <RaceSummary isUpcoming={results.length === 0} raceCardModel={arp.raceCardModel} t={props.t} />}
            {isUpcoming
              ? <div>
                <Goals raceCardModel={arp.raceCardModel} claimed={arp.claimed} isMobile={isMobile} t={props.t} />
                {claimable && <div style={styles.zeroStateBlurb}>
                  {t('Claim this race page to set goals!')}
                </div>}
                {getClaimButton()}
              </div>
              : getClaimButton()
            }
          </div>
        </div>
        {isUpcoming
          ? null
          : <div className='row'>
              <div className='col-12' style={{ marginTop: isMobile ? 30 : 0 }}>
                <h3 style={splitTable.title}>
                  {t('Race Splits')}
                </h3>
                <IRPRaceDetails
                  entryStatus={'CONF'}
                  intervals={intervals.map(x => {
                    return {
                      intervalFull: x.intervalName === 'Full Course',
                      intervalName: x.intervalName,
                      brackets: x.divisionRankings.map(y => ({
                        rank: y.place,
                        bracketType: y.divisionType,
                        totalAthletes: '???'
                      })),
                      // pace: '???',
                      timeWithPenalties: {
                        timeInMillis: x.elapsedTime * 1000
                      }
                    }
                  })}
                  isLoading={false}
                  isMobile={isMobile}
                  t={t}
                />
              </div>
            </div>
          }
      </div>
    )
  }



  const renderTopContent = () => {
    const dateTime = moment(parseInt(arp.eventCardModel.startDateTime.timeInMilliseconds)).tz(arp.eventCardModel.startDateTime.timeZone)
    const formattedDate = dateTime.format('LL')
    return (
      <div className={'container'} style={styles.mainInfoContainer}>

        <div style={styles.outerInfoContainer(isMobile)}>
          <ChevronLeftIcon style={styles.chevronLeft(isMobile)} />
          <div style={styles.more}>
            <DotDotDot />
          </div>
          <div style={styles.innerInfoContainer}>
            <div style={styles.eventName(isMobile)}>
              {arp.eventCardModel.name}
            </div>
            <div className={'row'}>
              <div style={styles.eventDate}>
                {`${formattedDate}`}
              </div>
              <div style={styles.eventLocation}>
                {`${arp.eventCardModel.location.city}, ${arp.eventCardModel.location.region}, ${arp.eventCardModel.location.country}`}
              </div>
            </div>
          </div>
        </div>
        <div style={styles.buttonContainer(isMobile)}>
          <div></div>
          <button
            onClick={() => { }}
            style={styles.registerButton(isMobile)}
          >
            {t('Register')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <PageTemplate
      {...props}
      headerIsFixed={true}
      paddingTop={0}
      unifiedSearchMode={true}
    >
      <div>
        <div style={{ overflow: 'hidden' }}>
          <div style={styles.topContainer}>
            <img src='http://placekitten.com/1920/1080' alt={''} />
          </div>
        </div>
        <div className='container'>
          {renderTopContent()}
        </div>
      </div>
      <div className='container' style={styles.bodyContainer(isMobile)}>
        <div className='row'>
          <div className={isMobile ? 'col-12' : 'col-4'}>
            {renderLeftContent()}
          </div>
          <div className={isMobile ? 'col-12' : 'col-8'}>
            {loginRequired && <LogInOrSignUpIndependent
              closeAction={() => setLoginRequired(false)}
              open={loginRequired}
              isMobile={isMobile}
              t={t}
            />
            }
            {renderRaceContent()}
          </div>
        </div>
      </div>
    </PageTemplate>
  )
}

const mapStateToProps = () => ({
  dispatch: undefined
})

export const AthleteRacePage = connect(mapStateToProps)(withTranslation()(AthleteRacePageComponent))
