import { EventResultsState } from '../model/eventResultsState';
import { ResultActionTypes } from '../resultsClient/types';

export default (state = EventResultsState, action) => {
  switch (action.type) {
    case ResultActionTypes.GET_EVENT_RESULTS_ERROR:
      return {
        ...state,
        errorMessage: 'Failed to get event results',
        errorDetail: action.error,
        isLoading: false
      };
    case ResultActionTypes.GET_EVENT_RESULTS:
      return {
        ...state,
        errorMessage: '',
        errorDetail: undefined,
        isLoading: true,
        wasStopped: false,
        args: action.args
      };
    case ResultActionTypes.GET_EVENT_RESULTS_STOP:
      return {
        ...state,
        data: [],
        isLoading: false,
        errorDetail: undefined,
        wasStopped: true
      };
    case ResultActionTypes.GET_EVENT_RESULTS_UPDATE:
      return {
        ...state,
        data: action.results,
        isLoading: false,
        errorMessage: '',
        errorDetail: undefined,
        wasStopped: false
      };
    default:
      return state;
  }
}
