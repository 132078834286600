import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
// import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import locales from '../locales'
//TODO Modularize the languages

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    keySeparator: '#',
    nsSeparator: '$',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
      defaultTransParent: 'div'
    },
    resources: {
      en: {
        translation: locales.en
      },
      es: {
        translation: locales.es
      },
      fr: {
        translation: locales.fr
      },
      nl: {
        translation: locales.nl
      },
      ru: {
        translation: locales.ru
      },
    },
    backend: {
      backends: [
        // LocalStorageBackend,  // primary
        XHR                   // fallback
      ],
      backendOptions: [
        {
          // expiration
          expirationTime: 7*24*60*60*1000,
        },
        {
          loadPath: '../../locales/{{lng}}.json',
        }
      ]
    }
  });

export default i18next;
