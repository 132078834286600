import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class RaceResultTitle extends Component {
  static propTypes = {
    year: PropTypes.string.isRequired
  };

  render() {
    return (
      <div style={{height: 35,
        marginLeft: -15,
        backgroundColor: '#FAFAFA',
        padding: '6px 20px',
        marginBottom: 20
      }}>
        <span style={{
          color: '#4A4A4A',
          fontFamily: 'ProximaNovaBold',
          fontSize: 14,
          lineHeight: '17px'
        }}>{this.props.year}</span>
      </div>
    );
  }
}

