import React, {Component} from 'react';
import {compose} from 'lodash/fp';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import ClearIcon from 'react-icons/lib/fa/times-circle';
import Person from 'react-icons/lib/md/person';
import PinIcon from 'react-icons/lib/md/place';
import SearchIcon from 'react-icons/lib/md/search';
import Slider from 'rc-slider';

import FilterControls from './FilterControls';
import {Loading} from '../../components/shared/Loading';
import MobileFilterHeader from './MobileFilterHeader';
import {isLoggedIn} from '../../utils/isLoggedIn';
import {detectLocation} from '../../data/geocode';
import {configs} from '../../configs';
import {_filterTab} from './styles';
import {GeoSuggestInput} from '../../components/shared/GeoSuggestInput';
import {getFullDisplayLocation} from '../../utils/location';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters'
import { EVENT_SEARCH, PROFILE_SEARCH } from '../../data/SearchFilters';
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';


export const {googleApiKey} = configs;
const FAQUrl = 'https://athlinks.zendesk.com/hc/en-us/articles/360015544834'

const mapStreamIds = {
  'event' : EVENT_SEARCH,
  'athlete' : PROFILE_SEARCH
};

class LocationFilter extends Component {
  constructor(props) {
    super(props);

    this.submitFilter = this.submitFilter.bind(this)

    this.location = defaultFilterData.filters.location;
    this.term = defaultFilterData.filters.location;
    this.withinRange = defaultFilterData.filters.withinRange;
    this.findingLocaiton = false;
    this.locationError = '';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.values && nextProps.values) {
      const locationFilter = nextProps.values.locationFilter || {
        value: {
          location: defaultFilterData.filters.location,
          range: defaultFilterData.filters.withinRange
        }
      };
      this.location = locationFilter.value.location;
      this.term = locationFilter.value.location;
      this.withinRange = locationFilter.value.range;
    }
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const { activeContext, values, history } = this.props;
    const filters = {
      ...values,
      locationFilter: {
        enabled: !!this.location,
        value: {
          location: this.location,
          range: this.location.length ? this.withinRange : defaultFilterData.filters.withinRange
        }
      }
    };
    setFilterValues(mapStreamIds[activeContext], filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  customHandle = ({index, ref, offset}) => {
    ref(this);
    return <div
      style={_filterTab.handle(offset)}
      key={index}
    />
  };

  render() {
    const {
      isMobile,
      onClose,
      athlete = {},
      t
    } = this.props;

    const { athlete: athleteData = {} } = athlete;
    const profileLocation = getFullDisplayLocation(
      athleteData.City,
      athleteData.StateProvAbbrev,
      athleteData.CountryID
    );

    const currentLocationText = this.locationError === '' ? t('Use Current Location')
      : <span>
          {t('You will need to unblock Athlinks from allowing access to your location in your')}
          <a target='_blank' rel="noopener noreferrer" href={FAQUrl}> {t(`browser's settings`)}</a>
        </span>
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.raceFilterWrap(isMobile)}>
          {
            isMobile && <MobileFilterHeader
              title={t('Location')}
              onClose={onClose}
            />
          }
          <div style={{ padding: 10 }}>
            {/* Location input */}
              <div style={_filterTab.locationSearchFieldWrap}>
                <SearchIcon size={25} style={_filterTab.searchIcon} />
                <div style={_filterTab.geoSubmitWrapper}>
                  <GeoSuggestInput
                    inputProps={{
                      onKeyDown: (event) => {
                        if( event.key === 'Enter' ) {
                          this.submitFilter()
                        }
                      }
                    }}
                    term={this.term}
                    width='100%'
                    placeholder={t('Enter Location')}
                    onChange={({target: {value}}) => {
                      this.location = value;
                      this.term = value;
                      this.forceUpdate()
                    }}
                    onPicked={(location) => {
                      this.location = location.description
                      this.term = location.description
                      this.forceUpdate()
                    }}
                    useCurrentLocationOption={false}
                    detectCurrentLocation={false}
                    onPickedCurrentLocation={() => null}
                    googleApiKey={googleApiKey}
                    typeaheadProps={{
                      inputStyle: _filterTab.locationInputField,
                      useMaterialUi: false,
                      label: '',
                      dropdownTop: 38
                    }}
                  />
                </div>
                <ClearIcon
                  size={25}
                  style={_filterTab.clearIcon(!!this.location.length)}
                  onClick={() => {
                    this.term = defaultFilterData.filters.location
                    this.location = defaultFilterData.filters.location
                    this.forceUpdate()
                  }}/>
              </div>
            {/* Current Location */}
            <div key='location' style={_filterTab.locationRowWrap(this.locationError !== '')}>
              <PinIcon size={25} style={_filterTab.locationRowIcon} />
              <button
                onClick={() => {
                  this.findingLocaiton = true
                  this.forceUpdate()
                  detectLocation(navigator.geolocation, googleApiKey)
                    .then((res) => {
                      this.location = res.label
                      this.term = res.label
                      this.findingLocaiton = false
                      this.locationError = ''
                      this.forceUpdate()
                    })
                    .catch((error) => {
                      this.locationError = error
                      this.findingLocaiton = false
                      this.forceUpdate()
                    })
                }}
                style={_filterTab.locationRowButton}>
                {currentLocationText}
                {
                  this.findingLocaiton &&
                  <div style={_filterTab.locationSpinner}>
                    <Loading noTimeout={true} />
                  </div>
                }
              </button>
            </div>
            {/* Profile Location */}
            { isLoggedIn() && <div key='current' style={_filterTab.locationRowWrap(false)}>
              <Person size={25} style={_filterTab.locationRowIcon} />
                <button
                  style={_filterTab.locationRowButton}
                  onClick={() => {
                    this.location = profileLocation
                    this.term = profileLocation
                    this.forceUpdate()
                  }}
                >
                  {t('Use Profile Location')} - {profileLocation}
                </button>
              </div>
            }
            {/* Range Wrap */}
            <div key="range" style={_filterTab.rangeWrap}>
              <div style={_filterTab.rangeData}>
                <div style={_filterTab.rangeTitle}>{t('Change Distance Radius')}</div>
                <div style={_filterTab.rangeValue}>
                  {
                    this.location.length
                    ? t('{{distance}} Miles', {distance: this.withinRange})
                    : t('No Location Set')
                  }
                </div>
              </div>
            </div>
            {
              <div style={_filterTab.sliderWrap}>
                <Slider
                  min={0}
                  max={100}
                  onChange={(value) => {
                    this.withinRange = value
                    this.forceUpdate()
                  }}
                  disabled={!this.location.length}
                  value={this.withinRange}
                  handle={this.customHandle}
                />
              </div>
            }

          </div>
        <FilterControls
          onSave={this.submitFilter}
          onReset={() => {
            this.term = defaultFilterData.filters.location
            this.location = defaultFilterData.filters.location
            this.withinRange = defaultFilterData.filters.withinRange
            this.forceUpdate()
          }}
        />
      </div>
    )
  }
};

export default compose(
  withTranslation(),
  connectStream({
    values: ({activeContext}) => getFilterValues(mapStreamIds[activeContext])
  }),
  withRouter,
)(LocationFilter);
