import { useEffect, useState } from 'react'
import { updateClaimPending, updateUnclaimingPending } from './rules/claiming'
import { updateFollowPending, updateUnfollowingPending } from './rules/following'
import { logInfo } from './Logging'
import { claimARP } from './claimArp'
import { unclaimARP } from './unclaimArp'
import { followAthlete } from './followAthlete'
import { unfollowAthlete } from './unfollowAthlete'
import { getARP, getArpResult } from './getARP'
import { asyncWSRequest } from './asyncWSRequest'
import { getArpWSClient } from './getArpWSClient'

export const useArp = (
  entryId,
  options = {}
) => {
  const {
    token,
    fetchToken
  } = options

  const [arp, setARP] =
    useState(undefined)

  useEffect(() => {
    let isSubscribed = true
    let socket = undefined

    const startARPClient = async (
      aToken
    ) => {
      if (isSubscribed) {
        const newArp = {
          ...await getARP(entryId, aToken),
          results: await getArpResult(entryId)
        }
        setARP(newArp)
        if (!socket && newArp.stream) {
          logInfo('socket', socket, 'newARP.stream', newArp.stream)
          socket = getArpWSClient({
            setARP,
            entryId,
            token: aToken
          })
        }
      }
    }

    if (fetchToken) {
      fetchToken().then(startARPClient)
    } else {
      startARPClient(token)
    }

    return () => isSubscribed = false
  }, [entryId, fetchToken, token])

  const asyncWSBase = asyncWSRequest(
    {
      entryId,
      arp,
      setARP
    }
  )

  const claim = asyncWSBase(
    'athleteCardModel.claimed',
    updateClaimPending,
    claimARP
  )

  const unclaim = asyncWSBase(
    'athleteCardModel.claimed',
    updateUnclaimingPending,
    unclaimARP
  )

  const follow = asyncWSBase(
    'athleteCardModel.following',
    updateFollowPending,
    followAthlete
  )

  const unfollow = asyncWSBase(
    'athleteCardModel.following',
    updateUnfollowingPending,
    unfollowAthlete
  )

  return {
    arp,
    claim,
    unclaim,
    follow,
    unfollow
  }
}
