import {combineReducers} from 'redux'
import eventMetadata from './eventMetadataReducer'
import eventResults from './eventResultsReducer'
import eventResultsFilters from './eventResultsFiltersReducer'
import eventSummarySearch from './eventSummarySearchReducer'
import individualResult from './individualResultReducer'
import media from './mediaReducer'
import modal from './modalReducer'
import print from './printReducer'
import search from './searchReducer'
import userPrefs from './userPrefsReducer'
import mapTracking from './mapTrackingReducer'
import { alaskaApi } from '../api/alaskaApi'
import { reigniteApi } from '../api/reigniteApi'
import { resultsApi } from '../api/resultsApi'
import reigniteSseReducer, { reigniteSseSlice } from './reigniteSseSlice'
import irpResultReducer, { irpResultSlice } from './irpResultSlice'
import { finisherCertificateApi } from '../api/finisherCertificateApi'

const rootReducer = combineReducers({
  eventMetadata,
  eventResults,
  eventResultsFilters,
  eventSummarySearch,
  individualResult,
  media,
  modal,
  print,
  search,
  userPrefs,
  mapTracking,
  [alaskaApi.reducerPath]: alaskaApi.reducer,
  [finisherCertificateApi.reducerPath]: finisherCertificateApi.reducer,
  [reigniteApi.reducerPath]: reigniteApi.reducer,
  [resultsApi.reducerPath]: resultsApi.reducer,
  [reigniteSseSlice.name]: reigniteSseReducer,
  [irpResultSlice.name]: irpResultReducer
})

export default rootReducer
