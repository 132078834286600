import React, { Component } from 'react';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { styles } from './styles';

/**
 * @deprecated use components/shared/Pager.js instead
 */
export class Pager extends Component {
  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
    this._getButton = this._getButton.bind(this);
    this._getNumberButton = this._getNumberButton.bind(this);
  }

  _onClick(e) {
    if (e.target.value !== this.props.pageNumber) {
      if (this.props.onPage) {
        this.props.onPage(e.target.value);
      }
    }
  }

  _getButton(pageParam, text, buttonStyle, key) {
    return (
      <div key={key}
           style={styles.buttonContainer}>
        <button style={buttonStyle}
                onClick={(target) => {
                  trackGoogleEvent('Results','Click','Pagination');
                  this._onClick(target);
                }}
                value={pageParam}>
          {text}
        </button>
      </div>
    );
  }

  _getNumberButton(num, text) {
    return this._getButton(
      num,
      text || num + 1,
      this.props.pageNumber === num
        ? styles.pageButtonSelected
        : styles.pageButton,
      num);
  }

  render() {

    const pageCount = Math.ceil(this.props.total / this.props.pageSize) || 0;
    const pageNumber = parseInt(this.props.pageNumber || 0);

    const numToArr = (num) => {
      const arr = new Array(num);
      for (var index = 0; index < num; index++) {
        arr[index] = index;
      }
      return arr;
    };

    return (
      <div>
        {
          pageNumber > 0 &&
          this._getButton(pageNumber - 1, '<', styles.pageButtonArrow, 'left')
        }
        {
          numToArr(pageCount)
            .map((num) => {
              if (pageNumber < 3) {
                if (num < 3 || num === pageCount - 1) {
                  return this._getNumberButton(num);
                }
                else if (num === 3) {
                  return this._getNumberButton(num, '...');
                }
              }
              else if (pageCount - pageNumber < 4) {
                if (num === 0 || num > pageCount - 4) {
                  return this._getNumberButton(num);
                }
                else if (num === pageCount - 4) {
                  return this._getNumberButton(num, '...');
                }
              }
              else {
                if (num === 0
                  || num === pageCount - 1
                  || Math.abs(pageNumber - num) < 2) {
                  return this._getNumberButton(num);
                }
                else if (Math.abs(pageNumber - num) === 2) {
                  return this._getNumberButton(num, '...');
                }
              }

              return null;
            })
        }
        {
          pageNumber < pageCount - 1 &&
          this._getButton(pageNumber + 1, '>', styles.pageButtonArrow, 'right')
        }
        <div key='clear' style={{clear: 'both'}} />
      </div>
    );
  }
}
