import {Component} from 'react';
import PropTypes from 'prop-types';
import {Sponsors} from '../../components/sponsors/Sponsors';
import _ from 'lodash';
import {
  eventToDateMap, getDateOptions, adjustRaces
} from '../../utils/resultsHelpers';
import {EventResultsSplitter} from '../../components/eventResultsSplitter/EventResultsSplitter';
import {EventHero} from '../../components/heroImage/EventHero';
import {Loading} from '../../components/shared/Loading';
import {PageTemplate} from '../home/PageTemplate';
import {imageStyles} from '../../shared/styles';
import {styles} from './styles';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import {eventBus} from '../../data/Streams';
import moment from 'moment';

import {isInPast} from '../../shared/util';
import {configs} from '../../configs';
import {fetchAthleteRaces} from '../../data/AthleteStreams';
import {hasResultInEventCourse} from '../../utils/ClaimUtil';
import {getResultsUrl} from '../../utils/resultsHelpers';
import { setNotifications, WARNING } from '../../data/NotificationStreams';
import SearchStatus, { SearchState } from '../../components/shared/SearchStatus';
import {convertCalendarTime} from '@eventops/athlinks-lib-units';
import { EventResultsSearch } from './EventResultsSearch';

function handleClick(e) {
  if (!e.target.classList.contains('suggestions') &&
    !e.target.classList.contains('searchInput')) {
    const nodes = document.getElementsByClassName('suggestions');
    [].slice.call(nodes).forEach((_) => _.style.display = 'none');
  }
}

export class EventResultsComponent extends Component {
  static propTypes = {
    master: PropTypes.object,
    races: PropTypes.object,
    resize: PropTypes.object,
    filter: PropTypes.object,
    paging: PropTypes.object,
    isMobile: PropTypes.bool,
    divisions: PropTypes.array,
    splits: PropTypes.array,
    activeToggle: PropTypes.string,
    selectedEventStatus: PropTypes.object,
    isEventLive: PropTypes.bool,
    isFetchingEventResults: PropTypes.bool,
    timer: PropTypes.object,
    eventId: PropTypes.number,
    following: PropTypes.object,
    page: PropTypes.number,
    extra: PropTypes.object,
    athleteRaces: PropTypes.object,
    t: PropTypes.func,
    eventMetadata: PropTypes.object,
    eventResults: PropTypes.object,
    eventResultsFilters: PropTypes.object,
    notifications: PropTypes.array,
    history: PropTypes.object,
    fetchEventResultsError: PropTypes.bool,
    refetch: PropTypes.func,
  };

  static defaultProps = {
    master: {isLoading: true},
    races: {isLoading: true},
    resize: {showTables: false},
    options: {isLoading: true},
    results: null,
    paging: {pageSize: configs.numResultsPerPage},
    filters: {
      courseOption: null,
      dateOption: null,
      isSearch: false
    },
    isMobile: false,
    isFetchingEventResults: false,
    divisions: [],
    splits: [],
    activeToggle: 'all',
    selectedEventStatus: {isLoading: true},
    page: 0,
    extra: {sponsorLogos: []},
    eventMetadata: {isLoading: true},
    eventResults: {isLoading: true},
    notifications: [],
    fetchEventResultsError: false,
  };

  state = {
    errors: {},
    filteredData: [],
    isLive: false,
    searchTerm: '',
  };

  //NOTE: This method is scheduled to be depricated. Try using componentDidUpdate or changing the component to a controlled component.
  UNSAFE_componentWillReceiveProps({masterId, eventId, courseId, athleteRaces}) {
    // we call this so we can tell if the user has a result for this event course
    if (!this.props.athleteRaces && (athleteRaces || {}).fetching) {
      fetchAthleteRaces(getTokenRacerId());
    }
    // figure out if the user has a result in this event course
    if (athleteRaces && !athleteRaces.fetching && !_.isEmpty(athleteRaces.races) && courseId) {
      if (!_.isEqual(athleteRaces, this.props.athleteRaces) || courseId !== this.props.courseId) {
        this.setState({
          claimedResultInEventCourse: hasResultInEventCourse(athleteRaces.races, courseId)
        });
      }
    }

    const url = window.location.href;
    // TODO: this is super hacky
    if(url.indexOf('/Event/') === -1) {
      this.props.history.replace({pathname: getResultsUrl(masterId, eventId, 0, 0, 0)});
    }
  }

  componentDidUpdate() {
    this.handleErrorsOnUpdate();
  }

  handleErrorsOnUpdate = () => {
    // CAUTION: Because this is called from componentDidUpdate, you must NOT set state unless a value has changed or it will cause an infinite loop.
    // Check for errorMessage and act on it. If you need this to occur before update, try raising state up one level and making this a controlled component.
    Object.keys(this.props).forEach((key)=>{
      const prop = this.props[key] || {};
      const { errors } = this.state;

      if(prop.errorMessage) {
        let setState = false;
        let errorProps = {};
        switch(key) {
          case 'eventMetadata':
            break;
          case 'eventResults':
            if(prop.data.length && !errors[key]) {
              // !errors[key] checks that we have not already logged this warning
              setNotifications([...this.props.notifications, {type: WARNING, message:'Event results did not refresh and may not be current.'}]);
              setState = true;
            } else if(!prop.data.length && !errors[key]) {
              errorProps = {...errorProps, wasStopped: true};
              setState = true;
            }
            break;
          case 'eventResultsFilters':
            if(!errors[key]) {
              setNotifications([...this.props.notifications, {type: WARNING, message:'Filters for this event are currently unavailable.'}]);
              setState = true;
            }
            break;
          default:
            break;
        }

        // Be cautious to use conditionals above that will only set the state when it has changed
        if(setState) {
          this.setState({errors: {...errors,
            [key]: {
              message: prop.errorMessage,
              ...errorProps
            }
          }});
        }
      } else {
        //Clear the error
        const { [key]: deleteKey, ...rest } = errors;
        if(deleteKey)
          this.setState({errors: rest});
      }
    });
  }

  /**
   * Extracts search results from props.
   * @return {*}
   */
  getSearchResults = () => {
    const {query, search: {results}} = this.props;

    return query ? results : null;
  };

  /**
   * Check if component should render future results for selected event
   */
  shouldShowFutureResults = () => {
    const timeZone = _.get(this.props.master, 'event.timeZone', 'US/Eastern');
    const today = moment.tz(timeZone).startOf('day').toDate().getTime();
    const futureEvent =
      this.props.races && this.props.races.races &&
      this.props.races.races.find((race) => {
        const m = moment.tz(race.raceDate, timeZone).startOf('day').toDate().getTime();
        return race.eventId === this.props.eventId
          && today < m;
      });

    return !!futureEvent;
  };

  /**
   * Handles on page change event.
   * @param {int} page
   */
  onPage = (page) => {
    eventBus.publish('er-page', {page});
  };

  /**
   * Handles on course results expand action
   * @param eventCourseId
   */
  onExpand = (eventCourseId) => {
    const {masterId, eventId} = this.props;

    this.props.history.push({pathname: getResultsUrl(masterId, eventId, eventCourseId, 0, 0)});
  };

  /**
   * Check if event is linked to CTLive
   * @return {boolean}
   */
  isCTLive = () => {
    const {master: {event: {ctliveId} = {}} = {}} = this.props;
    return !!ctliveId;
  };

  retry = () => {
    const {
      refetch,
    } = this.props;

    try {
      refetch()
    }
    catch (err) {
      console.warn(err)
    }
  };

  render() {
    const {
      masterId,
      intl,
      noHeaders,
      master,
      page,
      paging,
      races,
      isMobile,
      timer,
      filters,
      widthOffset,
      options,
      selectedEvent = {},
      selectedEventStatus,
      athlete,
      extra: {sponsorLogos = []},
      t,
      eventResults,
      eventMetadata,
      eventId,
      eventResultsFilters,
      athleteRaces = {},
      history,
      isFetchingEventResults,
      fetchEventResultsError,
    } = this.props;

    const {
      claimedResultInEventCourse,
    } = this.state;

    const isCTLive = this.isCTLive();

    if (master.isLoading || races.isLoading || !races.races) {
      const inner = (
        <div className='container'>
          <div style={styles.contentCard(isMobile)}>
            <div style={imageStyles.loadingPosition}>
              <Loading timeoutMessage={t('Still thinking...')}/>
            </div>
          </div>
        </div>
      );

      return noHeaders ? inner : (
        <PageTemplate
          unifiedSearchMode={true}
          paddingTop={0}
          headerIsFixed={true}>
        </PageTemplate>
      );
    }

    const map = eventToDateMap(races.races || []);

    const maybeWrapInHeaders = (inner) => (!noHeaders ? (
        <PageTemplate
          unifiedSearchMode={true}
          headerIsFixed={true}
          isMobile={isMobile}
          paddingTop={0}>

          <div
            onClick={handleClick}
            style={styles.overallStyle(isMobile)}
            itemScope itemType="http://schema.org/DataFeed">

            <EventHero
              tab='results'
              excludeSearch={true}
              isMobile={isMobile}
              isLive={this.state.isLive}
              eventName={master.event.masterName}
              eventDate={map[filters.dateOption]}
              eventId={filters.dateOption}
              master={master.event}
              masterEventId={master.event.masterId}
              t={t}
            />

            {inner}

          </div>

        </PageTemplate>
      ) : (
        inner
      )
    );

    const eventOptions = getDateOptions(adjustRaces(races.races), intl);
    const isPastEvent = isInPast(new Date(this.props.eventId && master.event.startEpoch));
    const isFuture = selectedEvent?.event
      ? convertCalendarTime({
        timeInMillis: selectedEvent.event.startEpoch * 1000,
        timeZone: selectedEvent.event.timeZone
      }).temporality
      : 'past'
    const isFutureEvent = selectedEventStatus.activatesIn
      ? selectedEventStatus.activatesIn > 0 : isFuture === 'future';


    const eventInfo = selectedEvent?.event || {};
    const eventCity = _.get(eventInfo, 'city', '');
    const eventState = _.get(eventInfo, 'region', '');
    const eventCountry = _.get(eventInfo, 'countryId', '');

    const eventCourseId = eventResultsFilters.course || 0;
    const divisionId = eventResultsFilters.division || 0;

    //
    // This is a complete hack, but there is a race condition in results client where
    // it attempts to subscribe before finishing the prior unsub.
    //
    let searchState;

    if (eventResults.wasStopped) {
      searchState = SearchState.SEARCH_FAILED;
    } else if (eventResults.isLoading || (eventResults.errorDetail && eventResults.errorDetail.startsWith('Duplicate'))) {
      searchState = SearchState.SEARCHING
    } else if (eventResults.errorMessage || fetchEventResultsError) {
      searchState = SearchState.RESULTS_FAILED
    } else if (eventResults.data.length === 0 && !isFetchingEventResults) {
      searchState = SearchState.NO_RESULTS
    } else {
      searchState = null
    }

    return (
      maybeWrapInHeaders(
        <div>
          <div style={styles.contentCard(isMobile)}>
            <div className='row'>
              <div className='col-12'>
                <EventResultsSearch
                  eventId={eventId}
                  eventMetadata={eventMetadata}
                  masterId={master.event.masterId}
                  isMobile={isMobile}
                  isCTLive={isCTLive}
                  eventOptions={eventOptions}
                  from={page}
                  limit={paging.pageSize}
                  onPage={this.onPage}
                />

                {
                  searchState ?
                    <SearchStatus
                      searchState={searchState}
                      retrySearch={this.retry}
                      t={t}
                      searchCopy={{
                        [SearchState.SEARCHING]:
                          <div style={{width: '100%', marginTop: 25, height: 70}}>
                            <Loading noTimeout={true} />
                          </div>,
                        [SearchState.NO_RESULTS]: isFutureEvent
                          ? t('This race hasn\'t started yet, but check back for results once the race begins.')
                          :
                          <div>
                            {t('No results for this Race & Division combination.')}
                            <br />
                            <button
                              style={{...styles.errorButtonStyle, marginTop: '2em'}}
                              onClick={() => {
                                history.push(`/event/${masterId}/results/Event/${eventId}/Results`)
                              }}
                            >{t('Go Back')}</button>
                          </div>,
                          
                        [SearchState.RESULTS_FAILED]: t('Welp, it appears we lost a connection while retrieving results')
                      }}
                      altStyle={true}
                    />
                    :
                    <EventResultsSplitter
                      results={eventResults}
                      meta={eventMetadata}
                      athlete={athlete}
                      eventCity={eventCity}
                      eventCountry={eventCountry}
                      eventCourseId={eventCourseId}
                      divisionId={divisionId}
                      eventId={eventId}
                      eventOptions={eventOptions}
                      eventState={eventState}
                      from={parseInt(page)}
                      hasEventResults={this.props.hasEventResults}
                      hasResultInEventCourse={claimedResultInEventCourse}
                      isCTLive={isCTLive}
                      isEventLive={this.props.isEventLive}
                      isMobile={isMobile}
                      isPastEvent={isPastEvent}
                      onExpand={this.onExpand}
                      onPage={this.onPage}
                      options={options}
                      master={master}
                      masterId={masterId}
                      paging={paging}
                      races={races.races}
                      startEpoch={master.event.startEpoch}
                      style={styles.unofficialStyle}
                      widthOffset={widthOffset}
                      athleteRaces={athleteRaces.races}
                    />
                }
                <TimerInfo
                  timer={timer}
                  eventId={eventId}
                  isCTLive={isCTLive}
                  affiliateID={master.event.ctliveId}
                  isMobile={isMobile}
                  t={t}
                />
              </div>
            </div>
          </div>
          {
            sponsorLogos.length > 0 &&
            <Sponsors
              logos={sponsorLogos}
              isMobile={isMobile}
              t={t}
            />
          }
        </div>
      )
    );
  }
}

export const TimerInfo = ({timer, eventId, isCTLive, affiliateID, isMobile, t}) => {

  const getTimerInfo = () => {
    const {displayName, displayContactInfo, name, email} = timer;
    if (displayName) {
      return t(
        'Results questions? Contact {{name}} at {{contact}}',
        {name: displayName, contact: displayContactInfo}
      );
    } else {
      return t(
        'Results questions? Contact {{name}} at {{contact}}',
        {name, contact: email}
      );
    }
  };

  const eventIdString = t('Event- {{eventId}}', {eventId});
  const affiliateIdString = isCTLive && affiliateID ? ' | ' + t('Affiliate- {{affiliateId}}', {affiliateId: affiliateID}) : '';

  return (
    <div style={styles.textStyle(isMobile)}>
      {
        timer &&
        <div dangerouslySetInnerHTML={{__html: getTimerInfo()}} />
      }
      {
        <div style={styles.idContainer}>
          {`${eventIdString}${affiliateIdString}`}
        </div>
      }
    </div>
  );
};

TimerInfo.propTypes = {
  timer: PropTypes.object,
  eventId: PropTypes.number,
  affiliateID: PropTypes.number,
  isCTLive: PropTypes.bool,
};
