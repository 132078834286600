export const claimStyles = {
  desktop: {
    bodyStyle: {
      color: '#000',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '0 40px 16px 40px',
    },
    arrow: {
      display: 'inline-block',
      margin: 'auto 48px',
      height: '25px',
      verticalAlign: 'middle',
    },
    genderYear: {
      width: '20%',
      float: 'right',
      fontSize: 14
    },
    name: {
      display: 'inline-block',
      fontSize: '14px',
      paddingLeft: 10
    },
    nameCompare: {
      textAlign: 'left',
      width: '50%',
      color: '#9B9B9B',
      marginTop: 30,
      background: '#f4f4f4'
    },
    actionContainer: {

    },
    actionButtonContainer: {
      padding: '20px 40px 40px 70px',
      textTransform: 'capitalize',
      display: 'inline-flex'
    },
    actionButton: {
      minWidth: 100,
      height: 40,
      textTransform: 'capitalize',
      margin: '0px 20px 0px 0px',
      color: '#16A9E1',
      backgroundColor: '#fff',
      border: '2px solid #16A9E1',
      paddingBottom: 10,
      fontSize: 17,
      marginRight: 6
    },
    verify: {
      fontSize: 12,
      marginTop: 30,
      marginBottom: 40,
      fontStyle: 'italic'
    }
  },
  mobile: {
    bodyStyle: {
      color: '#000',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '0 40px 16px 40px',
    },
    arrow: {
      display: 'inline-block',
      margin: '12px',
      height: '24px',
      verticalAlign: 'middle',
      transform: 'rotate(90deg)',
    },
    genderYear: {
      width: '20%',
      float: 'right',
      fontSize: 14
    },
    name: {
      display: 'block',
      fontSize: '14px',
      paddingLeft: 10
    },
    nameCompare: {
      textAlign: 'left',
      width: '50%',
      color: '#9B9B9B',
      marginTop: 30,
      background: '#f4f4f4'
    },
    actionContainer: {
      borderTop: '1px solid #ddd',
      backgroundColor: '#f4f4f4'
    },
    actionButtonContainer: {
      textAlign: 'center',
      padding: '16px',
      textTransform: 'capitalize',
      display: 'inline-flex'
    },
    actionButton: {
      minWidth: 100,
      height: 40,
      textTransform: 'capitalize',
      margin: '0px 20px 0px 0px',
      color: '#16A9E1',
      backgroundColor: '#fff',
      border: '2px solid #33aade',
      paddingBottom: 10,
      marginRight: 6
    },
    verify: {
      fontSize: 12,
      marginTop: 30,
      marginBottom: 40,
      fontStyle: 'italic'
    }
  }
};

