import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import { setBirthYearValueForUnclaimedSearch } from '../../data/SearchFilters';

const StyledBadge = withStyles({
  badge: {
    borderRadius: 12,
    color: '#FFFFFF',
    fontFamily: 'ProximaNovaBold',
    fontSize: 14,
    height: 24,
    left: 12,
    top: 12,
    width: 24,
  },
  colorPrimary: {
    backgroundColor: '#BC3A3C'
  }
})(Badge)

export const UnclaimedResultsAlert = (
  {
    searchTerm,
    unclaimedResultsCount,
    isMobile,
    birthYear,
    t
  }) => (
  <div
    style={{display: 'flex', alignItems: 'center'}}
    className='row'>
    <div className={isMobile ? 'col-12' : 'col-9 col-md-8'}>
      <StyledBadge
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        badgeContent={unclaimedResultsCount < 10 ? unclaimedResultsCount : '!'}
        color={'primary'}
        overlap={'circle'}
      >
        <div
          style={{
            color: '#4A4A4A',
            fontFamily: 'ProximaNovaRegular',
            fontSize: isMobile ? 18 : 21,
            padding: '0 0 0 40px',
          }}
        >
          {t('We found {{count}} new result that might be yours!', {count: unclaimedResultsCount})}
        </div>
      </StyledBadge>
    </div>
    <div
      className={isMobile ? 'col-12' : 'col-3 col-md-4'}
      style={{
        paddingTop: isMobile ? 20 : '',
        textAlign: isMobile ? 'center' : 'right'
      }}
    >
      <Link
        onClick={() => setBirthYearValueForUnclaimedSearch(birthYear)}
        to={`/search/unclaimed/?term=${encodeURIComponent(searchTerm)}`}
        style={{
          backgroundColor: '#16A9E1',
          borderRadius: 2,
          color: '#FFFFFF',
          display: 'inline-block',
          fontFamily: 'ProximaNovaRegular',
          fontSize: isMobile ? 18 : 16,
          height: isMobile ? 35 : '',
          lineHeight: 1.9,
          marginLeft: 'auto',
          padding: '7px 0',
          textAlign: 'center',
          textTransform: isMobile ? 'capitalize' : 'uppercase',
          width: isMobile ? '85%' : 184
        }}
      >
        {t('Claim Results')}
      </Link>
    </div>
  </div>
);

UnclaimedResultsAlert.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  unclaimedResultsCount: PropTypes.number.isRequired,
  t: PropTypes.func
};
