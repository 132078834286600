import * as log from 'loglevel'

export const LogLevel = log.levels
export const Log = log.getLogger('athlinks-web')

Log.setDefaultLevel(LogLevel.SILENT)

export function setLogLevel(logLevel) {
  Log.setLevel(logLevel)
}
