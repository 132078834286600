import React from 'react';
import { timeStyles } from './styles';

export const HeroCardTime = (props) => {

  const {
    fontSize = props.isMobile ? 38 : 43,
    time,
    text,
  } = props;

  return (
    <div>
      <div style={timeStyles.title}>
        {text}
      </div>
      <div style={{...timeStyles.number, fontSize}} >
        {
          time || '-'
        }
      </div>
    </div>
  );
};
