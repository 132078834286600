import { Theme, makeStyles } from '@material-ui/core';

import { theme } from '../../shared/theme';
import shareContent from '../../utils/shareContent';
import { trackGoogleEvent } from '../../utils/googleEvents';

import { FBShare, ShareIcon } from './icons';
import { FollowButton } from './Follow';

type StylesProps = {
  useFbIcon: boolean;
};

const useStyles = makeStyles<Theme, StylesProps>(({ palette }) => ({
  shareBtnWrapper: ({ useFbIcon }) => ({
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: useFbIcon ? 0 : 8,
  }),
  socialBtn: ({ useFbIcon }) => ({
    position: 'relative',
    display: 'inline-block',
    backgroundColor: palette.common.white,
    borderRadius: 2,
    cursor: 'pointer',
    padding: useFbIcon ? 'none' : '3px 5.5px',
    zIndex: 110,
    border: useFbIcon ? 'none' : `1px solid ${palette.brandColor}`,
  }),
}));

type SocialShareProps = {
  buttonsProps: any;
  followButton: boolean;
  isMobile: boolean;
  useFbIcon?: boolean;
};

const SocialShare = ({
  buttonsProps,
  followButton,
  isMobile,
  useFbIcon = false,
}: SocialShareProps) => {
  const styles = useStyles({ useFbIcon });

  const onClickShare = () => {
    const windowLocation = window.location.href;
    const shareIrpUrl = windowLocation.includes(`/Bib/`)
      ? window.location.href + '?t=' + Date.now()
      : window.location.href;
    trackGoogleEvent('Social', 'Click', 'Facebook');

    shareContent(shareIrpUrl || window.location.href, isMobile);
  };

  return (
    <div>
      {followButton && <Buttons isMobile={isMobile} {...buttonsProps} />}

      <div className={styles.shareBtnWrapper}>
        <div className={styles.socialBtn}>
          {useFbIcon ? (
            <FBShare
              size={40}
              color={theme.palette.facebookColor}
              onClick={onClickShare}
            />
          ) : (
            <ShareIcon
              fill={theme.palette.brandColor}
              height={21}
              onClick={onClickShare}
            />
          )}
        </div>
      </div>
    </div>
  );
};

type ButtonsProps = {
  isLoggedIn: boolean;
  isFriend: boolean;
  athlete: {
    DisplayName: string;
    LogoUrls: string[];
    City: string;
    StateProvAbbrev: string;
    CountryID3: string;
    RacerID: string;
  };
  isMobile: boolean;
  isMe: boolean;
};

const Buttons = ({
  isLoggedIn,
  isFriend,
  athlete: { DisplayName, LogoUrls, City, StateProvAbbrev, CountryID3, RacerID },
  isMobile,
  isMe,
}: ButtonsProps) => {
  if (isMe) {
    return null;
  }

  return (
    <FollowButton
      isMe={false}
      buttonType="rectangle"
      isFriend={isFriend}
      RacerId={RacerID}
      isLoggedIn={isLoggedIn}
      DisplayName={DisplayName}
      Photos={LogoUrls}
      City={City}
      StateProvAbbrev={StateProvAbbrev}
      CountryId3={CountryID3}
      isMobile={isMobile}
    />
  );
};

export { Buttons, SocialShare };
