import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import MobileDetect from 'mobile-detect';
import {withTranslation} from 'react-i18next';

import { Button } from '../button/Button';
import { configs } from '../../configs';
import download from 'downloadjs';
import { FacebookShareMediaIcon } from '../shared/icons';
import { getFinishCertMedia } from '../../utils/MediaUtil';
import { MediaGrid } from '../mediaGrid/MediaGrid';
import shareContent from '../../utils/shareContent';
import { styles } from './body/styles';
import { textStyles } from '../../shared/styles';
import { trackGoogleEvent } from '../../utils/googleEvents';

import _ from 'lodash';

class IndividualPhotoGridComponent extends Component {
  static propTypes = {
    event: PropTypes.object,
    result: PropTypes.object,
    isMobile: PropTypes.bool,
    isLandscape: PropTypes.bool,
    widthOffset: PropTypes.number,
    certificateUrl: PropTypes.string,
    titlePosition: PropTypes.string,
    t: PropTypes.func,
    media: PropTypes.object
  };

  static defaultProps = {
    event: {isLoading: true},
    result: {isLoading: true},
    isMobile: false,
    isLandscape: false,
    widthOffset: 0,
    certificateUrl: '',
    titlePosition: 'top'
  };

  constructor(props) {
    super(props);
    this.photoActionCallback = this.photoActionCallback.bind(this);
    this.facebookShareCallback = this.facebookShareCallback.bind(this);
  }

  photoActionCallback(tile) {
    if(tile.isCertificate) {
      trackGoogleEvent('Media', 'Click', 'Certificate Download');
    }
    else {
      trackGoogleEvent('Media', 'Click', 'Photo Download');
    }

    let url;
    if (tile.type === 'photo') {
      switch ( this.props.event.photoServiceType ) {
        case 'Paywall':
          url = this.getCTLiveUrl();
          break;
        case 'Redirect':
        case 'Preview':
          url = this.getRedirectLink();
          break;
        case 'Free':
        default:
          url = tile.url;
          break;
      }
    }
    else {
      url = tile.url;
    }

    const md = new MobileDetect(window.navigator.userAgent);

    if ((this.props.event.photoServiceType||'').toLowerCase() === 'free' && !md.is('iOS')) {
      fetch(`/download?url=${encodeURIComponent(url)}`, {withCredentials: true})
        .then((response) => {
          if (response.status < 200 || response.status > 399) {
            throw new Error('File not found.')
          }
          return response.blob();
        })
        .then((blob) => {
          return download(blob, url.substring(url.lastIndexOf('/') + 1));
        })
        .catch((e) => {
          console.error(`Failed to download ${url}`, e);
          return alert(this.props.t('Couldn\'t download file.'));
        })
    }
    else {
      window.open(url, '_blank');
    }

  }

  facebookShareCallback(tile) {
    if(tile.isCertificate) {
      trackGoogleEvent('Social', 'Click', 'Facebook Certificate Share');
    }
    else {
      trackGoogleEvent('Social', 'Click', 'Facebook Photo Share');
    }

    shareContent(tile.url, this.props.isMobile);
  }

  /**
   * Transform API results for multimedia assets to the expected format
   */
  genPhotoVideoList(event, media) {
    const { isPhotoEnabled, isVideoEnabled } = event;

    const formattedPhotos = (isPhotoEnabled && media.photos)
      ? media.photos.map((photoData) => {
        const isFree = event.photoServiceType === 'Free';
        const poster = isFree ? photoData.highUrl : photoData.lowUrl;
        return {
          poster,
          url: poster,
          isMuted: false,
          type: 'photo',
          featured: false,
          data:photoData
        };
      })
      : []

    const formattedVideos =  (isVideoEnabled && media.videos)
      ? media.videos.map((videoData, index) => {
        const url = videoData.url.trim().replace(/^http:\/\//, 'https://');
        const poster = videoData.thumbnail.trim().replace(/^http:\/\//, 'https://');
        return {
          url,
          poster,
          type: 'video',
          featured: index < 2,
          isMuted: videoData.isMuted,
          data: videoData
        }
      })
      : []

    return formattedVideos.concat(formattedPhotos)
  }

  getRedirectLink() {
    const { event, result } = this.props
    const baseUrl = event.photoServiceUrl;
    if (!baseUrl) return null;
    return baseUrl.replace(/[_%]BIB[_%]/g, result.bib)
                  .replace(/[_%]LASTNAME[_%]/g, result.lastName);
  }

  getCTLiveUrl() {
    const { media, event } = this.props
    if (media && event.ctliveId) {
      const entryId = media.entryId;
      return `https://results.chronotrack.com/trans/buy-photo?entryID=${entryId}`; // Go straight to the cart
    }
    return null;
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    const {
      certificateUrl,
      event,
      isMobile,
      media ={},
      result,
      titlePosition,
      t
    } = this.props;

    const arrangedMedia = this.genPhotoVideoList(event, media)

    if (event.isLoading || media.isLoading || result.isLoading) return null;

    const {
        wantsPhotoPurchasing,
        photoPrice,
        photoServiceType,
        photoServiceName,
      } = event;

    const rows = isMobile && !this.props.isLandscape ? 4 : 2;
    const prerollAudioUrl = `${configs.cdnUrl}/audio/prerollAudio.mp3`;
    const prerollMedia = media.preroll
                      && media.preroll.url
                      && media.preroll.isPublished ? {...media.preroll, file: media.preroll.url} : {};

    const certificateMedia = certificateUrl ? getFinishCertMedia(certificateUrl) : null;
    const resolvedMedia = certificateMedia ? [certificateMedia] : arrangedMedia;

    const grid = resolvedMedia.length ? (
      <div>
        <h3 style={styles.cardSectionTitle}>
          {certificateUrl ? t('Finisher Certificate') : t('Media')}
        </h3>
        <MediaGrid
          photos={resolvedMedia}
          rows={rows}
          prerollMedia={prerollMedia}
          prerollAudioUrl={prerollAudioUrl}
          actionCallback={this.photoActionCallback}
          actionIcon={SystemUpdateAltIcon}
          actionIconColor='white'
          facebookShareCallback={this.facebookShareCallback}
          facebookShareIcon={FacebookShareMediaIcon}
          openLightboxCallback={() => trackGoogleEvent('Media', 'Click', 'Open Lightbox')}
          loadMoreCallback={() => trackGoogleEvent('Media', 'Click', 'Load More')}
          toggledActionIcon={SystemUpdateAltIcon}
          toggledActionIconColor='white'
          padding={8}
          titlePosition={titlePosition}
          isCertificate={true}
          {...this.props}
        />
      </div>
    ) : null;

    let content;

    if(wantsPhotoPurchasing) {
      switch (photoServiceType) {
        case 'Redirect':
          const redirectLink = this.getRedirectLink();
          content = redirectLink ? (
            <div style={styles.cardContainer}>
              {grid}
              <div style={{textAlign: 'center', margin: '16px 0'}}>
                <Button
                  text={t(
                    'Photos available at {{serviceName}}',
                    {serviceName: photoServiceName}
                  )}
                  color='blue'
                  link={redirectLink}/>
              </div>
            </div>
          ) : null;
          break;
        case 'Preview':
          content = (
            <div style={styles.cardContainer}>
              {grid}
              <div style={{textAlign: 'center', margin: '16px 0'}}>
                <div style={Object.assign({}, textStyles.body1Dark, {
                  margin: 16
                })}>
                  {t('Photos displayed in low resolution. High resolution '
                    + 'photos provided with purchase.')}
                </div>
                <Button
                  text={t('Photos available at {{serviceName}}',
                    {serviceName: photoServiceName})}
                  color='blue'
                  link={this.getRedirectLink()}/>
              </div>
            </div>
          );
          break;
        case 'Paywall':
          content = (
            <div style={styles.cardContainer}>
              {grid}
              <div style={{textAlign: 'center', margin: '16px 0'}}>
                <div style={Object.assign({}, textStyles.body1Dark, {
                  margin: 16
                })}>
                  {t('Photos displayed in low resolution. High resolution '
                    + 'photos provided with purchase.')}
                </div>
                <Button
                  text={`${t(
                    'Purchase full resolution gallery for {{currencySymbol}}{{photoPrice}}{{currency}}',
                    {currencySymbol: '$', currency: 'USD', photoPrice}
                  )} `}
                  color='blue'
                  link={this.getCTLiveUrl()}
                />
              </div>
            </div>
          );
          break;
        case 'Free':
        default:
          content = !grid ? grid : (<div style={styles.cardContainer}>{grid}</div>);
          break;
      }
    }

    if(content) {
      return content;
    }
    else if(grid) {
      return (<div style={styles.cardContainer}>{grid}</div>);
    }

    return null;
  }
}

export const IndividualPhotoGrid = withTranslation()(IndividualPhotoGridComponent);
