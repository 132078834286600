import { textStyles, fonts, colors, loadingDark, loadingLight } from '../../shared/styles';

export const headerStyles = {
  wrapper: {
    width: '100%',
    padding: 0,
  },
  wrapperMobile: {
    height: 55,
    overflow: 'hidden',
    padding: 10,
  },
  tabs: {
    paddingTop: 0,
  },
  navRightSide: {
    paddingTop: 9,
    paddingBottom: 9,
    textAlign: 'right',
  },
  menuItem: (isHovered) => ({
    display: 'inline-block',
    padding: '19px 0 14px',
    marginRight: 40,
    borderBottom: '3px solid transparent',
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase',
    letterSpacing: '.5px',
    fontSize: 12,
    color: (isHovered) ? colors.blue : colors.grey,
    transition: 'all 0.3s',
  }),
  active: {
    color: colors.blue,
    borderBottom: '3px solid '+ colors.blue,
  },
  overflowStyle: {
    padding: 0,
    height: 250,
    width: 390,
    overflow: 'auto'
  },
  scrollMenu: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },
  scrollMenuA: {
    fontSize: 12,
    float: 'left',
    textTransform: 'uppercase',
    padding: '13px 0',
    marginRight: 40,
    color: colors.grey,
    fontFamily: 'ProximaNovaBold',
    letterSpacing: 1,
    display: 'inline-block',
  },
  shadow: {
    position: 'absolute',
    zIndex: '999',
    height: 55,
    marginTop: -10,
    minWidth: 50
  },
  mobileRowWrap: {
    paddingTop: 0,
    width: 750,
    whiteSpace: 'nowrap'
  },
  mobileShadow: (leftDirection) => ({
    ...headerStyles.shadow,
    left: leftDirection ? 0 : null ,
    right: !leftDirection ? 0 : null,
    background: leftDirection
      ? 'linear-gradient(to left, rgba(255, 255, 255, 0.5), white)'
      : 'linear-gradient(to right, rgba(255, 255, 255, 0.5), white)',
  })
};

export const eventStyles = {
  pseudoStyles: () => `
    .event:hover, .friends-upcoming-container:hover, .latest-event:hover {
      background: ${colors.lightGrey} !important;
      cursor: pointer;
    }
    .race-result:hover .race-name{
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
    .latest-event:hover .latest-event-title {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
    .friends-upcoming-container:hover .latest-event-title {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `,
  upcomingRowArrow: {
    position: 'absolute',
    right: 0,
    color: colors.greyLine,
    top: '50%',
    marginTop: '-17.5px',
    display: 'none'
  },
  wrapper: {
    marginTop: 15,
  },
  left: {
    float: 'left',
    width: 75,
    marginRight: 10,
  },
  right: {
    float: 'left'
  },
  quotes: {
    fontSize: 20
  },
  picture: {
    width: 75,
    height: 75,
    backgroundColor: 'grey',
    backgroundSize: 'cover',
  },
  eventBody: {
    clear: 'both',
    borderBottom: `1px solid ${colors.lightGrey2}`,
    borderTop: `1px solid ${colors.lightGrey2}`,
    minHeight: 90
  },
  updateContainer: {
    padding: '15px 0'
  },

  socialContainer: {
    paddingTop: 1,
  },
  socialDiv: {
    display: 'flex',
    marginLeft: '-10px',
  },
  socialIcon: {
    position: 'relative',
    // display: 'inline-block',
    display: 'none',
    padding: '5px 10px',
    marginRight: 10,
    cursor: 'pointer'
  },
  socialCount: {
    display: 'inline-block',
    position: 'absolute',
    top: 11,
    left: 31,
    fontSize: 10,
    color: colors.grey,
  },
  closeXbar1: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    display: 'inline-block',
    width: '40%',
    height: 2,
    transform: 'translateX(-50%) rotate(45deg)',
    backgroundColor: colors.grey,
  },
  closeXbar2: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    display: 'inline-block',
    width: '40%',
    height: 2,
    transform: 'translateX(-50%) rotate(135deg)',
    backgroundColor: colors.grey,
  },
  textField: {
    width: 200,
    height: 90,
    fontSize: 14,
    padding: 20
  },
  button: {
    ...textStyles.sectionHead,
    padding: '5px 10px 4px',
    color: colors.white,
    backgroundColor: colors.blue,
    borderRadius: 2,
    border: 'none',
  },

  eventBorderContainer: {
    marginTop: 8,
    marginBottom: 7,
    paddingTop: 12,
    paddingBottom: 18,
    borderTop: '1px solid '+ colors.greyLine,
    borderBottom: '1px solid '+ colors.greyLine,
  },
  eventBorderContMobile: {
    paddingTop: 4,
    paddingBottom: 4,
    borderTop: '1px solid '+ colors.greyLine,
    borderBottom: '1px solid '+ colors.greyLine,
  },
  eventMain: {
    alignItems: 'center',
  },
  eventBodyLeft: {
    paddingLeft: 0,
  },
  rankingsCell: {
    display: 'flex',
    padding: 0,
  },
  paceCell: {
    borderLeft: '1px solid '+ colors.greyLine,
    borderRight: '1px solid '+ colors.greyLine,
  },

  goals: () => ({
    textAlign: 'center'
  }),
  goalTitleCenter: {
    ...textStyles.sectionHead,
    textAlign: 'center',
    borderBottom: '1px solid '+ colors.greyLine,
    marginBottom: 1,
    paddingBottom: 7,
    paddingTop: 3,
  },
  goalResStyle: {
    paddingTop: 5,
    color: colors.textColor,
    textAlign: 'center',
  },
  goalNumberStyle: (color) => ({
    paddingTop: 6,
    fontFamily: 'LatoBold',
    letterSpacing: '-.75px',
    color: color || colors.textColor,
    fontSize: 30,
    lineHeight: '1em',
    textAlign: 'center',
  }),
  goalResNumerator: {
    paddingBottom: 3,
    fontSize: 21,
    lineHeight: '0.9em',
    fontFamily: 'LatoBold'
  },
  goalResDenominator: {
    fontFamily: 'LatoBold',
    letterSpacing: '-.75px',
    color: colors.grey,
    fontSize: 12,
    lineHeight: '1em',
  },

  mobileGoalTitle: {
    ...fonts.bold,
    fontSize: 10,
    color: colors.grey,
    textTransform: 'uppercase',
  },
  mobileRankValue: {
    fontFamily: 'LatoBold',
    letterSpacing: '-.75px',
    fontSize: 18,
    lineHeight: '1.2em',
  },
  mobileTimeValue: {
    paddingTop: 1,
    fontFamily: 'LatoBold',
    letterSpacing: '-.75px',
    fontSize: 24,
    lineHeight: '1em',
    color: colors.black,
  },
  mobileDenominator: {
    fontFamily: 'LatoBold',
    fontSize: 10,
    lineHeight: '1em',
    color: colors.grey,
  },
  goalText: (isMobile) => ({
    paddingLeft: 5,
    color: colors.grey,
    fontSize: (isMobile) ? 14 : 16,
  }),

  leftQuote: {
    float: 'left',
    marginTop: 10
  },
  rightQuote: {
    float: 'right',
    paddingLeft: -30,
    marginTop: 5
  },
  eventTitle: (isMobile) => ({
    display: 'block',
    marginBottom: (isMobile) ? 0 : 3,
    color: colors.textColor,
    fontSize: (isMobile) ? 16 : 21,
    lineHeight: '1em',
    fontFamily: 'ProximaNovaBold'
  }),
  eventDetail: (isMobile) => ({
    display: 'inline-block',
    paddingRight:(isMobile) ? 10 : 15,
    color: colors.grey,
    fontSize: (isMobile) ? 12 :14,
  }),
  eventDetailPipe: (isMobile) => ({
    display: 'inline-block',
    padding:(isMobile) ? '0 10px' : '0 15px',
    color: colors.grey,
    fontSize: (isMobile) ? 12 :14,
    borderLeft: '1px solid '+ colors.greyLine,
  }),

  unofficialTag: {
    position: 'absolute',
    top: '97%',
    left: 0,
    width: '100%',

    ...fonts.default,
    fontSize: 12,
    lineHeight: '1em',
    color: colors.grey,
  },
  upcomingEventContainer: (isMobile) => ({
    ...styles.rowStyle,
     borderBottom: (isMobile) ? 'none' : '1px solid lightgrey'
  }),
  goalsResStyle: (isMobile, Days) => ({
    ...styles.goalNumber,
    color: colors.darkType,
    fontFamily: Days === 0 ? 'ProximaNovaRegular' : 'LatoBold',
    lineHeight: '40px'
  }),
  goalPaceStyle: {
    borderLeft: `1px solid ${colors.greyLine}`,
    borderRight: `1px solid ${colors.greyLine}`
  },
  messagesDialogContainer:{
    float: 'left',
    overflowY: 'auto',
    maxHeight: '270px',
    overflowX: 'hidden'
  },
};

// noinspection JSSuspiciousNameCombination
/**
 * Athlete overview set styles
 * @type {object}
 */
export const aoStyles = {
  overviewContainer: {
    paddingBottom: 20,
    borderBottom: '1px solid '+ colors.greyLine
  },
  photoCol: (isMobile) => ({
    flex: '0 1 auto',
    padding: isMobile ? '15px 30px 0 20px' : '17px 55px 0 40px',
  }),
  photoBox: (isMobile) => ({
    position: 'relative',
    borderRadius: '50%',
    boxShadow: isMobile ? `0 0 0 1px ${colors.white}, 0 0 0 4px ${colors.blue}` : `0 0 0 4px ${colors.white}, 0 0 0 9px ${colors.lightGrey2}, 0 0 0 18px ${colors.blue}, 0 0 0 23px ${colors.lightGrey2}`,
  }),
  photoSkeleton: (width) => ({
    position: 'relative',
    display: 'inline-block',
    minWidth: width,
    minHeight: width,
  }),
  photoEditButton: (show) => ({
    opacity: (show) ? 1 : 0,
    transition: 'all 0.3s',
  }),
  overviewCol: {
    flex: '1',
  },
  bestTicks: {
    fontSize: 24,
    float: 'left',
    paddingTop: 10,
    clear: 'both',
    color: colors.textColor
  },
  identityStyles: {
    container: {
      position: 'relative',
      minHeight: 50,
      marginBottom: 20
    },
    title: {
      fontFamily: 'ProximaNovaBold',
      fontSize: 30,
      lineHeight: '1em',
      marginBottom: 4,
      marginTop: 0
    },
    ageAndIconsBar: {
      ...textStyles.body1Grey,
      lineHeight: '1em',
      marginBottom: 25,
    },
    personalFirst: {
      display: 'inline-block',
      paddingRight: 15,
      marginRight: 15,
      borderRight: '1px solid '+ colors.greyLine
    },
    personal: {
      display: 'inline-block',
    },
  },
  resultsSummaryStyles: {
    summaryItem: {
      display: 'inline-block'
    },
    summaryCount: {
      fontSize: 35,
      fontWeight: '100',
      border: '1px solid',
      borderColor: colors.greyLine,
      padding: '0px 20px',
      margin: 3
    },
    summaryTitle: {
      fontSize: 13,
      margin: 0,
      whiteSpace: 'nowrap',
      marginTop: 10,
      color: colors.grey,
    },
  },
  loading: {
    photo: (width, fetching) => ({
      position: 'absolute',
      zIndex: 3,

      top: 0,
      left: 0,
      opacity: (fetching) ? 1 : 0,
      width,
      height: width,
      borderRadius: '50%',
      pointerEvents: 'none',
      transition: 'opacity 0.3s 0.1s',
      ...loadingDark,
    }),
    identityContainer: (fetching) => ({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      paddingTop: 1,
      pointerEvents: 'none',
      opacity: (fetching) ? 1 : 0,
      transition: 'opacity 0.3s 0.2s',
    }),
    name: () => ({
      height: 32,
      width: '30%',
      minWidth: 150,
      marginBottom: 4,
      ...loadingDark,
    }),
    nameDetails: () => ({
      height: 14,
      width: '40%',
      minWidth: 200,
      ...loadingLight,
    })
  },
};

/**
 * Related athletes styles
 * @type {object}
 */
export const raStyles = {
  athletesHolder: {
    display: 'block',
    cursor: 'pointer'
  },
  athletePhotos: {
    display: 'inline-block',
    position: 'relative',
  },
  names: {
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    left: '104%',
    transform: 'translateY(-50%)',
    fontSize: 13,
    color: colors.blue,
  },
  relatedRowLoading: (show) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    height: 51,
    width: '100%',
    pointerEvents: 'none',
    opacity: (show) ? 1 : 0,
    transition: 'opacity 0.3s 0.1s',
    ...loadingLight,
  }),
  noFriendsAndRivals: {
    fontFamily: 'ProximaNovaRegular',
    color: colors.textColor
  },
  relatedRowContainer: {
    position:'relative',
    minHeight:51
  }
};

/**
 * Personal records styles
 * @type {object}
 */
export const prStyles = {
  container: {
    cursor: 'pointer'
  },
  pseudoStyles: () => `
    .rightArrow {
      display: none;
    }
    .rivalRow:hover .rightArrow {
      display: block;
    }
  `,
  sectionTitle: {
    position: 'relative',
    ...textStyles.sectionHead,
    marginBottom: 10,
  },
  sectionTitleLoader: (fetching) => ({
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 12,
    width: 130,
    pointerEvents: 'none',
    opacity: (fetching) ? 1 : 0,
    transition: 'opacity 0.3s 0.25s',
    ...loadingDark,
  }),
  recordRowContainer: (isHeader) => ({
    position: 'relative',
    padding: (isHeader) ? '0 5px' : null,
    minHeight: (isHeader) ? 0 : 120,
  }),
  recordContainer: (isHeader) => ({
    display: 'inline-block',
    padding: (isHeader) ? '0 10px' : '0 30px 0 0',
    marginBottom: (isHeader) ? 0 : 30,
    maxWidth: (isHeader) ? 120 : 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),

  title: {
    paddingBottom: 2,
    fontFamily: 'ProximaNovaRegular',
    fontSize: 14,
    letterSpacing: 0,
    color: colors.grey,
    borderBottom: '1px solid '+ colors.greyLine,
    maxWidth: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  bestTicks: {
    paddingTop: 9,
    paddingBottom: 2,
    fontFamily: 'LatoBold',
    fontSize: 28,
    letterSpacing: '-.75px',
    lineHeight: '1em',
    color: colors.textColor,
  },
  location: {
    marginBottom: 4,
    marginTop: 4,
    ...fonts.bold,
    fontSize: 12,
    lineHeight: '1em',
    color: colors.textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    ...fonts.default,
    fontSize: 12,
    marginTop: 3,
    lineHeight: '1em',
    color: colors.grey,
  },
  rating: {
    ...textStyles.body1Grey,
    ...fonts.bold,
    lineHeight: '1em',
  },

  recordContainerLoading: (fetching, isHead = true) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    paddingTop: 2,
    paddingLeft: (isHead) ? 5 : 0,
    width: '100%',
    display: 'flex',
    pointerEvents: 'none',
    opacity: (fetching) ? 1 : 0,
    transition: 'opacity 0.3s',
  }),
  loadingRecordTitle: {
    height: 20,
    width: '80%',
    marginBottom: 5,
    ...loadingLight,
  },
  loadingRecordUnderline: {
    height: 2,
    width: '100%',
    marginBottom: 8,
    ...loadingLight,
  },
  loadingRecordTime: {
    height: 25,
    width: '95%',
    marginBottom: 7,
    ...loadingLight,
  },
  loadingRecordDate: {
    height: 12,
    width: '70%',
    ...loadingLight,
  },
  noPersonalRecordsTitle: {
    maxWidth: 200,
    borderBottom: 'none',
    marginLeft: 10
  },
};

export const statStyles = {
  statPage: (isMobile) => ({
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
    padding: isMobile ? '15px' : '20px',
    marginBottom: 20,
  }),
  title: (isMobile) => ({
    lineHeight: '1em',
    marginBottom: 24,
    marginTop: 0,
    fontWeight: 'normal',
    fontSize: isMobile ? 18 : ''
  })
};

/**
 * Result Item styles
 * @type {object}
 */
export const riStyles = {
  resPage: (isMobile) => ({
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
    padding: isMobile ? '15px' : '20px'
  }),
  titleRow: {
    marginBottom: 40,
  },
  title: (isMobile) => ({
    fontWeight: 'normal',
    fontSize: isMobile ? 18 : '',
    marginTop: 0,
    marginBottom: 0,
  }),
  detailRow: {
    ...textStyles.body1Grey,
    marginBottom: 25
  },
  yearRow: {
    padding: '8px 0 6px 5px',
    fontFamily: 'Lato',
    fontSize: 18,
    lineHeight: '1em',
    backgroundColor: colors.lightGrey
  },

  tableHeaderWrapper: {
    display: 'flex',
    paddingBottom: 6,
    ...textStyles.sectionHead,
    textAlign: 'center',
  },
  tableHeaderLeftCell: {
    paddingLeft: 5,
    textAlign: 'left',
  },

  resultMobileTitle: {
    fontSize: 16,
  },
  resultMobileUnofficial: {
    color: colors.grey,
    fontSize: 14,
  },
  resultMobileDataRow: {

  },

  resultRowWrapper: {
    display: 'flex',
    padding: '21px 0 19px 5px',
    color: colors.textColor,
    borderTop: '1px solid '+ colors.greyLine,
  },
  resultRowLink: (isHover) => ({
    display: 'block',
    color: colors.textColor,
    backgroundColor: (isHover) ? colors.lightGrey : 'transparent',
    transition: 'all 0.2s',
  }),
  resultRowEvent: {
    paddingBottom: 4,
    fontFamily: 'ProximaNovaBold',
    fontSize: 21,
    lineHeight: '1em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateLocationRow: {
    display: 'flex',
  },
  resultRowDate: {
    display: 'inline-block',
    flex: '0 0 auto',
    verticalAlign: 'top',
    paddingRight: 20,
    marginRight: 20,
    ...textStyles.body1Grey,
    borderRight: '1px solid '+ colors.greyLine
  },
  resultRowLocation: {
    display: 'inline-block',
    flex: '1',
    ...textStyles.body1Grey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  resultRowCell: {
    paddingTop: 8,
    textAlign: 'center',
  },
  resultRowStat: {
    paddingBottom: 3,
    fontFamily: 'LatoBold',
    letterSpacing: '-.75px',
    fontSize: 21,
    lineHeight: '1em',
    marginBottom: 1
  },
  resultRowTime: {
    fontFamily: 'LatoBold',
    fontSize: 24,
    letterSpacing: '-.75px',
    lineHeight: '0.9em',
    marginBottom: 3
  },
  resultRowUnit: {
    ...fonts.bold,
    color: colors.grey,
    fontSize: 12,
    lineHeight: '1em',
  },
  resultRowTooltip: {
    ...fonts.default,
    display: 'inline',
    paddingLeft: 4
  },
  resultRowTooltipIcon: {
    marginTop: '-2px',
    color: colors.blue
  },
  resultFinalTime: {
    position: 'relative',
    paddingTop: 3,
    textAlign: 'center',
    fontFamily: 'LatoBold',
    fontSize: 30,
    whiteSpace: 'nowrap',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  resultRowUnofficialFlag: {
    position: 'absolute',
    top: '85%',
    left: 0,
    display: 'block',
    width: '100%',

    ...fonts.bold,
    fontSize: 12,
    lineHeight: '1em',
    color: colors.grey,
  },

  resultDetailLoader: (show) => ({
    position: 'absolute',
    left: 15,
    bottom: 0,
    height: 18,
    width: 300,
    pointerEvents: 'none',
    opacity: (show) ? 1 : 0,
    transition: 'all 0.3s',
    ...loadingLight,
  }),
  loading: (show) => ({
    position: 'absolute',
    zIndex: 2,
    top: 17,
    left: 0,
    width: '100%',
    opacity: (show) ? 1 : 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
  }),
  loadingCategoryHead: {
    height: 33,
    width: '100%',
    marginBottom: 21,
    ...loadingLight,
  },
  loadingEvent: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 39,
  },
  loadingEventTitle: {
    height: 23,
    marginBottom: 5,
    ...loadingDark,
  },
  loadingEventDetail: {
    height: 20,
    width: '75%',
    ...loadingLight,
  },
  loadingRank: {
    height: 18,
    width: '80%',
    margin: '0 auto 4px',
    ...loadingLight,
  },
  loadingRankUnit: {
    height: 12,
    width: '75%',
    margin: '0 auto',
    ...loadingLight,
  },
  loadingEventTime: {
    width: '90%',
    height: 30,
    margin: '0 auto',
    ...loadingDark,
  },
  allResults: {
    marginTop: 0,
    marginBottom: 0,
    fontSize: 18,
    fontFamily: 'ProximaNovaRegular',
    fontWeight: 'normal'
  },
  filtersContainer: (isMobile) => ({
    paddingLeft: 20,
    display: (isMobile) ? '' : 'inline-flex',
    width: '100%'
  }),
  filterDropdown: {
    width: 200,
    float: 'left',
    marginRight: 30
  },
  groupDropdown: {
    width: 200,
    float: 'left'
  },
  resultsDesktopHeader: (fetching) => ({
    position:'relative',
    minHeight: (fetching) ? 320 : 0,
    transition:'all 0.3s'
  }),
  loadMoreContainer: {
    marginTop:20,
    textAlign:'center'
  },
  loadMoreButton: {
    display: 'inline-block',
    borderRadius:'3px',
    fontFamily:'ProximaNovaRegular',
    margin:'20px 0px',
    height:'40px',
    textAlign:'center',
    padding:'10px',
    border:'none',
    outline:'none',
    cursor:'pointer',
    backgroundColor:'rgb(22, 169, 225)',
    color:'rgb(255, 255, 255)',
    textTransform:'capitalize',
    fontSize:'17px','width':'250px'
  }
};

export const rivalStyles = {
  card: (isMobile) => ({
    padding: isMobile ? '15px' : '20px',
    boxShadow: colors.cardShadow,
    backgroundColor: colors.white,
  }),
  vsCard: (isMobile) => ({
    padding: (isMobile) ? '15px' : '20px',
    boxShadow: colors.cardShadow,
    backgroundColor: colors.white,
  }),

  vsBack: (isHover) => ({
    display: 'inline-block',
    marginBottom: 7,
    ...textStyles.sectionHead,
    color: (isHover) ? colors.blue : colors.textColor,
    transition: 'all 0.3s',
  }),
  vsBackChevron: {
    position: 'relative',
    top: '-2px',
    display: 'inline-block',
    marginRight: 5,
  },

  titleRow: {
    marginBottom: 31,
  },
  title: (isMobile) => ({
    color: colors.textColor,
    fontSize: isMobile ? 18 : '',
    lineHeight: '1em',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'normal',
  }),
  vsTitleNameContainer: {
    display: 'inline-block',
    textAlign: 'center',
  },
  vsWord: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0 0.4em',
  },
  vsTitleScore: {
    paddingTop: 3,
    fontSize: 16,
    lineHeight: '1em',
    color: colors.grey,
  },

  listHeader: {
    paddingBottom: 6,
    textAlign: 'center',
    ...textStyles.sectionHead,
    borderBottom: '1px solid '+ colors.greyLine,
    fontFamily: 'ProximaNovaSemiBold, arial, sans-serif',
    fontSize: 11
  },
  vsHeaderLeft: (isMobile) => ({
    paddingLeft: (isMobile) ? 32 : 40,
    textAlign: 'left',
  }),
  vsHeaderCol: (isMobile) => ({
    flex: (isMobile) ? '0 1 25%' : '0 1 12.5%',
    maxWidth: (isMobile) ? '25%' : '12.5%',

  }),

  listRow: (hovered) => ({
    alignItems: 'center',
    padding: '30px 0 26px',
    textAlign: 'center',
    borderBottom: '1px solid '+ colors.greyLine,
    backgroundColor: hovered ? colors.lightGrey : 'transparent',
    cursor: 'pointer',
    transition: 'all 0.3s',
  }),
  rowPersonCol: {
    textAlign: 'left',
    paddingLeft: 5
  },
  vsPersonCol: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: 5,
  },
  expandPlus: (isMobile) => ({
    flex: '0 0 auto',
    marginRight: (isMobile) ? 15 : 20,
  }),
  vsNameCol: {
    flex: 1,
    maxWidth: '89%',
  },
  winText: {
    display: 'inline-block',
    marginRight: 5,
    color: colors.blue
  },

  rowName: (isMobile) => ({
    width: '100%',
    marginBottom: 1,
    color: colors.textColor,
    fontSize: isMobile ? 18 : 22,
    lineHeight: '1em',
    fontFamily: 'ProximaNovaBold',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  rowLocation: {
    color: colors.grey,
    fontSize: 13
  },
  rowDataContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rowDataCol: {
    color: colors.textColor,
    fontSize: 21,
  },
  vsDataCol: (isMobile) => ({
    fontSize: (isMobile) ? 16 : 21,
    flex: (isMobile) ? '0 1 25%' : '0 1 12.5%',
    maxWidth: (isMobile) ? '25%' : '12.5%',
  }),
  rowTime: {
    paddingTop: 2,
    fontFamily: 'LatoBold',
    fontSize: 24,
    letterSpacing: '-.75px',
    lineHeight: '1em',
    flex: '0 1 12.5%',
    maxWidth: '12.5%',
  },
  loadingContainer: (show) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: '100%',
    opacity: (show) ? 1 : 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
  }),
  loadingRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 0 29px',
  },
  vsPersonColLoading: {
    paddingLeft: 40,
  },
  loadingTitle: {
    height: 22,
    width: '100%',
    minWidth: 100,
    marginBottom: 3,
    ...loadingDark,
  },
  loadingDetails: {
    height: 14,
    width: '70%',
    minWidth: 80,
    ...loadingLight,
  },
  loadingStat: {
    height: 22,
    width: '100%',
    ...loadingLight,
  },
  loadingTime: {
    height: 24,
    width: '100%',
    ...loadingDark,
  },

  vsDetailsContainer: (showing = true, isMobile) => ({
    maxHeight: (!showing) ? 0 : (isMobile) ? 300 : 150,
    overflow: 'hidden',
    opacity: (showing) ? 1 : 0,
    paddingTop: (!showing) ? 0 : (isMobile) ? 20 : 30,
    paddingLeft: (isMobile) ? 17 : 25,
    transition: (showing) ? 'all 0.3s, opacity 0.4s 0.2s' : 'all 0.3s',
  }),
  vsDetailsHeader: (isMobile) => ({
    paddingBottom: (isMobile) ? 3 : 6,
    textAlign: 'center',
    ...textStyles.sectionHead,
    fontSize: (isMobile) ? 10 : 12,
    color: colors.grey,
    borderBottom: '1px solid '+ colors.greyLine,
  }),
  vsDetailsRow: (isMobile) => ({
    paddingTop: (isMobile) ? 15 : 21,
    fontSize: (isMobile) ? 14 : 16,
  }),
  vsDetailsHand: {
    display: 'inline-block',
    marginLeft: 10,
    verticalAlign: 'top',
  },
  vsDetailsCol: (isMobile) => ({
    flex: (isMobile) ? '0 1 25%' : '0 1 12.5%',
    maxWidth: (isMobile) ? '25%' : '12.5%',
  }),
  vsDetailsTime: (isMobile) => ({
    paddingTop: 2,
    fontFamily: 'LatoBold',
    letterSpacing: '-.5px',
    fontSize: 20,
    lineHeight: '1em',
    flex: (isMobile) ? '0 1 25%' : '0 1 12.5%',
    maxWidth: (isMobile) ? '25%' : '12.5%',
  }),

  noRivalsContainer: {
    textAlign: 'center',
    paddingTop: 20,
    paddingBottom: 50,
  },
  noRivalsSubtext: {
    paddingTop: 10,
    fontSize: 16,
    color: colors.grey,
  },
  rivalTableContainer: (fetching) => ({
    position:'relative',
    minHeight: fetching ? 410 : 0
  }),
  rowAthName: {
    textAlign:'left',
    paddingLeft:5
  },
  rivalVSTableContainer: (fetching) => ({
    position:'relative',
    minHeight: fetching ? 200 : 0
  }),
  rivalsVSDetailsContainer: {
    paddingTop: 8,
    color: colors.darkRed,
    paddingLeft: 20
  },
  pseudoStyles: () => `
    .rightArrow {
      display: none;
    }
    .rivalRow:hover .rightArrow {
      display: block;
    }
    .rivalRow:hover .rival-name {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `
};

export const suggestionStyles = {
  suggestionColumn: (isMobile) => ({
    marginTop: isMobile ? 20 : 0,
  }),
  suggestionCard: (isMobile) => ({
    padding: isMobile ? '15px' : '20px',
    marginBottom: 20,
    boxShadow: colors.cardShadow,
    backgroundColor: colors.white,
    borderRadius: '3px'
  }),
  suggestionTitle: {
    marginBottom: 5,
    color: colors.darkType,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '21px',
    letterSpacing: '0px',
  },
  suggestionRow: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 92,
    padding: '15px 0',
  },
  suggestionRowInfo: {
    flex: 1,
    paddingLeft: 15,
  },
  suggestionRowName: {
    display: 'block',
    fontSize: 18,
    color: colors.textColor,
    marginBottom: 1,
  },
};

export const followerStyles = {
  pseudoStyles: () => `
    .followerRow:hover {
      background: ${colors.lightGrey};
      cursor: pointer;
    }
    .followerRow:hover .follower-name {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `,
  followersContent: (isMobile) => ({
    boxShadow: colors.cardShadow,
    backgroundColor: colors.white,
    padding: isMobile ? '15px' : '20px'
  }),
  followingTitle: (isMobile) => ({
    color: colors.textColor,
    fontSize: isMobile ? 18 : '',
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 'normal',
  }),
  reportMessage: {
    fontSize: 16,
    color: colors.textColor
  },
  showFollowing: {
    color: colors.grey,
    fontSize: 14,
    paddingBottom: 35
  },
  showFollowingLoading: (show) => ({
    position: 'absolute',
    top: 2,
    left: 0,
    height: 16,
    width: '100%',
    opacity: (show) ? 1 : 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
    ...loadingLight,
  }),

  followerGroupLetterRow: {
    padding: '8px 0px 6px 5px',
    ...fonts.heavy,
    fontSize: 18,
    lineHeight: '1em',
    textTransform: 'uppercase',
    backgroundColor: colors.lightGrey,
  },

  loadingContainer: (show) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: '100%',
    opacity: (show) ? 1 : 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
  }),
  loadingHeader: {
    height: 32,
    width: '100%',
    marginBottom: 15,
    ...loadingLight,
  },
  loadingFollowerRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 31,
  },
  loadingAvatar: {
    flex: '0 0 auto',
    display: 'inline-block',
    width: 65,
    height: 65,
    borderRadius: '50%',
    ...loadingLight,
  },
  loadingNameCell: {
    flex: 1,
    display: 'inline-block',
    paddingLeft: 20,
  },
  loadingName: {
    height: 22,
    width: '50%',
    minWidth: 200,
    marginBottom: 5,
    ...loadingDark,
  },
  loadingDetails: {
    height: 16,
    width: '40%',
    minWidth: 180,
    ...loadingLight,
  },
  loadingButton: {
    flex: '0 0 auto',
    display: 'inline-block',
    height: 36,
    width: 48,
    borderRadius: 4,
    ...loadingLight,
  },

  followerRow: {
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid '+ colors.greyLine,
    padding: '15px 0',
  },
  followerRowPhotoCell: {
    display: 'inline-block',
    flex: '0 1 auto',
  },
  followerRowInfoCell: {
    display: 'inline-block',
    flex: '1',
    paddingLeft: 20,
  },

  followerName: (isMobile) => ({
    display: 'block',
    marginBottom: 3,
    fontFamily: 'ProximaNovaBold',
    fontSize: isMobile ? 18 : 22,
    lineHeight: '1em',
    color: colors.textColor,
  }),
  followerDetail: {
    ...textStyles.body1Grey,
  },
  bannerStyle: {
    width: 300,
    height: 250
  },
  followerGroupContainer: (fetching) => ({
    position: 'relative',
    minHeight: (fetching) ? 430 : 0
  }),
};
export const overviewStyles = {
  rightSidebar: {
    background: colors.white,
    padding: 20,
    marginBottom: 20,
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.15)'
  }
}

export const messageStyles = {
  messageCard: (isMobile) => ({
    padding: isMobile ? '15px' : '20px',
    marginBottom: 20,
    boxShadow: colors.cardShadow,
    backgroundColor: colors.white,
  }),
  title: (isMobile) => ({
    marginBottom: 18,
    marginTop: 0,
    fontWeight: 'normal',
    fontSize: isMobile ? 18 : ''
  }),
  threadListTitle: {
    marginBottom: 5,
    paddingLeft: 5,
    ...textStyles.sectionHead,
  },
  listContainer: {
    display: 'inline-block',
    flex: '0 0 210px',
    maxWidth: '210px',
    paddingRight: '10px'
  },
  threadContainer: {
    display: 'inline-block',
    flex: '1 0 0',
  },
  threadListContainer: {
    backgroundColor: colors.lightGrey2,
  },
  threadListItem: {
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  threadListItemSelected: {
    backgroundColor: colors.blue,
    padding: '20px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  photoContainer: {
    display: 'inline-block',
    flex: '0 0 auto',
    padding: '0 12px',
  },
  itemCopyContainer: {
    display: 'inline-block',
    flex: '1 1 auto',
    paddingRight: 10,
    minWidth: 0,
  },
  itemName: (isActive) => ({
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: isActive ? 'white' : null,
  }),
  itemPreview: (isActive) => ({
    fontSize: 12,
    color: (isActive) ? 'white' : colors.grey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),

  messageRow: (isMine) => ({
    display: 'flex',
    marginBottom: 12,
    paddingLeft: 15,
    justifyContent: isMine ? 'flex-end' : 'flex-start',
    alignItems: 'center',
  }),
  messageBody: (isMine) => ({
      maxWidth: '78%',
      margin: '0 20px',
      padding: '10px 15px',
      float: isMine ? 'right' : 'left',
      fontSize: 14,
      color: isMine ? colors.white : colors.textColor,
      backgroundColor: isMine ? colors.darkBlue : colors.lightGrey2,
      borderRadius: 3,
  }),
  messageTitle: {
    width: '100%',
    textAlign: 'center',
    borderBottom: `1px solid ${colors.lightGrey2}`,
    lineHeight: '0.1em',
    margin: '15px 0 30px'
  },
  messageContainer: {
    backgroundColor: colors.white,
    padding: '0 30px',
    color: colors.grey,
    fontSize: 11,
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase'
  },
  dateStyle: (isMine) => ({
    fontSize: 12,
    color: colors.textColor,
    marginRight: isMine ? 20 : 0,
    marginLeft: isMine ? 0 : 20,
    paddingTop: 5,
    float: isMine ? 'right' : 'left',
    clear: 'both'
  }),
  messageBox: (isMobile) => ({
    maxHeight: isMobile ? 250 : 400,
    overflow: 'auto'
  }),
  backToAllMessages: {
    color: colors.textColor,
    fontSize: 12,
    textTransform: 'uppercase',
    fontFamily: 'ProximaNovaSemibold',
    paddingLeft: 8,
    cursor: 'pointer',
    marginBottom: 10,
    alignItems: 'center',
    display: 'inline-flex',
  }
};


export const styles = {
  container: {
    width: 1116,
    margin: '0 auto'
  },
  mainContainer: (showBetaBanner) => ({
    background: colors.lightGrey,
    marginTop: showBetaBanner ? 115 : 77,
    transition: 'margin-top 0.3s',
  }),
  headerContainer: {
    backgroundColor: colors.white,
    boxShadow: '0 2px 3px '+ colors.greyLine,
  },
  overviewContainer: {
    background: colors.white,
    borderBottom: '1px solid rgb(223, 223, 223)'
  },
  floatLeft: {
    padding: 0,
    float: 'left'
  },
  floatLeftMargin: {
    float: 'left',
  },
  suggestionRow: {
    marginBottom: 30,
  },
  noRelatedImage: {
    display: 'inline-block',
    width: 50,
    height: 50,
    marginLeft: -8,
    backgroundColor: colors.grey,
    borderRadius: '50%',
    border: `2px solid ${colors.white}`
  },
  padding: {
    padding: 0
  },
  wrapperStyle: (isMobile) => ({
    marginLeft: -8,
    marginTop: isMobile ? 2 : 7
  }),
  athletePhoto: {
    padding: 0,
    border: `2px solid ${colors.white}`,
  },
  noUpcoming: {
    margin: '40px 0 40px',
    fontSize: 16,
    fontStyle: 'italic',
    color: colors.grey,
  },
  column: {
    float: 'left',
    width: '100%'
  },
  banners: {
    background: colors.white,
    clear: 'both',
    marginBottom: 20,
    paddingTop: '1px',
    paddingBottom: 20,
    border: `1px solid ${colors.lightGrey2}`,
    borderTop: 'none'
  },
  button: {
    display: 'inline-block',
    border: `1px solid ${colors.darkBlue}`,
    fontSize: 11,
    borderRadius: 2,
    float: 'right',
    textTransform: 'uppercase',
    color: colors.darkBlue,
    padding: '3px 7px 3px 7px',
    fontFamily: 'ProximaNovaSemiBold'
  },
  btns: {
    lineHeight: '30px',
    marginBottom: 10,
    color: colors.white,
    display: 'inline-block',
    padding: '0px 45px',
    fontSize: 14,
    fontWeight: 'normal',
    verticalAlign: 'middle',
    border: '0px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    borderRadius: 3,
    textDecoration: 'none',
    outline: 'none',
    marginRight: 10,
    backgroundColor: colors.blue
  },
  section: {
    marginTop: 40,
    marginLeft: '20%',
    clear: 'both'
  },
  resultTitle: {
    color: colors.textColor,
    fontSize: 10,
    fontFamily: 'ProximaNovaSemiBold',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 10,
    clear: 'none',
    textAlign: 'center',
    float: 'left'
  },
  sectionTitle: {
    color: colors.textColor,
    fontFamily: 'ProximaNovaSemiBold',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginTop: 10,
    clear: 'both'
  },

  boxTitle: (isMobile) => ({
    position: 'relative',
    marginTop: 0,
    marginBottom: 14,
    ...fonts.default,
    color: colors.textColor,
    fontSize: isMobile ? 18 : '',
    lineHeight: '22px',
    fontWeight: 'normal',
  }),
  boxTitleLoading: (show) => ({
    position: 'absolute',
    top: 2,
    left: 0,
    zIndex: 2,
    width: '60%',
    height: 20,
    pointerEvents: 'none',
    ...loadingDark,
    opacity: (show) ? 1 : 0,
    transition: 'opacity 0.3s',
  }),
  calendarContainer: (isMobile) => ({
    padding: isMobile ? '15px' : '20px',
    marginBottom: 20,
    backgroundColor: colors.white,
    boxShadow: colors.cardShadow,
  }),
  cardTitle: {
    color: colors.textColor,
    margin: 0,
    fontFamily: 'ProximaNovaSemiBold',
    fontSize: 21
  },
  viewAllResults: (
    backgroundColor = colors.blue,
    marginTop,
    width = '40%',
    height,
    fontSize = 16,
    isMobile,
  ) => ({
    minWidth: 120,
    borderRadius: 2,
    cursor: 'pointer',
    clear: 'both',
    lineHeight: '22px',
    fontFamily: 'ProximaNovaRegular',
    backgroundColor,
    textAlign: 'center',
    padding: '12px 20px',
    margin: '0px auto',
    color: colors.white,
    fontSize,
    marginTop,
    height,
    width,
    maxWidth: isMobile ? '250px' : '100%'
  }),
  viewAllText: {
    color: colors.white,
    fontSize: 18
  },
  overviewEventsContainer: (fetching) => ({
    position: 'relative',
    minHeight: (fetching) ? 625 : 100,
    transition:'all 0.3s',
  }),

  upcomingLoader: (show) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: '100%',
    transition: 'all 0.3s',
    pointerEvents: 'none',
    opacity: (show) ? 1 : 0,
  }),
  upcomingTitleLoader: (show) => ({
    display: 'inline-block',
    height: 22,
    width: '30%',
    minWidth: 150,
    marginBottom: 9,
    pointerEvents: 'none',
    ...loadingDark,
    opacity: (show) ? 1 : 0,
    transition: 'opacity 0.3s',
  }),
  eventPlaceholder: {
    marginBottom: 36,
  },
  eventTitleLoader: {
    height: 21,
    width: '70%',
    minWidth: 200,
    marginBottom: 4,
    ...loadingLight,
  },
  eventDetailLoader: {
    display: 'inline-block',
    height: 20,
    width: '40%',
    minWidth: 180,
    marginBottom: 16,
    ...loadingLight,
  },
  loaderStandingsRow: {
    display: 'flex',
    paddingBottom: 18,
  },
  standingLoaderTitle: {
    height: 12,
    width: '95%',
    marginBottom: 8,
    ...loadingLight,
  },
  standingLoaderUnderline: {
    height: 2,
    width: '100%',
    marginBottom: 6,
    ...loadingLight,
  },
  standingLoaderMetric: {
    display: 'inline-block',
    height: 29,
    width: '100%',
    ...loadingLight,
  },
  eventSocialLoader: {
    display: 'inline-block',
    height: 22,
    width: 140,
    ...loadingLight,
  },
  titleStyle: (isMobile) => ({
    textTransform: 'uppercase',
    fontFamily: 'ProximaNovaSemiBold',
    fontSize: isMobile ? 10 : 14,
    color: isMobile ? colors.grey : colors.textColor
  }),
  goalNumber: {
    fontSize: 26,
    fontFamily: 'BebasNeueBold',
    lineHeight: '1.2em',
    textTransform: 'uppercase',
    color: colors.textColor
  },
  rowStyle: {
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: '1px solid lightgrey'
  },
  days: {
    color: colors.grey,
    fontSize: 11,
    textTransform: 'uppercase'
  },
  daysLeftContainer: (isMobile) => ({
    textAlign: 'center',
     paddingLeft: 10,
     fontSize: isMobile? 10 : 14
  }),
  daysLeft: {
    alignSelf: 'center'
  },
  cleanRowStyle: {
    paddingTop: 15,
    paddingBottom: 15,
    position: 'relative'
  },
  relatedRow: (isMobile) => ({
    marginTop: 10,
    borderRight: isMobile ? 'none' : '1px solid lightgrey',
    paddingLeft: 8
  }),
  athleteMainContainer: (marginTop) => ({
    ...styles.mainContainer,
    WebkitFontSmoothing: 'antialiased',
    marginTop
  }),
  pseudoStyles: () => `
    .no-gutters {
      border-top: 1px solid ${colors.greyLine};
    }
    .no-gutters:hover, .latest-event:hover {
      background: ${colors.lightGrey} !important;
      cursor: pointer;
    }
    .no-gutters:hover .upcomingRightArrow {
      display: block !important;
    }
    .no-gutters:hover .latest-event-title {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `
};

/**
 * Related athletes styles
 * @type {object}
 */
export const athletePhotoStyles = {
  athPhotoContainer: (wrapperStyle, sizePx) => ({
    position: 'relative',
    zIndex: '1',
    width: sizePx,
    height: sizePx,
    ...wrapperStyle
  }),
  athPhotoHolder: (backgroundUrl, containerStyle, sizePx) => ({
    position: 'relative',
    zIndex: 1,
    width: sizePx,
    height: sizePx,
    borderRadius: '50%',

    backgroundColor: colors.grey,
    backgroundImage: `url("${backgroundUrl}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...containerStyle
  }),
  athInitials: (initialsSize) => ({
      ...fonts.bold,
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: colors.textColor,
      fontSize: initialsSize
  }),
  athPhoto: (imgUrl, sizePx) => ({
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    width: sizePx,
    height: sizePx,
    borderRadius: '50%',
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
}

export const previewStyles = {
  previewContainer: {
    fontFamily: 'ProximaNovaRegular',
    color: colors.darkGrey3,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center'
  },
  locationStyle: (isMobile) => ({
    display: 'inline-block',
    fontSize: 13,
    color: (isMobile) ? colors.grey : colors.darkGrey3
  })
};
