import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation , Trans} from 'react-i18next';
import {riStyles, eventStyles} from './styles';
import {calculatePaceArrForResult, getResultsUrlForBib} from '../../utils/resultsHelpers';
import {Goal} from './upcomingEvents/UpcomingEvent';
import {Link} from 'react-router-dom';
import {Location} from '../shared/Location';
import {DateTime} from '../shared/DateTime';
import {DropDown} from '../shared/Elements';
import {SearchField} from '../shared/Elements';
import {colors} from '../../shared/styles';
import Style from 'style-it';
import {isLoggedIn, getTokenRacerId} from '../../utils/isLoggedIn';
import { LogInOrSignUp } from '../../components/modal/LogInOrSignUp';
import { stripLeadingZero } from '../../shared/util';
import {
  getClaimStatusMessage,
  getClaimStatusDescription
} from '../../utils/ClaimUtil';
import {HelpIcon} from '../shared/icons';
import {Tooltip} from '../tooltip/Tooltip';
import {
  buildGroupOptions,
  buildRaceTypeOptions,
  isResultValid,
  searchResults,
  raceTypeFilter
} from '../../utils/ProfileUtil';

/**
 * Renders all results.
 */
class ResultsComponent extends Component {

  static propTypes = {
    athleteId: PropTypes.string.isRequired,
    athlete: PropTypes.object.isRequired,
    races: PropTypes.array,
    fetching: PropTypes.bool,
    isMobile: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    races: [],
    fetching: false,
  };

  state = {
    top: 50,
    courseFilter: 'all',
    groupBy: 'year',
    searchTerm: ''
  };

  /**
   * Action performed on on search. Simply set state and render with handle
   * filtering.
   *
   * @param {object} event
   * @param {string} value
   * @param {number} index
   */
  onSearch = ({ target: { value } }) =>
    this.setState({searchTerm: value, top: 50});

  onCourseFilterChange = (e) => {
    this.setState({
      courseFilter: e.target.value
    })
  };

  onGroupByChange = (e) => {
    this.setState({
      groupBy: e.target.value
    })
  };

  /**
   * Extracts information from result row.
   * Can find year, category pattern or race description
   *
   * @param {string} type Type of sort(group)
   * @return {function}
   */
  get = (type) => {
    const groupMethods = {
      year: ({race: {raceDate}}) => new Date(raceDate).getFullYear(),
      category: ({race: {courses: [{raceCatDesc}]}}) => raceCatDesc,
      race: ({race: {courses: [{coursePattern}]}}) => coursePattern,
      event: ({race: {masterName}}) => masterName
    };
    return groupMethods[type]
  };

  /**
   * Sorting method for categories
   *
   * @param {string} type Type of sort(group)
   * @return {function}
   */
  sort = (type) => {
    const sortMethods = {
      year: (y1, y2) => y2 - y1,
      category: (c1, c2) => c1.localeCompare(c2),
      race: (r1, r2) => r1.localeCompare(r2),
      event: (e1, e2) => e1.masterEventID === e2.masterEventID
    };
    return sortMethods[type];
  };

  /**
   * Helper method to group results by filtered value
   *
   * @param {object} acc
   * @param {object} result
   * @return {object}
   */
  categorise = (acc, result) => {
    const {groupBy} = this.state;
    const key = this.get(groupBy)(result);
    const grouped = acc[key] || [];
    acc[key] = [...grouped, result];

    return acc;
  };

  /**
   * Method to load 10 more results in results tab. Update this.state.top
   */
  loadMore = () => {
    this.setState({top: this.state.top + 50});
  };

  render() {
    const {
      athlete: {DisplayName},
      races,
      fetching = false,
      isMobile,
      t
    } = this.props;

    const {
      courseFilter,
      groupBy,
      searchTerm
    } = this.state;

    const filtered = races
        .filter((filterData) => searchResults(filterData, searchTerm))
        .filter((filterData) => raceTypeFilter(filterData, courseFilter))
        .filter(isResultValid)
      || [];
    const totalNum = filtered.length;

    const sliced = filtered.slice(0,this.state.top);

    const filteredNum = sliced.length;
    const showingNum = (totalNum === filteredNum) ? t('All').toLowerCase()
      : filteredNum;

    const scroller = (totalNum > 50);
    const loadMore = (this.state.top >= races.length || showingNum < this.state.top);
    const categorized = sliced.reduce(this.categorise, {});

    const filterStyle = {
      width: '200px',
      paddingRight: '24px'
    };

    return (
      <div
        className={`row ${isMobile ? 'px-0' : 'mx-0'}`}
        style={riStyles.resPage(isMobile)}
      >
        {
          isMobile &&
          <h2 style={riStyles.allResults} className='col-12'>
            {t('All results')}
          </h2>
        }

        {/*Central part for showing results */}
        <div className="col-12 col-md-12">
          <div className='row' style={riStyles.titleRow}>
            { !isMobile &&
              <div className='col-sm-8'>
                <h2 style={riStyles.title(isMobile)} id='results-title'>
                  {t('Results')}
                </h2>
                <div style={riStyles.detailRow} id='results-subtitle'>
                  <Trans>
                    Showing <span style={{color: colors.black}}>{{resNum: showingNum}}</span> of {{name: DisplayName}}'s <span style={{color: colors.black}}>{{totalNum}}</span> results
                  </Trans>
                </div>
                <div style={riStyles.resultDetailLoader(fetching)}/>
              </div>
            }
            <div className='col-sm-4 col-12'>
              <SearchField
                id="searchResults"
                placeholder={t('Search by event name')}
                onChange={this.onSearch}
                name="searchResults"
                value={searchTerm}
              />
            </div>

            { !isMobile &&
            <div className='col-12'>
              <div className='row' style={riStyles.filtersContainer(isMobile)}>
                <div style={filterStyle}>
                  <DropDown
                    id='filter'
                    label={t('Filter')}
                    options={buildRaceTypeOptions(races, t)}
                    onChange={this.onCourseFilterChange}
                    value={courseFilter}
                  />
                </div>
                <div style={filterStyle}>
                  <DropDown
                    id='group'
                    label={t('Group by')}
                    options={buildGroupOptions(t)}
                    onChange={this.onGroupByChange}
                    value={groupBy}
                  />
                </div>
              </div>
            </div>
            }


          </div>

          <div style={riStyles.resultsDesktopHeader(fetching)}>
            {!isMobile && <ResultsDesktopHeader t={t} />}

            { // Loading placeholder
            }
            <div style={riStyles.loading(fetching)}>
              <div style={riStyles.loadingCategoryHead}/>
              <ResultLoader />
              <ResultLoader />
              <ResultLoader />
            </div>

            { Object.keys(categorized)
                .sort(this.sort(groupBy))
                .map((category, index) => (
                  <div key={index}>
                    {
                      !isMobile &&
                      <div style={riStyles.yearRow} className='year-category-section'>{category}</div>
                    }
                    {
                      this.renderCategory(categorized[category])
                    }
                  </div>
                ))
            }
          </div>

          { scroller && !loadMore && !fetching &&
            <div style={riStyles.loadMoreContainer}>
              <button
                style={riStyles.loadMoreButton}
                onClick={this.loadMore}
                id='load-more-button'
              >
                {t('Load More')}
              </button>
            </div>
          }
        </div>
      </div>
    );
  }

  /**
   * Renders category results
   *
   * @param {array} categoryResults
   * @return {XML}
   */
  renderCategory = (categoryResults) => categoryResults.map((race, index) => {
    const {
      isMobile,
      t
    } = this.props;
    const {racerID} = race;
    const ResultComponent = isMobile ? ResultMobile : ResultDesktop;
    return (
      <ResultComponent
        key={`race-result-${index}`}
        {...race}
        isMe={parseInt(this.props.athleteId) === getTokenRacerId()}
        athleteID={racerID}
        isMobile={isMobile}
        t={t}
      />
    );
  })

}
export const Results = withTranslation()(ResultsComponent);

const ResultLoader = () => (
  <div style={riStyles.loadingEvent}>
    <div className='col-5 pl-0'>
      <div style={riStyles.loadingEventTitle}/>
      <div style={riStyles.loadingEventDetail}/>
    </div>
    <div className='col-7'>
      <div className='row' style={{alignItems: 'center'}}>
        <ResultRankLoader />
        <ResultRankLoader />
        <ResultRankLoader />
        <ResultRankLoader />
        <div className='col-3'>
          <div style={riStyles.loadingEventTime}/>
        </div>
        <div className='col-1'/>
      </div>
    </div>
  </div>
);

const ResultRankLoader = () => (
  <div className='col-2'>
    <div style={riStyles.loadingRank}/>
    <div style={riStyles.loadingRankUnit}/>
  </div>
);



export const ResultsDesktopHeader = ({t}) => (
  <div style={riStyles.tableHeaderWrapper}>
    <div className='col-5' style={riStyles.tableHeaderLeftCell}>
      {t('Event')}
    </div>
    <div className='col-7'>
      <div className='row'>
        <div className='col-2'>{t('Overall')}</div>
        <div className='col-2'>{t('Gender')}</div>
        <div className='col-2'>{t('Division')}</div>
        <div className='col-2'>{t('Pace')}</div>
        <div className='col-3'>{t('Final Time')}</div>
      </div>
    </div>
  </div>
);



export const ResultMobile = (props) => {
  const {
    race,
    isUnofficial,
    ticks,
    rankO,
    countO,
    unofficialTime,
    ticksString,
    tickString,
    entryID,
    isMobile,
    eventCourseID,
    bibNum,
    isMe,
    claimStatus,
    t
  } = props;
  const {
    raceName, raceDate, city, stateProvAbbrev, countryID, courses, raceID, masterEventID
  } = race;
  const {raceCatDesc, distUnit} = courses[0];
  const pace = calculatePaceArrForResult({
    raceCatDesc, distUnit, ticks
  });

  const irpUrlBib = isUnofficial && masterEventID
    ? null
    : getResultsUrlForBib(raceID, eventCourseID, bibNum, masterEventID, entryID);

  const row =
    <div>
      <div style={riStyles.resultMobileTitle}>
        <div>{raceName}</div>
      </div>
      <div style={{...riStyles.dateLocationRow, marginBottom:4}}>
        <DateTime
          date={raceDate}
          showTime={false}
          icon={null}
          style={riStyles.resultRowDate}
        />
        <Location
          style={riStyles.resultRowLocation}
          city={city}
          state={stateProvAbbrev}
          country={countryID}
          icon={null}
          searchType={null}
        />
      </div>
      <div className='row mx-0' style={eventStyles.eventBorderContMobile}>
        {/*Overall*/}
        <Goal
          className='col'
          title={t('Overall')}
          value={rankO || '--'}
          footer={countO ? `of ${countO}` : null}
          isMobile={isMobile}
          isTime={false}
          isFinalTime={false}
        />
        {/*Pace*/}
        <Goal
          className='col'
          title={t('Pace')}
          value={pace[0] || '--'}
          footer={pace[1] ? pace[1] : null}
          isMobile={isMobile}
          style={eventStyles.paceCell}
          isTime={true}
          isFinalTime={false}
        />
        {/*Final time*/}
        <Goal
          className='col'
          title={t('Final Time')}
          value={isUnofficial ? unofficialTime || tickString : ticksString}
          isMobile={isMobile}
          isTime={true}
          isFinalTime={true}
          claimMessage={getClaimStatusMessage(claimStatus, t)}
        />
      </div>
    </div>;

  const linkedRow = irpUrlBib
    ? <Link style={riStyles.resultRowLink(false)} to={irpUrlBib}>
        {row}
      </Link>
    : row;

  return (
    <div style={{marginBottom: 30}}>
      {linkedRow}
      <div style={{marginTop: 4, display: 'none'}}>
        { isMe &&
          <LogInOrSignUp
            control='removeResultsUnofficial'
            isLoggedIn={isLoggedIn()}
            eventCourseId={raceID}
            raceName={raceName}
            raceDate={raceDate}
            isMobile={isMobile}
            athleteId={getTokenRacerId()}
            buttonMode="edit"
          />

        }
      </div>
    </div>
  );
};

export class ResultDesktop extends Component {

  state = {
    hover: false,
  };

  render() {
    const {
      race,
      isUnofficial,
      isVirtual,
      ticks,
      rankA,
      rankG,
      rankO,
      countA,
      countG,
      countO,
      unofficialTime,
      ticksString,
      tickString,
      entryID,
      eventCourseID,
      bibNum,
      claimStatus,
      isMobile,
      t
    } = this.props;
    const ResultRowStat = ({c = 'col-2', rank, count, isTime = false, isStatus = false}) => {
      if (count) {
        if (isTime || isStatus) {
          return (
            <div className={c} style={riStyles.resultRowCell}>
              <div style={isTime ? riStyles.resultRowTime : riStyles.resultRowStat}>
                {rank || ''}
              </div>
              <div style={riStyles.resultRowUnit}>
                {
                  isStatus ? <div style={{
                    fontFamily: 'ProximaNovaBold', 
                    color: colors.blue, 
                    fontSize: 12, 
                    textTransform: 'uppercase',
                    display: 'inline-block',
                    marginTop: 2
                  }}>{count}</div> : count.toLowerCase()
                }
                {
                  isStatus &&
                  <Tooltip
                    icon={<HelpIcon style={riStyles.resultRowTooltipIcon}/>}
                    tip={{id: `${claimStatus}-tooltip`, content: getClaimStatusDescription(claimStatus, t)}}
                    style={riStyles.resultRowTooltip}
                  />
                }
              </div>
            </div>
          );
        } else {
          return (
            <div className={c} style={riStyles.resultRowCell}>
              <div style={riStyles.resultRowStat}>{rank}</div><div style={riStyles.resultRowUnit}>{t('of {{count}}', {count})}</div>
            </div>
          );
        }
      } else {
        return (
          <div className={c} style={riStyles.resultRowCell}>
            <div style={isTime ? riStyles.resultRowTime : riStyles.resultRowStat}>
              {rank || ''}
            </div>
          </div>
        );
      }
    };

    const {
      raceName, raceDate, city, stateProvAbbrev, countryID, courses, raceID, masterEventID
    } = race;
    const {raceCatDesc, distUnit} = courses[0];
    const pace = ticks ? calculatePaceArrForResult({
      raceCatDesc, distUnit, ticks
    }) : (this.props.pace || '').split(' ');
    const irpUrlBib = isUnofficial && masterEventID
      ? null
      : getResultsUrlForBib(raceID, eventCourseID, bibNum, masterEventID, entryID);
    const placeholder = '--';
    const rowClass = (isUnofficial, isVirtual) => {
      let classNames = isUnofficial ? 'unofficial-result' : '';
      classNames += isVirtual ? ' virtual-result' : '';
      return (classNames.trim());
    }
    const row =
      <div style={riStyles.resultRowWrapper} className={rowClass(isUnofficial, isVirtual)}>
        <div className="col-5 pl-0">
          <div style={riStyles.resultRowEvent} className='race-name'>
            {raceName}
          </div>
          <div style={riStyles.dateLocationRow}>
            <DateTime
              date={raceDate}
              showTime={false}
              icon={null}
              style={riStyles.resultRowDate}
            />
            <Location
              city={city}
              state={stateProvAbbrev}
              country={countryID}
              icon={null}
              searchType={null}
              style={riStyles.resultRowLocation}
            />
          </div>
        </div>
        <div className="col-7">
          <div className='row'>
            {/*Overall*/}
            <ResultRowStat
              rank={rankO || placeholder}
              count={countO || ''}
            />
            {/*Gender*/}
            <ResultRowStat
              rank={rankG || placeholder}
              count={countG || ''}
            />
            {/*Age*/}
            <ResultRowStat
              rank={rankA || placeholder}
              count={countA || ''}
            />
            {/*Pace*/}
            <ResultRowStat
              rank={stripLeadingZero(pace[0] || placeholder)}
              count={pace[1] || ''}
              isTime={true}
            />
            {/*Finish Time*/}
            <ResultRowStat
              rank={stripLeadingZero(isUnofficial ? unofficialTime || tickString : ticksString)}
              count={getClaimStatusMessage(claimStatus, t)}
              isTime={true}
              isStatus={true}
              c={'col-3'}
            />
          </div>
        </div>
      </div>;

    const linkedRow = irpUrlBib
      ? <Link
        to={irpUrlBib}
        style={riStyles.resultRowLink(this.state.hover)}
        onMouseEnter={() => this.setState({hover:true})}
        onMouseLeave={() => this.setState({hover:false})}
        className='race-result'>
        {row}
      </Link>
      : row;

    return (
      Style.it(eventStyles.pseudoStyles(isMobile),
        linkedRow
      )
    );
  }
}
