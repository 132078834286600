import React from 'react';
import {Link} from 'react-router-dom';

export const NoResults = (
  {
    link,
    styles,
    messagesLink,
    messgesBody,
    id
  }) => (
  <div>
    <Link to={link}>
      <div style={styles.topLabel}>
        <span style={styles.spanSectionLabel}>{messagesLink}</span>
      </div>
    </Link>
    <div style={styles.resultContent}>
      <div id={id} style={styles.resultRow(false)}>
        {messgesBody}
      </div>
    </div>
  </div>
);
