
export const fonts = {
  serif: {
    color: '#000',
    fontFamily: 'MercuryDisplayBold,serif',
  },
  default: {
    fontFamily: 'ProximaNovaRegular,helvetica,arial,sans-serif',
  }
};

export const textStyles = {
  raceTitleDarkDesktop: Object.assign({}, fonts.serif, {
    color: '#344253',
    fontSize: '45px',
    letterSpacing: '-0.22px',
    lineHeight: '40px',
  }),
  subhead1Grey: Object.assign({}, fonts.default, {
    color: '#99A1A9',
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '16px',
    letterSpacing: '2.56px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  }),
  subhead2Grey: Object.assign({}, fonts.default, {
    color: '#99A1A9',
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '24px',
    letterSpacing: '2.56px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  }),
  subhead2MenuWhite: Object.assign({}, fonts.default, {
    color: '#FFFFFF',
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '14px',
    letterSpacing: '2.8px',
    lineHeight: '20px',
    textTransform: 'uppercase',
  }),
  body1Dark: Object.assign({}, fonts.default, {
    color: '#344253',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '2.8px',
    textTransform: 'uppercase',
  }),
  body2Dark: Object.assign({}, fonts.default, {
    color: '#344253',
    fontSize: '14px',
    lineHeight: '28px',
    letterSpacing: '.28px',
  }),
  body1Grey: Object.assign({}, fonts.default, {
    color: '#99A1A9',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '2.8px',
    textTransform: 'uppercase',
  }),
  captionGrey: Object.assign({}, fonts.default, {
    color: '#99A1A9',
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '3.9px',
    textTransform: 'uppercase',
  }),
  headlineDark: Object.assign({}, fonts.default, {
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '4.8px',
    textTransform: 'uppercase',
    color: '#344253',
  }),
  textLink1Blue: Object.assign({}, fonts.default, {
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '.36px',
    color: '#16A9E1',
  }),
  textLink2Blue: Object.assign({}, fonts.default, {
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '14px',
    lineHeight: '28px',
    letterSpacing: '.28px',
    textDecoration: 'none',
    color: '#16A9E1',
  }),
}
