import { colors } from '../../shared/styles';

export const styles = {
  eventName: {
    fontFamily: 'ProximaNovaBold',
    fontSize: 24,
    lineHeight: '27px',
    fontColor: colors.fontColor,
    marginBottom: -18
  },
  loaderWrap: {
    width: '100%',
    height: 70,
    marginTop: 30
  },
  noSearchResultsMessage: {
    fontSize: 20,
    letterSpacing: 0,
    paddingBottom: 20,
    lineHeight: 1.7
  },
  contentCard: (isMobile) => ({
    padding: isMobile ? '15px' : '20px',
    backgroundColor: colors.white,
    minHeight: 300,
    marginBottom: '20px'
  }),
  pagerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20
  },
};
