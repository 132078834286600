import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { NotFound } from '../../views/notFound/NotFound';
import { CuratedEvents } from '../../components/home/curatedEvents/CuratedEvents';
import { PageTemplate } from '../home/PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import { getLeastFutureForCategory } from '../../data/EventResultsStreams';

import {
  getCategoryById
} from './mockCuratedData';

import { connectStream } from '../../lib/bastetjs/utils/connectStream';

export class CuratedEventsPageComponent extends Component {
  static propTypes = {
    categoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    leastFutureResults: PropTypes.object,
    isMobile: PropTypes.bool,
    windowWidth: PropTypes.number,
    t: PropTypes.func
  };

  render() {
    const {
      categoryId,
      isMobile,
      windowWidth,
      leastFutureResults,
      leastFutureResults: { result: {eventIDs, events = {}} = {} } = {},
      t
    } = this.props;


    if (leastFutureResults && leastFutureResults.error){
      return (
        <NotFound errorStatus={404} />
      )
    }

    const categoryInfo = getCategoryById(categoryId);
    if (!categoryInfo) {
      return null;
    }

    const lookedUpEvents = (eventIDs || [])
      .map((id) => ({...((events[id] || {}).event)}))
      .filter((lookedUpEvent) => !!lookedUpEvent.masterId);

    const title = t('Athlinks Featured Events');
    const description = t(
      'View {{curatedGroup}} events on Athlinks',
      {curatedGroup: categoryInfo.name}
    );

    return (
      <PageTemplate
        unifiedSearchMode={true}
        headerIsFixed={true}
        isMobile={isMobile}
        paddingTop={0}
        bannerTopOffset={50}
      >
        <StandardHeaderTags
          title={title}
          description={description}
        />
        <OpenGraphTags
          ogType='website'
          title={title}
          description={description}
        />

        <CuratedEvents
          category={categoryInfo}
          events={lookedUpEvents}
          isMobile={isMobile}
          windowWidth={windowWidth}
        />
      </PageTemplate>
    );
  }
}

function getStreams({categoryId}) {
  const categoryInfo = getCategoryById(categoryId) || {};
  return getLeastFutureForCategory(categoryInfo.idName);
}

export const CuratedEventsPage = withTranslation()(connectStream({
  leastFutureResults: getStreams
})(CuratedEventsPageComponent));

