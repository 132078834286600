import React, {Component} from 'react';
import {aoStyles} from './styles';
import {AthletePhoto} from './AthletePhoto';
import {ImagePickerButton} from '../shared/ImagePickerButton';
import {setAthletePhoto} from '../../data/EditProfileApi';

/**
 * Renders profile image. If isMe then also renders photo upload
 */
export class Avatar extends Component {

  static defaultProps = {
    fetching: false,
  };

  state = {
    showEditIcon: false,
    photoUploading: false
  };

  /**
   * Handles uploading profile photo
   * @param {string} dataURL
   */
  handlePhotoUpload = (dataURL) => {
    const {athlete} = this.props;
    const {RacerID} = athlete
    this.setState({
      photoUploading: true
    }, () => {
      setAthletePhoto(RacerID, dataURL)
        .catch((error) => {
          console.error(`Error uploading photo: ${JSON.stringify(error)}`);
        })
        .finally(this.doneUploading)
    });
  };

  /**
   * On mouse over profile photo we set state that photo should show.
   *
   * @param {bool} shouldShow
   */
  toggleEditIcon = (shouldShow = false) =>
    this.setState({showEditIcon: shouldShow});

  /**
   * Check if edit icon should be shown
   * @return {boolean}
   */
  showEditIcon = () => {
    const {photoUploading, showEditIcon} = this.state;
    const {isMobile} = this.props;
    return isMobile || photoUploading || showEditIcon;
  };

  /**
   * After upload is completed reset photo upload values.
   */
  doneUploading = () => this.setState({
    showEditIcon: false,
    photoUploading: false
  });

  /**
   * Calculate photo width based on window width
   *
   * @return {*}
   */
  determinePhotoWidth() {
    const {windowWidth} = this.props;
    if (windowWidth >= 1200) return null;
    else if (windowWidth >= 992) return 100;
    else if (windowWidth >= 768) return 80;
    else if (windowWidth >= 601) return 50;
    else return 50;
  }

  render() {
    const {
      athlete,
      fetching,
      isMobile,
      isMe,
    } = this.props;
    const isMember = athlete.IsMember
    const name = athlete.DisplayName
    const photo = athlete.LogoUrls

    const {
      photoUploading
    } = this.state;

    const photoWidth = this.determinePhotoWidth() || 140;
    return (
      <div style={aoStyles.photoSkeleton(photoWidth)}>
        <div
          style={aoStyles.photoBox(isMobile)}
          onMouseEnter={this.toggleEditIcon.bind(this, true)}
          onMouseLeave={this.toggleEditIcon.bind(this, false)}
        >
          {
            <AthletePhoto
              isMember={isMember}
              size={isMobile ? 'Medium' : 'Large'}
              photo={photo}
              name={name}
              width={photoWidth}
            />
          }
          {
            isMe &&
            <ImagePickerButton
              style={aoStyles.photoEditButton(this.showEditIcon())}
              uploading={photoUploading}
              isMobile={isMobile}
              upload={this.handlePhotoUpload}
            />
          }
        </div>

        {
          // Loading placeholder
        }
        <div style={aoStyles.loading.photo(photoWidth, fetching)}>
        </div>
      </div>
    );
  }
}
