import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CameraUpload from 'react-icons/lib/ti/camera-outline';
import Cropper from 'react-cropper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PhotoUpload from 'react-icons/lib/fa/camera';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { Button as FlatButton } from '../shared/Button'

class ImagePickerButtonComponent extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    uploading: PropTypes.bool.isRequired,
    upload: PropTypes.func.isRequired,
    t: PropTypes.func
  };

  state = {
    pickingFile: false,
    showModal: false,
    pickedDataURL: null,
    previewDataURL: null
  };

  constructor(props) {
    super(props)

    this.createStyledComponents(props)
  }

  createStyledComponents(props) {
    const style = props.isMobile ? styles.mobile : styles.desktop;

    this.StyledDialog = withStyles({
      paper: {
        ...style.contentStyle
      }
    })(Dialog)

    this.StyledDialogActions = withStyles({
      root: {
        ...style.actionsContainerStyle
      }
    })(DialogActions)

    this.StyledDialogContent = withStyles({
      root: {
        ...style.bodyStyle
      }
    })(DialogContent)
  }

  onPicked = (e) => {
    if (!e.target.files || !e.target.files.item) {
      return;
    }

    const file = e.target.files.item(0);

    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        pickingFile: false,
        pickedDataURL: e.target.result,
        showModal: true
      })
    };

    this.setState({
      pickingFile: true
    }, () => {
      reader.readAsDataURL(file);
    });
  };

  onCrop = () => {
    // image in dataUrl
    this.setState({
      previewDataURL: this.refs.cropper ? this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg') : null
    })
  };

  render() {
    const {
      style: wrapperStyle = {},
      uploading,
      isMobile,
      t
    } = this.props;

    const {
      pickedDataURL,
      pickingFile,
      previewDataURL,
      showModal
    } = this.state;

    const buttonText = uploading
      ? t('Uploading...')
      : pickingFile ? t('loading...')
        : t('Upload Photo');

    const buttonDisabled = uploading;

    const style = isMobile ? styles.mobile : styles.desktop;

    const standardActions = (
      <div>
        <div style={{textAlign: 'center', padding: '16px 0 40px 0'}}>
          <FlatButton
            onClick={this.closeModal}
            style={{
              ...styles.modalButton,
              ...styles.modalButtonLabelStyle
            }}
            text={t('Cancel')}
          />
          <FlatButton
            disabled={buttonDisabled}
            onClick={this.upload}
            style={{
              ...styles.modalButton,
              ...styles.modalButtonLabelStyle
            }}
            text={t('Crop and Upload')}
          />
        </div>
      </div>
    );

    //fileInput is unmounted every time we show modal so its onChange triggers next time it's mounted
    //even when we pick the same file multiple times.
    return (
      <div ref='imagePicker' style={{
        position: 'absolute',
        zIndex: 2,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...wrapperStyle
      }}>
        {!showModal &&
        <input
          ref='fileInput'
          type='file'
          accept='image/*'
          multiple={false}
          style={{display: 'none'}}
          onChange={this.onPicked}
        />
        }

        { !isMobile &&
          <button style={!isMobile ? {...style.buttonStyle} : {cursor: 'pointer', width: 50, height: 50}}
                              label={buttonText}
                              onClick={this.pickFile}>
            <div><PhotoUpload color='#16A9E1' size="40"/></div>
          </button>
        }

        { isMobile && <div style={style.mobileButtonContainer}>
          <button label={buttonText}
                style={style.mobileButton}
                onClick={this.pickFile}><CameraUpload color='#16A9E1' size="18"/>
          </button>
        </div> }

        {showModal &&
        <this.StyledDialog
          onClose={this.closeModal}
          open={true}
        >
          <this.StyledDialogContent>
            <div>
              <div style={{whiteSpace: 'nowrap'}}>
                {pickedDataURL &&
                <div style={{display: 'inline-block', marginRight: 20, marginTop: 40}}>
                  <Cropper
                    ref='cropper'
                    src={pickedDataURL}
                    viewMode={0}
                    style={{width: 400, height: 300}}
                    // Cropper.js options
                    aspectRatio={1}
                    guides={true}
                    crop={this.onCrop}
                  />
                </div>
                }
                {previewDataURL &&
                  <img
                    style={{width: 300, height: 300}}
                    src={previewDataURL}
                    alt={''}
                  />
                }
              </div>
            </div>
          </this.StyledDialogContent>
          <this.StyledDialogActions>
            {standardActions}
          </this.StyledDialogActions>
        </this.StyledDialog>
        }

      </div>
    );
  }

  pickFile = () => {
    if (this.refs.fileInput) {
      this.refs.fileInput.click();
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      pickedDataURL: false,
      previewDataURL: null
    })
  };

  upload = () => {
    const {
      upload
    } = this.props;

    const {
      previewDataURL
    } = this.state;

    //closeModal destroys state.previewDataURL so don't call it before grabbing it
    this.closeModal();

    //upload cropped image
    upload(previewDataURL);
  }
}

export const ImagePickerButton = withTranslation()(ImagePickerButtonComponent);

const styles = {
  modalButton: {
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0 0 0'
    },
    backgroundColor: '#fff',
    border: '2px solid #33aade',
    boxShadow: '0 0 0',
    color: '#33aade',
    fontSize: 17,
    height: 40,
    margin: '0px 20px 0px 0px',
    marginRight: 6,
    minWidth: 100,
    paddingBottom: 10,
    textTransform: 'capitalize',
  },
  modalButtonLabelStyle: {
    fontFamily: 'ProximaNovaRegular',
    fontSize: 14,
    // padding: '0 16px',
    textTransform: 'uppercase'
  },
  modalH1: {
    fontSize: 34,
    width: '100%',
    color: '#4a4a4a',
    fontFamily: 'ProximaNovaRegular'
  },
  desktop: {
    actionsContainerStyle: {
      justifyContent: 'center',
      padding: '0',
    },
    bodyStyle: {
      color: '#000',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '0 40px 16px 40px',
      overflowY: 'scroll'
    },
    contentStyle: {
      padding: '0',
      minWidth: '430px',
      maxWidth: '800px'
    },
    titleContainerStyle: {
      padding: '0',
      textAlign: 'center'
    },
    titleStyle: {
      textAlign: 'center',
      padding: '12px 40px 24px 40px',
      fontSize: '45px'
    },
    buttonStyle: {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      backgroundSize: 'cover',
      borderRadius: '50%',
      width: 150,
      height: 150,
      border: 'none',
      outline: 'none',
      cursor: 'pointer'
    }
  },
  mobile: {
    actionsContainerStyle: {
      justifyContent: 'center',
      padding: '0',
    },
    mobileButtonContainer: {
      marginTop: 40,
      marginLeft: 8,
      width: 32,
      height: 20,
      textAlign: 'center',
    },
    mobileButton: {
      paddingLeft: 7,
      background: '#fff',
      border: 'none',
      boxShadow: '#888888 1px 1px 4px',
      borderRadius: '20%',
      outline: 'none'
    },
    bodyStyle: {
      color: '#000',
      fontSize: '1em',
      lineHeight: '1.5em',
      padding: '10px 0',
      overflowY: 'scroll',
    },
    contentStyle: {
      padding: '0',
      width: window.innerWidth - 24,
      maxWidth: 600,
      margin: 12,
    },
    titleContainerStyle: {
      padding: '0'
    },
    titleStyle: {
      textAlign: 'center',
      marginBottom: '12px',
      fontSize: '1.5em'
    },

  }
};
