import React, {Component} from 'react';
import {compose} from 'lodash/fp';
import {withTranslation} from 'react-i18next';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import { withRouter } from 'react-router-dom';

import Checkbox from './Checkbox';
import FilterControls from './FilterControls';
import {_filterTab} from './styles';
import {getUnclaimedResultsStream} from '../../data/UnclaimedSearchStreams';
import MobileFilterHeader from './MobileFilterHeader';
import { getSearchTerm } from '../../data/SearchTerm';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters'
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';

const streamId = 'unclaimed-results-search-filters-stream';

const EmptyState = ({isMobile, hasSearchValue, onClose, t}) => (<div
  onClick={(e) => e.stopPropagation()}
  style={_filterTab.raceFilterWrap(isMobile)}>
    {isMobile && <MobileFilterHeader
      title={t('Locations')}
      onClose={onClose}
    />}
    <div style={{padding: 10}}>
      {!isMobile && <div style={_filterTab.locationsHeader}>
        {t('Locations')}
      </div>}
      <div style={_filterTab.locationsMsg}>
        { hasSearchValue? t(`No Locations were found for this search`)
        : t(`Enter a name in the search above to filter by locations`)}
      </div>
    </div>
</div>);

class LocationFilter extends Component {

  constructor(props) {
    super(props);
    this.locations = defaultFilterData.filters.locations
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.values && nextProps.values && nextProps.results) {
      const { results = {} } = nextProps;
      const unclaimedResult = (results.results || {}).Result;
      const locationList = (unclaimedResult || {}).Locations;
      const selectedValues = nextProps.values.locationFilter.value;
      const mapSelected = (locationList || []).filter((_) => selectedValues.includes(_.Id))
        .map((_) => ({label: _.LocationString, count: _.ResultCount}));
      this.locations = mapSelected;
    }
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const {
      values,
      results = {},
      history
    } = this.props;

    const unclaimedResult = (results.results || {}).Result;
    const locationList = (unclaimedResult || {}).Locations;

    const selected = locationList
      .filter((location) =>
        this.locations.findIndex((_) => _.label === location.LocationString) !== -1
      ).map((_) => _.Id);
    const filters = {
      ...values,
      locationFilter: {
        enabled: this.locations.length,
        value: selected
      }
    }
    setFilterValues(streamId, filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  render() {
    const {
      isMobile,
      onClose,
      results = {},
      searchTerm,
      t
    } = this.props;

    const unclaimedResult = (results.results || {}).Result;
    const locationList = (unclaimedResult || {}).Locations;
    const locations = (locationList || [])
      .map((_) => ({label: _.LocationString, count: _.ResultCount}));

    if(!searchTerm || !Object.keys(results).length) {
      return <EmptyState
        onClose={onClose}
        isMobile={isMobile}
        t={t}
      />
    }

    if(searchTerm && !locations.length) {
      return <EmptyState
        onClose={onClose}
        isMobile={isMobile}
        hasSearchValue={true}
        t={t}
      />
    }

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.raceFilterWrap(isMobile)}>
          {
            isMobile && <MobileFilterHeader
              title={t('Locations')}
              onClose={onClose}
            />
          }
          <div style={{ padding: 11, maxHeight: 350, overflow: 'auto' }}>
            {!isMobile &&
              <div style={_filterTab.locationsHeader}>
                {t('Locations')}
              </div>
            }
            {
              locations.map((location) =>
                <div key={location.label}>
                  <div style={{display: 'inline-block', paddingLeft: 6}}>
                    <Checkbox
                      label={location.label}
                      onCheck={() => {
                        if (this.locations.findIndex((_) => _.label === location.label) === -1) {
                          this.locations = this.locations.concat(location)
                        } else {
                          this.locations = this.locations.filter((_) => _.label !== location.label)
                        }
                        this.forceUpdate()
                      }}
                      checked={this.locations.findIndex((_) => _.label === location.label) !== -1}
                    />
                  </div>
                </div>
              )
            }
          </div>
        <FilterControls
          onSave={this.submitFilter}
          onReset={() => {
            this.locations = defaultFilterData.filters.locations;
            this.forceUpdate()
          }}
        />
      </div>
    )
  }
};

export default compose(
  withTranslation(),
  connectStream({
    results: () => getUnclaimedResultsStream(),
    values: () => getFilterValues(streamId),
    searchTerm: getSearchTerm
  }),
  withRouter,
)(LocationFilter);
