import { useStyles } from './styles';

type Props = {
  eventCourse: any,
  intl: any,
  isLoading: boolean,
  roster?: {
    total: number
  }
  term: string,
}
export function SearchResult({
  eventCourse,
  intl,
  isLoading,
  roster,
  term,
}: Props) {
  const styles = useStyles();

  if (isLoading) {
    return null;
  }

  const searchResultMessage = () => {
    const msg = {
      id: 'roster.results',
      defaultMessage: term ?
        `There {numResults, plural,
              =0 {are no athletes}
              =1 {is 1 athlete}
              other {are # athletes}
          } matching "${term}".` :
        `There {numResults, plural,
            =0 {are no athletes}
            =1 {is 1 athlete}
            other {are # athletes}
          } registered for this race.`,
      description: '',
    };
    return intl.formatMessage(msg, { numResults: roster?.total ?? 0 });
  };

  return (
    <>
      <h3 className={styles.searchResultTitle}>{eventCourse?.courseName}</h3>
      <div className={styles.searchResultMessage}>
        {searchResultMessage()}
      </div>
    </>
  );
}
