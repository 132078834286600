
export const DISTANCE_UNITS_TYPES = ['m', 'km', 'mi', 'yd', 'ft', '100m', '100yd'] as const
export type DistanceUnits = typeof DISTANCE_UNITS_TYPES[number]

export const RACE_TYPES = ['running', 'triathlon & multisport']
export type RaceType = typeof RACE_TYPES[number]

export const INTERVAL_RACE_TYPES = ['run' , 'bike' , 'swim', 'other'] as const
export type IntervalRaceType = typeof INTERVAL_RACE_TYPES[number]

export const PACE_UNITS_TYPES = ['none', 'm/s', 'ft/s', 'yd/s', 'mi/hr', 'km/hr', 'min/mi', 'min/km', 'min/100yd', 'min/100m'] as const
export type PaceUnits = typeof PACE_UNITS_TYPES[number]

export const TIME_UNITS_TYPES = ['ms', 's', 'min', 'hr'] as const
export type TimeUnits = typeof TIME_UNITS_TYPES[number]
