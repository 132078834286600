import { keys, omit, map, filter, flow } from 'lodash'

import { logError, logInfo, logWarning } from "./Logging"
import { configs } from '../configs'
import { extractAthleteRacePage } from './getARP'

const callSafely = foo => {
  try {
    if (foo) {
      foo()
    }
  } catch (err) {
    logError('Error in callback', err)
  }
}

let lastId = 0
let subscribers = {}
const addSubscriber = (
  setter
) => {
  const id = lastId++
  subscribers = {
    ...subscribers,
    [id]: setter
  }
  logInfo('Added subscriber to WebSocket', id)
  return `${id}`
}

const removeSubscriber = (
  id
) => {
  subscribers = omit(subscribers, `${id}`)
}

const updateSubscribers = (
  arpUpdate
) => filter(map(subscribers,
  (setNewArp, id) => {
    try {
      return setNewArp(arpUpdate) ? id : undefined
    } catch (err) {
      logError('Subscriber error', err)
    }
    return id
  }))

const getSubscriberCount = () => keys(subscribers).length

let socket = undefined

const getSocketState = () => {
  switch (socket?.readyState) {
    case socket.OPEN:
      return 'OPEN'
    case socket.CONNECTING:
      return 'CONNECTING'
    case socket.CLOSED:
      return 'CLOSED'
    case socket.CLOSING:
      return 'CLOSED'
    default:
      return 'UNDEFINED'
  }
}

const printSocketState = flow(
  getSocketState,
  (state) => logInfo('Socket state is', state)
)

export const getArpWSClient = (
  args,
  streamOverride = false
) => {
  const {
    setARP,
    handleOpen,
    entryId,
    token
  } = args
  if (!socket) {
    logInfo('New socket starting streaming...', `wss://${configs.athleteApiWSUrl}`)
    addSubscriber(setARP)
    socket = new WebSocket(`wss://${configs.athleteApiWSUrl}`)
    printSocketState()
    socket.onopen = () => {
      callSafely(
        handleOpen
      )
      logInfo('Websocket connected...')
      socket.send(JSON.stringify({
        entryId,
        token
      }))
      logInfo('Requested ARP',
        {
          entryId,
          token
        })
    }
    socket.onmessage = async (message) => {
      // This is where your new ARP comes in
      logWarning('Update received:', message)
      logWarning('Data received:', JSON.parse(message.data))
      const arpUpdate = extractAthleteRacePage({
        data: JSON.parse(message.data)
      })
      logInfo('Current number of subscribers', getSubscriberCount())
      const completedSubscribers = updateSubscribers(arpUpdate)
      map(completedSubscribers, removeSubscriber)
      logInfo('Number after clean up', getSubscriberCount())
      if ((!streamOverride &&
        !arpUpdate.stream) ||
        getSubscriberCount() === 0) {
        logInfo('No active connections... closing')
        socket.close()
      }
      printSocketState()
    }

    socket.onerror = (err) => {
      logError('Error encountered for WS for entry', entryId, err)
      try {
        socket.close()
      } catch (e2) {
        logError('Error encountered for WS for entry', entryId, e2)
      }
    }

    socket.onclose = (ev) => {
      logInfo('Socket closing', ev)
      printSocketState()
      socket = undefined
    }

    window.onbeforeunload = (_ev) => {
      try {
        socket.close()
      } catch (err) {
        console.error(`Couldn't close on unload`)
      }
    }
  } else {
    logInfo('Socket already open.')
    printSocketState()
    callSafely(
      handleOpen
    )
    addSubscriber(setARP)
  }
  return socket
}
