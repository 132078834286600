import { useGetFinisherCertificateQuery } from '../../api/finisherCertificateApi'

export default function useFinisherCertificate({
  athlinksEventId,
  bib,
  entryId,
  eventCourseId,
  thirdPartyEntryId,
}: {
  athlinksEventId: number
  bib?: string
  entryId?: number
  eventCourseId: number
  thirdPartyEntryId?: number
}
) {
  const {
    data: url, isError, isFetching,
  } = useGetFinisherCertificateQuery({
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
    thirdPartyEntryId,
  }, {
    skip: !entryId && !thirdPartyEntryId && !bib,
  })

  return {
    error: isError
      ? 'Finisher certificate not currently available, please check back later.'
      : undefined,
    fetching: isFetching,
    url,
  }
}
