import eventBus from '../../lib/event-bus-rx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { styles } from './styles';
import {setTypeaheadQuery, RESULTS_TYPEAHEAD} from '../../data/EventSearchStream';
import { colors } from '../../shared/styles';

const NUM_SUGGESTIONS = 6;

class EventResultsSearchComponent extends Component {
  static propTypes = {
    component: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    component: RESULTS_TYPEAHEAD,
  };

  state = {
    suggestions: {},
    highlighted: null
  };

  getInputText = (e) => (e.target.value || '').toLowerCase().trim();

  inputOnFocus = () => {
    if (!this.props.isMobile) {
      const nodes = document.getElementsByClassName('suggestions');
      [].slice.call(nodes).forEach((_) => _.style.display = '');
    }
  };

  inputOnKeyDown = (e) => {
    if (!this.props.isMobile) {
      if (e.keyCode === 38 && this.state.highlighted !== null) {
        e.preventDefault();
        const pos = this.getInputText(e).length * 2;
        e.target.setSelectionRange(pos, pos);
      }
    }
  };

  inputOnKeyUp = (e) => {
    const {
      component
    } = this.props;

    const query = this.getInputText(e);
    const current = this.state.highlighted;
    setTypeaheadQuery(component, query);

    if (e.key === 'Enter') {
      const entry = current !== null
        ? this.flattenedSuggestions()[current] : null;
      this.setResults(entry)();
      document.getElementById('searchInput').blur();
    }
    else {
      const nodes = document.getElementsByClassName('suggestions');
      [].slice.call(nodes).forEach((_) => _.style.display = '');
    }

    if (e.keyCode === 40) { // Down arrow
      current === null || this.blurSuggestion(current);
      const next = current === null ? 0 : current + 1;
      this.highlightSuggestion(Math.min(next, NUM_SUGGESTIONS-1))();
    }
    else if (e.keyCode === 38) { // Up arrow
      if (current !== null) {
        this.blurSuggestion(current);
        current > 0 ? this.highlightSuggestion(current - 1)()
                    : this.blurSuggestion(current - 1)();
      }
    }
  };

  setResults = () => () => {
    const nodes = document.getElementsByClassName('suggestions');
    [].slice.call(nodes).forEach((_) => _.style.display = 'none');
    this.setState({suggestions: {}, highlighted: null});

    eventBus.publish('event_results_filters', {
      dateOption: this.props.eventId,
      courseOption: 0,
      divisionOption: 0,
      isSearch: !!this.props.searchQuery.length
    });
    eventBus.publish('active_toggle', 'all');
  };

  suggestionText = (res) => {
    const query = this.props.searchQuery;
    const text = [res.bib.trim(), ' ', res.displayName.trim()].join('');

    function sliceMatch(str) {
      const idx = text.toLowerCase().indexOf(str);

      if (idx < 0) {
        const len = str.length;
        if (len > 1) {
          const leftMatch = sliceMatch(str.slice(0, len - 1));
          const rightMatch = sliceMatch(str.slice(1));

          if (leftMatch && rightMatch) {
            return leftMatch[1].length >= rightMatch[1].length
                 ? leftMatch : rightMatch;
          }
          return leftMatch || rightMatch;
        }
      }
      else {
        return [
          text.slice(0, idx),
          text.slice(idx, idx + str.length),
          text.slice(idx + str.length)
        ];
      }
    }

    const sliced = sliceMatch(query);
    const [left, match, right] = sliced || [text, '', ''];

    return (
      <span>
        <span>{left}</span>
        <span style={{color: colors.darkGrey4}}>{match}</span>
        <span>{right}</span>
      </span>
    );
  };

  highlightSuggestion = (idx) => () => this.setState({highlighted: idx});

  blurSuggestion = () => () => this.setState({highlighted: null});

  flattenedSuggestions = () => Object.keys(this.state.suggestions || {})
    .reduce((list, id) => list.concat(this.state.suggestions[id]), [])
    .sort((a, b) => a._score < b._score)
    .slice(0, NUM_SUGGESTIONS);

  renderSuggestions = () => {
    const flattened = this.flattenedSuggestions();
    if (!(this.props.searchQuery || '').length || !flattened.length) {
      return (<div/>);
    }

    const nodes = flattened.map((_, idx) => {
      const highlightedStyle = this.state.highlighted === idx
        ? {backgroundColor: colors.lightBlue} : {};
      return (
        <div key={idx}
             className='suggestions'
             style={Object.assign({}, styles.suggestion, highlightedStyle)}
             onMouseEnter={this.highlightSuggestion(idx)}
             onMouseLeave={this.blurSuggestion(idx)}
             onClick={this.setResults(_)}>
          {this.suggestionText(_)}
        </div>
      );
    });
    const style = !this.props.isMobile
      ? styles.suggestions
      : Object.assign({}, styles.suggestions, {
        width: `${window.innerWidth - 48}px`
      });

    return (<div className='suggestions' style={style}>{nodes}</div>);
  };

  componentDidMount() {
    this.props.searchQuery && this.setResults(null)();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    document.getElementById('searchInput').value = nextProps.searchQuery;
  }

  getInput = (inputStyle, button) => {
    const {
      t
    } = this.props;

    const containerStyle = Object.assign({}, styles.searchContainer, {
      marginTop: this.props.isMobile ? '0px' : '0px'//'24px'
    });
    return (
      <div>
        <div className='searchContainer' style={containerStyle}>
          <form style={{width: '100%'}} onSubmit={(e) => {e.preventDefault(); return false}}>
            <input
              style={inputStyle}
              id='searchInput'
              className='search-input'
              placeholder={t('Search by name or bib number')}
              defaultValue={this.props.searchQuery}
              spellCheck='off'
              autoComplete='off'
              autoCorrect='off'
              type='search'
              onKeyDown={this.inputOnKeyDown}
              onKeyUp={this.inputOnKeyUp}
              onFocus={this.inputOnFocus}
              onClick={this.inputOnFocus}/>
          </form>
          {button || null}
        </div>
        {this.renderSuggestions()}
      </div>
    );
  };

  getButton = () => null;

  render() {
    return this.props.isMobile
       ? this.getInput(styles.mobileSearchInput)
       : this.getInput(styles.searchInput, this.getButton());
  }
}

export const EventResultsSearch = withTranslation()(EventResultsSearchComponent);
