
const keyRegistry = [
  'PROXY_ENDPOINT',
  'ATHLINKS_API_HOST',
  'WEATHER_API_HOST',
  'WEATHER_API_KEY'
];

let configs = {};

/**
 * @param {string} key A config object key.
 * @throws Error if given an unknown config key.
 * @return {void} The given object.
 */
function _checkRegistry(key) {
  if (keyRegistry.indexOf(key) === -1) {
    throw new Error('Attempt to set an unknown key: `' + key + '`');
  }
}

/**
 * @param {object} options Key/value pairs to add to the configs object.
 * @return {void}
 */
function _setConfigs(options) {
  configs = Object.assign({}, configs, options);
}

export const Configure = {
  /**
   * @return object The entire config object.
   */
  getConfigs: () => {
    return configs;
  },
  setConfigs: _setConfigs,
  /**
   * @param {string} key A config object key.
   * @param {bool|null} mustExist Optionally skip existence check (default true).
   * @return {mixed} The corresponding value for the given key.
   * @throws Error if the check is induced in combination w/ a non-existent key.
   */
  getValue: (key, mustExist) => {
    mustExist = mustExist === false ? false : true;

    if (mustExist === true && Object.keys(configs || {}).indexOf(key) === -1) {
      throw new Error('No such Config key `' + key + '`');
    }

    return configs[key] ? configs[key] : undefined;
  },
  /**
   * @param {string} key A config object key.
   * @param {mixed} value Corresponding value for key.
   * @param {bool|null} inRegistry Optionally skip registry check (default true).
   * @return {void}
   * @throws Error if the given key isn't valid, when inRegistry is used.
   */
  setValue: (key, value, inRegistry) => {
    inRegistry === false || _checkRegistry(key);
    _setConfigs({[key]: value});
  }
}