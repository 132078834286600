import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import {styles} from '../body/styles';
import {rankStyles} from './styles';
import RankUtils from '../../../utils/races/RankUtils';

export const HeroCardAllDivisions = (props) => {
  const {
    rankings,
    title,
    isUnranked,
    t
  } = props;

  if (!rankings || rankings.length === 0) return null;

  if (isUnranked) {
    return (
      <div style={styles.cardSectionTitle}>
        {title ? t('{{title}}: Unranked', {title}) : t('Ranking: Unranked')}
      </div>
    );
  }

  return (
    <div>
      <div style={styles.cardSectionTitle}>
        {title || t('Ranking')}
      </div>
      <div className="row">
        { rankings.map((rank, index) =>
            {return rank.label
              ? (
                <div key={index} className="col-4" style={{paddingBottom: 10}}>
                  <HeroCardDivision {...rank} t={t}/>
                </div>
              )
              : null
            }

          )
        }
      </div>
    </div>
  );
};

HeroCardAllDivisions.propTypes = {
  rankings: PropTypes.arrayOf(PropTypes.shape({
    rank: PropTypes.any,
    total: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  title: PropTypes.string,
  isUnranked: PropTypes.bool,
  t: PropTypes.func
};

HeroCardAllDivisions.defaultProps = {
  rankings: []
};



const HeroCardDivisionComponent = (props) => {
  const {
    style,
    label,
    rank,
    total,
    t
  } = props;

  const parsedInt = !isNaN(parseInt(rank)) ? parseInt(rank) : false;
  const hasRank = rank && parsedInt && !RankUtils.isDnf(parsedInt) && !RankUtils.isNoRank(parsedInt);

  const ranking = hasRank
    ? (<div>
        <span style={rankStyles.rank}>{rank}</span>
        <span style={rankStyles.outOf}> {t('/ {{count}}', {count: total})}</span>
      </div>)
    : (
      <div>
        <span style={rankStyles.rank}>--</span>
      </div>
    );

  return (
    <div style={style}>
      <div title={label} alt={label} style={rankStyles.title}>{label}</div>
      {ranking}
    </div>
  );
};

export const HeroCardDivision = withTranslation()(HeroCardDivisionComponent);
