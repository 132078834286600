import { PaceUnits, TIME_UNITS_TYPES, TimeUnits } from '../types.results';

export default function convertPaceUnitsToTimeUnits(paceUnits: PaceUnits): TimeUnits {
  if (paceUnits === 'none') {
    return 'min'
  }

  const [numerator, denominator] = paceUnits.split('/')

  if (TIME_UNITS_TYPES.includes(numerator as TimeUnits)) {
    return numerator as TimeUnits
  }
  else if (TIME_UNITS_TYPES.includes(denominator as TimeUnits)) {
    return denominator as TimeUnits
  }

  return 'min'
}
