import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import {RivalItem} from './RivalItem';
import {SearchField} from '../../shared/Elements';
import {rivalStyles} from '../styles';

class RivalsListComponent extends Component {

  static propTypes = {
    rivals: PropTypes.array.isRequired,
    athlete: PropTypes.object.isRequired,
    showSlideoutOverride: PropTypes.bool,
    isMe: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    scrollThreshold: 500
  };

  state = {
    searchTerm: '',
    showSlideout: false
  };

  onSearch = ({ target: { value } }) => this.setState({ searchTerm: value });

  searchFilter = ({DisplayName}) =>
    DisplayName.toLowerCase().includes(this.state.searchTerm.toLowerCase());

  render() {
    const {
      rivals,
      athlete,
      isMobile,
      isMe,
      fetching,
      t
    } = this.props;
    const { searchTerm } = this.state;

    return (
      <div>

        {
          // Title/search
        }
        <div className='row mx-0' id='rivals-tab' style={rivalStyles.titleRow}>
          <h2
            id="rivals-title"
            className={isMobile ? 'col-12 px-0' : 'col-7 px-0'}
            style={rivalStyles.title(isMobile)}>
            {t('Rivals')}
          </h2>
          <div className={isMobile ? 'col-12 px-0' : 'col-5 px-0'} style={{minHeight:48}} >
            { rivals.length > 0 && !fetching &&

              <SearchField
                placeholder={t('Search by athlete name')}
                id='search-athlete'
                onChange={this.onSearch}
                value={searchTerm}
              />
            }
          </div>
        </div>

        {
          // Table heaader
        }
        { ( rivals.length || fetching ) ?
          <div>
            <div className="row mx-0" style={rivalStyles.listHeader}>
              <div className={isMobile ? 'col-7' : 'col-6'} style={rivalStyles.rowAthName}>
                {t('Name')}
              </div>
              <div className={isMobile ? 'col-5 px-0' : 'col-6 px-0'} style={rivalStyles.rowDataContainer}>
                {
                  !isMobile &&
                  [
                    <div className='col pl-0' key='class'>{t('class')}</div>,
                    <div className='col' key='home'>{t('Home')}</div>
                  ]
                }
                <div className='col'>{t('wins')}</div>
                <div className='col'>{t('losses')}</div>
                <div className='col-1'/> {/*Arrow column*/}
              </div>
            </div>

            {
              // Rival table
            }
            <div style={rivalStyles.rivalTableContainer(fetching)}>
              {
                rivals
                  .filter((racer) => racer.ShowPersonalData)
                  .filter(this.searchFilter)
                  .map((rival, index) =>
                    <Link to={`/athletes/${athlete.RacerID}/rivals/${rival.RacerID}`} key={index} className='rival-item'>
                      <RivalItem
                        rival={rival}
                        athlete={athlete}
                        isMobile={isMobile}/>
                    </Link>
                  )
              }

              {
                // Loading Placeholder
              }
              <div style={rivalStyles.loadingContainer(fetching)}>
                <LoadingRow isMobile={isMobile} />
                <LoadingRow isMobile={isMobile} />
                <LoadingRow isMobile={isMobile} />
                <LoadingRow isMobile={isMobile} />
              </div>


            </div>
          </div>
          :
          <div style={rivalStyles.noRivalsContainer}>
            <div style={rivalStyles.rowName(isMobile)} id='no-rivals'>
              { isMe ?
                t('You don\'t have any Rivals yet.')
                : t(
                  '{{name}} doesn\'t have any Rivals yet.',
                  {name: athlete.FName || athlete.DisplayName || t('Athlete')}
                )
              }
            </div>
            { !isMe ?
              <div style={rivalStyles.noRivalsSubtext} id='no-rivals-subtext'>
                {t('Get out there and race together!')}
              </div>
              : null
            }
          </div>
        }
      </div>
    );
  }
}

export const RivalsList = withTranslation()(RivalsListComponent);

const LoadingRow = ({isMobile}) => (
  <div style={rivalStyles.loadingRow}>
    <div className="col-8 col-lg-6" style={rivalStyles.rowAthName}>
      <div style={rivalStyles.loadingTitle}/>
      <div style={rivalStyles.loadingDetails}/>
    </div>
    <div className='col-4 col-lg-6 px-0' style={rivalStyles.rowDataContainer}>
      {
        !isMobile &&
        [
          <div className='col pl-0' key='class'>
            <div style={rivalStyles.loadingStat}/>
          </div>,
          <div className='col' key='home'>
            <div style={rivalStyles.loadingStat}/>
          </div>
        ]
      }
      <div className='col'>
        <div style={rivalStyles.loadingStat}/>
      </div>
      <div className='col'>
        <div style={rivalStyles.loadingStat}/>
      </div>
      <div className='col-1'/> {/*Arrow column*/}
    </div>
  </div>
);
