import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../actions/modalActions'
import { styles } from './styles'
import { XIcon } from '../../shared/icons'
import { MODAL_TYPES } from '../ModalTypes'

const mapStateToProps = (state) => (
  state.modal
);

class ModalContainerComponent extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool
  };

  defaultModalProps = {
    showModalCloseXButton: false 
  }

  state = {
    modalProps: this.defaultModalProps,
  };


  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.modalType) {
      document.body.style.overflow = 'hidden';
      if (newProps.modalProps !== this.state.modalProps) {
        this.setState({
          modalProps: {
            ...this.state.modalProps,
            ...newProps.modalProps
          }
        })
      }
    } else if (this.props.modalType && !newProps.modalType) {
      document.body.style.overflow = 'unset';
      this.setState({
        modalProps: this.defaultModalProps
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset'
  }

  closeModal = () => {
    this.props.dispatch(hideModal())
  };

  updateProps = (newProps) => {
    this.setState({
      modalProps: {
        ...this.state.modalProps,
        ...newProps
      }
    })
  };

  render() {
    const {
      isMobile
    } = this.props;

    const {
      noScroll = false,
      currentPage = 0
    } = this.props.modalProps;

    const {
      modalProps
    } = this.state;

    const style = styles.modalContainer;
    const type = this.props.modalType;

    if (!type) {
      return null
    }

    const modalPage = MODAL_TYPES[type].pages[currentPage];
    const SpecifiedHeader = modalPage.header;
    const SpecifiedModal = modalPage.body;
    const SpecifiedFooter = modalPage.footer;

    return (
      <div className='container-fluid h-100' style={ style.modalBackdrop }>
        <div className='row h-100 align-items-center' style={ style.modalBackdropContent }>
          <div className='container d-flex flex-column' style={ style.modalContent(isMobile) }>
            <div className='row justify-content-end'>
              <div className='col-auto'>
                {modalProps.showModalCloseXButton && <button onClick={this.closeModal} style={ style.modalCloseXButton }>
                  <XIcon/>
                </button>}
              </div>
            </div>
            {SpecifiedHeader && <SpecifiedHeader {...modalProps} isMobile={isMobile} />}
            {SpecifiedModal && <div style={ noScroll ? style.modalBodyNoScroll : style.modalBodyScroll }>
              <SpecifiedModal
                closeModal={this.closeModal}
                updateProps={this.updateProps}
                {...modalProps}
              />
            </div>}
            {SpecifiedFooter && <SpecifiedFooter {...modalProps} isMobile={isMobile} />}
          </div>
        </div>
      </div>
    )
  }
}

export const ModalContainer = compose(
  connect(mapStateToProps),
  withTranslation()
)(ModalContainerComponent);
