import { stateMap } from './stateMap';
import { iso2Toiso3Map } from './countryMap';
import { toNameCase } from './stringUtils';

/**
 * getDisplayLocation
 * returns the location string to display based on a city, state and country
 * non-US countries will not display state information
 * US will not display country unless there is no city and no state
 * @param city
 * @param state
 * @param country
 */
export const getDisplayLocation = (city, state, country) => {
  const stateStr = stateMap[state] || state;
  const countryStr = iso2Toiso3Map[country] || country;

  return [toNameCase(city), stateStr?.toUpperCase(), countryStr?.toUpperCase()].filter((s) => s).join(', ');
};

export const getFullDisplayLocation = (city, state, country) => {
  const isUS = country === 'US' || country === 'USA';
  const stateStr = isUS ? `${stateMap[state] || state}` : '';
  const countryStr = iso2Toiso3Map[country] || country;

  return [city, stateStr, countryStr].filter((s) => s).join(', ');
};
