import React from 'react';

import { styles } from '../styles';

export const Bib = ({bib}) => {
  if (!bib) {
    return <div style={{width:40}} />
  }
  return (
    <div style={styles.bib} id="bib-value">{bib}</div>
  )
};
