import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import { useGetResultsBibNameSearchQuery } from '../../api/reigniteApi';
import { KioskSearchFormComponent } from './KioskSearchFormComponent';

const PureKioskSearchForm = (props) => {
  const eventId = props.eventId

  const [searchTerm, setSearchTerm] = useState('')
  const debounce = useDebounce(searchTerm, 1000)
  const {
    data: results,
    error,
    isFetching,
  } = useGetResultsBibNameSearchQuery({
    eventId,
    from: 0,
    limit: 20,
    term: debounce,
  }, {
    refetchOnMountOrArgChange: 30, // cache for 30 seconds 
    skip: !eventId || !debounce,
  })
  console.debug('PureKioskSearchForm', { eventId, debounce, searchTerm, isFetching, results })
  return <KioskSearchFormComponent
    {...props}
    errorMessage={error}
    isLoading={isFetching || (searchTerm !== debounce)}
    results={results}
    searchTerm={searchTerm}
    setSearchTerm={setSearchTerm}
  />
}

export const KioskSearchForm = withTranslation()(PureKioskSearchForm);
