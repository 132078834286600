import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {styles, eventStyles} from '../styles';
import ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right';
import {EventHeader, Goals} from '../upcomingEvents/UpcomingEvent';
import {RelatedRow} from '../AthleteRelated';
import { colors } from '../../../shared/styles';
import Style from 'style-it';

class UpcomingEvent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  state = {
    editing: false
  };

  toggleEdit = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  /**
   * Get all starters for event
   * @param event
   */
  getAllStartersForEvent = ({Courses}) =>
    Courses.List.reduce((acc, {Entries}) => acc.concat(Entries.List), []);

  render() {
    const {
      event = {},
      history,
      t
    } = this.props;

    const {
      City,
      Country,
      Days,
      MasterID,
      Name,
      StartDate,
      StateProv,
      isMe
    } = event;

    const {isMobile} = this.props;

    const athletes = (event.Courses && this.getAllStartersForEvent(event)) || [];

    const onRowClickHandler = (ev) => {
      if (!ev.isDefaultPrevented()) {
        // Don't mess with the native function of anchor tags
        if (ev.currentTarget.contains(ev.target) && (ev.target.tagName !== 'A' && ev.target.tagName !== 'BUTTON')) {
          ev.preventDefault()
          history.push({
            pathname: `/event/${MasterID}`
          })
        }
      }
    }

    return (
      Style.it(eventStyles.pseudoStyles(isMobile),
      <div
        className='row mx-0 friends-upcoming-container'
        style={eventStyles.upcomingEventContainer(isMobile)}
        onClick={onRowClickHandler}
      >
        <div className='col-12 px-0' style={{fontSize: 20}}>
          <EventHeader
            Name={Name}
            StartDate={StartDate}
            City={City}
            StateProv={StateProv}
            Country={Country}
            isMobile={isMobile}
            />
        </div>
        <div className='col-12' style={isMobile ? eventStyles.eventBorderContMobile : {}}>
          <div className='row'>
            <div className='col-lg-10 col-8' style={styles.relatedRow(isMobile)}>
                <RelatedRow
                  relatedType='following'
                  athletes={athletes}
                  linkTo={`/event/${MasterID}/roster`}
                  showTitle={false}
                  title={t('{{count}} Following', {count: athletes.length})}
                  isMobile={isMobile}
                  isMe={isMe}
                />
            </div>
            <div className='col-lg-2 col-4 pr-0' style={styles.daysLeft}>
              <div className='row mx-0'>
                <div className='col-9 px-0' style={styles.daysLeftContainer(isMobile)}>
                  <Goals
                    RaceGoals={Days !== 0 ? Days : t('Today')}
                    goalResStyle={eventStyles.goalsResStyle(isMobile, Days)}
                    type='friendsUpcomingEvent'
                    isMobile={isMobile}
                  />
                  {
                    Days !== 0 &&
                    <div style={styles.days}>
                      {
                        t('Day Left', {count: Days})
                      }
                    </div>
                  }
                </div>
                <div className='col-3 px-0' style={{alignSelf: 'center'}}>
                  <ArrowRight size={35} style={{color: colors.lightGrey2}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
    );
  }
}

export default withTranslation()(withRouter(UpcomingEvent));
