import React from 'react';
import PropTypes from 'prop-types';
import {imageStyles} from '../../shared/styles';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import {getNotifications, setNotifications, WARNING, RELOAD} from '../../data/NotificationStreams';
import {logError} from '../../data/Logging';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import animationData from './loader.json'

class LoadingComponent extends React.Component {
  static propTypes = {
    notifications: PropTypes.array,
    noTimeout: PropTypes.bool,
    timeoutMessage: PropTypes.string,
    isSearchPage: PropTypes.bool,
    centered: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    noTimeout: false,
    timeoutMessage: '',
    isSearchPage: false,
    centered: false
  };

  componentDidMount() {
    const {
      noTimeout,
      timeoutMessage,
      isSearchPage,
      notifications,
      t
    } = this.props;

    const config = {
      container: this.loaderEl,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    };

    if(!noTimeout) {
      const notification = {
        type: WARNING,
        message: timeoutMessage || t('Still thinking...'),
        action: RELOAD,
        duration: 10000,
        isSearchPage
      };

      this.timeout = setTimeout(() => {
        setNotifications(_.concat(notifications, notification));
        logError('loader timeout');
      }, 20000);
    }

    //This needs to be here for the animation to work, even if it seems to be unused
    window.bodymovin.loadAnimation(config);
  }

  componentWillUnmount() {
    if(!this.props.noTimeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <div
        ref={(el) => { this.loaderEl = el; }}
        style={{
          ...imageStyles.loading,
          ...(this.props.centered ? imageStyles.loadingPosition : {}),
        }}/>
    );
  }
}

export const Loading = withTranslation()(connectStream({
  notifications: getNotifications
})(LoadingComponent));
