import React from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connectStream } from './lib/bastetjs/utils/connectStream';
import queryString from 'query-string';
import { compose } from 'redux';
import {externalStore} from './App';
import _ from 'lodash';
import { getRefreshTokenStream } from './data/AuthStreams';
import {
  getDefaultEventSearchFilters,
  getProfileSearchFilters,
  getDefaultProfileSearchFilters,
  getUnclaimedResultsSearchFilters,
  getDefaultUnclaimedResultsSearchFilters
} from './data/SearchFilters';
import { NotFound } from './views/notFound/NotFound';
import { AthletePage } from './views/athlete/AthletePage';
import { AthleteSearchPage } from './views/athleteSearch/AthleteSearchPage';
import { BragiResizingContainer } from './components/shared/BragiResizingContainer';
import { CuratedEventsPage } from './views/home/CuratedEventsPage';
import { DocumentPage } from './views/document/DocumentPage';
import { EventLandingPage } from './views/eventLandingPage/EventLandingPage';
import { EventSearchPage } from './views/eventSearch/EventSearchPage';
import { HomePage } from './views/home/HomePage';
import { IndividualResult } from './views/individualResult/IndividualResult';
import { getTokenRacerId } from './utils/isLoggedIn';
import { MasterEventKiosk } from './views/kiosk/MasterEventKiosk';
import { MasterLandingPage } from './views/masterLandingPage/MasterLandingPage';
import { SearchResults } from './views/searchResults/SearchResults';
import { SignInPage } from './views/signIn/SignInPage';
import { SignUpPage } from './views/signUp/SignUp';
import { UnclaimedSearchPage } from './views/unclaimedsearch/UnclaimedSearchPage';
import { EventResultsFilterActionTypes } from './actions/types';
import { Loading } from './components/shared/Loading';
import { imageStyles } from './shared/styles';
import { ModalContainer } from './components/modal/common/ModalContainer';
import { AthleteRacePage } from './views/athleteRacePage/AthleteRacePage';
import { setTermValue } from './data/SearchTerm';
import { VRLandingPage } from './views/vr/VRLandingPage';
import { AzpResolver } from "./views/azpResolver/AzpResolver";

const routeInit = (match) => {
  window.scrollTo(0, 0);
  if(match && match.create === 'imageShare') {
    window.prerenderReady = false;
  }
};

const onEnter = (nextState) => {
  routeInit(nextState);
};

const onEnterResults = (nextState) => {
  if (nextState) {
    const {eventId, courseId, divisionId, splitId} = nextState;
    if(eventId) {
      externalStore.dispatch({
        type: EventResultsFilterActionTypes.FILTER_SET,
        date: parseInt(eventId),
        course: parseInt(courseId),
        division: parseInt(divisionId),
        split: parseInt(splitId)
      });
    }
  }
};

const Loader = () => {
  return (
    <div style={imageStyles.loadingOverlay}>
      <div style={imageStyles.loadingPosition}>
        <Loading noTimeout={true}/>
      </div>
    </div>
  );
};

/**
 * A route that Wraps route componenets with a resizing container for responsiveness. Also
 * connects to several streams for use within the various views.
 */
const BragiRoute = ({
  component: Component,
  resize,
  auth = { isLoading: true },
  onEnter = null,
  connectedProps,
  ...rest
}) => (<Route { ...rest }
    render={(routeProps) => {
      if (routeProps.match.params &&
          routeProps.match.params.masterId &&
          routeProps.match.params.masterId.indexOf('-') > -1) {
        const parts = routeProps.match.params.masterId.split('-')
        routeProps.match.params.masterId = parts.pop()
        routeProps.match.params.vanityToken = parts.join('-')
      }
      const query = queryString.parse(window.location.search);
      const pairs = {
        ...routeProps.match.params,
        ...query,
        ...connectedProps,
        ...rest,
      };
      try {
        if (typeof pairs.filters === 'string') {
          pairs.filters = pairs.filters ? JSON.parse(pairs.filters) : null;
        }
        else {
          pairs.filters = null;
        }
      }
      catch (e) {
        pairs.filters = null;
      }
      if (onEnter) {
        onEnter(pairs)
      }
      if (resize) {
        return (
          <BragiResizingContainer>
            <Component {... routeProps} pairs={pairs} />
            <ModalContainer />
          </BragiResizingContainer>
        );
      }
      else {
        return <Component {... routeProps} pairs={pairs} />
      }
    }}
  />);

// TODO: set up redirect / to /athletes/id when logged in

const KIOSKRoutes = ({pairs, ...rest}) => <MasterEventKiosk
  { ...rest }
  beta={pairs.beta}
  bib={parseInt(pairs.bib, 10)}
  eventCourseId={parseInt(pairs.eventCourseId, 10)}
  eventId={parseInt(pairs.eventId, 10)}
  masterId={parseInt(pairs.masterId, 10)}
  mode={pairs.mode}
/>

const relevantProps = [
  'isLoggedIn',
  'auth',
  'defaultEventFilters',
  'profileFilters',
  'defaultProfileFilters',
  'unclaimedResultsFilters',
  'defaultUnclaimedResultsFilters',
  't',
]

const propsReducer = (props) => (acc, propName) => {
  if (relevantProps.indexOf(propName) > -1) {
    return {
      ...acc,
      [propName]: props[propName]
    }
  }
  return acc
}

class RoutesComponent extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
  }

  shouldComponentUpdate(nextProps, nextState) {
    const current = Object.keys(this.props).reduce(propsReducer(this.props), {})
    const next = Object.keys(nextProps).reduce(propsReducer(nextProps), {})
    return !_.isEqual(current, next)
  }

  UNSAFE_componentWillUpdate() {
    const query = queryString.parse(window.location.search);
    if (query.term) {
      setTermValue(query.term)
    }
  }

  render() {
    if (!this.props.auth || this.props.auth.isLoading) {
      return (<Loader />);
    }

    const racerId = this.props.isLoggedIn ? getTokenRacerId() : null;

    return (
    <Router>
      <Switch>
        {/* Sign Up / In */}
        <BragiRoute
          resize
          path="/signup"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <SignUpPage
            { ...rest }
            refreshToken={pairs.refreshToken}
            redirectUrl={pairs.redirectUrl}
            referrerPath={pairs.referrerPath} />
          }
        />
        <BragiRoute
          resize
          path="/signin"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({pairs, ...rest}) => <SignInPage
            { ...rest }
            refreshToken={pairs.refreshToken}
            redirectUrl={pairs.redirectUrl}
            referrerPath={pairs.referrerPath}
          />}
        />

        {/* Documents */}
        <BragiRoute
          resize
          path="/about/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'about'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/accessibility/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'accessibility'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/advertise/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({pairs, ...rest}) => <DocumentPage
            { ...rest }
            docType={'advertise'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/gdpr/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
                    component={({ pairs, ...rest }) => <DocumentPage
                      { ...rest }
                      docType={'gdpr'}
                      displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/privacy/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'privacy'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/ccpa/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'ccpa'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/mhmd/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'mhmd'}
            displayType={pairs.displayType} />}
        />

        <BragiRoute
          resize
          path="/terms/:displayType?"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <DocumentPage
            { ...rest }
            docType={'terms'}
            displayType={pairs.displayType} />}
        />

        {/* Document Redirects */}
        <Redirect path='/home/about/:displayType?' exact to='/about/:displayType?' />
        <Redirect path='/home/accessibility/:displayType?' exact to='/accessibility/:displayType?' />
        <Redirect path='/home/advertise/:displayType?' exact to='/advertise/:displayType?' />
        <Redirect path='/home/ccpa/:displayType?' exact to='/ccpa/:displayType?' />
        <Redirect path='/home/gdpr/:displayType?' exact to='/gdpr/:displayType?' />
        <Redirect path='/home/privacy/:displayType?' exact to='/privacy/:displayType?' />
        <Redirect path='/home/terms/:displayType?' exact to='/terms/:displayType?' />

        {/* ELP */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/Master/:masterId/LandingPage',
            '/event/:masterId/:tab/Event/:eventId/Course/:courseId/Entry/:entryId',
            '/event/:masterId/:tab/Event/:eventId/Course/:courseId/Bib/:bib',
            '/event/:masterId/:tab',
            '/event/:masterId'
          ]}
          component={({ pairs, ...rest }) => {
            return (
              <MasterLandingPage
                {...rest}
                tab={pairs.tab || 'about'}
                masterId={pairs.masterId}
                search={pairs.search}
                eventId={parseInt(pairs.eventId) || 0}
                courseId={parseInt(pairs.courseId) || 0}
                divisionId={parseInt(pairs.divisionId) || 0}
                entryId={pairs.entryId || 0}
                bib={pairs.bib}
                action={pairs.action}
                friendsFilter={pairs.friendsFilter || ''}
                mapPreview={!!pairs.mapPreview}
                referrerPath={pairs.referrerPath}
                source={pairs.source || 'internal'}
              />
            );
          }}
        />

        {/* Legacy ELP */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/race/event',
            '/Events/:raceid/Courses/:courseid',
            '/Events/:raceid',
          ]}
          component={({ pairs, ...rest }) => <EventLandingPage
            { ...rest }
            eventId={parseInt(pairs.raceid)}
            courseId={parseInt(pairs.courseid)}
          />}
        />

        {/* Roster */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/event/:masterId/roster/Event/:eventId',
            '/event/:masterId/roster/Event/:eventId/Course/:courseId',
            '/event/:masterId/roster/Event/:eventId/Course/:courseId/Division/:divisionId',
          ]}
          component={({pairs, ...rest}) => {
              return (<MasterLandingPage
                { ...rest }
                tab={'roster'}
                masterId={pairs.masterId}
                search={pairs.search}
                eventId={parseInt(pairs.eventId) || 0}
                courseId={parseInt(pairs.courseId) || 0}
                divisionId={parseInt(pairs.divisionId) || 0}
                entryId={pairs.entryId || 0}
                bib={pairs.bib}
                action={pairs.action}
                mapPreview={!!pairs.mapPreview}
                friendsFilter={pairs.friendsFilter || ''}/>)
            }
          }
        />

        {/* ERP */}
        <BragiRoute
          resize
          exact
          onEnter={onEnterResults}
          connectedProps={this.props}
          path={[
            '/Master/:masterId/EventResults',
            '/Master/:masterId/Event/:eventId/Results',
            '/Master/:masterId/Event/:eventId/Course/:courseId/Results',
            '/event/:masterId/results',
            '/event/:masterId/results/Event/:eventId/:type?',
            '/event/:masterId/results/Event/:eventId/Course/:courseId/:type?',
            '/event/:masterId/results/Event/:eventId/Course/:courseId/Division/:divisionId/:type?',
            '/event/:masterId/results/Event/:eventId/Course/:courseId/Division/:divisionId/Split/:splitId/:type?',
          ]}
          component={({ pairs, ...rest }) => {
            if (pairs.view === 'kiosk') return KIOSKRoutes({ pairs, ...rest })
            else return (<MasterLandingPage
              { ...rest }
              tab={'results'}
              masterId={pairs.masterId}
              search={pairs.search}
              eventId={parseInt(pairs.eventId) || 0}
              courseId={parseInt(pairs.courseId) || 0}
              divisionId={parseInt(pairs.divisionId) || 0}
              entryId={pairs.entryId || 0}
              bib={pairs.bib}
              action={pairs.action}
              friendsFilter={pairs.friendsFilter || ''}
              mapPreview={!!pairs.mapPreview}
              referrerPath={pairs.referrerPath}
            />)
          }}
        />

        {/* KIOSK */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path="/kiosk/:masterId/event/:eventId"
          component={KIOSKRoutes}
        />

        {/* IRP */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/Event/:eventId/Course/:eventCourseId/Result/:entryId',
            '/Event/:eventId/Course/:eventCourseId/Bib/:bib',
            // '/Event/:eventId/Course/:eventCourseId/Bib/:bib(?entryId=:entryId)',
          ]}
          component={({ pairs, ...rest }) => <IndividualResult
            { ...rest }
            eventCourseId={pairs.eventCourseId}
            eventId={pairs.eventId}
            entryId={pairs.entryId}
            bib={pairs.bib}
            source={pairs.source || 'internal'}
            mapPreview={!!pairs.mapPreview}
          />}
        />

        {/* SEARCH */}
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path="/Master/:masterId/SearchResults"
          component={({ pairs, ...rest }) => <SearchResults
            { ...rest }
            search={pairs.search}
            masterId={pairs.masterId}
          />}
        />

        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path="/search/events"
          component={({ pairs, ...rest }) => <EventSearchPage
            { ...rest }
            category={'events'}
            filters={pairs.filters}
            searchTerm={pairs.term}
            locationTerm={pairs.locationTerm || pairs.location}
            locationRange={pairs.withinRange}
            masterId={pairs.masterId}
            auth={pairs.auth}
          />}
        />

        <BragiRoute
          resize
          exact
          path="/search/athletes"
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <AthleteSearchPage
            { ...rest }
            category={'athletes'}
            auth={pairs.auth}
            filters={pairs.filters}
            searchTerm={pairs.term}
            locationTerm={pairs.locationTerm}
          />}
        />

        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/search/unclaimed',
            '/result/searchclaim',
            '/result/search',
            '/search',
          ]}
          component={({ pairs, ...rest }) => <UnclaimedSearchPage
            { ...rest }
            category={'unclaimed'}
            filters={pairs.filters}
            searchTerm={pairs.term}
            locationTerm={pairs.locationTerm}
          />}
        />

        {/* Athlete Race Page (ARP) */}
        <BragiRoute
          resize
          path={[
              '/entry/:entryId'
          ]}
          onEnter={onEnter}
          connectedProps={this.props}
          component={({pairs, ...rest}) => <AthleteRacePage
            { ...rest }
            entryId={pairs.entryId}
          />}
        />

        {/* Athletes */}
        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/athletes/:athleteId/:tab/:rivalId?',
            '/athletes/:athleteId/:tab?',
            '/athletes/:athleteId',
          ]}
          component={({ pairs, ...rest }) => <AthletePage
            { ...rest }
            athleteId={pairs.athleteId}
            auth={pairs.auth}
            tab={pairs.tab}
            selectedRival={pairs.rivalId}
          />}
        />

        {/* Curated Events */}
        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          path="/home/curatedEvents/:categoryId"
          component={({ pairs, ...rest }) => <CuratedEventsPage
            { ...rest }
            categoryId={pairs.categoryId}
          />}
        />

        {/* VR Landing */}
        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          path={'/vr/:entryUniqueId'}
          component={({ pairs, ...rest }) => <VRLandingPage
            {...rest }
            {...pairs}
          />}
        />

        {/* Home */}
        {
          this.props.isLoggedIn && racerId > 0 && <Redirect from='/' exact to={`/athletes/${racerId}`} />
        }
        <BragiRoute
          resize
          exact
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/',
            '/home'
          ]}
          component={({pairs, ...rest}) => <HomePage {...rest} isLoggedIn={pairs.isLoggedIn} />}
        />

        {/* AZP Resolver */}
        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          path={[
            '/azp/:azp/event/:azpEventId/bib/:bib',
            '/azp/:azp/event/:azpEventId',
          ]}
          component={({ pairs, ...rest }) => <AzpResolver
            {...rest }
            azp={pairs.azp}
            azpEventId={pairs.azpEventId}
            bib={pairs.bib}
          />}
        />

        {/* Not Found */}
        <BragiRoute
          resize
          onEnter={onEnter}
          connectedProps={this.props}
          component={({ pairs, ...rest }) => <NotFound { ...rest } errorStatus={pairs.errorStatus}/>}
        />
      </Switch>
    </Router>);
  }
}

export const Routes = compose(
  withTranslation(),
  connectStream({
    auth: getRefreshTokenStream,
    defaultEventFilters: ({t}) => getDefaultEventSearchFilters(t),
    profileFilters: ({t}) => getProfileSearchFilters(t),
    defaultProfileFilters: ({t}) => getDefaultProfileSearchFilters(t),
    unclaimedResultsFilters: getUnclaimedResultsSearchFilters,
    defaultUnclaimedResultsFilters: getDefaultUnclaimedResultsSearchFilters,
  }),
)(RoutesComponent)
