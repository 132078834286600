import {streamHook, eventBus} from './Streams';
import _ from 'lodash';
import moment from 'moment';

export const EVENT_SEARCH = 'event-search-filters-stream';
export const PROFILE_SEARCH = 'profile-search-filters-stream';
export const UNCLAIMED_RESULTS_SEARCH = 'unclaimed-results-search-filters-stream';


/**
 * Set all types to true/false
 *
 * @param {boolean} checked
 * @param {func} t
 * @return {{}}
 */
export function getInitialTypes(checked = false, t) {
  const val = (type) =>
    typeof checked === 'boolean' ? checked : checked[type];
  let types = {};
  _.each(eventTypeMapping(t), ({ key, children }) => {
    types[key] = val(key);
    if (children) {
      _.each(children, ({ key }) => {
        types[key] = val(key);
      })
    }
  });
  return types;
}

export const defaultEventFilters = (t) => ({
  dateRangeFilter: {
    enabled: true,
    value: {
      from: moment().subtract(2, 'years').format('YYYY-MM-DD'),
      to: moment().add(2, 'years').format('YYYY-MM-DD')
    }
  },
  locationFilter: {
    enabled: false,
    value: {location: '',range: 50}
  },
  typeFilter: getInitialTypes(false, t)
});

export function getEventSearchFilters(t) {
  return streamHook(EVENT_SEARCH, defaultEventFilters(t));
}

export function getDefaultEventSearchFilters(t) {
  return streamHook(`${EVENT_SEARCH}-default`, defaultEventFilters(t));
}

export const defaultProfileFilters = (t) => ({
  ageFilter: {
    enabled: false,
    value: [16, 115]
  },
  genderFilter: {
    enabled: false,
    value: 'A'
  },
  locationFilter: {
    enabled: false,
    value: {location: '', range: 50}
  },
  typeFilter: getInitialTypes(false, t)
});

export function getProfileSearchFilters(t) {
  return streamHook(`${PROFILE_SEARCH}`, defaultProfileFilters(t))
}

export function getDefaultProfileSearchFilters(t) {
  return streamHook(`${PROFILE_SEARCH}-default`, defaultProfileFilters(t))
}

export const defaultUnclaimedResultsFilters = {
  birthDateFilter: {
    enabled: false,
    value: ''
  },
  locationFilter: {
    enabled: false,
    value: []
  }
};

export function getUnclaimedResultsSearchFilters() {
  return streamHook(
    `${UNCLAIMED_RESULTS_SEARCH}`, defaultUnclaimedResultsFilters)
}

export function getDefaultUnclaimedResultsSearchFilters() {
  return streamHook(
    `${UNCLAIMED_RESULTS_SEARCH}-default`, defaultUnclaimedResultsFilters)
}


/**
 *
 * @param {string} filter - Stream id
 * @param {*} values - filter values
 * @param {boolean} isDefault - will set values as default if TRUE
 */
export function setValues(filter, values, isDefault = false) {
  eventBus.publish(filter, values);
  if(isDefault) {
    eventBus.publish(`${filter}-default`, values);
  }
}

export function setEventSearchFilterValues(values, isDefault = false) {
  setValues(EVENT_SEARCH, values, isDefault);
}

export function setProfileFilterValues(values, isDefault = false) {
  setValues(PROFILE_SEARCH, values, isDefault);
}

export function setUnclaimedResultsSearchFilterValues(values, isDefault = false) {
  setValues(UNCLAIMED_RESULTS_SEARCH, values, isDefault);
}

export function setBirthYearValueForUnclaimedSearch(birthYear) {
  setUnclaimedResultsSearchFilterValues({
    ...defaultUnclaimedResultsFilters,
    birthDateFilter: {enabled: true, value: birthYear}
  });
}

export function eventTypeMapping(t) {
  return [
    {
      key: 'running',
      title: t('Running'),
      children: [
        {key: 'upTo5k', title: t('Up to 5k')},
        {key: 'from5kTo15k', title: t('5k to 15k')},
        {key: 'from15kToHalfMara', title: t('15k to Half Marathon')},
        {key: 'fromHalfMaraToMara', title: t('Half Marathon to Marathon')},
        {key: 'marathon', title: t('Marathon')},
        {key: 'ultra', title: t('Ultra')},
      ]
    },
    {
      key: 'triathlon',
      title: t('Triathlon & Multisport'),
      children: [
        {key: 'sprint', title: t('Sprint')},
        {key: 'olympic', title: t('Olympic')},
        {key: 'halfIronman', title: t('Half Ironman')},
        {key: 'ironmanAndUp', title: t('Ironman and Up')},
        {key: 'aquathlon', title: t('Aquathlon')},
        {key: 'aquabike', title: t('Aquabike')},
        {key: 'duathlon', title: t('Duathlon')},
      ]
    },
    {
      key: 'more',
      title: t('More Categories'),
      children: [
        {key: 'swim', title: t('Swim')},
        {key: 'mountainBike', title: t('Mountain Bike')},
        {key: 'cycling', title: t('Cycling')},
        {key: 'snow', title: t('Snow')},
        {key: 'adventure', title: t('Adventure')},
        {key: 'obstacle', title: t('Obstacle')},
        {key: 'other', title: t('Other')},
      ]
    }
  ];
}
