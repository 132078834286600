import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core';

import { DialogTitle as Title } from './DialogTitle';
import { styles } from './styles';

export class DialogModal extends Component {
  static defaultProps = {
    actionsContainerStyle: {}
  };

  constructor(props) {
    super(props)

    this.createStyledDialog(props)
    this.createStyledDialogActions(props)
    this.createStyledDialogContent(props)
    this.createStyledDialogTitle(props)
  }

  createStyledDialog(props) {
    const style = props.isMobile ? styles.mobile : styles.desktop

    this.StyledDialog = withStyles({
      container: {
        ...props.contentStyle
      },
      paper: {
        ...style.contentStyle,
        ...props.paperStyle
      }
    })(Dialog)
  }

  createStyledDialogActions(props) {
    const style = props.isMobile ? styles.mobile : styles.desktop

    this.StyledDialogActions = withStyles({
      root: {
        ...style.actionsContainerStyle,
        ...props.actionsContainerStyle
      }
    })(DialogActions)
  }

  createStyledDialogContent(props) {
    const style = props.isMobile ? styles.mobile : styles.desktop

    this.StyledDialogContent = withStyles({
      root: {
        ...style.bodyStyle,
        ...props.bodyStyle
      }
    })(DialogContent)
  }

  createStyledDialogTitle(props) {
    const style = props.isMobile ? styles.mobile : styles.desktop

    this.StyledDialogTitle = withStyles({
      root: {
        ...style.titleContainerStyle
      }
    })(DialogTitle)
  }

  render() {
    const { modalContainerStyle = {} } = this.props;

    const title = (
      <Title
        isMobile={this.props.isMobile}
        title={this.props.title}
        separator={this.props.separator}
        onRequestClose={this.props.onRequestClose}
        titleStyle={this.props.titleStyle}
        closeStyle={this.props.closeStyle}
      />
    );

    const propTitle = (
      <div
        style={{
          fontSize: 25,
          float: 'left',
          width: '100%',
          color: '#4a4a4a',
          borderBottom: 'none',
          padding: '40px 0px 0px 40px',
          fontFamily: 'ProximaNovaRegular'}}
        id='claim-result-title'>
        {this.props.title}
      </div>
    );

    return (
        <div id='modal' style={modalContainerStyle}>
          <this.StyledDialog
            onClose={this.props.onRequestClose}
            open={this.props.open}
          >
            <this.StyledDialogTitle>
              {this.props.hideCloseIcon ? propTitle : title}
            </this.StyledDialogTitle>
            <this.StyledDialogContent>
              {this.props.body}
            </this.StyledDialogContent>
            <this.StyledDialogActions>
              {this.props.actions}
            </this.StyledDialogActions>
          </this.StyledDialog>
        </div>
    );
  }
}
