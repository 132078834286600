export const locales = {
  'de': {
    days: 'Sonntag Montag Dienstag Mittwoch Donnerstag Freitag Samstag'.split(' '),
    shortDays: 'So Mo Di Mi Do Fr Sa'.split(' '),
    months: 'Januar Februar März April Mai Juni Juli August September Oktober November Dezember'.split(' '),
    shortMonths: 'Jan Feb Mär Apr Mai Jun Jul Aug Sep Okt Nov Dez'.split(' '),
    AM: 'AM',
    PM: 'PM',
    am: 'am',
    pm: 'pm',
    formats: {
      D: '%d.%m.%Y',
      F: '%Y-%m-%d',
      R: '%H:%M',
      T: '%H:%M:%S',
      X: '%T',
      c: '%a %b %d %X %Y',
      r: '%I:%M:%S %p',
      v: '%e-%b-%Y',
      x: '%D'
    }
  }
};
