import moment from 'moment';
import React from 'react';
import  ArrowRight  from 'react-icons/lib/fa/angle-right';
import { Link } from 'react-router-dom';
import Style from 'style-it';
import { withRouter } from 'react-router-dom';
import {DateTime} from '../../components/shared/DateTime';
import { styles } from './styles';
import { trackGoogleEvent } from '../../utils/googleEvents';

const collectImportantRaceDates = (races) => {
  const today = moment();
  let mostRecent = null;
  let next = null;

  races.forEach((race) => {
    const date = moment(race.eventDate);
    if (date.isBefore(today)) {
      mostRecent = date;
    }
    else if (!next) {
      next = date;
    }
  });
  return {
    mostRecent,
    next,
  };
};


const EventComponent = ({event, isMobile, t, history}) => {
  const { races } = event;
  const racesCount = event.races.length;

  races.sort((a, b) => {
    const aDate = moment(a.eventDate);
    const bDate = moment(b.eventDate);

    return aDate.isBefore(bDate) ? -1 : aDate.isAfter(bDate) ? 1 : 0;
  });

  const { next } = collectImportantRaceDates(races);
  const eventUrl = `/event/${event.masterEventId}`;

  const raceUrlTemplate = (string, ...keys) => {
    return `/event/${event.masterEventId}/results/Event/${keys[0]}/Results`;
  };

  return Style.it(styles.pseudoStyles(isMobile),
    <div style={{...styles.eventContainer}} className="event row mx-0" onClickCapture={(ev) => {
      trackGoogleEvent('search', 'selectMasterEvent', event.masterEventId);
      if (!ev.isDefaultPrevented()) {
        // Don't mess with the native function of anchor tags
        if (ev.currentTarget.contains(ev.target) && (ev.target.tagName !== 'A' && ev.target.tagName !== 'BUTTON')) {
          ev.preventDefault()
          // console.log({... ev})
          history.push({
            pathname: eventUrl
          })
        }
      }
    }}>
      <div className="col-md-12 col-lg-12 px-0" style={ !isMobile ? styles.firstEventRow : {marginBottom: 15} }>
        <div className="col-md-12 col-lg-6 px-0" style={{ float: !isMobile ? 'left' : 'static' }}>
          <div style={ !isMobile ? styles.afterName : styles.afterNameMob}>
            <Link className='event-url' to={eventUrl} style={styles.eventName(isMobile)} title={event.masterEventTitle}>
              {event.masterEventTitle}
            </Link>
            <div style={styles.detailsContainer}>
              {
                next ?
                <Link style={styles.dateTimeLink} to={eventUrl} >
                  <span style={styles.dateTimeContainer} className="event-date">
                    <DateTime
                      date={next}
                      showTime={false}
                      icon={null}/>
                  </span>
                </Link> :
                <span>{t('TBD')}</span>
              }
              <span style={styles.detailsPipe}>|</span>
              <span className="event-location">
                {t('{{city}}, {{stateProv}}', {city: event.city, stateProv: event.stateProv})}
              </span>
            </div>
          </div>
        </div>
        { !isMobile &&
        <div className="col-md-12 col-lg-6" style={styles.secondRowLast}>
          <div style={{ display: 'flex' }}>
            <div style={styles.eventInfoEL}>{t('Results')}</div>
              <div className="results" style={styles.raceDateContainer}>
                {
                  races.reverse().map((race, index) => {
                    const formattedDate = moment(race.eventDate).format('YYYY')
                    return (
                      index < 6
                        ? <Link className='year-link'
                            key={index}
                            title={`${event.masterEventTitle} - ${formattedDate}`}
                            to={raceUrlTemplate`${race.raceId}`}
                            style={styles.raceDate}
                            onClick={(e) => {
                              trackGoogleEvent('search', 'selectEvent', race.raceId);
                              e.stopPropagation();
                            }}>
                              {formattedDate}
                          </Link>
                        : index === 6
                          ? <Link
                              key={index}
                              to={raceUrlTemplate`${race.raceId}`}
                              onClick={(e) => {
                                trackGoogleEvent('search', 'selectEvent', race.raceId);
                                e.stopPropagation();
                              }}>
                                <span className="see-more-results" key={index} style={styles.raceDate}>+{racesCount - 6}</span>
                            </Link>
                          : null
                    );

                  })
                }
              </div>
            </div>
          </div>
        }
        { !isMobile && <ArrowRight className="resultArrow" style={styles.rightArrow} size={35} /> }
      </div>
    </div>
  );
};

export default withRouter(EventComponent);
