import parsers from './ParserUtil';

export default {

  /* Get Rank
   * - - - -
   * @param {any} val
   *
   * @return {string}
   */
  getFormat(val, useCommas) {
    let rank = parsers.tryParseInt(val, false);

    if (!rank || rank <= 0 || this.isNoRank(rank)) {
      return '--';
    }

    if (this.isDnf(rank)) {
      return 'DNF';
    }

    if (useCommas) {
      return rank.toLocaleString('en-US', { useGrouping: true });
    }

    return rank.toString();
  },

  // is you is or is you ain't

  /* Is Did Not Finish
   * - - - - - - - - -
   * @param {int} rank
   *
   * @return {bool}
   */
  isDnf(rank) {
    return rank === 999999;
  },

  /* Is Not Ranked
   * - - - - - - -
   * @param {int} rank
   *
   * @return {bool}
   */
  isNoRank(rank) {
    return rank === 888888;
  }
};
