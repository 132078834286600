import React from 'react';
import Checkmark from 'react-icons/lib/io/ios-checkmark-empty';
import {_filterTab} from './styles';


const Checkbox = ({
  onCheck, 
  label, 
  checked
  }) => {
  return (
    <div style={_filterTab.checkContainer}>
      <div
        onClick={() => onCheck()}
        style={_filterTab.checkBox(checked)}
      >
        <Checkmark style={_filterTab.checkMark(checked)}/>
      </div>
      <div style={_filterTab.checkLabel(checked)}>
        {label}
      </div>
    </div>
  )
};

export default Checkbox;