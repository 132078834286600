import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { Nobles } from '../lib/anuket';
import { streamHook, streamFail, eventBus } from './Streams';
import { getToken } from '../utils/isLoggedIn';
import { extractBasisFromLegs } from '../utils/resultsHelpers';
import { of as ObservableOf, combineLatest } from 'rxjs';
import {
  map,
  take,
  flatMap,
} from 'rxjs/operators';
import { EventStreams } from './EventStreams';
import { curatedCategory } from './CuratedEventsStreams';
import { convertTime } from '@eventops/athlinks-lib-units';

function flatten(msg, replCourses) {
  const eventData = {
    eventId: msg.eventId || 0,
    eventName: msg.eventName || '',
    masterId: msg.masterId || 0
  };
  const courses = replCourses || msg.courses || [];

  return courses.reduce((acc, curr) => {
    const courseData = {
      affiliateCourseId: curr.affiliateCourseId || '',
      affiliateEventId: curr.affiliateEventId || '',
      courseId: curr.courseId || 0,
      courseName: curr.courseName || '',
      coursePattern: curr.coursePattern || '',
      divisionId: curr.divisionId || 0,
      eventCourseId: curr.eventCourseId || 0,
      isCtLive: curr.isCtLive || false,
      isPublished: curr.isPublished || false,
      legCount: curr.legCount || 0
    };

    acc[curr.eventCourseId] = (Array.isArray(acc[curr.eventCourseId]) ? acc[curr.eventCourseId] : [])
        .concat(curr.results || [])
        .reduce((prev, curr) => {
          if(!prev.some((el) => {
            const prevID = el.entryId || el.ctLiveEntryId;
            const currID = curr.entryId || curr.ctLiveEntryId;
            return prevID === currID;
          })) {
            return prev.concat(curr)
          } else {
            return prev;
          }
        },[])
        .map((_, i) => {
          const legs = curr.legs.map((l, i) => Object.assign(l, _.legs[i] || {}));
          if (i === 0) { getLegsBasis(courseData.eventCourseId, legs); }

          const flatLegs = legs.reduce((flat, l) => {
            flat[`leg${l.legNumber}Ticks`] = l.ticks;
            flat[`leg${l.legNumber}TicksString`] = convertTime({timeInMillis: l.ticks, timeUnit: 'h'}).value;
            return flat;
          }, {});
          return Object.assign({}, eventData, courseData, flatLegs, _);
    });

    return acc;
  }, {});
}

function eventCourseStreamId(prefix, params) {
  const from = (params || {}).from || 0;
  const topN = (params || {}).topN || configs.numResultsPerPage;
  const divisionId = (params || {}).divisionId || 0;
  const splitId = (params || {}).splitId || 0;
  const eventCourseId = (params || {}).eventCourseId || 0;

  return prefix + '-'
       + parseInt(eventCourseId) + '-'
       + parseInt(divisionId) + '-'
       + parseInt(splitId) + '_'
       + parseInt(from) + '-'
       + parseInt(topN);
}

export function getLegsBasis(eventCourseId, legs) {
  const streamId = 'EventResultsLegsBasis-' + parseInt(eventCourseId);
  const stream = streamHook(streamId, {isLoading: true});

  stream.subscribe((msg) => {
    if ((msg || {}).isLoading && Array.isArray(legs) && legs.length) {
      const basis = extractBasisFromLegs(legs);
      eventBus.publish(streamId, basis);
    }
  });

  return stream;
}

export function getErpEvent({masterId, eventId}) {
  return eventId
    ? EventStreams.receiveEventByEventId({eventId})
    : Nobles.EventsNoble.getMostRecentPastEventByMasterId({masterId});
}

export async function fetchEntryResultByEventId(data) {
  const token = await getToken();
  return Nobles.ResultsNoble.getEntryResults({...data, token})
    .pipe(take(1)).subscribe((msg) => {
      const flattened = flatten((msg.success && msg.result) || {});
      const keys = Object.keys(flattened || {});
      const index = keys.indexOf(data.eventCourseId.toString());
      const result = keys.length > 0 && flattened[keys[0]].length
        ? flattened[keys[index]][0] : {};

      eventBus.publish(`irp-result-${data.eventCourseId}-${data.bib}`, result);

      return result;
    });
}

export function getEntryResult({eventCourseId, bib}) {
  return streamHook(`irp-result-${eventCourseId}-${bib}`, {isLoading: true});
}

const legacyEntryStreamId = 'EntryResultLegacy';

export function setLegacyEntryResult(racerId, entryId) {
  if (racerId && entryId) {
    const url = `${configs.ATHLINKS_API_HOST}/Athletes/Api/${racerId}/Races/${entryId}`;

    streamFail(
      legacyEntryStreamId,
      FetchApi.memoizeFetch(10000)(
        url,
        { method: 'GET' }
      ),
      (msg) => {
        if (msg.ErrorMessage) {
          return {error: msg.ErrorMessage};
        }

        const result = (msg || {}).Result || {};
        return {
          overallCount: result.CountO || 'N/A',
          genderCount: result.CountG || 'N/A',
          ageCount: result.CountA || 'N/A',
          className: result.ClassName || 'N/A',
          rankA: result.RankA || 'N/A',
          rankG: result.RankG || 'N/A',
          rankO: result.RankO || 'N/A',
          legEntries: result.LegEntries || {}
        };
      }
    );
  }
}

export function getLegacyEntryResult(){
  return streamHook(legacyEntryStreamId, {isLoading: true});
}

function fetchLeastFuture(masterId) {
  const streamId = 'LeastFutureStream-'+masterId;

  const stream = streamHook(streamId, {isLoading: true});

  stream.subscribe((msg) => {
    if ((msg || {}).isLoading) {
      eventBus.publish(streamId, {});
      Nobles.EventsNoble.getLeastFutureEventByMasterId({masterId})
        .pipe(take(1))
        .subscribe((msg) => eventBus.publish(streamId, msg));
    }
  });

  return stream;
}

export function getLeastFutureForCategory(categoryName) {
  const embedCuratedDescription = (event, masterCuratedDescription) => ({
    ...event, masterCuratedDescription
  });

  return curatedCategory(categoryName)
    .pipe(flatMap(({error, result} = {}) => {

      if (error) {
        return ObservableOf({error});
      }

      const curatedEvents = (result || [])
        .filter(({masterEventId}) => !!masterEventId);

      return combineLatest(
        ...(curatedEvents.map(({masterEventId}) => fetchLeastFuture(masterEventId))),
        (...leastFutureResults) => (
          leastFutureResults.reduce((acc, val, i) => ({
            ...acc,
            [curatedEvents[i].masterEventId] : {
              event: embedCuratedDescription(val.event, curatedEvents[i].leastFutureEvent.masterCuratedDescription)
            }
          }), {})
        ))
        .pipe(map((events) => ({result: {
          eventIDs: curatedEvents.map(({masterEventId}) => masterEventId),
          events
        }})));
    })
  );
}

export function getPage() {
  return streamHook('er-page').pipe(map((s) => parseInt(s.page)));
}

export const EventResultsStreams = {
  getLegsBasis,
  fetchEntryResultByEventId,
  getEntryResult,
  getLegacyEntryResult,
  setLegacyEntryResult,
  getErpEvent,
  eventCourseStreamId,
  getPage
};
