const Entities = require('html-entities').AllHtmlEntities;
const entities = new Entities();

/**
 * @param {object|array|string|number|boolean|null|undefined} Some value.
 * @return {mixed} The given argument, with its strings HTML-entity decoded.
 * @throws Error if given a function, or something with a function anywhere inside of it.
 */
export function decode(val) {
  if (val === null) {
    return val;
  }
  else if (typeof val === 'undefined') {
    return val;
  }
  else if (typeof val === 'boolean') {
    return val;
  }
  else if (typeof val === 'number') {
    return val;
  }
  else if (typeof val === 'string') {
    return entities.decode(val);
  }
  else if (typeof val === 'object') {
    if (Array.isArray(val)) {
      return val.map((_) => decode(_));
    }
    else {
      let decoded = {};
      Object.keys(val).forEach((_) => decoded[_] = decode(val[_]));
      return decoded;
    }
  }
  else {
    throw new Error(`Does not work with type \`${typeof val}\``);
  }
}

export const entityDecoder = {
  decode
};
