import React from 'react';
import PropTypes from 'prop-types';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  allLocationsSelected,
  allRaceResultsSelected,
  blockEntries,
  filterResults,
  getFiltered,
  getLastUnclaimedSearchQuery,
  getLocationsForYear,
  getUnclaimedResults,
  getUnclaimedResultsStream,
  raceResultSelected,
  searchUnclaimed,
  setLastUnclaimedSearchQuery,
  unBlockEntries
} from '../../data/UnclaimedSearchStreams';
import clearSearchFilters from '../../utils/ClearSearchResults';
import {
  defaultUnclaimedResultsFilters,
  getUnclaimedResultsSearchFilters
} from '../../data/SearchFilters';
import { setTermValue } from '../../data/SearchTerm';
import { NotFound } from '../../views/notFound/NotFound';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { PageTemplate } from '../home/PageTemplate';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import { toggle } from '../../shared/util';
import { UnclaimedSearch } from '../../components/unclaimedSearch/UnclaimedSearch';

export class UnclaimedSearchPageComponent extends React.Component {
  static propTypes = {
    birthYear: PropTypes.string,
    filters: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    lastUnclaimedSearchQuery: PropTypes.object,
    results: PropTypes.object,
    searchTerm: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    results: {}
  };

  constructor(props, ctx) {
    super(props, ctx);

    const unclaimedResults = getUnclaimedResults((props.results || {}).results);
    this.state = {
      canSearch: _.size(this.props.searchTerm) > 3,
      filtered: {
        raceResults: [],
        locations: []
      },
      searchFilters: {},
      selectedRaces: [],
      unclaimedResults
    };

    this.debouncedDoSearch = _.debounce(this.doSearch, 800);
    this.debounceResetLocations = _.debounce(this.resetLocations, 500)

    this.getFilters = this.getFilters.bind(this);
  }

  componentDidMount() {
    this.doSearch();
  }

  getFilters(filters, unclaimedData) {
    return {
      ...defaultUnclaimedResultsFilters,
      ...(filters || unclaimedData),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //select all locations from API results & filter
    const {results} = nextProps;
    const filters = this.getFilters(this.props.filters, this.props.unclaimedData);
    const nextFilters = this.getFilters(nextProps.filters, nextProps.unclaimedData);

    if ((results && !results.searching && results !== this.props.results) || filters.birthDateFilter || nextFilters.birthDateFilter) {
      const unclaimedResults = getUnclaimedResults((results || {}).results);
      const filtered = filterResults(unclaimedResults.raceResults, nextFilters).raceResults;
      this.setState({
        unclaimedResults,
        filtered
      });

    }

    if (this.props.searchTerm !== nextProps.searchTerm) {
      this.setState({
        canSearch: _.size(nextProps.searchTerm) > 3
      });
      this.debouncedDoSearch();
      this.debounceResetLocations();
    }
  }

  componentWillUnmount() {
    this.debouncedDoSearch.cancel();
  }

  resetLocations() {
    const filters = this.getFilters(this.props.filters, this.props.unclaimedData);
    const { locationFilter: { value } } = filters
    const resetValue = {
      ...filters,
      locationFilter: {
        enabled: false,
        value: []
      }
    }
    if(value.length) {
      clearSearchFilters('unclaimed', resetValue, this.props.history)
    }
  }

  getDefaultLocation(unclaimedResults, filters) {
    const locationByDate = getLocationsForYear(unclaimedResults.raceResults, filters.birthDateFilter);
    const raceLocations = this.getLocationForRace(locationByDate.raceResults, unclaimedResults.locations);
    const filteredLocations = !!raceLocations && _.uniq(raceLocations.map((loc) => loc[0]));
    const defaultLocation = getFiltered(unclaimedResults.raceResults, filteredLocations, filters);
    return defaultLocation.locations;
  }

  render() {
    const {
      selectedRaces = [],
      unclaimedResults,
      searchFilters
    } = this.state;

    const {raceResults} = unclaimedResults;

    const {
      history,
      isMobile,
      quickSearchResults,
      results = {},
      searchTerm,
      t,
      unclaimedData
    } = this.props;
    const filters = this.getFilters(this.props.filters, unclaimedData);
    const {
      searching = false,
      error
    } = results;

    if (error) {
      return (
        <NotFound errorStatus={503} />
      )
    }

    const urlBirthYear = new Date().getFullYear() - parseInt(this.props.birthYear);

    const ogTitle = searchTerm
      ? t('Athlinks Unclaimed Results Search: {{searchTerm}}', {searchTerm})
      : t('Athlinks Unclaimed Results Search');
    const ogDescription = searchTerm ? t(
      'Search Athlinks for unclaimed results that match {{searchTerm}}',
      {searchTerm}
    ) : t('Search and find your unclaimed results on Athlinks');

    return (

      <PageTemplate
        filters={filters}
        headerIsFixed={true}
        history={history}
        isMobile={isMobile}
        onSearchInput={this.onSearchInputFromHeader}
        searchTab="unclaimed"
        searchTerm={this.props.searchTerm}
        shouldHideLocationInput={true}
        showHeaderFilters={true}
        showSearchTab={'results'}
      >
        <StandardHeaderTags
          description={ogDescription}
          title={ogTitle}
        />
        <OpenGraphTags
          description={ogDescription}
          ogType='website'
          title={ogTitle}
        />
        <UnclaimedSearch
          allLocationsToggled={this.allLocationsToggled}
          allRaceResultsToggled={this.allRaceResultsToggled}
          birthYear={urlBirthYear}
          birthYearTyped={this.birthYearTyped}
          blockResultEntries={this.blockResultEntries.bind(this)}
          canSearch={this.state.canSearch}
          filterValues={filters}
          isMobile={isMobile}
          locationToggled={this.locationToggled}
          nameTyped={this.nameTyped}
          quickSearchResults={quickSearchResults}
          raceResults={filterResults(raceResults, filters).raceResults}
          raceResultToggled={this.raceResultToggled}
          resultLocations={this.getDefaultLocation(unclaimedResults, filters)}
          searchButtonPressed={this.searchButtonPressed}
          searchFilters={searchFilters}
          searching={searching}
          searchTerm={this.props.searchTerm}
          selectedRaces={selectedRaces}
        />
      </PageTemplate>
    );
  }

  getLocationForRace(raceResults, defaultLocationOptions){
    return raceResults.map((race) => {
      return defaultLocationOptions
        .filter((location) => location.Id === race.StateProvId || location.Id === race.Country)
        .map((location) => location)
    });
  }

  doSearch = (alias, location, isRefined) => {
    const { searchTerm, lastUnclaimedSearchQuery, results } = this.props;
    const filters = this.getFilters(this.props.filters);
    const completeQuery = {...filters, searchTerm};

    if ((_.isEmpty(results) || !_.isEqual(completeQuery, lastUnclaimedSearchQuery)) && this.state.canSearch) {
      setLastUnclaimedSearchQuery(completeQuery);
      searchUnclaimed({searchTerm, alias, location}, isRefined);
    }
  };

  nameTyped = (searchTerm) =>
    setTermValue(searchTerm);

  birthYearTyped = (birthYear) => {
    const filters = {...this.state.filters, birthYear};
    this.setState({
      filters
    });
  };

  searchButtonPressed = () => {
    this.debouncedDoSearch();
  };

  raceResultToggled = (id, isSelected) => {
    this.setState({
      selectedRaces: toggle(id, this.state.selectedRaces, isSelected)
    });
    const {
      selectedRaces
    } = this.state;
    this.setState({selectedRaces: raceResultSelected(selectedRaces, id)});
  };

  allRaceResultsToggled = (allSelected) => {
    this.setState({
      selectedRaces: allSelected ? {} : allRaceResultsSelected(this.state.filtered.raceResults)
    });
  };

  locationToggled = (id, isSelected) => {
    const location = toggle(id, this.state.filters.location, isSelected);
    const filters = {...this.state.filters, location};
    const { unclaimedResults } = this.state;
    this.setState({
      filters,
      filtered: getFiltered(unclaimedResults.raceResults, unclaimedResults.locations, filters)
    });
  };

  blockResultEntries(racerId, racesToBlock, blocked) {
    blocked ? unBlockEntries(racerId, racesToBlock) : blockEntries(racerId, racesToBlock);
  }

  allLocationsToggled = (allSelected) => {
    const { unclaimedResults, filtered } = this.state;

    //Toggle from all selected to none selected or vice versa
    const location = allSelected ? {} : allLocationsSelected(filtered.locations);
    const filters = {...this.state.filters, location};

    this.setState({
      filters,
      filtered: getFiltered(unclaimedResults.raceResults, unclaimedResults.locations, filters)
    });
  };

  onSearchInputFromHeader = ({target: {value}}) => {
    setTermValue(value);
  };
}

export const UnclaimedSearchPage = withTranslation()(connectStream({
  results: () => getUnclaimedResultsStream(),
  unclaimedData: ({ t }) => getUnclaimedResultsSearchFilters(t),
  lastUnclaimedSearchQuery: getLastUnclaimedSearchQuery
})(withRouter(UnclaimedSearchPageComponent, 'unclaimed')));
