import React from 'react';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { MasterEvent } from '../../classes/MasterEvent';
import { Loading } from '../../components/shared/Loading';
import { EventKioskResults } from './EventKioskResults';
import { useHistory } from 'react-router-dom';

export const MasterEventKiosk = connectStream({
  masterEvent: ({ masterId }) => MasterEvent.get(masterId),
})(({ masterEvent = new MasterEvent(), beta, eventId, masterId, isMobile, mode }) => {
  const history = useHistory();

  if(!masterEvent.isLoaded()) {
    return <Loading centered={true}/>;
  }

  return (
    <div>
      <EventKioskResults
        beta={beta}
        eventId={eventId || masterEvent.prevRaceID}
        isMobile={isMobile}
        masterEvent={masterEvent}
        masterId={masterId}
        mode={mode}
        history={history}
      />
      <div id="hiddenCanvas" hidden="hidden"></div>
    </div>
  );
});
