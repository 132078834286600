import { ReplaySubject } from 'rxjs';
import eventBus from '../lib/event-bus-rx';
import {logError} from './Logging';

let _data = {};

function streamHook(id, initial) {

  if (_data[id] === void 0) {
    const subject = new ReplaySubject(1);
    typeof initial === 'undefined' || subject.next(initial);
    eventBus.getTopic(id).subscribe((msg) => subject.next(msg));
    _data[id] = subject;
  }

  return _data[id];
}

function streamFail(id, stream, fromPredicate) {
  const failed = (error) => {
    console.log('streamFail', id, error);
    eventBus.publish(id, {
      error
    });

    logError(`streamFail-${id}-${error}`)
  };

  return stream.subscribe((msg) => {
    const result = fromPredicate(msg);
    if (result) {
      eventBus.publish(id, result);
    }
    else {
      failed(msg);
    }
  }, failed);
}

export {
  streamFail,
  streamHook,
  eventBus
}
