import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { AthlinksMedal } from '../../../shared/icons'

const mapStateToProps = () => ({
  dispatch: undefined
})

const UnfollowAthleteFailureComponent = (
  props
) => {
  const { t } = props
  return (
    <div style={style.medalContainer}>
      <div style={style.medal}>
        <AthlinksMedal />
      </div>
      <div style={style.modalTitle}>
        {t('Unfollow Unsuccessful')}
      </div>
      <div style={{ ...style.modalSubtitle, ...style.subMessage }}>
        {t(`Sorry, we were unable to follow through with unclaiming your result.`)}
      </div>
    </div>
  )
}

export const UnfollowAthleteFailure = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteFailureComponent)

const UnfollowAthleteFailureFooterComponent = (
  props
) => {
  const {
    dispatch,
    isMobile,
    t
  } = props

  const footerStyle = isMobile ? style.footerMobile : {}
  return (
    <div style={footerStyle}>
      <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
        <div style={style.doneButtonText}>
          {t('Done')}
        </div>
      </button>
    </div>
  )
}

export const UnfollowAthleteFailureFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteFailureFooterComponent)