import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import { athleteCard, buttonsContainer } from './styles';
import { aoStyles } from '../../athlete/styles';
import { isLoggedIn } from '../../../utils/isLoggedIn';
import { profileUserImageUrl } from '../../../utils/profileImageUrl';
import { getResultUrl } from '../../../utils/resultsHelpers';
import { Bib } from '../../shared/athlete/Bib';
import { HeroCardName } from './Name';
import {FollowButton} from '../../shared/Follow';
import {AthletePhoto} from '../../athlete/AthletePhoto';
import {FriendsRacing} from '../../ELP/friendsRacing/FriendsRacing';

class AthleteCardComponent extends Component {
  static propTypes = {
    isPublicProfile: PropTypes.bool,
    t: PropTypes.func
  };

  state = {
    friendStatusMsg: null
  };

  onFollow = (isFriend) => {
    const {
      result: {
        displayName
      },
      t
    } = this.props;

    const msg = isFriend
      ? t('Now Following {{name}}', {name: displayName})
      : t('No Longer Following {{name}}', {name: displayName});
    this.setState({friendStatusMsg: msg});
  };

  render() {
    const result = this.props.result || {};
    const {
      isMe,
      isMobile,
      athleteId,
      isFriend,
      event,
      shouldShowFriends,
      isPublicProfile,
      t
    } = this.props;

    const profPicUrl = profileUserImageUrl(result.racerId, 't', !!result.racerId, true);

    const genderString = /nb|non[-\s]*binary/i.test(this.props.gender)
      ? 'NB'
      : ((this.props.gender || '')[0] || '')

    const genderAndAge = genderString + ' ' + (result.age || '')

    const athleteName = `${result.firstName} ${result.lastName}`;
    const imgDim = isMobile ? {width: 50, height: 50} : {};

    const profileImg = (
      <div style={aoStyles.photoBox(isMobile)}>
        <AthletePhoto
          isMember={!!result.racerId}
          size={isMobile ? 'Small' : 'Large'}
          photo={profPicUrl}
          name={athleteName}
          containerStyle={{margin: '0 auto', display: 'inline-block'}}
          styles={{...imgDim}}
        />
      </div>
    );

    const irpUrl = getResultUrl(result.eventId, result.eventCourseId, result.entryId);
    const profileUrl = `/athletes/${result.racerId}`;

    const profileElement = !isPublicProfile || this.props.linkToIRP || !result.racerId
      ? profileImg
      : <Link
          to={profileUrl}
          style={{display:'block', textDecoration:'none'}}
          href={profileUrl}
        >
          {profileImg}
        </Link>;

    const buttonsProps = {
      isMe,
      isLoggedIn,
      isMobile,
      athleteId,
      result
    };

    const details = (
      <div itemScope itemType="http://schema.org/Person"
           style={athleteCard.contentContainer}>

        <div style={athleteCard.photoContainer(isMobile)}>
          {profileElement}
        </div>

        <div style={{textAlign: isMobile ? 'left' : ''}}>
          <HeroCardName
            linkToIRP={this.props.linkToIRP}
            result={result}
            isMobile={this.props.isMobile}
            isPublicProfile={isPublicProfile}
          />
        </div>

        <div style={athleteCard.details(isMobile)}>
          <div itemProp="gender" style={athleteCard.firstDetail} id="ageGender">
              {genderAndAge}
          </div>
          <div itemProp="homeLocation" style={isMobile ? athleteCard.firstDetail : athleteCard.secondDetail} id="IRPUserLocation">
            {this.props.homeTown}
          </div>
          {isMobile && <div itemProp="bib" style={athleteCard.secondDetail}>
           #{result.bib}
          </div> }
        </div>
        { isMobile && !shouldShowFriends && <FriendsRacing
          type="IRP"
          isMobile={isMobile}
          event={event}
          athleteCard={true}
          athleteId={athleteId}
        /> }
        {  !isMobile && result.bib &&
          <div style={athleteCard.bibContainer}>
            <div style={athleteCard.bibTitle}>
              {t('BIB')}
            </div>
            <Bib bib={result.bib} />
          </div>
        }

        { !!this.props.result.racerId &&
          <div style={buttonsContainer(isMobile)}>
            <Buttons
              isMobile={isMobile}
              isFriend={isFriend}
              onChange={this.onFollow}
              profPicUrl={profPicUrl}
              {...buttonsProps}/>
          </div>
        }
      </div>
    );

    return this.props.linkToIRP
      ? <Link to={irpUrl} style={{textDecoration: 'none'}}>{details}</Link>
      : details;
  }
}

export const AthleteCard = withTranslation()(AthleteCardComponent);

/**
 * Prints buttons for athlete profile
 *
 * @param {object} props
 * @return {XML}
 * @constructor
 */
export const Buttons = (props) => {
  const {
    isLoggedIn,
    isFriend,
    result: {locality, country, region, displayName},
    athleteId,
    isMobile,
    isMe,
    onChange,
    profPicUrl
  } = props;

  if(isMe || isFriend === undefined) return null;
  else return (
    <FollowButton
      buttonType='rectangle'
      isFriend={isFriend}
      RacerId={athleteId}
      isLoggedIn={isLoggedIn()}
      DisplayName={displayName}
      Photos={{HasImage: true, Medium: profPicUrl}}
      City={locality}
      StateProvAbbrev={region}
      CountryId3={country}
      isMobile={isMobile}
      onChange={onChange}/>
  );
};
