import { styles as searchStyles } from '../views/searchResults/styles';
import { configs } from '../configs';

export const colors = {
  blue: '#16A9E1',
  blue2: '#1390bf',
  lightBlue: '#e3f4fb',
  lightBlue2: '#99A1A9',
  darkBlue3: '#17a9e2',
  lightBlue3: '#17a9e2',
  lightBlue4: '#3ba9e0',
  lightBlue5: '#17a9e3',
  lightBlue6: '#f8f9fd',
  lightBlue7: '#60647a',
  lightBlue8: '#40445f',
  lightBlue9: '#aaacb9',
  darkBlue1: '#31667c',
  darkBlue: '#014661',
  darkBlue4: '#2c314e',
  facebookBlue: '#3b5998',
  darkType: '#4A4A4A',
  textColor: '#4a4a4a',
  darkGrey4: '#414141',
  darkGrey3: '#242424',
  darkGrey2: '#444',
  darkGrey: '#666',
  grey: '#9B9B9B',
  grey2: '#999',
  grey3: '#5f7375',
  greyHover: '#efefef',
  greyLine: '#dddddd',
  greySubText: '#848484',
  softBlueLine: '#464a64',
  labelColor: '#5C5C5C',
  lightGrey8: '#ABABAB',
  lightGray7: '#fcfcfc',
  lightGrey6: '#9b9b9b',
  lightGrey5: '#eaeaea',
  lightGrey4: '#ccc',
  lightGrey3: '#f2f2f2',
  lightGrey2: '#f4f4f4',
  lightGrey: '#fafafa',
  lightGreyHover: '#fafafa',
  white: '#fff',
  error: '#bc3a3c',
  warning: '#9b9b9b',
  success: '#5fc2a2',
  cardShadow: '0 1px 2px 0 rgba(0,0,0,0.15)',
  orange: '#f56423',
  black: '#000',
  darkRed: '#BC3A3C',
  red: '#ff0000',
  green: '#56ac60',
  snow: '#f9f9f9'
};

export const imageStyles = {
  loading: {
    height: '100%',
    width: '100%'
  },
  loadingPosition: {
    position: 'absolute',
    zIndex: 1000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 100,
    width: 100,
    overflow: 'hidden',
  },
  loadingOverlay: {
    position: 'relative',
    height: '100vh',
    width: '100%',
    textAlign: 'center',
    backgroundColor: colors.lightGrey,
  }
};

// TODO: Move the banner style into the components/shared/banner.js component. They all have the same container styling.
export const bannerStyle = (isMobile) => ({
  background: colors.white,
  clear: 'both',
  marginBottom: 20,
  boxShadow: colors.cardShadow,
  overflow: 'hidden',
  padding: isMobile ? 15 : 0
});

export const singleBanner = {
  height: 250,
  width: 300
};

export const fonts = {
  serif: {
    color: colors.textColor,
    fontFamily: 'MercuryDisplayBold,serif',
  },
  bold: {
    fontFamily: 'ProximaNovaSemibold, arial, sans-serif',
  },
  boldItalic: {
    fontFamily: 'ProximaNovaBlackItalic, arial, sans-serif',
  },
  default: {
    fontFamily: 'ProximaNovaRegular,helvetica,arial,sans-serif',
  },
  heavy: {
    fontFamily: 'ProximaNovaBold, arial, sans-serif',
  },
  number: {
    fontFamily: 'BebasNeueBold, serif'
  }
};

export const textStyles = {
  raceTitleDarkDesktop: Object.assign({}, fonts.bold, {
    color: colors.darkBlue,
    fontSize: '45px',
    letterSpacing: '-0.22px',
    lineHeight: '40px',
  }),
  raceTitleDarkMobile: Object.assign({}, fonts.bold, {
    color: colors.darkBlue,
    fontSize: '32px',
    letterSpacing: '-0.36px',
    lineHeight: 'auto',
  }),
  head1Grey: Object.assign({}, fonts.default, {
    color: colors.textColor,
    fontSize: 32,
    margin: 0,
  }),
  subhead1Grey: Object.assign({}, fonts.bold, {
    color: colors.grey,
    fontSize: '16px',
    letterSpacing: '2.56px',
    lineHeight: '20px',
  }),
  subhead2Grey: Object.assign({}, fonts.bold, {
    color: colors.grey,
    fontSize: '24px',
    letterSpacing: '2.56px',
    lineHeight: '24px',
  }),
  subhead2MenuWhite: Object.assign({}, fonts.bold, {
    color: colors.white,
    fontSize: '14px',
    letterSpacing: '2.8px',
    lineHeight: '20px',
  }),
  subhead3: {
    fontSize: 18,
    color: colors.textColor,
    letterSpacing: '0.5px',
  },
  sectionHead: Object.assign({}, fonts.heavy, {
    color: colors.grey,
    textTransform: 'uppercase',
    fontSize: 12,
    lineHeight: '1em',
    letterSpacing: '0.5px'
  }),
  body1Dark: Object.assign({}, fonts.default, {
    color: colors.textColor,
    fontSize: 14,
    lineHeight: '18px',
  }),
  body2Dark: Object.assign({}, fonts.default, {
    color: colors.textColor,
    fontSize: '14px',
    lineHeight: '28px',
  }),
  body1Grey: Object.assign({}, fonts.default, {
    color: colors.grey,
    fontSize: '14px',
    lineHeight: '20px',
  }),
  captionGrey: Object.assign({}, fonts.bold, {
    color: colors.grey,
    fontSize: '13px',
    lineHeight: '16px',
  }),
  headlineDark: Object.assign({}, fonts.bold, {
    fontSize: '24px',
    lineHeight: '24px',
    color: colors.darkBlue,
  }),
  textLink1Blue: Object.assign({}, fonts.bold, {
    fontSize: '18px',
    lineHeight: '32px',
    letterSpacing: '.36px',
    color: colors.blue,
    cursor: 'pointer',
  }),
  textLink2Blue: Object.assign({}, fonts.default, {
    fontSize: '14px',
    lineHeight: '28px',
    textDecoration: 'none',
    color: colors.blue,
    cursor: 'pointer',
  }),
  textLink3Blue: Object.assign({}, fonts.default, {
    fontSize: '13px',
    lineHeight: '28px',
    letterSpacing: '.28px',
    textDecoration: 'none',
    color: colors.blue,
    cursor: 'pointer',
  })
};
export const resultList = {
  name: (blocked = false) => ({
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-block',
    width: '100%',
    marginBottom: 4,
    verticalAlign: 'middle',
    lineHeight: 'normal',
    fontSize: '22px',
    fontFamily: 'ProximaNovaRegular',
    letterSpacing: '0.36px',
    color: (blocked) ? colors.grey : colors.textColor
  }),
};


export const mainStyles = {
    floatLeft: {
      float: 'left',
      padding: 0,
    },
  floatLeftImg: {
    float: 'left',
    padding: 0,
    backgroundImage: `url(${configs.cdnUrl}/images/banners/athlinks-generic-runner-728x90-ordf-final.jpg)`,
    height:90,
    width: 728
  },
};

export const filterStyles = {
  contentContainer: {
    backgroundColor: colors.lightGrey,
  },
  content: {
    minHeight: '90vh',
    marginTop: 80,
    padding: '20px 20px 50px',
    backgroundColor: colors.white,
    boxShadow: `1px 2px 3px ${colors.lightGrey4}`,
    transition: 'opacity 0.3s',
    overflow: 'hidden'
  },
  content2: (canShow) => ({
    opcaity: (canShow) ? 1 : 0,
    minHeight: '90vh',
    marginTop: 80,
    padding: '20px 20px 50px',
    backgroundColor: colors.white,
    boxShadow: `1px 2px 3px ${colors.lightGrey4}`,
    transition: 'opacity 0.3s'
  }),

  filters: {
    float: 'left',
  },
  pageTitle: {
    fontSize: 32,
    margin: 0,
    color: colors.textColor,
  },
  filterLabel: {
    color: colors.grey,
    fontSize: '11px'
  },
  filtersDiv: {
    paddingTop: 20,
    paddingBottom: 20,
    position: 'relative'
  },
  inputIconDiv: {
    position:'absolute',
    width:15,
    height:18,
    right:0,
    background: 'transparent'
  },
  inputIconImg: {
    width:15,
    height:18,
    marginTop: '-5px'
  },
  filterAll: {
    borderBottom: `1px solid ${colors.lightGrey2}`,
    paddingBottom: 15,
    marginBottom: 20
  },
  totalResults: {
    color: colors.textColor,
  },
  summaryRow: {
    position: 'relative',
    height: 15,
    marginBottom: 25,
  },
  summaryToggleItem: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  spanStyle: (processing) => ({
    color: colors.grey,
    fontFamily: 'ProximaNovaRegular',
    clear: 'both',
    fontSize: 14,
    marginBottom: 25,

    opacity: (processing) ? 0 : 1,
    transition: (processing) ? 'all 0.2s' : 'all 0.2s 0.2s',
  }),
  inputField: {
    ...searchStyles.searchInput,
    width:'100%',
    position: 'absolute',
    height: 20,
    paddingLeft: 0,
    borderBottom: '1px solid rgba(74,74,74,1)',
    outline:'none',
    border:0,
    boxShadow: 0,
    marginTop:0,
    borderRadius: 'none',
    backgroundColor: 'transparent',
    boxSizing: 'border-box'
  },
  loading: (canShow) => ({
    position: 'absolute',
    top: 200,
    left: 0,
    width: '100%',
    height: 70,

    opacity: (canShow) ? 1 : 0,
    transition: 'all 0.4s',
    pointerEvents: 'none',
  }),
  searchLoader: {
    height: '100%',
    backgroundColor: 'transparent',
  },
  noResults: (canShow) => ({
    position: 'absolute',
    top: 100,
    left: 0,

    ...fonts.defualt,
    fontSize: 24,
    color: colors.textColor,

    width: '100%',
    paddingRight: 100,
    textAlign: 'center',
    padding: (canShow) ? '80px 0' : 0,
    opacity: (canShow) ? 1 : 0,
    transition: 'all 0.3s',
  }),
  geoInputBorder: {
    borderBottom: '1px solid '+ colors.textColor,
    paddingBottom: 40
  }
};

export const transition = {
  fade: (canShow) => ({
    opacity: (canShow) ? 1 : 0,
    transition: 'all 0.5s',
  }),
  fadeIn: {
    opacity: 0,
    animation: 'fadeIn 0.5s forwards',
  }
};

const loadingColors = {
  loadingGrey1: colors.lightGrey5,
  loadingGrey1Shimmer: colors.lightGrey3,
  loadingGrey2: colors.lightGrey2,
  loadingGrey2Shimmer: colors.lightGrey,
};

export const loadingDark = {
  backgroundColor: loadingColors.loadingGrey1,
  backgroundImage: `linear-gradient(105deg, ${loadingColors.loadingGrey1} , ${loadingColors.loadingGrey1} 20%, ${loadingColors.loadingGrey1Shimmer}, ${loadingColors.loadingGrey1} 80%, ${loadingColors.loadingGrey1})`,
  backgroundSize: '200% 100%',
  animation: '2s 1s shimmer infinite',
};

export const loadingLight = {
  backgroundColor: loadingColors.loadingGrey2,
  backgroundImage: `linear-gradient(105deg, ${loadingColors.loadingGrey2} , ${loadingColors.loadingGrey2} 20%, ${loadingColors.loadingGrey2Shimmer}, ${loadingColors.loadingGrey2} 80%, ${loadingColors.loadingGrey2})`,
  backgroundSize: '200% 100%',
  animation: '2s 1s shimmer infinite',
};

export const arrowStyle = {
  position: 'absolute',
  top: '50%',
  cursor: 'pointer',
  zIndex: 10
};
