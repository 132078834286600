import React from 'react';
import PropTypes from 'prop-types';
import {styles} from './styles';
import {withTranslation} from 'react-i18next';
import {DateTime} from '../../shared/DateTime';
import { getDisplayLocationString } from '../../../resultsClient/getDisplayLocationString';

const EventDetailsComponent = ({ useDate = false, useLocation = true, event, isMobile = false, t }) => {
  const eventDate = useDate
    ? <DateTime
        date={event.startEpoch}
        showTime={false}
        icon={null}
        style={styles.location}
      />
    : null;
  const eventLocation = useLocation
    ? <span style={styles.location}>
        {getDisplayLocationString(event.city, event.state, event.country)}
      </span>
    : null;
  const dateAndLocation = useDate && useLocation
    ? <div>
        {eventDate} | {eventLocation}
      </div>
    : useDate
      ? eventDate
      : eventLocation;

  return (
    <div>
      <span style={styles.title}>
        {isMobile ? t('Event') : t('Event Name & Location')}
      </span>
      <div style={styles.detailsContainer}>
        <div id='event-name' style={styles.eventName}>
          {event.name}
        </div>
        <div style={styles.dateLocation}>
         {dateAndLocation}
        </div>
      </div>
    </div>
  )
};

EventDetailsComponent.propTypes = {
  useDate: PropTypes.bool,
  useLocation: PropTypes.bool,
  event: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  t: PropTypes.func
};

export const EventDetails = withTranslation()(EventDetailsComponent);
