import React from 'react';
import { styles } from './styles';
import { configs } from '../../configs';
import { Link } from 'react-router-dom';

export const Privacy = (props) => {
  const { isContentOnly = false, showDownloadLink = true } = props;
  const containerStyle = isContentOnly ? styles.contentOnly : styles.fullPage;

  return (
    <div className="row" style={containerStyle}>
      <div>
        <h1 style={{ marginBottom: 5 }}>Privacy Policy</h1>
        {showDownloadLink && <a style={styles.privacyDownload} download href={`${configs.cdnUrl}/policy/Athlinks_Privacy_Policy.pdf`}>Download</a>}
        <h5>
          <time>Last Updated: February 14, 2020</time>
        </h5>
        <h4>
          Please note we have updated our Privacy Policy, including new information relating
          to California Consumer privacy practices and rights, effective January 1, 2020.
          Click
            {<Link to="/home/ccpa">
              &nbsp;here
            </Link>} to learn more.
        </h4>
        <p>
          Athlinks and ChronoTrack including ChronoTrack Systems Corp. and its subsidiaries
          (collectively, “Athlinks,” “we,” “us,” or “our”) respect your privacy and are
          committed to protecting your personal information.
        </p>
        <p>
          This Privacy Policy (“Policy”) describes our policy for the information we collect,
          use, process, maintain, and disclose from your use of our websites, mobile apps, products,
          and services (collectively, “Services”). This information includes information that can be
          associated with a person/or could be used to identify a person (“Personal Data”), as well as
          information that does not relate to a person or cannot be used to identify a person
          (“Non-Personal Data”). The limitations and requirements in this policy on our collection, use,
          processing, disclosure, transfer, and storage/retention of Personal Data do not apply to Non-Personal Data.
          If you are a California Consumer, the&nbsp;
            {<Link to="/home/ccpa">
              Athlinks – ChronoTrack Privacy Notice for California Consumers
            </Link>}
            &nbsp;applies to you.
        </p>
        <p>
          By visiting our websites or using any of our products or services, you agree that
          your personal information will be handled as described in this policy.
        </p>
        <h2>Table of Contents</h2>
        <ol>
          <li>Personal Data We Collect</li>
          <li>How We Use the Personal Data We Collect</li>
          <li>How We Disclose and Transfer Your Personal Data</li>
          <li>How We Secure Your Personal Data</li>
          <li>Choices You Have Regarding Your Personal Data</li>
          <li>Retention of Your Personal Data</li>
          <li>Cookies, Web Beacons, and Other Technologies</li>
          <li>Children</li>
          <li>International Privacy Laws</li>
          <li>Terms of Service</li>
          <li>Exclusions</li>
          <li>Changes to This Privacy Policy</li>
          <li>Contacting Us</li>
        </ol>
        <h2>1. Personal Data We Collect</h2>
        <h3>1.1 Personal Data You Provide Us</h3>
        <p>
          In order to sign up to use our Services, there is some Personal Data we obtain that you
          voluntarily provide to us. This Personal Data may include, but is not limited to, your
          contact information such as a first and last name, email address, postal address, and
          phone number; an account password; your date of birth; your gender; your social media
          account(s) information; your subscription preferences; your photographs, videos, or
          other content you or we contribute from your participation in events; your payment and
          billing information; your participation in or donation to a particular charity or charity
          event; your geo-location; your emergency contact information; your athletic performance in
          or your training performance for events; and/or information you include in public forums,
          messages, comments, searches, or queries through the Services.

        </p>
        <p>
          From time to time, we may run a sweepstakes or contest through our Services, or ask you to
          complete questionnaires or surveys. We use the information you provide to administer the
          sweepstakes, contest or survey, to analyze the results, for research purposes, to send you
          other information or offers we think may be of interest to you, to comply with legal
          requirements, and for other purposes as described in this Policy.

        </p>
        <h3>1.2  Personal Data We Collect Automatically</h3>
        <p>
          Some Personal Data we collect automatically as you use our Services. This Personal Data may
          include, but is not limited to, your internet protocol (“IP”) addresses; your device and
          browser type; your internet service provider (“ISP”); your operating systems; statistics
          on your activities through the Services, such as your login frequency or the length of time
          spent logged in; information about how you came to our Services; advertising metrics and links
          clicked within the Services; and/or information collected through cookies, web beacons, and
          other technologies as described below under “7. Cookies, Web Beacons, and Other Technologies”.

        </p>
        <h3>1.3 Personal Data We Collect From Third Parties</h3>
        <p>
          We may also collect Personal Data about you from third-party sources such as, though not
          limited to, event organizers, event timers, and other commercially and publically available
          sources. For example, if you click through our Services to register for an event, the event
          organizer may provide us with your name, contact information, age, gender, and race time. The
          information we receive from third parties may be combined with the information we collect,
          including personally identifiable information that we collect about you.
        </p>
        <p>
          You may also be able to choose to link your account with certain third party applications,
          such as Facebook or Google. When you link your account to one of these third party applications,
          we will request permission to access your basic information such as your name, profile picture,
          gender, networks, user ID, list of friends, and any other information you’ve made public on that
          application. For more information regarding linking your account with third party applications,
          please see “3.7 Third Party Applications” below.
        </p>
        <h3>1.4  Personal Data From Your Mobile Device</h3>
        <p>
          We may provide features that rely on the use of additional information on your mobile device or
          require access to certain services through your mobile device that will enhance your experience but
          are not required to use the Services. Granting us access to this information does not mean you are
          granting us unlimited access to that information or that we will access specific information without
          your permission. Some Personal Data from your mobile device, such as your mobile device ID, your operation
          system, your mobile carrier, and your IP address, are collected automatically when you use our Services.
          When using our Services through your mobile device, we will request permission to obtain your current
          location to provide you with location-related services. In the “Settings” function on your phone, you
          will have the ability to manually permit or preclude us from recording your geolocation information for
          certain features of the Services.
        </p>
        <h2>2. How We Use the Personal Data We Collect</h2>
        <h3>2.1 Your Account</h3>
        <p>
          We may use the Personal Data that we receive or collect about you for purposes such as to register your
          account for certain Services we provide, to communicate with you about our Services for customer service
          purposes, to register you for events and provide event rosters and results, to help facilitate your
          participation in a charity event, to improve our Services by providing personalized experiences, location
          customization, personalized help and instructions, and for other customer service purposes. We may obtain
          additional Personal Data about you to keep our records current.
        </p>
        <p>
          Third parties should note that we may use information we receive or collect regarding users (including
          without limitation via an event registration page) in accordance with the terms of this Policy.
          In certain contexts, we collect information on behalf of third parties that is subject to
          contractual requirements that limit our ability to use and transfer your information in
          ways that are narrower than those in this Policy. In those limited circumstances, your
          information is subject to those contractual requirements and not to this Policy, and is
          subject to enforcement by the applicable third party. If your information is collected
          on behalf of a third party, it will be evident at the time that you provide such information.
          This Policy does not cover a third party’s use of your information outside of our Services.
          You will need to contact that party directly to determine if your information is subject to
          such limitations on uses and to determine how the third party will make use of your information.
        </p>
        <h3>2.2 Our Business Use</h3>
        <p>
          We may use the Personal Data that we receive or collect about you for internal business purposes
          such as helping us improve the content and functionality of our Services, to better understand our
          users and how they use our Services, to improve the Services we offer, to develop new features or
          services, to manage your account, to provide you with customer service, to help improve our security
          and prevent fraud, to comply with all legal obligations and rights, and to generally manage the
          Services and our business.
        </p>
        <h3>2.3 Communications and Marketing </h3>
        <p>
          We may use the Personal Data that we receive or collect about you for communications purposes such
          as to provide you with information you have requested to receive from us in response to your opt-in
          requests, to send you electronic communications regarding events, news and updates, newsletters, and
          promotions, to send you promotional or marketing materials via electronic communications, to provide
          you with offers for third party products and services, and to inform you of new changes or updates to
          our Services. We may also use your Personal Data for marketing and advertising purposes such as
          advertising our Services on third party websites and in displaying targeted content and advertisements
          to you on or off our Services. For further information regarding electronic communications, please see
          “5.3 Opting Out of Electronic Communications” below.
        </p>
        <h2>3. How We Disclose and Transfer Your Personal Data</h2>
        <h3>3.1 Not Selling Your Personal Data</h3>
        <p>
          We consider your trust in us regarding your Personal Data to be an important part of our relationship
          with you. Therefore, we will not sell your Personal Data to third parties, including third party
          advertisers. There are some circumstances, however, where we may disclose, transfer, or share your
          Personal Data with a third party without notice to you, as described below.
        </p>
        <h3>3.2 Business Transfers</h3>
        <p>
          There may come a time where we sell or buy businesses or assets. In the event of a corporate sale,
          merger, reorganization, dissolution, or similar event, Personal Data may be considered part of the
          transferred assets. By using our Services, you acknowledge and agree that any successor to Athlinks
          (or its assets) will continue to have the right to use your Personal Data in accordance with this
          Privacy Policy.
        </p>
        <h3>3.3 Parent Companies, Subsidiaries, and Affiliates</h3>
        <p>
          We may share your Personal Data with our parent companies, subsidiaries, and/or affiliates for
          purposes according to this Privacy Policy. Any Personal Data shared with our parent companies,
          subsidiaries, and/or affiliates will be done so in a manner consistent with the Privacy Policy
        </p>
        <h3>3.4 Contractors, Consultants, and Service Providers</h3>
        <p>
          We may share your Personal Data with our contractors, consultants, and service providers who process
          Personal Data on behalf of Athlinks to perform certain business-related functions and may be located
          wherever Athlinks operates. These companies may include, but are not limited to, marketing agencies,
          database service providers, backup and disaster recovery service providers, email service providers,
          and others. When we engage another company to perform such business-related functions that may require
          access to Personal Data, these companies will agree to maintain the confidentiality, security, and
          integrity of such Personal Data in accordance with this Privacy Policy. Access to Personal Data that
          these companies receive will be limited to only Personal Data needed to successfully complete the
          business-related function they have been engaged by us for unless you expressly permit them to use
          your information for other purposes.
        </p>
        <h3>3.5 Event Organizers</h3>
        <p>
          When you register for an event through an event page or through a page on our Services, we provide
          the Personal Data entered on the applicable page to the event organizer. If an event has a charity
          fundraising component to it, your Personal Data may be provided to that charity as well. An event
          organizer may appoint a third party, which may or may not be affiliated with the event organizer,
          to create an event page on its behalf, in which case, that third party may also have access to your
          Personal Data. By using our Services to register for an event, you agree that Athlinks is not
          responsible for the actions of these event organizers or third parties they use with regards to
          your Personal Data. It is important that you review the applicable privacy policies of the event
          organizers, and if applicable, their appointed third parties, before providing Personal Data or
          other information in connection with an event.
        </p>
        <h3>3.6 Marketing Partners</h3>
        <p>
          We may disclose your Personal Data to third parties with whom we have marketing, promotional, or
          other advertising relationships. These third parties may use your information for marketing purposes,
          such as to market products and services they believe would be of interest to you. We may combine the
          Personal Data that we collect about you with other information that we obtain from third parties. This
          information may be used to help us determine what advertisements to direct to you, to place on our
          websites, and where to advertise our Services. You have the ability to opt-out of certain uses of your
          information, as well as the ability to opt-in to receiving certain information from us and these third
          parties as discussed below in “5.3 Opting Out of Electronic Communications”. These third parties may
          also use cookies, JavaScript, web beacons, and other technologies to measure the effectiveness of their
          ads and to personalize advertising content to you. For further information about the use of third party
          technologies, please see “7. Cookies, Web Beacons, Etc.”.
        </p>
        <p>
          We may share aggregate or anonymized information about users with third parties for marketing, advertising,
          research, or similar purposes. For example, if we display advertisements on behalf of a third party, we may
          share aggregate, demographic information with that third party about the users to whom we displayed the
          advertisements.
        </p>
        <p>
          At times, our Services may contain links to other third party websites and services.
          Any access to and use of such linked websites is not governed by this Privacy Policy,
          but is governed by the privacy policies of those third party websites and services.
          We are not responsible for the information practices and policies of such third party
          websites and services.
        </p>
        <h3>3.7 Third Party Applications</h3>
        <p>
          Some our Services allow you to connect your Athlinks account to third party services, like
          Facebook or RunSignUp for example, through Single Sign On authorization (“SSO”). If you choose
          to connect your account with a third party application, that third party application may have
          access to certain Personal Data including, but not limited to, your name, username, email address,
          location, and age. Connecting your account may also allow a third party application to collect your
          IP address, which page(s) you are visiting on our Services, and may set a cookie for its feature(s)
          to function properly. Additionally, that third party application may share some of your Personal Data
          on its service with Athlinks, and your interactions with it on our Services may be shared with others
          within your social network. Depending on the application, your ability to adjust your account settings
          and the sharing of your Personal Data may reside in our Services or within the third party application.
          Please be aware that your ability to use SSO with third party applications may be impacted and/or prevented
          by any limitations you set with your Personal Data.
        </p>
        <p>
          A third party application’s use of information collected from you (or as authorized by
          you) is governed by the third party application’s Privacy Policy and your settings on
          its service, and that Athlinks’ use of such information is governed by this Privacy
          Policy and your Athlinks account settings. Athlinks is not responsible for the collection
          and use of your information by third party applications.
        </p>
        <h3>3.8 Legal Requirements</h3>
        <p>
          We may disclose your Personal Data if required to do so by law in order to respond to a subpoena or
          request from law enforcement, a court, or a government agency (including in response to public
          authorities to meet national security or law enforcement requirements), or in the good faith
          belief that such action is necessary to (i) comply with a legal obligation, (ii) protect or
          defend our rights, interests, or property, or that of third parties, (iii) prevent or investigate
          possible wrongdoing in connection with our Services, (iv) act in urgent circumstances to protect
          the personal safety of users of our Services or the public, or (v) protect against legal liability.
        </p>
        <h2>4. How We Secure Your Personal Data </h2>
        <p>
          We take your security seriously and strive to take reasonable steps to protect your information.
          No data transmission over the internet or information storage technology can be guaranteed to be
          100% secure. The following is a summary of the measures we take to protect the Personal Data you
          provide us and an explanation of ways we implement these measures. We continue to evaluate and
          implement enhancements in security technology and practices, however we can only take steps to
          help reduce the risks of unauthorized access. You can also take steps to protect your information
          and further minimize the likelihood that a security incident may occur.
        </p>
        <h3>4.1 Secure Socket Layer </h3>
        <p>
          We use Secure Socket Layer (“SSL”) encryption when transmitting certain kinds of information,
          such as payment information or Personal Data. An icon resembling a padlock is displayed in most
          browsers’ window or address bar during SSL transactions. For example, any time we ask for a credit
          card number for payment or for verification purposes, it will be SSL encrypted in its transmission
          to our third party payment gateway, Authorize.net. The information you provide will be stored securely
          on Authorize.net services. Once you choose to store or enter your credit card number in our Services,
          it will not be displayed back in entirety for any users. Instead of the entire number, you will only
          see asterisks and either the first four digits or the last four digits of your number.
        </p>
        <h3>4.2 Secure Storage</h3>
        <p>
          We maintain reasonable physical, electronic, and procedural safeguards that comply with federal
          regulations to protect personal information about you.
        </p>
        <h3>4.3 Vendors and Partners</h3>
        <p>
          We work with vendors and partners to protect the security and privacy of user information.
          We host our Services on Amazon AWS, who maintains its own procedures and controls to ensure
          data security. Details can be found at <a target="_blank" rel="noopener noreferrer" href="https://aws.amazon.com/security/#overview">https://aws.amazon.com/security/#overview</a>
        </p>
        <h3>4.4 Employee and Contractor Access to Personal Data</h3>
        <p>
          We limit access to personal information about you only to those trained employees who we
          reasonably believe need to come into contact with that information to provide products
          and services to you in order to do their jobs.
        </p>
        <h2>5. Choices You Have Regarding Your Personal Data</h2>
        <h3>5.1 Accessing, Updating, Correcting, or Deleting Your Personal Data</h3>
        <p>
          You may request access to some of your Personal Data being stored by us. As required by applicable
          law, including the U.S.–EU Privacy Shield Framework, you may contact us to confirm whether we maintain,
          or process on behalf of a third party, any of your Personal Data.
        </p>
        <p>
          If your personal information changes, or if you have determined that the Personal Data we collected
          about you is inaccurate or processed in violation of applicable law, including the U.S.– EU Privacy
          Shield Principles, you may also request that your Personal Data be corrected, amended, or deleted.
          Requests for access to your Personal Data and to have it corrected, amended, or deleted should be
          sent to  <a href="mailto:privacy@athlinks.com">privacy@athlinks.com </a> or to the mailing address
          provided under “Contacting Us”. We will try to meet all requests regarding Personal Data, however,
           we may decline to process requests that are unreasonably repetitive, require disproportionate technical
           effort, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise
           required by local law.
        </p>
        <p>
          If your information has been shared with a third party, as described above, then that third
          party has received their own copy of your data. If you have been contacted by one of these
          third parties and wish to correct or request they delete your information, please contact
          them directly.
        </p>
        <h3>5.2 Limiting the Personal Data You Provide</h3>
        <p>
          You can browse our Services without providing any Personal Data (other than data
          automatically collected to the extent it is considered Personal Data under applicable laws)
          or by limiting the Personal Data you provide. If you choose not to provide any Personal
          Data or limit the Personal Data you provide, you may not be able to use certain functionality
          of the Services.
        </p>
        <h3>5.3 Opting Out of Electronic Communications</h3>
        <p>
          Athlinks may send you electronic communications marketing or advertising through the Services
          themselves or events on the Services. When you use our Services, you will automatically receive
          “Athlinks News and Updates” and “Partner Offers” from us or other third parties. If at any
          time you would like to stop receiving the information that you have requested to receive from us,
          you may follow the opt-out instructions contained in any such electronic communication. Additionally,
          you may also manage your email preferences at any time through your account.
        </p>
        <p>
          If you opt-out of receiving emails or promotions from us, we may still send you emails about your
          account or any Services you have requested or received from us, or for other customer services purposes.
          In addition, you may still receive emails sent by third parties through means other than our Services.
          If you opt-out of receiving information related to a particular event, you may still receive Athlinks
          communications or communications from other organizers whose events you have attended, are registered
          to attend, or who have otherwise obtained your email address. You may have to unsubscribe from multiple
          emails before you stop receiving all communications related to events for which you registered through
          our Services.
        </p>
        <p>
          Please be aware that you cannot unsubscribe from update communications about our Services
          unless you deactivate your account. If you wish to close your account, please email
          <a href="mailto:privacy@athlinks.com"> privacy@athlinks.com</a>. Even after you opt-out of all
          communications, we will retain your Personal Data in accordance with this Privacy Policy,
          as provided under “6. Retention of Your Personal Data”, though we will no longer use it to
          contact you. However, third parties who have received your Personal Data in accordance with
          this Privacy Policy may still use that Personal Data to contact you in accordance with their
          own privacy policies. You will need to contact those third parties in order to have your
          information deleted from their records as well. If you wish to not have your Personal Data
          shared with third parties for the creation and display of targeted advertisements, you will
          need to close your account. You can do so by emailing us at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com</a>.
        </p>
        <h3>5.4 Social Media Notifications</h3>
        <p>
          If you connect a third party application through SSO or sign up for other social media
          integrations, you may receive social notifications from these additional services or from
          Athlinks’ Services. You can manage these social notifications by toggling your social
          settings to private or disconnecting such integration. For more information regarding
          connection of third party applications, see “3.7 Third Party Applications”.
        </p>
        <h2>6. Retention of Your Personal Data</h2>
        <p>
          We may retain your Personal Data as long as you are registered to use our Services. You may
          close your account by contacting us by email at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com </a>
          or by mail at the address listed under “13. Contacting Us”. However, please be aware we may
          retain Personal Data for an additional period as required under applicable laws. After we
          delete your Personal Data, it may exist on backup or archival media or servers for an
          additional period of time for legal, tax, or regulatory reasons, or for legitimate and
          lawful business purposes.
        </p>
        <h2>7. Cookies, Web Beacons, Etc.</h2>
        <h3>7.1 What Cookies and Web Beacons Are</h3>
        <p>
          Cookies are small data files placed onto your computer or mobile device when you visit a
          website which allows our Services to distinguish you from other users, allow the Services to
          work properly, as well as to help monitor advertising effectiveness. Web beacons work similar
          to cookies, however, instead of a file stored on your computer, web beacons are embedded
          invisibly on web pages. We use cookies, web beacons, tags, and other similar tracking
          technologies to track when you visit our websites and mobile applications (collectively,
          “Cookies”).  We also allow third parties to place Cookies on our Services to assist with advertising.
        </p>
        <h3>7.2 How We Use Cookies</h3>
        <p>
          There are several reasons we use Cookies on our Services. Some of these Cookies are
          necessary for technical reasons and help our Services to operate as intended for your benefit
          (“Essential Cookies”), such as access to secure areas of the Services. Other types of Cookies
          are used for analytical purposes such as how our Services are being used, the effectiveness of
          advertising shown as you use the Services, and to customize advertising to you (“Analytical
          Cookies”). While these types of Cookie help us to create new features, improve on our existing
          Services, and provide advertisements of interest to you, our Services can function without them.
        </p>
        <h3>7.3 Controlling Cookies</h3>
        <p>
          You have the right to decide whether to accept or reject Cookies. You can exercise your Cookie
          preferences by activating the setting on your web browser that allows you to refuse the setting
          of all or some Cookies. However, if you use your browser settings to block all cookies (including
          Essential Cookies), you may not be able to access or use all or parts of our Services. If you have
          not adjusted your browser setting so that it will refuse cookies, our system will issue Cookies as
          soon as you visit our Services. Blocking or deleting Cookies will not prevent device identification
          and related data collection from occurring when you access our Services. Turning off Analytical
          Cookies will prevent their ability to measure the relevance and effectiveness of our Services,
          emails, and advertising as well as to show you tailored advertising, however this does not mean
          you will no longer see any advertisements when you use our Services.
        </p>
        <h2>8. Children</h2>
        <p>
          The Children’s Online Privacy Protection Act of 1998 and its rules (“COPPA”) requires us to
          inform parents and legal guardians about our practices for collecting, using, and disclosing
          personal information from children under the age of thirteen (13). In some countries, even
          higher age limits may apply. Athlinks does not intend to collect Personal Data from children
          under the age of thirteen (13), or the otherwise applicable age limit if different in your
          country of location. If you are aware of a user under the age of thirteen (13) who has provided
          Personal Data through our Services, please contact us at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com </a>. Parents and
          legal guardians may choose to provide information about their children, even if under the age
          of thirteen (13), for the purposes of event registration on our Services. Please be aware that
          the results of all events, including name, age, gender, and results time, are made publicly
          available and published online after an event’s completion.
        </p>
        <h2>9. International Privacy Laws</h2>
        <h3>9.1 EU–U.S. and Swiss–U.S. Privacy Shield</h3>
        <p>
          Athlinks complies with the EU–U.S. Privacy Shield Framework and the Swiss–U.S. Framework as set
          forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal
          information transferred from the European Union and the United Kingdom and Switzerland to the United
          States, respectively. Athlinks has certified to the Department of Commerce that it adheres to the
          Privacy Shield Principles of Notice, Choice, Accountability for Onward Transfer, Security, Data
          Integrity and Purpose Limitation, Access, and Recourse, Enforcement and Liability. If there is any
          conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy
          Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our
          certification, please visit  <a href="https://www.privacyshield.gov/">https://www.privacyshield.gov/</a>.
        </p>
        <p>
          Athlinks is responsible for the processing of personal data it receives under the Privacy Shield
          Framework, and subsequent transfers to a third party acting as an agent on its behalf. Athlinks
          complies with the Privacy Shield principles for all onward transfers of personal data from the EU,
          UK, or Switzerland, including the onward transfer liability provisions.
        </p>
        <p>
          With respect to personal data received or transferred pursuant to the Privacy Shield Framework,
          Athlinks is subject to the regulatory enforcement powers of the U.S. Federal Trade Commission.
          In certain situations, Athlinks may be required to disclose personal data in response to lawful
          requests by public authorities, including to meet national security or law enforcement requirements.
        </p>
        <p>
          If you are an EU, UK, or Swiss resident and have an unresolved privacy or data use concern that we
          have not addressed satisfactorily, please contact our U.S.-based third party dispute
          resolution provider (free of charge) at <a href="https://www.jamsadr.com/file-an-eu-us-privacy-shield-or-safe-harbor-claim">https://www.jamsadr.com/file-an-eu-us-privacy-shield-or-safe-harbor-claim</a>.
        </p>
        <p>
          Under certain conditions, more fully described on the Privacy Shield <a href="https://www.privacyshield.gov/article?id=How-to-Submit-a-Complaint">website</a>,
          if you are an EU or Swiss resident, you may invoke binding arbitration when other dispute
          resolutions have been exhausted.
        </p>
        <h2>10. Terms of Use</h2>
        <p>
          Your use of our Services, as well as any dispute over privacy, is subject to this Privacy
          Policy and our Terms of Service, available at <a href="https://www.athlinks.com/home/terms">https://www.athlinks.com/home/terms </a>
          and incorporated by reference here.
        </p>
        <h2>11. Exclusions</h2>
        <h3>11.1 Personal Data Provided to Others</h3>
        <p>
          This Privacy Policy does not apply to any Personal Data that you provide to another user or
          visitor through the Services or through any other means, including to organizers on event
          pages or information posted by you to any public areas of the services.
        </p>
        <h3>11.2 Third Party Links</h3>
        <p>
          This Privacy Policy applies only to the Services we offer. The Services may contain links to
          other websites not operated or controlled by us (the “Third Party Sites”). The policies and
          procedures we described here do not apply to the Third Party Sites. The links from the Services
          do not imply that we endorse or have reviewed the Third Party Sites. We suggest contacting
          those sites directly for information on their privacy policies.
        </p>
        <h3>11.3  Aggregated Personal Data</h3>
        <p>
          In an ongoing effort to better understand and serve the users of our Services, we often conduct
          research on our customer demographics, interests, and behavior based on Personal Data and other
          information that we have collected. This research may be compiled and analyzed on an aggregate
          basis and this aggregate information does not identify you personally and we therefore consider
          and treat this data as Non-Personal Data.
        </p>
        <h2>12. Changes to This Privacy Policy</h2>
        <p>
          Athlinks reserves the right to update or modify this Privacy Policy at any time. If we make
          changes to this Privacy Policy, we will post the revised Privacy Policy to our website with a
          changed “Last Updated” date at the top of this Privacy Policy. All changes are effective 30 days
          after posting. Your continued use of Services following the effectiveness of any changes
          constitutes acceptance of those changes. If you do not agree with any of the changes to the
          Privacy Policy, you should cease accessing, browsing, and otherwise using of the Services we
          provide, and close your account by emailing us at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com</a>.
        </p>
        <h2>13. Contacting Us</h2>
        <p>
          We regularly reviews our practices regarding Personal Data and this Privacy Policy. If you
          have questions, comments, or concerns about this Privacy Policy, please contact us at:
        </p>
        <ul>
          <li>By email at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com</a></li>
          <li>By mail at:</li>
          <address>
            ATTN: Athlinks Privacy Team <br/>
            2902 Corporate Place <br/>
            Chanhassen, MN 55317 <br/>
          </address>
        </ul>
      </div>
    </div>
  );
};