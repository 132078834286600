import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { AthlinksMedal } from '../../../shared/icons';

const mapStateToProps = () => ({
  dispatch: undefined
});

class ClaimEntrySuccessComponent extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div style={style.medalContainer}>
        <div style={style.medal}>
          <AthlinksMedal />
        </div>
        <div style={style.modalTitle}>
          {t('Entry Claimed!')}
        </div>
        <div style={{ ...style.modalSubtitle, ...style.subMessage }}>
          {t(`This entry and any results associated with it are now a part of your personal Athlinks profile`)}
        </div>
      </div>
    )
  }
}
export const ClaimEntrySuccess = connect(mapStateToProps, null)(withTranslation()(ClaimEntrySuccessComponent));

export class ClaimEntrySuccessFooterComponent extends React.Component {
  render() {
    const {
      dispatch,
      isMobile,
      t
    } = this.props;

    const footerStyle = isMobile ? style.footerMobile : {};

    return (
      <div style={footerStyle}>
        <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
          <div style={style.doneButtonText}>
            {t('Done')}
          </div>
        </button>
      </div>
    )
  }
}
export const ClaimEntrySuccessFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(ClaimEntrySuccessFooterComponent);
