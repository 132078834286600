import {Nobles} from '../../lib/anuket';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {dateUtils} from '../../lib/bastetjs/utils/dateUtils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {of as ObservableOf} from 'rxjs';
import { useStyles} from './styles';
import i18n from '../../utils/i18n';

function weatherTitle(startEpoch, t) {
  const aWeekAway = 86400 * 7; // Seven days.
  const rightNow = (new Date()).getTime() / 1000;
  const isInPast = startEpoch && startEpoch < rightNow;
  const isForecast = startEpoch
    && startEpoch >= rightNow
    && startEpoch - rightNow <= aWeekAway;
  return isForecast ? t('Forecast') : (isInPast
    ? t('Weather') : t('Avg Weather'));
}

function haveMaxTemperatures(weather) {
  return weather.tempmax
    && weather.tempmaxCelsius
    && !Number.isNaN(weather.tempmax)
    && !Number.isNaN(weather.tempmaxCelsius);
}

/**
 * Parse date
 *
 * @param eventDate
 * @returns {number}
 */
function convertDate(eventDate){
  return Date.parse(eventDate) / 1000;
}

const WeatherComponent = ({
  type,
  result,
  event,
}) => {
  const isOnIrp = type === 'resultsSummary';

  const { t } = useTranslation();
  const styles = useStyles({ isOnIrp });

  const {
    isLoading,
    weather = {
      conditions: '',
    },
  } = result;

  const title = weatherTitle(convertDate(event.startTime), t);
  const summary = weather.conditions?.replace(/\.$/, '');
  const roundedTempF = Math.round(weather.tempmax);
  const roundedTempC = Math.round(weather.tempmaxCelsius);

  const temperatures = (
    <div className={styles.temperatureNumber}>
      <span>
        {roundedTempF}&deg;
        <span className={styles.temperatureUnit}>F</span>
      </span>

      <span className={styles.divider} />

      <span>
        {roundedTempC}&deg;
        <span className={styles.temperatureUnit}>C</span>
      </span>
    </div>
  );

  return (
    <div>
      {!isOnIrp && (
        <div className={styles.title}>
          {title}
        </div>
      )}

      {isLoading && (
        <div className={styles.loadingWeather} />
      )}

      {!isLoading && !haveMaxTemperatures(weather) && (
        <p>N/A</p>
      )}

      {!isLoading && haveMaxTemperatures(weather) && (
        <div className={styles.container}>
          {!!haveMaxTemperatures(weather) && temperatures}

          <div className={styles.iconContainer}>
            <div className={`weather-${weather.icon} ${styles.icon}`} />

            {!isOnIrp && (
              <div className={styles.summary}>
                {summary}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

WeatherComponent.propTypes = {
  result: PropTypes.object,
};

WeatherComponent.defaultProps = {
  result: {isLoading: true}
};

export const Weather = connectStream({
  result: ({event}) => {
    const { location = {} } = event;
    const lat = event.latitude || location.latitude || false;
    const long = event.longitude || location.longitude || false;
    const timeZone = event.timeZone || location.timeZone || false;
    const eventTime = event.startEpoch || convertDate(event.startTime) || false;

    return (lat && long)
      ? Nobles.WeatherNoble.get({
        coords: [lat, long],
        dateTime: dateUtils.formatted(
          eventTime,
          '%FT%T%z',
          timeZone
        ),
        lang: i18n.language
      })
      : ObservableOf({});
  }
})(WeatherComponent);
