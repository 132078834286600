import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import {
  streamFail,
  streamHook
} from './Streams';

const blogStreamId = 'BlogList';

export function getBlog() {
  const url = configs.bragiUrl + `/api/blog`;

  streamFail(
    blogStreamId,
    FetchApi.memoizeFetch(10000)(url, {method: 'GET'}),
    (msg) => {
      return (msg.ErrorMessage ? { error: msg.ErrorMessage } : msg);
    }
  );
}

export function getBlogStream() {
  return streamHook(blogStreamId, []);
}