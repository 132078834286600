import { configs } from '../../configs';
import { textStyles, colors } from '../../shared/styles';

export const styles = {
  suggestions: {
    zIndex: 2,
    width: '380px',
    minWidth: '380px',
    textAlign: 'left',
    backgroundColor: colors.white,
    borderRight: `1px solid ${colors.lightBlue2}`,
    borderLeft: `1px solid ${colors.lightBlue2}`,
    borderBottom: `1px solid ${colors.lightBlue2}`,
    position: 'absolute',
    overflow: 'hidden',
    display: ''
  },
  suggestion: Object.assign({}, textStyles.captionGrey, {
    cursor: 'pointer',
    padding: '12px',
    display: ''
  }),
  searchContainer: {
    marginTop: '24px',
    display: 'flex'
  },
  searchInput: Object.assign({}, textStyles.captionGrey, {
    padding: '12px',
    width: '240px',
    minWidth: '240px',
    boxSizing: 'border-box',
    outline: 'none',
    border:0,
    borderBottom: `1px solid ${colors.lightGrey}`
  }),
  mobileSearchInput: Object.assign({}, textStyles.captionGrey, {
    padding: '12px',
    width: '100%',
    border: `1px solid ${colors.lightBlue2}`,
    boxSizing: 'border-box',
    background: `url(${configs.bragiUrl}/svg/search-icon.svg) no-repeat`,
    backgroundPosition: 'right 6px top 6px'
  }),
  searchButton: Object.assign({}, textStyles.captionGrey, {
    width: '120px',
    marginLeft: '12px',
    color: colors.white,
    borderRadius: '50px',
    cursor: 'pointer',
    padding: '14px 20px 14px 20px',
    border: `solid 1px ${colors.white}`,
    backgroundColor: colors.blue
  }),
  suggestionText: (isFocused) => ({
    color: isFocused ? 'rgb(239,239,239)' : colors.darkGrey
  }),
  typeaheadOptionContainer: (isFocused, isHovered, isSelected = false, isKiosk = false) => ({
    cursor: 'pointer',
    padding: isKiosk ? '15px' : '5px',
    textAlign:'left',
    backgroundColor:
      isFocused? colors.blue : isHovered ? colors.lightGrey3 : isSelected ? colors.lightBlue : colors.white,
    borderBottom:`1px solid ${colors.greyLine}`
  }),
  typeaheadOptionSpanWrap: {
    color: colors.grey,
    fontSize: 12
  },
  typeaheadOptionGender: {
    borderRight: `1px solid ${colors.grey}`,
    paddingRight: 7,
    marginRight: 7
  },
  typeaheadWrap: {
    position:'relative',
    display:'inline-block',
    width:'100%'
  },
  typeaheadDropDown: {
    border: `1px solid ${colors.lightGrey}`,
    boxShadow: '0 0 9px 5px rgba(0,0,0,.05)',
    backgroundColor: colors.white
  },
  searchStyle: {
    position: 'absolute',
    left: 0,
    bottom: 10
  }};
