import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import {injectIntl} from 'react-intl';
import { connect } from 'react-redux';
import { configs } from '../../configs';
import Finished from 'react-icons/lib/io/android-checkmark-circle';
import Circle from 'react-icons/lib/fa/circle-o-notch';

import { colors } from '../../shared/styles';
import { styles } from './styles';
import {handlePrint} from '../../utils/handlePrint';
import {trackGoogleEvent} from  '../../utils/googleEvents';
import RaisedButton from '../../components/button/RaisedButton';

const printStatusSendingPrint = {
  label: 'Sending To Printer',
  icon: <Circle size={40} color={colors.white} className='spinAnimation' style={{
    verticalAlign: 'middle',
    position: 'absolute',
    marginLeft: 0,
    left: 18,
    top: 8,
  }} />
}
const printStatusPrintSent = {
  label: 'Sent to Printer',
  icon: <Finished size={40} color={colors.white} style={{
    verticalAlign: 'middle',
    position: 'absolute',
    marginLeft: 0,
    left: 18,
    top: 8,
  }} />
}
const printStatusDefault = {
  label: 'Print Result',
  icon: <img style={styles.buttonIconStyle} src={`${configs.bragiUrl}/svg/print-icon.svg`} alt={'Print Result'}/>
}

export class IndividualKioskPrintComponent extends Component {
  constructor(props, context) {
    super(props, context);
    this.executePrintHandler = this.executePrintHandler.bind(this);
  }

  state = {
    printStatus: printStatusDefault
  }

  executePrintHandler () {
    const {result, masterEvent, intl, t} = this.props
    if (this.state.printStatus === printStatusDefault) {
      this.setState({printStatus: printStatusSendingPrint})
      trackGoogleEvent('Kiosk', 'Share', 'Print')
      handlePrint(result, masterEvent, intl, t).then(() =>
        this.setState({printStatus: printStatusPrintSent})
      )
    }
  }

  render() {
    const { printStatus } = this.state;

    if (!this.props.print.printerSet) {
      return null
    }
    const isDisabled = printStatus !== printStatusDefault

    return <RaisedButton
      label={printStatus.label}
      labelStyle={styles.kioskEmailButtonLabel}
      style={styles.kioskRegEmailButton(isDisabled)}
      disabled={isDisabled}
      labelColor={colors.white}
      onClick={this.executePrintHandler}
      icon={printStatus.icon}
      backgroundColor={colors.blue} />
  }
}

const mapStateToProps = (state) => ({
  dispatch: undefined,
  print: state.print
});

export const IndividualKioskPrint = connect(mapStateToProps)(withTranslation()(injectIntl(IndividualKioskPrintComponent)));
