import { streamHook, eventBus } from './Streams';

const refreshTokenStreamId = 'refresh-token';
const accessTokenStreamId = 'access-token';
const idTokenStreamId = 'id-token';

export function getRefreshTokenStream() {
  return streamHook(refreshTokenStreamId, {isLoading: true});
}

export function setRefreshToken(msg) {
  eventBus.publish(refreshTokenStreamId, msg);
}

export function setAccessToken(msg) {
  eventBus.publish(accessTokenStreamId, msg);
}

export function setIdToken(msg) {
  eventBus.publish(idTokenStreamId, msg);
}
