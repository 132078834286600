import { Box } from '@material-ui/core';
import moment from 'moment';
import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Nobles } from '../../lib/anuket';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { getResultsUrl } from '../../utils/resultsHelpers';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { useStyles } from './styles';

interface RaceDate {
  date: number;
  eventId: number;
}

interface ElpResultsProps {
  isMobile: boolean;
  event: {
    athlinksMasterId: number;
    athlinksId: number;
  };
  result?: {
    isLoading: boolean;
    dates?: RaceDate[];
  };
}

const ElpResultsComponent = ({
  isMobile,
  event,
  result = { isLoading: true, dates: [] }
}: ElpResultsProps) => {
  const { isLoading, dates } = result;

  const { t } = useTranslation();
  const styles = useStyles();

  const [showAllRaceDates, setShowAllRaceDates] = useState(false);

  const visibleDates = useMemo(() => {
    const RACE_DATE_DISPLAY_THRESHOLD = isMobile ? 4 : 12;

    if (showAllRaceDates) {
      return dates;
    }

    return dates?.slice(0, RACE_DATE_DISPLAY_THRESHOLD);
  }, [dates, isMobile, showAllRaceDates]);

  return (
    <div>
      <div className={styles.title}>
        {t('Results')}
      </div>

      {!dates?.length && !isLoading && (
        <p>N/A</p>
      )}

      <div className={styles.flexStyle}>
        {isLoading && Array.from({ length: 3 }).fill(Number).map((_, index) => (
          <div key={index} className={styles.loadingPlaceholderItem} />
        ))}

        {!isLoading && visibleDates?.map((date, raceDateIndex) => (
          <Link
            key={`div-${raceDateIndex}`}
            onClick={() => trackGoogleEvent('ELP', 'Click', 'getRaceDateResults')}
            to={{ pathname: getResultsUrl(event.athlinksMasterId, date.eventId) }}
            className={styles.link}>
            {moment(date.date).format('MMM DD, YYYY')}
          </Link>
        ))}

        {!!visibleDates?.length && visibleDates.length < (dates?.length || 0) && (
          <Box
            onClick={() => setShowAllRaceDates(true)}
            className={styles.link}>
            {t('Show All')}
          </Box>
        )}
      </div>
    </div>
  );
};

export const ElpResults = connectStream({
  result: ({ event }: { event: { athlinksId: number } }) => {
    return Nobles.ResultsNoble.getEventRaceDates({
      eventId: event.athlinksId
    });
  }
})(ElpResultsComponent);
