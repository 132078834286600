import React, { Component } from 'react';
import { KioskThemeSwitcher } from '../../components/kiosk/KioskThemeSwitcher';
import { colors } from '../../shared/styles';
import { AthlinksLogo } from './AthlinksLogo';
import { KioskPrinter } from '../../views/kiosk/KioskPrinter';
/**
 * Bare header. Has only Athlinks logo on left side.
 * @param {*} style
 * @return {*}
 * @constructor
 */


export class Header extends Component {
  render () {
    const {
      beta,
      style,
      themeSwitcherEnabled = true,
      isLightMode,
      onSwitchMode,
      t
    } = this.props
    return (
      <div
        style={{
          width: '100%',
          height: 66,
          bottom: 'auto',
          top: 0,
          display: 'flex',
          padding: '0 12px',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: colors.darkBlue4,
          boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
          zIndex: 10,
          ...style
        }}>
        <div>
          <AthlinksLogo fill={colors.white} showFullLogo={true}/>
        </div>
        {
          beta
          ? <div style={{flex: '0 1 100%'}}>
              <KioskPrinter {...this.props}/>
            </div>
          : <div></div>
        }
        {themeSwitcherEnabled &&
          <KioskThemeSwitcher
            isLightMode={isLightMode}
            onSwitchMode={onSwitchMode}
            t={t}
          />
        }
      </div>
    );
  }
}

/**
 * We need refs for react-sticky so we cannot go stateless
 */
export class StickyHeader extends Component {
  render() {
    return <Header {...this.props}/>;
  }
}