import React from 'react';
import ReactDOM from 'react-dom';

const IGNORE_CLASS = 'ignore-react-onclickoutside';

export default function clickOutside(BaseComponent) {

  return class ClickOutside extends React.Component {

    static displayName = `${BaseComponent.name}ClickOutside`;

    componentDidMount() {
      const component = this.refs.component
      const componentHandler = component.onClickOutside

      if(!componentHandler)
        throw new Error('Component lacks a onClickOutside(event) function for processing outside click events.');

      const makeHandler = (localNode, eventHandler) => {
        return (evt) => {
          var source = evt.target;
          var found = false;
          while(source.parentNode) {
            found = (source === localNode || source.classList.contains(IGNORE_CLASS));

            if(found) return;
              source = source.parentNode;

          }

          eventHandler(evt);
        }
      }

      this.__outsideClickHandler = makeHandler(ReactDOM.findDOMNode(this), componentHandler.bind(component));

      if (!this.props.disableOnClickOutside) {
        this.enableOnClickOutside();
      }
    }

    componentWillUnmount() {
      this.disableOnClickOutside();
      this.__outsideClickHandler = undefined;
      this.__callback = undefined
    }

    enableOnClickOutside() {
      const fn = this.__outsideClickHandler;
      document.addEventListener('mousedown', fn);
      document.addEventListener('touchstart', fn);
    }

    disableOnClickOutside() {
      const fn = this.__outsideClickHandler;
      document.removeEventListener('mousedown', fn);
      document.removeEventListener('touchstart', fn);
    }

    render() {
      return <BaseComponent {...this.props} ref="component"/>;
    }

  };
}