import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { TypeAhead } from './TypeAhead';
import { FetchGeoSuggest } from '../geosuggest/FetchGeoSuggest';
import { detectLocation } from '../../data/geocode';
import { colors } from '../../shared/styles';
import { configs } from '../../configs';
import { styles } from './styles';
import {Loading} from './Loading';

export const { googleApiKey } = configs;

const DefaultTypeaheadOption = ({option, isFocused, isHovered}) => {
  return (
    <div style={styles.getSuggestInput.descContainer(isFocused, isHovered)}>
      {option.description}
    </div>
  );
};

const DefaultCurrentLocationOption = ({isFocused, isHovered, error, detecting, location, t}) => {
  const text = (error
    ? t('Error Detecting Location')
    : detecting ? t('Detecting Location...')
    : location ? t('Current Location: {{location}}', {location: location.label})
    : t('Use Current Location')
  );

  isFocused = isFocused && !error && !detecting;

  return (
    <div style={styles.getSuggestInput.descContainer(isFocused, isHovered)}>
      {text}
    </div>
  );
};

class GeoSuggestInputComponent extends Component {
  static propTypes = {
    defaultOptions: PropTypes.array,
    detectCurrentLocation: PropTypes.bool,
    googleApiKey: PropTypes.string.isRequired,
    InputIcon: PropTypes.func,
    inputStyle: PropTypes.object,
    inputProps: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onPicked: PropTypes.func.isRequired,
    onPickedCurrentLocation: PropTypes.func.isRequired,
    onSearchingCurrentLocation: PropTypes.func,
    placeholder: PropTypes.string,
    renderTypeaheadOption: PropTypes.func,
    renderCurrentLocationOption: PropTypes.func,
    shouldAutoPickLoc: PropTypes.bool,
    term: PropTypes.string,
    typeaheadProps: PropTypes.object,
    types: PropTypes.string,
    useCurrentLocationOption: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    t: PropTypes.func
  };

  static defaultProps = {
    types: '(cities)',
    width: 300,
    detectCurrentLocation: true,
    useCurrentLocationOption: true,
    defaultOptions: [],
    onSearchingCurrentLocation: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      currentLocationError: null,
      fetched: undefined,
      location: undefined,
      shouldAutoPickLoc: props.shouldAutoPickLoc || false
    }
  }

  componentDidMount(){
    if(!this.props.term && this.props.detectCurrentLocation) {
      this.detectAndPickCurrentLocation();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const {
      error,
      fetched = [],
      location,
      detecting,
      currentLocationError
    } = this.state;

    const {
      defaultOptions,
      googleApiKey,
      inputProps,
      term = '',
      onChange,
      placeholder,
      typeaheadProps,
      types,
      useCurrentLocationOption,
      width,
      disabled,
      labelColor,
      errorText,
      t
    } = this.props;

    let options = defaultOptions;
    //Show 'use current location' as first typeahead row (if useCurrentLocationOption is true)
    if(useCurrentLocationOption && !currentLocationError) {
      options = [{isCurrentLocation: true}, ...options];
    }


    //Filter out current location from auto-complete options to avoid displaying it twice
    const currentLocationLabel = (location || {}).label;
    options = options.concat(
      (fetched || []).filter((l) => l.description !== currentLocationLabel)
    );

    let getOptions = [];
    options.map((option) => getOptions.push(option.description));

    return (
      <div style={styles.getSuggestInput.fetchGeoSuggestWrap(width)}>

        <FetchGeoSuggest
          term={term}
          types={types}
          onResults={this.onResults}
          googleApiKey={googleApiKey}
        />

        <TypeAhead
          inputStyle={styles.getSuggestInput.typeAheadInput}
          inputProps={{id:'location-search', ...inputProps}}
          useMaterialUi={true}
          value={term}
          errorText={errorText}
          labelColor={labelColor}
          disabled={disabled}
          label={t('City')}
          placeholder={placeholder}
          dropdownHeight={230}
          dropdownTop={63}
          width={'100%'}
          options={options}
          geoSearch={true}
          dropdownStyle={styles.getSuggestInput.typeAheadDropDown}
          renderOption={this.renderOption}
          onChange={onChange}
          onOptionSelected={this.onPicked}
          showDropdownOverride={detecting}
          {...typeaheadProps}
        />
        {
        // <MarkerIcon
        //   size={20}
        //   style={{position: 'absolute', right: 0, bottom: 13}}
        //   color="#517e90"/>
        }
        { detecting &&
          <div style={styles.getSuggestInput.loadingLocationContainer}>
            <div>{t('Finding your location...')}</div>
            <div style={styles.getSuggestInput.loadingLocation}>
              <Loading noTimeout={true}/>
            </div>
          </div>
        }

        {error && <div style={{color:colors.red}}>{error}</div>}

      </div>
    );
  }

  onPicked = (option) => {
    if (option.isCurrentLocation) {
      const {
        detecting,
        location,
        currentLocationError
      } = this.state;
      if (location) {
        this.props.onPickedCurrentLocation(location);
      } else {
        if (!detecting && !currentLocationError) {
          this.detectAndPickCurrentLocation();
        }
      }
    } else {
      this.props.onPicked(option);
    }
  };

  renderOption = ({option, isFocused, isHovered, value}) => {
    const {
      renderTypeaheadOption = DefaultTypeaheadOption,
      renderCurrentLocationOption = DefaultCurrentLocationOption,
      t
    } = this.props;

    if (option.isCurrentLocation) {
      const {
        currentLocationError,
        detecting,
        location
      } = this.state;
      return renderCurrentLocationOption({isFocused, isHovered, location, detecting, error: currentLocationError, t});
    } else {
      return renderTypeaheadOption({option, isFocused, isHovered, value});
    }
  };

  // ova funkcija setuje lokaciju
  detectAndPickCurrentLocation() {
    const { googleApiKey } = this.props;
    this.props.onSearchingCurrentLocation(true);
    this.setState({
      detecting: true,
      currentLocationError: null
    }, () => {
      detectLocation(navigator.geolocation, googleApiKey)
        .then(
          this.onLocationDetectedAndPicked,
          (error) => {
            this.props.onSearchingCurrentLocation(false);
            if (this.unmounted) return;
            this.setState({
              detecting: false,
              currentLocationError: error
            });
        });
    });
  }

  onLocationDetectedAndPicked = (location) => {
    if (this.unmounted) return;
    this.setState({
      detecting: false,
      location
    });
    if( location ) {
      this.props.onPickedCurrentLocation(location);
    }
  };

  onResults = (results, error) => {
    const { shouldAutoPickLoc } = this.state;

    if( shouldAutoPickLoc && results.length) {
      this.onPicked(results[0]);
    }

    this.setState({
      fetched: results,
      error,
      shouldAutoPickLoc: false
    });
  }
}

export const GeoSuggestInput = withTranslation()(GeoSuggestInputComponent);
