import { combineLatest} from 'rxjs';
import { map } from 'rxjs/operators';
import { entityDecoder } from '../../bastetjs/utils/entityDecoder';
import { Configure, FetchApi, eventBus } from '../../anuket-http';
import { callApi } from './helpers/HttpHelper';

function mapEvent(event) {
  const eventDate = new Date(event.eventDate).getTime();

  return Object.assign({}, event, {
    eventLocationCity: event.city,
    eventLocationCountry: event.countryID3,
    eventLocationState: event.stateProvAbbrev,
    athlinksMasterId: event.masterID,
    eventStart: eventDate,
    eventEnd: eventDate,
  });
}

function mapFriend(friend) {
  return Object.assign({}, friend, {
    racerId: friend.racerID,
    stateProv: friend.stateProvName
  });
}

export function getAthlete({racerId}) {
  const host = Configure.getValue('ATHLINKS_API_HOST');
  return FetchApi.memoizeFetch(10000)(`${host}/Athletes/Api/${racerId}`);
}

export function getSummary({racerId}) {
  const host = Configure.getValue('ATHLINKS_API_HOST');
  const streamId = `AthleteNoble.getSummary-${racerId}`;
  const stream = eventBus.getTopic(streamId);

  FetchApi
    .memoizeFetch(10000)(`${host}/Athletes/Api/${racerId}/Summary`)
    .subscribe((_) => eventBus.publish(streamId, _), (err) => {
      eventBus.publish(streamId, {failed: true, error: err});
    });

  return stream;
}

export function getProfile({racerId}) {
  const athlete = getAthlete({racerId});
  const summary = getSummary({racerId});

  return combineLatest(athlete, summary).pipe(map((both) => {
    const athleteRes = (key, def) => both[0].Result ? both[0].Result[key] : def;
    const picRes = (key, def) => both[0].Result ? both[0].Result.LogoUrls[key] : def;
    const summaryRes = (key, def) => both[1].Result ? both[1].Result.resultsSummary[key] : def;

    return entityDecoder.decode({
      displayName: athleteRes('DisplayName', ''),
      gender: athleteRes('Gender', ''),
      age: athleteRes('Age', ''),
      city: athleteRes('City', ''),
      state: athleteRes('StateProvName', ''),
      country: athleteRes('CountryID3', ''),
      profilePic: {
        hasImage: picRes('HasImage', false),
        thumb: picRes('Thumb', null),
        small: picRes('Small', null),
        medium: picRes('Medium', null),
        large: picRes('Large', null),
        xl: picRes('Xl', null)
      },
      milesRaced: summaryRes('MilesRaced', 0),
      totalRaces: summaryRes('TotalRaces', 0),
      isLoading: false
    });
  }));
}

export function addFriend({ racerId }) {
  return callApi(`Links/Api/Follow/${racerId}`, 'post');
}

export function removeFriend({ racerId }) {
  return callApi(`Links/Api/Unfollow/${racerId}`, 'delete');
}

export function getFriends() {
  return callApi('Links/Api/Friends');
}

export function claimResult({entryId, isMergeRequest}) {
  const path = 'Claim/Api/Entries';
  const body = `{"entryIds": [${entryId}], "isMergeRequest": ${isMergeRequest}}`;
  return callApi(path, 'post', {}, body);
}

export function upcomingEvents({racerId}) {
  return callApi(`Events/API/Upcoming/${racerId}`).pipe(map((res) => {
    return {
      results: res.result.map((event) => mapEvent(event))
    };
  }));
}

export function upcomingFriendsEvents({racerId}) {
  return callApi(`Events/API/Upcoming/${racerId}/Friends`).pipe(map((res) => {
    return { results: res.result.map((event) => mapEvent(event)) };
  }));
}

export function upcomingFriendsForEvent({eventId, racerId}) {
  return callApi(`Events/API/${eventId}/Friends/${racerId}`).pipe(map((res) => {
    return {
      results: Object.values(res.result.reduce((acc, friend) => {
          acc[friend.racerID] = mapFriend(friend);
          return acc;
        }, {}))
    };
  }));
}