import { Configure } from './Configure';
import Memoizer from './Memoizer';
import { from, throwError } from 'rxjs';
import { timeout } from 'rxjs/operators'

/* D.J. Funky Fetch (Fetch Wrapper)
 * - - - - - - - - - - - - - - - - -
 * @param {string} uri  Required fetch() URI.
 * @param {object|null} data  Optional fetch() data.
 * @param {bool|null} wantJSON  If given null, defaults to true.
 * @param {object|null} timeoutOpts  Optional arguments for timing out the
 *                                   fetch() Observable, of the form {
 *                                     dueTime:  <int>,      // milliseconds
 *                                     stream: <Observable>, // other stream
 *                                   }
 * @return {observable}
 */
 function _doFetch(uri, data, wantJSON, timeoutOpts) {

   const fetchStream = getFetchStream(uri, data, wantJSON, timeoutOpts, false);

   return fetchStream;
 }

function _doRawFetch(uri, data, wantJSON, timeoutOpts) {

    const fetchStream = getFetchStream(uri, data, wantJSON, timeoutOpts, true);

    return fetchStream;
}

const getFetchStream = (uri, data, wantJSON, timeoutOpts, isRaw) => {
  wantJSON = wantJSON !== false;

  if (typeof fetch !== 'function') {
    return throwError(new Error('Fetch is not defined.'));
  }

  const fetchStream = from(
    // Do a then here, that way resp.json() won't get called multiple times.
    fetch(uri, data).then((resp) => {
      if (resp.status >= 500) {
        if (Configure.getConfigs().redirectUrl) {
          window.location.replace(Configure.getConfigs().redirectUrl)
        }
        const error = new Error(uri + ' -> ' + resp.status + ': ' + resp.statusText);
        error.response = resp;
        throw error;
      }

      if (isRaw) {
        if (wantJSON) {
          return {'meta': resp, 'body': resp.json()};
        } else {
          return {'meta': resp, 'body': resp.text()};
        }
      }else {
        if (wantJSON) {
          return resp.json();
        }
        else if (resp.text && typeof resp.text === 'function') {
          return resp.text();
        }
        else {
          return resp;
        }
      }
    })
  );

  const due = (timeoutOpts || {}).dueTime;
  return due
   ? fetchStream.pipe(timeout(due))
   : fetchStream;
}

export const doFetch = _doFetch
export const doRawFetch = _doRawFetch
export const memoizeFetch = (ttl) => (uri, data, wantJSON, timeoutOpts) => {
  return Memoizer.memoize((uri, data, wantJSON, timeoutOpts) => {
    return _doFetch(uri, data, wantJSON, timeoutOpts);
  }, ttl)(uri, data, wantJSON, timeoutOpts);
}
export const memoizeRawFetch = (ttl) => (uri, data, wantJSON, timeoutOpts) => {
  return Memoizer.memoize((uri, data, wantJSON, timeoutOpts) => {
    return _doRawFetch(uri, data, wantJSON, timeoutOpts);
  }, ttl)(uri, data, wantJSON, timeoutOpts);
}

export const FetchApi = { doFetch, doRawFetch, memoizeFetch, memoizeRawFetch }
export default FetchApi