import React  from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';

/**
 * Get active mode
 * @param {boolean} activeMode
 * @param {string} mode
 * @return {*}
 * @constructor
 */
const SwitchButton = ({ activeMode, onClick, mode, t }) => (
    <div onClick={ () => {
            onClick( mode === 'light' );
        }}
        style={styles.switchButton(mode, activeMode === mode )}>
        { mode === 'dark' ? t('Dark Mode') : t('Light Mode') }
    </div>
);

/**
 * Get active mode
 * @param {boolean} isLightMode
 * @return {*}
 * @constructor
 */
const getActiveMode = (isLightMode) => isLightMode ? 'light' : 'dark';

/**
 * Kiosk theme switcher
 * @param {boolean} isLightMode
 * @return {*}
 * @constructor
 */
export const KioskThemeSwitcher = ({ isLightMode, onSwitchMode, t }) => (
    <div style={styles.colorSwitchContainer}>
       <SwitchButton activeMode={getActiveMode(isLightMode)} onClick={onSwitchMode} mode="dark" t={t}/>
       <SwitchButton activeMode={getActiveMode(isLightMode)} onClick={onSwitchMode} mode="light" t={t}/>
    </div>
);

KioskThemeSwitcher.propTypes = {
  isLightMode: PropTypes.bool.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  t: PropTypes.func
};
