import React from 'react';
import { styles } from './styles';

export const Terms = (props) => {
  const { isContentOnly = false } = props;
  const containerStyle = isContentOnly ? styles.contentOnly : styles.fullPage;

  return (
    <div className="row" style={containerStyle}>
      <div>
        <h1>Terms of Service</h1>
        <h5>{'Last Updated: '}
          <time>November 9, 2016</time>
        </h5>
        <p>
          Welcome to the family of websites, mobile applications, or anywhere
          else these Terms of Service are posted (collectively,
          the &ldquo; Athlinks Site(s)&rdquo;) where services are made available
          through the Site (&ldquo;Services&rdquo;) owned
          or operated by ChronoTrack Systems Corp. or its corporate parent(s),
          subsidiaries and affiliates (&ldquo;Athlinks&rdquo;
          &ldquo;we&rdquo; &ldquo;us&rdquo; or &ldquo;our&rdquo;). The following
          terms and conditions, together with any other
          terms, conditions, rules, or guidelines posted within the Site or by
          use of the Services incorporated by reference (collectively,
          these &ldquo;Terms of Service&rdquo;), govern your access to and use
          of, including any content, functionality and Services
          offered on or through the Athlinks Sites, whether as a guest or
          logged-in user. Athlinks is an event registration and
          participant services platform whereby users who are event organizers,
          timers, and owners (&ldquo;Event Director(s)&rdquo;)
          can create event landing pages, facilitate registration, payment,
          solicit donations, host results and photos and other
          technology services for events in order to provide such services to
          those who use the site and participate in such events
          (&ldquo;Athlete(s)&rdquo;).
        </p>
        <p>
          Athlinks and any subsidiaries and affiliates are collectively referred
          to herein as &ldquo;we,&rdquo; &ldquo;us&rdquo; or
          &ldquo;our&rdquo; and &ldquo;you,&rdquo; &ldquo;or &ldquo;your,&rdquo;
          refers to collectively to all users of the Athlinks
          Sites, including but not limited to Athletes and Event Directors.
          Please note that special terms may apply to certain
          Services offered on the Site, including premium content that requires
          a membership, rules for particular promotions,
          or other features or activities and such terms will be posted on the
          applicable Athlinks Sites. Any such terms are in
          addition to these Terms of Service, and in the event of any conflict,
          prevail over these Terms of Service.
        </p>
        <h2>Acceptance of the Terms of Service</h2>
        <p>
          <strong>By using the Athlinks Sites or by clicking to accept or agree
            when this option is made available to you, you accept and agree to
            be bound and abide by these Terms of Service and our Privacy Policy,
            incorporated by reference.</strong> The Athlinks Sites are offered
          and available to users who are 13 years of age or older. If you do not
          agree to these
          Terms of Service or the Privacy Policy or are not 13 years of age or
          older, you must not access or use the Athlinks Sites.
        </p>
        <h2>Accessing the Athlinks Sites, Registration and Account Security</h2>
        <p>
          We reserve the right to withdraw or amend the Athlinks Sites, and any
          service or material we provide on the Athlinks Sites,
          in our sole discretion without notice. We will not be liable if for
          any reason all or any part of the Athlinks Sites
          is unavailable at any time or for any period. From time to time, we
          may restrict access to some parts of the Athlinks
          Sites, or the entire Athlinks Sites, to users, including registered
          users.
        </p>
        <p>
          In order to register for an event or use portions of the Athlinks
          Sites, you may be required to create an Athlinks Account,
          including providing a username, password or other information required
          by Athlinks or an Event Director. You are responsible
          for maintaining the confidentiality of your Athlinks Account
          information and for all activity associated with your Athlinks
          Account. It is a condition of your use of the Athlinks Sites that all
          the information you provide on the Athlinks Sites
          is correct, current and complete. You agree that all information you
          provide through the Athlinks Sites or otherwise,
          including but not limited to through the use of any interactive
          features on the Athlinks Sites, is governed by our Privacy
          Policy, and you consent to all actions we take with respect to your
          information consistent with our Privacy Policy. You
          acknowledge and consent to the transfer of such information to the
          United States or other countries for storing, processing
          or other use by Athlinks. You also acknowledge that your Athlinks
          Account is personal to you and agree not to provide
          any other person with access to this Athlinks Sites or portions of it
          using your user name, password or other security
          information. We are not responsible for any loss or damage caused by
          your failure to take reasonable security measures
          or disclosure of your username and password. You agree to notify us
          immediately of any unauthorized access to or use
          of your user name or password or any other breach of security by
          emailing support@athlinks.com. You should use particular
          caution when accessing your account from a public or shared computer
          so that others are not able to view or record your
          password or other personal information.
        </p>
        <p>
          We have the right to disable any user name, password or other
          identifier, whether chosen by you or provided by us, at any
          time in our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these
          Terms of Service.
        </p>
        <h2>License</h2>
        <p>
          Athlinks hereby grants you a non-exclusive, non-transferable,
          non-sublicensable, revocable right to access and use the
          Athlinks Sites and Services solely for the following purposes: (a)
          searching, registering or making donations for an
          event on the Athlinks Sites, (b) accessing, claiming results, or
          otherwise using functionality in your Athlinks Account,
          or (c) setting up event registration, event landing pages, or using
          the Athlinks Sites to promote, organize, operate,
          time, accept payment, donations, or using other functionality as an
          Event Director. Notwithstanding the foregoing, you
          shall not reproduce, modify, display, publicly perform, distribute or
          create derivative works of or circumvent any access
          controls to the Athlinks Sites, for any purpose not specifically
          permitted herein. You represent that your use of the
          Athlinks Sites complies and will continue to comply, with all
          applicable foreign, federal and state laws, rules and regulations.
          If you are an Event Director and collect information from a user
          facilitated through the Athlinks Sites, you must provide
          a link to an applicable privacy policy that discloses your collection,
          use and storage practices, including any disclosure
          to any third party and such policy must comply with all applicable
          data privacy laws and regulations.
        </p>
        <h2>Event Registrations, Purchases and Fees</h2>
        <p>
          Athlinks provides the capability for Event Directors to facilitate
          registration and payment for events, products or services,
          including donations or other fundraising, but Athlinks is not the
          organizer or owner of the events themselves. The Event
          Director is solely responsible for all aspects of the event including
          ensuring the accuracy and completeness of all information
          posted about the event and ensuring the event is conducting in a
          manner that meets all applicable laws, rules and regulations.
        </p>
        <p>
          If you wish to make a purchase or register for an event through the
          Athlinks Sites, you will be required to
          provide certain information, including credit card information, in
          order to complete the transaction. You agree to pay
          all charges and fees incurred by you, including any applicable taxes,
          processing or other fees. You represent and warrant
          that all information you provide will be accurate and you consent to
          Athlinks providing such information to Event Directors
          and other third parties in order to facilitate the transaction. Event
          Directors may charge a non-refundable processing
          or service fee, which is either passed along to the athlete or
          included in the registration price and paid by the Event
          Director out of the registration proceeds. The processing fees paid by
          Athletes are not always the same as those charged
          by Athlinks for use of the Athlinks registration platform. Athletes
          may also be charged additional fees including facility,
          use, service, fulfillment or other fees as set by the Event Director.
          Please note that certain fees may be charged by
          your bank or credit card company including fees for purchasing event
          registrations or products or services in foreign
          currencies or from foreign Event Directors. Athlinks recommends
          consulting with the appropriate financial institutions
          prior to consummating a transaction to understand all applicable fees,
          credit card charges and currency conversion rates
          (if applicable).
        </p>
        <p>
          Athlinks is not a party to and makes no representations or warranties
          with regard to any events, products or
          services sold by Event Directors or other third-parties through the
          Athlinks Sites. Athlinks will not be responsible
          for any costs or damages arising out of any interaction or transaction
          between the Event Director and the Athlete. Athlinks
          is not responsible for vetting any Event Directors or their events,
          products or services and assumes no liability for
          any actions, inactions, or other activities of Event Directors or
          their employees, affiliates, contractors or related
          parties. Event Directors may impose additional terms and conditions
          with respect to their events, products and services.
        </p>
        <h2>Refunds and Event Cancellation</h2>
        <p>
          Event Directors are responsible for posting the applicable
          cancellation, refund, return and exchange policies for each
          of their events, products or services sold through the Athlinks Sites.
          You understand and agree that Event Directors
          often reserve the right to cancel events for a variety of reasons
          including but to limited to poor weather conditions,
          acts or war, terrorism, riots or other reasons that would impact the
          safety or security of the participants and spectators.
          All event policies and rules are at the discretion of the Event
          Director and Athlinks is not responsible in any way for
          such policies or rules.
        </p>
        <p>
          If you need to request a refund or have any issues with respect to a
          purchase, please contact the Event Director for processing
          as the Athlinks Sites only facilitate the transaction between the
          Athlete and the Event Director. Athlinks shall have
          no liability to the Athlete or the Event Director for its actions or
          inactions in resolving or failing to resolve any
          disputes.
        </p>
        <h2>Event Video, Photography and Results</h2>
        <p>
          By participating in an event that uses the Athlinks Sites for display
          of photography, video or results (&ldquo;Athlete
          Images and Data&rdquo;), you hereby grant Athlinks a non-exclusive,
          transferable, sub-licensable, royalty-free, worldwide
          license to use your image and likeness in the Athlete Images and Data
          uploaded by Event Directors on or through the Athlinks
          Sites.
        </p>
        <p>
          If you are an Event Director or acting on behalf of an Event Director
          and you upload any Athlete Images and Data, you hereby
          grant Athlinks a non-exclusive, transferable, sub-licensable,
          royalty-free, worldwide license to use the Athlete Images
          and Data on the Athlinks Sites.
        </p>
        <h2>Prohibited Uses</h2>
        <p>
          You may use the Athlinks Sites only for lawful purposes and in
          accordance with these Terms of Service. You agree not to use
          the Athlinks Sites:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local or
            international law or regulation (including, without
            limitation, any laws regarding the export of data or software to and
            from the US or other countries).
          </li>
          <li>
            For the purpose of exploiting, harming or attempting to exploit or
            harm minors in any way by exposing them to inappropriate
            content, asking for personally identifiable information or
            otherwise.
          </li>
          <li>
            To send, knowingly receive, upload, download, use or re-use any
            material which does not comply with the content standards
            as set forth in these Terms of Service.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material without our prior written consent, including
            any &quot;junk mail&quot;, &quot;chain letter&quot; or &quot;
            spam&quot; or any other similar solicitation.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Athlinks Sites, or which,
            as determined by us, may harm Athlinks or users of the Athlinks
            Sites or expose them to liability.
          </li>
        </ul>
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use of the Athlinks Sites in any manner that could disable,
            overburden, damage, or impair the site or interfere with
            any other party's use of the Athlinks Sites, including their ability
            to engage in real time activities through the
            Athlinks Sites.
          </li>
          <li>
            Use any robot, spider or other automatic device, process or means to
            access the Athlinks Sites for any purpose, including
            monitoring or copying any of the material on the Athlinks Sites.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on the
            Athlinks Sites or for any other unauthorized purpose
            without our prior written consent.
          </li>
          <li>Use any device, software or routine that interferes with the
            proper working of the Athlinks Sites.
          </li>
          <li>Introduce any viruses, trojan horses, worms, logic bombs or other
            material which is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Athlinks Sites, the server
            on which the Athlinks Sites is stored, or any server, computer or
            database connected to the Athlinks Sites.
          </li>
          <li>Attack the Athlinks Sites via a denial-of-service attack or a
            distributed denial-of-service attack.
          </li>
          <li>Otherwise attempt to interfere with the proper working of the
            Athlinks Sites.
          </li>
        </ul>
        <h2>User Contributions</h2>
        <p>
          The Athlinks Sites may contain message boards, chat or message
          capabilities, personal or event pages or profiles, forums,
          and other interactive features (collectively, &quot;Interactive
          Services&quot;) that allow users to post, submit, publish,
          display or transmit to other users or other persons
          (hereinafter, &quot;post&quot;) content or materials (collectively,
          &quot;User Contributions&quot;) on or through the Athlinks Sites. All
          User Contributions must comply with the Content
          Standards set out in these Terms of Use.
        </p>
        <p>
          Any User Contributions you post to the site will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Athlinks Sites, you grant us and our affiliates
          and service providers, and each of their respective
          licensees, successors and assigns the right to use, reproduce, modify,
          perform, display, distribute and otherwise disclose
          to third parties any such material for any purpose. You understand
          that by posting, any messages, text, files, images,
          video, photos, sounds, or other materials on or through the Athlinks
          Sites (&quot;User Contributions&quot;), you are
          granting Athlinks, its subsidiaries, affiliates, assigns, agents, and
          licensees the irrevocable, perpetual, fully paid,
          non-exclusive worldwide right to use, reproduce, display, distribute,
          adapt, perform and prepare derivative works of
          the User Contributions.
        </p>
        <p>
          You represent and warrant that:
        </p>
        <ul>
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above
            to us and our affiliates and service providers, and each of their
            respective licensees, successors and assigns.
          </li>
          <li>All of your User Contributions do and will comply with these Terms
            of Use.
          </li>
        </ul>
        <p>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not
          Athlinks, have full responsibility for such content, including its
          legality, reliability, accuracy and appropriateness.
          We are not responsible, or liable to any third party, for the content
          or accuracy of any User Contributions posted by
          you or any other user of the Athlinks Sites.
        </p>
        <h2>Monitoring and Enforcement; Termination</h2>
        <p>We have the right to:</p>
        <ul>
          <li>Remove or refuse to post any User Contributions for any or no
            reason in our sole discretion.
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including
            if we believe that such User Contribution violates the Terms of Use,
            including the Content Standards, infringes any
            intellectual property right or other right of any person or entity,
            threatens the personal safety of users of the Athlinks
            Sites or the public or could create liability for Athlinks.
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party who claims that material posted by you violates
            their rights, including their intellectual property rights or their
            right to privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized
            use of the Athlinks Sites.
          </li>
          <li>
            Terminate or suspend your access to all or part of the Athlinks
            Sites for any or no reason, including without limitation,
            any violation of these Terms of Service.
          </li>
        </ul>
        <p>
          Without limiting the foregoing, we have the right to fully cooperate
          with any law enforcement authorities or court order
          requesting or directing us to disclose the identity or other
          information of anyone posting any materials on or through
          the Athlinks Sites. YOU WAIVE AND HOLD HARMLESS ATHLINKS AND ITS
          AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY
          CLAIMS RESULTING FROM ANY ACTION TAKEN BY ATHLINKS OR ANY OF THE
          FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS
          AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY SUCH
          PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          However, we do not review all material before it is posted on the
          Athlinks Sites, and cannot ensure prompt removal of objectionable
          material after it has been posted. Accordingly, we assume no liability
          for any accuracy, action or inaction regarding
          transmissions, communications or content provided by any user or third
          party. We have no liability or responsibility
          to anyone for performance or nonperformance of the activities
          described in this section.
        </p>
        <h2>Content Standards</h2>
        <p>
          These content standards apply to any and all User Contributions and
          use of Interactive Services. User Contributions must
          in their entirety comply with all applicable federal, state, local and
          international laws and regulations. Without limiting
          the foregoing, User Contributions must not:
        </p>
        <ul>
          <li>
            Contain any material which is defamatory, obscene, indecent,
            abusive, offensive, harassing, violent, hateful, inflammatory
            or otherwise objectionable.
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age.
          </li>
          <li>Infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could
            give rise to any civil or criminal liability under applicable laws
            or regulations or that otherwise may be in conflict
            with these Terms of Service and our Privacy Policy.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>Promote any illegal activity, or advocate, promote or assist any
            unlawful act.
          </li>
          <li>Cause annoyance, inconvenience or needless anxiety or be likely to
            upset, embarrass, alarm or annoy any other person.
          </li>
          <li>Impersonate any person, or misrepresent your identity or
            affiliation with any person or organization.
          </li>
          <li>Involve commercial activities or sales, such as contests,
            sweepstakes and other sales promotions, barter or advertising.
          </li>
          <li>Give the impression that they emanate from or are endorsed by us
            or any other person or entity, if this is not the case.
          </li>
        </ul>
        <h2>Copyright Infringement</h2>
        <p>
          Pursuant to the Digital Millennium Copyright Act (&quot;DMCA&quot;),
          Athlinks has designated an agent (&quot;Copyright
          Agent&quot;) to receive notices of claimed copyright infringement. If
          you (or an agent authorized to act on your behalf)
          believe in good faith that your work on the Athlinks Sites is used in
          a way that constitutes copyright infringement,
          please provide written notice to Athlinks' Copyright Agent by email to
          legal@athlinks.com or by mail to Athlinks, 1795
          Dogwood St, Suite 400, Louisville, CO, 80027. Attn: Copyright Agent
          with the following information: (a) a physical or
          electronic signature of a person authorized to act on the copyright
          owner's behalf; (b) a description of the copyrighted
          work claimed to have been infringed; (c) a description of where the
          copyrighted work is located on the Athlinks Sites,
          including if possible a URL; (d) your address, telephone number and
          email address; (e) a statement that you believe in
          good faith that use of the work is not authorized by the copyright
          owner, its agent or the law; and (f) a statement,
          under penalty of perjury, that the above information in your notice is
          accurate, and that you are the copyright owner
          or authorized to act on the copyright owner's behalf. In accordance
          with the DMCA, Athlinks may delete, remove or disable
          any infringing work, and may forward to the alleged infringer your
          written notice and its own notice that the work has
          been deleted, removed or disabled. Athlinks reserves the right to
          terminate members who have posted infringing work.
        </p>
        <h2>Intellectual Property Rights</h2>
        <p>
          The entire contents of the Athlinks Sites are copyrighted under the
          law as collective work and/or compilation. Athlinks
          holds and owns the copyright and all other proprietary and
          intellectual property rights and title in and to the collective
          work, including but not limited to the pages, graphics, design, text,
          sound recordings, images, materials and content
          of the Athlinks Sites, some of which may be trademarked. You agree
          that you will not copy, reproduce, alter, modify,
          decompile, reverse engineer, distribute, transfer, sell, transmit,
          translate, publish, perform, license, or create derivative
          works from, any of the contents of the Athlinks Sites, including any
          information, products or services, without Athlinks'
          express written consent. Subject to these and any other express
          restrictions with regard to specific material, you understand
          that you may download information concerning your Athlinks Account,
          event or other information as allowed by Athlinks
          applicable to my use of the Athlinks Sites. You further agree that
          Athlinks does not need to give me any further right
          to approve its uses of my User Contributions.
        </p>
        <p>
          The Athlinks name, logo and all related names, logos, product and
          service names, designs and slogans are trademarks of Athlinks
          or its affiliates or licensors. You must not use such marks without
          the prior written permission of Athlinks. All other
          names, logos, product and service names, designs and slogans on this
          Athlinks Sites are the trademarks of their respective
          owners.
        </p>
        <h2>Reliance on Information Posted</h2>
        <p>
          The information presented on or through the Athlinks Sites is made
          available solely for general information purposes. We
          do not warrant the accuracy, completeness or usefulness of this
          information. Any reliance you place on such information
          is strictly at your own risk. We disclaim all liability and
          responsibility arising from any reliance placed on such materials
          by you or any other visitor to the Athlinks Sites, or by anyone who
          may be informed of any of its contents. We may update
          the content on the Athlinks Sites from time to time, but its content
          is not necessarily complete or up-to-date. Any of
          the material on the Athlinks Sites may be out of date at any given
          time, and we are under no obligation to update such
          material.
        </p>
        <p>
          This Athlinks Sites include content provided by third parties,
          including materials provided by other users, bloggers and
          third-party licensors, syndicators, aggregators and/or reporting
          services. All statements and/or opinions expressed in
          these materials, and all articles and responses to questions and other
          content, other than the content provided by Athlinks,
          are solely the opinions and the responsibility of the person or entity
          providing those materials. These materials do
          not necessarily reflect the opinion of Athlinks. We are not
          responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <h2>Links from the Athlinks Sites</h2>
        <p>
          If the Athlinks Sites contain links to other sites and resources
          provided by third parties, these links are provided for
          your convenience only. This includes links contained in
          advertisements, including banner advertisements and sponsored
          links. We have no control over the contents of those sites or
          resources, and accept no responsibility for them or for
          any loss or damage that may arise from your use of them. If you decide
          to access any of the third party Athlinks Sites
          linked to from the Athlinks Sites, you do so entirely at your own risk
          and subject to the terms and conditions of use
          for such Athlinks Sites.
        </p>
        <h2>Disclaimer of Warranties</h2>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the
          Athlinks Sites will be free of viruses or other destructive code. You
          are responsible for implementing sufficient procedures
          and checkpoints to satisfy your particular requirements for anti-virus
          protection and accuracy of data input and output,
          and for maintaining a means external to our site for any
          reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
          LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT
          MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
          PROPRIETARY MATERIAL DUE TO YOUR USE OF THE ATHLINKS
          SITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE ATHLINKS SITES OR
          TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
          OR ON ANY ATHLINKS SITES LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE ATHLINKS SITES, THE CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE ATHLINKS SITES IS AT YOUR OWN
          RISK. THE ATHLINKS SITES, THE CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE ATHLINKS SITES ARE PROVIDED ON AN
          &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY
          WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          NEITHER THE ATHLINKS NOR ANY PERSON ASSOCIATED WITH ATHLINKS MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
          OF THE ATHLINKS SITES. WITHOUT LIMITING THE FOREGOING,
          NEITHER ATHLINKS NOR ANYONE ASSOCIATED WITH ATHLINKS REPRESENTS OR
          WARRANTS THAT THE ATHLINKS SITES, ITS CONTENT OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE ATHLINKS SITES WILL BE
          ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
          ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
          OR THAT THE ATHLINKS SITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE ATHLINKS SITES WILL OTHERWISE MEET YOUR NEEDS
          OR EXPECTATIONS.
        </p>
        <p>
          ATHLINKS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
          LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
          FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h2>Limitation on Liability</h2>
        <p>
          IN NO EVENT WILL ATHLINKS, ITS AFFILIATES OR THEIR LICENSORS, SERVICE
          PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS
          BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
          OF OR IN CONNECTION WITH YOUR PARTICIPATION IN
          OR SPECTATING AT ANY EVENT, THE USE, OR INABILITY TO USE, THE ATHLINKS
          SITES, ANY ATHLINKS SITES LINKED TO IT, ANY CONTENT
          ON THE ATHLINKS SITES OR SUCH OTHER ATHLINKS SITES OR ANY SERVICES OR
          PRODUCT OBTAINED THROUGH THE ATHLINKS SITES OR
          SUCH OTHER SITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT
          NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
          DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h2>Indemnification</h2>
        <p>
          You agree to defend, indemnify and hold harmless Athlinks, its
          affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and
          against any claims, liabilities, damages, judgments, awards, losses,
          costs, expenses or fees (including reasonable attorneys'
          fees) arising out of or relating to your violation of these Terms of
          Service or your use of the Athlinks Sites, including,
          but not limited to, your User Contributions, any use of the Athlinks
          Sites' content, services and products other than
          as expressly authorized in these Terms of Service or your use of any
          information obtained from the Athlinks Sites.
        </p>
        <h2>Governing Law and Jurisdiction</h2>
        <p>
          All matters relating to the Athlinks Sites and these Terms of Service
          and any dispute or claim arising therefrom or related
          thereto (in each case, including non-contractual disputes or claims),
          shall be governed by and construed in accordance
          with the internal laws of the State of Minnesota without giving effect
          to any choice or conflict of law provision or
          rule (whether of the State of Minnesota or any other jurisdiction).
        </p>
        <p>
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Service or the Athlinks Sites shall be
          instituted exclusively in the federal courts of the United States or
          the courts of the State of Minnesota and County
          of Hennepin although we retain the right to bring any suit, action or
          proceeding against you for breach of these Terms
          of Service in your country of residence or any other relevant country.
          You waive any and all objections to the exercise
          of jurisdiction over you by such courts and to venue in such courts.
        </p>
        <h2>Limitation on Time to File Claims</h2>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE ATHLINKS SITES MUST BE
          COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
          OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY
          BARRED.
        </p>
        <h2>Waiver and Severability</h2>
        <p>
          No waiver by Athlinks of any term or condition set forth in these
          Terms of Service shall be deemed a further or continuing
          waiver of such term or condition or a waiver of any other term or
          condition, and any failure of Athlinks to assert a
          right or provision under these Terms of Service shall not constitute a
          waiver of such right or provision.
        </p>
        <p>
          If any provision of these Terms of Service is held by a court or other
          tribunal of competent jurisdiction to be invalid,
          illegal or unenforceable for any reason, such provision shall be
          eliminated or limited to the minimum extent such that
          the remaining provisions of the Terms of Service will continue in full
          force and effect.
        </p>
        <h2>Entire Agreement</h2>
        <p>
          These Terms of Service, our Privacy Policy, API Terms of Use and any
          other posted terms or policies constitute the sole and
          entire agreement between you and Athlinks with respect to the Athlinks
          Sites and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both
          written and oral, with respect to the Athlinks Sites.
        </p>
        <h2>Changes to Terms of Service</h2>
        <p>
          We may revise and update these Terms of Service from time to time in
          our sole discretion. All changes are effective immediately
          when we post them, and apply to all access to and use of the Athlinks
          Sites thereafter. Your continued use of the Athlinks
          Sites following the posting of revised Terms of Use means that you
          accept and agree to the changes. You should reference
          this page each time you access the Athlinks Sites so you are aware of
          any changes, as they are binding on you.
        </p>
        <h2>Your Comments and Concerns</h2>
        <p>
          All other feedback, comments, requests for technical support and other
          communications relating to the Athlinks Sites should
          be directed to support@athlinks.com.
        </p>
        <p>Thank you for visiting the Athlinks Sites.</p>
        <p>&copy; 2019 Athlinks Inc. All rights reserved.</p>
      </div>
    </div>
  );
};
