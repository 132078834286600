import React from 'react';
import { styles } from './styles';

export const About = () => {
  return (
    <div className="row p-0">
      <div className="col-12" style={styles.fullPage}>
        <h2 id='about-title'>Athlinks is the best way to track all of your race results in one
          place.</h2>

        <h3>Results Database</h3>
        <p>Each year, over 10 million athletes in the U.S. cross a finish line
          at an endurance event. For most of us, it's not about the medals, or
          the prize money, or the roaring crowds. It's about results. It's about
          getting better each race - going faster, going farther, beating a
          rival, or lifting up a loved one. It’s about the hours, minutes, and
          seconds scribbled on the back of a race bib or on a row in a
          spreadsheet.</p>
        <p>We set out to make capturing those rewards as simple as typing your
          name into a search box.</p>
        <p>Athlinks is the largest results database for competitive endurance
          athletes in the world. We pull in times for running races, triathlon,
          swimming, cycling, mountain biking ... pretty much any timed endurance
          sport that you can think of - if we can find the results, we will add
          them to the database and link them to our members' accounts.</p>

        <h3>Athlete Community</h3>
        <p>"Compete. Connect." That's our motto. Use fitness to not only push
          yourself, but to connect with your fellow athletes. Whether in your
          hometown, or half the way around the world - Athlinks members connect
          in more meaningful ways to those around them on the site. Using the
          commonality of racing – you can seek out local training partners, gear
          heads, or mentors who have achieved things in racing that you endeavor
          to accomplish.</p>
        <p>We start you out by showing you your “Rivals” – a list of everyone
          that you have raced against, along with a race-by-race record of wins
          and losses as well as time differentials.</p>

        <h3>How We Do It</h3>
        <p>Athlinks relies on the support of the race director and athlete
          communities to populate the database. As results are posted online,
          the Race Director sends the results files or links to our team, who
          then uploads the information to the database. We have a dedicated
          staff of results hounds.</p>
        <p>As more and more athletes sign up with Athlinks, we are able to use
          the support of our members to track down upcoming races to add to the
          results queue - which takes pressure off of the race directors.</p>
      </div>
    </div>
  );
};
