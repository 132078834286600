import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';

export class Button extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        color: PropTypes.string,
        style: PropTypes.object,
        /**Style props will be passed down to the base component:
         * color: text color
         * backgroundColor
         * border: button border, e.g. '2px solid #16A9E1',
         * width, height, borderRadius: of button
         * padding: of text (to pad the button itself, wrap in a div)
         * fontSize, letterSpacing, lineHeight, textTransform, ...
         **/
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        disabled: false,
        style: {},
        width: '100%'
    };

    render() {
        const onClick = () => window.open(this.props.link, '_blank');

        const colors = {
            orange: styles.orange,
            white: styles.white,
            actualWhite: styles.actualWhite,
            gray: styles.gray
        };
        const color = colors[this.props.color] || (this.props.disabled ? 'gray' : styles.button);

        return (
            <button type="button"
                    className="button"
                    onClick={this.props.disabled ? () => true : (this.props.handleClick || onClick)}
                    style={Object.assign({}, color, {width: this.props.width}, this.props.style)}>
                {this.props.text}
            </button>
        );
    }
}
