import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { colors } from '../../shared/styles';

export const GDPR = () => {

  const Th = ({children}) =>
    <th style={{border: `1px solid ${colors.black}`}}>
      {children}
    </th>;

  const Td = ({children}) =>
    <td style={{border: `1px solid ${colors.black}`, padding: 5, width: '33%'}}>
      {children}
    </td>;

  const Bp = ({children}) =>
    <p style={{fontWeight: 'bold'}}>
      {children}
    </p>;

  return (
    <div className="row p-0">
      <div className="col-12" style={styles.fullPage}>
        <h2 id='about-title'>Athlinks and the GDPR</h2>
        <p>
          On May 25th, 2018 the General Data Protection Regulation (GDPR)
          becomes effective. The GDPR is a new European law implemented to
          enhance the protection of EU citizen’s personal data by increasing
          the obligations of companies who process data.
          It applies not only to businesses based in the EU, but also to any
          business that controls or processes personal data of EU citizens.
        </p>
        <p>
          Athlinks is working diligently to ensure our business practices
          successfully comply with the GDPR. Our efforts include helping
          our partners and customers understand how the GDPR will affect
          data collection, information processing, and our relationships.
          This site provides an overview of the steps Athlinks has taken
          towards GDPR compliance full data transparency.
        </p>


        <h3>Roadmap</h3>
        <p>
          This chart explains features and new processes that Athlinks is
          incorporating into its products to respond to GDPR requirements.
        </p>
        <table style={{borderCollapse: 'collapse'}}>
          <thead>
            <tr style={{border: `2px solid ${colors.black}`}}>
              <Th>Requirement</Th>
              <Th>Feature</Th>
              <Th>Status*</Th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td>Lawful Basis of Processing</Td>
              <Td>Data Processing Agreement</Td>
              <Td>Completed</Td>
            </tr>
            <tr>
              <Td>Consent</Td>
              <Td>Opt-in will be default option on Athlinks.</Td>
              <Td>Completed</Td>
            </tr>
            <tr>
              <Td>Withdrawal of Consent</Td>
              <Td>Opt-out will be available in user profile.</Td>
              <Td>Completed</Td>
            </tr>
            <tr>
              <Td>Cookies</Td>
              <Td>Cookie consent notices will be provided on Athlinks website.</Td>
              <Td>Completed</Td>
            </tr>
            <tr>
              <Td>Deletion</Td>
              <Td>Process outlined in Privacy Policy.</Td>
              <Td>Available online.</Td>
            </tr>
            <tr>
              <Td>Access/Portability</Td>
              <Td>Privacy Policy</Td>
              <Td>Available online.</Td>
            </tr>
            <tr>
              <Td>Modification</Td>
              <Td>Process outlined in Privacy Policy.</Td>
              <Td>Available online.</Td>
            </tr>
            <tr>
              <Td>Security Measures</Td>
              <Td>Athlinks will continue to adhere to Life Time’s Information Security and Data Protection Policies and procedures</Td>
              <Td>Available upon request.</Td>
            </tr>
          </tbody>
        </table>
        <p>
          *Work in progress features will be available by the May 25th, 2018,
          GDPR effective date.
        </p>

        <h3>FAQ.</h3>
        <ol>
          <li>
            <Bp>
              When will Athlinks be updating its legal documentation
              (Privacy Policy and Data Processing Agreement)?
            </Bp>
            <p>
              All have been updated and are available at the following links:
              <br/>
              <Link to='/home/privacy'>Privacy Policy</Link>
              <br/>
              <a target='_blank' rel="noopener noreferrer" href='https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=32747410-3ea4-4f93-a857-88ea30e2e36b'>Data Processing Agreement</a>
            </p>
          </li>
          <li>
            <Bp>
              Does the GDPR apply to me?
            </Bp>
            <p>
              While the current EU legislation (the 1995 EU Data Protection
              Directive) governs entities within the EU, the territorial
              scope of the GDPR is far wider in that it will also apply
              to non-EU businesses who a) market their products to people in
              the EU or who b) monitor the behavior of people in the EU.
              In other words, even if you’re based outside of the EU but you
              control or process the data of EU citizens, the GDPR will apply
              to you.
            </p>
          </li>
          <li>
            <Bp>
              Does the GDPR require personal data to be stored in the EU?
              What does Athlinks do to ensure lawful data transfers from the EU?
            </Bp>
            <p>
              No. There is no obligation under the GDPR for data to be
              stored in the EU and the rules regarding transfer of personal
              data outside the EU remain largely unchanged. The GDPR permits
              transfers of personal data outside of the EU subject to certain
              conditions. The EU-U.S. Privacy Shield continues to be one
              valid way to ensure adequate safeguards are in place for personal
              data transfer from the EU to the U.S. The EU model clauses also
              remain a valid mechanism to lawfully transfer personal data.
              Athlinks offers a Data Processing Agreement that incorporates
              the model clauses to our EU/EEA customers. We are also Privacy
              Shield certified.
            </p>
          </li>
          <li>
            <Bp>
              I have contacts in my database that I don't have specific opt-in
              records for. Do I need to delete them by May 25th?
            </Bp>
            <p>
              At the end of the day, that's up to you and your legal team.
            </p>
            <p>
              A quick note to think about, though: just because you don't
              have record of opt-in doesn't mean you don't have lawful basis to process a contact record. Lawful basis comes in multiple forms:
            </p>
            <ul>
              <li>
                Necessary for performance of a contract. Example: if Ana buys
                products from you, you can send her emails related to
                onboarding, billing, etc.
              </li>
              <li>
                Legitimate interest. In the above example, you could email Ana
                about related products or services.
              </li>
              <li>
                Consent (with notice). Freely given, affirmative, opt-in consent
                accompanied with transparent explanation of your purpose for
                acquiring/using the data.
              </li>
            </ul>
          </li>
          <li>
            <Bp>
              What are some resources I can visit to learn more about the GDPR?
            </Bp>
            <ul>
              <li>
                Guidance from the German Federal Commissioner for Data
                Protections' on the GDPR <a href="https://www.bfdi.bund.de/SharedDocs/Publikationen/Faltblaetter/Datenschutzgrundverordnung.html;jsessionid=013DE4A21C3128B4C82013D0AE60AC19.1_cid319?nn=5217040" target="_blank" rel="noopener noreferrer">here</a>
              </li>
              <li>
                EU Data Protection Supervisor <a href="https://edps.europa.eu/" target="_blank" rel="noopener noreferrer">here</a>
              </li>
              <li>
                Find your Supervisory Authority <a href="http://gdprandyou.ie/resources/" target="_blank" rel="noopener noreferrer">here</a>
              </li>
              <li>
                Full text of the GDPR <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN" target="_blank" rel="noopener noreferrer">here</a>
              </li>
              <li>
                Full text of the GDPR in German <a href="https://dsgvo-gesetz.de/" target="_blank" rel="noopener noreferrer">here</a>
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};
