import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import {
  streamFail,
  streamHook
} from './Streams';

const curatedCategoryStreamId = 'CuratedCategory';

export function curatedCategory(categoryName) {
  const url = configs.ATHLINKS_API_HOST + `/Events/Race/Api/Curated/${categoryName}`;

  const streamId = curatedCategoryStreamId+'-'+categoryName;

  streamFail(
    streamId,
    FetchApi
      .memoizeFetch(10000)(url, {}),
    (({ErrorMessage, Result}) => ErrorMessage ? {error: ErrorMessage} : {result: Result})
  );

  return streamHook(streamId);
}
