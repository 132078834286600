import { colors, fonts, textStyles } from '../../shared/styles'

export const buttonsContainer = (isMobile) => isMobile ? {
  display: 'flex',
  left: 80,
  position: 'relative',
  marginTop: 10,
} : {}

export const styles = {
  betaBadge: {
		color: 'rgb(22, 169, 225)',
		padding: '5px 10px',
		borderRadius: 10,
		fontSize: 12,
		marginRight: 15,
		backgroundColor: 'white'
  },
  betaBar: {
		marginBottom: 20,
    padding: '10px 20px',
		backgroundColor: 'rgb(22, 169, 225)',
    // boxShadow: colors.cardShadow,
		borderRadius: 3,
		color: '#fff',
		fontWeight: 'bold'
  },
  bodyContainer: (isMobile) => ({
    marginTop: isMobile ? '35px' : '80px'
  }),
  boxShadow: {
    boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)'
  },
  button: (isMobile) => ({
    border: `1px solid ${colors.greyLine}`,
    borderRadius: '3px',
    color: colors.textColor,
    cursor: 'pointer',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0px',
    padding: '10px 25px 10px 25px',
    minWidth: isMobile ? '100%' : '200px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: '15px'
  }),
  profileButton: {
    border: `1px solid ${colors.greyLine}`,
    borderRadius: '3px',
    color: colors.textColor,
    cursor: 'pointer',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0px',
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    margin: 'auto'
  },
  buttonContainer: (isMobile) => ({
    display: 'flex',
    flexDirection: isMobile ? 'column-reverse' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: isMobile ? '0px' : '55px'
  }),
  athleteContainer: (isMobile) => ({
    marginBottom: 20,
    marginTop: isMobile ? '35px' : '0px',
    padding: '15px 20px 20px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)'
  }),
  athleteHeader: {
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '12px',
    color: colors.greySubText,
    letterSpacing: '0.4px',
    lineHeight: '15px'
  },
  chevronLeft: (isMobile) => ({
    fontSize: isMobile ? '35px' : '45px',
    color: colors.textColor,
    marginRight: isMobile ? '5px' : '10px',
    marginTop: '0px'
  }),
  claimOrFollowButton: (able, unable, isMobile, mouseOver) => ({
    backgroundColor: able && mouseOver ? colors.darkBlue1 : able ? colors.darkBlue4 : unable && mouseOver ? colors.lightGray7 : unable ? colors.white : 'none',
    border: `1px solid ${
      able ? colors.darkBlue4 : unable ? 'none' : colors.greyLine}`,
    borderRadius: '3px',
    color: able ? colors.white : unable ? colors.lightGrey7 : colors.lightGrey6,
    cursor:  able || unable ? 'pointer' : 'not-allowed',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.5px',
    padding: '10px 45px 10px 45px',
    minWidth: isMobile ? '100%' : '200px',
    textAlign: 'center',
    textTransform: 'uppercase',
//    marginTop: '15px'
  }),
  similarAthletesContainer: (isMobile) => ({
    textAlign: 'center',
    marginTop: isMobile ? '10px' : '35px'
  }),
  claimButtonContainer: {
    textAlign: 'center',
    marginTop: '35px'
  },
  followButtonContainer: (show, isMobile) => ({
    display: show ? '' : 'none',
    height: 36,
    marginTop: isMobile ? '10px' : '10px',
    textAlign: 'center'
  }),
  followButton: (isMobile) => ({
    backgroundColor: colors.darkBlue4,
    border: `1px solid ${colors.darkBlue4}`,
    borderRadius: '3px',
    color: colors.white,
    cursor: 'pointer',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.5px',
    padding: '10px 45px 10px 45px',
    minWidth: isMobile ? '100%' : '200px',
    textAlign: 'center',
    textTransform: 'uppercase',
//    marginTop: '15px'
  }),
  clearButton: (isMobile) => ({
    backgroundColor: 'none',
    border: `1px solid ${colors.greyLine}`,
    borderRadius: '3px',
    color: colors.darkBlue4,
    cursor: 'pointer',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.5px',
    padding: '10px 45px 10px 45px',
    minWidth: isMobile ? '100%' : '200px',
    textAlign: 'center',
    textTransform: 'uppercase',
//    marginTop: '15px'
  }),
  countdownContainer: {
    paddingLeft: '16px',
    paddingRight: '0px'
  },
  eventDate: {
    color: colors.grey,
    fontFamily: fonts.default.fontFamily,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    borderRight: '1px solid '+ colors.greyLine,
    paddingRight: '8px',
    marginRight: '8px',
    marginLeft: '15px'
  },
  eventLocation: {
    color: colors.grey,
    fontFamily: fonts.default.fontFamily,
    fontSize: '14px',
    whiteSpace: 'nowrap'
  },
  eventName: (isMobile) => ({
    color: colors.textColor,
    fontFamily: fonts.heavy.fontFamily,
    fontSize: isMobile ? '25px' : '31px',
    lineHeight: isMobile ? '26px' : '32px',
    letterSpacing: '-0.40px',
    marginTop: '7px',
    marginBottom: '3px'
  }),
  friendsContainer:{
    marginBottom: 20,
    padding: '15px 20px 20px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)'
  },
  innerInfoContainer: {},
  heroImgContainer: (imgUrl) => ({
    backgroundImage: `url(${imgUrl})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '150px',
    width: '100%'
  }),
  mainInfoContainer: {
    backgroundColor: colors.white,
    borderRadius: '4px',
    marginTop: '-100px',
    padding: '15px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)'
  },
  more: {
    position: 'absolute',
    right: '15px',
    top: '5px'
  },
  outerInfoContainer: (isMobile) => ({
    height: '100%',
    display: isMobile ? '' : 'flex',
    flexDirection: 'row',
    fontFamily: 'ProximaBold',
    verticalAlign: 'top',
    width: '100%'
  }),
  raceContentContainer: (isMobile) => ({
    marginTop: isMobile ? '28px' : '',
    padding: '16px 20px 16px 20px',
    backgroundColor: colors.white,
    borderRadius: '4px',
    boxShadow: '0 4px 14px 0 rgba(0,0,0,0.08)',
		minHeight: 750
  }),
  raceContentTitle: {
    color: colors.textColor,
    fontFamily: fonts.bold.fontFamily,
    fontSize: '20px'
  },
  raceSelected: {
//    box-sizing: border-box;
    height: '25px',
    boxShadow: `0 0 0 5px ${colors.lightBlue6}`,
    border: `1px solid ${colors.darkBlue4}`,
    borderRadius: '12.5px',
    color: colors.darkBlue4,
    backgroundColor: colors.lightBlue6,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.35px',
    marginRight: '10px',
    marginBottom: '10px',
    padding: '3px 20px 3px 20px',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  raceDeselected: {
    //    box-sizing: border-box;
    height: '25px',
    boxShadow: `0 0 0 5px ${colors.lightBlue6}`,
    border: `1px solid ${colors.greyLine}`,
    borderRadius: '12.5px',
    backgroundColor: colors.lightBlue6,
    color: colors.greySubText,
    cursor: 'pointer',
    display: 'inline-block',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.35px',
    marginRight: '10px',
    marginBottom: '10px',
    padding: '3px 20px 3px 20px',
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  raceSelectionContainer:(isMobile) => ({
    margin: isMobile ? '-28px 20px 0px 10px' : '-28px 0 10px'
  }),
  registerButton: (isMobile) => ({
    backgroundColor: colors.darkBlue4,
    border: `1px solid ${colors.darkBlue4}`,
    borderRadius: '3px',
    color: colors.white,
    cursor: 'pointer',
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    letterSpacing: '0.5px',
    padding: '10px 25px 10px 25px',
    minWidth: isMobile ? '100%' : '200px',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginTop: isMobile ? '20px' : ''
  }),
  topContainer: {
    filter: 'blur(20px)',
    height: '200px',
    transform: 'scale(1.1)'
  },
  zeroStateBlurb: {
    color: colors.textColor,
    fontFamily: fonts.default.fontFamily,
    fontSize: '16px',
    textAlign: 'center',
    marginTop: '20px'
  }
}

export const athleteCard = {
  athleteName:{
    ...textStyles.athleteRacePageName
  },
  contentContainer:{
    textAlign: 'center',
  },
  photoContainer:{
    margin: '-55px 0 32px',
    display: 'inline-block',
    float: ''
  },
  details:(isMobile) => ({
    marginBottom: '15px',
    ...textStyles.body1Grey,
  }),
  firstDetail: {
    display: 'inline-block',
    paddingRight: 8,
    borderRight: '1px solid '+ colors.greyLine,
    marginRight: 8,
  },
  secondDetail: {
    display: 'inline-block'
  },
  bibContainer: {
    display: 'inline-block',
    marginBottom: 23,
  },
  bibTitle: {
    marginBottom: 3,
    ...textStyles.sectionHead,
  },
  photoBox: {
    position: 'relative',
    borderRadius: '50%',
    boxShadow: `0 0 0 6px ${colors.white}, 0 0 0 14px ${colors.greyLine}, 0 0 0 20px ${colors.lightGrey}`,
  }
}
