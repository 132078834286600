import { makeStyles } from '@material-ui/core/styles';

import { fonts } from '../../shared/styles';

export const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    borderTop: `1px solid ${palette.grey[100]}`,
    margin: '0 12px',
    padding: '12px 0',

    [breakpoints.up('sm')]: {
      padding: '12px 0 36px',
      margin: '0 24px',
    }
  },
  interiorContainer: {
    textAlign: 'center'
  },
  title: {
    color: palette.grey[800],
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
}));



