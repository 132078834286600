import { textStyles, colors } from '../../shared/styles';

export const styles = {
  container: {
    alignItems: 'center',
    padding: '12px 0',
    borderBottom: '1px solid #F5F5F5',
  },
  time: {
    ...textStyles.body1Grey,
    fontSize: 14,
  },
  name: {
    ...textStyles.body1Grey,
    color: colors.textColor,
  },
};
