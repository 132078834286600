import { entityDecoder } from '../../bastetjs/utils/entityDecoder';
import { sprintf } from 'sprintf-js';
import { map } from 'rxjs/operators';
import { Configure, FetchApi } from '../../anuket-http';
import { retryHelper } from './helpers/RetryHelper';

/**
 * All numeric values have pixels as their units.
 * @return {object} Mapping from API "icon" value to
 *                  sprite coordinates, for standard.png.
 */
export function standardCssSpriteMap() {
  return {
    cloudy: { width: 44, height: 32, left: -1, top: -17 },
    'partly-cloudy-day': { width: 54, height: 49, left: -129, top: 0 },
    'partly-cloudy-night': { width: 45, height: 39, left: -193, top: -10 },
    'clear-day': { width: 48, height: 49, left: -63, top: -264 },
    'clear-night': { width: 24, height: 24, left: -395, top: -277 },
    rain: { width: 44, height: 45, left: -257, top: -17 },
    snow: { width: 44, height: 40, left: -129, top: -145 },
    sleet: { width: 44, height: 44, left: -384, top: -81 },
    wind: { width: 44, height: 28, left: -257, top: -211 },
    fog: { width: 45, height: 32, left: -65, top: -208 }
  };
}

/**
 * @param {object} msg A Forecast.io API response message.
 * @return {object} CSS sprite data for a display component.
 */
export function cssSpriteData(msg) {
  const standardMap = standardCssSpriteMap();

  return entityDecoder.decode({
    standard: standardMap[msg.icon]
      ? standardMap[msg.icon]
      : standardMap.cloudy
  });
}

/*
 * @param {array} coords Pair of [latitude, longitude].
 * @param {string} A date in format "YYYY-MM-DDTHH:MM:SS".
 * @param {string|null} Optional language, e.g., 'en-US' or 'de'.
 * @return {string} A complete weather API call url.
 */
export function weatherApiUrl({ coords, dateTime, lang }) {
  const url = Configure.getValue('WEATHER_API_HOST');

  return url
       + (url.lastIndexOf('/') === url.length - 1 ? '' : '/')
       + `${coords.join(',')}` + (dateTime ? `/${dateTime}` : '')
       + (lang ? '?lang=' + lang : '');
}

/**
 * @param {object} event an EventsNoble.x return object.
 * @return {bool} true if the necessary API inputs are present, or false.
 */
export function haveInputData(event) {
  return event.latitude !== null
      && event.longitude !== null
      && event.startEpoch !== null;
}

/**
 * @return {object} An empty object matching the structure of the API "daily" data object.
 */
export function emptyApiData() {
  return {
    'time': 0,
    'datetimeEpoch': 0,
    'conditions': '',
    'icon': 'cloudy',
    'tempmin': 0,
    'tempmax': 0,
    'tempminCelsius': 0,
    'tempmaxCelsius': 0,
    'feelslikemin': 0,
    'feelslikemax': 0
  };
}

/**
 * @param {object} apiData API "daily" object, or the empty object of the same structure.
 * @return {object} Full response object w/ celsius data + sprite data + isLoading=false.
 */
export function getWeatherObject(apiData) {
  let celsius = {};
  const fields = [
    'tempmin',
    'tempmax',
    'feelslikemin',
    'feelslikemax'
  ];

  fields.forEach((_) => {
    celsius[_ + 'Celsius'] = sprintf('%.2f', ((apiData[_] - 32) * 5/9));
  });

  return {
    weather: Object.assign({}, apiData, celsius, {
      cssSpriteData: cssSpriteData(apiData)
    }),
    isLoading: false
  };
}

export function getCallback({ coords, dateTime, lang, timeoutOpts }) {
  return FetchApi.memoizeFetch(10000)(weatherApiUrl({ coords, dateTime, lang }), undefined, {}, true, timeoutOpts)
    .pipe(
      map((msg) => {
        const dtEpoch = dateTime ? new Date(dateTime).getTime()/1000 : null;

        let hourlyData = {};
        if (dtEpoch && msg.days && msg.days.length && msg.days[0].hours && msg.days[0].hours.length > 0) {
          let delta = dtEpoch;
          msg.days[0].hours.forEach((_) => {
            if (Math.abs(dtEpoch - _.datetimeEpoch) < delta) {
              delta = Math.abs(dtEpoch - _.datetimeEpoch);
              hourlyData = _;
            }
          });
        }
        const dailyData = msg.days && msg.days.length > 0
          ? msg.days[0]
          : emptyApiData();

        return getWeatherObject(Object.assign({}, dailyData, hourlyData));
      })
    );
}

/**
 * @param {array} coords Pair of [latitude, longitude].
 * @param {string} dateTime A date in format "YYYY-MM-DDTHH:MM:SS".
 * @param {string|null} Optional language, e.g., 'en-US' or 'de'.
 * @param {object|null} timeoutOpts FetchApi.doFetch timeout options.
 * @return {Observable} Whose message will be the value of the first
 *                      array element from the "daily" key from the API call.
 */
export function get({ coords, dateTime, lang, timeoutOpts }) {
  return retryHelper('weather', getCallback, { coords, dateTime, lang, timeoutOpts });
}