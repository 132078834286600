import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RCSlider, {Range as RCRange} from 'rc-slider';
import { styles } from './styles';

export const NumberHandle = ({value, offset, index, unit = ''}) => {
  return (
    <div key={index} style={styles.slider.numberHandleContainer(offset)}>
      <span style={styles.slider.textStyles}>{value}{unit}</span>
    </div>
  );
};

export class Slider extends Component {

  static propTypes = {
    range: PropTypes.bool,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number,
    pushable: PropTypes.number,
    onChange: PropTypes.func,
    label: PropTypes.string,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
    unit: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number)
    ]),
  };

  static defaultProps = {
    range: false,
    onChange: () => {
    },
    step: 1,
    label: '',
    startLabel: '',
    endLabel: '',
    unit: ''
  };

  handle = ({index, ref, ...rest}) => {
    ref(this);
    const {unit} = this.props;
    return <NumberHandle key={index} {...rest} unit={unit} className={this.props.id}/>
  };

  static renderLabel(text) {
    if(!text) return null;
    return (<div style={styles.slider.renderLabel}>{text}</div>);
  }

  buildSliderClassName() {
    const {startLabel, endLabel} = this.props;
    if(startLabel && endLabel) return 'rc-both-labels';
    else if(startLabel) return 'rc-start-label';
    else if(endLabel) return 'rc-end-label';
    return '';
  }

  render() {
    const {
      range,
      min,
      max,
      step,
      pushable = range ? 1 : 0,
      onChange,
      label,
      defaultValue,
    } = this.props;
    const rangeProps = {
      min,
      max,
      defaultValue,
      handle: this.handle,
      step,
      pushable,
      onChange,
      className: this.buildSliderClassName()
    };
    const Class = !range ? RCSlider : RCRange;

    return (
      <div className="range-slider">
        {Slider.renderLabel(label)}
        <div style={styles.slider.labelContainer} id="location-rc-slider">
          {//this.renderEdgeLabel(startLabel)
          }
          <Class {...rangeProps}/>
          {//this.renderEdgeLabel(endLabel)
          }
        </div>
      </div>
    );
  }
}
