import React from 'react'
import { styles } from './styles'
import { configs } from '../../configs'
import { Link } from 'react-router-dom'

export const MHMD = (props) => {
  const { isContentOnly = false, showDownloadLink = true } = props
  const containerStyle = isContentOnly ? styles.contentOnly : styles.fullPage

  return (
    <div className='row' style={containerStyle}>
      <div>
        <h1 style={{ marginBottom: 5 }}>
          Washington State Consumer Health Data Privacy Policy
        </h1>
        {showDownloadLink && (
          <a
            style={styles.privacyDownload}
            download
            href={`${configs.cdnUrl}/policy/Athlinks_MHMD.pdf`}
          >
            Download
          </a>
        )}
        <h5>
          <time>Last Updated: March 29, 2024</time>
        </h5>
        <p>
          This Washington State Consumer Health Data Privacy Policy supplements
          the Athlinks and ChronoTrack (including the ChronoTrack Systems Corp.
          and its subsidaries) (“Athlinks”, “we”, “us”) Privacy Policy (“Privacy
          Policy”) and applies to the extent you are a resident of the state of
          Washington from whom we collect “consumer health data” (“CHD”), as
          defined by the Washington State My Health My Data Act (“MHMDA”), or to
          personal data we collect in Washington, to the extent such data is
          CHD.
        </p>
        <h2>Consumer Health Data We May Collect</h2>
        <p>
          As described in the{' '}
          {
            <Link to='/home/privacy'>
              &nbsp;Personal Data We Collect&nbsp;{' '}
            </Link>
          }{' '}
          section of our Privacy Policy, the data we collect varies and depends,
          among other things, on the context of your interactions with us, the
          products and features you use, and applicable law. Because MHMDA
          defines CHD very broadly, some of the categories of data we collect
          could also be considered CHD under that law even though we do not use
          it to identify health conditions or status and only collect such
          information if you choose to provide it to us. We only collect and use
          the below information to provide the goods and services you request
          and provide you with information and access to our clubs, membership
          programs, events, and activities.
        </p>
        <p>
          Below are examples of the categories of data we collect that could be
          deemed CHD:
        </p>
        <ul>
          <li>
            Information about your health-related conditions, symptoms, status,
            diagnoses, testing, or treatments. For example, we may collect such
            information when it might be associated with a product or service
            you purchase, when you sign up to use our services, or participate
            in races, programs, and activities. We may also collect information
            about health-related surgeries or procedures, or fitness and
            exercise activity data when you provide it to us, such as your
            athletic performance during a race, program, or activity, or your
            training history for events.
          </li>
          <li>
            Bodily functions, vital signs, symptoms or measurements related to
            your physical or mental health status (for example, your height and
            weight, or sleep, fitness, and exercise activity data).
          </li>
          <li>
            Location information used to track your races or search for
            activities. We collect location information in connection with your
            races and activities and not to associate it with any kind of
            attempt to acquire or receive health services.
          </li>
          <li>
            Information that could be related to reproductive or sexual health
            information such as your participation in certain classes, programs,
            and activities. For example, we may collect information related to
            your pregnancy status if you provide that information to us when you
            register for a race, event or activity
          </li>
          <li>
            Information that could identify your attempt to seek health care
            services or information, including services that allow you to
            assess, measure, improve, or learn about your or another person’s
            health. For example, we collect your search queries on this website
            (“Website”) and our mobile applications (“App”), which may include
            queries concerning nutrition, wellness, fitness, or other
            health-related products or services.
          </li>
        </ul>
        <p>
          We collect other non-health information as described in our Privacy
          Policy in order to provide our Website and the App (collectively, the
          “Services”) or operate our business such as information about products
          or services you have purchased or signed up for, images, or video
          recordings which we do not process to associate or identify you with
          information that could be deemed CHD.
        </p>
        <h2>Sources of Consumer Health Data</h2>
        <p>
          To the extent any of the information we collect constitutes CHD, we
          collect personal data (which may include CHD as described above)
          directly from you, from your interactions with our site and
          applications, products and services, and from third parties. For
          example, if you choose to connect and integrate our products and
          services with third-party applications and services so that we can
          provide you with products and services that you request, those
          third-party applications may disclose personal data (which may include
          CHD as described above) to us.
        </p>
        <h2>Why We Collect and Use Consumer Health Data</h2>
        <p>
          To the extent we collect and use CHD, we do so for the purposes
          described in the
          {
            <Link to='/home/privacy'>
              &nbsp;How We Use the Personal Data We Collect&nbsp;{' '}
            </Link>
          }{' '}
          section of our Privacy Policy. More specifically, we collect and use
          information that could be considered CHD:
        </p>
        <ul>
          <li>
            As reasonably necessary to provide you with the products or services
            you have requested or authorized. This may include delivering and
            operating the products and services and their features, responding
            to your communications, personalization of certain product or
            service features, ensuring the secure and reliable operation of the
            products and the systems that support them, troubleshooting and
            improving the products, and other essential business operations that
            support the provision of the products or services (such as analyzing
            our performance, meeting our legal obligations, developing our
            workforce, and conducting research and development).
          </li>
          <li>
            For any purpose for which you consent or direct us to collect or use
            it.
          </li>
        </ul>
        <p>
          We may use CHD for other purposes for which we will give you choices
          and/or obtain your consent as required by law. See the{' '}
          {
            <Link to='/home/privacy'>
              &nbsp;Choices You Have Regarding Your Personal Data&nbsp;{' '}
            </Link>
          }
          section of the Privacy Policy and the How to Exercise Your MHMDA
          Rights section below for more details on the rights and choices you
          may have.
        </p>
        <h2>Our Disclosure of Consumer Health Data</h2>
        <p>
          We may disclose each of the categories of data that could be
          considered CHD described above for the purposes described below:
        </p>
        <ul>
          <li>
            To the extent necessary to provide a product or service that you
            have requested or as reasonably necessary to complete any
            transaction or provide any product or service you have requested or
            authorized, as described above.
          </li>
          <li>
            If we are involved in a merger, asset sale, financing, corporate
            divesture, reorganization, or acquisition of all or some portion of
            our business to another company or if we undergo liquidation or
            bankruptcy proceedings, we may disclose your information in
            connection with such transaction or proceeding before and or after
            the transaction closes or the proceedings are completed.
          </li>
          <li>
            For any purpose for which you consent or direct us to disclose it.
          </li>
        </ul>
        <p>
          If you make a purchase or payment for an activity registration,
          product, or service, we will disclose information about the
          transaction as necessary to process the payment, including protection
          against fraud. And we may disclose data when we believe that doing so
          is necessary to comply with applicable law or respond to valid legal
          process.
        </p>
        <h2>Third Parties To Whom We Disclose Consumer Health Data</h2>
        <p>
          As necessary for the purposes described above to provide the products
          and services that you request from us, we disclose information that
          could be considered CHD with the following categories of third
          parties:
        </p>
        <ul>
          <li>
            Service providers. Vendors, service providers, or contractors
            (“processors”) that provide services on our behalf may access
            information that might be considered CHD for the purposes described
            above. For example, companies that provide customer service support,
            technical services, or assist in protecting and securing our systems
            and services may need access to data to provide those functions on
            our behalf.
          </li>
          <li>
            Financial institutions & payment processors. When you make a
            purchase, sign up for a membership, or enter into a financial
            transaction, we will disclose payment and transactional data to
            banks and other entities as necessary for payment processing, fraud
            prevention, credit risk reduction, analytics, or other related
            financial services.
          </li>
          <li>
            Parties to a corporate transaction. We may disclose information that
            might be considered CHD as part of a corporate transaction or
            proceeding such as a merger, financing, acquisition, bankruptcy,
            dissolution, or a transfer, divestiture, or sale of all or a portion
            of our business or assets.
          </li>
          <li>
            Affiliates. To the extent that we disclose data across our
            subsidiaries, affiliates, and related companies, we do so to
            facilitate the provision of goods or services you may{' '}
          </li>
          <li>
            With third parties for legal or security reasons. As described in
            our Privacy Policy , we disclose data to third parties (including
            law enforcement or other government agencies) when we believe doing
            so is necessary to comply with applicable law or respond to valid
            legal process.{' '}
          </li>
          <li>
            Other third parties. In certain circumstances, it may be necessary
            to provide data to other third parties, for example, to comply with
            the law or to protect our rights or those of our customers.
          </li>
          <li>
            Other users and individuals. If you use our services to interact
            with other users of the service or other recipients of
            communications, we may disclose data, including information that
            might be considered CHD, as directed by you and your interactions in
            order to provide the services or product you request.
          </li>
          <li>
            The public. You may select options available through our services to
            publicly display and disclose certain information, such as your
            Athlinks profile, demographic data, content, or geolocation data,
            which may include information that might be considered CHD.
          </li>
        </ul>
        <h2>How to Exercise Your MHMDA Rights</h2>
        <p>
          MHMDA provides certain rights with respect to CHD, including rights to
          confirm collection of, access, delete, or withdraw consent relating to
          such data, subject to certain exceptions. You can request to exercise
          such rights using the methods described in the{' '}
          {
            <Link to='/home/privacy'>
              &nbsp;Choices You Have Regarding Your Personal Data&nbsp;{' '}
            </Link>
          }
          section of the Privacy Policy. And if you want to access or control
          CHD that we process that is not available via those methods, you can
          contact us using the contact information in the Contact Us section of
          the Privacy Policy.
        </p>
        <p>
          If your request to exercise a right under the MHMDA is denied, you may
          appeal that decision by contacting our privacy support team at ATTN:
          Athlinks Privacy Team, 2902 Corporate Place, Chanhassen, MN 55317 or
          by email at{' '}
          <a href='mailto:privacy@athlinks.com'>privacy@athlinks.com</a>. If
          your appeal is unsuccessful, you can raise a concern or file a
          complaint with the Washington State Attorney General at
          www.atg.wa.gov/file-complaint.
        </p>
      </div>
    </div>
  )
}
