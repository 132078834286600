export const styles = {
    adOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
    },
    adContent: {
      position: 'relative',
      maxWidth: '90%',
      maxHeight: '90%',
      overflow: 'auto',
      background: 'white',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
    },
    adImage: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto',
        height: 'auto',
        display: 'block',
        margin: '0 auto',
      },
    closeButton: {
      position: 'absolute',
      bottom: '20px',
      right: '20px',
      padding: '10px 20px',
      background: '#16A9E1',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
    },
    bannerStyle: {
        width: '100%',
        margin: '20px auto',
      }
  };
  