import React from 'react';
import { styles } from './styles';

export const Advertise = () => {
  return (
    <div className="row">
      <div className="col-12"  style={styles.fullPage}>
        <h2>Athlinks is the best way to reach the competitive endurance
          community.</h2>
          <div className="row p-0 m-0">
            <div className="col-2">
              <span>Contact us at</span><br/>
              <a href="mailto:sales@athlinks.com">sales@athlinks.com</a><br/>
              <span>for more information</span>
            </div>
            <div className="col-10">
              <p className="m-0">Athlinks is the first complete marketplace for
                anyone who touches athletic events. For brands, we offer
                unprecedented access to active consumers at every stage of
                their athletic event journey.</p>
              <p>Whether you’re looking to reach athletes as they search for
                their next event or cross the finish line, our digital and
                experiential solutions get your brand’s message in front of the
                right consumers at the right time.</p>
              <p>Let us help you design a campaign that taps into the passion
                points of active consumers and drives results for your business.
              </p>
            </div>
          </div>
      </div>
    </div>
  );
};
