import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { fetchPendingClaims, getPendingClaims } from '../../data/UnclaimedSearchStreams';
import { getCheckedEntriesStream } from '../../data/UnclaimedSearchStreams';
import { getPendingStatusForResult } from '../../utils/ClaimUtil';
import { RaceResult } from './RaceResult';
import { RaceResultTitle } from './RaceResultTitle';
import { shouldShowSignUpBanner } from '../../utils/isLoggedIn';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';

class RaceResultListComponent extends Component {
  static propTypes = {
    allRaceResultsToggled: PropTypes.func.isRequired,
    checkedEntries: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    raceResults: PropTypes.array.isRequired,
    raceResultToggled: PropTypes.func.isRequired,
    selectedRaces: PropTypes.array,
  };

  state = {
    claimAllOpen: false,
    claimMergeOpen: false
  };

  componentDidMount() {
    fetchPendingClaims();
  }

  //Performance optimization - rely on raceResults AND selectedRaces being immutable for reference comparison
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.raceResults !== nextProps.raceResults ||
      this.props.selectedRaces !== nextProps.selectedRaces ||
      this.props.isMobile !== nextProps.isMobile ||
      this.props.checkedEntries !== nextProps.checkedEntries ||
      this.state.claimAllOpen !== nextState.claimAllOpen ||
      this.state.claimMergeOpen !== nextState.claimMergeOpen ||
      this.props.checkedEntries !== nextProps.checkedEntries ||
      !_.isEqual(this.props.pendingClaims, nextProps.pendingClaims)
  }

  claimCallback = () => {
    fetchPendingClaims();
  };

  render() {
    const {
      raceResults = [],
      raceResultToggled,
      selectedRaces,
      isMobile,
      blockResultEntries,
      pendingClaims
    } = this.props;

    let prevSeenYear = null;
    let isFirst = true;
    let signUpBannerCount = 0;

    const races = [];
    const claims = _.get(pendingClaims, 'result', []);

    (raceResults || []).forEach((raceResult) => {
      if (isFirst || prevSeenYear !== raceResult.year) {
        prevSeenYear = raceResult.year;
        isFirst = false;
        races.push(
          <RaceResultTitle key={'title-'+raceResult.year}
                           year={raceResult.year} />
        );
      }

      races.push(
        <RaceResult
          key={raceResult.EntryId}
          raceResult={raceResult}
          blockResultEntries={blockResultEntries}
          selectedRaces={selectedRaces}
          raceResultToggled={raceResultToggled}
          isMobile={isMobile}
          showSignUpBanner={signUpBannerCount === 2 && shouldShowSignUpBanner()}
          claimCallback={this.claimCallback}
          claimStatus={!_.isEmpty(claims) ? getPendingStatusForResult(claims, raceResult) : ''}
        />
      );
      signUpBannerCount += 1;
    });

    return (
      <div className="container" style={{marginTop:48}}>
        {races}
      </div>
    );
  }

}

export const RaceResultList = connectStream({
  checkedEntries: getCheckedEntriesStream,
  pendingClaims: getPendingClaims
})(RaceResultListComponent)