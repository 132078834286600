import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {RivalVs} from './rivals/RivalVs';
import {RivalsList} from './rivals/RivalsList';
import {
  fetchAthleteRivals
} from '../../data/AthleteStreams';
import {Suggestions} from '../shared/Suggestions';
import {Banner, AthlinksAds} from '../shared/Banner';
import { bannerStyle } from '../../shared/styles';
import {suggestionStyles, rivalStyles, followerStyles} from './styles';
import {isLoggedIn} from '../../utils/isLoggedIn';

export class Rivals extends Component {

  static propTypes = {
    athleteId: PropTypes.number.isRequired,
    selectedRivalId: PropTypes.number,
    athlete: PropTypes.object.isRequired,
    rivals: PropTypes.array,
    fetching: PropTypes.bool,
    suggested: PropTypes.object,
    isMe: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    rivals: [],
    fetching: false,
  };

  constructor(props, context) {
    super(props, context);
    AthlinksAds.init();
  }

  // search = (searchTerm) => this.setState({searchTerm});

  shouldComponentUpdate(nextProps) {
    return this.props.rivals !== nextProps.rivals
      || this.props.athlete !== nextProps.athlete
      || this.props.athleteId !== nextProps.athleteId
      || this.props.suggested !== nextProps.suggested
      || this.props.selectedRivalId !== nextProps.selectedRivalId;
  }

  componentDidMount() {
    if (this.props.athleteId) {
      fetchAthleteRivals(this.props.athleteId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.athleteId && nextProps.athleteId !== this.props.athleteId) {
      fetchAthleteRivals(nextProps.athleteId);
    }
  }

  render() {
    const {
      athleteId,
      selectedRivalId,
      athlete,
      rivals = [],
      fetching,
      suggested = {},
      isMobile,
      isMe
    } = this.props;

    const adunits = AthlinksAds.adsForPage('Profile');

    if (!athlete || !rivals) {
      return;
    }

    return (
      <div className='row'>
        <div className={isMobile ? 'col-12 p-0' : 'col-12 col-lg-8'}>
          { !Number.isInteger(selectedRivalId) ?
            <div style={rivalStyles.card(isMobile)}>
              <RivalsList
                athlete={athlete}
                rivals={rivals}
                fetching={fetching}
                isMobile={isMobile}
                isMe={isMe}
              />
            </div>
            :
            <div style={rivalStyles.vsCard(isMobile)}>
              <RivalVs
                athlete={athlete}
                rival={rivals.find(({RacerID}) => selectedRivalId === RacerID)}
                fetching={fetching}
                isMobile={isMobile}
              />
            </div>
          }

        </div>

        <div className={isMobile ? 'col-12 p-0' : 'col-12 col-lg-4'}>
          <div style={suggestionStyles.suggestionColumn(isMobile)}>
            { isLoggedIn() &&
              <Suggestions
                isMobile={isMobile}
                athleteId={athleteId}
                {...suggested}
              />
            }
            {adunits.map((x) => {
              return (
                <div key={x.adKey} style={bannerStyle(isMobile)}>
                  <Banner
                    data-ad={x.adKey}
                    adKey={x.adKey}
                    divId={x.divId}
                    style={followerStyles.bannerStyle}/>
                </div>
              );
            })}
          </div>
        </div>

      </div>
    );
  }
}
