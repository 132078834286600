import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {AthleteRelated} from './AthleteRelated';
import { followerStyles, overviewStyles } from './styles';
import Calendar from './Calendar';
import {Banner, AthlinksAds} from '../shared/Banner';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import FriendsUpcomingEvents from './FriendsUpcomingEvents';
import {isToday} from '../../utils/date';

/**
 *
 * Overview section for athlete profile
 *
 * @param athleteRelatedProps
 * @param hasLiveBanner
 * @param athlete
 * @param athleteId
 * @param calendar
 * @param friendsUpcoming
 * @param fetchAthleteCalendar
 * @param fetching
 * @param races
 * @param isMobile
 * @param liveEvent
 * @param pendingClaims
 * @returns {XML}
 * @constructor
 */
export const Overview = (
  {
    athleteRelatedProps,
    athlete,
    athleteId,
    calendar,
    friendsUpcoming,
    fetchAthleteCalendar,
    races: {fetching, races = []},
    liveEvent,
    isMobile,
    hasLiveBanner,
    pendingClaims,
    t
  }) => {
  const isMe = getTokenRacerId() === parseInt(athleteId);
  const shouldShowFriendsUpcomingEvents = !!_.size(_.get(friendsUpcoming, 'Events.List', []));
  const hasUpcomingRaces = !!_.size(_.get(calendar, 'UpcomingRaces', [])) &&
    !(calendar.UpcomingRaces.length === 1 && isToday(calendar.UpcomingRaces[0].StartDate));

  const adunits = AthlinksAds.adsForPage('Profile');
  return (
    <div className={`row`}>
      <div className={isMobile ? 'col-12 px-0' : 'col-8'} id='overview-content'>
        {/*Live Events*/}
        {
          hasLiveBanner &&
          <Calendar
            athleteId={athleteId}
            isMobile={isMobile}
            isMe={isMe}
            liveEvent={liveEvent}
            title={t('Today\'s Events')}
            noResultsMessage={
              t('What\'s next on your event calendar? Join a start list and '
                + 'your upcoming events will show up here. You\'ll also see upcoming'
                + ' events of anybody you follow.')
            }
            type="liveEvents"
            calendar={calendar}
            friendsUpcoming={friendsUpcoming}
            fetchAthleteCalendar={fetchAthleteCalendar}
            pendingClaims={pendingClaims}
          />
        }

        {/*Upcoming Events*/}
        {
          (hasUpcomingRaces || !shouldShowFriendsUpcomingEvents) &&
          <Calendar
            athleteId={athleteId}
            isMe={isMe}
            isMobile={isMobile}
            liveEvent={liveEvent}
            title={t('Upcoming Events')}
            noResultsMessage={
              t('What\'s next on your event calendar? Join a start list and '
                + 'your upcoming events will show up here. You\'ll also see upcoming'
                + ' events of anybody you follow.')
            }
            type="upcomingEvents"
            calendar={calendar}
            friendsUpcoming={friendsUpcoming}
            fetchAthleteCalendar={fetchAthleteCalendar}
            races={races}
            pendingClaims={pendingClaims}
          />
        }
        {
          isMe && shouldShowFriendsUpcomingEvents &&
          <FriendsUpcomingEvents
            upcomingEvents={friendsUpcoming}
            isMobile={isMobile}
            isMe={isMe}
            t={t}
          />
        }
        {/*Latest Update Events*/}
        <Calendar
          athleteId={athleteId}
          isMe={isMe}
          isMobile={isMobile}
          liveEvent={liveEvent}
          title={t('Latest Results')}
          noResultsMessage={
            t('Athlinks is the largest collection of official race results '
              + 'in the world. Find your results and claim them to your profile!')
          }
          type="latestUpdate"
          races={races}
          fetching={fetching}
          friendsUpcoming={friendsUpcoming}
          fetchAthleteCalendar={fetchAthleteCalendar}
          pendingClaims={pendingClaims}
        />
      </div>
      <div
        className={isMobile ? 'col-12' : 'col-4'}
        style={isMobile ? {padding: 0} : null}>
        <div style={overviewStyles.rightSidebar} id='athlete-related'>
          <AthleteRelated
            {...athleteRelatedProps}
            athleteId={athleteId}
            athlete={athlete}
            isMobile={isMobile}
            isMe={isMe}
            t={t}
          />
        </div>

        {adunits.map((x) => {
          return (
            <div key={x.adKey} style={overviewStyles.rightSidebar} className='profile-banner'>
              <Banner
                data-ad={x.adKey}
                adKey={x.adKey}
                divId={x.divId}
                style={followerStyles.bannerStyle}/>
            </div>
          );
        })}
      </div>

    </div>
  );
};

Overview.propTypes = {
  athleteRelatedProps: PropTypes.any,
  athlete: PropTypes.any,
  athleteId: PropTypes.any,
  calendar: PropTypes.any,
  friendsUpcoming: PropTypes.any,
  fetchAthleteCalendar: PropTypes.any,
  liveEvent: PropTypes.array,
  suggested: PropTypes.any,
  races: PropTypes.object,
  isMobile: PropTypes.bool,
  hasLiveBanner: PropTypes.bool
};

Overview.defaultProps = {
  races: {fetching: false},
};
