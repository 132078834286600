import React  from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';
import { IndividualKioskContentHeader } from './IndividualKioskContentHeader';
import { IndividualKioskResultsContent } from './IndividualKioskResultsContent';
import { IndividualKioskShare } from './IndividualKioskShare';
import { CardHeader } from './CardHeader';
import { MasterEvent } from '../../classes/MasterEvent';

/**
 * Individual Kiosk Result content holder
 * @param {Result} result
 * @param {Event} event
 * @param {MasterEvent} masterEvent
 * @param {boolean} isMobile
 * @return {*}
 * @constructor
 */
export const IndividualKioskContent = (
  {
    eventCourseName,
    eventDate,
    eventMetadata,
    isLightMode,
    isLoading,
    isMobile,
    masterEvent,
    onBack = () => {},
    result,
    t
  }) => (
  <div
    className='row mx-0'
    style={styles.resultContainer}>
    <CardHeader
      eventMetadata={eventMetadata}
      eventDate={eventDate}
      isLightMode={isLightMode}
      onBack={onBack}
      isMobile={isMobile}
      t={t}
    />
    <div
      className='col-sm-10 col-md-8'
      style={styles.card(isLightMode)}>
      <IndividualKioskContentHeader
        bibNumber={result.bib}
        isLightMode={isLightMode}
        isLoading={isLoading}
        isMobile={isMobile}
        t={t}
        title={result.displayName}
      />
      <IndividualKioskResultsContent
        eventCourseName={eventCourseName}
        eventDate={eventDate}
        isLightMode={isLightMode}
        isMobile={isMobile}
        result={result}
        t={t}
      />
      <IndividualKioskShare
        t={t}
        eventMetadata={eventMetadata}
        isLightMode={isLightMode}
        isMobile={isMobile}
        masterEvent={masterEvent}
        result={result}
      />

    </div>
  </div>
);

IndividualKioskContent.propTypes = {
  isMobile: PropTypes.bool,
  masterEvent: PropTypes.instanceOf(MasterEvent).isRequired,
  result: PropTypes.object.isRequired,
  t: PropTypes.func
};
