const strftime = require('strftime');
const locales = require('../shared/locales').locales;
const timeZones = require('../shared/timeZones').timeZones;
const moment = require('moment-timezone');

function _getStrftime(tz, lc, epoch) {
  const match = (tz || '').match(/^(America|US)\/(.*)/) || [];
  const defaultOffset = () => {
    return (
      timeZones[`America/${match[2]}`]
        || timeZones[`US/${match[2]}`]
        || timeZones[tz]
        || null
    );
  }
  const mZone = moment.tz.zone(`America/${match[2]}`)
             || moment.tz.zone(`US/${match[2]}`)
             || moment.tz.zone(tz)
             || null;
  const offset = mZone ? (mZone.utcOffset(epoch) * -1) : defaultOffset();

  return strftime.localize(locales[lc]).timezone(offset);
}

function _dateFromEpoch(epoch) {
  return epoch ? new Date(epoch * 1000) : new Date(null);
}

export function analyzeDateRange(startEpoch, endEpoch, timeZone, lc) {
  const strf = _getStrftime(timeZone, lc);
  const start = strf('%Y %m %d', _dateFromEpoch(startEpoch)).split(' ');
  const end = endEpoch ? strf('%Y %m %d', _dateFromEpoch(endEpoch)).split(' ') : null;

  const dayDiffers = end === null ? false : start[2] !== end[2];
  const monthDiffers = end === null ? false : start[1] !== end[1];
  const yearDiffers = end === null ? false : start[0] !== end[0];

  return {
    isRange: dayDiffers || monthDiffers || yearDiffers,
    dayDiffers,
    monthDiffers,
    yearDiffers
  };
}

export function formatted(epoch, format, timeZone, lc) {
  const strf = _getStrftime(timeZone, lc, epoch * 1000);
  return epoch ? strf(format || '%D', _dateFromEpoch(epoch)) : null;
}

export function ymdDate(epoch, format, timeZone, lc) {
  return formatted(epoch, format, timeZone, lc);
}

export const dateUtils = {
  analyzeDateRange,
  formatted,
  ymdDate
}
