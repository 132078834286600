import React from 'react';
import {withTranslation} from 'react-i18next';
import {MdHelpOutline} from 'react-icons/lib/md';
import {Tooltip} from '../tooltip/Tooltip';
import {ClaimStatus} from '../../data/UnclaimedSearchStreams';
import {getClaimStatusDescription} from '../../utils/ClaimUtil';
import {styles} from './styles';

export const VRBadge = (props = undefined) => {
  const labelOrChildren = props?.children ? props.children :
    <span>{props?.label || 'Virtual Race'}</span>
  return(
    <div style={{...styles.VRBadge, ...props?.style}}>
      {labelOrChildren}
      {/* {console.log(props.race[0])} */}
    </div>
  )
}

const VRBadgeLinkComponent = (props) => {
  const {
    t,
    hideToolTip
  } = props
  return(
    <div style={styles.VRTextLink}>
      Virtual
      {
        !hideToolTip && (
        <Tooltip
          icon={<MdHelpOutline style={styles.VRBadgeIcon} />}
          tip={{
            id: `ClaimedVirtual-tooltip`,
            content: getClaimStatusDescription(ClaimStatus.virtual, t)}}
          style={styles.resultRowTooltip}
        />)
      }
    </div>
  )
}

export const VRBadgeLink = withTranslation()(VRBadgeLinkComponent)