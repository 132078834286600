import ChronotrackApi from "../../anuket-http/ChronotrackApi";

export const getPhotosAndVideos = ({eventId, entryId}) => {
  const streamId = `GetPhotosAndVideos-${eventId}-${entryId}`;
  return ChronotrackApi.memoizeQuery(streamId, `results/${eventId}/entry/${entryId}`)
    .map((msg) => {
      return {
        photos: msg.photos,
        videos: msg.videos
      };
    });
}