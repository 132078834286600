import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { ClaimResultButton } from '../control/ClaimResult';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { DateTime } from '../../components/shared/DateTime';
import { getCheckedEntriesStream } from '../../data/UnclaimedSearchStreams';
import { getResultsUrlForBib, getResultUrl } from '../../utils/resultsHelpers';
import { getTokenRacerId, isLoggedIn } from '../../utils/isLoggedIn';
import { resultList } from '../../shared/styles';
import { SignUpBannerSearch } from '../searchComponents/SignUpBannerSearch';
import Style from 'style-it';
import { styles } from './styles';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { VRBadgeLink } from '../shared/VRBadge';

class RaceResultComponent extends Component {

  static propTypes = {
    raceResult: PropTypes.object.isRequired,
    raceResultToggled: PropTypes.func.isRequired,
    blockResultEntries: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    claimCallback: PropTypes.func,
    claimStatus: PropTypes.string,
    t: PropTypes.func
  };

  state = {
    blocked: false,
    processing: false,
    userClaimed: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.raceResult !== nextProps.raceResult ||
        this.state.blocked !== nextState.blocked ||
        this.state.processing !== nextState.processing ||
        this.props.claimStatus !== nextProps.claimStatus ||
        this.state.userClaimed !== nextState.userClaimed;
  }

  componentCallback = (claimed) => {
    this.setState({
      userClaimed: claimed
    })
  };

  /**
   * Will build props for (un)claim modal
   * Since this component is used for unclaimed search
   * isClaimed is always false and racerId is always 0.
   * @return {*}
   */
  getClaimProps = () => {
    const {
      isMobile,
      raceResult: {
        Age,
        Gender,
        DisplayName,
        EntryId,
        EventCourseId,
        Time,
        Name,
        Country,
        FormatDate,
        StateProv
      },
      claimCallback,
      claimStatus
    } = this.props;

    const { userClaimed } = this.state;

    return {
      age: Age,
      gender: Gender,
      fullName: DisplayName,
      entryId: EntryId,
      eventCourseId: EventCourseId,
      isLoggedIn: isLoggedIn(),
      isMobile,
      isUnclaimedSearch: true,
      racerId: 0,
      finalTime: Time,
      eventName: Name,
      country: Country,
      date: FormatDate,
      state: StateProv,
      claimStatus,
      onClaimCallback: claimCallback,
      claimOverride: userClaimed
    };
  };

  render() {
    const eventUrl = this.getEventUrl();

    const {
      isMobile,
      raceResult = {},
      showSignUpBanner,
      claimStatus,
      t
    } = this.props;

    const { blocked, processing, userClaimed } = this.state;

    const {
      Name,
      DisplayName,
      StateProv,
      Country,
      GenderID,
      Age,
      Time,
      IsVirtual,
    } = raceResult;

    const { resStyle } = styles;

    const clickHandler = (ev) => {
      if (!ev.isDefaultPrevented()) {
        // Don't mess with the native function of anchor tags
        if (ev.currentTarget.contains(ev.target) && (ev.target.tagName !== 'A' && ev.target.tagName !== 'BUTTON')) {
          ev.preventDefault()
          // console.log({... ev})
          this.props.history.push({
            pathname: eventUrl
          })
        }
      }
    }


    return (
      Style.it(resStyle.pseudoStyles(isMobile),
      <div>
        <div
          className="row unclaimed-result-row"
          style={resStyle.row(isMobile)}
          onClick={(ev) => {
            trackGoogleEvent('search', 'selectResult', eventUrl);
            clickHandler(ev)
          }}
        >
          <div className="col-md-11 col-lg-7" style={resStyle.rowChild}>
            <div style={resStyle.resultInfoContainer}>
              {
                // Name
              }
              <div className={'unclaimed-results-event-url'}>
                <span style={resultList.name(blocked)}>
                  {Name}
                </span>
              </div>
              {
                // Data/Location row
              }
              <div style={resStyle.dateRow(isMobile)}>
                  <span style={resStyle.datumLeft}>
                    <DateTime
                        date={raceResult.StartDateTime}
                        showTime={false}
                        icon={null}
                    />
                  </span>
                  <span style={resStyle.datum} className={'race-result-state-country'}>
                    {t('{{stateProv}}, {{country}}', {stateProv: StateProv, country: Country})}
                  </span>
              </div>
              {
                // Athlete row
              }
              <div style={resStyle.athleteRow(isMobile)}>
                  <span style={resStyle.datumLeft}>
                    {DisplayName}
                  </span>
                  <span style={resStyle.datum} className={'race-result-gender-age'}>
                    {GenderID ? GenderID : '' }
                    {Age > 0 ? Age : ''}
                  </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-3 px-0" style={resStyle.timeContainer}>
            <div style={resStyle.timeLabel}>
              {t('Final Time')}
            </div>
            <div style={resStyle.time(blocked)}>
              {IsVirtual ? '--' : Time}
            </div>
            <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}> {
              IsVirtual === true ? <VRBadgeLink props={this.props}/> : null
            }</div>
          </div>
          <div
            className="col-md-12 col-lg-2 p-0 unclaimed-results-claim-result"
            style={{...resStyle.claim, textAlign: 'center', marginTop: 20}}
          >
            {
              !blocked &&
              <ClaimResultButton
                actionsDone={this.toggleProcessing}
                componentCallback={this.componentCallback}
                onClick={this.toggleProcessing}
                {...this.getClaimProps()}
              />
            }
            {
              !userClaimed && !claimStatus && !processing && isLoggedIn() &&
              <span style={resStyle.notMine}
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  this._block()
                }}
                className='hide-button'
              >
                {
                  !blocked
                    ? t('Hide')
                    : t('Hidden')
                }
              </span>
            }
          </div>
        </div>
        {
          showSignUpBanner &&
          <SignUpBannerSearch
            isMobile={isMobile}
            style={{
              marginRight: isMobile ? -20 : 0,
              marginLeft: isMobile ? -20 : 0,
              marginTop: 10,
              marginBottom: isMobile ? 40 : 65
            }}
            t={t}
          />
        }
      </div>
      )
    );
  }

  _block = () => {
    trackGoogleEvent('Claim', 'Click', 'Hide Result');
    const { raceResult: {EntryId}} = this.props;

    this.props.blockResultEntries(getTokenRacerId(), EntryId, this.state.blocked);
    this.setState({ blocked: !this.state.blocked });
  };

  getEventUrl() {
    const { raceResult: {EventId, EventCourseId, EntryId, BibNum} } = this.props;

    return BibNum
      ? getResultsUrlForBib(EventId, EventCourseId, BibNum, null, EntryId)
      : getResultUrl(EventId, EventCourseId, EntryId);
  }

  toggleProcessing = () => this.setState({ processing: !this.state.processing })
}

export const RaceResult = compose(
  connectStream({checkedEntries: getCheckedEntriesStream}),
  withTranslation(),
  withRouter,
)(RaceResultComponent)
