import { entityDecoder } from '../../bastetjs/utils/entityDecoder';
import { Configure, FetchApi } from '../../anuket-http';
import { take, map } from 'rxjs/operators'

export function mapEvents(evts) {
  return evts.map((evt) => {
    return entityDecoder.decode({
      event: {
        athlinksId: evt.athlinksId,
        allowCourseChange: evt.allowCourseChange,
        masterId: evt.athlinksMasterId || 0,
        ctliveId: parseInt(evt.affiliateRaceId || 0),
        name: evt.name,
        masterName: evt.masterName,
        description: evt.description,
        extendedDescription: evt.extendedDescription,
        endEpoch: (new Date(evt.endTime).getTime() / 1000),
        startEpoch: (new Date(evt.startTime).getTime() / 1000),
        imageName: evt.imageName,
        city: evt.location ? evt.location.city : '',
        region: evt.location ? evt.location.regionName : '',
        regionId: evt.location ? evt.location.regionId : '',
        country: evt.location ? evt.location.country : '',
        countryId: evt.location ? evt.location.countryId : '',
        latitude: evt.location ? evt.location.latitude : null,
        longitude: evt.location ? evt.location.longitude : null,
        timeZone: evt.location ? evt.location.timeZone : '',
        organizationName: evt.organization ? evt.organization.name : '',
        organizationEmail: evt.organization ? evt.organization.email : '',
        organizationUrl: evt.organization ? evt.organization.url : '',
        timerName: evt.timer ? evt.timer.name : '',
        timerEmail: evt.timer ? evt.timer.email : '',
        timerUrl: evt.timer ? evt.timer.url : '',
        eventType: evt.eventType,
        siteUri: evt.siteUri,
        facebookUrl: evt.facebookUrl,
        twitterHandle: evt.twitterHandle,
        instagramHandle: evt.instagramHandle,
        thirdPartyRegLink: evt.thirdPartyRegLink,
        hasHeroImage: evt.hasHeroImage,
        isPublished: evt.isPublishedAthlinks,
        isPhotoEnabled: evt.isPhotoEnabled,
        isVideoEnabled: evt.isVideoEnabled,
        wantsPhotoPurchasing: !!evt.wantsPhotoPurchasing,
        entryPhotoPrice: evt.entryPhotoPrice,
        photoPrice: evt.photoPrice,
        photoPurchasingHelp: evt.photoPurchasingHelp,
        photoServiceType: evt.photoServiceType,
        photoServiceName: evt.photoServiceName,
        photoServiceUrl: evt.photoServiceUrl,
        bazuServices: evt.bazuServices ? evt.bazuServices : []
      }
    });
  });
}
export function getLeastFutureEventByMasterId({masterId}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Api/LeastFuture/${masterId}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(
    take(1),
    map((msg) => {
      const res = msg.success ? msg.result : {};
      return mapEvents(res.athlinksId ? [res] : [])[0];
    })
  );
}
export function getMostRecentPastEventByMasterId({masterId}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Api/MostRecent/${masterId}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(
    take(1),
    map((msg) => {
      const res = msg.success ? msg.result : {};
      return mapEvents(res.athlinksId ? [res] : [])[0];
    })
  )
}
export function getEventByEventId({eventId}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Api/Merged/${eventId}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(
    take(1),
    map((msg) => {
      const res = msg.success ? msg.result : {};
      return mapEvents(res.athlinksId ? [res] : [])[0];
    })
  );
}