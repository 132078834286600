import { Button, styled } from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { DialogModal } from './common/DialogModal';
import { Privacy } from '../../views/document/Privacy';

class PrivacyPolicyModalComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  state = {
    success: true
  };

  getBody(){
    const mainStyle = {
      padding: 20,
      borderBottom: '1px solid #dddddd',
      borderTop: '1px solid #dddddd'
    };

    return (
      <div style={mainStyle}>
        <Privacy showDownloadLink={false} isContentOnly={true} />
      </div>
    );
  }


  getActions() {
    const closeCallback = this.props.handleClose || (() => {});
    const closeButton = {
      color: '#4a4a4a'
    };
    const actionButtonContainer = {
      textAlign: 'right',
      borderTop: 'none',
      textTransform: 'capitalize',
    };

    const FlatButton = styled(Button)({
      ...closeButton
    })

    return (
      <div style={actionButtonContainer}>
        <div>
          <FlatButton
            variant="outlined"
            primary={true}
            onClick={closeCallback}
          >
            {this.props.t('Close')}
          </FlatButton>
        </div>
      </div>
    );
  }

  render(){
    const actionStyle = {
      paddingRight: 40,
      borderTop: 'none',
      paddingBottom: 40,
      marginTop: 20
    };

    return (
      <span>
        <DialogModal
          isMobile={this.props.isMobile}
          title={
            <span>{this.props.t('Athlinks Privacy Policy')}</span>
          }
          body={this.getBody()}
          actions={this.getActions()}
          actionsContainerStyle={actionStyle}
          open={this.props.open}
          onRequestClose={this.props.handleClose}
          bodyStyle={{minHeight: 120}}
          modalContainerStyle={{display:'inline-block'}}/>
      </span>
    );
  }
}

export const PrivacyPolicyModal = withTranslation()(PrivacyPolicyModalComponent);
