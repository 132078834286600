import { ModalActionTypes as Actions } from '../actions/types'
import { ModalState } from '../model/modalState'

const initialState = ModalState

export default (state = initialState, action) => {
  switch (action.type) {
    case Actions.SHOW_MODAL:
      return {
        modalProps: action.modalProps,
        modalType: action.modalType,
        pages: action.pages
      }
    case Actions.HIDE_MODAL:
      return initialState
    case Actions.MODAL_CHANGE_PAGE:
      return {
          ...state,
          modalProps: {
            ...state.modalProps,
            currentPage: action.page
          }
        }
    default:
      return state
  }
}
