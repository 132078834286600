import md5 from 'md5';
import { memoize } from 'lodash';

const _cache = {}; // Memory.


const DEFAULT_TTL = 180*1000; // 3 minutes, in milliseconds.

function _expired(key, ttl) {
  return !_cache[key] || Math.abs(Date.now() - _cache[key].tstamp) >= ttl;
}

function _getKey (fn, args) {
  if (typeof fn !== 'function') {
    throw new Error('Parameter `fn` must be a function.');
  }
  return md5([fn.toString(), JSON.stringify(args)].join(''));
}

const Memoizer = {
  DEFAULT_TTL,
  expired: _expired,
  getKey: _getKey,
  memoize: (fn, ttl, verbose) => {
    ttl = ttl || ttl === 0 ? ttl : DEFAULT_TTL;

    return function() {
      const args = [...arguments];
      const key = _getKey(fn, args);

      if (!_cache[key] || _expired(key, ttl)) {
        verbose && console.log(`Applying ${fn.name || 'anon'}, _cache key=${key}`);
        _cache[key] = {
          value: memoize((key) => fn.apply(null, args)),
          tstamp: Date.now()
        };
      }
      else if (verbose) {
        console.log(`Memoized ${fn.name || 'anon'}, _cache key=${key}`);
      }

      return _cache[key].value(key);
    };
  }
};

export default Memoizer;
