import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {  SearchIconNew } from '../../components/shared/icons';
import useDebounce from '../../hooks/useDebounce';

type IPushHistory = (value: {
  _term: string;
  _page: number;
}) => void;

type Props = {
  pushHistory: IPushHistory
}

export function TermSearch({
  pushHistory,
}: Props) {
  const termInput = useRef<any>();

  const { t } = useTranslation();

  const currentUrl = () => new URL(window.location.href);
  const searchTerm = currentUrl().searchParams.get('term') || '';
  const [term, setTerm] = useState(searchTerm);
  const debounce = useDebounce(term, 600)

  useEffect(() => {
    setTerm(searchTerm);
  }, [searchTerm])

  useEffect(() => {
    const searchTerm = currentUrl().searchParams.get('term') || '';
    const _term = debounce ? String(debounce).trim() : ''

    if (_term !== searchTerm) {
      pushHistory({ _term, _page: 1 });
    }
  }, [debounce, pushHistory])

  return (
    <TextField
      fullWidth
      autoComplete={'off'}
      autoCorrect={'off'}
      placeholder={t('Search by bib, name or team')}
      defaultValue={term}
      inputRef={termInput}
      value={term}
      inputProps={{ id: 'athlete-search-input' }}
      InputProps={{
        endAdornment: term && term.length ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search input"
              onClick={() => {
                setTerm(termInput.current.value = '');
              }}
              edge="end"
            >
              <HighlightOffIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIconNew />
          </InputAdornment>
        )
      }}
      onChange={(e) => setTerm(e.currentTarget.value)}
    />
  );
}
