import {calculatePace} from '../lib/anuket/CalculatePace'
import {dateUtils} from '../lib/bastetjs/utils/dateUtils';
import i18next from './i18n';

import {shortDate} from './date'

export function getDate(epoch, tz) {
  const args = [epoch, '%Y %B %d', tz || 'GMT', i18next.language];
  const date = dateUtils.formatted.apply(null, args);
  return shortDate(date.split(' '));
}

export function calculatePaceArrForResult({raceCatDesc, distUnit, ticks}) {
  const pacePair = ticks && distUnit ? calculatePace({meters: distUnit, type: raceCatDesc}, ticks, true) : [];
  return pacePair && pacePair.length === 2
  && !['0.00', 'Infinity'].includes(pacePair[0])
    ? pacePair : [];
}

export function adjustRaces(races) {
  return races.map((race) => {
    const date = getDate(race.startEpoch);
    return Object.assign({}, race, {dateStr: date})
  });
}

export function eventToDateMap(races) {
  return races.reduce((acc, race) => {
    acc[race.eventId] = getDate(race.startEpoch);
    return acc;
  }, {});
}

export function getDateOptions(races, intl) {
  return races.map((race) => {
    return {
      text: intl.formatDate(new Date(race.raceDate), {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      }),
      value: race.eventId,
      startEpoch: race.startEpoch,
      eventName:  race.eventName
    };
  }).reduce((prev, curr) => {
    if (prev.map((_) => _.value).indexOf(curr.value) < 0) prev.push(curr);
    return prev;
  }, []);
}

export function getCourseOptions(races, eventOpt, t) {
  return [{text: t('All Race Types'), value: 0}].concat(races.filter((race) => {
    return race.eventId === eventOpt
  }).map((race) => {
    return {
      text: race.raceName,
      value: race.eventCourseId,
      startEpoch: race.startEpoch
    }
  }).sort((a, b) => a.text === b.text ? 0 : a.text < b.text ? -1 : 1));
}

export function getUnofficialCourseOptions(races, eventOpt, t) {
  return [{text: t('All Race Types'), value: 0}].concat(races.filter((race) => {
    return race.eventId === eventOpt
  }).map((race) => {
    return {
      text: race.raceName,
      value: race.courseId,
      startEpoch: race.startEpoch
    }
  }).sort((a, b) => a.text === b.text ? 0 : a.text < b.text ? -1 : 1));
}

/**
 * Packs divisions for event. Grouped by eventCourseId and ordered in that
 * way that Overall always comes first.
 * Will also set value for Overall division to 0
 *
 * @param {[]} divisions
 * @return {object}
 */
export function getDivisionOptions(divisions) {
  const allDivisions =  divisions.reduce(
    (acc, {division, divisionId, eventCourseId}) => {
      if(!acc[eventCourseId]) {
        acc[eventCourseId] = [];
      }
      acc[eventCourseId].push({
        text: division,
        value: divisionId
      });
      return acc;
    },
  {});
  return Object.keys(allDivisions).reduce((acc, eventCourseId) => {
    acc[eventCourseId] = allDivisions[eventCourseId].map((division) => {
      if(division.text.toUpperCase() === 'OVERALL') {
        division.value = 0;
      }
      return division;
    }).sort((d1, d2) => {
      const dt1 = d1.text.toUpperCase();
      const dt2 = d2.text.toUpperCase();
      if(dt1 === 'OVERALL') return -1;
      if(dt2 === 'OVERALL') return 1;
      return dt2.localeCompare(dt1);
    });
    return acc;
  }, {});
}

export function getBracketRank(bracketName, row) {
  switch (bracketName) {
    case 'Male':
    case 'Female':
    case 'Non-Binary':
      return row.genderRank
    case 'Overall':
      return row.overallRank
    default:
      return row.primaryBracketRank
  }
}

/**
 * Packs splits for event. Grouped by eventCourseId and ordered in that
 * way that Full Course always comes first.
 * Will also set value for Full Course split to 0
 *
 * @param {[]} splits
 * @return {object}
 */
export function getSplitOptions(splits) {
  const allSplits = splits.reduce(
    (acc, {intervalId, eventCourseId, interval, distance}) => {
      if(!acc[eventCourseId]) {
        acc[eventCourseId] = [];
      }
      acc[eventCourseId].push({
        text: interval,
        value: intervalId,
        distance
      });
      return acc;
    },
  {});
  return Object.keys(allSplits).reduce((acc, eventCourseId) => {
    acc[eventCourseId] = allSplits[eventCourseId].map((split) => {
      if(split.text.toUpperCase() === 'FULL COURSE') {
        split.value = 0;
      }
      return split;
    }).sort((d1, d2) => {
      const dt1 = d1.text.toUpperCase();
      const dt2 = d2.text.toUpperCase();
      if(dt1 === 'FULL COURSE') return -1;
      if(dt2 === 'FULL COURSE') return 1;
      return d1.distance <= d2.distance ? 1 : -1;
    });
    return acc;
  }, {});
}

export function extractBasisFromLegs(legs) {
  return (legs || []).reduce((map, _) => {
    map[_.legNumber] = {
      description: _.description,
      distanceUnit: _.distanceUnit
    };
    return map;
  }, {});
}

/**
 * Builds ERP link
 * @param {number} masterId
 * @param {number} eventId
 * @param {number} eventCourseId
 * @param {number} divisionId
 * @param {number} splitId
 * @param {string} friendsFilter
 * @return {string}
 */
export function getResultsUrl(masterId, eventId, eventCourseId = 0, divisionId = 0, splitId = 0, {friendsFilter} = {}) {
  if(!eventId) {
    return `/event/${masterId}/results`;
  }
  const base = `/event/${masterId}/results/Event/${eventId}`;
  let url = (splitId && `${base}/Course/${eventCourseId}/Division/${divisionId}/Split/${splitId}/Results`)
    || (divisionId && `${base}/Course/${eventCourseId}/Division/${divisionId}/Results`)
    || (eventCourseId && `${base}/Course/${eventCourseId}/Results`)
    || `${base}/Results`;
  if (friendsFilter) url += `?friendsFilter=${friendsFilter}`;
  return url;
}

export function getResultUrl(eventId, eventCourseId, entryId, {masterId} = {}, category, term, additionalParams) {
  const queryStringParts = []
    .concat((category && category !== '') ? `category=${category}` : [])
    .concat((term && term !== '') ? `term=${term}` : [])
    .concat((additionalParams && additionalParams.length) ? additionalParams : [])
  const queryString = queryStringParts.length ? '?' + queryStringParts.join('&') : ''
  if (masterId) {
    //Already know masterId, for example when linked from ERP
    return `/event/${masterId}/results/Event/${eventId}/Course/${eventCourseId}/Entry/${entryId}${queryString}`;
  } else {
    //we're coming from somewhere else, for example athlete results and still need to look up masterId
    return `/Event/${eventId}/Course/${eventCourseId}/Result/${entryId}${queryString}`;
  }
}

export function getResultsUrlForBib(eventId, eventCourseId, bib, masterId = 0, entryId, category, term, additionalParams) {
  const queryStringParts = []
    .concat((category && category !== '') ? `category=${category}` : [])
    .concat((term && term !== '') ? `term=${term}` : [])
    .concat((additionalParams && additionalParams.length) ? additionalParams : [])
  const queryString = queryStringParts.length ? '?' + queryStringParts.join('&') : ''
  const url = bib
    ? `/Event/${eventId}/Course/${eventCourseId}/Bib/${bib}${queryString}`
    : `/Event/${eventId}/Course/${eventCourseId}/Entry/${entryId}${queryString}`;
  if(!masterId) return url;
  else return `/event/${masterId}/results${url}`;
}

export const resultsHelpers = {
  getDate,
  adjustRaces,
  eventToDateMap,
  getDateOptions,
  getCourseOptions,
  getDivisionOptions,
  getBracketRank
};
