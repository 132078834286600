import _ from 'lodash';

export function isReferrerValid(referrerPath) {
  return referrerPath !== '/signin' && referrerPath !== '/signup';
}

export function isRedirectUrlValid(url) {
  return url.match(/\/signin/i) < 0 && url.match(/\/signup/i) < 0;
}

export function query() {
  const parsed = new URL(window.location.href);
  return `referrerPath=${encodeURIComponent(parsed.pathname + parsed.search)}`;
}

export function signUp () {
  return _.map(query, (value, key) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
}
