import { streamHook, eventBus } from './Streams';
import { isLoggedIn, getTokenRacerId } from '../utils/isLoggedIn';
import { Nobles } from '../lib/anuket';

function fetchMyFriendsStartList(streamId, eventId) {
  if (isLoggedIn()) {
    const racerId = getTokenRacerId();
    return Nobles.AthletesNoble.upcomingFriendsForEvent({eventId, racerId})
      .subscribe((friends) => {
        eventBus.publish(streamId, friends);
      });
  }
}

function toStream(streamId, work, initial) {
  const stream = streamHook(streamId, {isLoading: true});
  stream.subscribe((msg) => {
    if ((msg || {}).isLoading) {
      eventBus.publish(streamId, initial);
      work(streamId);
    }
  });

  return stream;
}

export function getMyFriendsStartList(eventId) {
  return toStream('MyFriendsList', (streamId) => {
    return fetchMyFriendsStartList(streamId, eventId);
  }, {});
}
