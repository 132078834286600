export const styles = {
  container: {
    position: 'relative',
    minWidth: 80,
    fontSize: 13,
  },
  selectedLanguage: {
    width: '100%',
    background: '#fff',
    padding: '2px 4px',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
  languagesListContainer: (isKiosk = false) => ({
    width: '100%',
    position: 'absolute',
    bottom: isKiosk ? 'auto': '23px',
    top: isKiosk ? '23px': 'auto',
    background: '#fff',
    border: '1px solid #e0e0e0',
  }),
  language: {
    padding: '4px 7px',
    cursor: 'pointer'
  },
  arrowUp: {
      width: 0, 
      height: 0, 
      borderLeft: '3px solid transparent',
      borderRight: '3px solid transparent',
      borderBottom: '3px solid #8e8e8e',
      position: 'absolute',
      right: '10px',
      top: '10px',
  },
  arrowDown: {
    width: '0',
    height: '0',
    borderLeft: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderTop: '3px solid #8e8e8e',
    position: 'absolute',
    right: '10px',
    top: '10px'
  }
};