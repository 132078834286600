import React from 'react';
import {styles} from './styles';
import {HeroCardTime} from '../heroCard/Time';

export const FinalTime = ({time, pace, paceUnits, isMobile, timeLabel, showPace = true, latestIntervalName, t}) => {

  return (
    <div className="row" style={styles.cardSection} id="final-time-card">
      <div className={showPace ? 'col-7' : 'col-9'} id="irp-final-time">
        <HeroCardTime
          isMobile={isMobile}
          text={`${timeLabel} ${latestIntervalName}`}
          time={time}
        />
      </div>
      {
        showPace && pace ?
        <div className="col-5" id="irp-pace">
          <HeroCardTime
            isMobile={isMobile}
            text={`${t('Pace {{unit}}', {unit: paceUnits || ''})}`}
            time={pace}
          />
        </div> : null
      }
    </div>
  )
};
