/**
 * Null-safe string trimming
 */
export function safeTrim(str) {
  const s = str || '';
  return String.prototype.trim.call(s, s);
}

/**
 * Transforms a string to name case
 * Differs from _.capitalize in that it analyzes multiple words in a string seperately
 * rather than the whole string as a single word.
 */
export function toNameCase(str) {
  return safeTrim(str).split(/\s/g).map((_) => _.toLowerCase().replace(/^\w/, (x) => x.toUpperCase())).join(' ');
}

/**
 * Transforms a string to pascal case (PascalCase), splitting on non alpha-numeric characters
 */
export function toPascalCase(str) {
  return toNameCase(str.replace(/[^A-Za-z0-9]/g, ' ')).replace(/\s+/g, '');
}

function isObject(obj) {
  return (obj !== null) && (typeof obj === 'object');
}

export function capitalize(value) {
  if(value && value.charAt && value.charAt(0)) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  } else {
    return value;
  }
}

export function capitalizeKeys(obj) {
  return (Object.keys(obj) || []).reduce((acc, key) => {
    const value = obj[key];
    acc[capitalize(key)] = isObject(value) ? capitalizeKeys(value) : value;
    return acc;
  }, {});
}

/**
 * Transforms a string to camel case (camelCase), splitting on non alpha-numeric characters
 */
export function toCamelCase(str) {
  return toPascalCase(str).replace(/^\w/, (x) => x.toLowerCase());
}

/**
 * Joins a series of strings with the given joint, filtering out null/empty strings
 */
export function trimConcat(joint, ...rest) {
  const vals = rest && rest.length === 1 && Array.isArray(rest[0]) ? rest[0] : rest;
  return vals.filter((_) => _ !== null && _ !== undefined && `${_}`.length > 0).join(joint);
}

export const StringUtils = {
  capitalize,
  safeTrim,
  toNameCase,
  toPascalCase,
  toCamelCase,
  trimConcat
};