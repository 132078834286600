import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import Script from 'react-load-script';
import _ from 'lodash';

class FetchGeoSuggestComponent extends Component {
  static propTypes = {
    term: PropTypes.string,
    types: PropTypes.string,
    onResults: PropTypes.func.isRequired,
    googleApiKey: PropTypes.string.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    types: '(cities)'
  };

  constructor(props) {
    super(props);

    this.debouncedFetch = _.debounce(this.fetch, 600);
  }

  componentDidMount() {
    if (this.autocompleteService) {
      this.debouncedFetch(this.props.term, this.props.onResults);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.term !== nextProps.term) {
      this.debouncedFetch(nextProps.term, nextProps.onResults);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    this.debouncedFetch.cancel();
  }

  render() {
    const { googleApiKey } = this.props;

    const googleLibraryScript = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
    return (
        <Script
          url={googleLibraryScript}
          onCreate={this.handleScriptCreated}
          onError={this.handleScriptError}
          onLoad={this.handleScriptLoaded}
        />
    );
  }

  fetch = (term, onResults) => {
    if (!term) {
      return;
    }

    const { autocompleteService } = this;

    if (!autocompleteService) {
      return;
    }

    const {
      types,
      t
    } = this.props;

    try {
      autocompleteService.getPlacePredictions({
        'input': term,
        'types': types ? [types] : null
      }, (results, status) => {

        if (this.unmounted) {
          return;
        }

        const isOk = status === window.google.maps.places.PlacesServiceStatus.OK;
        const isZeroResults = status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS;
        const error = (!isOk && !isZeroResults
          ? t('Error looking up location: ') + status
          : null);
        onResults(results, error);
      });
    } catch (error) {
      onResults([], error.message || error);
    }
  };

  handleScriptCreated = () => {

  };

  handleScriptError = () => {

  };

  handleScriptLoaded = () => {
    this.autocompleteService = new window.google.maps.places.AutocompleteService();
    this.debouncedFetch(this.props.term, this.props.onResults);
  }
}

export const FetchGeoSuggest = withTranslation()(FetchGeoSuggestComponent);
