
export function toggle(id, selectedItems, isSelected) {
  selectedItems = {...selectedItems};
  if (isSelected) {
    delete selectedItems[id];
  } else {
    selectedItems[id] = 1;
  }
  return selectedItems;
}

/**
 * Get athlete initials
 * @param name
 */
export function getInitials(name) {
  if (typeof name === 'string') {
    const rawNames = name.split(' ');
    const names = rawNames.filter((name) => name); // filter our empty strings
    const initials = names.map((name) => name.split('')[0]);
    return initials.slice(0, 2).join('').toUpperCase();
  } else {
    return '';
  }
}

/**
 * Returns selected event id if event id is selected.
 * If not then if there is live event then that one is fetch.
 * Otherwise closest event in the past
 *
 * @param {[]} events - Ordered by start time descending
 * @param isLive
 * @param eventId
 * @return {number}
 */
export function getEventId(events, isLive, eventId) {
    const now = Date.now() / 1000;
    if (eventId) {
      return eventId;
    }
    const filtered = events.filter(({startEpoch}) =>
      isLive ? now < startEpoch : now >= startEpoch);
    if (filtered.length === 0) return 0;
    else return (isLive ? filtered[filtered.length - 1] : filtered[0]).value;
}
/**
 * Check if in list of friends athlete with athleteId is your friend
 * @param myFriendsList
 * @param athleteId
 * @returns {boolean}
 */
export function isAFriend(myFriendsList, athleteId) {
  //TODO verify this is the best way to detect who's a friend
  const athleteIdInt = parseInt(athleteId);
  return myFriendsList && myFriendsList
    .some((friend) => friend.RacerId === athleteIdInt);
}

export function isInPast(date){
  return date < Date.now() / 1000;
}

export function stripLeadingZero(string) {
  if (string && string[0] === '0') {
    const digits = string.split('');
    while (digits[0] === '0' || digits[0] === ':') {
      digits.shift();
    }
    return digits.join('');
  } else {
    return string
  }
}

//Email regex from http://emailregex.com/
export function validateEmail(email) {
  // eslint-disable-next-line
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}
