import React from 'react'
import { singletonHook } from 'react-singleton-hook'
import firebase from './app'

const defaultRemoteConfig = {
  config: {},
  initialized: false,
}

export type FirebaseConfig = {
  initialized: boolean
  config: {
    [key: string]: firebase.remoteConfig.Value
  }
}

export const useFirebaseConfig = singletonHook(defaultRemoteConfig, () => {

  const [state, setState] = React.useState<FirebaseConfig>(defaultRemoteConfig)
  React.useEffect(() => {
    const remoteConfig = firebase.remoteConfig()
    remoteConfig.settings = {
      fetchTimeoutMillis: 10000,
      minimumFetchIntervalMillis: 10000
    }
    remoteConfig.defaultConfig = defaultRemoteConfig.config
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        setState({
          config: remoteConfig.getAll(),
          initialized: true,
        })
      })
      .catch((e) => console.error(e))
  }, [setState])
  return state
})