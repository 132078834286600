import React from 'react';
import PropTypes from 'prop-types';
import { getMyFriendsStartList } from '../../../data/FriendsStreams';
import { connectStream } from '../../../lib/bastetjs/utils/connectStream';

/**
 * @TODO: Consider deleting this one
 */
class FriendsAttendingComponent extends React.Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    friendsAttending: PropTypes.object
  };

  render() {
    //TODO friendsAttending curated event detail
    return null;
  }
}

export const FriendsAttending = connectStream({
  friendsAttending: ({event}) => getMyFriendsStartList(event.athlinksId)
})(FriendsAttendingComponent);
