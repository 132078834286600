import React, { Component } from 'react';
import { styles } from './styles';

export class DialogTitle extends Component {
  constructor(props) {
    super(props);

    this.renderText = this.renderText.bind(this);
  }

  renderText() {
    const style = this.props.isMobile ? styles.mobile : styles.desktop;
    if (this.props.title) {
      return (
        <div style={this.props.titleStyle}>
          <div style={style.titleStyle}>{this.props.title}</div>
          {/*{hr}*/}
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div style={{textAlign: 'right', padding: '4px 4px 0 0', ...this.props.closeStyle}}>
          <svg onClick={this.props.onRequestClose}
               style={{cursor: 'pointer'}}
               viewBox='0 0 24 24' height='24' width='24' fill='#333333'
               className='close-icon'>
            <g transform='scale(1)'>
              <path d='M19 6.415l-1.415-1.415-5.585 5.585-5.585-5.585-1.415 1.415 5.585 5.585-5.585 5.585 1.415 1.415 5.585-5.585 5.585 5.585 1.415-1.415-5.585-5.585z'/>
            </g>
          </svg>
        </div>
        {this.renderText()}
      </div>
    );
  }
}
