import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { streamHook, streamFail, eventBus } from './Streams';
import { getToken } from '../utils/isLoggedIn';
import {getTypes, getLocation} from './SearchStreams';
import _ from 'lodash';
import { EVENT_CONTEXT } from './SearchContext';
import { trackGoogleEvent } from '../utils/googleEvents';

export const eventSearchStreamId = 'EventSearchResults';

/**
 * Extract date range values from date range element data
 * @param {*} query
 * @return {{startDate: string, endDate: string}}
 */
export const getDates = (query) => {
  const {dateRangeFilter: {value, enabled = false} = {}} = query;
  const {from = '', to = ''} = value || {};
  if(!enabled) return {startDate: '', endDate: ''};
  return {startDate: from, endDate: to};
};

/**
 * Search should only start if there are at least location, one of the dates
 * or search term.
 * Search should also start if there are at least on of the race types (?)
 * @param data
 * @return {boolean}
 */
export const shouldSearch = (data) => {
  const {searchTerm, location, startDate, endDate, limit} = data;
  return !(searchTerm === '' &&
    location === '' &&
    limit < 10 &&
    startDate === '' &&
    endDate === '');
};

/**
 * Builds query string
 * @param {*} filteredQuery
 * @return {string}
 */
const queryString = (filteredQuery) =>
  _.map(filteredQuery, (value, key) => `${key}=${encodeURIComponent(value)}`)
  .join('&');

/**
 * Execute search. Builds query data and check if there are enough parameters
 * to perform search
 * @param {*} query
 * @param {func} t
 */
export function doSearch(query = {}, t) {
  const {
    limit = 10,
    skip = 0,
    sortBy = '',
    searchTerm = '',
  } = query;
  let filteredQuery = {
    ...getTypes(query, t),
    ...getDates(query),
    ...getLocation(query),
    limit,
    skip,
    sortBy,
    searchTerm
  };

  if(shouldSearch(filteredQuery)) {
    trackGoogleEvent('search', EVENT_CONTEXT, searchTerm)
    eventBus.publish('search-events', true);
    const qs = queryString(filteredQuery);
    const url = `${configs.ATHLINKS_API_HOST}/events/race/api/find?${qs}`;

    getToken().then((token) => {
      const req = { headers: { Authorization: `Bearer ${token}` } };
      streamFail(
        eventSearchStreamId,
        FetchApi.memoizeFetch(10000)(url, req),
        ({success, result}) => {
          eventBus.publish('search-events', false);
          return success ? result : {}
        }
      );
    });
  }
}

export function eventsSearching() {
  return streamHook('search-events', false);
}

/**
 * Connect to this to start receiving event search results
 * @return {Observable}
 */
export function getEventSearchStream() {
  return streamHook(eventSearchStreamId);
}

// for saving the last query executed (filters & search term)
const LAST_EVENT_SEARCH_QUERY = 'last-event-search-query';

export function getLastEventSearchQuery() {
  return streamHook(LAST_EVENT_SEARCH_QUERY, {});
}

export function setLastEventSearchQuery(query) {
  eventBus.publish(LAST_EVENT_SEARCH_QUERY, query)
}

// event results typeahead search queries
const TYPEAHEAD_SEARCH_QUERY = 'typeahead-query';
export const STARTLIST_TYPEAHEAD = 'start-list';
export const RESULTS_TYPEAHEAD = 'event-results';

export function getTypeaheadQuery(component) {
  return streamHook(`${component}-${TYPEAHEAD_SEARCH_QUERY}`, '');
}

export function setTypeaheadQuery(component, query) {
  eventBus.publish(`${component}-${TYPEAHEAD_SEARCH_QUERY}`, query);
}
