import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { configs } from '../../configs';
import { styles } from './styles';

export class BackButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
        };
    }

    mouseEnter = () => {
        this.setState({ isHover: true });
    }

    mouseLeave = () => {
        this.setState({ isHover: false });
    }

    render() {
        const { isLightMode, onBack, label } = this.props;
        const { isHover } = this.state;
        return(
            <div style={{ position: 'relative' }}>
                <img
                    style={styles.backArrow(isLightMode, isHover)}
                    src={`${configs.bragiUrl}/svg/chevron_left.svg`}
                    alt={''}
                />
                <button
                    onMouseEnter={this.mouseEnter}
                    onMouseLeave={this.mouseLeave}
                    style={styles.backButton(isLightMode, isHover)} onClick={onBack}>
                    {label}
                </button>
            </div>
        )
    }
};

BackButton.propTypes = {
    label: PropTypes.string,
    onBack: PropTypes.func,
    isLightMode: PropTypes.bool
};
