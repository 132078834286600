import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { getToken } from '../../../../utils/isLoggedIn'
import { goToPage, hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { RightChevronRegular } from '../../../shared/icons'
import { setLogLevel, LogLevel } from '../../../../utils/logger'

const mapStateToProps = () => ({
  dispatch: undefined
})

setLogLevel(LogLevel.DEBUG)

class ClaimEntryConfirmComponent extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    confirmClicked: PropTypes.func
  }

  state = {
  }

  render() {
    const { isMobile } = this.props;

    return (
      <div style={style.container(isMobile)}>
        <div className='row' style={style.detailsRow}>
          <div className={`col-12 ${isMobile ? 'p-0' : 'pl-0'}`}>
            This doesn't look like your result.
          </div>
        </div>
        <div className='row' style={style.dateLinkRow}>
          Would you still like to claim it?
        </div>
      </div>
    )
  }
}

export const ClaimEntryConfirm = compose(
  connect(mapStateToProps),
  withTranslation()
)(ClaimEntryConfirmComponent)

export function ClaimEntryConfirmFooterComponent(props) {
  // const {entryId} = useParams('/:entryId')
  // const [arp, claim] = arpHook(entryId, {
  //   fetchToken: getToken
  // })
  const {
    claim,
    dispatch,
    isMobile,
    setClaiming,
    t
  } = props

  const footerStyle = isMobile ? style.footerMobile : {}
  const footerClass = isMobile ? '' : 'row align-items-end justify-content-end'
  const nextText = isMobile ? t('Confirm') : t('Confirm Results');

  return (
    <div style={ footerStyle }>
      <div className={footerClass} style={isMobile ? {} : {marginRight: 30}}>
        <button
          disabled={false}
          onClick={() => { 
            setClaiming(false)
            dispatch(hideModal())
          }}
          style={style.cancelButton}
          tabIndex={-1}
        >
          {t('Cancel')}
        </button>
        <button
          disabled={false}
          onClick={async () => {
            const token = await getToken()
            try {
              const claimConfirmation = await claim(token, true)
              if (claimConfirmation.success) {
                dispatch(goToPage(2))
              } else {
                dispatch(goToPage(4))
              }
            } catch (err) {
              console.error(err)
              dispatch(goToPage(4))
            }
          }}
          style={style.nextButton(false, isMobile)}
        >
          <div style={ style.nextButtonInner }>
            <div style={ style.nextButtonText(isMobile) }>
              {nextText}
            </div>
            <RightChevronRegular fill='#fff' height={14} style={ style.rightChevron(isMobile) } width={14}/>
          </div>
        </button>
      </div>
    </div>
  )
}

export const ClaimEntryConfirmFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(ClaimEntryConfirmFooterComponent);

const ClaimEntryConfirmHeaderComponent = ({ t }) => {
  return (
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Confirm your claim')}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t('Yes, that is my result!')}
      </div>
    </div>
  )
}

export const ClaimEntryConfirmHeader = withTranslation()(ClaimEntryConfirmHeaderComponent)
