import React  from 'react';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { configs } from '../../configs';
import { URLUtils } from '../../utils/urlUtils';

export const Social = (props) => {
  const renderIcons = () => {
    const {
      event,
      t
    } = props;

    const facebookUrl = event.facebookUrl;
    const twitterHandle = event.twitterHandle;
    const instagramHandle = event.instagramHandle;
    const siteUri = URLUtils.safeguardExternalUrl(event.siteUri);

    const facebookIcon = facebookUrl ? (
      <div style={styles.imgContainer} className='facebookUrl'>
        <Link to={facebookUrl} target={'_blank'}>
          <img
            src={`${configs.bragiUrl}/svg/Facebook.svg`}
            alt={'Facebook'}
            style={styles.icon}
          />
        </Link>
      </div>
    ) : null;
    const twitterIcon = twitterHandle ? (
      <div style={styles.imgContainer} className='twitterHandle'>
        <Link to={twitterHandle} target={'_blank'}>
          <img
            src={`${configs.bragiUrl}/svg/Twitter.svg`}
            alt={'Twitter'}
            style={styles.icon}
          />
        </Link>
      </div>
    ) : null;
    const instagramIcon = instagramHandle ? (
      <div style={styles.imgContainer} className='instagramHandle'>
        <Link to={instagramHandle} target={'_blank'}>
          <img
            src={`${configs.bragiUrl}/svg/Instagram.svg`}
            alt={'Instagram'}
            style={styles.icon}
          />
        </Link>
      </div>
    ) : null;
    const siteLink = siteUri ? (
      <div style={{...styles.linkContainer, ...styles.nativeLink}} className='siteUri'>
        <Link to={siteUri} target='_blank' style={styles.link}>
          {t('Official Website')}
        </Link>
      </div>
    ) : null;

    return (
      <div>
        {facebookIcon}
        {twitterIcon}
        {instagramIcon}
        <div style={{clear: 'both'}}/>
        {siteLink}
      </div>
    );
  };

  const event = props.event;
  const hasSocial = event.facebookUrl
                 || event.twitterHandle
                 || event.instagramHandle
                 || event.siteUri;


  const siteUriPrintable = (event.siteUri || '').replace('https://','').replace('http://','');

  if (event.isPublished && hasSocial) {
    return (
      <div>
        {renderIcons()}
      </div>
    );
  }
  else if (event.siteUri) {
    return (
      <div>
        <div style={styles.linkContainer} className='siteUri'>
          <Link to={event.siteUri} target='_blank' style={styles.link}>{siteUriPrintable}</Link>
        </div>
      </div>
    );
  }
  else return null;
};
