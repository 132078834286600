import { DISTANCE_UNITS_TYPES, DistanceUnits, PaceUnits } from '../types.results';

export default function convertPaceUnitsToDistanceUnits(paceUnits: PaceUnits): DistanceUnits {
  if (paceUnits === 'none') {
    return 'm'
  }

  const [numerator, denominator] = paceUnits.split('/')

  if (DISTANCE_UNITS_TYPES.includes(numerator as DistanceUnits)) {
    return numerator as DistanceUnits
  }
  else if (DISTANCE_UNITS_TYPES.includes(denominator as DistanceUnits)) {
    return denominator as DistanceUnits
  }

  return 'm'
}
