
import { ofType } from 'redux-observable'
import { map, catchError } from 'rxjs/operators'
import { flow } from 'lodash'
import { UserPrefsActionTypes } from '../actions/types'
import { setUserPrefs } from '../utils/userPrefsStorageHelper'
import { setUserPrefsActionComplete, setUserPrefsActionError } from '../actions/userPrefsAction'
import { actionPayloadOnly } from '../utils/reducerHelper';

export const setUserPrefsEpic = (actions$) => {
  return actions$.pipe(
    ofType(UserPrefsActionTypes.SET_USER_PREFS),
    map(
      flow(
        actionPayloadOnly,
        setUserPrefs,
        setUserPrefsActionComplete
      )
    ),
    catchError(setUserPrefsActionError)
  )
}