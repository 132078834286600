import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { streamHook, streamFail, eventBus } from './Streams';
import { getToken } from '../utils/isLoggedIn';
import {getInitialTypes} from './SearchFilters';
import _ from 'lodash';
import { ATHLETE_CONTEXT } from './SearchContext';
import { trackGoogleEvent } from '../utils/googleEvents';

export const athleteSearchStreamId = 'AthleteSearchResults';

const availableTypes = (t) => Object.keys(getInitialTypes(false, t))
  .filter((type) => type !== 'running' && type !== 'more' && type !== 'triathlon');

/**
 * Extract location values from location element data
 * @param {*} query
 * @return {{location: string, withinRange: string}}
 */
export const getLocation = (query) => {
  const {locationFilter: {value, enabled = false} = {}} = query;
  const {location = '', range = ''} = value || {};
  if(!enabled) return {location: '', withinRange: ''};
  return { location: location.description || location, withinRange: range};
};

/**
 * Extract date range values from date range element data
 * @param {*} query
 * @return {{fromAge: string, toAge: string}}
 */
export const getAge = (query) => {
  const {ageFilter: {value, enabled = false} = {}} = query;
  const [fromAge, toAge] = value || [];
  if(!enabled) return {fromAge: '5', toAge: '90'};
  return {fromAge, toAge};
};

export const getTypes = (query, t) => {
  const {typeFilter} = query;
  if(availableTypes(t).every((type) => !typeFilter[type]))
    return getInitialTypes(true, t);
  else return typeFilter;
};

export const getGender = (query) => {
  const {genderFilter: {value = 'A', enabled = false} = {}} = query;
  if(!enabled) return {gender: ''};
  return {gender: value};
};

export const shouldSearch = (data) => {
  const {searchTerm, location, limit} = data;
  return !(
    searchTerm === '' &&
    location === '' &&
    limit < 10
  );
};

/**
 * Builds query string
 * @param {*} filteredQuery
 * @return {string}
 */
const queryString = (filteredQuery) =>
  _.map(filteredQuery, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

export async function searchAthletes(query = {}, t) {
  const {
    limit = 10,
    skip = 0,
    sortBy = '',
    searchTerm = '',
  } = query;

  let filteredQuery = {
    ...getTypes(query, t),
    ...getGender(query),
    ...getAge(query),
    ...getLocation(query),
    limit,
    skip,
    sortBy,
    searchTerm
  };
  if(shouldSearch(filteredQuery)) {
    trackGoogleEvent('search', ATHLETE_CONTEXT, searchTerm)
    const token = await getToken();
    //We send a custom header if requested, for testing purposes
    const adpTestHeader = window.adpTest ? {'ADP-Test': true} : {};
    const reqHeaders = Object.assign({},  { Authorization: `Bearer ${token}` }, adpTestHeader);

    const qs = queryString(filteredQuery);
    const url = `${configs.ATHLINKS_API_HOST}/athletes/api/find?${qs}`;
    const req = { headers: reqHeaders };
    streamFail(
      athleteSearchStreamId,
      FetchApi.memoizeFetch(10000)(url, req),
      ({success, result}) => {
        return success && (result || {})
      }
    );
  }
}

/**
 * Connect to this to start receiving event search results
 * @return {Observable}
 */
export function getAthleteSearchStream() {
  return streamHook(athleteSearchStreamId);
}

// for saving the last query executed (filters & search term)
const LAST_PROFILE_SEARCH_QUERY = 'last-profile-search-query';

export function getLastProfileSearchQuery() {
  return streamHook(LAST_PROFILE_SEARCH_QUERY, {});
}

export function setLastProfileSearchQuery(query) {
  eventBus.publish(LAST_PROFILE_SEARCH_QUERY, query)
}
