import React from 'react';
import { withTranslation } from 'react-i18next';
import { _filterTab } from './styles';

const FilterControls = ({ onReset, onSave, style, t }) => {
  return (
    <div
      style={{ ...style, ..._filterTab.controlsWrap }} className="controls">
      <button key="reset" onClick={onReset} style={_filterTab.resetButton}>
        {t('RESET')}
      </button>
      <button key="save" onClick={onSave} style={_filterTab.applyButton}>
        {t('APPLY')}
      </button>
    </div>
  )
};

export default withTranslation()(
    FilterControls
);
