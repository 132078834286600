import FetchApi from './FetchApi';
import { Configure } from './Configure';
import eventBus from '../event-bus-rx';

function _getHeaders() {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
}

function _query(streamId, url, fetcher) {
  const results = eventBus.getTopic(streamId);

  const req = {
    method: 'get',
    headers: _getHeaders(),
    compress: true
  };

  fetcher(url, req).subscribe((json) => {
    if (json.error) {
      eventBus.publish(
        streamId,
        { isLoading: false, isError: false, error: json.error }
      );
    }
    else {
      eventBus.publish(
        streamId,
        Object.assign({ isLoading: false, isError: false }, json)
      );
    }
  });

  eventBus.publish(
    streamId,
    { isLoading: true }
  );

  return results;
}

const ChronotrackApi = {
  query: (streamId, path) => {
    const url = `${Configure.getConfigs().chronotrackApiUrl}/${path}`;

    return _query(streamId, url, FetchApi.memoizeFetch(3000));
  },
  memoizeQuery: (streamId, path) => {
    const url = `${Configure.getConfigs().chronotrackApiUrl}/${path}`;
    return _query(streamId, url, FetchApi.memoizeFetch());
  }
};

export default ChronotrackApi;
