import React from 'react';
import { Avatar, Grid, makeStyles, Typography, Hidden, Link } from '@material-ui/core';
import { configs } from '../../configs';
import { toNameCase } from '../../utils/stringUtils';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
  },
  avatar: {
    backgroundColor: (isMember) => isMember
      ? '#3DB8EB'
      : '#CACACA',
      // ? `url('${configs.cdnUrl}/images/default-athlete-blue.jpg')`
      // : `url('${configs.cdnUrl}/images/default-athlete-gray.jpg')`,
    backgroundSize: 'contain',
    color: (isMember) => isMember ? 'white' : 'black',
  },
  nameRow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '1.125rem',
  },
  teamRow: {
    textOverflow: 'ellipsis',
    marginTop: 8,
  },
  teamText: {
    color: 'grey',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  detailRow: {
    textOverflow: 'ellipsis',
  },
  detailCell: {
    '&::after': {
      color: 'grey',
      fontSize: '0.75rem',
      content: '"|"',
      paddingLeft: 8,
      paddingRight: 8,
    },
    '&:last-child': {
      '&::after': {
        content: '""',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  detailText: {
    color: 'grey',
    fontSize: '0.75rem',
  },
}))

export const ProfileRenderer = ({
  age,
  avatarUrl,
  bib,
  city,
  country,
  displayName,
  gender,
  href,
  isMember = false,
  isPrivate = false,
  nameOnly = false,
  racerId,
  region,
  team,
  teamLink,
  useItemScope = false,
}) => {

  const styles = useStyles(isMember)

  const itemScope = {}
  if (useItemScope) {
    itemScope.itemScope = true
    itemScope.itemType = 'http://schema.org/Person'
  }

  const initials = displayName?.split(/\s+/g)
    .filter((x, i, a) => x.trim().length && (i === 0 || i === a.length - 1))
    .map((x) => x.trim().toUpperCase()[0])
    .join('')

  const location = [
    toNameCase(city),
    region?.toUpperCase(),
    country?.toUpperCase(),
  ]
  .filter(_ => !!_)
  .join(', ')

  const ageGender = <>
    {gender ? <span itemProp="gender" style={{marginRight: 4}}>{gender.toUpperCase()}</span> : null}
    {age > 0 ? <span>{age}</span> : null}
  </>

  const bibLabel = bib ? <span itemProp="identifier">Bib: {bib}</span> : null

  const athleteMeta = [].concat(ageGender).concat(bibLabel).filter(_ => !!_)


  let $avatarUrl = undefined
  if (!isPrivate && isMember && avatarUrl?.trim().length) {
    const size = avatarUrl.match(/^.*_(.).*/)[1] || 't'
    const racerBucket = Math.floor(racerId / 1000) * 1000;
    $avatarUrl = `${configs.imgixPhotosUrl}/Racers/${racerBucket}/${size}/${avatarUrl}`;
  }

  const metaGridItem = (content, idx) => {
    return (
      <Grid key={`athlete-meta-${idx}`} item className={styles.detailCell}>
         <Typography variant="caption" className={styles.detailText}>
          {content}
        </Typography>
      </Grid>
    )
  }

  let metaItems = [].concat(athleteMeta)
  if (location) metaItems.push(
    <div style={{display: 'inline-block'}}>
      <address itemProp="homeLocation">{location}</address>
    </div>
  )
  metaItems = metaItems.map(metaGridItem)

  return (
    <Grid container direction="row" spacing={1}
      className={styles.root}
      alignContent="space-around" wrap="nowrap"
      {...itemScope}>
      <Grid item>
        <Avatar itemProp="image" className={styles.avatar} alt={displayName} src={$avatarUrl}>{initials}</Avatar>
      </Grid>
      <Grid item container direction="row" xs alignContent="space-around">
        <Grid item xs={12} className={styles.nameRow}>
          <Typography variant="h2" className={styles.nameText}
            itemProp="name">{displayName}</Typography>
        </Grid>
        <Hidden mdUp>
          <>
            {team?.length ? (
              <Grid item xs={12} className={styles.teamRow}>
                <Typography variant="h3" className={styles.teamText}
                  itemProp="name">Team: <Link component={RouterLink} to={teamLink}>{team}</Link></Typography>
              </Grid>
            ) : null}
            <Grid item container xs={12} className={styles.detailRow} spacing={0} alignContent={"flex-start"}>
              {metaItems}
            </Grid>
          </>
        </Hidden>
      </Grid>
    </Grid>
  )
}