import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { setLogLevel, LogLevel } from '../../../../utils/logger'

const mapStateToProps = () => ({
  dispatch: undefined
})

setLogLevel(LogLevel.DEBUG)

export function UnfollowAthleteConfirmFooterComponent(props) {
  const {
    cancel,
    dispatch,
    isMobile,
    unfollow,
    t
  } = props

  const footerStyle = isMobile ? style.footerMobile : {}
  const footerClass = isMobile ? '' : 'row align-items-end justify-content-end'
  const nextText = t('Unfollow')

  return (
    <div style={ footerStyle }>
      <div className={footerClass} style={isMobile ? {} : {marginRight: 30}}>
        <button
          disabled={false}
          onClick={() => {
            cancel()
            dispatch(hideModal())
          }}
          style={style.cancelButton}
          tabIndex={-1}
        >
          {t('Cancel')}
        </button>
        <button
          disabled={false}
          onClick={async () => {
            await unfollow() 
          }}
          style={style.nextButton(false, isMobile)}
        >
          <div style={ style.nextButtonInner }>
            <div style={ style.nextButtonText(isMobile) }>
              {nextText}
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export const UnfollowAthleteConfirmFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteConfirmFooterComponent);

const UnfollowAthleteConfirmHeaderComponent = ({ athleteCardModel: acm, t }) => {
  return (
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalUnfollowTitle }>
        {t(`Are you sure you want to unfollow ${acm.firstName}?`)}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t(`You will no longer get race page updates or notifications from ${acm.firstName} ${acm.lastName}.`)}
      </div>
    </div>
  )
}

export const UnfollowAthleteConfirmHeader = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteConfirmHeaderComponent)
