import { styles as buttonStyles } from '../button/styles';
import { colors } from '../../shared/styles';

export const styles = {
  container: {
    backgroundColor: colors.white,
    borderColor: colors.lightGrey4,
    borderStyle: 'solid',
    boxShadow: '0px 2px 4px 0px rgba(58, 86, 109, 0.15)',
    borderWidth: '1px',
    marginBottom: '24px',
    padding: '24px 8px',
  },
  nativeLinkStyle: {
    ...buttonStyles.orange,
    width: 'auto',
    textAlign: 'center',
    display: 'block',
    textDecoration: 'none'
  },
  nativeLinkWrap: (isMobile) => ({
    marginBottom: isMobile ? '16px' : null
  }),
  mobile: {
    container: {
      boxShadow: 'initial',
      marginBottom: '16px',
      border: 'none'
    }
  }
};
