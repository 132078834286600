export const InternalClientActionType = {
  TEST_CONNECTION: 'TEST_CONNECTION',
  TEST_CONNECTION_COMPLETE: 'TEST_CONNECTION_COMPLETE',
  TEST_CONNECTION_FAILED: 'TEST_CONNECTION_FAILED',

  GET_EVENT_RESULTS_DATA: 'GET_EVENT_RESULTS_DATA',
  GET_EVENT_RESULTS_DATA_STARTED_POLLING: 'GET_EVENT_RESULTS_DATA_STARTED_POLLING',
  GET_EVENT_RESULTS_DATA_STOP_POLLING: 'GET_EVENT_RESULTS_DATA_STOP_POLLING',
  GET_EVENT_RESULTS_DATA_STOPPED_POLLING: 'GET_EVENT_RESULTS_DATA_STOPPED_POLLING',
  GET_EVENT_RESULTS_DATA_UPDATE: 'GET_EVENT_RESULTS_DATA_UPDATE',
  GET_EVENT_RESULTS_ERROR: 'GET_EVENT_RESULTS_ERROR',

  GET_INDIVIDUAL_RESULTS_DATA: 'GET_INDIVIDUAL_RESULTS_DATA',
  GET_INDIVIDUAL_RESULTS_DATA_STARTED_POLLING:
    'GET_INDIVIDUAL_RESULTS_DATA_STARTED_POLLING',
  GET_INDIVIDUAL_RESULTS_DATA_STOP_POLLING:
    'GET_INDIVIDUAL_RESULTS_DATA_STOP_POLLING',
  GET_INDIVIDUAL_RESULTS_DATA_STOPPED_POLLING:
    'GET_INDIVIDUAL_RESULTS_DATA_STOPPED_POLLING',
  GET_INDIVIDUAL_RESULTS_DATA_UPDATE: 'GET_INDIVIDUAL_RESULTS_DATA_UPDATE',
  GET_INDIVIDUAL_RESULTS_ERROR: 'GET_INDIVIDUAL_RESULTS_ERROR',

  GET_EVENT_SUMMARY: 'GET_EVENT_SUMMARY',
  GET_EVENT_SUMMARY_COMPLETE: 'GET_EVENT_SUMMARY_COMPLETE',
  GET_EVENT_SUMMARY_ERROR: 'GET_EVENT_SUMMARY_ERROR',

  GET_MEDIA_DATA: 'GET_MEDIA_DATA',
  GET_MEDIA_DATA_STARTED_POLLING: 'GET_MEDIA_DATA_STARTED_POLLING',
  GET_MEDIA_DATA_STOP_POLLING: 'GET_MEDIA_DATA_STOP_POLLING',
  GET_MEDIA_DATA_STOPPED_POLLING: 'GET_MEDIA_DATA_STOPPED_POLLING',
  GET_MEDIA_DATA_UPDATE: 'GET_MEDIA_DATA_UPDATE',
  GET_MEDIA_ERROR: 'GET_MEDIA_ERROR',

  GET_META_DATA: 'GET_META_DATA',
  GET_META_DATA_COMPLETE: 'GET_META_DATA_COMPLETE',
  GET_META_DATA_ERROR: 'GET_META_DATA_ERROR',

  SUBSCRIBE_ERROR: 'SUBSCRIBE_ERROR',
  UNSUBSCRIBE_ERROR: 'UNSUBSCRIBE_ERROR'
}


export const ResultActionTypes = {
  GET_META: 'GET_META',
  GET_META_COMPLETE: 'GET_META_COMPLETE',
  GET_META_ERROR: 'GET_META_ERROR',
  GET_EVENT_SUMMARY: 'GET_EVENT_SUMMARY',
  GET_EVENT_SUMMARY_COMPLETE: 'GET_EVENT_SUMMARY_COMPLETE',
  GET_EVENT_SUMMARY_ERROR: 'GET_EVENT_SUMMARY_ERROR',
  GET_EVENT_RESULTS: 'GET_EVENT_RESULTS',
  GET_EVENT_RESULTS_UPDATE: 'GET_EVENT_RESULTS_UPDATE',
  GET_EVENT_RESULTS_SUBSCRIBED: 'GET_EVENT_RESULTS_SUBSCRIBED',
  GET_EVENT_RESULTS_STOP: 'GET_EVENT_RESULTS_STOP',
  GET_EVENT_RESULTS_ERROR: 'GET_EVENT_RESULTS_ERROR',
  GET_INDIVIDUAL_RESULTS: 'GET_INDIVIDUAL_RESULTS',
  GET_INDIVIDUAL_RESULTS_UPDATE: 'GET_INDIVIDUAL_RESULTS_UPDATE',
  GET_INDIVIDUAL_RESULTS_SUBSCRIBED: 'GET_INDIVIDUAL_RESULTS_SUBSCRIBED',
  GET_INDIVIDUAL_RESULTS_STOP: 'GET_INDIVIDUAL_RESULTS_STOP',
  GET_INDIVIDUAL_RESULTS_ERROR: 'GET_INDIVIDUAL_RESULTS_ERROR',
  GET_MEDIA: 'GET_MEDIA',
  GET_MEDIA_UPDATE: 'GET_MEDIA_UPDATE',
  GET_MEDIA_SUBSCRIBED: 'GET_MEDIA_SUBSCRIBED',
  GET_MEDIA_STOP: 'GET_MEDIA_STOP',
  GET_MEDIA_ERROR: 'GET_MEDIA_ERROR'
}