import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { EventList } from './EventsList';
import { styles } from './styles';
import { filterStyles, transition }  from '../../shared/styles';
import { Pagination } from '../../components/shared/Pagination';
import { NotFound } from '../../views/notFound/NotFound';
import { SearchPageHeader } from '../../components/shared/Elements';
import {getLocation} from '../../data/SearchStreams';
import {
  getEventSearchStream,
  doSearch,
  setLastEventSearchQuery,
  eventsSearching
} from '../../data/EventSearchStream';
import _ from 'lodash';

class EventSearchComponent extends Component {

  static propTypes = {
    results: PropTypes.object,
    filterValues: PropTypes.object,
    startList: PropTypes.object,
    searchTerm: PropTypes.string,
    lastEventSearchQuery: PropTypes.object,
    isMobile: PropTypes.bool,
    t: PropTypes.func
  };

  state = {
    page: 1,
    processing: false,
    initialLocation: false,
    locationProcessing: false,
    willSearch: false,
    canSearch: _.size(this.props.searchTerm) > 3 || this.locationFilterValid(this.props.filterValues.locationFilter) || this.dateRangeFilterValid(this.props.filterValues.dateRangeFilter)
  };

  /**
   * @TODO load more should also store value in search filter stream
   */
  loadMore = () => this.setState({page: this.state.page + 1}, () => {
    const {
      filterValues,
      searchTerm
    } = this.props;

    const {page} = this.state;
    this.search({...filterValues, limit:  page * 10, searchTerm});
  });

  componentDidUpdate(prevProps) {
    const {
      filterValues: prevFilterValues,
      searchTerm: prevSearchTerm
    } = prevProps;

    const {
      filterValues,
      searchTerm
    } = this.props;

    if(prevFilterValues && this.didFilterChange(prevFilterValues)) {
      this.search({...filterValues, searchTerm});
    }
    else if(prevSearchTerm !== searchTerm) {
      this.searchDebounced({...filterValues, searchTerm});
    }
  }

  componentDidMount() {
    const {
      filterValues,
      searchTerm
    } = this.props;
    this.search({...filterValues, searchTerm});
  }

  /**
   * Check if filters are changed
   * @param {*} oldFilterValues
   * @return {boolean}
   */
  didFilterChange = (oldFilterValues) => {
    const {
      filterValues
    } = this.props;

    return !_.isEqual(oldFilterValues, filterValues);
  };

  /**
   * Perform search
   * @param query
   */
  search = (query) => {
    const {
      searchTerm,
      filterValues,
      lastEventSearchQuery,
      results,
      t
    } = this.props;
    const {limit} = query;
    const completeQuery = _.assign({}, filterValues, {searchTerm});
    const { locationFilter = {} } = filterValues;
    const stopSearch = locationFilter.enabled && !locationFilter.value.location;
    if (stopSearch) {
      this.setState({willSearch: false});
    }
    else if (((_.isEmpty(results) || !_.isEqual(completeQuery, lastEventSearchQuery)) && this.state.canSearch) || limit > 10) {
      this.setState({processing: true, willSearch: true, locationProcessing: false});
      setLastEventSearchQuery(completeQuery);
      doSearch(query, t);
    } else {
      this.setState({willSearch: false, processing: false});
    }
  }
  
  searchDebounced = _.debounce(this.search, 1000, {
    leading: false,
  });

  // are the date range filter settings valid to use in a query
  dateRangeFilterValid(filter = {enabled: false}) {
    return filter.enabled && filter.value && (!!filter.value.from || !!filter.value.to);
  }

  // are the location filter settings valid to use in a query
  locationFilterValid(filter = {enabled: false}) {
    return filter.enabled && filter.value && !!filter.value.location;
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const {filterValues: {locationFilter = {}, dateRangeFilter = {}}} = this.props;
    const nextLocationFilter = nextProps.filterValues.locationFilter || {};
    const nextDateRangeFilter = nextProps.filterValues.dateRangeFilter || {};

    if(nextLocationFilter.enabled && nextLocationFilter.value !== locationFilter.value) {
      this.setState({initialLocation: true});
    }

    if(_.isEqual(nextProps.results, this.props.results) && (this.state.willSearch || this.state.locationProcessing)) {
      this.setState({processing: true});
    } else {
      this.setState({processing: false, willSearch: false});
    }

    if(nextProps.searchTerm !== this.props.searchTerm ||
       nextLocationFilter.enabled !== locationFilter.enabled ||
       (nextLocationFilter.value || {}).location !== (locationFilter.value || {}).location ||
       nextDateRangeFilter.enabled !== dateRangeFilter.enabled ||
       (nextDateRangeFilter.value || {}).from !== (dateRangeFilter || {}).from ||
       (nextDateRangeFilter.value || {}).to !== (dateRangeFilter || {}).to) {
      this.setState({
        canSearch: _.size(nextProps.searchTerm) > 3 || this.locationFilterValid(nextLocationFilter) || this.dateRangeFilterValid(nextDateRangeFilter)
      });
    }

  }

  componentWillUnmount() {
    this.searchDebounced.cancel();
    this.unmounted = true;
  }

  buildSearchPageHeaderFilters = () => {
    const {filterValues} = this.props;
    return getLocation(filterValues);
  };

  onSearchingCurrentLocation = (isSearching) => {
    this.setState({processing: isSearching, locationProcessing: isSearching});
  };

  render() {
    const {
      error,
      page,
      canSearch,
    } = this.state;

    const {
      searchTerm,
      isMobile,
      results: {events, total} = {},
      filterValues: {locationFilter, dateRangeFilter},
      eventsSearching,
      t
    } = this.props;

    const errorStatus = error && 503;

    const noValidLocDateFilters = !this.locationFilterValid(locationFilter)
      && !this.dateRangeFilterValid(dateRangeFilter);
    const searchMessage = noValidLocDateFilters
      ? (_.size(searchTerm) === 0
        ? t('Enter an event name in the search box above or choose a '
          + 'location or date range to begin searching for events')
        : t('Please narrow your search term or choose a location or date '
          + 'range to search for events'))
      : '';

    if(error){
      return (
        <NotFound errorStatus={errorStatus} />
      )
    }

    const LoaderToShow = isMobile ? LoadingEventMobile : LoadingEvent;
    const limit = page * 10;
    const loadingNum = (page-1 || 0.5) * 10;
    const limitLengthArray = Array.from(Array(loadingNum));

    return (
      <div style={filterStyles.contentContainer}>
        <div className="container" style={filterStyles.content}>
          <div className="row">
            <div
              className="col-md-12 col-lg-12"
              key="total-records-div"
              style={styles.searchPageHeader}
            >
              <SearchPageHeader
                isMobile={isMobile}
                filterStyles={filterStyles}
                processing={eventsSearching}
                styles={styles}
                total={canSearch ? total : 0}
                searchTerm={searchTerm}
                filters={this.buildSearchPageHeaderFilters()}
                title={t('Events')}
                hasResults={canSearch}
                showButtonToAddNew={true}
                message={searchMessage}
              />

              <div style={ transition.fade(!eventsSearching) }>
                { canSearch && events &&
                  <div key="event-list-div">
                    <EventList
                      key="event-list"
                      isMobile={isMobile}
                      filters={this.state.filters}
                      events={events}
                      t={t}
                    />
                    <Pagination
                      key="pagination"
                      total={total}
                      isMobile={isMobile}
                      limit={limit}
                      skip={0}
                      processing={eventsSearching}
                      onChange={this.loadMore}
                      t={t}
                    />
                  </div>
                }
              </div>

              {
                // Loading placeholder
              }
              {eventsSearching &&
                <div style={styles.loadingContainer(eventsSearching)}>
                  {
                    limitLengthArray.map((item, index) =>
                      <LoaderToShow key={`loader-${index}`} />
                    )
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const EventSearch = withTranslation()(connectStream({
  results: getEventSearchStream,
  eventsSearching
})(EventSearchComponent));

const LoadingEvent = () => (
  <div style={styles.loadingRow}>
    <div style={styles.loadingTitle}/>
    <div style={styles.loadingSubtitle}/>
    <div style={styles.loadingDetailContainer}>
      <div style={styles.loadingDetailCellOne}>
        <div style={styles.loadingSubtitle}/>
        <div style={styles.loadingSubtitle}/>
      </div>
      <div style={styles.loadingDetailCellTwo}>
        <div style={styles.loadingSubtitle}/>
      </div>
    </div>
  </div>
);

const LoadingEventMobile = () => (
  <div style={styles.loadingRowMobile}>
    <div style={styles.loadingTitle}/>
    <div style={styles.loadingDetailContainerMobile}>
      <div style={styles.loadingCellOneMobile}/>
      <div style={styles.loadingCellTwoMobile}/>
      <div style={styles.loadingCellThreeMobile}/>
    </div>
    <div style={styles.loadingSubtitle}/>
  </div>
);
