import Axios from 'axios'
import { configs } from '../configs'
import { logInfo } from './Logging'
import { getCORSConfig } from './getCORSConfig'

export const claimARP = (
  {
    entryId
  },
  token,
  force = false
) => new Promise(async (resolve, _reject) => {
  const data = {
    entryId,
    force
  }
  const url = `${configs.athleteApiUrl}/claims/pending`
  logInfo('hitting', url)
  Axios.post(url, data, getCORSConfig(token))
    .then(response => {
      if (response.status === 202) {
        resolve({ success: true })
      }
      resolve({
        success: false,
        isStrongMatch: response.data.isStrongMatch,
        pendingClaimExists: response.data.pendingClaimExists
      })
    }).catch((error) => {
      if (error.response?.status === 403) {
        resolve({ success: false, notLoggedIn: true })
      }

      resolve({
        success: false,
        error: `Claim failed for entryId "${entryId}": "${error.response?.data}"`
      })
    })
})