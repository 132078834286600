import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { FollowStream } from '../../data/FollowStream';
import { headerStyles } from './styles';
import { SocialShare } from '../shared/SocialShare';

const availableTypes = {
  overview: '',
  results: '/results',
  following: '/following',
  rivals: '/rivals',
  statistics: '/statistics'
};

const StyledBadge = withStyles({
  badge: {
    color: '#FFFFFF',
    fontFamily: 'ProximaNovaBold',
    fontSize: 9,
    height: 14,
    minWidth: 14,
    right: -3,
    top: -3,
    width: 14,
  },
  colorPrimary: {
    backgroundColor: '#BC3A3C'
  }
})(Badge)

class HeaderComponent extends Component {
  static propTypes = {
    athlete: PropTypes.object.isRequired,
    athleteId: PropTypes.string.isRequired,
    fetchAthlete: PropTypes.func.isRequired,
    isFriend: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isMe: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    summary: PropTypes.object,
    t: PropTypes.func,
    unclaimedResultsCount: PropTypes.number
  };

  static defaultProps = {
    unclaimedResultsCount: 0,
  };

  state = {
    leftShadow: false,
    rightShadow: true,
    hoverItem: null,
  };

  /**
   * Follow/unfollow athlete
   */
  updateFollowing = () => {
    FollowStream.setFollow(this.props.isFriend, this.props.athleteId);
  };

  attachOnScroll = (node) => {
    node && node.addEventListener('scroll', () => {
      const left = node.scrollLeft;
      let leftShadow = left > 10,
        rightShadow = left < 150;
      this.setState({leftShadow, rightShadow});
    });
  };

  renderTabLink = (type) => {
    let badgeContent = 0, showBadge = false;
    if(type === 'results') {
      const {unclaimedResultsCount} = this.props;
      badgeContent = unclaimedResultsCount < 10 ? unclaimedResultsCount : '!';
      showBadge = unclaimedResultsCount > 0;
    }
    if(!showBadge) return this.props.t(`athleteTabs#${type}`);
    else return (
      <StyledBadge
        badgeContent={badgeContent}
        color={'primary'}
        overlap={'circle'}
      >
        {this.props.t(`athleteTabs#${type}`)}
      </StyledBadge>
    );
  };

  render() {
    const {
      athlete,
      athleteId,
      isMe,
      isFriend,
      isLoggedIn,
      isMobile
    } = this.props;
    const {
      leftShadow,
      rightShadow,
      hoverItem
    } = this.state;

    const buttonsProps = {
      isMe,
      isLoggedIn,
      isFriend,
      isMobile,
      updateFollowing: this.updateFollowing,
      athlete,
      athleteId
    };

    return (
      <div className="container">
        {
          isMobile ?
          <div className="row" style={headerStyles.wrapperMobile}>
            {leftShadow && <MobileMenuShadow side="left"/>}
            <div
              ref={this.attachOnScroll}
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              style={headerStyles.overflowStyle}
            >
              <div
                style={headerStyles.mobileRowWrap}>
                {
                  Object
                    .keys(availableTypes)
                    .map((type, index) => {
                      return (
                        <NavLink
                          id={`${type}-tab`}
                          key={index}
                          exact to={`/athletes/${athleteId}${availableTypes[type]}`}
                          style={headerStyles.scrollMenuA}
                          activeStyle={headerStyles.active}
                        >
                          {this.renderTabLink(type)}
                        </NavLink>
                      );
                    })
                }
              </div>
            </div>
            {rightShadow && <MobileMenuShadow side="right"/>}
          </div>
          :
          <div className="row">
            <div className="col-9" style={headerStyles.tabs} id="navigation">
              {
                Object
                  .keys(availableTypes)
                  .map((type, index) => {
                    return (
                      <NavLink
                        id={`${type}-tab`}
                        key={index}
                        exact to={`/athletes/${athleteId}${availableTypes[type]}`}
                        style={headerStyles.menuItem(hoverItem === type)}
                        activeStyle={headerStyles.active}
                        onMouseEnter={ () => this.setState({hoverItem:type}) }
                        onMouseLeave={ () => this.setState({hoverItem:null}) }>
                        {this.renderTabLink(type)}
                      </NavLink>
                    );
                  })
              }
            </div>
            <div className="col-3" style={headerStyles.navRightSide} id="header-social">
              { athlete.RacerID &&
                <SocialShare
                  buttonsProps={buttonsProps}
                  athlete={athlete}
                  followButton={true}
                  isMobile={isMobile}
                />
              }
            </div>
          </div>
        }
      </div>
    );
  };
}

export const Header = withTranslation()(HeaderComponent);

/**
 * Shadow for mobile navigation  menu
 * @param side
 * @returns {XML}
 * @constructor
 */
export const MobileMenuShadow = ({side}) => {
  if (side === 'left') {
    return (
      <div style={headerStyles.mobileShadow(true)}/>
    );
  } else {
    return (
      <div style={headerStyles.mobileShadow()}/>
    );
  }

};
