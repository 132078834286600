import { fonts, colors } from '../../shared/styles';

export const followButton = {
    cursor: 'pointer',
    lineHeight:'15px',
    letterSpacing: '.7px',
    textTransform: 'uppercase',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: 'ProximaNovaRegular',
    borderRadius:'2px',
    color: colors.white,
    fontSize: '11px',
    backgroundColor: colors.blue
};

export const friendButtons = {
    isFriendButton: {
        ...followButton,
        color: colors.blue,
        border: `1px solid ${colors.blue}`,
        background: colors.white
    },
    notFriendButton: {
        ...followButton,
        color: colors.white,
        background: colors.blue
    },
};

export const claimResult = {
    onErpButton: (canClick) => ({
        backgroundColor: 'transparent',
        border: 'none',
        color: colors.blue,
        cursor: (canClick) ? 'pointer' : 'default',
        fontSize: 12,
        paddingTop: '12px',
        paddingBottom: '12px'
    }),
    defaultClaim: (canClick, isStickyButton, isDisabled = false, claimed = false) => ({
        width: '100%',
        padding: '9px 15px 9px',
        textAlign: 'center',
        ...fonts.default,
        fontSize: 16,
        color: isDisabled ? colors.greyLine : (claimed ? colors.blue : colors.white),
        borderRadius: 2,
        border: '1px solid '+ (isDisabled ? colors.greyLine : colors.blue),
        backgroundColor: claimed || isDisabled ? colors.white : colors.blue,
        cursor: canClick ? 'pointer' : 'default',
        textTransform: isStickyButton ? 'uppercase' : ''
    })
};

export const follow = {
    button: {
        position: 'relative',
        display: 'inline-block',
        border: 'none',
        boxShadow: 'none',
        padding: '9px 30px 7px 45px',
        ...fonts.heavy,
        textAlign: 'center',
        fontSize: 13,
        color: colors.white,
        textTransform: 'uppercase',
        letterSpacing: '0.5px',
        backgroundColor: colors.blue,
        borderRadius: 2,
        cursor: 'pointer',
    },
    plusSign: {
        position: 'absolute',
        top: '50%',
        left: 23,
        transform: 'translateY(-50%)',
        display: 'inline-block',
        height: 16,
        width: 16,
    }
};
