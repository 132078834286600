import React, { useState } from 'react'

const ReigniteServerSentEvents = ({
  onMessage,
  path
}: {
  onMessage: (data: any) => void
  path: string
}) => {
  const [eventSource, setEventSource] = useState<EventSource | null>(null)
  const [eventListener, setListener] = useState<globalThis.EventListener>()

  React.useEffect(() => {
    if (!path) {
      console.warn('no eventsource path provided!')
      return
    }
    setEventSource(oldEvtSrc => {
      let evtSrc = oldEvtSrc

      if (!oldEvtSrc?.url.endsWith(path)) {
        oldEvtSrc?.close()

        evtSrc = new EventSource(`${process.env.REACT_APP_REIGNITE_SSE_URL}/${path}`, { withCredentials: true })
      }

      setListener((oldListener: any) => {
        evtSrc?.removeEventListener('message', oldListener)

        const listener = (msg: MessageEvent<any>): void => {
          try {
            console.debug('Received SSE message: ', msg)

            const parsed = JSON.parse(msg.data)

            onMessage(parsed)
          } catch (err) {
            console.error(err)
          }
        }
        evtSrc?.addEventListener('message', listener)

        return listener
      })

      return evtSrc
    })
  }, [onMessage, path])


  React.useEffect(() => () => {
    eventSource?.removeEventListener('message', eventListener!)
    eventSource?.close()
  }, [eventListener, eventSource])

  return (
    <div />
  )
}

export default ReigniteServerSentEvents
