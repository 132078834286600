import _ from 'lodash';

const pageButton = {
  margin: 5,
  padding: '12px 16px',
  color: '#00a8e3',
  backgroundColor: '#fff',
  border: 'solid 1px #b3c1ce',
  cursor: 'pointer'
};

export const styles = {
  buttonContainer: {
    float: 'left'
  },
  pageButton,
  pageButtonArrow: _.assign({}, pageButton, {
    paddingLeft: 8,
    paddingRight: 8
  }),
  pageButtonSelected: _.assign({}, pageButton, {
    color: '#fff',
    backgroundColor: '#3cb8eb'
  })
};
