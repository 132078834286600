import React from 'react';
import PropTypes from 'prop-types';
import {aoStyles} from './styles';
import { Link } from 'react-router-dom';
import {PersonalRecords} from './PersonalRecords';
import {StatisticsSummary} from './Statistics';
import {Avatar} from './Avatar';
import {SocialShare} from '../shared/SocialShare';
import {getDisplayLocation} from '../../utils/location';

export const AthleteOverview = (
  {
    athlete,
    athleteId,
    summary: {summary = {}, fetching: summaryFetching},
    fetching,
    isMe,
    isFriend,
    isLoggedIn,
    isMobile,
    windowWidth,
    t
  }) => {
  const {distanceSummary = []} = summary;
  return (
    <div style={ aoStyles.overviewContainer }>
      <div className='container'>
        <div className='row' id="athlete-overview-header">

          <div style={ aoStyles.photoCol(isMobile) }>
            <Avatar
              athlete={athlete}
              fetching={fetching}
              isMe={isMe}
              isLoggedIn={isLoggedIn}
              isMobile={isMobile}
              windowWidth={windowWidth}
            />
          </div>

          <div style={ aoStyles.overviewCol }>
            <Identity
              {...athlete}
              fetching={fetching}
              isMobile={isMobile}
              t={t}
            />
            {
              !isMobile ?
              <div className="row">
                <div className="col-12 col-lg-5">
                  <Link to={`/athletes/${athleteId}/statistics`} id="header-personal-record">
                    <PersonalRecords
                      type="header"
                      distanceSummary={distanceSummary}
                      perRow={3}
                      isMobile={isMobile}
                      fetching={summaryFetching}
                      t={t}
                    />
                  </Link>
                </div>
                <div className="col-12 col-lg-5">
                  <StatisticsSummary
                    distanceSummary={distanceSummary}
                    {...summary.resultsSummary}
                    direction="horizontal"
                    isMobile={isMobile}
                    fetching={summaryFetching}
                    t={t}
                  />
                </div>
              </div>
              :
              <SocialShare
                athlete={athlete}
                followButton={true}
                isMobile={isMobile}
                buttonsProps={{
                  isMe,
                  isLoggedIn,
                  isFriend,
                  isMobile,
                  athlete,
                  athleteId
                }}
              />
            }
          </div>

        </div>
      </div>
    </div>
  );
};

AthleteOverview.propTypes = {
  athleteId: PropTypes.string.isRequired,
  athlete: PropTypes.object.isRequired,
  fetching: PropTypes.bool,
  summary: PropTypes.object,
  isMe: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  fetchAthlete: PropTypes.func.isRequired,
  setAthletePhoto: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  windowWidth: PropTypes.number,
  t: PropTypes.func
};

AthleteOverview.defaultProps = {
  summary: {
    summary: {
      distanceSummary: []
    }
  },
  windowWidth: '',
  fetching: false,
};

/**
 * Prints athlete identity information.
 *
 * @param DisplayName
 * @param Gender
 * @param Age
 * @param City
 * @param StateProvAbbrev
 * @param CountryID3
 * @param fetching
 * @return {XML}
 * @constructor
 */
export const Identity = ({DisplayName, Gender, Age, City, StateProvAbbrev, CountryID, fetching, t}) => {
  const { identityStyles, loading } = aoStyles;

  return (
    <div style={identityStyles.container}>
      <h1
        id='athlete-name'
        style={identityStyles.title}>
        {DisplayName}
      </h1>
      <div style={identityStyles.ageAndIconsBar}>
        <div style={identityStyles.personalFirst}>
          {t(`genderMap#${Gender}`)}, {Age}
        </div>
        <div style={identityStyles.personal}>
          {getDisplayLocation(City, StateProvAbbrev, CountryID)}
        </div>
      </div>
      {
        // Loading placeholder
      }
      <div style={loading.identityContainer(fetching)}>
        <div style={loading.name(fetching)}/>
        <div style={loading.nameDetails(fetching)}/>
      </div>
    </div>
  );
};
