import React, { Component } from 'react';
import {withTranslation} from 'react-i18next';
import { TermsOfServiceModal } from '../modal/TermsOfServiceModal';
import { colors } from '../../shared/styles';

class TermsOfServiceInPageComponent extends Component {
  state = {
    open: false,
    success: false
  };

  handleOpen = () => this.setState({open: true});

  handleClose = () => this.setState({open: false});

  onTouchTap = (e) => {
    e.preventDefault();
    this.handleOpen();
  };

  handler = () => this.setState({success: true});

  render() {
    const {
      isMobile,
      t
    } = this.props;
    const styles = this.props.style ? this.props.style : ''

    return (
      <span style={styles}>
        <div style={{color: colors.blue, fontSize: 12, cursor: 'pointer'}} onClick={this.onTouchTap}>
          {t('Terms of Service')}
        </div>
        <TermsOfServiceModal
          isMobile={isMobile}
          open={this.state.open}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          handler = {this.handler}
        />
      </span>
    );
  }
}

export const TermsOfServiceInPage = withTranslation()(TermsOfServiceInPageComponent);
