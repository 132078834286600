import { textStyles, colors } from '../../shared/styles';

export const styles = {
  contentCard: (isMobile) => ({
    padding: isMobile ? '12px' : '12px 24px 36px',
    minHeight: 300,
    marginBottom: '20px'
  }),
  buttonStyle: {
    margin: '0px auto',
    height: 50,
    background: colors.blue,
    textAlign: 'center',
    border: 'none',
    padding: 10,
    textTransform: 'uppercase',
    fontSize: 16,
    color: colors.white,
    letterSpacing: 0.5,
    fontFamily: 'ProximaNovaRegular'
  },

  errorButtonStyle: {
    borderColor: colors.error,
    borderRadius: 3,
    color: colors.error,
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 'bold',
    margin: '.5em',
    padding: '.25em 3em',
    textTransform: 'uppercase',
    width: 'initial',
    backgroundColor: colors.white,
  },

  filterCol: {
    paddingRight: 50,
  },
  filterColPosition: {
    position: 'relative',
    top: '-11px', // because the material dropdowns are huge
  },

  titleContainer: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    margin: 0,
    color: '#424242',
    flex: 1,
  },
  equalizerStyle: {
    position: 'absolute',
    top: 60,
    right: 0
  },
  titleSearch: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '40%',
  },
  detailRow: {
    marginBottom: 38,
    ...textStyles.body1Grey,
  },
  unofficialStyle: {
    header: {
      marginTop: 20,
      textTransform: 'uppercase',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '15px',
      fontFamily: 'ProximaNovaBold',
      color: colors.textColor,
      paddingBottom: 5,
      borderBottom: `1px solid ${colors.greyLine}`
    },
    styleButton: {
      backgroundColor: colors.blue,
      border: `1px solid ${colors.blue}`,
      borderRadius: 2,
      color: colors.white,
      padding: '0px 20px',
      fontSize: 18,
      height: 42,
      fontFamily: 'ProximaNovaRegular',
      cursor: 'pointer'
    },
    addTimeBox: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.lightGrey,
      minHeight: 100
    },
    label: {
      fontSize: 32,
      color: colors.darkType,
      fontFamily: 'ProximaNovaRegular',
    },
    athleteName: (isMobile) => ({
      color: colors.darkGrey2,
      fontFamily: 'ProximaNovaRegular',
      fontSize: isMobile ? 18 : 22,
      lineHeight: '27px'
    }),
    info: {
      color: colors.grey,
      fontFamily: 'ProximaNovaRegular',
      fontSize: 14,
      lineHeight: '20px'
    },
    spanLeft: {
      paddingRight: 15,
      borderRight: `1px solid ${colors.greyLine}`
    },
    spanRight: {
      paddingLeft: 15,
    },
    time: {
      color: colors.greyLine,
      fontFamily: 'BebasNeueBold',
      fontSize: 36,
      lineHeight: '43px'
    },
    labelUnofficial: (isMobile) => ({
      fontSize: (isMobile) ? 20 : 24,
      color: colors.textColor,
    }),
    buttonContainer: (isMobile) => ({
      textAlign: isMobile ? 'center' : 'right',
      paddingTop: (isMobile) ? 15 : 0,
    }),
  },
  overallStyle: (isMobile) => ({
    backgroundColor: colors.lightGrey3,
    paddingBottom: '20px',
    userSelect: isMobile ? 'none' : 'auto',
    WebkitTouchCallout: isMobile ? 'none' : 'auto',
    WebkitUserSelect: isMobile ? 'none' : 'auto'
  }),
  addTimeBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.lightGrey,
    minHeight: 100,
    padding: '13px 5px 13px 15px',
    marginTop: 30,
    marginLeft: 0,
    marginRight: 0
  },
  textStyle: (isMobile) => ({
    color: colors.darkGrey,
    right: 0,
    bottom: -15,
    letterSpacing: '0.3px',
    fontFamily: 'ProximaNovaRegular',
    fontSize: 14,
    paddingBottom: 3,
    paddingTop: 30,
    float: 'right',
    textAlign: isMobile ? 'right' : 'left',
  }),
  idContainer: {
    float: 'right'
  },
  noResults: {
    marginTop: 20,
    color: colors.textColor,
    textAlign: 'center'
  },
  pagerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 20
  },
  noFriendsRacesContainer: {
    fontSize: 20,
    letterSpacing: 0,
    paddingBottom: 20,
    lineHeight: 1.7
  },
  showFiltersRow: (isMobile) => ({
    display: isMobile ? '' : 'inline-flex', width: '100%'
  }),
  showFiltersContainer: (isMobile) => ({
    right: isMobile ? '15px' : '0px'
  }),
  loginOrSignup: (isMobile) => ({
    display: isMobile ? 'block' : 'inline-block'
  }),
  eventResultsSearchContainer: {
    position: 'relative',
    margin: '30px 0px'
  },
  h3: {
    marginTop: 0
  }
};
