import React from 'react';
import PropTypes from 'prop-types';
import { configs } from '../../configs';
import { isReferrerValid, isRedirectUrlValid } from '../../utils/auth';

class SignInPageComponent extends React.Component {
  static propTypes = {
    refreshToken: PropTypes.string,
  };

  returnUrl = () => {
    const {referrerPath} = this.props;
    if (referrerPath) return configs.bragiUrl + (isReferrerValid(referrerPath) ? referrerPath : '/');
    else {
      const currentLocation = document.location.href;
      return isRedirectUrlValid(currentLocation) ? currentLocation : configs.bragiUrl;
    }
  };

  componentDidMount() {
    const keycloak = window.kc;
    const currentLocation = this.returnUrl();
    keycloak.login({
      redirectUri: currentLocation
    });
  }

  render() {
    return null;
  }
}

export const SignInPage = SignInPageComponent;
