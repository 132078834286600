import React from 'react';
import PropTypes from 'prop-types';

const LogoFlame = (props) => (
	<svg version="1.1" id="Layer_1" viewBox="0 0 25.3 31.8" width={ props.width }>
		<g id="Layer_2">
			<path fill={ props.fill } d="M6.5,30.8c-1.4-1.5-2.9-4.2-1-8.1c0,0,0.9,9.4,8.4,9.2s11.4-5.9,11.4-12.5c0-1.5-0.4-2.9-0.9-4.3
					c-0.1,2.6-1.6,4.9-3.9,6c0,0,7.3-10.1-1.9-16.9c0,0,1.5,3.9-1.2,5.1c0,0-0.7-8.7-10.4-9.3c0,0,6.2,5.6,1.3,13.7
					c0,0,0.4-5.9-5.2-5.6c0,0,2.8,2.5-0.2,5.8c-2.7,2.8-3.5,6.9-2.3,10.6C1.8,27.2,3.8,29.4,6.5,30.8z"/>
		</g>
	</svg>
);

LogoFlame.propTypes = {
	width: PropTypes.string,
	fill: PropTypes.string,
};

LogoFlame.defaultProps = {
	width: '23px'
};

export default LogoFlame;
