import { makeStyles } from '@material-ui/core';

import { fonts, loadingLight } from '../../shared/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  eventTitle: {
    color: theme.palette.grey[800],
    fontSize: 14,
    letterSpacing: 0.4,
    lineHeight: '24px',
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  link: {
    color: theme.palette.brandColor,
    display: 'block',
    fontSize: 14,
  },
  loadingDetail: {
    ...loadingLight,
    height: 15,
    pointerEvents: 'none',
    width: '100%',
  },
  siteLink: {
    color: theme.palette.brandColor,
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: '0.17px',
    lineHeight: '20px',
    wordBreak: 'break-word',
  },
}));
