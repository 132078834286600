import React, {Component} from 'react';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';

import FilterControls from './FilterControls';
import Checkbox from './Checkbox';
import MobileFilterHeader from './MobileFilterHeader';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters'
import {_filterTab} from './styles';
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';

export const genderTypes = {
  'M': 'Male',
  'F': 'Female',
  'U': 'Unspecified'
};

const streamId = 'profile-search-filters-stream';

class Gender extends Component {

  constructor(props) {
    super(props);
    this.activeGender = defaultFilterData.filters.gender;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.values && nextProps.values) {
      this.activeGender = nextProps.values.genderFilter.value;
    }
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const { values, history } = this.props;
    const filters = {
      ...values,
      genderFilter: {
        enabled: this.activeGender !== 'A',
        value: this.activeGender
      }
    };
    setFilterValues(streamId, filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  render() {
    const {
      isMobile,
      onClose,
      t
    } = this.props;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.ageFilterWrap(isMobile)}>
        {
          isMobile && <MobileFilterHeader
            title={t('Gender')}
            onClose={onClose}
          />
        }
        <div style={{padding:20}}>
          {
            Object.keys(genderTypes).map((type) =>
              <Checkbox
                key={type}
                label={t(`genderMap#${type}`)}
                onCheck={() => {
                  this.activeGender = type;
                  this.forceUpdate()
                }}
                checked={this.activeGender === type}
              />
            )
          }
        </div>
        <FilterControls
          style={{ justifyContent: 'flex-end' }}
          onSave={this.submitFilter}
          onReset={() => {
            this.activeGender = defaultFilterData.filters.gender;
            this.forceUpdate();
          }}
        />
      </div>
    )
  }
}

export default withTranslation()(connectStream({
  values: () => getFilterValues(streamId)
})(withRouter(Gender)));
