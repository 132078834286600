import { FetchApi} from '../lib/anuket-http';
import { configs } from '../configs';
import eventBus from '../lib/event-bus-rx';
import { map, flatMap } from 'rxjs/operators';

/**
 * Fetch courses
 */
export function fetchCourse() {
  return eventBus.getTopic('athlinksId').pipe(flatMap(getExtraData));
}

/**
 * Get extra data for event
 * @param eventId
 */
export function getExtraData(eventId) {
  if (parseInt(eventId)) {
    return FetchApi
      .memoizeFetch(10000)(`${configs.ATHLINKS_API_HOST}/events/api/${eventId}/extra`, {})
      .pipe(map(({ result }) => result ? result : {}));
  }
}

