import React  from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { styles } from './styles';
import FriendsUpcomingEvent from './friendsUpcomingEvents/FriendsUpcomingEvent';
import {ViewAll} from './UpcomingEvents';
import { eventSearchClick } from '../shared/QuickLinks';

const FriendsUpcomingEvents = ({upcomingEvents, isMobile, isMe, t, history}) =>  {
  if (upcomingEvents.length === 0) {
    return null;
  }

  const upcomingEventsList = upcomingEvents
    && upcomingEvents.Events
    && upcomingEvents.Events.List;

  return (
    <div style={styles.calendarContainer(isMobile)} id='friends-upcoming'>
      <h2 style={styles.boxTitle(isMobile)} id='friends-upcoming-title'>
        {t('Friends\' Upcoming Events')}
      </h2>
      {
        upcomingEventsList.map((event, index) => {
          return (
            <FriendsUpcomingEvent
              key={`friends-upcoming-event-${index}`}
              event={event}
              isMobile={isMobile}
              isMe={isMe}
            />
          );
        })
      }
      <ViewAll
        messages={t('Find More Events')}
        type='friendsUpcomingEvents'
        onClickAction={(ev) => eventSearchClick(ev, t, history)}
        isMobile={isMobile}
      />
    </div>
  );
};

FriendsUpcomingEvents.propTypes = {
  t: PropTypes.func
};

export default withRouter(FriendsUpcomingEvents);
