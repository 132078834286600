import React from 'react';
import { styles } from './styles';

export function suggestionText({ searchQuery, res, isFocused }: {
  searchQuery: string
  res: any
  isFocused: boolean
}) {
  const text = [res.bib.trim(), ' ', res.displayName.trim()].join('');

  function sliceMatch(str: string): string[] | undefined {
    const idx = text.toLowerCase().indexOf(str);

    if (idx < 0) {
      const len = str.length;
      if (len > 1) {
        const leftMatch = sliceMatch(str.slice(0, len - 1));
        const rightMatch = sliceMatch(str.slice(1));

        if (leftMatch && rightMatch) {
          return leftMatch[1].length >= rightMatch[1].length
            ? leftMatch : rightMatch;
        }
        return leftMatch || rightMatch;
      }
    }
    else {
      return [
        text.slice(0, idx),
        text.slice(idx, idx + str.length),
        text.slice(idx + str.length)
      ];
    }
  }

  const sliced = sliceMatch(searchQuery.toLowerCase());
  const [left, match, right] = sliced || [text, '', ''];

  return (
    <span style={styles.suggestionText(isFocused)}>
      <span>
        {left}
      </span>
      <span style={{ fontWeight: 'bold' }}>
        {match}
      </span>
      <span>
        {right}
      </span>
    </span>
  );
}
