import React, { Component } from 'react';
import PropTypes from 'prop-types';

const colors = {
  gray: {
    primary: '#919191',
    lights: ['#E6E6E6', '#CACACA', '#A3A3A3'],
    darks: ['#6D6D6D', '#333333']
  },
  white: {
    primary: '#FFFFFF',
    darks: ['#F4F4F4', '#FBFBFB', '#FDFDFD']
  },
};

const styles = {
  container: {
    position: 'relative',
    overflow: 'hidden'
  },
  nav: {
    textAlign: 'center',
    height: '30px'
  },
  goTo: {
    padding: '4px',
    cursor: 'pointer',
    lineHeight: '30px'
  },
  circle: {
    height: '7px',
    width: '7px',
    display: 'inline-block',
    borderRadius: '100%',
    verticalAlign: 'middle',
    border: '1px solid ' + colors.gray.darks[1],
    backgroundColor: colors.gray.darks[1]
  },
  circleFill: {
    border: '1px solid ' + colors.gray.primary,
    backgroundColor: colors.white.primary
  },
  arrow: {
    width: 0,
    height: 0,
    verticalAlign: 'middle',
    display: 'inline-block'
  },
  arrowRight: {
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderLeftWidth: '5px',
    borderLeftStyle: 'solid',
    borderLeftColor: colors.gray.primary
  },
  arrowLeft: {
    borderTop: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderRightWidth: '5px',
    borderRightStyle: 'solid',
    borderRightColor: colors.gray.primary
  },
  arrowActive: {
    borderRightColor: colors.gray.darks[1],
    borderLeftColor: colors.gray.darks[1]
  },

  navArrowsContainer: {
    position: 'absolute',
    top:'50%',
    width:'100%',
    overflow: 'visible'
  },
  navArrowOverlayLeft: {
    cursor:'pointer',
    position: 'absolute',
    textAlign: 'center',
    zIndex: 2,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    left: 40,
    top: 0
  },
  navArrowOverlayRight: {
    cursor:'pointer',
    position: 'absolute',
    textAlign: 'center',
    zIndex: 2,
    userSelect: 'none',
    WebkitUserSelect: 'none',
    right: 40,
    top: 0
  },
  navArrow: {
    marginTop: -15,
    lineHeight: '50px',
    backgroundColor: 'rgba(100, 100, 100, 0.5)',
    height: 50,
    width: 70
  }
};

const defaultLeftArrow = (
  <div style={styles.navArrow}>PREV</div> //TODO: translate this
);

const defaultRightArrow = (
  <div style={styles.navArrow}>NEXT</div> //TODO: translate this
);

export class Carousel extends Component {

  static propTypes = {
    activeIdx: PropTypes.number,

    //Provide either slideWidth or slidesToShow
    slideWidth: PropTypes.number,
    slidesToShow: PropTypes.number,

    //how much to scale down slides to right/left of active:
    // if set to 0.01, slides shrink by 1% as you go left/right
    scaleWithDistance: PropTypes.number,

    overlap: PropTypes.number,

    //Fade in active slides rather than slide;
    //should be used only with slidesToShow=1
    fadeNotSlide: PropTypes.bool,

    autoPlaySeconds: PropTypes.number,

    slideHeight: PropTypes.number,
    slidesToScroll: PropTypes.number,
    wrapAround: PropTypes.bool,
    focusOnSelect: PropTypes.bool,
    dragThresholdToSwipe: PropTypes.number,
    previousSlideEdgeSize: PropTypes.number,
    showNavOverlay: PropTypes.bool,
    showNavDots: PropTypes.bool,
    renderLeftArrow: PropTypes.func,
    renderRightArrow: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    activeIdx: 0,
    slideHeight: 300,
    slidesToScroll: 1,
    overlap: 0,
    wrapAround: true,
    dragThresholdToSwipe: 50,
    previousSlideEdgeSize: 0,
    showNavOverlay: true,
    showNavDots: true
  };

  constructor(props) {
    super(props);

    this.state = {
      activeIdx: props.activeIdx,

      animate: true,

      wrapIdx: 0,

      circleMarks: props.scaleWithDistance
        ? calcCircleDistancesFromActive(props.children, props.activeIdx, props.scaleWithDistance)
        : {
        totalSlideDeltas: 0,
        distanceTillActive: 0
      },

      slideWidth: props.slideWidth || 100,

      //dragging
      dragging: false,
      draggedDistance: 0,
    };

    if (props.autoPlaySeconds) {
      this.setupAutoPlay(props.autoPlaySeconds);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.activeIdx !== nextProps.activeIdx) {
      this.goToItem(nextProps.activeIdx);
    }

    if (nextProps.slideWidth && nextProps.slideWidth !== this.state.slideWidth) {
      this.setState({
        slideWidth: nextProps.slideWidth
      });
    }

    if (nextProps.autoPlaySeconds !== this.props.autoPlaySeconds) {
      this.setupAutoPlay(nextProps.autoPlaySeconds);
    }

    //Calculate distance to each slide from current slide, scaling down the farther you go from active
    if (nextProps.scaleWithDistance &&
        (React.Children.count(nextProps.children) !== React.Children.count(this.props.children) ||
          nextProps.scaleWithDistance !== this.props.scaleWithDistance)) {
      this.setState({
        circleMarks: calcCircleDistancesFromActive(nextProps.children, nextProps.activeIdx, nextProps.scaleWithDistance)
      })
    }
  }

  componentDidMount() {
    this.updateSlideWidth();
  }

  componentDidUpdate() {
    this.updateSlideWidth();

    //The carousel slides the div to the left/right, while positioning the slides at the visible offset.
    //Periodically move the whole div to 0 and reset the offset (wrapIdx).
    //Main use-case is autoPlay carousels that could accumulate huge transform values if not periodically reset.
    if (!this.state.animate) {
      //We have finished resetting positions, immediately set back to animating
      this.resetToAnimating();
    } else if (Math.abs(this.state.wrapIdx) > 10) {
      //Periodically reset the positions to 0 (without animation)
      this.resetPositions();
    }
  }

  resetToAnimating = () => {
    this.setState({
      animate: true
    });
  };

  resetPositions = () => {
    if (this.timer) clearTimeout(this.timer);

    //wait for current animation to finish, then reset positions to 0 without animation
    this.timer = setTimeout(() => {
      if (this.unmounted) return;

      this.timer = null;
      this.setState({
        animate: false,
        wrapIdx: 0
      })
    }, 600);
  };

  componentWillUnmount() {
    this.unmounted = true;

    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  updateSlideWidth() {
    if (this.props.slidesToShow && this.containerRef) {
      const curSlideWidth = this.state.slideWidth;
      const containerWidth = this.containerRef.getBoundingClientRect().width;
      const newSlideWidth = Math.ceil(containerWidth/this.props.slidesToShow);
      if (curSlideWidth !== newSlideWidth) {
        this.setState({
          slideWidth: newSlideWidth
        });
      }
    }
  }

  handleNext = (activeIdx, numItems, pageSize) => {
    pageSize = Math.min(pageSize, numItems);

    if (!this.props.wrapAround && (activeIdx + pageSize) >= numItems) {
      return;
    }

    //Reset the auto-play timer
    if (this.props.autoPlaySeconds) {
      this.setupAutoPlay(this.props.autoPlaySeconds);
    }

    this.goToItem((activeIdx + pageSize) >= numItems ? 0 : (activeIdx + pageSize));
  };

  handlePrev = (activeIdx, numItems, pageSize) => {
    pageSize = Math.min(pageSize, numItems);

    if (!this.props.wrapAround && activeIdx - pageSize < 0) {
      return;
    }

    const numPages = Math.ceil(numItems / (pageSize||4));

    //Reset the auto-play timer
    if (this.props.autoPlaySeconds) {
      this.setupAutoPlay(this.props.autoPlaySeconds);
    }

    this.goToItem((activeIdx - pageSize < 0) ? pageSize*(numPages - 1) : (activeIdx - pageSize));
  };

  goToItem = (targetIdx) => {
    const {
      children,
      wrapAround,
      fadeNotSlide
    } = this.props;

    const numItems = React.Children.count(children);

    const {
      activeIdx,
      wrapIdx
    } = this.state;

    if (targetIdx === activeIdx) {
      return;
    }

    const shouldWrap = wrapAround && !fadeNotSlide && Math.abs(targetIdx - activeIdx) > numItems/2;

    let newWrapIdx = wrapIdx;
    //If we're close to end of carousel and are going to beginning, simulate going forward instead of going back
    if (shouldWrap) {
      if (targetIdx > activeIdx) {
        newWrapIdx -= 1;
      } else {
        newWrapIdx += 1;
      }
    }

    let { circleMarks } = this.state;
    if (this.props.scaleWithDistance) {
      circleMarks = calcCircleDistancesFromActive(children, targetIdx, this.props.scaleWithDistance);
    }

    this.setState({
      activeIdx: targetIdx,
      wrapIdx: newWrapIdx,
      circleMarks
    }, () => {
      if (this.props.onChange) {
        this.props.onChange(targetIdx);
      }
    });
  };

  renderTrack() {
    const {
      children,
      slideHeight,
      scaleWithDistance,
      overlap,
      fadeNotSlide,
      focusOnSelect
    } = this.props;

    const {
      activeIdx,
      slideWidth,
      circleMarks
    } = this.state;

    const { distances } = circleMarks;

    let accumWidth = 0;

    return (
      <div style={{ position: 'relative' }}>
        {
          React.Children.map(children, (child, i) => {
            const distance = scaleWithDistance ? distances[i] : 0;
            const deltaPcnt = scaleWithDistance ? scaleWithDistance * distance  : 0;
            const widthShrinkDelta = deltaPcnt * slideWidth;
            const width = slideWidth - widthShrinkDelta;

            const isActive = i === activeIdx;

            const overlapOffset = (isActive ? 0 : overlap);

            //If the slide is scaled by delta, that leaves .5*delta margin on each side -
            //move left to close the left margin; also move left for overlap
            const left = fadeNotSlide ? 0 : accumWidth - (widthShrinkDelta * .5) - overlapOffset;

            accumWidth += (width - overlapOffset);

            let cssTransition = null;
            if (scaleWithDistance) {
              const scale = 1 - deltaPcnt;
              cssTransition = {
                WebkitTransition: 'transform .5s',
                WebkitTransform: `scale(${scale})`,
                MsTransition: 'transform .5s',
                MsTransform: `scale(${scale})`,
                MozTransition: 'transform .5s',
                MozTransform: `scale(${scale})`,
                OTransition: 'transform .5s',
                OTransform: `scale(${scale})`,
                transition: 'transform .5s',
                transform: `scale(${scale})`
              }
            } else if (fadeNotSlide) {
              cssTransition = {
                transition: 'opacity 1s'
              }
            }
            const onClickProps = focusOnSelect ? {onClick:()=>this.goToItem(i)} : null;

            const opacity = fadeNotSlide ? (isActive ? 1 : 0) : 1;
            return (
              <div
                key={'child_'+i}
                {...onClickProps}
                style={{
                      position:'absolute',
                      top: 0,
                      left,
                      height: slideHeight,
                      zIndex: scaleWithDistance ? 1-distance : 'auto',
                      width: slideWidth,
                      opacity,
                      ...cssTransition
                    }}>

                {child}

              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    const {
      children,
      slidesToScroll,
      slideHeight,
      scaleWithDistance,
      overlap,
      fadeNotSlide,
      wrapAround,
      previousSlideEdgeSize,
      showNavDots,
      showNavOverlay,
      renderLeftArrow,
      renderRightArrow,
      isMobile = false
    } = this.props;

    const {
      animate,
      activeIdx,
      slideWidth,
      circleMarks,
      wrapIdx,
      dragging,
      draggedDistance,
    } = this.state;

    //The very next frame we'll re-render in reset positions. Returning null here ensures the reset won't be visible/animated.
    if (!animate) return null;

    const numItems = React.Children.count(children);

    const pageSize = slidesToScroll;
    const numPages = Math.ceil(numItems / pageSize);
    const curPage = Math.floor(activeIdx / pageSize);

    const hasPrev = wrapAround || (activeIdx - pageSize >= 0);
    const hasNext = wrapAround || (activeIdx + pageSize < numItems);

    const leftArrow = (renderLeftArrow && renderLeftArrow()) || defaultLeftArrow;
    const rightArrow = (renderRightArrow && renderRightArrow()) || defaultRightArrow;

    const height = showNavDots ? slideHeight*1.2+50 : slideHeight;
    const cardHeight = height-5;

    const { totalSlideDeltas, distanceTillActive } = circleMarks;

    const trackLeft = wrapAround && !fadeNotSlide ? this.renderTrack() : null;
    const trackCenter = this.renderTrack();
    const trackRight = wrapAround && !fadeNotSlide ? this.renderTrack() : null;

    const totalOverlapWidth = numItems > 1 ? (numItems - 1)*overlap : 0;
    const trackWidth = fadeNotSlide ? slideWidth : numItems * slideWidth - totalOverlapWidth - (scaleWithDistance ? totalSlideDeltas * scaleWithDistance * slideWidth : 0);

    const distanceTillActiveSlideInTrack = activeIdx * (slideWidth - overlap) - (scaleWithDistance ? distanceTillActive*scaleWithDistance*slideWidth : 0);

    const dragDistance = (dragging ? draggedDistance : 0);
    const targetLeft = fadeNotSlide ? dragDistance : -1 * ((wrapIdx * trackWidth) + distanceTillActiveSlideInTrack) + dragDistance;

    const transform = {
      WebkitTransform: `translateX(${targetLeft}px)`,
      MsTransform: `translateX(${targetLeft}px)`,
      MozTransform: `translateX(${targetLeft}px)`,
      OTransform: `translateX(${targetLeft}px)`,
      transform: `translateX(${targetLeft}px)`
    };

    const cssTransition = dragging ?  transform : {
      ...transform,
      WebkitTransition: 'transform 0.5s',
      MsTransition: 'transform 0.5s',
      MozTransition: 'transform 0.5s',
      OTransition: 'transform 0.5s',
      transition: 'transform 0.5s'
    };

    const carouselStyle = Object.assign({position:'relative'}, isMobile ? { overflow: 'hidden'} : {});

    //Use React.Children.map instead of children.map because if there's just 1 child, children is not an array but an object.
    return (
      <div style={carouselStyle}>

        <div style={styles.container}>
          <div
            ref={this.setContainerRef}
            style={{...cssTransition, position:'relative', left: 0}}
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onTouchEnd={this.onTouchEnd}
            onTouchCancel={this.onTouchCancel}>

            {trackLeft &&
            <div
              style={{
                      position:'absolute',
                      top: 0,
                      left: (wrapIdx-1)*trackWidth + previousSlideEdgeSize,
                      height: slideHeight,
                      width: slideWidth
                    }}>
              {trackLeft}
            </div>
            }

            <div
              style={{
                      position:'absolute',
                      top: 0,
                      left: (wrapIdx)*trackWidth + previousSlideEdgeSize,
                      height: slideHeight,
                      width: slideWidth
                    }}>
              {trackCenter}
            </div>

            {trackRight &&
            <div
              style={{
                      position:'absolute',
                      top: 0,
                      left: (wrapIdx+1)*trackWidth + previousSlideEdgeSize,
                      height: slideHeight,
                      width: slideWidth
                    }}>
              {trackRight}
            </div>
            }

          </div>

          <div style={{height: cardHeight}} />

          {showNavDots &&
          <nav style={styles.nav}>
            {numItems > 1 ? (
              <div>
                {[...new Array(numPages).keys()].map((pg) => {
                  return (
                    <div key={'nav-' + pg}
                       style={styles.goTo}
                       onClick={() => this.goToItem(pg*pageSize)}>
                      <div style={pg === curPage ? styles.circle : {...styles.circle, ...styles.circleFill}}/>
                    </div>
                  );
                })}
                <div style={styles.goTo}
                   onClick={() => this.handlePrev(activeIdx, numItems, pageSize)}>
                  <div style={{...styles.arrow,  ...styles.arrowLeft, ...(hasPrev ? styles.arrowActive : {})}}/>
                </div>
                <div style={styles.goTo}
                   onClick={() => this.handleNext(activeIdx, numItems, pageSize)}>
                  <div style={{...styles.arrow, ...styles.arrowRight, ...(hasNext ? styles.arrowActive : {})}}/>
                </div>
              </div>
            ) : null}
          </nav>
          }
        </div>

        {showNavOverlay &&
        <div style={styles.navArrowsContainer}>
          <div onClick={() => this.handlePrev(activeIdx, numItems, pageSize)}
               style={styles.navArrowOverlayLeft}>
            {leftArrow}
          </div>

          <div onClick={() => this.handleNext(activeIdx, numItems, pageSize)}
               style={styles.navArrowOverlayRight}>
            {rightArrow}
          </div>
        </div>
        }

      </div>
    );
  }

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  setupAutoPlay(seconds) {
    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval);
    }
    if (seconds) {
      this.autoPlayInterval = setInterval(() => {
        const {
          children,
          slidesToScroll
        } = this.props;

        const {
          activeIdx
        } = this.state;

        const numItems = React.Children.count(children);

        this.handleNext(activeIdx, numItems, slidesToScroll);
      }, seconds * 1000);
    }
  }

  handleSwipe(draggedDistance) {
    const {
      children,
      slidesToScroll,
      dragThresholdToSwipe
    } = this.props;

    const {
      activeIdx
    } = this.state;

    const numItems = React.Children.count(children);

    const pageSize = slidesToScroll;

    if (draggedDistance < -dragThresholdToSwipe) {
      this.handleNext(activeIdx, numItems, pageSize);
    } else if (draggedDistance > dragThresholdToSwipe) {
      this.handlePrev(activeIdx, numItems, pageSize);
    }
  }

  //TOUCH EVENTS for dragging interaction
  onTouchStart = (e) => {
    if (!e.touches || !e.touches.length) {
      return;
    }

    this.startPageX = e.touches[0].pageX;
    this.setState({
      dragging: true,
      draggedDistance: 0
    })
  };

  onTouchMove = (e) => {
    if (!e.touches || !e.touches.length) {
      return;
    }

    const draggedDistance = e.touches[0].pageX - this.startPageX;
    if (this.state.dragging) {
      e.preventDefault();

      this.setState({
        draggedDistance
      });
    }
  };

  onTouchEnd = () => {
    const {
      dragging,
      draggedDistance
    } = this.state;

    if (dragging) {
      this.setState({
        dragging: false
      });
      this.handleSwipe(draggedDistance);
    }
  };

  onTouchCancel = () => {
    const {
      dragging
    } = this.state;

    if (dragging) {
      this.setState({
        dragging: false
      });
    }
  }
}


function calcCircleDistancesFromActive(children, activeIdx, scaleWithDistance) {
  const numItems = React.Children.count(children);

  const distances = {[activeIdx]:0};
  let totalSlideDeltas = 0;

  //Don't allow to shrink slide width below 0
  const maxDistance = 1/(scaleWithDistance||.1);

  const half = Math.floor((numItems - 1)/2);
  for (let i = 1; i <= half; ++i) {
    const rightIdx = (activeIdx + i) % numItems;
    let leftIdx = activeIdx - i;
    if (leftIdx < 0) leftIdx += numItems;
    const distance  = Math.min(maxDistance, i);
    distances[rightIdx] = distance;
    distances[leftIdx] = distance;
    totalSlideDeltas += distance * 2;
  }

  //If even # of total slides, populate the middle slide of (n-1) to have same distance as neighbors
  if (numItems > 1 && numItems%2 === 0) {
    const farthestSlideIdx = (activeIdx + (numItems / 2)) % numItems;
    distances[farthestSlideIdx] = distances[(farthestSlideIdx + 1) % numItems];
    totalSlideDeltas += distances[farthestSlideIdx];
  }

  let distanceTillActive = 0;
  for (let i = 0; i < activeIdx; ++i) {
    distanceTillActive += distances[i];
  }

  return {
    totalSlideDeltas,
    distanceTillActive,
    distances
  }
}
