import React from 'react';
import ReactDOM from 'react-dom';
import keycloak from './lib/keycloak'
import { rollbar } from './data/Logging'
import App from './App.jsx'
import {I18nextProvider} from 'react-i18next';
import i18n from './utils/i18n';
import reportWebVitals from './reportWebVitals';
import './scss/global.scss';
import 'react-smartbanner/dist/main.css'

rollbar()

keycloak()

i18n.init({
  react: {
    useSuspense: false
  }
});

function runApp() {
  ReactDOM.render(
    <I18nextProvider i18n={ i18n }>
      <App/>
    </I18nextProvider>,
    document.getElementById('content')
  );
}

if (!window.Intl) {
  import('./intl-polyfill').then(({ Intl }) => {
    window.Intl = Intl
    runApp()
  })
  .catch((e) => {
    console.error(e)
  })
} else {
  runApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
