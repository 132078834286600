import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import marked from '../../utils/marked';
import { Location } from '../shared/Location';
import { DateTime } from '../shared/DateTime';
import { _date } from '../../utils/date';
import { configs } from '../../configs';

import { useStyles } from './styles';

interface EventSummaryProps {
  event: {
    isPublishedAthlinks: boolean;
    description?: string;
    extendedDescription?: string;
    name?: string;
    startTime?: string;
    location?: {
      regionId?: string;
      city?: string;
      region?: string;
      countryId?: string;
    };
  };
}

const EventSummary = ({ event }: EventSummaryProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [more, setMore] = useState(false);

  const clickMore = () => {
    setMore(!more);
  };

  const renderExtended = () => {
    const desc = String(event.extendedDescription || '');

    if (desc.length) {
      const parsed = marked.parse(desc);
      return (
        <div style={{ marginTop: '8px' }} id={'extended-description'} dangerouslySetInnerHTML={{ __html: parsed }} />
      );
    }

    return null;
  };

  const renderExtendedWithControls = () => {
    if (more) {
      return (
        <div>
          {renderExtended()}
          <div style={{ marginTop: '8px' }}>
            <div className={styles.link} onClick={clickMore} id={'elp-hide-button'}>
              {t('Hide')}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.moreButtonWrap}>
          <div className={styles.link} onClick={clickMore} id='elp-read-more-button'>
            {t('Read more')}
            <img className={styles.moreImg} src={`${configs.bragiUrl}/svg/down-chevron-blue.svg`} alt={''} />
          </div>
        </div>
      );
    }
  };

  const renderDesc = () => {
    const { isPublishedAthlinks, description, extendedDescription } = event;

    if (!isPublishedAthlinks) return null;

    if (description && extendedDescription) {
      return (
        <div itemProp="description" className={styles.descContainer}>
          {description}
          {renderExtendedWithControls()}
        </div>
      );
    } else {
      return (
        <div itemProp="description" className={styles.descContainer}>
          {extendedDescription ? renderExtended() : description}
        </div>
      );
    }
  };

  const { name = '', startTime, location = {} } = event;
  const startDate = _date(startTime);
  const state = location.regionId ? location.regionId.split('-') : [];

  return (
    <div style={{ paddingBottom: '24px' }}>
      <h2 className={styles.title}>
        {name}
      </h2>

      <div className={styles.detailsContainer}>
        <DateTime date={startDate} showTime={false} icon={null} />

        <div className={styles.divider} />

        <Location
          city={location.city || ''}
          state={location.region ? location.region : state[1] || ''}
          country={location.countryId || ''}
          icon={null}
        />
      </div>

      {renderDesc()}
    </div>
  );
};

export { EventSummary };
