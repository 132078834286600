import _ from 'lodash';
import {ClaimStatus} from '../data/UnclaimedSearchStreams';

export function getPendingStatusForResult(pendingClaims, result) {
  return (getPendingClaimForResult(pendingClaims, result) || {}).claimProcessingStatus;
}

export function getClaimStatusMessage(status, t) {
  if(status === ClaimStatus.inReview) {
    return t('In Review');
  }
  else if(status === ClaimStatus.pending) {
    return t('Pending');
  }
  else if(status === ClaimStatus.unofficial) {
    return t('Unofficial');
  }
  else if(status === ClaimStatus.virtual) {
    return t('Virtual');
  }
  return '';
}

export function getClaimStatusDescription(status, t) {
  if(status === ClaimStatus.inReview) {
    return t(
      'Results marked as "In Review"\nare results that must be approved by\nour support team.'
    ).split('\n');
  }
  else if(status === ClaimStatus.pending) {
    return t(
      'Results marked as "Pending"\nare results that the race organizer\nhasn\'t finalized within our system.'
    ).split('\n');
  }
  else if(status === ClaimStatus.unofficial) {
    return t(
      'Results marked as "Unofficial"\nare results that the user created.\nUnofficial results are reviewed by\nour staff and will become official if\nthey can be verified.'
    ).split('\n');
  }
  else if(status === ClaimStatus.virtual) {
    return t(
      'This is an Athlinks Virtual Race.\nIt will count toward your race counts, but not your Personal Records.'
    ).split('\n');
  }

  return '';
}

export function hasResultInEventCourse(results, eventCourseId) {
  // eslint-disable-next-line
  return _.some(results, (result) => result.eventCourseID == eventCourseId || result.eventCourseId == eventCourseId);
}

export function getPendingClaimForResult(pendingClaims, result) {
  pendingClaims = pendingClaims?.result ?? pendingClaims
  if(_.isEmpty(pendingClaims)) {
    return undefined;
  }

  const entryId = result?.entryId ||
    result?.entryID ||
    result?.EntryId ||
    result?.ctLiveEntryId ||
    result?.thirdPartyEntryId;

  if(!entryId) {
    return undefined;
  }

  const courses = _.flatten(_.map(pendingClaims, (event) => event.Courses));
  const claim = _.find(courses, (course) => {
    const entryIds = course?.AthlinksOrCtResultEntry;
    return entryId === entryIds?.AthlinksEntryId || entryId === entryIds?.CTLiveEntryId;
  });
  return claim;
}