import {colors} from '../../../shared/styles';

export const styles = {
  title: {
    color: colors.textColor,
    fontFamily: 'ProximaNovaRegular',
    fontWeight: '300',
    fontSize: 18,
    marginBottom: 5
  },
  profPic: {
    margin: '0 4px 8px 4px',
    border: `2px solid ${colors.white}`,
    borderRadius: '50%',
    height: '40px',
    width: '40px',
  },
  moreBtn: {
    marginLeft:10,
    whiteSpace:'nowrap',
    fontSize: '13px',
    color: 'rgb(22, 169, 225)',
    display: 'flex',
    alignItems: 'center'
  },
  elpStyle: (sideMargin) => ({
    marginLeft: sideMargin,
    marginBottom: 20,
    backgroundColor: colors.white
  }),
  irpStyle: (sideMargin, athleteCard) => ({
    paddingTop: 5,
    paddingBottom: 10,
    marginBottom: 20,
    boxShadow: athleteCard ? 'none' : '0 1px 2px 0 rgba(0,0,0,0.15)',
    textAlign: 'center',
    minHeight: 145,
    marginLeft: sideMargin,
    backgroundColor: athleteCard ? 'transparent' : 'white',
    position: athleteCard ? 'absolute' : 'auto',
    right: athleteCard ? 30 : null,
    top: athleteCard ? '-17px' : null
  }),
  titleStyle: (type) => ({
    color: colors.textColor,
    fontFamily: 'ProximaNovaRegular',
    fontWeight: '300',
    fontSize: type === 'ELP' ? 18 : 14,
    marginBottom: 5,
    textAlign: 'center'
  }),
  athletePhoto: {
    float: 'left',
    marginLeft: -10,
    zIndex: 99
  },
  moreButtonWrap: (isMobile) => ({
    margin: '0 auto',
    display: 'flex',
    zIndex: 2,
    textAlign: (isMobile) ? 'center' : null
  })
};
