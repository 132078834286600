import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { styles } from './styles';

export class Banner extends PureComponent {

  static propTypes = {
    adKey: PropTypes.string,
    divId: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number
  };

  static defaultProps = {
    width: 300,
    height: 250,
    style: { }
  };

  static safeGuardGTM = () => {
    if (!window.googletag) {
      window.googletag = {cmd: []};
    }
    if (!window.googletag.cmd) {
      window.googletag.cmd = [];
    }
  };

  static adsInitialized = false;
  static initAds = () => {
    if (!Banner.adsInitialized) {
      Banner.adsInitialized = true;
      Banner.safeGuardGTM();
      window.googletag.cmd.push(() => {
        window.googletag.pubads().enableSingleRequest();
        window.googletag.pubads().disableInitialLoad();
        window.googletag.enableServices();
      })
    }
  };

  static slots = {};
  static slotsToInit = [];
  static initTimeout = 0;
  static queueSlot = (adKey, size, divId, owner) => {
    Banner.slotsToInit.push({adKey, size, divId, owner});
    Banner.initTimeout && clearTimeout(Banner.initTimeout);
    Banner.initTimeout = setTimeout(() => Banner.initSlots(), 100);
  };
  static initSlots = () => {
    Banner.initTimeout = 0;
    Banner.safeGuardGTM();

    const slots = Banner.slotsToInit.slice();
    Banner.slotsToInit.length = 0;

    slots.forEach((def) => {
      window.googletag.cmd.push(() => {
        const slot = Banner.slots[def.adKey] || window.googletag.defineSlot(def.adKey, def.size, def.divId);
        Banner.slots[def.adKey] = slot;
        if (slot.owner !== def.owner) {
          slot.owner = def.owner;
          slot.addService(window.googletag.pubads());
        }
        else {
          window.googletag.display(def.divId);
        }

      });
    });
    window.googletag.cmd.push(() => window.googletag.pubads().refresh());
  };

  constructor(props, context) {
    super(props, context);
    Banner.initAds();
  }

  destroyAd() {
    if (Banner.slots[this.props.adKey] && (Banner.slots[this.props.adKey].owner === this)) {
      // If we're using the same ad namespace, don't destroy the reg here.
      // if (!document.getElementById(this.props.divId)) {
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots([Banner.slots[this.props.adKey]]);
      });
      delete Banner.slots[this.props.adKey];
    }
  }

  componentDidMount() {
    Banner.queueSlot(this.props.adKey, [this.props.width, this.props.height], this.props.divId, this);
  }

  componentWillUnmount() {
    this.destroyAd();
  }

  render() {
    const { width, height } = this.props;
    return (
      <div
        id={this.props.divId}
        onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Double Click')}
        style={{...this.props.style, ...styles.bannerMinStyle(width, height)}}
        data-adkey={this.props.adKey}
      />
    );
  }
}

const hoistAthlinksAds = (aa) => {
  const ads = [];
  let initialized = false;
  const init = () => {
    !initialized && (aa.ads || []).forEach((x) => ads.push(x));
    initialized = true;
  };
  const adsForPage = (x) => ads.filter( (_) => _.page === x );

  return {
    ads,
    adsForPage,
    init
  }
};

window.AthlinksAds = hoistAthlinksAds(window.AthlinksAds || {ads:[]});

export const AthlinksAds = window.AthlinksAds;
