import {
  getUnclaimedResults,
  checkYearMatch,
} from '../data/UnclaimedSearchStreams';
import _ from 'lodash';

export const tabTypes = [
  'overview',
  'results',
  'followers',
  'following',
  'rivals',
  'statistics'
];

/**
 * Check if in some of events in calendar is today
 * @param calendar
 * @returns {*}
 */
export function getLiveEvent(calendar){
  return calendar && calendar.UpcomingRaces
    .filter(function(upcoming) {return new Date(upcoming.StartDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);}
  );
}

export function setTab(tabName) {
  const tabNameLower = (tabName || '').toLowerCase();

  return !tabNameLower || tabTypes.indexOf(tabNameLower) < 0
    ? 'overview'
    : tabNameLower;
}

 /**
   * Check if athlete data are loaded
   * @param {number} prevRacerID
   * @return {boolean}
 */
export function athleteDidLoad(prevRacerID, RacerID) {
  return RacerID !== prevRacerID
}


/**
 * Pull out number of unclaimed results
 * @return {number}
 */
export function getNumberOfUnclaimedResults(unclaimedResults, athlete) {
  const displayName = _.get(athlete, 'athlete.DisplayName', null);
  const lastSearchedTerm = _.get(unclaimedResults, 'lastSearchedTerm', null);

  if(displayName !== lastSearchedTerm) return 0;

  const results = _.get(unclaimedResults, 'results', {});
  const athleteAge = _.get(athlete, 'athlete.Age', '');
  const thisYear = new Date().getFullYear();
  const myUnclaimedResults = getUnclaimedResults(results).raceResults;
  const filtered = myUnclaimedResults.filter(({StartDateTime, Age}) => {
    return checkYearMatch(StartDateTime, Age, {enabled: true, value: thisYear - athleteAge})
  });

  return filtered.length;
}

 /**
 * Generates options for group drop down
 *
 * @return {[*,*,*]}
 */
export function buildGroupOptions(t) {
  return [
    {value: 'year', label: t('Year')},
    {value: 'category', label: t('Category')},
    {value: 'race', label: t('Distance')},
    {value: 'event', label: t('Event')},
  ];
}

/**
 * Generates race type options for filter drop down
 * @return {Array.<*>}
 */
export function buildRaceTypeOptions(races, t) {

  const allTypes = races.filter((r) => isResultValid(r)).map(
    ({race: {courses: [{coursePattern}]}}) => coursePattern);

  return [{value: 'all', label: t('All')}].concat(
    [...new Set(allTypes)].map((type) => ({value: type, label: type}))
  );
}

export function isResultValid({ isUnofficial, unofficialTime, tickString }) {
  return !isUnofficial || unofficialTime.length > 0 || !!tickString;
}

/**
 * Compare name of every result element and returns false if name does not
 * contain search term
 *
 * @param {string} RaceName
 */
export function searchResults({race: {raceName}}, searchTerm) {
  return raceName.toLowerCase().includes(searchTerm.toLowerCase());
}

/**
 * Filtering by race type
 *
 * @param {string} CoursePattern
 * return {bool}
 */
export function raceTypeFilter({race: {courses: [{coursePattern}]}}, filter) {
  return filter === 'all' || filter === coursePattern;
}

/**
 * This method will group athletes by first letter of first name
 *
 * @param {[]} athletes
 */
export function getAthletesGrouped(athletes) {
  return athletes.reduce((acc, athlete) => {
    const {DisplayName} = athlete;
    const key = DisplayName[0].toLowerCase();
    if (!(key in acc)) acc[key] = [];
    acc[key].push(athlete);
    return acc;
  }, {});
}


/**
 * Filters athletes by display name and search term.
 *
 * @param {[]} athletes
 */
export function getFilteredAthletes(athletes, searchTerm) {
  return athletes.filter(({DisplayName}) =>
    DisplayName.toLowerCase().includes(searchTerm.toLowerCase()));
}
