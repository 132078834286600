import React from 'react'
import { withTranslation } from 'react-i18next'

import { style } from '../arpModalStyles'

const UnclaimModalSpinnerHeaderComponent = (props) => {
  const {
    t
  } = props

  return(
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Unclaiming')}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t('We are removing this result from your profile.')}
      </div>
    </div>
  )
}

export const UnclaimModalSpinnerHeader = (withTranslation()(UnclaimModalSpinnerHeaderComponent))