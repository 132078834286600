import React from 'react';
import { RegBox } from '../regBox/RegBox';
import { configs } from '../../configs';
import { eventBus, streamHook } from '../../data/Streams';
import { FetchApi } from '../../lib/anuket-http';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { take } from 'rxjs/operators';

function regOptions({event}) {
  const id = (event || {}).ctliveId || 0;
  const streamId = 'ELPRegOptions-' + id;
  const stream = streamHook(streamId, {isLoading: true});

  stream.subscribe((msg) => {
    if ((msg || {}).isLoading && id) {
      eventBus.publish(streamId, {});
      FetchApi.doFetch(`${configs.chronotrackApiUrl}/event/${id}/reg-choice`)
        .pipe(take(1))
        .subscribe((_) => eventBus.publish(streamId, _.event_reg_choice || []));
    }
  });

  return stream;
}

//TODO rename - no longer Floating

export const FloatingComponentsComponent = ({regOptions, event, isMobile, t}) => (
  <div>
    <RegBox
      regOptions={regOptions}
      event={event}
      isMobile={isMobile}
      t={t}
    />
  </div>
);

FloatingComponentsComponent.defaultProps = {
  regOptions: []
};

export const FloatingComponents = connectStream({regOptions})(FloatingComponentsComponent);
