import { getTokenRacerId } from '../utils/isLoggedIn'
// import { configs } from '../configs'
// import Rollbar from 'rollbar'

/**
 * @type Rollbar
 */
let _rollbar = null

export function rollbar() {
  // if (!_rollbar) {
  //   const environment = window.location.href.startsWith('https://stage.athlinks.com') ? 'stage' :
  //     window.location.href.startsWith('https://beta-stage.athlinks.com') ? 'beta-stage' :
  //     window.location.href.startsWith('https://beta.athlinks.com') ? 'beta' :
  //     window.location.href.startsWith('https://athlinks.com') || window.location.href.startsWith('https://www.athlinks.com') ? 'production' : 'dev';

  //     _rollbar = new Rollbar({
  //     accessToken: configs.ROLLBAR_KEY,
  //     captureUncaught: true,
  //     captureUnhandledRejections: true,
  //     captureIp: 'anonymize', // GDPR requirement
  //     // enabled: process.env.NODE_ENV === 'production',
  //     payload: {
  //       environment: environment,
  //       client: {
  //         javascript: {
  //           source_map_enabled: true,
  //           code_version: process.env.REACT_APP_REACT_APP_VERSION,
  //           guess_uncaught_frames: true
  //         }
  //       }
  //     }
  //   })
  // }
  return _rollbar
}

/**
 * Error logging through anubis
 * @param user
 * @param url
 * @param msg
 * @param browserInfo
 */
export async function logError(msg, data = undefined) {
  if (rollbar()) {
    try {
      try {
        throw msg // Needed to get the stack
      }
      catch (e) {
        rollbar().critical(e, {
          user: getTokenRacerId() || undefined,
          url: window.location.href,
          data
        })
      }
    }
    catch (e) {
      // Rollbar is disabled, probably an ad blocker
      rollbar().configure({enabled: false})
    }
  }
}

/**
 * Error logging through anubis
 * @param user
 * @param url
 * @param msg
 * @param browserInfo
 */
export async function logWarning(...rest) {
  if (rollbar()) {
    try {
      rollbar().warn(...[...rest, {
        user: getTokenRacerId() || undefined,
        url: window.location.href,
      }])
    }
    catch (e) {
      // Rollbar is disabled, probably an ad blocker
      rollbar().configure({enabled: false})
    }
  }
}


/**
 * Error logging through anubis
 * @param user
 * @param url
 * @param msg
 * @param browserInfo
 */
export async function logInfo(...rest) {
  if (rollbar()) {
    try {
      rollbar().info(...[...rest, {
        user: getTokenRacerId() || undefined,
        url: window.location.href,
      }])
    }
    catch (e) {
      // Rollbar is disabled, probably an ad blocker
      rollbar().configure({enabled: false})
    }
  }
}