import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
// TODO: need to update react-icons and after remove @types/react-icons and update import
// @ts-ignore-next-line
import MarkerIcon from 'react-icons/lib/ti/location-outline';

import { getDisplayLocation } from '../../utils/location';

const defaultIcon: ReactNode = <MarkerIcon style={{ paddingBottom: 3 }} />;

interface LocationProps {
  city?: string;
  state?: string;
  country?: string;
  searchType?: 'events' | 'unclaimed' | 'athletes';
  className?: string;
  icon?: ReactNode;
}

const Location = ({
  city,
  state,
  country,
  searchType,
  className,
  icon = defaultIcon,
}: LocationProps) => {
  const locationText = getDisplayLocation(city, state, country);

  const link = !!(searchType && locationText) && `/search/${searchType}/?locationTerm=${locationText}`;

  const inner = (
    <div className={className}>
      {icon}
      <span id="location">{locationText}</span>
    </div>
  );

  return link ? <Link to={link}>{inner}</Link> : inner;
}

export { Location };
