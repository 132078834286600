import _ from 'lodash';

// Mixins
const noPace = false;

const pace = {
  imperial: 'getMinPerMi',
  metric: 'getMinPerKm'
};

const cyclePace = {
  imperial: 'getMiPerHour',
  metric: 'getKmPerHour'
};

const swimPace = {
  imperial: 'getMinPer100Yd',
  metric: 'getMinPer100M'
};

export default function(t) {
  return {
    eventTypes: [{
      id: 0,
      displayName: t('Unknown'),
      name: 'Unknown',
      noun: '',
      pace: noPace
    }, {
      id: 2,
      displayName: t('Running'),
      name: 'Running',
      noun: 'Runner',
      pace,
      icon: 'ath-run'
    }, {
      id: 3,
      displayName: t('Cycling'),
      name: 'Cycling',
      noun: 'Cyclist',
      pace: cyclePace,
      icon: 'ath-bike'
    }, {
      id: 4,
      displayName: t('Inline Skating'),
      name: 'InlineSkating',
      noun: 'Inline Skater',
      pace
    }, {
      id: 5,
      displayName: t('XC Ski'),
      name: 'SkiCrossCountry',
      noun: 'XC Skier',
      pace
    }, {
      id: 6,
      displayName: t('Wheelchair'),
      name: 'Wheelchair',
      noun: 'Para-athlete',
      pace: cyclePace
    }, {
      id: 7,
      displayName: t('Triathlon'),
      name: 'TriathlonMultisport',
      noun: 'Triathlete',
      pace: noPace,
      icon: 'ath-tri'
    }, {
      id: 8,
      displayName: t('Duathlon'),
      name: 'Duathlon',
      noun: 'Duathlete',
      pace: noPace
    }, {
      id: 9,
      displayName: t('Hiking'),
      name: 'TrailRunningHiking',
      noun: 'Hiker',
      pace
    }, {
      id: 10,
      displayName: t('Mountain Biking'),
      name: 'MountainBiking',
      noun: 'Mountain Biker',
      pace: cyclePace,
      icon: 'ath-crank'
    }, {
      id: 11,
      displayName: t('Mountain Climb'),
      name: 'MountainClimb',
      noun: 'Mountain Climber',
      pace
    }, {
      id: 12,
      displayName: t('Adventure Racing'),
      name: 'AdventureRacing',
      noun: 'Adventure Racer',
      pace: noPace
    }, {
      id: 15,
      displayName: t('Trail Running'),
      name: 'TrailRunning',
      noun: 'Trail Runner',
      pace,
      icon: 'ath-run'
    }, {
      id: 16,
      displayName: t('Swimming'),
      name: 'Swimming',
      noun: 'Swimmer',
      pace: swimPace,
      icon: 'ath-swim'
    }, {
      id: 17,
      displayName: t('Race Walking'),
      name: 'RaceWalking',
      noun: 'Race Walker',
      pace
    }, {
      id: 18,
      displayName: t('Paddleboarding'),
      name: 'Paddleboarding',
      noun: 'Paddleboarder',
      pace
    }, {
      id: 19,
      displayName: t('Snowshoe Running'),
      name: 'SnowshoeRunning',
      noun: 'Snowshoe Runner',
      pace
    }, {
      id: 20,
      displayName: t('Canoe/Kayak'),
      name: 'CanoeKayak',
      noun: 'Kayaker',
      pace
    }, {
      id: 21,
      displayName: t('Aquathlon'),
      name: 'Aquathlon',
      noun: 'Aquathlete',
      pace: noPace
    }, {
      id: 22,
      displayName: t('Aquabike'),
      name: 'Aquabike',
      noun: 'Aquabiker',
      pace: noPace
    }, {
      id: 23,
      displayName: t('Off-Road Tri'),
      name: 'OffRoadTriathlon',
      noun: 'Off-Road Triathlete',
      pace: noPace,
      icon: 'ath-tri'
    }, {
      id: 24,
      displayName: t('Off-Road Duathlon'),
      name: 'OffRoadDuathlon',
      noun: 'Off-Road Duathlete',
      pace: noPace
    }, {
      id: 25,
      displayName: t('Off-Road Aquabike'),
      name: 'OffRoadAquabike',
      noun: 'Off-Road Aquabiker',
      pace: noPace
    }, {
      id: 26,
      displayName: t('Paddle Triathlon'),
      name: 'PaddleTriathlon',
      noun: 'Paddle Triathlete',
      pace: noPace,
      icon: 'ath-tri'
    }, {
      id: 27,
      displayName: t('Stair Climb'),
      name: 'StairClimb',
      noun: 'Stair Climber',
      pace
    }, {
      id: 28,
      displayName: t('Orienteering'),
      name: 'Orienteering',
      noun: 'Orienteer',
      pace
    }, {
      id: 29,
      displayName: t('Cyclocross'),
      name: 'Cyclocross',
      noun: 'Cyclocrossist',
      pace: cyclePace,
      icon: 'ath-bike'
    }, {
      id: 30,
      displayName: t('Criterium'),
      name: 'Criterium',
      noun: 'Criterium Racers',
      pace
    }, {
      id: 31,
      displayName: t('Roller Skiing'),
      name: 'RollerSkiing',
      noun: 'Roller Skier',
      pace
    }, {
      id: 32,
      displayName: t('Obstacle'),
      name: 'Obstacle',
      noun: 'Obstacle Racer',
      pace,
      icon: 'ath-obstacle'
    }, {
      id: 33,
      displayName: t('Fun Run'),
      name: 'FunRun',
      noun: 'Fun Runner',
      pace,
      icon: 'ath-run'
    }, {
      id: 34,
      displayName: t('Untimed'),
      name: 'Untimed',
      noun: 'Athlete',
      pace: noPace
    }],

    /*
     * @param {Number} id
     *
     * @return {object}
     */
    getById(id) {
      if (typeof id === 'string') {
        id = parseInt(id);

        if (!id) {
          return false;
        }
      }
      return _.find(this.eventTypes, {id});
    },

    /*
     * @param {Number} id
     *
     * @return {string}
     */
    getNounById(id) {
      const evt = this.getById(id);
      return evt.noun;
    }
  }
};
