import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { styles } from './styles'

export const SearchState = {
  SEARCHING: 'SEARCHING',
  NO_RESULTS: 'NO_RESULTS',
  SEARCH_FAILED: 'SEARCH_FAILED',
  RESULTS_FAILED: 'RESULTS_FAILED'
};

const SearchStatus = ({
  searchState,
  retrySearch,
  searchCopy,
  altStyle = false,
  t,
  buttonLabel
}) => {
  const DEFAULT_COUNTER_VALUE = 10
  const [counter, setCounter] = useState(DEFAULT_COUNTER_VALUE)

  useEffect(() => {
    if (searchState === SearchState.SEARCHING) {
      setCounter(DEFAULT_COUNTER_VALUE)
    } else {
      const timer = counter && setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [counter, searchState])

  switch(searchState) {
    case SearchState.SEARCHING:
      return (
        <div style={styles.summarySearch.container}>
          {searchCopy[searchState] || t('Searching...')}
        </div>
      );
    case SearchState.NO_RESULTS:
      return (
        <div style={styles.summarySearch.container}>
          {searchCopy[searchState] ||
          <div>
            {t('No results found.')}<br />
            {t('Try adjusting your search terms.')}
          </div>
          }
        </div>
      );
    case SearchState.SEARCH_FAILED:
    case SearchState.RESULTS_FAILED:
      return (
        <div style={altStyle
          ? styles.summarySearch.alternateErrorContainer
          : styles.summarySearch.errorContainer
        }>
          {searchCopy[searchState] || t('Search failed.')}
          <br />
          <input
            type="button"
            value={counter ? `${t(buttonLabel)} in ${counter} ${counter === 1 ? 'second' : 'seconds'}` : t(buttonLabel)}
            disabled={!!counter}
            style={{...styles.summarySearch.button, marginTop: altStyle ? '2em' : '0.5em'}}
            onClick={() => retrySearch()}
          />
        </div>)
    default:
      return null
  }
};

SearchStatus.propTypes = {
  retrySearch: PropTypes.func,
  searchCopy: PropTypes.object,
  searchState: PropTypes.oneOf([...Object.keys(SearchState), null]),
  t: PropTypes.func,
  buttonLabel: PropTypes.string
}

SearchStatus.defaultProps = {
  searchCopy: {},
  searchState: null,
  buttonLabel: 'Try Again'
}

export default SearchStatus
