import { useTranslation } from 'react-i18next';

import { Nobles } from '../../lib/anuket';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';

import { useStyles } from './styles';

interface Race {
  raceName: string;
}

interface RaceDistancesProps {
  fetching: boolean;
  result?: {
    isLoading?: boolean;
    races?: Race[];
  };
}

const RaceDistancesComponent = ({ fetching, result }: RaceDistancesProps) => {
  const { t } = useTranslation();
  const styles = useStyles({ isFetching: !!result?.isLoading });

  const { isLoading, races = [] } = result || {};

  const showLoading = fetching || isLoading;

  const raceNames = races.reduce((acc: string[], curr: Race) => {
    if (!acc.includes(curr.raceName)) {
      acc.push(curr.raceName);
    }
    return acc;
  }, []);

  if (!raceNames.length && !showLoading) {
    return null;
  }

  return (
    <div>
      <div className={styles.title}>
        {t('Race Distances')}
      </div>

      <div>
        {showLoading && (
          <>
            <div className={styles.loadingDistance} />
            <div className={styles.loadingDistance} />
            <div className={styles.loadingDistance} />
          </>
        )}

        {!showLoading && raceNames.map((name) => (
          <div className={styles.name} key={name}>
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

export const RaceDistances = connectStream({
  result: ({ event }: { event: { athlinksId: string } }) => {
    const athlinksId = event.athlinksId;
    return Nobles.RacesNoble.getAthlinksCourses(athlinksId);
  }
})(RaceDistancesComponent);
