import queryString from 'query-string';

/**
 * Navigates to category based on number of results in each.
 * If one and only one category has results then navigates to
 *  - Category has one result -> goes to that result (event, athlete, results)
 *  - Category has more than one results -> goes to that category search page
 *
 * @param {string} term
 * @param {string} context
 * @return {*}
 */
export function goToCategory(term = '', category, history) {
  if (term.length > 3) {
    const subHistory = {
      pathname: '/search/' + category,
      search: queryString.stringify({
        ...queryString.parse(window.location.search),
        category,
        term
      })
    }
    // console.log('SearchNavigator history push', subHistory)
    history.push(subHistory);
  }
}
