import React from 'react';
import { withRouter } from 'react-router-dom';
import {AthletePhoto} from './AthletePhoto';
import User from 'react-icons/lib/md/perm-identity';
import {styles, raStyles} from './styles';
import {ViewAll} from './UpcomingEvents';
import _ from 'lodash';
import { athleteSearchClick } from '../shared/QuickLinks';

/**
 * This component holds information about related athletes.
 * Prints three rows with following, followers and rivals.
 */
export const AthleteRelated = (
  {
    athleteId,
    athlete,
    following,
    isMobile,
    rivals,
    isMe,
    t
  }) => {
  const athletes = following.result || [];
  let rivalsTranslated = t('Rivals');
  if (athlete && athlete.FName) {
    rivalsTranslated = t('{{name}}\'s Rivals', {name: athlete.FName});
  }
  if ((rivals.rivals || []).length === 0) {
    rivalsTranslated = t('No Rivals');
  }

  let followingTranslated = t('No Following');
  if (athletes.length > 0) {
    followingTranslated = t('{{count}} Following', {count: athletes.length});
  }

  return (
    <div>
      {
        ((following && !_.isEmpty(following.result)) || !isMe) &&
        <RelatedRow
          relatedType='following'
          athletes={athletes}
          fetching={following.fetching}
          linkTo={`/athletes/${athleteId}/following`}
          title={followingTranslated}
          isMobile={isMobile}
          isMe={isMe}/>
      }
      {
        ((rivals && !_.isEmpty(rivals.rivals)) || !isMe) &&
        <RelatedRow
          relatedType='rivals'
          athlete={athlete}
          athletes={rivals.rivals}
          fetching={rivals.fetching}
          linkTo={`/athletes/${athleteId}/rivals`}
          title={rivalsTranslated}
          isMobile={isMobile}
          isMe={isMe}/>
      }
      {
        following && following.result && _.isEmpty(following.result) && isMe &&
        <NoFriendsAndRivals
          isMobile={isMobile}
          t={t}
        />
      }
    </div>
  );
};

AthleteRelated.defaultProps = {
  following: {fetching: true}
};

const NoFriendsAndRivalsComponent = ({isMobile, t, history}) => {
  return (
    <div style={raStyles.noFriendsAndRivals}>
      <div style={{fontSize: 20}}>{t('Friends and rivals')}</div>
      <div style={{...styles.noUpcoming, textAlign: 'left'}}>
        {t('Follow other Athlinks members and you\'ll see a comparison of '
          + 'the races you\'ve done together.')}
      </div>
      <ViewAll
        messages={t('Search Athlinks Members')}
        type='searchMembers'
        onClickAction={(ev) => athleteSearchClick(ev, t, history)}
        isMobile={isMobile}/>
    </div>
  );
};

export const NoFriendsAndRivals = withRouter(NoFriendsAndRivalsComponent);

export const NoRelatedImage = () => {
  return (
    <div style={styles.noRelatedImage}>
      <User size={40}
            color='white'
            style={{ marginLeft: 2}}/>
    </div>
  );
};

export const NoRelatedRow = () => { //TODO: ASK
  let photos = [];
  for (let i = 0; i < 6; i++) {
    photos.push(<NoRelatedImage key={`logo-${i}`}/>);
  }
  return (
    <div style={{marginLeft: 6, padding: 0}}>
      {photos}
    </div>
  );
};

/**
 * Print row with related athletes. Can be followings, followers and rivals.
 * Prints athlete profile images and athlete names.
 *
 * @param {[]} athletes
 * @param {string} relatedType following|followers|rivals
 * @param {string} title
 * @param linkTo
 * @param athlete
 * @param isMobile
 * @param fetching
 * @param showTitle
 * @param isMe
 * @return {XML}
 * @constructor
 */
export const RelatedRowComponent = ({athletes = [], relatedType, title, linkTo, isMobile, fetching = false, showTitle = true, isMe, history}) => {
  if ((!athletes || athletes.length === 0) && !fetching && !isMe) {
    return (
      <div style={styles.suggestionRow}>
        <div style={styles.boxTitle(isMobile)}>
          {title}
        </div>
        <div
          className={`${relatedType}-sidebar-items`}
          onClick={(e) =>{
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            history.push(linkTo)
        }} >
        <NoRelatedRow/>
        </div>
      </div>
    );
  }


  const athleteCount = athletes.length;
  const remaining = athleteCount - 6;

  const logos = athletes.slice(0, 6).map(({LogoUrls, Photos, DisplayName, RacerId, RacerID}, index) =>
    <div key={`logo-${index}`} style={{display: 'inline-block'}}>
      <AthletePhoto
        isMember={!!RacerId || !!RacerID}
        key={`logo-${relatedType}-${index}`}
        name={DisplayName}
        wrapperStyle={styles.wrapperStyle(isMobile)}
        photo={LogoUrls || Photos}
        styles={styles.athletePhoto}
        width={!showTitle || !relatedType ? 35 : 45}
        height={!showTitle || !relatedType ? 35 : 45}
      />
    </div>
  );

  return (
    <div style={showTitle ? styles.suggestionRow : {}}>

      {showTitle &&
        <div style={styles.boxTitle(isMobile)}>
          {title}
          <div style={styles.boxTitleLoading(fetching)}/>
        </div>
      }

      <div style={raStyles.relatedRowContainer}>
        <div style={raStyles.relatedRowLoading(fetching)}/>

        <div
          className={`${relatedType}-sidebar-items`}
          style={raStyles.athletesHolder}
        >
          <div
            style={raStyles.athletePhotos}
            onClick={(e) =>{
              e.preventDefault()
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              history.push(linkTo)
            }}
          >
            {logos}
            <div style={raStyles.names}>
              {remaining > 0 && ` +${remaining}`}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export const RelatedRow = withRouter(RelatedRowComponent)
