import React from 'react';
import PropTypes from 'prop-types';
import { videoHeroStyles, homeText } from './styles';

const VideoHero = (props) => {
  const textStyles = homeText(props);
  const {
  	windowWidth,
  	isMobile,
		betaBannerHeight,
    t
  } = props;

  const {
  	heroContainer,
  	videoContainer,
  	video,
  	centerContent
  } = videoHeroStyles;

  const videoProps = (windowWidth < 800) ? {height: 450} : {width: windowWidth};
  return (
		<section style={{ ...heroContainer, paddingTop: betaBannerHeight }}>
			<div style={ videoContainer }>
				{ !isMobile &&
					<video
						{...videoProps}
						style={ video }
						autoPlay
						loop
            			muted
					>
						<source src={'https://d33vaoadodpfl.cloudfront.net/miami-cut.mp4'} type='video/mp4'/>
            {t('Please update your browser to support video')}
					</video>
				}

			</div>

			<div style={ centerContent(isMobile) }>

	      <h1 id='own-race-day-forever'style={ Object.assign({}, textStyles.homeTitle, videoHeroStyles.ownRaceDayForever) }>
					{t('All of Your Results in One Place')}
				</h1>
	      <div style={ Object.assign({}, textStyles.homeSubtitle, videoHeroStyles.painYouEndured )}>
          {t(
            'The pain you endured to cross the finish line is temporary. But your '
              + 'race results are forever. Claim, share and celebrate them here.'
          )}
	      </div>

				{props.children}

			</div>
		</section>
	);
};

VideoHero.propTypes = {
	windowWidth: PropTypes.number.isRequired,
	windowHeight: PropTypes.number.isRequired,
  t: PropTypes.func
};

export default VideoHero;
