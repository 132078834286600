import React from 'react';
import {Link} from 'react-router-dom';
import {styles} from './styles';
import {EventRow} from './EventRow'

export const EventComponent = (props) => {
  const slice = props.slice || 3;
  const {
    searchTerm,
    locationTerm,
    events,
    t
  } = props;
  const shownEvents = events.slice(0, slice);

  const searchQuery = searchTerm
    ? locationTerm
      ? `?locationTerm=${locationTerm}&term=${encodeURIComponent(searchTerm)}`
      : `?category=events&term=${encodeURIComponent(searchTerm)}`
    : locationTerm
      ? `?locationTerm=${locationTerm}`
      : '';
  const searchAllEventsLink = `/search/events/${searchQuery}`;

  return (
    <div>
      <Link to={searchAllEventsLink}>
        <div style={styles.topLabel}>
          {t('Events')}
        </div>
      </Link>
      <div style={styles.resultContent}>

        {shownEvents.map(({masterEventId, masterEventTitle, city, stateProv}) =>
          <EventRow
            key={masterEventId}
            masterEventId={masterEventId}
            masterEventTitle={masterEventTitle}
            city={city}
            searchTerm={searchTerm}
            stateProv={stateProv}
            styles={styles}/>
        )}

        <Link to={searchAllEventsLink}>
          {
            <span id='see-all-events-link' style={styles.moreLink}>
              {t('See all events for "{{searchTerm}}"', {searchTerm})}
            </span>
          }
        </Link>
      </div>
    </div>
  );
};
