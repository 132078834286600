import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Style from 'style-it';

import { prStyles } from './styles';
import { getResultUrl } from '../../utils/resultsHelpers';
import { DateTime } from '../shared/DateTime';
import { convertTime } from '@eventops/athlinks-lib-units';

export const PersonalRecord = ({type, distance: {CoursePattern, Rating, EventDate, EventName}, t}) => {

  const {OuterName} = CoursePattern;
  const {BestTicks, RatingO} = Rating;

  return (
    Style.it(prStyles.pseudoStyles(),
    <div style={prStyles.container} className="statistic-record">
      <div style={prStyles.title} className={'personal-record-title'}>
        {OuterName.substr(0, 8)}
      </div>

      <div style={prStyles.bestTicks} className={'personal-record-best-ticks'}>
        {convertTime({timeInMillis: BestTicks, timeUnit: 'h'}).value}
        { type === 'mainTab' &&
          <span style={prStyles.rating} className={'personal-record-rating'}>
            {t(' /\u00a0Top\u00a0{{number}}%', {number: Math.round(RatingO)})}
          </span>
        }
      </div>

      {type === 'mainTab' &&
        <div style={prStyles.location} className={'personal-record-location'}>{EventName}</div>
      }

      <div style={prStyles.date} className={'personal-record-date'}>
        <DateTime date={EventDate} month='short' showTime={false} icon={null}/>
      </div>

    </div>
    )
  );
};

export const PersonalRecords = (
  {
    distanceSummary = [],
    type,
    fetching,
    t
  }) => {

  /**
   * Sort PR by furthest Distance
   * @param {number} d1
   * @param {number} d2
   * @return {number}
   */
  const sortPRByDistance = (
    {CoursePattern: {Distance : d1}}, {CoursePattern: {Distance : d2}}
  ) => d2 - d1;

  const isHeader = (type === 'header');
  const prItemClass = (isHeader) ? 'col-4' : 'col-12 col-md-6 col-lg-4';

  return (
    <div>
      {
        isHeader &&
        <div style={prStyles.sectionTitle}>
          {t('Personal Records')}
          <span style={prStyles.sectionTitleLoader(fetching)}/>
        </div>
      }
      <div
        className={ isHeader ? 'row' : 'row mx-0 personal-records'}
        style={prStyles.recordRowContainer(isHeader)}>
        {
          (distanceSummary.length) ?
          distanceSummary.sort(sortPRByDistance).map((distance, index) => {

            const {Rating} = distance;
            const {BestECID, BestEntryID, BestEventID} = Rating;

            return (
              <div
                className={prItemClass}
                key={index}
                style={prStyles.recordContainer(isHeader)}
              >
                {type === 'mainTab'
                  ? <Link to={getResultUrl(BestEventID, BestECID, BestEntryID)}>
                      <PersonalRecord
                        key={`pr-${index}`}
                        type={type}
                        distance={distance}
                        t={t}
                      />
                    </Link>
                  : <PersonalRecord
                      key={`pr-${index}`}
                      type={type}
                      distance={distance}
                      t={t}
                    />
                }
              </div>
            );
          }).slice(0, isHeader ? 3 : distanceSummary.length)
          :
          (!fetching && distanceSummary.length === 0) &&
            <div>
              <div style={Object.assign({}, prStyles.title, prStyles.noPersonalRecordsTitle)}>
                {t('No personal Records Yet')}
              </div>
            </div>
        }
        {
          // Loading placeholder
        }
        { fetching && <div style={prStyles.recordContainerLoading(fetching, isHeader)}>
          <LoadingRecord size={prItemClass} isHeader={isHeader} />
          <LoadingRecord size={prItemClass} isHeader={isHeader} />
          <LoadingRecord size={prItemClass} isHeader={isHeader} />
        </div>
        }
      </div>
    </div>
  );
};

PersonalRecords.propTypes = {
  type: PropTypes.string,
  distanceSummary: PropTypes.array,
  isMobile: PropTypes.bool,
  fetching: PropTypes.bool,
  t: PropTypes.func
};

PersonalRecords.defaultProps = {
  fetching: false,
};

export const LoadingRecord = ({size, isHeader}) => (
  <div style={prStyles.recordContainer(isHeader)} className={size}>
    <div style={prStyles.loadingRecordTitle}/>
    <div style={prStyles.loadingRecordUnderline}/>
    <div style={prStyles.loadingRecordTime}/>
    <div style={prStyles.loadingRecordDate}/>
  </div>
);
