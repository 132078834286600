import { RosterEntry } from '../../types.rosters';
import { Pager } from '../../components/shared/Pager';

import { useStyles } from './styles';
import { RosterTable } from './RosterTable';

type Props = {
  isLoading: boolean,
  isMobile: boolean,
  roster?: {
    page: number,
    pageSize: number,
    results: RosterEntry[]
    total: number
  },
}
const RosterResults = ({
  isLoading,
  isMobile,
  roster,
}: Props) => {
  const styles = useStyles();

  if (isLoading || !roster?.results?.length) {
    return null;
  }

  const { page, pageSize, results, total } = roster

  return (
    <div className={styles.rosterResultsContainer}>
      <RosterTable
        isMobile={isMobile}
        pageSize={pageSize}
        roster={results}
      />

      <Pager
        pageNumber={page} // zero-based
        pageSize={pageSize}
        total={total}
      />
    </div>
  )
}

export { RosterResults };
