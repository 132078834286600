import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';

import { DateTime } from '../shared/DateTime';
import { homeCarouselStyles } from '../home/styles';

class BlogCardComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  state = {
    cardHovered: false,
    ctaHovered: false
  };

  getMediaUrl() {
    const { source_url, media_details = {} } = this.props;
    return media_details.sizes
      && media_details.sizes.medium_large
      && media_details.sizes.medium_large.source_url
      ? media_details.sizes.medium_large.source_url
      : source_url;
  }

  render() {
    const {
      id,
      link,
      title, // title.rendered has the post title
      excerpt, // excerpt.rendered has the title
      date,
      isMobile,
      t
    } = this.props;

    const {
      cardHovered,
      ctaHovered
    } = this.state;

    const {
      slide,
      slideImg,
      slideTitleContainer,
      slideTitle,
      slideCopy,
      slideCaption,
      slideCta
    } = homeCarouselStyles;

    const excerptChopped = excerpt.rendered.slice(0, 150);
    const excerptHtml = excerptChopped.slice(0, excerptChopped.lastIndexOf(' ')) + '...</p>';

    return (
      <div
        className={`blog-container-${id}`}
        style={ slide(cardHovered) }
        onMouseEnter={() => this.setState({cardHovered: true})}
        onMouseLeave={() => this.setState({cardHovered: false})}
      >

        <div style={ slideImg(this.getMediaUrl()) }>
          <div style={ slideTitleContainer } >
            <h4
              className={`blog-title-${id}`}
              style={ slideTitle }
              dangerouslySetInnerHTML={{__html: _.unescape(title.rendered)}}
            />
          </div>
          <p style={ slideCaption }>
            <DateTime
              date={ date }
              showTime={false}
              icon={null}
            />
          </p>
        </div>


        <div className='carousel-content'>
            <p
              style={ slideCopy(isMobile) }
              dangerouslySetInnerHTML={{ __html: excerptHtml }}
            />
            <a
              className={`blog-link-${id}`}
              style={ slideCta(ctaHovered) }
              href={ link }
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => this.setState({ctaHovered: true})}
              onMouseLeave={() => this.setState({ctaHovered: false})}
            >
              {t('read full article')}
            </a>
        </div>

      </div>
    );
  }
}

export const BlogCard = withTranslation()(BlogCardComponent);
