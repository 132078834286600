import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { LiveRaceHeader } from '../shared/LiveRaceHeader';

import { EventDetails } from './EventDetails';
import { EventHeaderTabMenu } from './EventHeaderTabMenu';
import { HeroImage } from './HeroImage';
import { useStyles } from './styles';

interface EventHeroProps {
  actionButton: ReactNode;
  erpEventId: number;
  eventId: string;
  searchQuery?: string;
  excludeSearch: boolean;
  isMobile: boolean;
  master: any;
  isLiveRaceHeaderVisible: boolean
  liveEventURL: string
}

export const EventHero = ({
  actionButton,
  erpEventId,
  eventId,
  searchQuery,
  excludeSearch,
  isMobile,
  master,
  isLiveRaceHeaderVisible,
  liveEventURL,
}: EventHeroProps) => {
  const {
    eventType,
    ctliveId,
    affiliateRaceId,
    athlinksMasterId,
    isPublishedAthlinks,
    hasHeroImage,
  } = master;

  const { t } = useTranslation();
  const styles = useStyles({});

  return (
    <>
      <HeroImage
        isMobile={isMobile}
        type={eventType}
        ctliveEventId={affiliateRaceId}
        isPublished={isPublishedAthlinks}
        hasHeroImage={hasHeroImage}
      />

      <div className={styles.stickyContainer}>
        {isLiveRaceHeaderVisible &&
          <LiveRaceHeader
            liveEventURL={liveEventURL}
            isMobile={isMobile}
            isProfile={false}
            isMe={false}
            athlete={null}
            containerStyling={null}
            t={t}
          />
        }

        <EventDetails
          actionButton={actionButton}
          eventId={eventId}
          master={master as any}
          isMobile={isMobile}
          searchQuery={searchQuery}
          excludeSearch={excludeSearch}
          type='eventHeader'
          ctliveId={ctliveId}
        />

        <EventHeaderTabMenu
          event={master}
          isMobile={isMobile}
          masterId={athlinksMasterId}
          erpEventId={erpEventId}
        />
      </div>
    </>
  );
};
