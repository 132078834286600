import React  from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../shared/styles';
import { styles } from './styles';

/**
 * Loader rectangle helper
 * @param {string|number} width
 * @param {string|number} height
 * @return {*}
 */
const loader = (width, height, isLightMode = true) => (
  <span
    style={{
      width,
      height,
      backgroundColor: isLightMode ? '#f4f4f4' : colors.darkGrey4,
      display: 'inline-block',
    }}/>
);

/**
 * Renders title and subtitle for kiosk IRP
 * @param {string} title - display name
 * @param {string} subtitle - event name
 * @param {boolean} isLoading
 * @param {boolean} isMobile
 * @return {*}
 * @constructor
 */
export const IndividualKioskContentHeader = (
  {
    title,
    bibNumber,
    isLoading,
    isMobile,
    isLightMode,
  }) => (
  <div
    style={styles.resultHeaderContainer(isLightMode)}>
    {isLoading
      ? <div style={styles.resultSubtitle(isLightMode)}> {loader('30%', 25, isLightMode)} </div>
      : <div style={styles.resultSubtitle(isLightMode)}>
          <div style={styles.bibContainer(isLightMode)}>{bibNumber}</div>
        </div>
    }
    <h1
      style={styles.resultHeading(isMobile, isLightMode)}>
      { isLoading ? loader('40%', 50, isLightMode) : title }
    </h1>
  </div>
);

IndividualKioskContentHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
