import { fonts, colors } from '../../shared/styles';

const getRowColor = (isFocused, isLightMode) => {
  if(isFocused) {
    return colors.darkBlue3;
  }
  return isLightMode ? colors.white : colors.darkBlue4;
};

const getRowTextColor = (isFocused, isLightMode) => {
  if(isFocused || !isLightMode) {
    return colors.white;
  }

  return colors.black;
};

export const styles = {
  searchViewContainer: (isLightMode) => ({
    position: 'relative',
    backgroundColor: isLightMode ? colors.lightBlue6 : colors.lightBlue7,
    paddingTop: 0,
    minHeight: '100vh',
    height: '100%'
  }),
  card: (isLightMode) => ({
    boxShadow: colors.cardShadow,
    backgroundColor: isLightMode ? colors.white : colors.darkBlue4,
    padding: '1.25rem',
    minWidth: '23em',
    borderRadius: 4,
    maxWidth: 969
  }),
  headerContainer: {
    textAlign: 'center'
  },
  headerIconContainer: {
    marginTop: '1.875rem'
  },
  headerIconImage: {
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)'
  },
  headerMainHeading: (isMobile, isLightMode, isMainPage) => ({
    color: isLightMode ? colors.darkType : colors.white,
    fontSize: `${ isMobile ? 1.5 : 2 }rem`,
    fontFamily: 'ProximaNovaBold',
    textRendering: 'optimizeLegibility',
    maxWidth: !isMobile ? '72vw': '100vw',
    marginTop: `${ isMobile ? 4.75 : 1 }rem`,
    marginBottom: '0.2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    lineHeight: 1.15,
    textAlign: 'center',
    alignSelf: 'center',
    width: isMainPage && isMobile ? '100%' : 'auto',
    display: isMainPage && isMobile ? 'flex' : 'block',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20
  }),
  headerSubHeading: (isLightMode) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    marginTop: 8,
    marginBottom: 10,
    fontSize: '1rem',
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase'
  }),
  searchFormHeading: (isLightMode) => ({
    color: isLightMode ? colors.lightGrey6 : colors.white,
    fontWeight: 'normal',
    fontSize: '0.8rem',
    marginTop: 0,
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase',
    letterSpacing: '.5px',
    marginBottom: '0.625rem'
  }),
  searchInputContainer: {
    justifyContent: 'center',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 !important',
    marginTop: 35
  },
  searchInputLoader: {
    width: '2.5rem',
    height: '2.5rem',
    top: 4,
    right: 4,
    position: 'absolute'
  },
  searchInputHint: (isLightMode) => ({
    color: isLightMode ? colors.black : colors.white,
    paddingLeft: '0.625rem',
    top: '11px',
    pointerEvents: 'none',
    fontSize: '1.125rem'
  }),
  searchInput: (isLightMode) => ({
    boxSizing: 'border-box',
    height: '3rem',
    padding: '0.5rem',
    border: `2px solid ${isLightMode ? colors.lightGrey6 : colors.white}`,
    backgroundColor: isLightMode ? colors.white : colors.lightBlue8,
    boxShadow: 'inset 0 1px 2px rgba(74, 74, 74, 0.1)',
    fontFamily: 'ProximaNovaRegular',
    fontSize: '1.125rem',
    fontWeight: 'normal',
    borderRadius: 4,
    lineHeight: 1.5,
    // paddingLeft: 45,
    color: isLightMode ? colors.black : colors.white,
    transition: 'box-shadow 0.5s, border-color 0.25s ease-in-out',
    position: 'static',
  }),
  searchSuggestionDropdown: (isLightMode) => ({
    backgroundColor: isLightMode ? `${colors.white} !important` : `${colors.darkBlue4} !important`,
    border: '1px solid #fafafa',
    boxShadow: '0 0 9px 5px rgba(0,0,0,.05)',
    width: '100%',
    maxHeight: '20em'
  }),
  resultContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resultBackButtonContainer: {
    alignSelf: 'flex-start',
    position: 'absolute',
    marginLeft: 20,
    marginTop: 20
  },
  resultBackButton: {
    width: '11.25rem'
  },
  resultBackButtonLabel: {
    fontSize: '1rem',
    lineHeight: 1,
    padding: '1.25rem',
    textTransform: 'none',
  },
  resultBackButtonRipple: {
    margin: '0 0 1rem 0',
    padding: '0.75rem 0.875rem',
    border: '1px solid transparent',
    borderRadius: 0,
    transition: 'background-color 0.25s ease-out, color 0.25s ease-out',
    cursor: 'pointer',
  },
  resultHeaderContainer: (isLightMode) => ({
    textAlign: 'center',
    width: '100%',
    margin: 0,
    marginBottom: '1.125rem',
    borderBottom: `1px solid ${isLightMode ? colors.greyLine : colors.softBlueLine}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20
  }),
  resultHeading: (isMobile, isLightMode) => ({
    color: isLightMode ? colors.darkType : colors.white,
    margin: 0,
    fontSize: `${isMobile ? 2.5 : 2.6}rem`,
    lineHeight: `${isMobile ? 2.5 : 3.125}rem`,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: 'ProximaNovaBold'
  }),
  resultSubtitle: (isLightMode) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '0.875rem',
    margin: 0,
  }),
  resultsContentContainer: {
    justifyContent: 'center',
    margin: 0,
    width: '100%',
  },
  resultsEventInfo: {
    textAlign: 'center',
    width: '100%',
  },
  resultsEventName: (isMobile, isLightMode) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '1.5625rem',
    lineHeight: '1.5625rem',
    margin: 0,
  }),
  resultsEventDetails: (isMobile, isLightMode) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  timePaceContainer: {
    margin: '1.2rem 0 1rem 0',
  },
  timePaceCenterContent: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  timePaceHeader: (isLightMode, isBubble) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    textTransform: 'uppercase',
    fontFamily: 'ProximaNovaBold',
    fontSize: 14,
    lineHeight: '1rem',
    letterSpacing: 0.5,
    marginBottom: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    maxWidth: isBubble ? 110 : 'auto',
    marginTop: 0,
  }),
  timePaceNumbers: (isMobile, isLightMode) => ({
    fontFamily: 'LatoBold',
    fontSize: isMobile ? 64 : 70,
    lineHeight: 1.2,
    color: isLightMode ? colors.darkType : colors.white
  }),
  notFinisher: (isLightMode) => ({
    ...fonts.default,
    fontSize: 45,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    color: isLightMode ? colors.darkType : colors.white
  }),
  timePaceItemContainer: {
    textAlign: 'center',
  },
  timePaceRank: (isLightMode) => ({
    color: isLightMode ? colors.darkType : colors.white,
    fontSize: 28,
    letterSpacing: -.5,
    fontFamily: 'LatoBold',
    lineHeight: 1.2
  }),
  timePaceRankTotal: (isLightMode) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    fontFamily: 'LatoBold',
    lineHeight: 1.2,
    fontSize: 16
  }),
  typeaheadKioskContainer: (isHovered = false, isSelected = false, isLightMode = true) => ({
    display: 'flex',
    width: '100%',
    height: '5em',
    padding: 10,
    color: getRowTextColor(isHovered || isSelected, isLightMode),
    backgroundColor: getRowColor(isHovered || isSelected, isLightMode),
    flexDirection: 'row',
  }),
  kioskEmailContainer: (isLightMode) => ({
    margin: 0,
    justifyContent: 'flex-end',
    position: 'relative',
    border: `1px solid ${isLightMode ? colors.greyLine : colors.softBlueLine}`,
    borderRadius: 8,
    backgroundColor: isLightMode ? colors.lightGray7 : colors.darkBlue4,
    padding: `16px 16px 16px 16px`,
    textAlign: 'center',
    minHeight: '155px',
    alignItems: 'center'
  }),
  kioskRegEmailButton: (isDisabled) => ({
    marginBottom: '1rem',
    position: 'relative',
    boxShadow: 'none !important',
    pointerEvents: isDisabled ? 'none' : 'auto',
    // if not in pixels
    // Material button broken
    height: 55
  }),
  buttonIconStyle: {
    marginLeft: 0,
    width: '50px',
    position: 'absolute',
    left: '5px',
    top: '4px'
  },
  kioskManualEmailButton: (isDisabled) => ({
    float: 'left',
    position: 'relative',
    pointerEvents: isDisabled ? 'none' : 'auto',
    borderRadius: 0
  }),
  kioskEmailButtonLabel: {
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontFamily: 'ProximaNovaBold',
    letterSpacing: 0.5,
    verticalAlign: 'middle',
    paddingRight: 55,
    paddingLeft: 95
  },
  kioskEmailButtonRipple: {
    margin: '0 0 1rem 0',
    padding: '0.75rem 0.875rem',
    border: '1px solid transparent',
    borderRadius: 0,
    transition: 'background-color 0.25s ease-out, color 0.25s ease-out'
  },
  emailStatusMessage: (hasError) => ({
    position: 'absolute',
    bottom: '0px',
    color: hasError ? colors.error : colors.success
  }),
  emailInfoMessage:(isLightMode) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '0.75rem',
    textAlign: 'left'
  }),
  emailModalLinks: {
    color: colors.blue,
    fontSize: '0.75rem'
  },
  loaderContainer: {
    width: '3.75rem',
    height: '	3.75rem',
    top: '0.375rem',
    right: '-1.875rem',
    position: 'absolute'
  },
  noResultsTextContainer: (isLightMode) => ({
    'display': 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '3em',
    fontSize: '1em',
    color: isLightMode ? colors.black : colors.white
  }),
  colorSwitchContainer: {
    width: 150,
    height: 36,
    right: '40px',
    bottom: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    border: '2px solid white',
    borderRadius: 4
  },
  switchButton: (mode, isSelected) => ({
    flexGrow: 1,
    minWidth: '25%',
    textAlign: 'center',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.85em',
    cursor: 'pointer',
    textTransform: 'uppercase',
    background: isSelected  ? 'white' : 'none',
    color: isSelected ? colors.darkBlue4 : 'white'
  }),
  kisokResultRow: (width = null, isBold = false, smallRow = false) => ({
    display: 'flex',
    width,
    maxWidth: width,
    cursor: 'pointer',
    fontSize: '1rem',
    justifyContent: 'left',
    fontWeight: isBold ? '700' : 'auto',
    alignItems: 'center',
    padding: smallRow ? '0px 10px' : '0px'
  }),
  backButton: (isLightMode, isHovered) => ({
    height: 42,
    borderRadius: 4,
    paddingLeft: 40,
    paddingRight: 15,
    border: `1px solid ${isLightMode ? colors.darkBlue3 : colors.white}`,
    background: isHovered ? `rgba(22,169,225,0.05)` : 'transparent',
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    zIndex: 99,
    color: isLightMode ? colors.darkBlue3 : colors.white,

    cursor: 'pointer'
  }),
  backArrow: (isLightMode) => ({
    position: 'absolute',
    top: 14,
    left: 17,
    fontSize: 14,
    filter: !isLightMode ? 'brightness(0) invert(1)' : 'none',
    color: isLightMode ? colors.darkBlue3 : colors.white,
  }),
  cardHeader: {
    width: '100%',
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  cardHeaderTitle: {
    fontSize: '1.6875rem'
  },
  bibContainer: (isLightMode) => ({
    width: 95,
    height: 45,
    marginRight: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `5px solid ${colors.lightBlue5}`,
    borderRadius: 4,
    fontFamily: 'LatoBold',
    lineHeight: 1.2,
    color: isLightMode ? colors.darkType : colors.white,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderTop: `15px solid ${colors.lightBlue5}`,
    boxSizing: 'content-box'
  }),
  courseName: (isLightMode) => ({
    border: `1px solid ${colors.greyLine}`,
    padding: '2px 15px',
    borderRadius: 25,
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase',
    color: isLightMode ? colors.grey : colors.white
  }),
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  emailIcon: {
    position: 'absolute',
    marginLeft: 0,
    left: 18,
    top: 8,
  },
  timePaceWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paceRankWrap: {
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 7
  },
  bracketRowWrap: (isMobile) => ({
    minWidth: 130,
    paddingBottom: 8,
    paddingLeft: isMobile ? 5 : 12,
    paddingRight: isMobile ? 5 : 12,
  }),
  qrCode: {
    position: 'absolute',
    top: '0px',
    left: '0px'
  },
  errorContainer: (isLightMode) => ({
    color: isLightMode ? colors.textColor : colors.white,
    cursor: 'initial',
    ...fonts.default,
    fontSize: 18,
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'none',
    transition: 'all 0.3s',
    width: '100%',
    height: '100%'
  }),
  errorButton: {
    borderColor: colors.error,
    borderRadius: 3,
    color: colors.error,
    cursor: 'pointer',
    ...fonts.default,
    fontSize: 16,
    fontWeight: 'bold',
    margin: '.5em',
    padding: '.25em 3em',
    textTransform: 'uppercase',
    width: 'initial',
    backgroundColor: colors.white,
  }
}
