import { IntervalRaceType, TimeUnits } from "../types.results";

export default function convertRaceTypeToTimeUnits(raceType: IntervalRaceType): TimeUnits {
  switch (raceType) {
    case 'bike':
      return 'hr'
    case 'run':
    case 'swim':
      return 'min'
    default:
      return 'ms'
  }
}