import {withTranslation} from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles'
import { Link } from 'react-router-dom';
import { FBShare } from '../shared/icons';
import { TWShare } from '../shared/icons';
import { InstaShare } from '../shared/icons';
import {LanguageSwitcher} from '../shared/LanguageSwitcher';
import {AthlinksLogo} from '../../components/shared/AthlinksLogo';

class FooterComponent extends Component {

  static propTypes = {
    isMobile: PropTypes.bool,
    t: PropTypes.func
  };

  // Get menu items returned by column
  getMenu = () => {
    const {
      isMobile,
      t
    } = this.props;

    return {
      col1: [
        { name: t('Blog'), link: 'http://blog.athlinks.com', target: '_blank' },
        { name: t('About'), link:'/about', relative: true },
        { name: t('Careers'), link: 'https://careers.lifetime.life/c/general-events-jobs', target: '_blank' },
        { name: t('Accessibility Policy'), link: '/accessibility', relative: true },
      ],
      col2: [
        { name: t('Help'), link: 'https://athlinks.zendesk.com/hc/en-us', target: '_blank' },
        { name: t('Advertise'), link: '/advertise', relative: true },
        { name: 'ChronoTrack', link: 'https://www.chronotrack.com', target: '_blank' },
      ],
      col3: [
        { name: isMobile ? <FBShare /> :'Facebook', link: 'https://www.facebook.com/Athlinks/', target: '_blank' },
        { name: isMobile ? <TWShare /> : 'Twitter', link: 'https://twitter.com/athlinks', target: '_blank' },
        { name: isMobile ? <InstaShare /> : 'Instagram', link: 'https://www.instagram.com/athlinks/', target: '_blank' },
      ]
    }
  };

  buildColLinks = (linkData, customStyle) => {
    return linkData.map(({name, link, target, label, relative}, index) => {
      if(relative) {
        return (
          <Link className='footer-links'
             key={index}
             style={{...styles().a, ...customStyle}}
             to={link}
          >
            {name}
          </Link>
        )
      }

      return (
        <a className='footer-links'
           key={index}
           style={{...styles().a, ...customStyle}}
           href={link}
           target={ target || null }>
          {name}
        </a>
      );
    })
  };

  render() {

    const {
      isMobile,
      t
    } = this.props;

    const year = new Date().getUTCFullYear();
    const _s = styles(this.props);

    // on mobile justifyContent: 'center'
    const customNavProps = isMobile ? {
      style: _s.customNavMobile } : null;

    const mobileSocColStyle = _s.mobileSocialCol;

    const linksData = this.getMenu();

    const col1Links = this.buildColLinks(linksData.col1);
    const col2Links = this.buildColLinks(linksData.col2);
    const col3Links = this.buildColLinks(linksData.col3, isMobile? { padding: 10 } : null);

    return (
      <footer style={ _s.footer } id="footer-container">
        <div className="container" style={ _s.footerContainer(isMobile) }>
          <div className="row">

            {/* Logo */}
            <div style={ _s.col_logo(isMobile) } className={ isMobile? 'col-12' : 'col-4' }>
              <Link to="/home" style={ _s.col_logo_a } id="footer-logo">
                <AthlinksLogo showFullLogo={true} width={'165px'} height={'36px'}/>
              </Link>
            </div>

            {/* Nav elements */}
            <nav
              className={ isMobile? 'col-12 row' : 'col-8 row' }
              {...customNavProps}
            >
              {
                !isMobile ?
                  <div className='row col-12'>
                    <div className='col-md-3' id='footer-col-1'>{col1Links}</div>
                    <div className='col-md-3' id='footer-col-2'>{col2Links}</div>
                    <div className='col-md-3' id='footer-col-3'>{col3Links}</div>
                  </div> :
                  <div className='row col-12'>
                    <div className='col-6' id='footer-col-1'>{col1Links}</div>
                    <div className='col-6' id='footer-col-2'>{col2Links}</div>
                    <div className='col-12' id='footer-col-3' style={mobileSocColStyle}>{col3Links}</div>
                  </div>
              }
            </nav>
          </div>


          {/* year footer  */}
          <div className="row" style={{ justifyContent: 'flex-end' }}>
            <div className={isMobile ? 'col-12 mt-0' : 'col-8' } style={_s.copyright_row(isMobile)}>
              <div style={_s.copyright_text(isMobile)}> &#169; 2001 - { year }, Athlinks </div>

              {/* privacyPolicy link  */}
              <Link to="/privacy" style={_s.copyLink(isMobile)} className='footer-links'>
                {t('Privacy Policy')}
              </Link>

              {/* CCPA link  */}
              <Link to="/ccpa" style={_s.copyLink(isMobile)} className='footer-links'>
                {t('CCPA')}
              </Link>

              {/* MHMD link  */}
              <Link to="/mhmd" style={_s.copyLink(isMobile)} className='footer-links'>
                {t('MHMD')}
              </Link>

              {/* GDPR link  */}
              <Link to="/gdpr" style={_s.copyLink(isMobile)} className='footer-links'>
                {t('GDPR')}
              </Link>

              {/* terms link  */}
              <Link to="/terms" style={_s.copyLink(isMobile)} className='footer-links'>
                {t('Terms')}
              </Link>
              <div style={_s.languageSwitcher(isMobile)}>
                <LanguageSwitcher />
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export const Footer = withTranslation()(FooterComponent);
