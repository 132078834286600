import React from 'react';
import PropTypes from 'prop-types';
import { _athletes } from './styles';
import { AthleteRow } from './AthleteRow';
import {
  isLoggedIn as amILoggedIn,
  shouldShowSignUpBanner,
} from '../../utils/isLoggedIn';
import { isAFriend } from '../../shared/util';

export const AthleteList = (
  {
    athletes,
    isMobile,
    afterFollow,
    friends: {result},
    t
  }) => {
  const isLoggedIn = amILoggedIn();
  if(athletes === '') return null;
  else return(
    <div style={ _athletes.table(isMobile) }>
      {
        athletes.map((
          {
            racerId,
            avatarUrl,
            displayName,
            gender,
            age,
            city,
            country,
            stateProv,
            totalRaces,
            raceCategs
          }, ix) =>
          <AthleteRow
            key={racerId}
            athleteId={racerId}
            avatarUrl={avatarUrl}
            isFriend={isAFriend(result, racerId)}
            name={displayName}
            gender={gender}
            isMobile={isMobile}
            age={age}
            city={city}
            state={stateProv}
            country={country}
            isLoggedIn={isLoggedIn}
            total={totalRaces}
            raceCategs={raceCategs}
            afterFollow={afterFollow}
            showSignUpBanner={ix === 2 && shouldShowSignUpBanner()}
            t={t}
          />
        )
      }
    </div>
  );
};

AthleteList.propTypes = {
  auth: PropTypes.object,
  t: PropTypes.func
};


