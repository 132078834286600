import React from 'react'
import {getValidActivityTrackerNames} from './VRPostTimeModal'
import {
  Button,
  Dialog,
  withStyles,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withTranslation } from 'react-i18next'
import useMediaQuery from '@material-ui/core/useMediaQuery';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default withTranslation()(({t, open, handleClose}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen={fullScreen}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('Approved Activity Trackers')}
      </DialogTitle>
      <DialogContent dividers>
        {getValidActivityTrackerNames()
          .sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : a.toLowerCase() < b.toLowerCase() ? -1 : 0)
          .map((x) => (
          <Typography key={x} gutterBottom>
            {x}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
})