import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import HomeSectionTitle from './HomeSectionTitle';
import { fakeNewsfeedStyles } from './styles';
import _ from 'lodash';
import {configs} from '../../configs';

const breakpoints = {
	col2: 576,
	col3: 768
};

const images = [
	'event-countdown.png',
	'event-results.png',
	'suggested-top10.png',
	'friend-result.png',
	'event-update.png',
	'friend-joined.png',
	'friend-update.png',
	'suggested-blog.png',
	'friend-update2.png',
	'suggested-events.png',
];
const imgPath = (index) => (`${configs.cdnUrl}/images/home/newsfeed/${images[index]}`);

const NewsFeedImage = ({source}) => (
	<img src={source} alt={''} style={fakeNewsfeedStyles.fakeCard} />
);


class NewsFeedBladeComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

	constructor(props) {
		super(props);

		this.state = {
			nextImage: 9,
			nextColumn: 1,
			column1: [0,3,6],
			column2: [1,4,7],
			column3: [2,5,8],
		};

		this.currentTimeout = null;

		this.startFeed();
	}

	getNextColumn() {
		const { windowWidth } = this.props;
		const { nextColumn } = this.state;

		const maxColumn = (windowWidth < breakpoints.col2) ? 1 :
											(windowWidth < breakpoints.col3) ? 2 : 3;

		const nextColumnIndex = (nextColumn >= maxColumn) ? 1 : (nextColumn + 1);

		return (maxColumn === 1) ? maxColumn : nextColumnIndex;
	}

	addImage() {
		const {
			nextImage,
			nextColumn
		} = this.state;

		const targetColumn = `column${nextColumn}`;
		let newColumnIndexes = _.cloneDeep(this.state[targetColumn]);
		let nextImageIndex = _.cloneDeep(nextImage);

		while (newColumnIndexes.indexOf(nextImageIndex) !== -1) {
			nextImageIndex = (nextImageIndex >= images.length-1) ? 0 : (nextImageIndex + 1);
		}

		const columnLength = newColumnIndexes.unshift(nextImageIndex);

		if (columnLength >= 5) {
			newColumnIndexes.pop();
		}

		const newImageIndex = (nextImageIndex >= images.length-1) ? 0 : (nextImageIndex + 1);

		this.setState({
			[targetColumn]: newColumnIndexes,
			nextImage: newImageIndex,
			nextColumn: this.getNextColumn()
		});
	}

	setNewTimeout() {
		const waitTime = Math.round(Math.random() * 1500) + 1000;

		this.currentTimeout = window.setTimeout(() => {
			this.addImage();
			this.setNewTimeout();
		}, waitTime);
	}

	startFeed() {
		this.setNewTimeout();
	}

	renderColumn(columnArray) {
		return columnArray.map((imgIndex) => (
			 <NewsFeedImage key={imgIndex} source={imgPath(imgIndex)}/>
		));
	}

	componentWillUnmount() {
		window.clearTimeout(this.currentTimeout);
	}

	render() {
	  const {
	    windowWidth,
      t
	  } = this.props;

	  const {
	  	column1,
	  	column2, // 576px +
	  	column3 // 768px +
	  } = this.state;

	  const {
	  	sectionContainer,
	  	sectionBottomGradient,
	  	titleContainer,
	  	newsContainer
	  } = fakeNewsfeedStyles;

		return (
			<section style={ sectionContainer }>
	      <div style={ titleContainer }>
	        <HomeSectionTitle
	        	title={t('Newsfeed (Coming Soon)')}
	        	titleStyle={fakeNewsfeedStyles.titleStyle}
	        	subtitle={t('What\'s Happening on Athlinks?')}
	        	subtitleStyle={fakeNewsfeedStyles.headStyle}
	        />
	      </div>

	      <div className='container' style={ newsContainer }>
	      	<div className='row'>
	      		<div className='col-xs-12 col-sm-6 col-md-4'>
	      			{this.renderColumn(column1)}
	      		</div>

	      		{ (windowWidth >= breakpoints.col2) ?
		      		<div className='col-xs-12 col-sm-6 col-md-4'>
		      			{this.renderColumn(column2)}
		      		</div>
		      		: null
		      	}

		      	{ (windowWidth >= breakpoints.col3) ?
		      		<div className='col-xs-12 col-sm-6 col-md-4'>
		      			{this.renderColumn(column3)}
		      		</div>
		      		: null
		      	}
	      	</div>
	      </div>

	      <div style={ sectionBottomGradient }>
	      </div>
			</section>
		);
	}
}

export const NewsFeedBlade = withTranslation()(NewsFeedBladeComponent);
