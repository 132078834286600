import React, { Component } from 'react';
import {withTranslation, Trans} from 'react-i18next';
import { Link } from 'react-router-dom';
import { colors } from '../../shared/styles';
import { inIframe } from '../../utils/inIframe';

class CookiesAlertComponent extends Component {

  /**
   * We will have to use state in order to force re-rendering on agree
   * @type {{accepted: boolean}}
   */
  state = {
    accepted: false,
  };

  setAccepted = () => {
    if (!inIframe()) {
      localStorage.setItem('acceptedCookie', '1');
    }
  };

  getAccepted = () => {
    if (inIframe()) return '1';
    else return localStorage.getItem('acceptedCookie');
  };

  onAgree = () => {
    this.setAccepted();
    this.setState({accepted: true});
  };

  shouldShowCookiesAlert = () => !this.state.accepted
    && this.getAccepted() !== '1';

  button = (labelId, onClick = () => {}, style = {}) => (
    <button
      onClick={onClick}
      style={{
        backgroundColor: colors.lightBlue4,
        fontSize: 16,
        color: colors.white,
        border: 'none',
        padding: '10px 30px',
        borderRadius: 3,
        fontWeight: 'bold',
        cursor: 'pointer',
        margin: this.props.isMobile ? '20px 0 0 0' : '0 5px',
        width: this.props.isMobile ? '100%' : 'unset',
        textTransform: 'uppercase',
        ...style
      }}>
      {this.props.t('okay, got it')}
    </button>
  );

  privacyPolicyLink = () =>
    <Link
      to="/home/privacy"
      onClick={this.onAgree}
      style={{color: '#3497c7'}}>
      {this.props.t('Privacy Policy')}
    </Link>;

  render() {
    if(!this.shouldShowCookiesAlert()) return null;
    else return (
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          textAlign: 'center',
          color: colors.white,
          padding: '30px 0',
          zIndex: this.props.isMobile ? 100 : 4,
        }}>
        <div className='container'>
          <div className='row' style={{justifyContent: 'center'}}>
            <div className='col-md-7 col-12' style={{textAlign: 'left'}}>
              <Trans i18nKey="userMessagesUnread">
                This website uses cookies to improve user experience. By using our website you consent to all cookies in accordance with our {this.privacyPolicyLink()}
              </Trans>
            </div>
            <div className='col-md-3 col-12' style={{textAlign: 'center'}}>
              { this.button('okay, got it', this.onAgree, {}) }
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export const CookiesAlert = withTranslation()(CookiesAlertComponent);
