import React from 'react';
import Style from 'style-it';
import {_filterTab} from './styles';

const FilterTab = ({
  onClickCallback,
  type,
  active,
  label,
  isMobile,
  content,
  hasSelectedValue,
  disabled
}) => {
  return (
    Style.it(_filterTab.filterTabPseudoStyles(hasSelectedValue),
    <div 
      onClick={() => onClickCallback(type)}
      className="filterTabWrapper"
      style={_filterTab.wrapper(active, hasSelectedValue)}
    >
      <div style={_filterTab.filterTabClickable}>
        <span style={_filterTab.label(active, hasSelectedValue, disabled)}>{label}</span>
      </div>
      {active && 
        <div style={_filterTab.content(isMobile)}>
          {React.cloneElement(content, { isMobile: isMobile } )}
        </div>
      }
    </div>
  ))
};

export default FilterTab;
