import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import  ArrowRight  from 'react-icons/lib/fa/angle-right';
import {rivalStyles} from '../styles';
import Style from 'style-it';

class RivalItemComponent extends Component {
  state = {
    hovered: false
  };

  render() {
    const {
      rival,
      isMobile,
      t
    } = this.props;

    // TODO: Get rival's city

    const {
      DisplayName, StateProvAbbrev, CountryID3, Wins, Losses, Gender, Age
    } = rival;

    return (
      Style.it(rivalStyles.pseudoStyles(isMobile),
        <div
          className="row mx-0 rivalRow"
          style={rivalStyles.listRow(this.state.hovered)}
          onMouseEnter={() => this.setState({hovered: true})}
          onMouseLeave={() => this.setState({hovered: false})}
        >
          <div className="col-7 col-lg-6" style={rivalStyles.rowPersonCol}>
            <div style={rivalStyles.rowName(isMobile)} className='rival-name'>
              {DisplayName}
            </div>
            <div>
              <span style={rivalStyles.rowLocation}>
                {t('{{stateProv}}, {{country}}', {stateProv: StateProvAbbrev, country: CountryID3})}
              </span>
            </div>
          </div>
          <div className='col-5 col-lg-6 px-0' style={rivalStyles.rowDataContainer}>
            {
              !isMobile &&
              [
                <div
                  className="col pl-0"
                  style={rivalStyles.rowDataCol}
                  key='gender-age'
                >
                  {`${Gender.toUpperCase()} ${Age}`}
                </div>,
                <div
                  className="col"
                  style={rivalStyles.rowDataCol}
                  key='state'
                >
                  {StateProvAbbrev}
                </div>
              ]
            }
            <div className="col" style={rivalStyles.rowDataCol}>
              {Wins}
            </div>
            <div className="col" style={{
              ...rivalStyles.rowDataCol,
              paddingRight: !isMobile ? 20 : null
            }}>
              {Losses}
            </div>
            <div className="col-1 px-0">
              <ArrowRight color="#ddd" size={35} className="rightArrow"/>
            </div>
          </div>
        </div>
      )
    );
  }
}

export const RivalItem = withTranslation()(RivalItemComponent);
