import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { styles } from './styles';
import _ from 'lodash';

class FooterLanguageSwitcher extends Component {

  static propTypes = {
    value: PropTypes.string,
    languages: PropTypes.array,
    onChange: PropTypes.func
  };

  state = {
    showLanguages: false,
  };

  constructor(props) {
    super(props);
    this.toggleLanguages = this.toggleLanguages.bind(this);
  }

  toggleLanguages(selected = null) {
    if(selected && _.isString(selected) && this.props.onChange) this.props.onChange(selected);
    this.setState({ showLanguages: !this.state.showLanguages })
  }

  handleClickOutside = () => this.setState({ showLanguages: false });

  render() {
    const { value, languages, isKiosk } = this.props;
    const { showLanguages } = this.state;
    const langToLanguageMap = {
      en: 'English',
      nl: 'Nederlands',
      fr: 'Français',
      es: 'Español',
      ru: 'русский'
    };
    return (
      <div style={styles.container} id="change-language">
        <div style={styles.selectedLanguage} onClick={this.toggleLanguages}>
          { value && langToLanguageMap[value]}
          <div style={showLanguages ? styles.arrowUp : styles.arrowDown} />
        </div>
        {
          showLanguages &&
            <div style={styles.languagesListContainer(isKiosk)}>
            {
              languages
              .filter((lang) => lang !== value)
              .map((lang, index) => (
                <div
                  id={`language-${lang}`}
                  key={index}
                  style={styles.language}
                  onClick={() => this.toggleLanguages(lang)}
                >
                  {langToLanguageMap[lang]}
                </div>
              ))
            }
            </div>
        }
      </div>
    );
  }

}

export default onClickOutside(FooterLanguageSwitcher)
