import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { configs } from '../../../configs';
import { saveEntryDetailUnofficialOrVirtualTime } from '../../../data/VRStreams'
import { Loading } from '../../../components/shared/Loading'
import './styles'
import { ClaimStatus } from '../../../data/UnclaimedSearchStreams';
import { getTokenRacerId, isLoggedIn } from '../../../utils/isLoggedIn';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { LogInOrSignUpIndependent } from '../../modal/LogInOrSignUpIndependent'
import ApprovedTrackersModal from './VRApprovedTrackerModal'
// import styles from './styles'

export const formatTime = (t) => {
  const h = Math.floor(t / (60 * 60))
  const m = Math.floor((t - (h * 60 * 60)) / 60)
  const s = t - (h * 60 * 60) - (m * 60)
  return `${zeroPad(isNaN(h) ? 0 : h)}:${zeroPad(isNaN(m) ? 0 : m)}:${zeroPad(isNaN(s) ? 0 : s)}`
}

export const zeroPad = (n) => {
  const x = n.toFixed(0)
  return x.length === 1 ? `0${n}` : `${n}`
}

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, closing, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && !closing ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    '& .MuiTextField-root': {
      width: 57,
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * Primaery Button
 */
export const PrimaryButton = withStyles({
  root: {
    background: '#2B314E',
    width: 300,
    height: 35,
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 18,
    '&:hover': {
      background: '#2B314E',
      border: 'none'
    },
  },
  label: {
    textTransform: 'uppercase',
    color: '#ffffff'
  },
})(Button);

const SubmitTimeButton = withStyles({
  root: {
    background: '#2B314E',
    width: 300,
    height: 35,
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    '&:hover': {
      background: '#2B314E',
      border: 'none'
    },
  },
  label: {
    textTransform: 'uppercase',
    color: '#ffffff'
  },
})(Button);

/**
 * Text Inputs
 */
const TextInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: 57,
      backgroundColor: '#FCFCFC',
      height: 45,
    },
    '& .MuiError': {
      borderColor: 'red',
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 10,
      // marginRight: 18
    }
  },
})(TextField);

const TextInputDisabled = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: 57,
      height: 45,
      backgroundColor: '#C0C0C0'
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 10,
      // marginRight: 18
    }
  },
})(TextField);

const TextInputLong = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: '100%',
      backgroundColor: '#FCFCFC',
      height: 45,
    },
    '& .MuiError': {
      borderColor: 'red',
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }
  },
})(TextField);


const TextInputLongDisabled = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: '100%',
      height: 45,
      backgroundColor: '#C0C0C0'
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }
  },
})(TextField);

/**
 * Finsih Time Modal Tite StyledComponent
 */

const PrimaryDialogTitle = withStyles({
  root: {
    '& .MuiTypography-root': {
      fontFamily: 'ProximaNovaSemiBold',
      fontSize: '23pt',
      color: '#4a4a4a'
    },
    '& .MuiTypography-body1-root': {
      fontFamily: 'ProximaNova',
      fontSize: '14pt',
      color: '#9b9b9b'
    },
  }

})(DialogTitle)

 /**
  * Post Your FInish Time Modal CSS (injected into the DOM)
  */
 const dialogStyles = {
  root: {
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    '& .MuiDialogContent-root': {
      paddingTop: 16,
      paddingRight: 24,
      paddingBottom: 16,
      paddingLeft: 24
    },

    '& .MuiDialog-paperWidthSm':{
      width: 600,
      height: 480,
    },
    '& .MuiDialogContent-dividers':{
      border: 'none',
    },
    '& .MuiTextField-root':{
      marginRight: 12,
    },
    '& .MuiTypography-root': {
      fontFamily: 'ProximaNovaRegular',
      fontSize: '12pt',
      color: '#4a4a4a'
    },
    '& .MuiTypography-h6': {
      fontFamily: 'ProximaNovaSemiBold',
      fontSize: '23pt',
      color: '#4a4a4a',
      paddingRight: '2rem'
    },
    '& .MuiTypography-body1':{
      fontFamily: 'ProximaNovaRegular',
      fontSize: 14,
      color: '#9b9b9b'
    },
    '& .MuiButton-root': {
      boxShadow: 'none'
    }
  },
};

const validKeys = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
  'Delete', 'Backspace', 'Tab', 'ArrowLeft', 'ArrowRight']

export const numericCharFilter = (event) => {
  if (validKeys.indexOf(event.key) === -1) {
    event.preventDefault()
    return
  }
}

export const fieldNavHandler = (prevControl, nextControl) => {
  let backspaceBuffer = 0
  return (event) => {
    if (event.isDefaultPrevented()) {
      return
    }
    if (event.key === 'Tab' || event.key === 'Shift' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      // do nothing
    }
    else if (event.key === 'Backspace') {
      if (`${event.target.value}`.length > 0) {
        backspaceBuffer = 0
      }
      else if (prevControl) {
        backspaceBuffer++
        if (backspaceBuffer === 2) {
          backspaceBuffer = 0
          prevControl.focus()
        }
      }
    }
    else if (event.target.value.length >= event.target.maxLength) {
      if (nextControl) {
        nextControl.focus()
      }
    }
    else {
      backspaceBuffer = 0
    }
  }
}

/**
 * Post Your Finish Time Modal for Virtual Races
 */

const VRPostTimeModalComponent = ({
  classes,
  className,
  claimStatus,
  result,
  eventCourseId,
  maxAllowedTicks,
  minAllowedTicks,
  onUpdate,
  isMobile,
  t,
}) => {

  maxAllowedTicks = maxAllowedTicks ?? Number.POSITIVE_INFINITY
  minAllowedTicks = minAllowedTicks ?? 0
  const tokenRacerId = getTokenRacerId()
  const [open, setOpen] = React.useState(false);
  const [closing, setClosing] = React.useState(false);
  const [isValid, setIsValid] = React.useState(true);
  const [ticks, setTicks] = React.useState(-1)
  const formHours = React.useRef(null)
  const formMinutes = React.useRef(null)
  const formSeconds = React.useRef(null)
  const formDetailEntry = React.useRef(null)
  const [isTrackersOpen, setIsTrackersOpen] = React.useState(false)
  const [detailUrlValue, setDetailUrlValue] = React.useState('')
  const [requestError, setRequestError] = React.useState(null)

  const isValidDetailUrl = (x) => {
    if (!x) return true
    if (!x.length) return true
    return isValidActivityUrl(x)
  }

  const handleClose = (force = false) => {
    if (!closing || force) {
      setOpen(false);
      setIsValid(true);
    }
  };

  const getVal = (ref) => {
    return (isNaN(parseInt(ref.current.value)) ? 0
      : parseInt(ref.current.value))
  }

  const saveTimeAndClose = async () => {
    setRequestError(null)
    setClosing(false)
    if (tokenRacerId && eventCourseId) {
      const h = getVal(formHours) * 60 * 60
      const m = getVal(formMinutes) * 60
      const s = getVal(formSeconds)
      const _ticks = (h + m + s) * 1000
      const detail = !!isValidDetailUrl(detailUrlValue)
      if (detail && !isNaN(_ticks) && _ticks >= minAllowedTicks && _ticks <= maxAllowedTicks) {
        setIsValid(true)
        setClosing(true)
        const body = {
          eventCourseId: eventCourseId,
          entryUniqueId: result.entryUniqueId,
          unofficialTicks: _ticks,
          detailUri: detailUrlValue,
        }
        try {
          const result = await saveEntryDetailUnofficialOrVirtualTime(body)
          onUpdate({brackets: result.brackets, ticks: _ticks});
          handleClose(true);
        }
        catch (e) {
          setClosing(false)
          setRequestError(t('There was an error saving your result. Try again later.'))
        }
      }
      else {
        setTicks(_ticks)
        setIsValid(false)
      }
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
    setClosing(false);
    setIsValid(true);
  };

  const styles = {
    postTimeContainer: {
      // margin: '3rem'
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '50%'
    },
    postTimeInputs: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 15,
    },
    dialogIcon: {
      marginRight: '1rem'
    },
    timeLabel:{
      fontSize: 14,
      fontFamily: 'ProximaNovaSemibold',
      color:'#4a4a4a',
      textTransform: 'uppercase',
      marginTop: 20
    },
    dialogContent: {
      paddingTop: 0,
    },
    postTimeBtn: {
      width: '100%'
    },
    dividers: {
      fontSize: 34,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
      marginRight: 6,
      marginLeft: 6,
    },
    inputValue: {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
      width: '4ch'
    }
  }

  const errorMessage = ticks < minAllowedTicks  ? `You must specify a time greater than ${formatTime(minAllowedTicks / 1000)}` :
    ticks > maxAllowedTicks ? `You must specify a time less than ${formatTime(maxAllowedTicks / 1000)}` :
    null
  const isClaimed = claimStatus === ClaimStatus.claimed
  const InputClass = isClaimed ? TextInput : TextInputDisabled
  const WideInputClass = isClaimed ? TextInputLong : TextInputLongDisabled
  return (
    <div>
      <ApprovedTrackersModal
        aria-labelledby="supported-trackers"
        aria-describedby="list-of-supported-activity-trackers"
        open={isTrackersOpen}
        handleClose={() => setIsTrackersOpen(false)}
      />
    {
      // claimStatus && claimStatus !== ClaimStatus.claimable ?
        <div>
        <SubmitTimeButton style={styles.postTimeBtn} variant="outlined" color="primary" onClick={handleClickOpen}>
          Post Your Time
        </SubmitTimeButton>
        {isLoggedIn() ? (
          <Dialog
            fullScreen={isMobile}
            className={isMobile ? null : clsx(classes.root, className)}
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            disableBackdropClick={closing}
            open={open}>
            <PrimaryDialogTitle className={'primaryDialogTitle'} id="customized-dialog-title" onClose={handleClose} closing={closing}>
              <img
                style={styles.dialogIcon}
                src={`${configs.bragiUrl}/svg/icon_dialog_time.svg`}
                alt={'Dialog Icon'}
              />
              Add Your Race Time
            </PrimaryDialogTitle>
            <DialogContent style={styles.dialogContent} dividers>
              <Typography gutterBottom>
                {t('Add your final race time for your virtual race')}
              </Typography>
              <div style={styles.postTimeContainer}>
                <div style={styles.timeLabel}>
                  Final Time
                </div>
                <form noValidate autoComplete="off">
                  <div style={styles.postTimeInputs}>
                    <InputClass variant="outlined" helperText="HH"
                      inputRef={formHours}
                      disabled={!isClaimed}
                      maxLength={2}
                      onKeyDownCapture={numericCharFilter}
                      onKeyUp={fieldNavHandler(null, formMinutes.current)}
                      error={!isValid}
                      inputProps={{
                        maxLength: 2,
                      }}/>
                    <span style={styles.dividers}>:</span>
                    <InputClass variant="outlined" helperText="MM"
                      error={!isValid}
                      maxLength={2}
                      disabled={!isClaimed}
                      onKeyUp={fieldNavHandler(formHours.current, formSeconds.current)}
                      onKeyDownCapture={numericCharFilter}
                      inputRef={formMinutes}
                      inputProps={{
                        maxLength: 2
                      }}/>
                    <span style={styles.dividers}>:</span>
                    <InputClass variant="outlined" helperText="SS"
                      inputRef={formSeconds}
                      disabled={!isClaimed}
                      maxLength={2}
                      onKeyUp={fieldNavHandler(formMinutes.current, null)}
                      onKeyDownCapture={numericCharFilter}
                      error={!isValid}
                      inputProps={{
                        maxLength: 2
                      }}/>
                    </div>
                    <div style={{display: isValid ? 'none' : 'block', color: 'red', fontSize: '1rem'}}>
                      {errorMessage}
                    </div>
                    <div>
                      <div style={{...styles.timeLabel, marginBottom: 15}}>{t('Activity Link - Optional')}</div>
                      <WideInputClass variant="outlined"
                        style={{width: '100%'}}
                        disabled={!isClaimed}
                        type="url"
                        helperText={<Button variant="text" onClick={() => setIsTrackersOpen(true)}>
                          {t('(Click here for supported links)')}
                        </Button>}
                        inputRef={formDetailEntry}
                        onChange={(e) => setDetailUrlValue(e.target.value)}
                        error={!isValidDetailUrl(detailUrlValue)}
                        />
                    </div>
                </form>
                {isClaimed && (
                  <div style={{display: isClaimed ? 'none' : 'block', color: 'red', fontSize: '1rem'}}>
                    {t('You will be able to post your time once your claim is approved. Please check back in 24 hours.')}
                  </div>
                )}
                {!!requestError && (
                  <div style={{color: 'red', fontSize: '1rem'}}>
                    {requestError}
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <PrimaryButton autoFocus onClick={saveTimeAndClose} color="primary" disabled={closing || !isClaimed}>
                {closing ? 'Rescoring Race' : 'Submit' }
                {closing && <span style={{height: 24, width: 24, marginLeft: 16}}><Loading timeoutMessage={'Still recalculating... Hang on.'} /></span>}
              </PrimaryButton>
            </DialogActions>
          </Dialog>
        ) : (
          <LogInOrSignUpIndependent
            title={null}
            separator={false}
            open={open}
            isMobile={isMobile}
            closeAction={handleClose}
          />
        )}
      </div>
      // : null
    }
    </div>
  );
}

export const VRPostTimeModal = compose(
  withTranslation(),
  withStyles(dialogStyles),
)(VRPostTimeModalComponent);

const patterns = {
  'Zwift': [
    /^https?:\/\/zwift\.com\/activity\/\d+/,
  ],
  'iFit': [
    /^https?:\/\/www\.ifit\.com\/workout\/\w+\/\w+/,
  ],
  'Strava': [
    /^https?:\/\/www\.strava\.com\/activities\/\d+/,
    /^https?:\/\/strava\.app\.link\/\w+/,
  ],
  'MapMyRide': [
    /^https?:\/\/www\.mapmyride\.com\/workout\/\d+/,

  ],
  'plotaroute.com': [
    /^https?:\/\/www\.plotaroute\.com\/mobile\/route\/\d+/,
  ],
  'Relive': [
    /^https?:\/\/www\.relive\.cc\/view\/\w+/,
  ],
  'Pacer': [
    /https?:\/\/share\.mypacer\.com\/track\/.+/,
  ],
  'endomondo': [
    /^https?:\/\/www\.endomondo\.com\/users\/\d+\/workouts\/\d+/,
  ],
  'FitBit': [
    /^https?:\/\/www\.fitbit\.com\/activities\/\w+\/\d+/,
  ],
  'MapMyRun': [
    /^https?:\/\/www\.mapmyrun\.com\/workout\/\d+/,
  ],
  'MapMyWalk': [
    /^https?:\/\/www\.mapmywalk\.com\/workout\/\d+/,
  ],
  'MapMyFitness': [
    /^https?:\/\/www\.mapmyfitness\.com\/workout\/\d+/,
  ],
  'Garmin': [
    /^https?:\/\/connect\.garmin\.com\/modern\/activity\/\d+/,
    /^https?:\/\/connect\.garmin\.com\/modern\/activity\/embed\/\d+/,
  ],
  'Peloton': [
    /^https?:\/\/members\.onepeloton\.com\/members\/\w+\/workouts\/\w+/,
    /^https?:\/\/members\.onepeloton\.com\/profile\/workouts\/\w+/,
  ],
  'RunKeeper': [
    /^https?:\/\/rnkpr.com\/\w+/,
    /^https?:\/\/(www\.)?runkeeper.com\/\w+\/.+/
  ],
  'Polar': [
    /^https?:\/\/flow\.polar\.com\/training\/analysis\/\d+/
  ],
  'Suunto': [
    /^https?:\/\/www\.suunto\.com\/move\/[^/]+\/\w+/,
    /^https?:\/\/www\.movescount\.com\/moves\/\w+/,
  ],
  'TomTom': [
    /^https?:\/\/mysports\.tomtom\.com\/service\/webapi\/v2\/activity\/.+/,
    /^https?:\/\/mysports\.tomtom\.com\/app\/activity\/\d+/,
  ],
  'Training Peaks': [
    /^https?:\/\/tpks\.ws\/.+/,
    /^https?:\/\/home\.trainingpeaks\.com\/athlete\/workout\/.+/,
  ],
}

export const isValidActivityUrl = (url) => !!mapLinkToTrackerName(url)

export const mapLinkToTrackerName = (url) => Object.keys(patterns).find((x) => {
  const casePatterns = patterns[x]
  return casePatterns.some((p) => {
    if (p instanceof RegExp) {
      return p.test(url)
    }
    return url.startsWith(p)
  })
})

export const getValidActivityTrackerNames = () => Object.keys(patterns)