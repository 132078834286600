import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Button, Select } from '@material-ui/core'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

const usePageButtonStyle = makeStyles(() => ({
  root: {
    color: (isCurrent) => isCurrent ? 'white' : 'rgb(60, 184, 235)',
    backgroundColor: (isCurrent) => isCurrent ? 'rgb(60, 184, 235)' : 'white',
    // border: '1px solid rgb(179, 193, 206)',
    cursor: 'pointer',
    minWidth: 0,
    margin: 3,
  },
  label: {
    width: 'auto',
  },
}))

const usePageSelectorStyle = makeStyles(() => ({
  root: {
    marginLeft: 3,
    marginRight: 3,
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: '"Roboto", "Helvetica", sans-serif',
  },
  select: {
    border: 'none',
  },
  outlined: {
    padding: '8px 24px 11px 14px',
    color: 'rgb(60, 184, 235)',
  }
}))

const PagerButton = ({isCurrent, label, href, ariaLabel}) => {
  const style = usePageButtonStyle(isCurrent)
  return (
    <Button classes={style} component={Link} aria-label={ariaLabel}
      to={href} variant="outlined">{label}</Button>
  )
}

PagerButton.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  label: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
}

/**
 * A paging component that supports proper URL deep linking
 * @param {pageNumber, pageSize, total, pageParamName = 'page'} pageNumber: page currently displayed, pageSize: number of records per page, total: total records in result set, pageParamName: querystring param name to use
 */
export const Pager = ({pageNumber, pageSize, total, pageParamName = 'page'}) => {
  const selectorStyle = usePageSelectorStyle()
  const history = useHistory()
  const pages = React.useMemo(() => {
    const totalPages = Math.ceil(total/pageSize)

    const hrefFunction = (index) => {
      const url = new URL(window.location.href)
      url.searchParams.set(pageParamName, index)
      return url.pathname + url.search
    }

    const makeButton = (index, $label = undefined) => {
      let effectivePageNumber = index
      let ariaLabel = index
      let label = index
      if (index === 0) {
        effectivePageNumber = pageNumber + 1
        ariaLabel = 'next'
        label = <NavigateNextIcon size="small" />
      }
      else if (index === -1) {
        effectivePageNumber = pageNumber - 1
        ariaLabel = 'previous'
        label = <NavigateBeforeIcon size="small" />
      }
      else if (index === -2) {
        effectivePageNumber = 1
        ariaLabel = 'first'
        label = <SkipPreviousIcon size="small" />
      }
      else if (index === Number.POSITIVE_INFINITY) {
        effectivePageNumber = totalPages
        ariaLabel = 'last'
        label = <SkipNextIcon size="small" />
      }
      if (typeof $label !== 'undefined') {
        label = $label
      }
      const href = hrefFunction(effectivePageNumber)
      return (
        <PagerButton href={href} label={label} key={`pager-button-${ariaLabel}`}
          ariaLabel={`page ${ariaLabel}`}
          isCurrent={effectivePageNumber === pageNumber} />
      )
    }

    const out = []

    if (totalPages > 10) {
      let pageSpan = 9
      if (pageNumber > 1) {
        out.push(makeButton(-2))
        pageSpan -= 1
      }
      if (pageNumber < totalPages) {
        pageSpan -= 1
      }
      let rangeStart = Math.max(pageNumber - Math.floor(pageSpan / 2), 1)
      let rangeEnd = Math.min(totalPages, pageNumber + Math.floor(pageSpan / 2))
      if (pageSpan > (rangeEnd - rangeStart)) {
        rangeStart -= Math.max(1, pageSpan - (rangeEnd - rangeStart))
        rangeStart = Math.max(1, rangeStart)
        if (pageSpan > (rangeEnd - rangeStart)) {
          rangeEnd = rangeStart + pageSpan
        }
      }

      for (let i = rangeStart; i <= rangeEnd; i++) {
        out.push(makeButton(i))
      }
      if (pageNumber < totalPages) {
        out.push(makeButton(Number.POSITIVE_INFINITY))
      }
      out.push(
        <span key={`page-selector`} style={{marginLeft: 3}}>
        <Select classes={selectorStyle} onChange={(e) => history.push(hrefFunction(e.target.value))}
          native defaultValue={pageNumber} variant={'outlined'}>
          {new Array(totalPages).fill(0).map((x, i) => (
            <option value={i + 1} key={`page-option-${i + 1}`}>Page: {i + 1}</option>
          ))}
        </Select>
        </span>
      )
    }
    else {
      out.push(
        ...(new Array(totalPages).fill(0).map((x, i) => makeButton(i + 1)))
      )
    }

    return out
  }, [pageNumber, total, pageSize, pageParamName, selectorStyle, history])

  return (
    <nav aria-label="pages" role="navigation">
      {pages}
    </nav>
  )
}

Pager.propTypes = {
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  pageParamName: PropTypes.string,
}

Pager.defaultProps = {
  pageParamName: 'page'
}