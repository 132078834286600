import { calculatePace } from '../../../lib/anuket';

export function maybeCalculatePace(event, race, result) {
  if (!race.meters || !result.ticks) {
    return '';
  }

  const tuple = {type: event.eventType || 'Running', meters: race.meters};
  return calculatePace(tuple, result.ticks, true);
}

export function getGender(gender, t) {
  const fixed = (gender || '').toUpperCase()
  switch(fixed) {
    case 'F':
    case 'M':
    case 'NB':
      return t(`genderMap#${fixed}`);
    default:
      return t('genderMap#U')
  }
}
