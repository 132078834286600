import {
  setFilterValues,
  getDefaultFilterValues,
  pushFilterHistory
} from '../components/shared/Filters'

import {
  EVENT_SEARCH,
  PROFILE_SEARCH,
  UNCLAIMED_RESULTS_SEARCH
} from '../data/SearchFilters';

const mapStreamIds = {
  'event': EVENT_SEARCH,
  'athlete': PROFILE_SEARCH,
  'unclaimed': UNCLAIMED_RESULTS_SEARCH
};

export default (streamId, resetData, history) => {
  // eslint-disable-next-line
  Object.keys(mapStreamIds).map((key) => {
    getDefaultFilterValues(mapStreamIds[key]).subscribe((data) => {
      if(key === streamId && resetData) {
        setFilterValues(mapStreamIds[key], resetData);
      } else {
        if (EVENT_SEARCH === mapStreamIds[key]) {
          setFilterValues(mapStreamIds[key], {
            ...data,
            dateRangeFilter: {
              enabled: false,
              value: { from: '', to: '' }
            }
          });
          pushFilterHistory(null, history);
        } else {
          setFilterValues(mapStreamIds[key], data);
        }
      }
    })
  })
}
