import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { getBlog, getBlogStream } from '../../data/HomePageStreams';
import RssIcon from 'react-icons/lib/ti/rss';
import Slider from 'react-slick';
import HomeSectionTitle from '../home/HomeSectionTitle';
import sliderSettings from '../home/sliderSettings';
import { BlogCard } from './BlogItem';
import { homeCarouselStyles } from '../home/styles';

class BlogCarouselComponent extends Component {

  state = {
    actionHover: false
  };

  componentDidMount() {
    getBlog();
  }

  render() {
    const {
      blogFeed = [],
      t
    } = this.props;

    
    if (blogFeed.isError || blogFeed.error || !Array.isArray(blogFeed) || !blogFeed.length) {
      return null
    }
    
    return (
      <section id="blog-section" className='blog-section container' style={homeCarouselStyles.carouselWrap}>
        <div style={ homeCarouselStyles.carouselTitleContainer }>
          <HomeSectionTitle
            id="blog-post"
            subtitle={t('Recent Blog Posts')}
          />
          <a type="application/rss+xml"
            href="http://blog.athlinks.com/feed"
            style={ homeCarouselStyles.titleAction(this.state.actionHover) }
            onMouseEnter={ () => this.setState({actionHover: true})}
            onMouseLeave={ () => this.setState({actionHover: false})}
            target="_blank" rel="noopener noreferrer"
          >
            <RssIcon style={ homeCarouselStyles.titleActionIcon } />
            {t('Subscribe')}
          </a>
        </div>

        <div className='home-carousel' style={ homeCarouselStyles.carouselWrapper }>
          <Slider { ...sliderSettings }>

            {
              blogFeed.map((blogData, index) => (
                <div key={ blogData.id }>
                  <BlogCard
                    id={index}
                    { ...blogData }
                    { ...this.props }
                  />
                </div>
              ))
            }

          </Slider>
        </div>
      </section>
    );
  }
}

BlogCarouselComponent.propTypes = {
  blogFeed: PropTypes.array.isRequired,
  t: PropTypes.func
};

BlogCarouselComponent.defaultProps = {
  blogFeed: []
};

export const BlogCarousel = withTranslation()(connectStream({
  blogFeed: getBlogStream
})(BlogCarouselComponent));
