import { FetchApi } from '../lib/anuket-http';
import { streamHook, streamFail, eventBus } from './Streams';

const kioskEmailStreamId = 'KioskEmailStream';

export function sendKioskEmail(emailData) {
  streamFail(
    kioskEmailStreamId,
    FetchApi.doFetch(
      '/sfmcTriggerSend',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          ctLiveEntryId: emailData.ctLiveEntryId,
          emailParams: emailData.ContactAttributes
        })
      }
    ),
    (msg) => {
      return (msg.error ? {error: true} : {success: true});
    }
  );
}

export function getKioskEmailStream() {
  return streamHook(kioskEmailStreamId, {});
}

export function resetKioskEmailStream() {
  return eventBus.publish(kioskEmailStreamId, {});
}
