
export function parse(token, raiseError = false) {
  try {
    const parts = token.split('.');

    // Keycloak 2.1 token format:
    if (parts.length === 3) {
      const plainText = Buffer.from(parts[1], 'base64').toString('utf8');
      return JSON.parse(plainText);
    }
  }
  catch(e) {
    if (raiseError) { throw e; }
    return {};
  }

  // Any other format.
  return {};
}

export default {
  parse
};
