import isInteractiveAdEventBlacklisted from "./isInteractiveAdEventBlacklisted";
import isInteractiveAdEventWhitelisted from "./isInteractiveAdEventWhitelisted";
import { useFirebaseConfig } from "./remote-config";

export default function useInteractiveAds(athlinksEventId: number) {
  const firebaseConfig = useFirebaseConfig()
  const allowInteractiveAds = firebaseConfig.config.useInteractiveAds?.asBoolean() ?? false

  return allowInteractiveAds
    && isInteractiveAdEventWhitelisted(firebaseConfig, athlinksEventId)
    && !isInteractiveAdEventBlacklisted(firebaseConfig, athlinksEventId)
}