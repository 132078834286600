import {createStaticRanges} from '@eventops/athlinks-react-date-range'

const today = new Date();
const currentYear = today.getFullYear()
const todayDay = today.getDate()
export const staticRanges = (t) => {
  return createStaticRanges([
  {
    label: t('Upcoming'),
    ranges: [
      {
        label: t('Next 7 Days'),
        range: () => ({
          startDate: today,
          endDate: new Date(new Date().setDate(todayDay + 7)),
        }),
      },
      {
        label: t('Next 30 Days'),
        range: () => ({
          startDate: today,
          endDate: new Date(new Date().setDate(todayDay + 30)),
        }),
      },
      {
        label: t('Next Year'),
        range: () => ({
          startDate: today,
          endDate: new Date(new Date().setFullYear(currentYear + 1)),
        }),
      },
    ],
  },
  {
    label: t('Past'),
    ranges: [
      {
        label: t('Last 7 Days'),
        range: () => ({
          startDate: new Date(new Date().setDate(todayDay - 7)),
          endDate: today,
        }),
      },
      {
        label: t('Last 30 Days'),
        range: () => ({
          startDate: new Date(new Date().setDate(todayDay - 30)),
          endDate: today,
        }),
      },
      {
        label: t('Last Year'),
        range: () => ({
          startDate: new Date(new Date().setFullYear(currentYear - 1)),
          endDate: today,
        }),
      }
    ]
  }
], true)};
