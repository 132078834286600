import React  from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { configs } from '../configs';
import _ from 'lodash';

const getUrlNoQueryStrings = () => {
  return [window.location.protocol, '//', window.location.host, window.location.pathname].join('');
};

//If we don't have a good image passed in, use default logo
const athlinksDefaultImage = `${configs.cdnUrl}/images/athlinks-logo-200x200.png`;
const defaultImageHeight = 200;
const defaultImageWidth = 200;

export const OpenGraphTags = ({url = '', ogType = '', title = '', description = '', image = '', imageHeight = 0, imageWidth = 0, noImageSize = false}) => {
  const defaultImgSizeMeta = [{id: 'og-image-width', property: 'og:image:width', content: defaultImageWidth}, {id: 'og-image-height', property: 'og:image:height', content: defaultImageHeight}];

  const imageDimensions = image && imageHeight && imageWidth
      ? [{id: 'og-image-width', property: 'og:image:width', content: imageWidth}, {id: 'og-image-height', property: 'og:image:height', content: imageHeight}]
      : image && noImageSize
        ? []
        : defaultImgSizeMeta;

  const metaTags = [
    {id:'og-url', property:'og:url', content: url || getUrlNoQueryStrings()},
    {id:'og-type', property:'og:type', content: ogType},
    {id:'og-title', property:'og:title', content: title},
    {id:'og-description', property:'og:description', content: description},
    {id:'og-image', property:'og:image', content: image || athlinksDefaultImage}
  ];

  const mergedTags = _.concat(imageDimensions.slice(0), metaTags.slice(0));

  return (
    <MetaTags>
      {
        mergedTags.map((item) => {
          return (
            <meta property={item.property} content={item.content} key={item.id} />
          );
        })
      }
    </MetaTags>
  )
};

OpenGraphTags.propTypes = {
  url: PropTypes.string,
  ogType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  noImageSize: PropTypes.bool
};
