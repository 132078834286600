import { createSlice } from '@reduxjs/toolkit'

export const irpResultSlice = createSlice({
  name: 'irpResult',
  initialState: {
    data: undefined,
  },
  reducers: {
    storeIrpResultData: (state, action) => {
      state.data = action.payload
    }
  }
})

export const { storeIrpResultData } = irpResultSlice.actions

export default irpResultSlice.reducer
