import { colors } from '../../../shared/styles';

export const styles = {
  button: {
    container: {
      display: 'inline-block',
      verticalAlign: 'top'
    },
    hover: () => `
      .hover-unit-button:hover {
        background: ${colors.greyHover} !important;
      }
     `,
    style: (backgroundColor, borderColor) => ({
      border: `1px solid ${borderColor}`,
      borderRadius: '0px 5px 5px 0px',
      backgroundColor,
      color: colors.grey,
      padding: '13px',
      cursor: 'pointer',
      marginRight: '0px'
    })
  },
  calendar: {
    container: {
      position: 'absolute',
      zIndex: 99,
      background: colors.white,
      border: `1px solid ${colors.greyLine}`,
      borderRadius: '4px',
      boxShadow: '0 9px 19px 0 rgba(0,0,0,0.08)',
      marginLeft: '120px'
    }
  },
  input: {
    container: (width) => ({
      display: 'inline-block',
      width
    }),
    pseudoStyles: () => `
      .dateEntryInput::-webkit-inner-spin-button {
        -webkit-appearance: none; 
        display: none;
      }
      .dateEntryInput::-webkit-calendar-picker-indicator {
        -webkit-appearance: none; 
        display: none;
      }
    `,
    style: (color, borderColor, borderRadius, width) => ({
      fontFamily: 'ProximaNovaRegular',
      fontSize: '16px',
      border: `1px solid ${borderColor}`,
      borderRadius,
      backgroundColor: colors.white,
      padding: '10px 0px 10px 10px',
      color,
      textTransform: 'uppercase',
      minHeight: '22px',
      width,
      cursor: 'text'
    })
  },
  label: {
    asterisk: {
      color: colors.blue,
      marginLeft: '4px',
      fontSize: '16px'
    },
    container: {
      display: 'inline-block',
      fontSize: '10px',
      fontFamily: 'ProximaNovaRegular',
      fontWeight: 600,
      letterSpacing: '0.5px',
      marginBottom: '7px'
    },
    style: {
      color: colors.labelColor,
      textTransform: 'uppercase'
    }
  },
  message: {
    container: {
      height: '15px'
    },
    style: {
      color: colors.error,
      fontFamily: 'ProximaNovaRegular',
      fontSize: '12px',
      marginTop: '8px'
    }
  }
};
