import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { connectStream } from '../../lib/bastetjs/utils/connectStream'
import { Waypoint } from 'react-waypoint';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';

import {SearchIcon} from '../../components/shared/icons';
import { SearchStream } from '../../data/allResultsStreams';
import { searchResults as styles } from './styles';
import { setTermValue, getSearchTerm } from '../../data/SearchTerm';
import { AllResults } from '../../components/searchComponents/AllResultsComponent';
import {
  resultsLoading,
  eventsLoading,
  athletesLoading
} from '../../data/allResultsStreams'
import {SearchTab} from '../home/SearchTab'
import { colors } from '../../shared/styles';
import {goToCategory} from '../../data/SearchNavigator';

const mapStateToProps = (state) => ({
  context: state.search.context
});

class SearchResultComponent extends Component {
  static propTypes = {
    master: PropTypes.object,
    searchTerm: PropTypes.string,
    fetching: PropTypes.bool,
    hideSearchInputs: PropTypes.bool,
    onInputsVisible: PropTypes.func,
    onInputsScrolledOff: PropTypes.func,
    resize: PropTypes.object,
    paging: PropTypes.object,
    isMobile: PropTypes.bool,
    results: PropTypes.object,
    type: PropTypes.string,
    context: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    results: {},
  };

  state = {
    hideResults: true
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({hideResults: true})
    }
  }

  _handleWaypointEnter = () => {
    this.props.onInputsVisible && this.props.onInputsVisible()
  }

  _handleWaypointLeave = () => {
    this.props.onInputsScrolledOff && this.props.onInputsScrolledOff()
  }

  updateSearchState = (e) => {
    const term = SearchStream.getInputText(e);
    setTermValue(term);
    this.setState({hideResults: false});
  }

  onKeyUp = ({key}) => {
    const {searchTerm, context, history} = this.props;
    if (key === 'Enter') goToCategory(searchTerm, context, history);
  }

  onFocus = () => {
    this.setState({ hideResults: false});
  };
  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  render() {
    const {
      searchTerm = '',
      hideSearchInputs,
      isMobile,
      type,
      searchFocused,
      onUserChooseResult,
      context,
      t,
      resultsLoading = { fetching: false },
      eventsLoading = { fetching: false },
      athletesLoading = { fetching: false }
    } = this.props;

    const _s = styles(this.props);

    const notFound = (type === 'notFound');

    const fetching = resultsLoading.fetching || eventsLoading.fetching || athletesLoading.fetching;

    //Just show the results box in this mode - used in home page header unified search dropdown
    if (hideSearchInputs) {
        return (
          <div style={_s.allResults(false)}>
            <AllResults
              isMobile={isMobile}
              fetching={ fetching }
              props={ this.props }
              searchTerm={searchTerm}
              hideResults={this.state.hideResults || searchTerm.length < 4}
              onUserChooseResult={onUserChooseResult && onUserChooseResult}
              hideSearchInputs={true}
              searchDestination={context}
              searchFocused={searchFocused}
              notFound = {notFound}
            />
          </div>
        )
    }

    return (
      <section style={ (type === 'header') ? _s.headerPageMobile : (type === 'notFound') ? _s.headerPageNotFound : _s.headerPage }>
        <Waypoint
          onEnter={ this._handleWaypointEnter }
          onLeave={ this._handleWaypointLeave }
        />
        <div style={_s.searchTabWrapper(type === 'notFound')}>
          <SearchTab
            isMobile={isMobile}
            styleMode={'light'}
            searchTerm={searchTerm}
            isHomePage={true}
            notFound={type === 'notFound'}
          />
        </div>
        <div style={_s.searchInputWrapper}>
          <SearchIcon
            height={ type === 'home' ? 20 : 15}
            fill={ type === 'home' ? colors.textColor : colors.white}
            style={ type === 'home' ? _s.search_input_icon : _s.search_input_icon_notFound()}
          >
          </SearchIcon>
          <form action="#" onSubmit={(event) => event.preventDefault()}>
            <input
              style={ type === 'home' ? _s.searchInput :  _s.searchInput_notFound()}
              onChange={ this.updateSearchState }
              value={ searchTerm }
              id='searchInput'
              type='text'
              placeholder={ context === 'events' ? t('Enter an event name') : t('Enter an athlete name') }
              autoComplete={'off'}
              onKeyUp={this.onKeyUp}
              onFocus={this.onFocus}
            />
          </form>
        </div>
        {
          searchTerm.length > 0 && <div style={_s.allResults(type === 'home' || type === 'notFound', type === 'home')} ref={this.setWrapperRef}>
            <AllResults
              isMobile={isMobile}
              fetching={ fetching }
              props={ this.props }
              searchTerm={searchTerm}
              searchDestination={context}
              hideResults={this.state.hideResults}
              hideSearchInputs={false}
              searchFocused={searchFocused}
              notFound = {notFound}
            />
          </div>
        }
      </section>
    )
  }
}

export const SearchResults = connect(mapStateToProps)(withTranslation()(connectStream({
  results: SearchStream.allResults,
  searchTerm: getSearchTerm,
  resultsLoading: resultsLoading,
  eventsLoading: eventsLoading,
  athletesLoading: athletesLoading
})(withRouter(SearchResultComponent))));
