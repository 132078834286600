import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { PageTemplate } from './PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import {NewsFeedBlade} from '../../components/home/NewsFeedBlade';
import VideoHero from '../../components/home/videoHero';
import { CuratedCarouselContainer } from './CuratedCarouselContainer';
import { BlogCarousel } from '../../components/blog/BlogCarousel';
import { SearchResults } from '../searchResults/SearchResults';
import { HighlightBlades } from '../../components/home/HighlightBlades';
import { setTermValue, getSearchTerm } from '../../data/SearchTerm';
import {
  getLocationTerm,
  setLocationTerm,
} from '../../data/allResultsStreams';
import { streamHook } from '../../data/Streams';

class HomePageComponent extends Component {
  static propTypes = {
    searchTerm: PropTypes.string,
    locationTerm: PropTypes.string,
    isLandscape: PropTypes.bool,
    isMobile: PropTypes.bool,
    windowWidth: PropTypes.number,
    isLoggedIn: PropTypes.bool.isRequired,
    t: PropTypes.func
  };

  state = {
    headerIsFixed: false,
    anyHeaderInputsFocused: false,
  };

  onSearchInput = ({target: {value}}) => {
    setTermValue(value);

    this.setState({
      anyHeaderInputsFocused: true,
    })
  }

  onLocationInput = (locationTerm) => {
    setLocationTerm(locationTerm);

    this.setState({
      anyHeaderInputsFocused: true,
    })
  }

  onHeaderFocusChange = (anyFocused) => {
    this.setState({
      anyHeaderInputsFocused: anyFocused,
    })
  }

  onUnifiedInputsVisible = () => {
    this.setState({
      headerIsFixed: false,
      anyHeaderInputsFocused: false,
    })
  }

  onUnifiedInputsScrolledOff = () => {
    this.setState({
      headerIsFixed: true,
    })
  }

  render() {
    const {
      t
    } = this.props;

    const {
      headerIsFixed,
      anyHeaderInputsFocused,
    } = this.state;

    const showUnifiedSearchDropdown = headerIsFixed && anyHeaderInputsFocused;

    const title = 'Athlinks.com';
    const description = t('View all your hard-earned race results in one place on Athlinks, connect with friends, and see how you stack up against the competition.');

    return (
      <PageTemplate
        onSearchInput={this.onSearchInput}
        onLocationInput={this.onLocationInput}
        onHeaderFocusChange={this.onHeaderFocusChange}
        headerIsFixed={headerIsFixed}
        unifiedSearchMode={true}
        onHomePage={true}
        paddingTop={0}
        showUnifiedSearchDropdown={showUnifiedSearchDropdown}
        hideSignUpBanner={true}
        {... this.props}
      >
        <StandardHeaderTags
          title={title}
          description={description}
        />
        <OpenGraphTags
          ogType='website'
          title={title}
          description={description}
        />

        <VideoHero { ...this.props }>
          <SearchResults
            type="home"
            onInputsVisible={this.onUnifiedInputsVisible}
            onInputsScrolledOff={this.onUnifiedInputsScrolledOff}
            hideResults={showUnifiedSearchDropdown}
            { ...this.props }
          />
        </VideoHero>
        <HighlightBlades {...this.props } />

        <NewsFeedBlade { ...this.props } />

        <CuratedCarouselContainer { ...this.props } />

        <BlogCarousel { ...this.props } />

        {
          // !isLoggedIn() && !isMobile && <JoinACommunity { ...this.props } />
        }

      </PageTemplate>
    )
  }
}

export const HomePage = withTranslation()(connectStream({
  searchTerm: getSearchTerm,
  locationTerm: getLocationTerm,
  betaBannerHeight: () => streamHook('beta-banner-height', 0),
})(withRouter(HomePageComponent)));
