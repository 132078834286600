import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles' 
import { AthlinksMedal } from '../../../shared/icons'

const mapStateToProps = () => ({
  dispatch: undefined
});

class ClaimEntryFailureComponent extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div style={style.medalContainer}>
        <div style={style.medal}>
          <AthlinksMedal />
        </div>
        <div style={style.modalTitle}>
          {t('Claim Unsuccessful')}
        </div>
        <div style={{ ...style.modalSubtitle, ...style.subMessage }}>
          {t(`Sorry, we were unable to follow through with your claim.`)}
        </div>
      </div>
    )
  }
}
export const ClaimEntryFailure = connect(mapStateToProps, null)(withTranslation()(ClaimEntryFailureComponent));

export class ClaimEntryFailureFooterComponent extends React.Component {
  render() {
    const {
      dispatch,
      isMobile,
      t
    } = this.props;

    const footerStyle = isMobile ? style.footerMobile : {};

    return (
      <div style={footerStyle}>
        <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
          <div style={style.doneButtonText}>
            {t('Done')}
          </div>
        </button>
      </div>
    )
  }
}
export const ClaimEntryFailureFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(ClaimEntryFailureFooterComponent);
