import { textStyles, colors } from '../../shared/styles';

export const styles = {
  title: Object.assign({}, textStyles.captionGrey, {
    marginTop: '24px'
  }),
  icon: {
    cursor: 'pointer',
    height: '40px',
    width: '40px',
    border: 'none'
  },
  imgContainer: {
    float: 'left',
    marginTop: '8px',
    marginRight: '8px',
  },
  link: textStyles.textLink2Blue,
  linkContainer: {
    height: '2em',
    overflow: 'hidden',
    color: colors.textColor
  },
  nativeLink: {
    marginTop: 10, 
    marginLeft: 5
  }
};
