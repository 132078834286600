import { useTranslation } from 'react-i18next';

import { OrgAndTimer } from '../orgAndTimer/OrgAndTimer';
import { Weather } from '../weather/Weather';
import { trackGoogleEvent } from '../../utils/googleEvents';

import { useStyles } from './styles';

interface EventInfoProps {
  event: any;
}

const EventInfo = ({ event = {} }: EventInfoProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  const siteLink = event.siteUri ? (
    <div>
      <div className={styles.eventTitle}>
        {t('Official Website')}
      </div>

      <a
        href={event.siteUri}
        target='_blank'
        rel="noopener noreferrer"
        className={styles.siteLink}
        onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Official Website')}
      >
        {event.siteUri}
      </a>
    </div>
  ) : null;

  const hasSocialLinks = !!(event.facebookUrl || event.twitterHandle || event.instagramHandle);

  return (
    <div className={styles.container}>
      {!!event.location?.latitude && (
        <Weather event={event} />
      )}

      {siteLink}

      <OrgAndTimer event={event} />

      {hasSocialLinks && (
        <div>
          <div className={styles.eventTitle}>{t('Event Links')}</div>

          {!!event.facebookUrl && (
            <a
              href={event.facebookUrl}
              target='_blank'
              rel="noopener noreferrer"
              className={styles.link}
            >
              Facebook
            </a>
          )}

          {!!event.twitterHandle && (
            <a
              href={event.twitterHandle}
              target='_blank'
              rel="noopener noreferrer"
              className={styles.link}
            >
              Twitter
            </a>
          )}

          {!!event.instagramHandle && (
            <a
              href={event.instagramHandle}
              target='_blank'
              rel="noopener noreferrer"
              className={styles.link}
            >
              Instagram
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export { EventInfo };
