import {
  textStyles,
  colors,
  loadingDark,
  loadingLight
} from '../../shared/styles';

const _type = {
  filterAll: {
    display: 'block',
    marginBottom: 20,
  },
  subfilter: {
    display: 'block',
    marginLeft: 10,
  },
  all: {
    color: 'rgba(74,74,74,1)',
    border: '1px solid rgba(74,74,74,1)',
    borderRadius: 5,
    fontSize: 12,
    padding: 3,
    textTransform: 'uppercase'
  },
  filterList: {
    marginBottom: 15,
    color: colors.grey,
  },
  spanStyle: {
    color: colors.grey,
    fontSize: 14
  },
  raceButton: {
    textTransform: 'uppercase',
    color: '#003d56',
    float: 'right',
    borderRadius: 5,
    padding: 5,
    border: '1px solid #003d56',
    fontSize: 12,
    fontFamily: 'ProximaNovaSemibold'
  },
  listToggleContainer: (canShow) =>({
    marginTop: (canShow) ? 6 : 0,
    maxHeight: (canShow) ? 300 : 0,
    opacity: (canShow) ? 1 : 0,
    overflow: 'hidden',
    transition: 'all 0.5s ease-in-out, opacity 0.3s 0.2s',
  }),
};

const _filters = {
  filter: {
    display: 'inline-block',
    marginRight: 15,
  },
  label: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 15,
    color: 'rgb(112, 112, 112)'
  },
  labelGender: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 20,
    color: 'rgb(112, 112, 112)'
  },
  radioLi: {
    display: 'inline-block',
    marginRight: 20
  },
  radioLabel: {
    zIndex: '9999',
    marginRight: 10
  },
  radioM: {
    background: 'gray',
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 4,
    paddingBottom: 4,
    color: '#fff',
    fontSize: 12,
    borderRadius: 2,
    marginLeft: -6
  },
  radioF: {
    background: 'gray',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: '#fff',
    fontSize: 12,
    borderRadius: 2
  },
  rangeLeftSide: {
    float: 'left',
    fontSize: 10,
    marginLeft: -10
  },
  rangeRightSide: {
    float: 'right',
    fontSize: 10,
    marginRight: -15
  }
};

const _athletes = {
  pseudoStyles: () => `
    .athlete-row:hover {
      background: ${colors.lightGrey} !important;
      cursor: pointer;
    }
    .athlete-row:hover .athlete-name {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `,
  table: (isMobile) => ({
    textAlign: 'left',
    width: '100%',
    marginTop: isMobile ? 0: 48,
    clear: 'both',
    borderTop: isMobile ? `1px solid ${colors.greyLine}` : 'none',
  }),
  info: {
    padding: 0,
    minHeight: 70,
    borderBottom: '1px solid #d8d8d8',
    borderTop: '1px solid #d8d8d8'
  },
  imgColSizeMob: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  followMob: {
    padding: 0,
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    marginTop: 20
  },
  athleteInfo: {
    ...textStyles.body1Grey,
    float: 'left',
    padding: 0,
    lineHeight: '1em',
  },
  athleteInfoMain: {
    paddingTop: 12,
    paddingBottom: 5,
    paddingLeft: 0
  },
  athleteName: (isMobile) => ({
    fontSize: isMobile ? 18 : 22,
    lineHeight: '1em',
    color: colors.textColor,
  }),
  paddingRight: {
    paddingRight: 5
  },
  athleteFloat: {
    float: 'left',
    padding: 0
  },
  athleteFloatMobile: {
    float: 'left',
    padding: 0,
    width: '100%',
    borderTop: '1px solid #cecece',
    color: '#9b9b9b',
    fontSize: 12,
    paddingTop: 5
  },
  raceRow: {
    ...textStyles.body1Grey,
    paddingTop: 7,
    borderTop: '1px solid '+ colors.greyLine,
  },
  tableRowName: {
    fontSize: 22,
    paddingBottom: 10
  },
  tableRowInfo: {
    ...textStyles.body1Grey,
    display: 'inline-block',
    marginRight: 20,
  },
  tableRowInfoMob: {
    ...textStyles.body1Grey,
    display: 'block',
    marginRight: 10,
  },
  tableRowInfoMiddle: {
    ...textStyles.body1Grey,
    display: 'inline-block',
    marginRight: 20,
    padding: '0px 20px',
    borderLeft: '1px solid '+ colors.greyLine
  },
  tableRowInfoMiddleMob: {
    ...textStyles.body1Grey,
    display: 'inline-block',
    marginRight: 10,
    padding: '0px 20px',
    borderLeft: '1px solid '+ colors.greyLine,
    borderRight: '1px solid '+ colors.greyLine
  },
  detailRow: {
    height: '100%',
  },
  runnerType: {
    marginBottom: 4,
  },
  flagContainer: {
    position: 'relative',
    top: -2,
    display: 'inline-block',
    marginLeft: 15,
    fontSize: 12,
  },
  topPercent: {
    display: 'inline-block',
    paddingLeft: 20,
    borderLeft: '1px solid '+ colors.greyLine,
  },
  userImg: {
    backgroundColor: '#0090de',
    borderRadius: 40,
    width: 40,
    float: 'right',
    padding: 5
  },
  followButton: {
    display: 'inline-block',
    width: 48,
    float: 'right',
    cursor: 'pointer'
  },
  startListDiv: {
    float: 'left',
    minHeight: 69,
    paddingTop: 15,
    textAlign: 'right'
  },
  athletesFriends: {
    listStyle: 'none',
    width: 180,
    marginTop: 2,
    display: 'flex'
  },
  userImgNormal: {
    backgroundColor: 'white',
    border: '2px solid #33aade',
    borderRadius: 3,
    width: 60,
    height: 40,
    padding: 5,
    cursor: 'pointer'
  },
  userImgNormalMobile: {
    backgroundColor: 'white',
    border: '2px solid #33aade',
    borderRadius: 3,
    width: 45,
    height: 25,
    padding: 5,
    cursor: 'pointer',
    marginLeft: 15
  },
  userRemove: {
    backgroundColor: '#fff',
    border: '2px solid #0090de',
    borderRadius: 40,
    width: 40,
    padding: 5
  },
  genderAgeMobile: {
    textTransform: 'uppercase',
    paddingRight: 10,
    whiteSpace: 'nowrap'
  },
  infoDividerSpacer: {
    marginRight: 10
  },
  infoDivider: {
    borderRight: `1px solid ${colors.greyLine}`,
    margin: '4px 0',
    height: 12,
  },
  locationMobile: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }
};
const _pagination = {
  moreContainer: {
    marginTop: 40,
    textAlign: 'center',
  },
  moreButton: (processing) => ({
    ...textStyles.textLink2Blue,
    display: 'inline-block',
    padding: 5,
    opacity: processing ? '0.5' : '1',
    pointerEvents: processing ? 'none' : 'auto',
  })
};

const _loading = {
  loadingContainer: (canShow) => ({
    position: 'absolute',
    top: 90,
    left: 0,
    width: '100%',
    height: '100%',
    paddingLeft: 15,
    opacity: (canShow) ? 0.6 : 0,
    pointerEvents: 'none',
    transition: 'all 0.4s',
  }),
  loadingRow: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 44,
  },
  loadingAvatar: {
    flex: '0 0 auto',
    display: 'inline-block',
    width: 60,
    height: 60,
    marginTop: 5,
    marginRight: 20,
    borderRadius: '50%',
    ...loadingDark, // Animation
  },
  loadingDetailContainer: {
    flex: '1 1 auto',
    display: 'inline-block',
  },
  loadingTitle: {
    height: 22,
    width: '30%',
    minWidth: 150,
    ...loadingDark, // Animation
    marginBottom: 12,
  },
  loadingSubtitle: {
    height: 14,
    width: '50%',
    minWidth: 200,
    ...loadingLight, // Animation
    marginBottom: 12,
  },
};

export { _type, _filters, _athletes, _pagination, _loading };
