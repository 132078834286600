import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { of as ObservableOf } from 'rxjs';

import { Nobles } from '../../lib/anuket';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { dateUtils } from '../../lib/bastetjs/utils/dateUtils';
import { shortDate } from '../../utils/date';
import i18n from '../../utils/i18n';
import { TimeSlot } from '../timeSlot/TimeSlot';
import { DateTime } from '../shared/DateTime';

import { useStyles } from './styles';

const groupByDay = (races, tz, lc) => {
  let groups = {};

  races.forEach((race) => {
    const start = race.plannedStartTime || race.startTime;
    const date = dateUtils.formatted(start, '%Y %b %d', tz, lc);
    const group = shortDate(date.split(' '));
    groups[group] = groups[group] || [];
    groups[group].push(race);
  });

  return groups;
};

export const ScheduleComponent = (
  {
    result = {},
    courses = {},
    event,
    fetching,
  }) => {
  const { t } = useTranslation();

  const lc = i18n.language;

  const renderTimes = (groupedRaces, event) => {
    return Object.keys(groupedRaces).map((date) => (
      <div key={date} className={styles.dateWrapper}>
        <div className={styles.date}>
          <DateTime
            date={date}
            showTime={false}
            icon={null}
          />
        </div>

        {groupedRaces[date].map((race) => (
          <TimeSlot
            key={race.id}
            race={race}
            event={event}
          />
        ))}
      </div>
    ))
  };

  const showLoading = result.isLoading || fetching;

  const {races} = result;
  const racesOnly = races && !!races.length ?
    races.map((race) => {
      race.raceId = race.id;
      return race;
    }) : [];

  const {schedules} = courses;
  const scheduleList = schedules || racesOnly;

  const tz = event.timeZone;
  const groupedRaces = groupByDay(scheduleList, tz, lc);

  const styles = useStyles({});

  if (Object.keys(groupedRaces || {}).length < 1 && !showLoading) {
    return null
  };

  return (
    <div>
      <div className={styles.title}>
        {t('Schedule')}
      </div>

      <div>
        {showLoading && (
          <>
            <div className={styles.loadingPlaceholder} />
            <div className={styles.loadingPlaceholder} />
            <div className={styles.loadingPlaceholder} />
          </>
        )}

        {!showLoading && (
          <>
            {renderTimes(groupedRaces, event)}

            {event.ctliveId !== 0 && (
              <div className={styles.disclaimer}>
                {t('Schedule subject to change. Always check with the event organizer for the official schedule.')}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ScheduleComponent.propTypes = {
  result: PropTypes.object,
};

ScheduleComponent.defaultProps = {
  result: {isLoading: true},
};

export const Schedule = connectStream({
  result: ({event}) => {
    const eventId = event.affiliateRaceId;
    if (!eventId) {
      return ObservableOf([]);
    }
    return Nobles.RacesNoble.getRaceTimesByCTLiveId(eventId);
  }
})(ScheduleComponent);
