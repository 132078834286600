import { textStyles } from '../shared/megabloks-styles';

const shared = {
  button: {
    borderRadius: 50,
    cursor: 'pointer',
    outline: 'none',
    padding: '14px',
    width: '100%',
    letterSpacing: '2.8px'
  }
};

export const styles = {
  button: Object.assign({}, textStyles.subhead2MenuWhite, shared.button, {
    backgroundColor: '#16A9E1',
    border: 'none'
  }),
  orange: Object.assign({}, textStyles.subhead2MenuWhite, shared.button, {
    backgroundColor: '#F7A116',
    border: 'none'
  }),
  white: Object.assign({}, textStyles.subhead2MenuWhite, shared.button, {
    backgroundColor: '#F7FCFF',
    border: '2px solid #16A9E1',
    color: '#16A9E1',
    padding: '12px'
  }),
  actualWhite: Object.assign({}, textStyles.subhead2MenuWhite, shared.button, {
    backgroundColor: '#fff',
    border: '2px solid #16A9E1',
    color: '#16A9E1',
    padding: '12px'
  }),
  gray: Object.assign({}, textStyles.subhead2MenuWhite, shared.button, {
    backgroundColor: '#CCCCCC',
    border: '2px solid #1C1C1C',
    color: '#666666',
    padding: '12px'
  })
};
