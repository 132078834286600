import { StringUtils } from './stringUtils';

function safeguardExternalUrl(x) {
  const url = StringUtils.safeTrim(x);
  if (url && !/^https?\/\//.test(url))
    return `http://${url}`;
  return url;
}

export const URLUtils = {
  safeguardExternalUrl
};
