import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation , Trans} from 'react-i18next';
import _ from 'lodash';
import { FormattedNumber } from 'react-intl';
import {EventResultsStreams} from '../../data/EventResultsStreams';

import {styles} from './styles';
import {DateTime} from '../shared/DateTime';
import { trackGoogleEvent } from '../../utils/googleEvents';
import {SignUpBanner} from '../shared/SignUpBanner';
import {StickyContainer, Sticky} from 'react-sticky';
import {fetchAthleteRaces} from '../../data/AthleteStreams';
import {EventResultRow} from './EventResultRow';
import {EventResultsIntervalMobile} from './EventResultsIntervalMobile';
import {
  getCourseDetail,
  unsupportedPaceRaceTypes
} from '../../utils/ERPUtil';
import {hasResultInEventCourse} from '../../utils/ClaimUtil';
import {VRBadge} from '../shared/VRBadge';

class EventResultsContainerComponent extends Component {
  static propTypes = {
    eventMainId: PropTypes.number,
    eventCourseId: PropTypes.number,
    divisionId: PropTypes.number,
    eventCourseName: PropTypes.string,
    onExpand: PropTypes.func,
    athlete: PropTypes.object,
    eventMetadata: PropTypes.object,
    options: PropTypes.any,
    pageNumber: PropTypes.number,
    races: PropTypes.array,
    friends: PropTypes.object,
    isEventLive: PropTypes.bool,
    courseId: PropTypes.number,
    isCTLive: PropTypes.bool.isRequired,
    eventCity: PropTypes.string,
    eventState: PropTypes.string,
    eventCountry: PropTypes.string,
    showSignUpBanner: PropTypes.bool,
    athleteRaces: PropTypes.array,
    results: PropTypes.array,
    t: PropTypes.func
  };

  static defaultProps = {
    onExpand: () => {
    },
    showSignUpBanner: false,
  };

  componentDidMount() {
    const {
      results,
      eventCourseId = 0
    } = this.props;

    if (Array.isArray(results) && results.length) {
      if(!!eventCourseId) {
        this.legsBasisSub && this.legsBasisSub.unsubscribe();
        this.legsBasisSub = EventResultsStreams.getLegsBasis(eventCourseId).subscribe((basis) => {
          this.setState({legsBasis: basis});
        });
      }
    }
  }

  componentWillUnmount() {
    this.legsBasisSub && this.legsBasisSub.unsubscribe();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      results,
      raceCourseId,
      eventCourseId = 0
    } = this.props;

    const changed = !_.isEqual(nextProps.results, results)
      && Array.isArray(nextProps.results)
      && !_.isEqual(nextProps.raceCourseId, raceCourseId)
      && nextProps.results.length;

    if (changed && eventCourseId) {
      this.legsBasisSub && this.legsBasisSub.unsubscribe();
      this.legsBasisSub = EventResultsStreams.getLegsBasis(eventCourseId).subscribe((basis) => {
        this.setState({legsBasis: basis});
      });
    }
  }

  claimCallback = () => {
    fetchAthleteRaces(this.props.athlete.RacerID, true)
  };

  _tabulateHead(shouldShowPace, showGender) {
    const {
      t
    } = this.props;

    return (
      <div style={styles.tableHeaders} className='row mx-0'>
        <div className='col-5 pr-0' style={{marginRight: 1}}>
        </div>
        <div className='col px-0' style={styles.headerCol}>
          {t('Overall')}
        </div>
        {
          showGender ?
          <div className='col px-0' style={styles.headerCol}>
            {t('Gender')}
          </div> : <div className='col px-0' style={styles.headerCol} />
        }
        <div className='col px-0' style={styles.headerCol}>
          {t('Division')}
        </div>
        {
          shouldShowPace ?
          <div className='col px-0' style={styles.headerCol}>
            {t('Pace')}
          </div>
          : <div className='col px-0' style={styles.headerCol} />
        }
        <div className='col-3 px-0' style={styles.headerCol}>
          {t('Time')}
        </div>
      </div>
    );
  }


  _tabulateBody(data, results, shouldShowPace, showGender) {
    const {
      masterId,
      eventCourseId: maybeEventCourseId,
      isMobile,
      isEventLive,
      selectedEventStatus,
      eventCity,
      eventState,
      eventCountry,
      showSignUpBanner,
      eventMetadata,
      athleteRaces
    } = this.props;

    const eventCourseId = maybeEventCourseId || data.eventCourseId
    const shouldShowSignUpBanner = (i) => i === 2 && eventCourseId && showSignUpBanner;

    const courseDetail = getCourseDetail(eventCourseId, eventMetadata);
    const {
      isVirtual
    } = courseDetail;

    return (
      <div id={`eventResults_${eventCourseId}`}>
        {
          results.map((result, i) => (
            <EventResultRow
              key={result.entryId || result.bib}
              {...result}
              raceType={data.raceType}
              masterId={masterId}
              intervalRaceType={result.intervalRaceType}
              intervalFull={this.props.results[0].interval.intervalFull}
              shouldShowPace={shouldShowPace}
              isMobile={isMobile}
              isEventLive={isEventLive}
              isVirtual={isVirtual}
              isFriend={false}
              selectedEventStatus={selectedEventStatus}
              eventCity={eventCity}
              eventState={eventState}
              eventCountry={eventCountry}
              showSignUpBanner={shouldShowSignUpBanner(i)}
              hasResultInEventCourse={hasResultInEventCourse(athleteRaces, data.eventCourseId)}
              claimCallback={this.claimCallback}
              eventId={data.eventId}
              eventCourseId={data.eventCourseId}
              showGender={showGender}
            />
          ))
        }
      </div>
    );
  }

  getTotalString = (totalNum) => {
    const {divisionId} = this.props;

    return (
      !divisionId ?
      <div>
        <Trans>
          <FormattedNumber value={totalNum} /> entries in race
        </Trans>
      </div> :
      <div>
        <Trans>
          <FormattedNumber value={totalNum} /> entries in race in division
        </Trans>
      </div>
    );
  };

  arrangeResultsData(result) {
    const arrangedResultsData = result.interval.intervalResults.reduce((acc, curr) => {
      const isUnranked = (
        curr.entryStatus === 'DNF' && result.interval.intervalFull
      ) || curr.entryStatus === 'UNRANKED'

      const currIntervalName = isUnranked ? 'Unranked' : curr.intervalName;
      const currIntervalOrder = isUnranked ? 0 : curr.intervalOrder;

      const interval = acc.find((data) => {
        return data.name === currIntervalName;
      });

      const intervalExists = acc.indexOf(interval);
      if (intervalExists > -1) {
        interval.results.push(curr);
      } else {
        // bail if it is leaderboard
        if (this.props.eventCourseId === 0 && currIntervalName === 'Unranked')
          return acc
        acc.push({name: currIntervalName, order: currIntervalOrder, results: [curr]});
      }
      return acc;
    }, []);

    const orderedData = arrangedResultsData.sort((a, b) => b.order - a.order);
    const full = _.find(orderedData, (data) => data.order === 1);
    const nonFull = _.filter(orderedData, (data) => data.order !== 1);

    return (full ? [full] : []).concat(nonFull);
  }

  renderDesktop() {
    const {
      tableSubtitle,
      eventCourseId,
      onExpand,
      results,
      eventMetadata,
      t
    } = this.props;
    const renderRaceHeader = (
      {
        style, 
        ...rest
      }, 
      intervalName, 
      eventCourseId, 
      totalAthletes, 
      shouldShowPace, 
      showTotals, 
      showGender
   ) => {
      const courseDetail = getCourseDetail(eventCourseId, eventMetadata);
      const {
        eventCourseName,
        isVirtual
      } = courseDetail;


      let fixedStyle = style;
      if(style.top !== undefined) {
        fixedStyle = {...style, top: style.top + 40};
      }

      return (
        <div
          style={{
            zIndex: 5,
            backgroundColor: '#fff',
            ...fixedStyle
          }}>
          {
            showTotals ?
            <div style={{marginTop: 16, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {eventCourseName}
              {
                isVirtual === true ? <div style={{marginLeft: 8}}><VRBadge/></div> : null
              }
            </div> : null
          }
          {
            (showTotals && this.props.eventCourseId && totalAthletes) ?
              <div style={styles.raceSubtitle} id='total-results-string'>
                { this.getTotalString(totalAthletes) }
              </div>
              : null
          }
          <div style={styles.intervalTitle}>
            {!results[0].thirdParySystemRaceId ? t('Full Course') : intervalName || t('Full Course')}
          </div>
          {this._tabulateHead(shouldShowPace, showGender, isVirtual)}
        </div>
      );
  };

    const resultsSplitByInterval = (result) => {
      const orderedData = this.arrangeResultsData(result);
      const shouldShowPace = _.indexOf(unsupportedPaceRaceTypes, result.raceType) === -1; // TODO

      return (
        <div>
          {
            orderedData.map((interval, i) => {
              const intervalName = interval.name || result.interval.intervalName;
              const showGender = _.get(interval, 'results[0].entryType', '').toLowerCase() !== 'team';

              return(
                <MaybeStickyContainer beSticky={true} key={interval.order}>
                  <MaybeSticky beSticky={true} topOffset={-80}>
                    {(props) => renderRaceHeader(props, intervalName, result.eventCourseId, result.totalAthletes, shouldShowPace, i === 0, showGender)}
                  </MaybeSticky>
                  {this._tabulateBody(result, interval.results, shouldShowPace, showGender)}
                  {
                    !eventCourseId && result.totalAthletes > 3 &&
                    <LoadMore
                      seeMoreRow={styles.seeMoreRow}
                      seeMoreButton={styles.seeMoreButton}
                      onClick={() => {
                        onExpand(result.eventCourseId);
                      }}
                      messages={t('VIEW ALL')}
                    />
                  }
                </MaybeStickyContainer>
              )
            })
          }
        </div>
      );
    };

    const renderTitle = (resultsSet) => {
      return (
        <div style={styles.raceTitle}
        >
          <h4 style={styles.courseNameHeader} className='race-title'>
            {resultsSet.courseName || resultsSet.raceCourseName}
          </h4>
          {
            tableSubtitle &&
            <div style={styles.raceSubtitle}>
              {tableSubtitle}
            </div>
          }
        </div>
      );
    };

    return (
      <div style={styles.resultContainer}>
        <MaybeStickyContainer beSticky={true}>
          {
            results.map((result, index) => {
              return (
                <div key={index}>
                  {
                    // Course title
                  }
                  <MaybeSticky beSticky={false} topOffset={-100}>
                    {renderTitle(result)}
                  </MaybeSticky>
                  {
                    // Result rows
                  }
                  {
                    (result.interval.intervalResults.length)
                      ?  resultsSplitByInterval(result) :  null
                  }
                </div>
              );
            })
          }
        </MaybeStickyContainer>
      </div>
    );
  }

  renderMobile() {
    const {
      masterId,
      tableSubtitle,
      eventCourseId,
      eventMetadata,
      pageSize,
      results,
      pageNumber,
      startEpoch,
      showSignUpBanner,
      t,
      onExpand
    } = this.props;

    const getTitle = (resultsSet, eventCourseId) => {
      const courseDetail = getCourseDetail(resultsSet.eventCourseId, eventMetadata);
      const { eventCourseName, isVirtual } = courseDetail;
      const {totalAthletes} = resultsSet;
      

      if (!tableSubtitle) {
        return (
          <div style={Object.assign({}, styles.mobile.titleContainer, styles.mobile.sectionTitle)}>
            {eventCourseName}
            {
                isVirtual === true ? <div style={{marginTop: 3, marginBottom: 8}}><VRBadge/></div> : null
              }
            {
              (eventCourseId && totalAthletes) ?
                <div style={styles.raceSubtitle} id='total-results-string'>
                  { this.getTotalString(totalAthletes) }
                </div>
                : null
            }
          </div>
        );
      }
      return (
        <div style={styles.mobile.titleContainer}>
          <div style={styles.mobile.sectionTitle}>
            {eventCourseName}
          </div>
          <div style={styles.mobile.tableSubtitle}>
            {tableSubtitle}
          </div>
        </div>
      );
    };

    const getFooter = (resultsSet) => {
      const seeMoreClick = () => onExpand(resultsSet.eventCourseId  || resultsSet.courseId);
      const seeMore = !eventCourseId && resultsSet.totalAthletes > 2 &&
      <LoadMore
        seeMoreRow={styles.seeMoreRow}
        seeMoreButton={styles.seeMoreButton}
        onClick={() => {
          seeMoreClick();
        }}
        messages={t('VIEW ALL')}
      />;

      return (
        <div>
          {seeMore}
        </div>
      );
    };

    const rows = (result, leaderboard) => {
      const orderedData = this.arrangeResultsData(result);
      return (
        orderedData.map((interval, idx) => {
          return (
            <EventResultsIntervalMobile
              key={`results-interval-${idx}`}
              masterId={masterId}
              eventMetadata={eventMetadata}
              interval={interval}
              result={result}
              results={results}
              leaderboard={leaderboard}
              showSignUpBanner={showSignUpBanner}
              pageNumber={pageNumber}
              pageSize={pageSize}
            />
          );
        })
      );
    };

    return (
      <div style={{marginTop: 10}}>
        {
          results.map((result, index) => {
            return (
              <div key={index}>
                <div>
                  {getTitle(result, eventCourseId)}
                </div>
                <div style={styles.mobile.raceDate}>
                  <DateTime date={startEpoch * 1000} showTime={false} icon={null}/>
                </div>
                <div className="row px-0" style={styles.mobile.mainRow}>
                  <div className="col-2 p-0" style={{...styles.mobile.tableLabel, textAlign: 'center'}}>
                    {t('Rank')}
                  </div>
                  <div className="col-6 p-0" style={{...styles.mobile.tableLabel, textAlign: 'left'}}>
                    {t('Athlete/Bib/Division')}
                  </div>
                  <div className="col-3 pr-10" style={{...styles.mobile.tableLabel, textAlign: 'left'}}>
                    {t('Time')}
                  </div>
                </div>
                  {rows(result, !eventCourseId)}
                <div style={styles.mobile.footer}>
                  {getFooter(result)}
                </div>
              </div>
            );
          })
        }
      </div>
    )

  }

  render() {
    return this.props.isMobile ? this.renderMobile() : this.renderDesktop();
  }
}

export const EventResultsContainer = withTranslation()(EventResultsContainerComponent);

/**
 * We do not want to be sticky if we are listing all races on ERP
 * @param children
 * @param beSticky
 * @param props
 * @return {*}
 * @constructor
 */
export const MaybeStickyContainer = ({children, beSticky, ...props}) => {
  if(!beSticky) {
    return (<div>{children}</div>);
  }

  return (<StickyContainer {...props}>{children}</StickyContainer>);
};

/**
 * @see MaybeStickyContainer
 * @param children
 * @param beSticky
 * @param props
 * @return {*}
 * @constructor
 */
export const MaybeSticky = ({children, beSticky, ...props}) => {
  if(!beSticky) {
    return (<div>{children}</div>);
  }

  return (<Sticky {...props}>{children}</Sticky>);
};

export const LoadMore = ({seeMoreRow, seeMoreButton, onClick, messages}) => {
  return (
    <div style={seeMoreRow}>
      <button
        className='view-all-results'
        style={seeMoreButton}
        onClick={() => {
          onClick();
          trackGoogleEvent('Results','Click','View All');
        }}
      >
        {messages}
      </button>
    </div>
  );
};


export const SignUpBannerResults = (props) =>
  <SignUpBanner
    title={props.t('all your results in one place')}
    body={props.t('Create a profile, claim all your hard-earned race results, and connect '
      + 'with friends and events')}
    {...props}
  />;
