import {FetchApi} from '../lib/anuket-http';
import {getTokenRacerId, getToken} from '../utils/isLoggedIn';
import {configs} from '../configs';

/**
 * Sends unclaim request for result.
 *
 * @param params
 * @return {*}
 */
export async function unclaimResult(params) {
  const token = await getToken();
  const {eventCourseId, entryId, ctEntryId, ctLiveEntryId, bib, fullName} = params;

  const body = {eventCourseId, entryId, ctEntryId: ctEntryId || ctLiveEntryId, fullName};
  if(bib) {
    body.bib = bib;
  }
  return FetchApi.doFetch(
    `${configs.ATHLINKS_API_HOST}/Athletes/Api/${getTokenRacerId()}/UnclaimResult`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  );
}
