import { flow, map } from 'lodash'
import { TimeUnit, DistanceUnit, PaceType } from '@eventops/athlinks-results-model'
import Axios from 'axios'
import { computeFollowable } from './rules/following'
import { computeClaimable } from './rules/claiming'
import { configs } from '../configs'
import { getCORSConfig } from './getCORSConfig'
import { logInfo } from './Logging'

export const maybeSynthesizeTimeZone = (
  { startDateTime:
    {
      timeInMilliseconds,
      timeZone
    },
    ...theRest
  }) => ({
    ...theRest,
    startDateTime: {
      timeInMilliseconds,
      timeZone: timeZone || 'America/Denver'
    },
    synthesizedTimeZone: !!!timeZone
  })

export const extractAthleteRacePage = (
  {
    data: {
      claimed,
      entryId,
      athlete: {
        ageOnRaceDay,
        firstName,
        keycloakId,
        lastName,
        gender,
        location,
        racerId
      },
      courses: coursesCardModel,
      event,
      following,
      isLoggedIn,
      stream,
      isMe,
      bib,
      irp
    }
  }
) => ({
  claimed,
  athleteCardModel: {
    ageOnRaceDay,
    bib,
    ...computeClaimable(!!claimed, isMe, isLoggedIn),
    displayName: `${firstName} ${lastName.slice(0)[0]}`,
    entryId,
    firstName,
    ...computeFollowable(!!following, isMe, isLoggedIn),
    gender: gender ? gender : 'unspecified',
    isLoggedIn,
    isMe,
    keycloakId,
    lastName,
    location,
    racerId
  },
  entryId,
  coursesCardModel,
  eventCardModel: maybeSynthesizeTimeZone(event),
  isLoggedIn,
  isMe,
  raceCardModel: {
    ...maybeSynthesizeTimeZone(event),
    irp,
    courses: map(coursesCardModel, (course) => ({
      ...course,
      goalTime: {
        timeInMillis: 1320000,
        timeUnit: TimeUnit.MINUTES
      },
      goalPace: {
        distance: {
          distanceInMeters: 1609.34,
          distanceUnit: DistanceUnit.MILES
        },
        time: {
          timeInMillis: 480000,
          timeUnit: TimeUnit.MINUTES
        },
        paceType: PaceType.PACE
      }
    }))
  },
  similarAthletesCardModel: { athletes: [] },
  stream: !!stream
})

export const getARP = (
  id,
  token
) => new Promise(async (resolve, reject) => {
  const url = `${configs.athleteApiUrl}/entries/${id}`
  logInfo('hitting', url)
  Axios.get(url,
    getCORSConfig(token)
  ).then(
    flow(
      extractAthleteRacePage,
      resolve
    ))
    .catch((error) => {
      reject(new Error(
        `ARP lookup failed for entryId "${id}": "${error}"`
      ))
    })
})

export const getArpResult = (
  id,
  token
) => new Promise(async (resolve, reject) => {
  const url = `${configs.athleteApiUrl}/entries/${id}/results`
  logInfo('hitting', url)
  Axios.get(url,
    getCORSConfig(token)
  ).then(
    flow(
      (({data}) => data),
      resolve
    ))
    .catch((error) => {
      reject(new Error(
        `ARP result lookup failed for entryId "${id}": "${error}"`
      ))
    })
})
