import React from 'react'
import { withTranslation } from 'react-i18next'

import { style } from '../arpModalStyles'

const UnfollowAthleteModalSpinnerHeaderComponent = (props) => {
  const {
    t
  } = props

  return(
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Unfollowing Athlete')}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t('Filling the paperwork to defriend...')}
      </div>
    </div>
  )
}

export const UnfollowAthleteModalSpinnerHeader = (withTranslation()(UnfollowAthleteModalSpinnerHeaderComponent))