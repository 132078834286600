import { sprintf } from 'sprintf-js';

function formatNum(num, digits) {
  return sprintf(`%0${digits}d`, Math.floor(num));
}

function displayTime(rate) {
  const hours = rate / 3600;
  const mins = (hours % 1) * 60;
  const secs = (mins % 1) * 60;

  return [
    formatNum(hours, 1),
    formatNum(mins, hours >= 1 ? 2 : 1),
    formatNum(secs, 2)
  ];
}

const typeMapping = {
  '5k run': 'running',
  'adventure racing': 'offroadrun',  //bad ux (might want unique picture for this)
  aquabike: 'swimming',
  aquathlon: 'swimming',
  criterium: 'biking',
  cycling: 'biking',
  cyclocross: 'offroadbike',
  'fun run': 'funrun',
  'half-marathon': 'running',
  marathon: 'running',
  'mountain biking': 'offroadbike',
  'mountain climb': 'offroadrun',
  'non-athletic (untimed)': 'funrun',
  'off-road aquabike': 'swimmingoutdoor',
  'off-road duathlon': 'offroadrun',
  'off-road triathlon': 'offroadrun', //bad ux (show running and biking on pavement)
  orienteering: 'offroadrun',
  'race walking': 'running',
  'ski - cross country': 'crosscountryski',
  'snowshoe running': 'running',  //bad ux (show people running on pavement not snow)
  'trail running': 'offroadrun',
  'trail running & hiking': 'offroadrun',
  untimed: 'funrun',
}

const raceTypeMap = {
  running: (m, s, asArray) => {
    const [hours, mins, secs] = displayTime(s / (0.000621371 * m));
    // eslint-disable-next-line
    const time = (hours != '0' ? `${hours}:` : '') + `${mins}:${secs}`;
    const units = 'Min/Mi';
    return asArray ? [time, units] : `${time} ${units}`;
  },
  offroadrun: (m ,s, a) => raceTypeMap.running(m, s, a),
  other: (m, s, a) => raceTypeMap.running(m, s, a),
  funrun: (m, s, a) => raceTypeMap.running(m, s, a),
  obstacle: (m, s, a) => raceTypeMap.running(m, s, a),
  crosscountryski: (m, s, a) => raceTypeMap.running(m, s, a),
  swimming: (m, s, asArray) => {
    const [hours, mins, secs] = displayTime((s * 100) / (1.09361 * m));
    // eslint-disable-next-line
    const time =(hours != '0' ? `${hours}:` : '') + `${mins}:${secs}`;
    const units = 'Min/100Yd';
    return asArray ? [time, units] : `${time} ${units}`;
  },
  swimmingindoor: (m, s, a) => raceTypeMap.swimming(m, s, a),
  swimmingoutdoor: (m, s, a) => raceTypeMap.swimming(m, s, a),
  cycling: (m, s, asArray) => {
    const mph = (0.000621371 * m) / (s / 3600);
    const time = sprintf('%.02f', mph);
    const units =  'MPH';
    return asArray ? [time, units] : `${time} ${units}`;
  },
  biking: (m, s, a) => raceTypeMap.cycling(m ,s, a),
  offroadbike: (m, s, a) => raceTypeMap.cycling(m, s, a)
};

export function calculatePace(tuple, ticks, asArray) {
  const secs = ticks / 1000;
  const meters = tuple.meters;
  const type = tuple.type.toLowerCase();

  const pace = raceTypeMap[type] || raceTypeMap[typeMapping[type]];
  return typeof pace === 'function' ? pace(meters, secs, asArray) : null;
}
