import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button as MUIButton, styled } from '@material-ui/core';
import { colors, textStyles } from '../../shared/styles';

const defaultStyle = {
  ...textStyles.sectionHead,
  color: colors.white,
  backgroundColor: colors.blue,
  '&:hover': {
    backgroundColor: colors.darkBlue,
  },
  minWidth: '100px',
  height: '36px',
  borderRadius: '2px',
  padding: '8px 12px 6px'
};

const StyledButton = styled(MUIButton)(defaultStyle);

const ButtonComponent = ({variant = 'contained', style = {}, text, t, tReady, Component = null, ...rest}) => {
  return (
    <StyledButton style={style} variant={variant} {...rest}>
      {t(text)}
    </StyledButton>
  )
}

export const Button = withTranslation()(ButtonComponent);