import React  from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { CuratedEventCard } from './CuratedEventCard';
import { CuratedEventDetail } from './CuratedEventDetail';
import { CuratedEventsMap } from './CuratedEventsMap';
import { Carousel } from '../../shared/Carousel';
import { Loading } from '../../shared/Loading';
import { Link } from 'react-router-dom';
import RightIcon from 'react-icons/lib/fa/angle-right';
import LeftIcon from 'react-icons/lib/fa/angle-left';
import { EventDetails } from '../../heroImage/EventDetails';
import { colors } from '../../../shared/styles';
import { Banner, AthlinksAds } from '../../shared/Banner';
import { bannerStyle } from '../../../shared/styles';
import { styles } from './styles';

export const overlapPercentForActiveSlide = 0.125;

const { curatedEvents } = styles;

class CuratedEventsComponent extends React.Component {
  static propTypes = {
    category: PropTypes.object.isRequired,
    events: PropTypes.array,
    isMobile: PropTypes.bool,
    windowWidth: PropTypes.number,
    t: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      activeSlide: 0
    };

    AthlinksAds.init();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.events !== this.props.events) {
      if (this.state.activeSlide >= (nextProps.events || []).length) {
        this.setState({
          activeSlide: 0
        })
      }
    }
  }

  componentDidMount(){
    this.setState({
      activeSlide: 0
    })
  }

  //Note: if using react-slick, each child must be wrapped in div, else it throws an internal exception
  renderEvents({events, activeSlide, slideWidth, slideHeight, windowWidth}) {
    const {isMobile} = this.props;

    return (events || []).map((event, idx) => {
      const isActive = (idx === activeSlide);
      const scaledWidth = isActive ? slideWidth * 1.2 : slideWidth;
      const scaledHeight = isActive ? slideHeight * 1.5 : slideHeight;

      //have the active slide overlap the previous slide
      const marginLeft = isActive ? -slideWidth*overlapPercentForActiveSlide  : 29;

      const paddingSizeleft = windowWidth > 320 && windowWidth < 375 ? -13 : -10;
      return (
        <div id={`curated-event-page-${idx}`}
             style={curatedEvents.container(scaledWidth, scaledHeight)}
          key={'event-'+idx}>
          <div
            style={curatedEvents.cardContainer(isMobile, isActive, marginLeft, paddingSizeleft)}>
            <CuratedEventCard
              event={event}
              isActive={isActive}
              idx={idx}
              isMobile={isMobile}
            />
          </div>
        </div>
      );
    });
  }

  render() {
    const {
      category,
      events,
      isMobile,
      windowWidth,
      t
    } = this.props;

    const {
      activeSlide
    } = this.state;

    const slidesToScroll = 1;//windowWidth > 1200 ? 3 : windowWidth > 900 ? 2 : 1;
    const widthDesktop = windowWidth >=320 && windowWidth <=350 ? 13 : windowWidth >350 && windowWidth <=375 ? 10 : 8;
    const slideWidth = isMobile ? windowWidth*4/6 - widthDesktop : 400;
    const slideHeight = isMobile ? 250 : 360;
    const showNavOverlay = true;

    const activeEvent = events[activeSlide];

    const previousSlideEdgeSize = isMobile ? slideWidth/10 : slideWidth*8/10;

    //line up paragraph left edge with active slide left edge
    const paragraphPaddingLeft = previousSlideEdgeSize - slideWidth*overlapPercentForActiveSlide;

    //scale down slides as you move away from active slide - 3D/perspective effect
    const scaleWithDistance = null;//0.07;

    //overlap slide over previous one
    const overlap = 0;//10;
    const mobilePadding = windowWidth/10;
    const paddingSide = isMobile ? 10 : 30;

    const adunits = AthlinksAds.adsForPage('CuratedEvents');

    return (
      <div style={curatedEvents.content}>
        <div style={ curatedEvents.nameContent(isMobile, paragraphPaddingLeft, mobilePadding)}>
          <span style={curatedEvents.name(isMobile)} id="curated-event-name">
            {category.name}
          </span>
        </div>

        {(!events || events.length === 0) &&
          <div style={curatedEvents.loading}>
            <Loading timeoutMessage={t('Still thinking...')}/>
          </div>
        }
        <div style={curatedEvents.carouselContainer(isMobile)} id='carousel-slider'>
            <Carousel activeIdx={activeSlide}
                    slideWidth={slideWidth}
                    scaleWithDistance={scaleWithDistance}
                    overlap={overlap}
                    slideHeight={slideHeight*1.51}
                    slidesToScroll={slidesToScroll}
                    wrapAround={true}
                    dragThresholdToSwipe={10}
                    focusOnSelect={true}
                    previousSlideEdgeSize={previousSlideEdgeSize+30}
                    showNavOverlay={showNavOverlay}
                    showNavDots={false}
                    isMobile={isMobile}
                    renderLeftArrow={ isMobile ? PrevNavButtonMob : PrevNavButton}
                    renderRightArrow={isMobile ? NextNavButtonMob : NextNavButton}
                    onChange={this.onChange} >
            {this.renderEvents({events, activeSlide, slideWidth, slideHeight, windowWidth})}

          </Carousel>
        </div>
        <div className="container" style={ curatedEvents.activeContent }>
          <div className='row' style={{...curatedEvents.row, borderBottom:0, minHeight: 355}}>
            <div className='col-md-8 col-lg-8'>
              <div style={{...curatedEvents.rowContainer, minHeight: 355 }}>
              {activeEvent &&
                <div style={curatedEvents.activeEvent}>

                  <EventDetails
                    color={colors.textColor}
                    curatedMode={true}
                    eventId={activeEvent.masterId}
                    master={activeEvent}
                    isMobile={isMobile}
                    excludeSearch={true}
                  />

                </div>
                }
                <SeparatorLine paddingSide={paddingSide} />
                <div
                  className='col-md-12 col-lg-12'>
                  {activeEvent &&
                  <CuratedEventDetail
                    event={activeEvent}
                    isMobile={isMobile}
                  />
                  }
                </div>
              </div>
            </div>
            <div className='col-md-4 col-lg-4'>
              <div style={curatedEvents.rightColumn}>
                {activeEvent && !isMobile &&
                <div style={curatedEvents.loadMore}>
                  <Link to={`/event/${activeEvent.masterId}`} id='learn-more-button'>
                  <span style={curatedEvents.register}>
                    {t('Learn More')}
                  </span>
                  </Link>
                </div>
                }
                <div style={curatedEvents.eventMap} id='curated-events-map'>
                  <CuratedEventsMap
                    style={curatedEvents.curatedEventsMap}
                    events={events}
                    activeEvent={activeEvent}
                    onEventSelected={this.onEventSelected}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
             {adunits.map((x) => {
                return (
                  <div key={x.adKey} style={{...bannerStyle(isMobile), margin: 15}}>
                    <Banner
                      data-ad={x.adKey}
                      adKey={x.adKey}
                      divId={x.divId}
                      style={curatedEvents.banner}/>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    )
  }

  //Carousel changed slide and is letting us know (next/prev buttons, swiping)
  onChange = (slide) => {
    if (this.state.activeSlide !== slide) {
      this.setState({
        activeSlide: slide
      });
    }
  };

  onEventSelected = (event) => {
    const eventIdx = (this.props.events || []).findIndex((e) => e.masterId === event.masterId);
    if (eventIdx >= 0 && this.state.activeSlide !== eventIdx) {
      this.setState({
        activeSlide: eventIdx
      })
    }
  }
}

export const CuratedEvents = withTranslation()(CuratedEventsComponent);

const SeparatorLine = ({paddingSide}) => {
  return (
    <div className='row' style={{...curatedEvents.row, borderTop:0,borderBottom:0}}>
      <div
        className='col-md-12 col-lg-12'
        style={{
          paddingLeft:paddingSide,
          paddingRight:paddingSide
        }}>
        <div
          style={curatedEvents.separatorLine} />
      </div>
    </div>
  )
};

export const PrevNavButton = (props) => <LeftIcon {...props} style={curatedEvents.leftStyle()}/>;
export const NextNavButton = (props) => <RightIcon {...props} style={curatedEvents.leftStyle()}/>;
export const PrevNavButtonMob = (props) => <LeftIcon {...props} style={curatedEvents.leftSideMobile()}/>;
export const NextNavButtonMob = (props) => <RightIcon {...props} style={curatedEvents.rightSideMobile()}/>;

