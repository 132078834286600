import convertRaceTypeToTimeUnits from './convertRaceTypeToTimeUnits';
import convertTimeUnitsToOldTimeUnit from './convertTimeUnitsToOldTimeUnit';
import { DivisionResultMetadata, IntervalResultMetadata } from '../types.results.reignite';

export default function transformReigniteEntryIntervalTime({
  chipTimeInMillis,
  division,
  gunTimeInMillis,
  interval,
}: {
  chipTimeInMillis: number;
  division: DivisionResultMetadata;
  gunTimeInMillis: number;
  interval: IntervalResultMetadata;
}) {
  return {
    timeInMillis: division.rankType === 'chip'
      ? chipTimeInMillis
      : gunTimeInMillis,
    timeUnit: convertTimeUnitsToOldTimeUnit(
      convertRaceTypeToTimeUnits(interval.raceType)
    ),
  };
}
