import { IndividualResultState } from '../model/individualResultState';
import { ClaimActionTypes as ClaimAction } from '../actions/types'
import { ResultActionTypes as ResultAction } from '../resultsClient/types';

export default (state = IndividualResultState, action) => {
  switch(action.type) {
    case ResultAction.GET_INDIVIDUAL_RESULTS_ERROR:
      return {
        ...state,
        errorMessage: 'Failed to get individual result',
        isLoading: false,
        wasStopped: false
      };
    case ResultAction.GET_INDIVIDUAL_RESULTS:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
        wasStopped: false,
        args: action.args
      };
    case ResultAction.GET_INDIVIDUAL_RESULTS_STOP:
      return IndividualResultState;
    case ResultAction.GET_INDIVIDUAL_RESULTS_UPDATE:
      console.log('got here ext', action)
      return {
        ...state,
        result: action.result,
        isLoading: false,
        errorMessage: '',
        wasStopped: false
      }
    case ClaimAction.AFTER_CLAIM_INDIVIDUAL_RESULTS_UPDATE:
      return {
        ...state,
        result: {
          ...state.result,
          racerId: action.racerId
        }
      }
    default:
      return state;
  }
}
