import { RaceLeaderboardResults } from '../types.results.reignite';
import transformReigniteIntervalResults from './transformReigniteIntervalResults';

export default function transformReigniteRaceLeaderboardResults({
  data,
  eventId,
  isLinked,
}: {
  data: RaceLeaderboardResults;
  eventId: number;
  isLinked: boolean
}) {
  return [
    {
      bracketName: data.division.name, // for mobile rankings
      eventId,
      eventCourseId: data.race.id,
      interval: {
        intervalFull: true, // need this for DNF's
        intervalName: 'latest',
        intervalResults: data.intervals.flatMap(i =>
          transformReigniteIntervalResults({
            division: data.division,
            interval: i,
            results: i.results,
            source: data.source,
          }).map(x => ({
            ...x,
            intervalName: i.name,
          }))
        ),
      },
      thirdParySystemRaceId: isLinked,
      totalAthletes: data.division.totalAthletes,
    }
  ]
}
