import { FetchApi } from '../lib/anuket-http';
import { eventBus, streamHook, streamFail } from './Streams';
import { configs } from '../configs';
import {setEventOptions} from './EventStreams';
import { getEventRaces, extractRaceCourses } from './RaceStreams';

export const INVALID_MASTER_EVENTID_MESSAGE = 'Invalid Master Event ID';

/**
 * Get master event by id
 * @param masterId
 */
export function fetchMasterEvent(masterId) {
  const streamId = `master-event-${masterId}`;

  if(parseInt(masterId) > 0) {
    streamFail(
      streamId,
      FetchApi.memoizeFetch(10000)(
        `${configs.ATHLINKS_API_HOST}/MasterEvents/Api/${masterId}`
      ),
      ({ErrorMessage, result}) => {
        if(ErrorMessage) {
          return {error: ErrorMessage};
        }

        setEventOptions(masterId, getEventRaces(result.eventRaces));
        const altStreamId = `GetAthlinksRacesByMasterId-${masterId}`;
        eventBus.publish(altStreamId, extractRaceCourses(Object.assign({}, {success: true, result})));

        return result;
      }
    );
  }
  else {
    return streamHook(streamId, {error: INVALID_MASTER_EVENTID_MESSAGE});
  }
}

export function getMasterEventStream(masterId) {
  return streamHook(`master-event-${masterId}`, {isLoading: true});
}

const masterIdRe = /^Master Event ID = \d+ not found/;

export function isInvalidMasterEventError(error) {
  return error === INVALID_MASTER_EVENTID_MESSAGE || masterIdRe.test(error);
}
