import { flow } from 'lodash'
import { UserPrefsState } from '../model/userPrefsState';

const userPreferencesKey = 'userPreferences'

/**
 * Takes in a list of prefs, pulls out the keys and just writes them to local storage
 *  
 * @param { key: value } prefs 
 */
export const setUserPrefs = (prefs) => {
  try {
    flow(
      JSON.stringify,
      (prefsAsString) => localStorage.setItem(userPreferencesKey, prefsAsString)
    )(prefs)
  } catch (err) {
    console.warn('Could not write to Local Storage -> ', err)
  }
  return prefs
} 

const parseOrDefault = (json) => {
  try {
    return JSON.parse(json)
  } catch (err) {
    return UserPrefsState
  }
} 

/**
 * Extracts prefs as string, converts to JSON
 */
export const getUserPrefs = flow(
  () => localStorage.getItem(userPreferencesKey),
  parseOrDefault
)