import React, {Component} from 'react';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import UpIcon from 'react-icons/lib/fa/angle-up';
import DownIcon from 'react-icons/lib/fa/angle-down';
import Style from 'style-it';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';

import FilterControls from './FilterControls';
import MobileFilterHeader from './MobileFilterHeader';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters'
import {getAthleteKeycloakStream} from '../../data/AthleteStreams';
import {_filterTab} from './styles';
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';

const streamId = 'unclaimed-results-search-filters-stream';

class BirthYear extends Component {

  constructor(props) {
    super(props);
    this.birthYear = defaultFilterData.filters.birthYear;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.values && nextProps.values) {
      if(this.props.athleteKeycloak && this.props.athleteKeycloak['date-of-birth']) {
        this.birthYear = this.props.athleteKeycloak['date-of-birth'].split('-')[0]
      } else if (this.props.athlete.athlete && this.props.athlete.athlete.Age) {
        this.birthYear = new Date().getFullYear() - this.props.athlete.athlete.Age
      } else {
        this.birthYear = nextProps.values.birthDateFilter.value
      }
    }
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const { values, history } = this.props;
    const filters = {
      ...values,
      birthDateFilter: {
        enabled: this.birthYear !== defaultFilterData.filters.birthYear,
        value: this.birthYear
      }
    };

    setFilterValues(streamId, filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  render() {

    const {
      isMobile,
      onClose,
      t
    } = this.props;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.ageFilterWrap(isMobile)}>
        {
          isMobile && <MobileFilterHeader
            title={t('Birth Year')}
            onClose={onClose}
          />
        }
        {(
          Style.it(_filterTab.pseudoStyles(),
            <div style={{padding: 20, position: 'relative'}}>
            <UpIcon size={35} style={_filterTab.upIcon}
                onClick={() => {
                  this.birthYear = this.birthYear === '' ? 1980 : this.birthYear + 1;
                  this.forceUpdate();
                }}
              />
              <DownIcon size={35} style={_filterTab.downIcon}
                onClick={() => {
                  this.birthYear = this.birthYear === '' ? 1980 : this.birthYear - 1;
                  this.forceUpdate();
                }}
              />
              <input
                type='number'
                className='birthYearInput'
                value={this.birthYear}
                pattern="\d{4}"
                style={_filterTab.birthYearinput}
                onKeyDown={(event) => {
                  if( event.key === 'Enter' ){
                    this.submitFilter()
                  }
                }}
                placeholder={t('Enter Birth Year')}
                onChange={(e) => {
                  if(e.target.value.length > 4) return;
                  this.birthYear = e.target.value;
                  this.forceUpdate();
                }}
              />

            </div>
          )
        )}
        <FilterControls
          style={{ justifyContent: 'flex-end' }}
          onSave={this.submitFilter}
          onReset={() => {
            this.birthYear = defaultFilterData.filters.birthYear;
            this.forceUpdate();
          }}
        />
      </div>
    )
  }
};

export default withTranslation()(connectStream({
  athleteKeycloak: () => getAthleteKeycloakStream(window.kc.tokenParsed ? window.kc.tokenParsed['athlinks-racer-id'] : 0),
  values: () => getFilterValues(streamId)
})(withRouter(BirthYear)));
