import { heroImageResolver } from '../lib/anuket';
import { configs } from '../configs';
const url = require('url');

const TTL = 900*1000; // 15 mins
let cache = {date: {}, url: {}};

export function getImage(type, size) {
  const key = [type, size].join('-');

  if (cache.date[key] && Date.now() - TTL > cache.date[key]) {
    delete cache.date[key];
    delete cache.url[key];
  }

  if (!cache.url[key]) {
    const imgUrl = heroImageResolver.getHeroImageUrl(type, size);
    const parsed = url.parse(String(imgUrl));
    cache.date[key] = (parsed.query || '').replace(/^date=/, '');
    cache.url[key] = parsed.protocol && parsed.host
      ? parsed.protocol + '//' + parsed.host + parsed.pathname
      : parsed.pathname;
  }

  return cache.url[key];
}

export function heroImageUrl(type, isMobile, ctliveEventId, encodeUrls) {
  const size = 1600;
  const imageForType = encodeUrls ? encodeURIComponent(getImage(type, size)) : getImage(type, size);
  const runningImage = encodeUrls ? encodeURIComponent(getImage('running', size)) : getImage('running', size);

  if (configs.bragiUrl.includes('local.athlinks.com')) {
    return imageForType
  }

  return ctliveEventId
    ? `${configs.bragiUrl}/aws/hero/${ctliveEventId}/${size}/${imageForType}`
    : `${configs.bragiUrl}/aws/image?img=${imageForType}&img=${runningImage}`;
}

export function getFacebookHeroImage(type, ctliveEventId) {
  const imageForType = getImage(type, 1600);
  return ctliveEventId
    ? `${configs.bragiUrl}/aws/hero/${ctliveEventId}/1600/${imageForType}`
    : imageForType;
}
