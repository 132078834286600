import { fonts, colors, textStyles } from '../../shared/styles';

export const profileTagStyles = {
	profileColumn: {
    minWidth: 0, // to allow text to be truncated
  },
  profileCell: {
    display: 'flex',
		alignItems: 'center',
		height: 56
  },
  profilePhotoContainer: {
    display: 'inline-block',
    marginRight: 30,
    width: 32
	},
	profileFollow: (isLoggedIn) => ({
		borderRadius: '50%',
		display: isLoggedIn ? 'none' : '',
		fontFamily: 'ProximaNovaBold',
		fontSize: 23,
		width: 20,
		lineHeight: '24px',
    backgroundColor: colors.blue,
    height: 20,
    color: 'white',
		padding: '0px 0px 0px 4px',
		float: 'right'
	}),
  profileNameContainer: (isMobile) => ({
    flex: '1 1 auto',
    verticalAlign: 'top',
    width: isMobile ? 160 : 186,
    position: 'relative'
  }),
  profileNameLink: (isMobile) => ({
		display: 'block',
		fontFamily: 'ProximaNovaBold',
    fontSize: isMobile ? 18 : 16,
    lineHeight: '20px',
    color: colors.textColor,
    textDecoration: 'none',
    width: isMobile ? 160 : 186,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
	}),
  profileCourse: {
    display: 'inline-block',
    fontSize: 12,
		color: colors.grey,
		lineHeight: '15px',
    maxWidth: 186,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
}

export const goalStyles = {
  horizontalSeparator: {
    border: '1px solid colors.greyLine',
    marginLeft: '0px',
    marginBottom: '5px',
    marginTop: '10px'
  },
  labelStyle: {
    color: colors.textColor,
    fontFamily: 'ProximaNovaBold',
    fontSize: 12,
    lineHeight: '1em',
    letterSpacing: '0.5px',
    padding: 0,
    textAlign: 'left',
    textTransform: 'uppercase'
  },  
  timeStyle: (isMobile) => ({
    color: colors.textColor,
    fontFamily: 'LatoLightItalic',
    fontSize: isMobile ? '30px' : '44px',
    fontWeight: '300',
    letterSpacing: '-0.44px',
    lineHeight: '57px'
  })
}

export const countdownStyles = {
  irpContainer: (timeLeft) => ({
    display: timeLeft ? 'none' : '',
    marginBottom: 53,
    // whiteSpace: 'nowrap'
  }),
  countdownContainer: (timeLeft) => ({
    margin: 'auto',
    marginBottom: timeLeft ? 53 : 30,
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }),
	countdownColumn: {
		display: 'inline-block',
	},
	countdownBigNum: (enabled, isMobile) => ({
		marginBottom: 10,
		color: enabled ? colors.textColor : colors.greyLine,
		display: 'inline-block',
		fontFamily: 'LatoBold',
		fontSize: isMobile ? 42 : 55,
		fontWeight: 900,
		height: 66,
//		lineHeight: 66,
		textAlign: 'center'
	}),
  labelStyle : {
    color: colors.textColor,
    fontFamily: 'ProximaNovaBold',
    fontSize: 12,
    lineHeight: '1em',
    letterSpacing: '0.5px',
    padding: 0,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
	separator: {
		borderLeft: `1px solid ${colors.greyLine}`,
		display: 'inline-block',
		height: 27,
//		padding: 0,
		margin: '10px 20px 35px',
		width: 1
	},
	titleStyle : {
    color: colors.textColor,
    fontFamily: 'ProximaNovaBold',
    fontSize: 12,
    lineHeight: '1em',
    letterSpacing: '0.5px',
    marginBottom: '15px',
    padding: 0,
    textTransform: 'uppercase'
  }
}

export const summaryStyles = {
	summaryContainer: {
		marginBottom: 45,
	},
	summaryTitle: {
		marginBottom: 16
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		paddingBottom: 8,
		marginBottom: 10,
		borderBottom: '1px solid '+ colors.greyLine,
	},
	lastRow: {
		display: 'flex',
		alignItems: 'center',
		height: '22px'
	},
	labelColumn: {
		...textStyles.sectionHead
	},
	valueColumn: {
		fontSize: 21,
		lineHeight: '1em',
		whiteSpace: 'nowrap'
	},
	valueUnits: (bold) => ({
		fontSize: 14,
		lineHeight: '1em',
		color: colors.grey,
		fontFamily: bold ? fonts.bold.fontFamily : fonts.default.fontFamily,
	})
}
