import React from 'react';
import {Link} from 'react-router-dom';
import { configs } from '../../configs';
import { styles } from './styles';

export const LiveRaceHeader = ({liveEventURL, isMobile, isProfile, isMe, athlete, containerStyling, t}) => {
  const liveHeader = !isMobile && isProfile && !!athlete ?
    (<div style={styles.liveHeader.live}>
      {isMe ? t('You\'re racing today. Check out your event!'): t(
        '{{name}} is racing today. Check out this event!',
        {name: athlete.FName}
      )}
    </div>) : undefined;

  const isLink = window.location.href.indexOf('/results/') === -1;
  const banner =
    <div id="live-race" style={{...styles.liveHeader.link}}>
      <img src={`${configs.cdnUrl}/images/live-result-animation.gif`} alt={''} height="25" width="25"/>
      {liveHeader}
      {
        (isMobile || !isProfile) &&
        <div style={styles.liveHeader.live}>{t('Race is today!')}</div>
      }
    </div>;

  const linkedBanner = isLink
    ? <Link to={liveEventURL}>
        {banner}
      </Link>
    : banner;

  return (
    <div style={{...containerStyling, ...styles.liveHeader.container}}>
      <div className='container'>
        {linkedBanner}
      </div>
    </div>
  );
};
