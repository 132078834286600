import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from '@eventops/athlinks-react-date-range';
import { withTranslation } from 'react-i18next';
import Style from 'style-it'
import { CalendarIcon } from '../icons';
import { styles } from './styles';
import { colors } from '../../../shared/styles';
import {
  checkDateInputValue,
  constructDateFromInputString,
  getDateInputString
} from '../../../utils/date';
import _ from 'lodash';

class DateEntryComponent extends React.Component {
  static propTypes = {
    calendarEnabled: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    invalidator: PropTypes.func,
    invalidOverride: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    calendarEnabled: true,
    invalidator: undefined,
    invalidOverride: false // TODO: this is hacky, invalidator should probably be more complex
  };

  state = {
    calendarOpen: false,
    date: undefined,
    dateString: undefined,
    invalidDate: false
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);

    const changeHandler = (dateString) => {
      this.setState({dateString});
    };

    let date = document.getElementById('dateInput');

    date.addEventListener('input', function(e) {
      const input = this.value;
      const resolvedInput = /\D\/$/.test(input) ? input.substr(0, input.length - 3) : input;
      const values = resolvedInput.split('/').map((v) => v.replace(/\D/g, ''));
      const month = values[0] ? checkDateInputValue(values[0], 12) : undefined;
      const day = values[1] ? checkDateInputValue(values[1], 31) : undefined;
      const output = _.compact([month, day, values[2]]).map((v, i) => v.length === 2 && i < 2 ? v + ' / ' : v);

      changeHandler(output.join('').substr(0, 14));
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    const { calendarOpen } = this.state;
    if(!calendarOpen ||
      this.calendarNode.contains(e.target) ||
      this.inputNode.contains(e.target) ||
      this.buttonNode.contains(e.target)) {
      return;
    }

    this.setState({
      calendarOpen: false
    });
  };

  callback(isInvalid, date) {
    const { callback } = this.props;
    isInvalid ? callback(undefined) : callback(date);
  }

  isInvalidDate = (date) => {
    const { invalidator } = this.props;
    return date && invalidator ? invalidator(date) : false;
  };

  inputChangeHandler = (dateString) => {
    const date = constructDateFromInputString(dateString);
    const isInvalid = this.isInvalidDate(date);

    this.setState({
      date,
      invalidDate: isInvalid
    });

    this.callback(isInvalid, date);
  };

  calendarChangeHandler = (date) => {
    const isInvalid = this.isInvalidDate(date);

    this.setState({
      calendarOpen: false,
      date,
      dateString: getDateInputString(date),
      invalidDate: isInvalid
    });

    this.callback(isInvalid, date);
  };

  calendarClickHandler = (open) => {
    const { calendarEnabled } = this.props;

    if(calendarEnabled) {
      this.setState({
        calendarOpen: open
      });
    }
  };

  renderLabel() {
    const { t } = this.props;
    const _styles = styles.label;

    return (
      <div style={_styles.container}>
        <span style={_styles.style}>
          {t('Event Date')}
        </span>
        <span style={_styles.asterisk}>
          *
        </span>
      </div>
    );
  }

  renderInputBox(borderColor, calendarEnabled) {
    const { t } = this.props;
    const {
      date,
      dateString,
      invalidDate
    } = this.state;
    const _styles = styles.input;
    const color = invalidDate
      ? colors.error
      : date
        ? colors.black
        : colors.grey;
    const borderRadius = calendarEnabled ? '3px 0px 0px 3px' : '3px';
    const width = calendarEnabled ? '120px' : '100%';

    return (
      Style.it(_styles.pseudoStyles(),
      <div ref={(node) => this.inputNode = node} style={_styles.container(width)}>
        <input
          type='text'
          id='dateInput'
          className='dateEntryInput'
          style={_styles.style(color, borderColor, borderRadius, width)}
          onChange={(e) => this.inputChangeHandler(e.target.value)}
          value={dateString || ''}
          placeholder={t('MM/DD/YYYY')}
          autoComplete='off'
          tabIndex={0}
        />
      </div>
      )
    );
  }

  renderCalendarButton(borderColor) {
    const { calendarOpen } = this.state;
    const _styles = styles.button;
    const backgroundColor = calendarOpen ? colors.greyHover : colors.white;

    return (
      <div ref={(node) => this.buttonNode = node} style={_styles.container}>
        <Style>
          {
            _styles.hover()
          }
          <button
            onClick={() => this.calendarClickHandler(!calendarOpen)}
            className="hover-unit-button"
            style={_styles.style(backgroundColor, borderColor)}
            type="button"
            tabIndex={-1}
          >
            <CalendarIcon/>
          </button>
        </Style>
      </div>
    );
  }

  renderCalendar() {
    const {
      calendarOpen,
      date
    } = this.state;

    if(!calendarOpen) {
      return null;
    }

    const _styles = styles.calendar;

    return (
      <div ref={(node) => this.calendarNode = node} style={_styles.container}>
        <Calendar
          date={date}
          maxDate={new Date()}
          showDateDisplay={false}
          showPreview={false}
          onChange={(e) => this.calendarChangeHandler(e)}
        />
      </div>
    );
  }

  renderMessage() {
    const { invalidOverride, t } = this.props;
    const { invalidDate } = this.state;
    const _styles = styles.message;

    return (
      <div style={_styles.container}>
        <span style={_styles.style}>
          {
            invalidDate
              ? t('Only past results can be added')
              : invalidOverride
                ? t('A past date is required')
                :  ''
          }
        </span>
      </div>
    );
  }

  render() {
    const { calendarEnabled, invalidOverride } = this.props;
    const { invalidDate } = this.state;
    const borderColor = invalidDate || invalidOverride ? colors.error : colors.greyLine;

    return (
      <div>
        {this.renderLabel()}
        <div>
          {this.renderInputBox(borderColor, calendarEnabled)}
          {calendarEnabled && this.renderCalendarButton(borderColor)}
          {this.renderMessage()}
        </div>
        {this.renderCalendar()}
      </div>
    );
  }
}

export const DateEntry = withTranslation()(DateEntryComponent);
