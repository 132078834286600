import { AddEventSuccess, SuccessFooter } from './AddEventSuccess';
import { AddEventWizard, WizardFooter, WizardHeader } from './AddEventWizard'
import { ClaimEntryConfirm, ClaimEntryConfirmFooter, ClaimEntryConfirmHeader } from './arp/claimEntry/ClaimEntryConfirm'
import { ClaimEntryPending, ClaimEntryPendingFooter } from './arp/claimEntry/ClaimEntryPending'
import { ClaimEntrySuccess, ClaimEntrySuccessFooter } from './arp/claimEntry/ClaimEntrySuccess'
import { ClaimEntryFailure, ClaimEntryFailureFooter } from './arp/claimEntry/ClaimEntryFailure'
import { ClaimModalSpinnerHeader } from './arp/claimEntry/ClaimModalSpinner'
import { 
  UnfollowAthleteConfirmFooter, 
  UnfollowAthleteConfirmHeader 
} from './arp/unfollowAthlete/UnfollowAthleteConfirm'
import {
  UnfollowAthleteSuccess,
  UnfollowAthleteSuccessFooter
} from './arp/unfollowAthlete/UnfollowAthleteSuccess'
import {
  UnfollowAthleteFailure,
  UnfollowAthleteFailureFooter
} from './arp/unfollowAthlete/UnfollowAthleteFailure'
import { UnfollowAthleteModalSpinnerHeader } from './arp/unfollowAthlete/UnfollowAthleteModalSpinner'
import { UnclaimModalSpinnerHeader } from './arp/unclaimEntry/UnclaimModalSpinner'
import { ModalSpinner } from './common/ModalSpinner'
import {
  UnclaimEntryConfirm,
  UnclaimEntryConfirmFooter,
  UnclaimEntryConfirmHeader
} from './arp/unclaimEntry/UnclaimEntryConfirm'
import {
  UnclaimEntryFailure,
  UnclaimEntryFailureFooter
} from './arp/unclaimEntry/UnclaimEntryFailure'
import {
  UnclaimEntrySuccess,
  UnclaimEntrySuccessFooter
} from './arp/unclaimEntry/UnclaimEntrySuccess'
import { ModalTypes } from '../../actions/types'

export const MODAL_TYPES = {
  [ModalTypes.ADD_EVENT_WIZARD]: {
    pages: [
      {
        body: AddEventWizard, 
        footer: WizardFooter,
        header: WizardHeader
      },
      {
        body: AddEventSuccess,
        footer: SuccessFooter
      }
    ]
  },
  [ModalTypes.CLAIM_ENTRY]: {
    pages: [
      {
        header: ClaimModalSpinnerHeader,
        body: ModalSpinner
      },
      {
        body: ClaimEntryConfirm,
        footer: ClaimEntryConfirmFooter,
        header: ClaimEntryConfirmHeader
      },
      {
        body: ClaimEntrySuccess,
        footer: ClaimEntrySuccessFooter
      },
      {
        body: ClaimEntryPending,
        footer: ClaimEntryPendingFooter
      },
      {
        body: ClaimEntryFailure,
        footer: ClaimEntryFailureFooter
      }
    ]
  },
  [ModalTypes.UNCLAIM_ENTRY]: {
    pages: [
      {
        body: UnclaimEntryConfirm,
        footer: UnclaimEntryConfirmFooter,
        header: UnclaimEntryConfirmHeader
      },
      {
        header: UnclaimModalSpinnerHeader,
        body: ModalSpinner
      },
      {
        body: UnclaimEntrySuccess,
        footer: UnclaimEntrySuccessFooter
      },
      {
        body: UnclaimEntryFailure,
        footer: UnclaimEntryFailureFooter
      }
    ]
  },
  [ModalTypes.UNFOLLOW_ATHLETE]: {
    pages: [
      {
        footer: UnfollowAthleteConfirmFooter,
        header: UnfollowAthleteConfirmHeader
      },
      {
        header: UnfollowAthleteModalSpinnerHeader,
        body: ModalSpinner
      },
      {
        body: UnfollowAthleteSuccess,
        footer: UnfollowAthleteSuccessFooter
      },
      {
        body: UnfollowAthleteFailure,
        footer: UnfollowAthleteFailureFooter
      }
    ]
  }
};
