import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import Dimensions from '../reactDimensions/ReactDimensions';
import MobileDetect from 'mobile-detect';
import { debounce } from 'lodash';

const PORTRAIT = 'portrait';
const LANDSCAPE = 'landscape';
const MOBILE_BREAK_POINT = 600;
const TABLET_BREAK_POINT = 1024;

class ResizingContainerComponent extends Component {

  _isMounted = false;
  get isMounted() {
    return this._isMounted;
  }
  set isMounted(value) {
    this._isMounted = value;
  }

  static providesProps = {
    isMobile: PropTypes.bool,
    isTablet:  PropTypes.bool,
    isLandscape:  PropTypes.bool,
    widthOffset: PropTypes.number,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number,
    pixelRatio: PropTypes.number
  }

  static propTypes = {
    mobileBreakPoint: PropTypes.number,
    tabletBreakPoint: PropTypes.number
  }
  
  static defaultProps = {
    mobileBreakPoint: MOBILE_BREAK_POINT,
    tabletBreakPoint: TABLET_BREAK_POINT
  }

  static isolateProps(x) {
    return Object.keys(ResizingContainer.providesProps).reduce((acc, cur) => {
      if (x && cur in x)
        return Object.assign({}, acc, x[cur]);
      return acc;
    }, {});
  }

  constructor(props) {
    super(props);
    this.isMounted = false
    this.state = {
      isMobile: false,
      isTablet: false,
      isLandscape: true,
      widthOffset: 0,
      windowWidth: 0,
      windowHeight: 0,
      pixelRatio: 1
    };

    this.mobileDetect = new MobileDetect(window.navigator.userAgent);

  }

  componentDidMount() {
    this.isMounted = true;
    this.update();
    if (window.addEventListener) {
      window.addEventListener('resize', this.onResize, false);
    }
    else if (window.attachEvent) {
      window.attachEvent('onresize', this.onResize);
    }
    else {
      window.onresize = this.onResize;
    }
  }

  componentWillUnmount() {
    this.isMounted = false
    if (window.removeEventListener) {
       window.removeEventListener('resize', this.onResize);
    }
    else if (window.detachEvent) {
      window.detachEvent('onresize', this.onResize);
    }
   }

  onResize = () => {
    this.update()
  }

  getDeviceOrientation() {
    // Special handling for mobile safari which doesn't rotate screen dimensions
    if ('orientation' in window) {
      return Math.abs(window.orientation) - 90 === 0 ? LANDSCAPE : PORTRAIT;
    }
    return window.screen.availWidth > window.screen.availHeight ? LANDSCAPE : PORTRAIT;
  }

  getDevicePixelRatio = function () {
    var ratio = 1;
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (window.screen.systemXDPI !== undefined 
        && window.screen.logicalXDPI !== undefined 
        && window.screen.systemXDPI > window.screen.logicalXDPI) {
      // Only allow for values > 1
      ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
    }
    else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    }
    return ratio;
  };

  update = debounce(() => {
    if (this.isMounted) {
      const {mobileBreakPoint, tabletBreakPoint} = this.props;
      const orientation = this.getDeviceOrientation();
      const isLandscape = orientation === LANDSCAPE;
      const isMobile = window.innerWidth <= mobileBreakPoint || !!this.mobileDetect.phone();
      const isTablet = (!isMobile && window.innerWidth <= tabletBreakPoint) || !!this.mobileDetect.tablet();
      const pixelRatio = this.getDevicePixelRatio();

      this.setState({
        widthOffset: window.innerWidth < 800 ? 0 : 100,
        isMobile,
        isTablet,
        isLandscape,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        pixelRatio
      });
    }
  }, 100)

  render() {
    const children = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children]
    return (
      <div>
        {
          Children.map(children, (child, idx) => React.cloneElement(child, {
              key: idx,
              widthOffset: this.state.widthOffset,
              isMobile: this.state.isMobile,
              isTablet: this.state.isTablet,
              isLandscape: this.state.isLandscape,
              containerWidth: this.props.containerWidth,
              containerHeight: this.props.containerHeight,
              windowWidth: this.state.windowWidth,
              windowHeight: this.state.windowHeight,
              pixelRatio: this.state.pixelRatio
            }))
        }
      </div>
    );
  }
}

export const ResizingContainer = Dimensions()(ResizingContainerComponent)