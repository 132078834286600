
import {
  eventBus,
  streamHook
} from './Streams';
import { take } from 'rxjs/operators'

const currentLocationStream = 'current-geo-location';

export function detectLocation(geolocation, googleApiKey) {
  if (!geolocation) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {

    getCurrentLocationStream()
      .pipe(take(1))
      .subscribe((result) => {

        const streamId = currentLocationStream;

        //Already got a result, don't fetch
        if (result.placeID) {
          resolve(result);
        }

        if (!result.placeID) {
          //Don't have a result yet, fetch it

          geolocation.getCurrentPosition(
            (position) => {
              reverseGeoCodeCity(
                position.coords.latitude,
                position.coords.longitude,
                googleApiKey)
                .then((result) => {

                  //cache latest on the bus
                  eventBus.publish(streamId, result);

                  resolve(result);
                });
            },
            (error) => {
              reject(error.message || error)
            },
            {enableHighAccuracy: true, timeout: 20000, maximumAge: 1000}
          );
        }
      });
  });
}

function getCurrentLocationStream() {
  return streamHook(currentLocationStream, {});
}

function findCityLocationInResults(responseJSON) {
  const cityLocationTypes =['locality', 'administrative_area_level_3'];
  for (let i = 0; i < responseJSON.results.length; i++) {
    for (let j = 0; j < cityLocationTypes.length; j++) {
      if (responseJSON.results[i].types.indexOf(cityLocationTypes[j]) !== -1) {
        const res = responseJSON.results[i];
        return {
          label: res.description || res.formatted_address || res.name,
          latitude: res.geometry.location.lat,
          longitude: res.geometry.location.lng,
          placeID: res.place_id
        }
      }
    }
  }
  return null;
}

function reverseGeoCodeCity(latitude, longitude, googleApiKey) {
  const query = {
    key: googleApiKey,
    language: 'en', // language of the results
    types: '(cities)', // available options for GoogleReverseGeocoding API : https://developers.google.com/maps/documentation/geocoding/intro,
    latlng: latitude + ',' + longitude
  };

  const queryStr = Object.keys(query)
    .map((key) => encodeURI(`${key}=${query[key]}`))
    .join('&');

  const url = 'https://maps.googleapis.com/maps/api/geocode/json?' + queryStr;

  return fetch(url)
    .then((res) => res.json())
    .then((json) => findCityLocationInResults(json));
}


//Doesn't work due to missing access-control-allow-origin in google API response
//Have to use Google Places client-side lib - see components/geosuggest/FetchGeoSuggest.js
//
// export function autocomplete(term, googleApiKey) {
//
//   const query = {
//     key: googleApiKey,
//     language: 'en',
//     types: '(cities)'
//   };
//
//   const queryStr = Object.keys(query)
//     .map((key) => encodeURI(`${key}=${query[key]}`))
//     .join('&');
//
//   const url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input='
//     + encodeURIComponent(term)
//     + '&' + queryStr;
//
//   return fetch(url)
//     .then(res => res.json())
//     .then(json => json)
//     .catch(error => {
//       alert('Error In Autocomplete: '+JSON.stringify(error));
//     });
//  }
