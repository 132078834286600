import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import CloseIcon from 'react-icons/lib/fa/close';
import LeftIcon from 'react-icons/lib/fa/angle-left';
import RightIcon from 'react-icons/lib/fa/angle-right';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';

import { Button } from '../shared/Button'
import { colors, fonts, textStyles } from '../../shared/styles';
import { configs } from '../../configs';
import { inIframe } from '../../utils/inIframe';
import { query } from '../../utils/auth';
import { trackGoogleEvent } from '../../utils/googleEvents';

const slides = [
  {
    titleText: 'Finisher Certificates',
    subTitleText: 'Claim your race results',
    img: `${configs.cdnUrl}/images/sign-up1.png`
  },
  {
    titleText: 'Rivals',
    subTitleText: 'Compete with friends',
    img: `${configs.cdnUrl}/images/sign-up2.png`
  },
  {
    titleText: 'Rosters',
    subTitleText: 'View the official roster',
    img: `${configs.cdnUrl}/images/sign-up3.png`
  }
];

const containerStyles = ({isMobile}) => ({
  height: isMobile ? document.documentElement.clientHeight : 588,
  minHeight: isMobile ? document.documentElement.clientHeight : 588,
  width: isMobile ? document.documentElement.clientWidth : 717,
  minWidth: isMobile ? document.documentElement.clientWidth : 717,
  padding: 0,
  backgroundColor: '#141723',
  overflow: 'hidden',
})

const useStyles = makeStyles({
  container: containerStyles,
  labelStyle: {
    textTransform: 'capitalize',
    fontSize: 16,
    fontFamily: 'ProximaNovaRegular'
  },
  actionsStyle: ({isMobile}) => ({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    [isMobile?'bottom':'top']: isMobile ? 32 : 528,
    whiteSpace: 'nowrap'
  }),
  signInButton: {
    ...textStyles.sectionHead,
    backgroundColor: 'rgba(255,255,255,0)',
    border: '1px solid #F1F1F1',
    color: '#F1F1F1',
    fontSize: 14,
    fontWeight: 'bold',
    height: 41,
    margin: '0px 10px',
    minWidth: Math.min(183, document.documentElement.clientWidth / 2 - 64),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  signUpButton: {
    ...textStyles.sectionHead,
    backgroundColor: colors.blue,
    border: '1px solid ' + colors.blue,
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    height: 41,
    margin: '0px 10px',
    minWidth: Math.min(183, document.documentElement.clientWidth / 2 - 64),
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  closeButton: ({isMobile}) => (isMobile ? {
    color: 'rgba(255,255,255,0.8)',
    transition: 'color 1s',
    ':hover': {
      color: 'rgba(255,255,255,0.3)',
    },
  } : {
    color: 'rgba(255,255,255,0.8)',
  }),
});

const slideStyles = makeStyles({
  titleText: ({isMobile}) => ({
    position: 'relative',
    textAlign: 'center',
    ...textStyles.sectionHead,
    fontSize: 18,
    color: '#16A9E1',
    fontStyle: 'italic',
    fontWeight: 'bold',
    lineHeight: '22px',
    top: isMobile ? 32 : 10,
  }),
  subTitleText: ({isMobile}) => ({
    ...textStyles.sectionHead,
    ...fonts.boldItalic,
    fontSize: '38px',
    color: '#FFFFFF',
    letterSpacing: -0.5,
    textAlign: 'center',
    lineHeight: '36px',
    position: 'relative',
    top: isMobile ? 32 : 10,
  }),
  slide: ({index, isMobile}) => ({
    ...containerStyles({isMobile}),
    backgroundImage: `url("${slides[index].img}")`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',

  })
})

const useNavButtonStyles = makeStyles({
  leftStyle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    zIndex: 10,
    left: -14,
  },
  rightStyle: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    zIndex: 10,
    right: -14,
  },
  navButton: ({isMobile, isBoundarySlide}) => (isMobile ? {
    color: 'rgba(255,255,255,0.8)',
    transition: 'color 1s',
    display: !isBoundarySlide ? 'block' : 'none',
    ':hover': {
      color: 'rgba(255,255,255,0.3)',
    },
  } : {
    display: !isBoundarySlide ? 'block' : 'none',
    color: 'rgba(255,255,255,0.8)',
  })
})

const SigupSlide = ({titleText, subTitleText, index, t, isMobile}) => {
  const styles = slideStyles({index, isMobile})
  return (
    <div key={index} name={'login-or-sign-up-slide-' + index}>
      <div className={styles.slide}>
        <div className={styles.titleText}>
          {t(titleText)}
        </div>
        <div className={styles.subTitleText}>
          {t(subTitleText)}
        </div>
      </div>
    </div>
  )
}

const LeftArrow = ({currentSlide, slideCount, isMobile = false, ...props}) => {
  const styles = useNavButtonStyles({isMobile, isBoundarySlide: currentSlide === 0})
  return (
    <div className={`${styles.navButton} ${styles.leftStyle}`}>
      <LeftIcon {...props} size={78} />
    </div>
  )
}

const RightArrow = ({currentSlide, slideCount, isMobile = false, ...props}) => {
  const styles = useNavButtonStyles({isMobile, isBoundarySlide: currentSlide === slideCount - 1})
  return (
    <div className={`${styles.navButton} ${styles.rightStyle}`}>
      <RightIcon {...props} size={78} />
    </div>
  )
}

const LogInOrSignUpIndependentComponent = ({t, closeAction = () => {}, isMobile = false, open = false}) => {
  const styles = useStyles({isMobile})

  const handleRequestClose = () => {
    closeAction()
  }

  const handleRequestSignIn = () => {
    handleRequestClose();

    if (inIframe()) {
      window.open(`${configs.bragiUrl}/signin?${query()}`, 'signin');
    }
    else {
      document.location.href = `${configs.bragiUrl}/signin?${query()}`;
    }

    trackGoogleEvent('Authentication', 'Click', 'Modal Sign In');
  }

  const handleRequestSignUp = () => {
    handleRequestClose();

    if (inIframe()) {
      window.open(`${configs.bragiUrl}/signup?${query()}`, 'signup');
    }
    else {
      document.location.href = `${configs.bragiUrl}/signup?${query()}`;
    }

    trackGoogleEvent('Authentication', 'Click', 'Modal Sign Up');
  }

  const settings = {
    dots: true,
    dotsClass: 'LogInOrSignUpIndependent-slick-dots',
    infinite: false,
    speed: 500,
    arrows: true,
    nextArrow: <RightArrow isMobile={isMobile} />,
    prevArrow: <LeftArrow isMobile={isMobile} />,
    swipe: isMobile,
  };

  const standardActions = (
    <div className={styles.actionsStyle}>
      <Button
        id='sign-in-modal-button'
        onClick={handleRequestSignIn}
        className={styles.signInButton}
        text={'Sign In'}
      />
      <Button
        id='join-modal-button'
        onClick={handleRequestSignUp}
        className={styles.signUpButton}
        text={'Join'}
      />
    </div>
  )

  return (
    <Dialog
      fullScreen={isMobile}
      maxWidth={false}
      onClose={handleRequestClose}
      open={open}
    >
    {open && (<div className={styles.container}>
      <Slider {...settings} className={styles.container}>
        { slides.map((x, index) => <SigupSlide key={'sign-up-slide-' + index}
          {... x}
          index={index}
          isMobile={isMobile}
          t={t} />) }
      </Slider>
      {standardActions}
      <div style={{
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer',
      }}
      className={styles.closeButton}
      onClick={handleRequestClose}>
        <CloseIcon size={24} />
      </div>
    </div>)}
    </Dialog>
  );
}

export const LogInOrSignUpIndependent = withTranslation()(LogInOrSignUpIndependentComponent);
