import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import styles from './styles';

function isNodeInRoot(node, root) {
  while (node) {
    if (node === root) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

/**
 * Lightbox component for use within the MediaGrid component.
 */
export class MediaGridLightbox extends Component {
  static propTypes = {
    tile: PropTypes.object,
    closePortal: PropTypes.func,
    isMobile: PropTypes.bool,
    isTablet: PropTypes.bool,
    isLandscape: PropTypes.bool,
    media: PropTypes.array,
    prerollMedia: PropTypes.object,
    prerollAudioUrl: PropTypes.string,
    actionIcon: PropTypes.object,
    actionCallback: PropTypes.func,
    facebookShareIcon: PropTypes.func,
    facebookShareCallback: PropTypes.func,
    closeActionStyling: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      tile: props.tile,
      prerollShown: false,
    }
    // State is too slow
    this.isInDragEvent = false;
    this.supposedCurrentTime = 0;
    this.handleMouseClickOutside = this.handleMouseClickOutside.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.seekTime = this.seekTime.bind(this);
    this.prevImage = this.prevImage.bind(this);
    this.nextImage = this.nextImage.bind(this);
    this.close = this.close.bind(this);
    this.hotkeyHandler = this.handleHotkey.bind(this);
    this.actionCallbackHandler = this.actionCallbackHandler.bind(this);
    this.facebookShareCallbackHandler = this.facebookShareCallbackHandler.bind(this);
    this.prerollEnded = this.prerollEnded.bind(this);
    this.renderPreroll = this.renderPreroll.bind(this);
    this.prerollSeeking = this.prerollSeeking.bind(this);
    this.prerollTimeUpdate = this.prerollTimeUpdate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseClickOutside);
    document.addEventListener('touchstart', this.handleTouchStart);
    window.addEventListener('keydown', this.hotkeyHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseClickOutside);
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchend', this.handleTouchEnd);
    document.removeEventListener('touchmove', this.handleTouchMove);
    window.removeEventListener('keydown', this.hotkeyHandler);
  }

  handleTouchStart(e) {
    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
  }

  handleTouchMove(e) {
    this.isInDragEvent = true;
  }

  handleTouchEnd(e) {
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
    this.handleMouseClickOutside(e);
    this.isInDragEvent = false;
  }

  handleMouseClickOutside(e) {
    if (isNodeInRoot(e.target, findDOMNode(this.refs.content))) {
      return;
    }
    else if (!this.isInDragEvent) {
      e.stopImmediatePropagation();
      e.preventDefault();
      this.close();
    }
  }

  handleHotkey(e) {
    switch (e.code) {
      case 'ArrowUp':
      case 'ArrowRight':
        e.preventDefault();
        e.stopPropagation();
        e.shiftKey ? this.selectImageAtIndex(this.props.media.length - 1) :
                     this.nextImage();
        break;
      case 'ArrowDown':
      case 'ArrowLeft':
        e.preventDefault();
        e.stopPropagation();
        e.shiftKey ? this.selectImageAtIndex(0) :
                     this.prevImage();
        break;
      default:
        break;
    }
  }

  actionCallbackHandler() {
    if (this.props.actionCallback) {
      this.props.actionCallback(this.state.tile);
    }
  }

  facebookShareCallbackHandler() {
    if (this.props.facebookShareCallback) {
      this.props.facebookShareCallback(this.state.tile);
    }
  }

  close() {
    this.props.closePortal();
  }

  seekTime() {
    this.refs.videoTile.currentTime = this.state.tile.offsetTime || 0;
  }

  nextImage() {
    const idx = this.props.media.indexOf(this.state.tile) + 1;
    this.selectImageAtIndex(idx);
  }

  prevImage() {
    const idx = this.props.media.indexOf(this.state.tile) - 1;
    this.selectImageAtIndex(idx);
  }

  selectImageAtIndex(idx) {
    if (idx < 0) {
      this.setState({
        tile: this.props.media[this.props.media.length - 1]
      })
    }
    else if (idx >= this.props.media.length) {
      this.setState({
        tile: this.props.media[0]
      })
    }
    else {
      this.setState({
        tile: this.props.media[idx]
      })
    }
    setTimeout(function() {
      this.refs.selectedTile &&
        this.refs.selectedTile.scrollIntoView()
    }.bind(this), 16);
  }

  renderThumbs() {
    const click = (ix) => () => this.selectImageAtIndex(ix);
    return (
      <div>
        {this.props.media.map((x, idx) => {
          const borderWidth = idx === this.props.media.indexOf(this.state.tile) ? 5 : 1;
          const ref = idx === this.props.media.indexOf(this.state.tile) ? 'selectedTile' : `thumb-${idx}`;
          const style = Object.assign({}, styles.lightbox.thumb, {
                  backgroundImage: `url('${x.poster}')`,
                  borderWidth
                });
          return (
            <div key={'thumb-' + idx} ref={ref} style={style}
              onClick={click(idx)} />
          )
        })}
      </div>
    )
  }

  hasPrerollMedia(preroll) { return !!Object.keys(preroll).length; }

  prerollEnded() {
    this.supposedCurrentTime = 0;
    this.setState({ prerollShown: true })
  }

  prerollTimeUpdate(e) {
    const video = e.target;
    if (!video.seeking) {
      this.supposedCurrentTime = video.currentTime;
    }
  }

  prerollSeeking(e) {
    const video = e.target;
    const delta = video.currentTime - this.supposedCurrentTime;
    if (Math.abs(delta) > 0.01) {
      video.currentTime = this.supposedCurrentTime;
    }
  }

  volumeChange = (e) => {
    const video = e.target;
    //Force a ctlive flag muted video to stay muted
    if(this.state.tile && this.state.tile.isMuted) {
      video.volume = 0;
    }
  }

  renderPreroll(prerollMedia, isMobile = false) {
    const videoStyle = isMobile ? styles.mobileLightbox.video : styles.lightbox.video;
    const { prerollAudioUrl } = this.props;

    if(prerollMedia.provider === 'video') {
      return(<video ref='videoPreroll' autoPlay controls
                  poster={this.state.tile.poster}
                  preload='metadata' style={videoStyle}
                  src={prerollMedia.file}
                  onEnded={this.prerollEnded}
                  onSeeking={this.prerollSeeking}
                  onTimeUpdate={this.prerollTimeUpdate}
                />)
    } else {
      // preroll audio
      return(<video ref='audioPreroll' autoPlay controls
                  poster={prerollMedia.file}
                  preload='metadata' style={videoStyle}
                  src={prerollAudioUrl}
                  onEnded={this.prerollEnded}
                  onSeeking={this.prerollSeeking}
                  onTimeUpdate={this.prerollTimeUpdate}
                />)
    }
  }

  renderActionIcon() {
    if (this.props.actionCallback) {
      const icon = {
      	clazz: this.props.actionIcon
      }

      if (this.props.actionIcon) {
        return (
          <div style={styles.lightbox.actionButtonContainer}>
            <IconButton style={styles.lightbox.actionButton} onClick={this.actionCallbackHandler}>
              <icon.clazz htmlColor='white' />
            </IconButton>
          </div>
        )
      }
    }

    return null;
  }

  renderFacebookShareIcon() {
    if (this.props.facebookShareCallback) {
      if (this.props.facebookShareIcon) {
        return (
          <div style={Object.assign({}, styles.lightbox.actionButtonContainer, {right: 72})}>
            <IconButton style={styles.lightbox.actionButton} onClick={this.facebookShareCallbackHandler}>
              <this.props.facebookShareIcon />
            </IconButton>
          </div>
        )
      }
    }

    return null;
  }

  renderNormal() {
    const { prerollMedia } = this.props;
    const { prerollShown } = this.state;
    return (
      <div style={styles.lightbox.shield}>

        <div ref='content' style={styles.lightbox.container}>

          <div style={styles.lightbox.leftScrollButtonContainer}>
            <IconButton onClick={this.prevImage} style={styles.lightbox.leftScrollButton}>
              <ChevronLeftIcon style={{fontSize: 60}} htmlColor='white' />
            </IconButton>
          </div>

          <div style={styles.lightbox.label}>
            {this.renderFacebookShareIcon()}
            {this.renderActionIcon()}
            <div>{this.state.tile.title}</div>
          </div>
          {this.state.tile.type === 'photo' && (
            <div style={styles.lightbox.imageContainer}>
              <div style={Object.assign({}, styles.lightbox.image, {
                  backgroundImage: `url('${this.state.tile.url}')`
                })}>
              </div>
            </div>
          )}
          {this.state.tile.type === 'video' && (
            <div style={styles.lightbox.videoContainer}>
              {
                this.hasPrerollMedia(prerollMedia) && !prerollShown ?
                this.renderPreroll(prerollMedia) :
                <video ref='videoTile'
                  controls autoPlay muted={this.state.tile.isMuted}
                  onVolumeChange={this.volumeChange}
                  poster={this.state.tile.poster} onLoadedMetadata={this.seekTime}
                  src={this.state.tile.url}
                  preload='metadata' style={styles.lightbox.video}
                />
              }
            </div>
          )}
          <div style={styles.lightbox.rightScrollButtonContainer}>
            <IconButton
              onClick={this.close}
              style={{...styles.lightbox.closeButton, ...this.props.closeActionStyling}}
              iconStyle={styles.lightbox.closeButtonIcon}
            >
              <CloseIcon htmlColor='white' />
            </IconButton>
            <IconButton onClick={this.nextImage} style={styles.lightbox.rightScrollButton}>
              <ChevronRightIcon style={{fontSize: 60}} htmlColor='white'/>
            </IconButton>
          </div>
          <div style={styles.lightbox.thumbContainer}>
            {this.renderThumbs()}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.tile) {
      if (this.props.isMobile) return this.renderMobile();
      return this.renderNormal();
    }
    return null;
  }

  renderMobileVideo(tile) {
    const { prerollMedia } = this.props;
    const { prerollShown } = this.state;
    return (
      <div>
        {
          this.hasPrerollMedia(prerollMedia) && !prerollShown ?
          this.renderPreroll(prerollMedia, true) :
            <video ref='videoTile' controls muted={tile.isMuted}
              onVolumeChange={this.volumeChange}
              poster={tile.poster} onLoadedMetadata={this.seekTime}
              src={tile.url} preload='metadata'
              style={styles.mobileLightbox.video}
            />
        }
      </div>
    );
  }

  renderMobilePhoto(tile) {
    return (
      <div style={Object.assign({}, styles.mobileLightbox.image, {
          backgroundImage: `url('${tile.url}')`
        })}/>
    )
  }

  renderMobile() {
    const current = this.state.tile.type === 'video' ?
                this.renderMobileVideo(this.state.tile) :
                this.renderMobilePhoto(this.state.tile)
    return (
      <div style={styles.lightbox.shield}>
        <div style={styles.mobileLightbox.image}
          ref='content'>

          {current};

          <div style={styles.mobileLightbox.leftScrollButton}>
            <IconButton onClick={this.prevImage} style={styles.mobileLightbox.leftScrollButtonIcon}>
              <ChevronLeftIcon style={{fontSize: 60}} htmlColor='white' />
            </IconButton>
          </div>

          <div style={styles.mobileLightbox.label}>
            {this.props.media.indexOf(this.state.tile) + 1}/{this.props.media.length}
          </div>

          <div style={styles.mobileLightbox.closeButton}>
            <IconButton onClick={this.close} style={styles.mobileLightbox.closeButtonIcon}>
              <CloseIcon htmlColor='white' />
            </IconButton>
          </div>
          <div style={styles.mobileLightbox.rightScrollButton}>
            <IconButton onClick={this.nextImage} style={styles.mobileLightbox.rightScrollButtonIcon}>
              <ChevronRightIcon style={{fontSize: 60}} htmlColor='white'/>
            </IconButton>
          </div>

        </div>
      </div>
     )
  }
}
