import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {configs} from '../../configs';
import { trackGoogleEvent } from '../../utils/googleEvents';

import { useStyles } from './styles';

/**
 * Container for sponsor logos
 */
export const Sponsors = ({ logos, enableTitleLogos }) => {
  const { t } = useTranslation()
  const styles = useStyles();

  const sortedLogos = useMemo(() => (
    logos.sort(({display_order: do1}, {display_order: do2}) => do1 - do2)
  ), [logos])

  if (!logos.length) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.title} id="sponsors">
        {t('Sponsors')}
      </div>

      <div className={styles.interiorContainer}>
        {sortedLogos.map((sponsor, i) => (
          <Sponsor
            id={`sponsor-${i}`}
            key={`sponsor-${sponsor.id}-${i}`}
            sponsor={sponsor}
            isTitleSponsor={enableTitleLogos && sponsor.is_title_sponsor}
            style={{margin: 7, cursor: !!sponsor.url ? 'pointer' : 'auto'}}
          />
        ))}
      </div>
    </div>
  )
}

Sponsors.propTypes = {
  logos: PropTypes.array.isRequired,
  enableTitleLogos: PropTypes.bool,
};

Sponsors.defaultProps = {
  enableTitleLogos: false,
};

/**
 * Rendering for sponsor logo. Wraps it up in Link if there is url associated
 * to the sponsor logo
 */
export const Sponsor = ({id, sponsor, isTitleSponsor, style}) => {

  /**
   * Builds sponsor url. Adds http if protocol is not defined in CTLive
   * @return {string}
   */
  const getSponsorUrl = () => {
    const {url} = sponsor;
    if(!url) return url;
    else if(url.slice(0, 4) === 'http') return url;
    else return `http://${url}`;
  };

  /**
   * Determine img dimension based on title sponsor flag
   * @return {{width: number, height: number}}
   */
  const dimensions = () => isTitleSponsor ? {width: 300, height: 250} : {width: 143, height: 121};

  const {customHeroImageUrl} = configs;
  const sponsorUrl = getSponsorUrl();
  const wrapInLink = !!sponsorUrl;
  const {width, height} = dimensions();
  const img =
    <img
      src={customHeroImageUrl + sponsor.path.slice(1)}
      style={style}
      width={width}
      height={height}
      alt={sponsor.name}
    />;

  if (!wrapInLink) {
    return img;
  }
  else {
    return (
      <a
        href={sponsorUrl}
        target="_blank"
        rel="noopener noreferrer"
        id={id}
        onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Event Sponsor')}
      >
        {img}
      </a>
    );
  }
};

Sponsor.propTypes = {
  sponsor: PropTypes.object.isRequired,
  style: PropTypes.object,
  isTitleSponsor: PropTypes.bool.isRequired
};

Sponsor.defaultProps = {
  style: {}
};
