import {
  fonts,
  textStyles,
  colors,
  loadingDark,
  loadingLight,
  transition
} from '../../shared/styles';

export const styles = {
  ...textStyles,
  totalResults: {
    color: colors.darkGrey3,
    fontSize: '15px',
  },
  searchTerm: {
    ...textStyles.captionGrey,
    fontSize: '12.5px',
  },
  event: {
    padding: '10px',
    borderBottom: `1px solid ${colors.lightGrey4}`,
  },
  plusIcon: {
    paddingLeft: '10px',
    paddingRight: '20px'
  },
  comunication: {
    paddingRight: 10
  },
  checkboxFilter: {
    marginRight: '10px',
    display: 'none'
  },
  inputStyles: {
    width: '100%',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none'
  },
  raceName: {
    display: 'inline-block',
    lineHeight: '31px',
  },
  raceCat: {
    color: 'red',
    marginRight: '5px',
    marginLeft: '5px',
    border: '1px solid red',
  },
  mostRecent: {
    marginRight: '5px',
    marginLeft: '5px',
    fontSize: '17px',
    color: colors.grey,
  },
  // tbd: {
  //   marginRight: '5px',
  //   marginLeft: '5px',
  // },
  addYearLabel: {
    marginTop: '8px',
    color: colors.grey,
    fontSize: '14px'
  },
  nameItemField: {
    display: 'none'
  },
  nameItemLabel: {
    display: 'none'
  },
  dates: {
    margin: '10px',
  },
  datesItem: {
    margin: '5px',
  },
  locationItemField: {
    display: 'none'
  },
  locationItemLabel: {
    display: 'none'
  },
  running: {
    margin: 10,
  },
  runningItem: {
    display: 'inline-block',
    margin: 5,
    width: '45%',
  },
  triathalon: {
    margin: '10px',
  },
  triathalonItem: {
    display: 'inline-block',
    margin: 5,
    width: '45%'
  },
  more: {
    margin: 10
  },
  moreItem: {
    display: 'inline-block',
    margin: 5,
    width: '45%'
  },
  raceButton: {
    position: 'absolute',
    top: 0,
    right: 15,
    display: 'inline-block',
    float: 'right',
    padding: 5,
  },
  viewHeader: {
    borderBottom: `1px solid ${colors.lightGrey5}`,
    paddingBottom: 10,
  },
  eventContainer: {
    ...transition.fadeIn,
    position: 'relative',
    borderTop: '1px solid '+ colors.greyLine,
  },
  avgFinishersContainer: {
    marginBottom:5,
    float: 'left',
  },
  eventName: (isMobile) => ({
    fontFamily: 'ProximaNovaRegular',
    display: 'inline-block',
    marginBottom: 5,
    lineHeight: (isMobile) ? '16px' : '22px',
    marginTop: (isMobile) ? 15 : 0,
    fontSize: (isMobile) ? 16 : 22,
    letterSpacing: '0.36px',
    color: colors.textColor
  }),
  afterName: {
    color: colors.grey,
    fontFamily: 'ProximaNovaRegular',
    fontSize: 13
  },
  afterNameMob: {
    color: colors.grey,
    fontFamily: 'ProximaNovaRegular',
    fontSize: 14,
    borderBottom: `1px solid ${colors.lightGrey5}`
  },
  resultsLabel: {
    color: colors.grey,
    paddingRight: 6,
    fontSize: '15px',
    fontFamily: 'ProximaNovaRegular'
  },
  firstEventRow: {
    float: 'left',
    padding: '26px 0px 23px 0px',
    display: 'flex',
    alignItems: 'center'
  },
  detailsContainer: {
    color: colors.grey,
    fontSize: 14,
  },
  detailsPipe: {
    margin: '0 15px',
    fontSize: '18px',
    color: colors.greyLine
  },
  detailsAddYear: {
    ...fonts.bold,
    color: colors.grey,
  },

  secondRowContainer: {
    display: 'flex',

  },
  secondRowEventFirst: {
    paddingLeft: 0,
    paddingTop: 12,
  },
  secondRowEventMiddle: {
    paddingTop: 0,
    borderLeft: '1px solid '+ colors.greyLine,
  },
  secondRowLast: {
    paddingTop: 12,
    paddingBottom: 12,
    borderLeft: '1px solid '+ colors.greyLine,
    height: '80px',
    display: 'flex',
    alignItems: 'center'
  },

  eventInfo: {
    ...textStyles.body1Grey,
    color: colors.darkGrey,
    paddingLeft: 5,
  },
  eventInfoMob: {
    color: colors.textColor,
    fontSize: 13,
    paddingLeft: 5,
  },
  eventInfoEL: {
    ...textStyles.body1Grey,
    display: 'inline-block',
    minWidth: 60,
  },
  eventInfoELMob: {
    color: colors.grey,
    fontSize: 12,
    paddingLeft: 5,
    fontFamily: 'ProximaNovaRegular'
  },
  raceDateTitle: {
    ...textStyles.body1Grey,
    display: 'inline-block',
    flex: '0 0 60px',
  },
  raceDateContainer: {
    display: 'inline-block',
    flex: '1',
  },
  raceDate: {
    ...textStyles.body2Dark,
    display: 'inline-block',
    marginBottom: 4,
    textAlign: 'center',
    lineHeight: '12px',
    margin: '0px 20px',
    width: '37px',
    color: colors.lightBlue3,
  },
  rightArrow: {
    color: colors.greyLine,
    display: 'none',
    position: 'absolute',
    right: '0px',
    marginTop: '-17.5px',
    top: '50%'
  },
  rangeDiv: {
    paddingTop: 20,
    position: 'relative'
  },
  sliderLeftSide: {
    float: 'left',
    fontSize: 10,
    margin: -4
  },
  slideRightSide: {
    float: 'right',
    fontSize: 10,
    margin: -4,
    marginRight: -12
  },

  // Loading state
  loadingContainer: (canShow) => ({
    position: 'absolute',
    top: 90,
    left: 0,
    width: '100%',
    height: '100%',
    paddingLeft: 15,
    opacity: (canShow) ? 0.6 : 0,
    pointerEvents: 'none',
    transition: 'all 0.4s',
  }),
  loadingRow: {
    marginTop: 20,
    marginBottom: 44,
  },
  loadingTitle: {
    height: 22,
    width: '30%',
    minWidth: 150,
    ...loadingDark, // Animation
    marginBottom: 12,
  },
  loadingSubtitle: {
    height: 14,
    width: '35%',
    minWidth: 200,
    ...loadingLight, // Animation
    marginBottom: 12,
  },
  loadingDetailContainer: {
    display: 'flex',
    borderTop: '3px solid '+ colors.lightGrey2,
    borderBottom: '3px solid '+ colors.lightGrey2,
  },
  loadingDetailCellOne: {
    flex: '0 0 50%',
    padding: '15px 0 0',
    borderRight: '3px solid '+ colors.lightGrey2,
  },
  loadingDetailCellTwo: {
    flex: '0 0 50%',
    padding: '15px 20px 0',
  },
  loadingRowMobile: {
    marginBottom: 20,
  },
  loadingDetailContainerMobile: {
    display: 'flex',
    paddingBottom: 4,
    marginBottom: 4,
    borderBottom: '2px solid '+ colors.lightGrey2,
  },
  loadingCellOneMobile: {
    height: 18,
    width: '20%',
    minWidth: 40,
    ...loadingLight, // Animation
    marginRight: 10,
    borderRight: '2px solid '+ colors.lightGrey2,
  },
  loadingCellTwoMobile: {
    height: 18,
    width: '30%',
    minWidth: 100,
    ...loadingLight, // Animation
    marginRight: 10,
    borderRight: '2px solid '+ colors.lightGrey2,
  },
  loadingCellThreeMobile: {
    height: 18,
    width: '40%',
    minWidth: 140,
    ...loadingLight, // Animation
  },
  addYearContainer: {
    display: 'inline-block',
    margin: '0px 20px'
  },
  dateTimeContainer: {

  },
  dateTimeLink: {
    color: colors.grey
  },
  isTodayLabel: {
    display: 'inline-block',
    fontSize: 12,
    marginLeft: 5
  },
  userStyle: {
    marginTop: '-6px'
  },
  avgFinishersLabel: {
    paddingBottom: '10px'
  },
  avgFinishersMobContainer: {
    float: 'left',
  },
  searchPageHeader: {
    minHeight: '80vh'
  },
  pseudoStyles: () => `
    .event:hover {
      background: ${colors.lightGrey};
      cursor: pointer;
    }
    .event:hover .resultArrow {
      display: block !important;
    }
    .event:hover .event-url {
      text-decoration: underline;
      text-decoration-color: ${colors.greyLine};
      -webkit-text-decoration-color: ${colors.greyLine};
    }
    .year-link:hover {
      border-bottom: 1px solid ${colors.lightBlue3} !important;
    }
    .add-year-link:hover,
    .see-more-results:hover {
      border-bottom: 1px solid ${colors.lightBlue3} !important;
      margin-bottom: -1px !important;
    }
  `
};
