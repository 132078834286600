import { stateMap } from "../utils/stateMap"
import { iso2Toiso3Map } from "../utils/countryMap"

/**
 * getDisplayLocation
 * returns the location string to display based on a city, state and country
 * non-US countries will not display state information
 * US will not display country unless there is no city and no state
 * @param city
 * @param state
 * @param country
 */
export const getDisplayLocationString = (
  city, state, country
) => {
  const isUS = country === 'US' || country === 'USA'
  const stateStr = isUS ? `${stateMap[state] || state}` : ''
  const countryStr =
    (isUS && (city || state)) ? '' : iso2Toiso3Map[country] || country

  return [city, stateStr, countryStr].filter((s) => s).join(', ')
}
