import { Configure, FetchApi } from '../../../anuket-http';

export function callApi(path, method, headers, body) {
  const url = Configure.getValue('ATHLINKS_API_HOST') + `/${path}`;
  const req = {
    method: method || 'get',
    headers: Object.assign({}, headers || {}, {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + Configure.getConfigs().access_token,
      'Content-type': 'application/json'
    }),
  };
  const reqWithBody = body ? Object.assign(req, {body}) : req;
  const fetcher = req.method.toLowerCase() === 'get' ? FetchApi.memoizeFetch(10000) : FetchApi.doFetch
  return fetcher(url, reqWithBody);
}