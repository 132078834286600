import React from 'react';
import PropTypes from 'prop-types';

export const AthlinksClaimedFlame = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 22 22" {...props}>
    <defs>
      <linearGradient x1="5.08270264%" y1="22.7294922%" x2="103.292847%" y2="72.7172852%" id="linearGradient-1">
        <stop stopColor="#2EB4E7" offset="0%"></stop>
        <stop stopColor="#16A9E1" offset="32.027264%"></stop>
        <stop stopColor="#0092C9" offset="100%"></stop>
      </linearGradient>
      <circle id="path-2" cx="10" cy="10" r="10"></circle>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="List-View-claim-left" transform="translate(-1101.000000, -821.000000)">
        <g id="Group" transform="translate(1102.000000, 822.000000)">
          <g id="Oval">
            <use fill="url(#linearGradient-1)" fillRule="evenodd" xlinkHref="#path-2"></use>
            <circle stroke="#FFFFFF" strokeWidth="1" cx="10" cy="10" r="10.5"></circle>
          </g>
          <path d="M7.07949378,15.5595445 C6.39838573,14.9326207 5.70817349,13.8284607 6.59441056,12.2544141 C6.59441056,12.2544141 7.04080092,16.1043974 10.5729464,15.9978277 C14.1053765,15.8932179 15.9999038,13.5680387 15.9999038,10.8702331 C15.9999038,10.8702331 16.0181122,10.0767176 15.5535134,9.11661039 C15.5535134,9.11661039 15.624071,10.7558238 13.6968254,11.5956419 C13.6968254,11.5956419 17.1786133,7.45755301 12.8145714,4.69948036 C12.8145714,4.69948036 13.5104738,6.27818174 12.2586462,6.79731089 C12.2586462,6.79731089 11.9328865,3.21975406 7.33640295,3 C7.33640295,3 10.2847416,5.27936649 7.94040979,8.59772623 C7.94040979,8.59772623 8.14866839,6.19905069 5.47886142,6.29802576 C5.47886142,6.29802576 6.79242694,7.32868943 5.36306737,8.65701328 C3.59941291,10.2957367 3.93427681,12.3345251 4.26174354,12.9817829 C4.82848006,14.0950075 5.82994218,15.0053821 7.07949378,15.5595445" id="Flame-Copy-2" fill="#FFFFFF"></path>
        </g>
      </g>
    </g>
  </svg>
);

AthlinksClaimedFlame.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

AthlinksClaimedFlame.defaultProps = {
  width: '22px',
  height: '22px'
};
