import { makeStyles } from '@material-ui/core/styles';

import { fonts, loadingLight } from '../../shared/styles';

export const useStyles = makeStyles(({ palette, breakpoints }) => ({
  flexStyle: {
    display: 'grid',
    gap: '5px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    marginTop: '5px',

    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    }
  },
  link: {
    borderRadius: '4px',
    border: `1px solid ${palette.grey[400]}`,
    color: palette.grey[600],
    cursor: 'pointer',
    display: 'block',
    flex: 1,
    fontSize: '12px',
    letterSpacing: 0.4,
    lineHeight: '22px',
    padding: '3px 10px',
    transition: 'all 0.2s ease',
    textAlign: 'center',
    textTransform: 'uppercase',
    textWrap: 'nowrap',

    '&:hover': {
      borderColor: palette.common.black,
      color: palette.common.black,
    },

    [breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '24px',
    }
  },
  loadingPlaceholderItem: {
    ...loadingLight,
    border: `1px solid ${palette.grey[400]}`,
    borderRadius: '4px',
    height: 34,
    width: 118,
  },
  title: {
    color: palette.grey[800],
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
}));
