import _ from 'lodash'
import { colors } from '../../shared/styles';

// search results
const defaultSearchResultStyles = {
  headerPage: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    zIndex: 9, //Needs to be lower than AnimatedHeader zIndex (currently 10)
  },

  headerPageNotFound: {
    margin: '0 auto',
    position: 'relative',
    width: '80%',
    display: 'flex',
    zIndex: 9, //Needs to be lower than AnimatedHeader zIndex (currently 10)
  },

  headerPageMobile: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 0, //Needs to be lower than AnimatedHeader zIndex (currently 10)
  },

  searchHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '34px',
    fontFamily: 'ProximaNovaSemibold',
    textAlign: 'center',
  },

  searchContainer: {
    position: 'relative',

  },

  searchInput: {
    width: '100%',
    height: '45px',
    border: 'none',
    borderRadius: '0px 2px 2px 0px',
    fontSize: '16px',
    paddingLeft: '47px',
    boxSizing: 'border-box',
  },

  search_input_icon: {
    width: '20px',
    position: 'absolute',
    left: '13px',
    top: '12px',
  },


  searchInput_notFound: () => ({
    width: '100%',
    height: '35px',
    borderRadius: '2px',
    fontSize: '12px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
    border: `2px solid ${colors.blue}`
  }),

  search_input_icon_notFound: () => ({
    fill: colors.white,
    width: '15px',
    position: 'absolute',
    right: 0,
    top: 1,
    padding: 9,
    background: colors.blue
  }),

  locationInputContainer: {
    display: 'none',
  },

  SearchResults: {
    textAlign: 'center',
    marginTop: '25px',
  },

  allResults: (pushDown = false) => ({
    padding: 0,
    position: 'absolute',
    top: pushDown ? '46px': 0,
    zIndex: 9999,
    width: '100%',
    background: colors.white,
    boxShadow: `0px 8px 18px -5px ${colors.black}`,
    borderRadius: '0 0 2px 2px',
  }),
  searchTabWrapper: (notFound) => ({
    flex: '20%',
    borderRight: notFound ? '0px' : '1px solid #dddddd'
  }),
  searchInputWrapper: {
    position: 'relative',
    flex: '80%'
  }
};

const tabletSearchResultStyles = {
  searchHeaderTitle: {
    fontWeight: 'bold',
    fontSize: '34px',
    fontFamily: 'ProximaNovaSemibold',
    textAlign: 'center',
  },

  searchInput: {
    width: '100%',
  },

  search_input_icon: {
    bottom: '22px',
  },

  locationInputContainer: {
    display: 'inline-block',
  },

  locationInput: {
    width: '100%',
    height: '45px',
    marginTop: '20px',
    border: 'none',
    float: 'right',
    boxShadow: '1px 2px 10px rgba(136, 136, 136, 0.44)',
    borderRadius: 0,
    fontSize: '15px',
    paddingLeft: '30px',
    boxSizing: 'border-box',
  },

  SearchResults: {
    textAlign: 'center',
    marginTop: '25px',
  },
  allResults: (pushDown = false, onHome = false) => ({
    padding: 0,
    position: 'absolute',
    top: pushDown ? '95%' : 0,
    left: onHome ? '20%' : '0%',
    zIndex: 9999,
    width: onHome ? '80%' : '100%',
    background: colors.white,
    boxShadow: `0px 8px 18px -5px ${colors.black}`,
    borderRadius: '0 0 2px 2px',
  }),
};

export const styles = _.merge({}, defaultSearchResultStyles, tabletSearchResultStyles);
export const searchResults = ({isLandscape}) => {
  const _tabletStyles = isLandscape ? tabletSearchResultStyles : {};

  return _.merge({}, defaultSearchResultStyles, _tabletStyles)
};

