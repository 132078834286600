import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MasterEvent } from '../../classes/MasterEvent';
import { IndividualKioskContent } from './IndividualKioskContent';
import _ from 'lodash';
import { Loading } from '../../components/shared/Loading';
import { trackGoogleEvent } from '../../utils/googleEvents';

/**
 * Renders IRP view for Kiosk results
 * Will return to ERP after 20 seconds of inactivity (move, click, key press)
 */
export class IndividualKioskResultsComponent extends Component {
  static propTypes = {
    bib: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    cancelSelection: PropTypes.func.isRequired,
    isLightMode: PropTypes.bool,
    isLoading: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    masterEvent: PropTypes.instanceOf(MasterEvent).isRequired,
    result: PropTypes.object,
    t: PropTypes.func
  };

  state = {
    secondsInactive: 0
  };

  componentDidMount() {
    const {
      bib,
      eventId,
      eventCourseId,
    } = this.props;

    trackGoogleEvent('Kiosk', 'Visit', `${eventId}-${eventCourseId}-${bib}`);

    if (this.props.mode !== 'dev') {
      this.interval = this.startInactivityTimer();
    }

    this.addEventListeners();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.removeEventListeners();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.secondsInactive !== 0
      && this.state.secondsInactive === nextState.secondsInactive)
      || !_.isEqual(this.props, nextProps);
  }

  /**
   * Starts inactivity timer
   * @return {number}
   */
  startInactivityTimer = () => setInterval(this.increaseInactivity, 1000);

  /**
   * Increase inactivity timer by one and check if view timeouts
   * @return {void|*}
   */
  increaseInactivity = () => this.setState({
    secondsInactive: this.state.secondsInactive + 1
  }, this.onInactive);

  /**
   * If inactive period is reached then we will force cancel result selection
   */
  onInactive = () => {
    const { secondsInactive } = this.state;
    if (secondsInactive >= 20) {
      const { cancelSelection } = this.props;
      cancelSelection();
    }
  };

  trackKeyDown = (e) => {
    if (e.key === 'Backspace') {
      this.resetInactivity();
      this.props.cancelSelection();
    }
  };

  /**
   * Resets inactivity. Called on mouse move, key press and mouse click
   * @return {void|*}
   */
  resetInactivity = () => this.setState({ secondsInactive: 0 });

  /**
   * Adding inactivity listeners
   */
  addEventListeners = () => {
    window.onkeypress = this.resetInactivity;
    window.onclick = this.resetInactivity;
    window.onmousemove = this.resetInactivity;
    window.onkeydown = this.trackKeyDown;
  };

  /**
   * Removing inactivity listeners
   */
  removeEventListeners = () => {
    window.onkeypress = null;
    window.onclick = null;
    window.onmousemove = null;
    window.onkeydown = null;
  };

  render() {
    const {
      cancelSelection,
      eventCourseName,
      eventDate,
      eventMetadata,
      isLightMode,
      isLoading,
      isMobile,
      masterEvent,
      result,
      t,
    } = this.props;

    if (isLoading)
      return <Loading centered={true} />;

    return (
      <section id='sectionId'>
        <IndividualKioskContent
          eventCourseName={eventCourseName}
          eventDate={eventDate}
          eventMetadata={eventMetadata}
          isLightMode={isLightMode}
          isLoading={isLoading}
          isMobile={isMobile}
          masterEvent={masterEvent}
          onBack={cancelSelection}
          result={result}
          t={t} />
      </section>
    );
  }
}
