import React from 'react';
import {connectStream} from '../../../lib/bastetjs/utils/connectStream';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {of as ObservableOf} from 'rxjs';
import {isLoggedIn} from '../../../utils/isLoggedIn';
import {getResultsUrl} from '../../../utils/resultsHelpers';
import {profileImageUrl} from '../../../utils/profileImageUrl';
import {AthletePhoto} from '../../athlete/AthletePhoto';
import {getMyFriendsStartList} from '../../../data/FriendsStreams';
import {styles} from './styles';
import {isInPast} from '../../../utils/date';

const FriendsRacingComponent = (
  {
    event,
    starters = [],
    sideMargin,
    type,
    styleFriendsRacing = {},
    friendsRacing: {results = []},
    athleteId,
    isMobile,
    athleteCard,
    t
  }) => {

  const friendsToShow = (list) => {
    const slice = list.slice(0, 3);
    const friends = slice.map((_, i) => renderProfilePic(_, i));
    const moreBtn = slice.length < list.length ?
      <span
        style={styles.moreBtn}>
        {'+'}{list.length - slice.length}
      </span>
      : null;
    return (
      <div style={styles.moreButtonWrap(isMobile)}>
        {friends}{moreBtn}
      </div>);
  };

  const renderProfilePic = (result, i) => {
    const {Photos, racerLogoPath, racerId, displayName} = result;
    const img = Photos ? Photos.Small : racerLogoPath.small;
    const profPicUrl = isInPast(event.startTime) ?
      profileImageUrl(racerId) : img;
    return (
      <AthletePhoto
        key={i}
        size='Small'
        isMember={!!racerId}
        photo={profPicUrl}
        name={displayName}
        wrapperStyle={styles.athletePhoto}
        width={40}
        height={40}
      />
    );
  };

  const renderLinkedFriends = (friends, event, isPast) => {
    const {masterId: eventMasterId, athlinksMasterId, athlinksId} = event;
    // TODO: depending on the event object passed in this could be masterId or athlinksMasterId
    const masterId = eventMasterId || athlinksMasterId;
    if (isPast) {
      return (
        <Link
          to={getResultsUrl(masterId, athlinksId, 0, 0, {friendsFilter: 'following'})}
          style={{display: 'flex'}}>
          {friendsToShow(friends)}
        </Link>
      );
    }
    else {
      return (
        <Link to={`/event/${masterId}/roster`}>
          <div>{friendsToShow(friends)}</div>
        </Link>
      );
    }
  };

  const shouldShow = () => {
    if(!isInPast(event.startTime) && starters) return true;
    else return !(
         !friendsRacing
      || friendsRacing.isLoading
      || !Array.isArray(friendsRacing.results)
      || !friendsRacing.results.length);
  };

  /**
   * Gets friend in results depending if event is in future
   * @param isPast
   * @return {Array.<*>}
   */
  const getFriends = (isPast) => {
    if(isPast) return results.filter(({showPersonalData, racerId}) =>
      showPersonalData && racerId !== athleteId);
    else return starters.filter(({ShowPersonalData}) => ShowPersonalData);
  };

  if (!shouldShow()) {
    return null;
  }

  const isPast = isInPast(event.startEpoch);
  const publicFriends = getFriends(isPast);
  if (publicFriends.length === 0) {
    return null;
  }

  const title = type === 'ELP' ? t('Racers you follow')
    : t('Other Friends In This Event');

  return (
    <div
      style={
        type === 'ELP' ?
          {...styles.elpStyle(sideMargin), ...styleFriendsRacing} :
          {...styles.irpStyle(sideMargin, athleteCard), ...styleFriendsRacing}
      }>
      <h4 style={styles.titleStyle(type)}>{title}</h4>
      {renderLinkedFriends(publicFriends, event, isPast)}
    </div>
  );
};

FriendsRacingComponent.propTypes = {
  friendsRacing: PropTypes.object,
  type: PropTypes.string,
  starters: PropTypes.array,
  t: PropTypes.func
};

FriendsRacingComponent.defaultProps = {
  friendsRacing: {isLoading: true},
  starters: []
};

export const FriendsRacing = withTranslation()(connectStream({
  friendsRacing
})(FriendsRacingComponent));

export function friendsRacing({event}) {
  if (isLoggedIn() && event) {
    return getMyFriendsStartList(event.athlinksId);
  }

  return ObservableOf({isLoading: false, isError: false, results: []});
}
