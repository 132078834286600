import React from 'react';
import { _athletes } from './styles';
import { Link, withRouter } from 'react-router-dom';
import {
  getTokenRacerId
} from '../../utils/isLoggedIn';
import raceCategories from '../../utils/races/RaceCategories';
import { isLoggedIn } from '../../utils/isLoggedIn';
import {AthletePhoto} from '../../components/athlete/AthletePhoto';
import {FollowButton} from '../../components/shared/Follow';
import { colors, transition } from '../../shared/styles';
import {SignUpBannerSearch} from '../../components/searchComponents/SignUpBannerSearch';
import Style from 'style-it';
import { trackGoogleEvent } from '../../utils/googleEvents';

export const AthleteRow = withRouter((
  {
    athleteId,
    avatarUrl,
    name,
    gender,
    age,
    city,
    state,
    country,
    total,
    isFriend,
    isMobile,
    raceCategs,
    afterFollow,
    showSignUpBanner,
    t,
    history,
  }) =>  {
  const isMe = getTokenRacerId() === parseInt(athleteId);
  const amILoggedIn = isLoggedIn();
  const imgColSize = isMobile ? 'col-2' : 'col-1 px-0';
  const infoColSize = isMobile ? 'col-10' : 'col-11';
  const width = isMobile ? 40 : 60;
  const photo = {
    HasImage: !!avatarUrl,
    Medium: avatarUrl
  };
  const athleteUrl = `/athletes/${athleteId}`;
  // className='name-of-athlete profile-url'
  const onClick = (ev) => {
    trackGoogleEvent('search', 'selectAthlete', athleteId);
    if (!ev.isDefaultPrevented()) {
      // Don't mess with the native function of anchor tags
      if (ev.currentTarget.contains(ev.target) && (ev.target.tagName !== 'A' && ev.target.tagName !== 'BUTTON')) {
        ev.preventDefault()
        // console.log({... ev})
        history.push({
          pathname: athleteUrl
        })
      }
    }
  };

  return(
    Style.it(_athletes.pseudoStyles(isMobile),
    <div className="container athlete-row" style={transition.fadeIn}>
      <div
        key={athleteId}
        className="row"
        style={{
          marginBottom: isMobile ? 0 : 50,
          padding: isMobile ? '15px 0' : 0,
          alignItems: isMobile ? 'center' : 'none',
          borderBottom: isMobile ? `1px solid ${colors.greyLine}` : 'none',
        }}
        onClick={onClick}
      >
        <div
          className={imgColSize}
          style={isMobile ? {..._athletes.imgColSizeMob} : {paddingTop:10, cursor: 'pointer'}}
        >
          <AthletePhoto
            isMember={!!athleteId}
            name={name}
            width={width}
            size='Medium'
            photo={photo}
          />
        </div>

        <div
          className={infoColSize}
          style={
            isMobile ? {padding: 0, float: 'left' } : {paddingLeft:0, cursor: 'pointer'}
          }>
          <NameGenderLocation
            url={athleteUrl}
            isMobile={isMobile}
            name={name}
            athleteId={athleteId}
            photo={photo}
            gender={gender}
            age={age}
            city={city}
            country={country}
            state={state}
            total={total}
            isFriend={isFriend}
            isMe={isMe}
            isLoggedIn={amILoggedIn}
            raceCategs={raceCategs}
            afterFollow={afterFollow}
            onClick={onClick}
            t={t}
          />
        </div>
      </div>
      {
        showSignUpBanner &&
        <SignUpBannerSearch
          isMobile={isMobile}
          style={{
            marginRight: isMobile ? -9 : 30,
            marginLeft: isMobile ? -9 : 0,
            marginTop: 20,
            marginBottom: isMobile ? 40 : 65
          }}
          t={t}
        />
      }
    </div>
    )
  );
});

/**
 * Print race count section. If mobile view then we will need rce count to
 * go in new line
 *
 * @param {int} racesCount
 * @param {boolean} isMobile
 * @return {XML}
 * @constructor
 */
export const racesCount = (racesCount, isMobile, t) => {
  const msg = `${racesCount} ${t('Races')}`;
  const styles = isMobile ? _athletes.tableRowInfoMob : _athletes.tableRowInfo;

  return (
    <div className={(isMobile) ? 'w-100' : ''} style={styles}>{msg}</div>
  );
};

function createRaceTypes(raceCatIds, t) {
  if (typeof raceCatIds !== 'object') {
    return
  }

  if (!raceCatIds.length)
    return t('No race types yet...');

  const raceTypes = raceCatIds.map((categoryId) => raceCategories(t).getById(categoryId).displayName);

  if (raceTypes.length > 3) {
    return raceTypes.slice(0, 4).join(', ') + ', ...';
  } else {
    return raceTypes.join(', ');
  }
}

export const PersonalInfoMobile = ({gender, age, location, t}) => (
  <div
    style={{
      ..._athletes.tableRowInfoMob,
      display: 'flex',
    }}>
    <div style={_athletes.genderAgeMobile}>
      {t('{{age}} {{gender}}', {age, gender})}
    </div>
    <div style={_athletes.infoDividerSpacer}>
      <div style={_athletes.infoDivider}/>
    </div>
    <div style={_athletes.locationMobile}>{location}</div>
  </div>
);

export const NameGenderLocation = ({ url, isMobile, name, athleteId, photo, gender, age, city, state, country, total, isMe, isLoggedIn, isFriend, raceCategs, afterFollow, onClick = () => {}, t}) => {
  const cityState = city ? state ? `${city}, ${state}` : country ? `${city}, ${country}` : city : '';

  return (
    <div style={_athletes.detailRow}>

      <div className='container'>
        <div className='row'>
          <div className='col-9' onClick={onClick} style={isMobile ? { padding: 0 } : null}>
            {
              // Name
            }
            <div className='w-100' style={{marginBottom:3}}>
              <Link to={url}
                className="athlete-name name-of-athlete"
                style={ _athletes.athleteName(isMobile) }>
                {name}
              </Link>
            </div>
            {
              // Personal info row for Mobile
              isMobile &&
              <PersonalInfoMobile
                gender={gender}
                age={age}
                location={cityState}
                t={t}
              />
            }

            {
              // Personal info row for Desktop
              !isMobile &&
              <div className='w-100' style={{marginBottom:5}}>
                <span style={ _athletes.tableRowInfo }>
                  <span className='gender' style={{textTransform: 'uppercase'}}>{gender}</span>, <span className='age'>{age}</span>
                </span>
                <span className="profile-location city-state" style={_athletes.tableRowInfoMiddle}>
                    {cityState}
                  <span
                    className={`flag-icon flag-icon-${country.substring(0, 2).toLowerCase()}`}
                    style={_athletes.flagContainer}/>
                </span>
              </div>
            }

          </div>
          <div className={`col-3 ${isMobile ? 'pr-0' : ''}`} style={
            !isMobile ?
              {paddingTop: 8, textAlign: 'right'} :
              {display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}
          }>
            <FollowButton
              isFriend={!!isFriend}
              RacerId={athleteId}
              isLoggedIn={isLoggedIn}
              DisplayName={name}
              Photos={photo}
              City={city}
              StateProvAbbrev={state}
              CountryId3={country}
              isMobile={isMobile}
              isMe={isMe}
              onChange={afterFollow}
            />
          </div>
        </div>

        {
          // Race & Friends info row
        }
        {
          !isMobile &&
          <div className='row'>
            <div className='col-12'>
              <div style={_athletes.raceRow}>
                <span
                  style={isMobile ? _athletes.tableRowInfoMob : _athletes.tableRowInfo}>
                  {racesCount(total, isMobile, t)}
                </span>
                <span
                  style={isMobile ? _athletes.tableRowInfoMiddleMob : _athletes.tableRowInfoMiddle}>
                  {createRaceTypes(raceCategs, t)}
                </span>
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  );
};
