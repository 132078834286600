import { flow, isEqual, partialRight, pick } from 'lodash'

export const areObjectsDifferent = (
  original,
  ...fields
) => {
  const old = pick(original, fields)
  return flow(
    partialRight(
      pick,
      fields
    ),
    partialRight(
      isEqual,
      old
    ),
    x => !x
  )
}
