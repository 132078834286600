import {colors, fonts} from '../../../shared/styles'

export const style = {
  container: (isMobile) => ({
    marginLeft: isMobile ? 0 : 15
  }),
  detailsRow: {
    marginLeft: 15,
    marginRight: 15
  },
  dateLinkRow: {
    marginLeft: 15,
    marginTop: 22
  },
  asterisk: {
    color: colors.blue,
    fontSize: '16px'
  },
  cancelButton: {
    backgroundColor: colors.white,
    border: 'none',
    color: colors.grey,
    fontFamily: fonts.bold,
    fontSize: '14px',
    fontWeight: 'bold',
    height: '44px',
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '80px',
    cursor: 'pointer'
  },
  componentLabel: {
    color: '#5C5C5C',
    fontFamily: fonts.default,
    fontSize: '10px',
    fontWeight: 600,
    height: '12px',
    letterSpacing: '0.5px',
    lineHeight: '12px',
    textTransform: 'uppercase'
  },
  footerMobile: {
    backgroundColor: colors.white,
    padding: 20,
    position: 'fixed',
    bottom: '0px',
    zIndex: 10,
    width: '100%'
  },
  headerContainer: {
    padding: '30px 0px'
  },
  hr: {
    border: `0.5px solid ${colors.greyLine}`,
    boxSizing: 'border-box',
    height: '1px',
    margin: '4px 15px',
    width: '100%'
  },
  modalBody: {
    padding: '15px 35px'   
  },
  nextButton: (disabled, isMobile) => ({
    backgroundColor: disabled ? colors.greyLine : colors.darkBlue4,
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.08)',
    cursor: disabled ? 'default' : 'pointer',
    height: '44px',
    width: isMobile ? 192 : 233,
    marginLeft: isMobile ? 0 : 36,
    float: isMobile ? 'right' : null
  }),
  nextButtonInner: {
    color: colors.white,
    height: '17px',
    lineHeight: '17px',
  },
  nextButtonText: (isMobile) => ({
    display: 'inline-block',
    fontFamily: fonts.default,
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: isMobile ? 152 : 174
  }),
  rightChevron: (isMobile) => ({
    display: 'inline-block',
    paddingBottom: 5,
    position: !isMobile ? 'relative' : null,
    left: !isMobile ? 14 : null
  }),
  modalSubtitle: {
    color: colors.darkType,
    fontFamily: fonts.default,
    fontSize: '14px',
    lineHeight: '19px',
    padding: '8px 30px',
    textAlign: 'center'
  },
  modalTitle: {
    color: colors.darkType,
    fontFamily: fonts.default,
    fontSize: '36px',
    fontWeight: 300,
    lineHeight: '44px',
    padding: '0px 30px',
    textAlign: 'center'
  },
  doneButton: {
    backgroundColor: colors.darkBlue4,
    border: 'none',
    borderRadius: '3px',
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.08)',
    height: '44px',
    margin: '35px auto 35px auto',
    width: 270,
    display: 'flex',
    alignSelf: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  doneButtonText: {
    color: colors.white,
    fontFamily: fonts.default,
    fontSize: '14px',
    fontWeight: 'bold',
    height: '17px',
    lineHeight: '17px',
    textAlign: 'center',
    margin: 'auto',
    textTransform: 'uppercase'
  },
  medal: {
    marginBottom: 15,
    marginTop: 26,
    textAlign: 'center'
  },
  medalContainer: {
    minHeight: 530
  },
  subMessage: {
    maxWidth: 500,
    textAlign: 'center',
    margin:'0 auto'
  }
}
