import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export default class LocalStreamingLog {

    subject = new BehaviorSubject();

    topics = new Map(); // Map[String, Observable[String]]

    publish(topic, message) {
        this.subject.next({ topic, message });
    }

    getTopic(topic) {
        let ret = this.topics.get(topic);
        if (!ret) {
            ret = this.subject.pipe(filter((ev) => ev.topic === topic)).pipe(map((ev) => ev.message));
            this.topics.set(topic, ret);
        }
        return ret;
    }
}
