import {SearchState} from '../model/searchState';
import {PrinterActionTypes} from '../actions/types';

export default (state = SearchState, action) => {
  switch(action.type) {
    case PrinterActionTypes.PRINTER_SET:
      return {
        ...state,
        printerSet: action.printerSet
      };
    default:
      return state;
  }


}
