import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import {
  eventBus,
  streamHook
} from './Streams';
import { getToken } from '../utils/isLoggedIn';
import { filter } from 'rxjs/operators'

const mainFollowStream = 'following-friend';

/**
 * Follow athlete
 * @param athleteId
 * @param RacerId
 */
export function followAthleteStream({athleteId, RacerId}) {
  const id = athleteId || RacerId;
return getFollow(id)
    .pipe(filter((isFriend) => isFriend === true || isFriend === false));
}

export async function toggleFollowing(isFriend, athleteId) {
  const token = await getToken();
  FetchApi.doFetch(
    `${configs.ATHLINKS_API_HOST}/Links/Api/${isFriend ? 'Follow' : 'Unfollow'}/${athleteId}`,
    {
      method: !isFriend ? 'DELETE' : 'POST',
      headers: {Authorization: `Bearer ${token}`}
    }
  ).subscribe(() => {
    setFollow(isFriend, athleteId);
  })
}

export function getFollow(athleteId) {
  return streamHook(mainFollowStream+'-'+athleteId, {fetching: true});
}

export function setFollow(isFriend, athleteId) {
  eventBus.publish(mainFollowStream+'-'+athleteId, isFriend);
}

export const FollowStream = {
  followAthleteStream,
  setFollow,
  toggleFollowing
};
