import React from 'react';
import Event from './Event';
import {shouldShowSignUpBanner} from '../../utils/isLoggedIn';

export const EventList = ({ events, isMobile, t }) => (
  <div className="event-list">
    {events && events.map((event, index) =>
      <Event
        key={index}
        event={event}
        isMobile={isMobile}
        showSignUpBanner={index === 2 && shouldShowSignUpBanner()}
        t={t}
      />
    )}
  </div>
);
