import { textStyles, fonts, colors } from '../../shared/styles';

export const styles = {
  allResults: (hide,notFound, isMobile, windowHeight) => ({
    padding: 0,
    zIndex: 900,
    background: colors.white,
    minHeight: (hide) ? 0 : 120,
    maxHeight: (hide) ? 0 : notFound && isMobile ? '36vh' : notFound ? (windowHeight / 23) + 'vh' : '80vh', // could probably do window height calc better
    overflowY: 'hidden',
    pointerEvents: (hide) ? 'none' : 'auto',
    transition: 'min-height 0.2s ease-in-out, max-height 0.2s ease-in-out', // for homepage hero search
  }),

  resultTable: {
    width: '100%',
  },
  tableHeader: {
    textAlign: 'left',
    paddingBottom: 10,
    paddingTop: 10,
    background: colors.lightGrey3
  },
  titles: {
    paddingTop: 40,
    color: 'blue',
    fontSize: 18
  },
  eventTitle: {
    width: 40,
    height: 40,
    marginRight: 10,
    background: colors.lightGrey4,
    color: colors.textColor,
    float: 'left'
  },
  listLabel: {
    borderBottom: `5px solid ${colors.blue}`,
    paddingBottom: 5,
    color: colors.textColor,
    cursor: 'pointer'
  },
  listLabelShow: {
    color: colors.textColor,
    cursor: 'pointer'
  },
  tableLabel: {
    width: '15%',
    display: 'table-cell',
    verticalAlign: 'middle',
    fontSize: 16,
    fontStyle: 'italic',
    color: colors.grey
  },
  athleteTimeContainer: {
    position: 'relative',
    display: 'inline-block',
    float: 'left',
    width: 40,
    height: 45,
    marginRight: 10,
    marginLeft: 10
  },
  athleteTime: {
    position: 'absolute',
    top: 6,
    right: 0,
    display: 'inline-block',
    maxWidth: 100,

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    fontFamily: 'BebasNeueBold, serif',
    textAlign: 'right',
    fontSize: 24,
    color: colors.textColor,
  },

  topLabel: {
    backgroundColor: colors.lightGrey2,
    padding: '4px 10px 2px 54px',
    ...textStyles.sectionHead,
  },

  tableLineHeight: {
    fontSize: 16,
    width: '100%',
    float: 'left',
    margin: 0,
    marginTop: 10,
    color: colors.grey,
    marginBottom: 10
  },
  resultRow: () => ({
    fontSize: 16,
    margin: '2px 0',
    color: colors.grey,
    padding: 12,
    position: 'relative'
  }),
  resultContent: {
    paddingLeft: 40,
    paddingRight: 10,
    marginBottom: 15,
  },
  moreLink: {
    display: 'inline-block',
    paddingLeft: 55,
    fontSize: 14,
    color: colors.blue
  },

  athleteImg: {
    borderRadius: '50%',
    marginRight: 15,
    paddingTop: 2,
    width: 40,
    float: 'left',
  },
  tempEvent: {
    display: 'inline-block',
    width: 40,
    height: 40,
    marginRight: 15,
    float: 'left',
    backgroundColor: colors.lightGrey5,
  },
  resultsContainer: {
    marginLeft: 55,
    maxWidth: 430
  },
  resultName: {
    paddingTop: 4,
    fontSize: 18,
    lineHeight: '1em',
    color: colors.textColor,
  },
  resultDetails: {
    fontSize: 14,
    color: colors.grey,
  },
  pipeLeft: {
    display: 'inline-block',
    marginRight: '12px',
  },
  pipeRight: {
    display: 'inline-block',
    marginLeft: '12px',
  },

  unifiedFilter: {
    borderBottom: `1px solid ${colors.lightGrey5}`,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'inline-flex',
    width: '100%',
    listStyle: 'none',
    margin: 0
  },
  unifiedFilterLI: {
    marginRight: '5%',
    paddingLeft: 10,
    fontWeight: 'bold',
    fontFamily: 'ProximaNovaSemibold'
  },
  unifiedFilterLIFirst: {
    marginRight: '5%',
    paddingLeft: 10,
    fontFamily: 'ProximaNova',
    fontStyle: 'italic',
    color: colors.grey
  },
  active: {
    borderBottom: `3px solid ${colors.grey}`
  },
  inactive: {
    borderBottom: 'none'
  },

  searchingOverlay: {
    paddingTop: 50,
    textAlign: 'center',
    ...fonts.body1Grey,
    color: colors.grey,
  },
  loadingOverlay: (show) => ({
    position: 'absolute',
    zIndex: 9999,
    top: 0,
    left: 0,
    width: '100%',
    height:'100%',
    backgroundColor: 'rgba(255,255,255, 0.8)',
    opacity: (show) ? 1 : 0,
    pointerEvents: 'none',
    transition: 'all 0.25s',
  }),
  rowArrowContainer: {
    position: 'absolute',
    top:0,
    right:0
  }
};
