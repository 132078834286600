import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { StickyHeader, Header } from '../../components/shared/Header';
import { MasterEvent } from '../../classes/MasterEvent';
import { Loading } from '../../components/shared/Loading';
import { styles } from '../../components/kiosk/styles';
import { FormattedDate } from 'react-intl';
import { IndividualKioskResults } from '../../components/kiosk/IndividualKioskResults';
import { KioskHeader } from '../../components/kiosk/KioskHeader';
import { KioskSearchForm } from '../../components/kiosk/KioskSearchForm';
import { getCourseDetail } from '../../utils/ERPUtil';
import { configs } from '../../configs';
import { FB_IMG_PATH } from './EventKioskResults';

/**
 * Event kiosk results view. Renders leaderboard and single result view
 * Can search results
 */
export class EventKioskResultsComponent extends Component {
  static propTypes = {
    event: PropTypes.object,
    eventId: PropTypes.number.isRequired,
    eventMetadata: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    masterEvent: PropTypes.instanceOf(MasterEvent).isRequired,
    masterId: PropTypes.number.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    event: {},
  };

  state = {
    bib: null,
    eventCourseId: null,
    isLightMode: true,
    isLoadingError: false
  };

  isLoaded = () => {
    const { event } = this.props.event;
    return !!event && !this.props.eventMetadata.fetching;
  };

  componentDidMount() {
    this.addPageLeaveListener();
    setTimeout(() => {
      if (!this.isLoaded()) {
        this.setState({ isLoadingError: true });
      }
    }, 10000);
  }

  componentWillUnmount() {
    this.removePageLeaveListener();
  }

  logoUrl() {
    const { event } = this.props.event;
    if (!event.hasHeroImage) return null;
    return `${configs.customHeroImageUrl}${FB_IMG_PATH}${event.imageName}`;
  }

  onOptionSelected = ({ bib, eventCourseId }) => this.setState({ bib, eventCourseId });

  cancelSelection = () => this.setState({ bib: null, eventCourseId: null });

  addPageLeaveListener = () => window.onbeforeunload = this.pageLeaveListener;

  removePageLeaveListener = () => window.onbeforeunload = null;

  pageLeaveListener = () => {
    const { t } = this.props;

    return window.confirm(t('Are you sure you want to leave this page?'));
  };


  renderHeader = (isLightMode) => {
    const { beta, isMobile, eventId, masterEvent, t } = this.props;
    return isMobile
      ? <Header
        isLightMode={isLightMode}
        onSwitchMode={(mode) => { this.setState({ isLightMode: mode }); }}
        {...this.props} />
      : <Sticky>
        {(props) => <StickyHeader
          beta={beta}
          isLightMode={isLightMode}
          onSwitchMode={(mode) => { this.setState({ isLightMode: mode }); }}
          eventId={eventId}
          masterEvent={masterEvent}
          t={t}
          {...props} />}
      </Sticky>;
  };

  render() {
    const {
      bib, eventCourseId, isLightMode, isLoadingError,
    } = this.state;

    const {
      beta, eventId, eventMetadata, masterEvent, mode, isMobile, history, t
    } = this.props;

    const {
      event
    } = this.props.event;

    if (isLoadingError) {
      return (
        <section
          style={styles.searchViewContainer(isLightMode)}>
          <StickyContainer>
            {this.renderHeader(isLightMode)}
            <div style={styles.errorContainer(isLightMode)}>
              {t("Welp, we're having trouble accessing this Event Kiosk")}
              <br />
              <button
                style={{ ...styles.errorButton, marginTop: '2em' }}
                onClick={() => {
                  history.push(`/event/${masterEvent.masterEventID}`);
                }}
              >{t('GO TO EVENT LANDING PAGE')}</button>
            </div>
          </StickyContainer>
        </section>
      );
    }

    if (!this.isLoaded()) {
      return <Loading centered={true} />;
    }

    const eventCourseDetail = getCourseDetail(eventCourseId, eventMetadata?.data);
    const { eventCourseName } = eventCourseDetail;
    const eventDate = (event.startEpoch || 0) * 1000;

    return (
      <section
        style={styles.searchViewContainer(isLightMode)}>
        <StickyContainer>
          {this.renderHeader(isLightMode)}
          {!!bib ? (
            <IndividualKioskResults
              beta={beta}
              bib={bib}
              cancelSelection={this.cancelSelection}
              eventCourseId={eventCourseId}
              eventCourseName={eventCourseName}
              eventDate={eventDate}
              eventMetadata={eventMetadata?.data}
              eventId={eventId}
              isLightMode={isLightMode}
              isMobile={isMobile}
              masterEvent={masterEvent}
              mode={mode}
              t={t} />
          ) : (
            <div>
              <FormattedDate
                year='numeric'
                month='long'
                day='2-digit'
                value={event.startEpoch * 1000}
              >
                {(date) => (
                  <KioskHeader
                    logo={event.logoUrl}
                    title={eventMetadata?.data.eventName}
                    isLightMode={isLightMode}
                    subtitle={date}
                    isMobile={isMobile} />
                )}
              </FormattedDate>
              <KioskSearchForm
                {...this.props}
                eventMetadata={eventMetadata?.data}
                onOptionSelected={this.onOptionSelected}
                isLightMode={isLightMode} />
            </div>
          )}
        </StickyContainer>
      </section>
    );
  }
}
