import { useEffect } from 'react'
import { useGetIrpResultQuery } from '../../api/resultsApi'
import { EventCourses } from '../../utils/types'
import { IRPBodyContainerComponent } from '../../components/IRP/body/IRPBodyContainer'
import { isEmpty } from 'lodash'
import { Loading } from '../../components/shared/Loading'
import { useDispatch } from 'react-redux'
import { storeIrpResultData } from '../../reducers/irpResultSlice'
import useSse from '../../lib/firebase/useSse'
import { ReigniteIndividualSseResult } from './ReigniteIndividualSseResult'
import useIrpMedia from '../../hooks/useIrpMedia'
import useFinisherCertificate from './useFinisherCertificate'

export function RtkQueryIndividualResult(props: {
  athlinksEventId: number
  bib?: string
  courses: EventCourses
  entryId?: number
  event?: {
    ctliveId?: number
  }
  eventCourseId: number
  isLive: boolean
}) {
  const {
    athlinksEventId,
    bib,
    courses,
    entryId,
    eventCourseId,
    isLive,
  } = props

  const dispatch = useDispatch()

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    data: irpResult,
    isFetching,
    refetch,
  } = useGetIrpResultQuery(
    {
      eventId: athlinksEventId,
      eventCourseId,
      bib,
      id: entryId,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 60_000
        : undefined,
    }
  )

  const thirdPartyEntryId = irpResult?.thirdPartyEntryId ?? 0
  const media = useIrpMedia({
    thirdPartyEntryId,
    thirdPartyEventId: props.event?.ctliveId ?? 0,
  })
  const certificate = useFinisherCertificate({
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
    thirdPartyEntryId,
  })

  useEffect(() => {
    dispatch(storeIrpResultData(irpResult))
  }, [
    dispatch,
    irpResult,
  ])

  if (isEmpty(irpResult)) {
    return (
      <Loading
        centered={true}
      />
    )
  }

  return (
    <>
      {usingSse && <ReigniteIndividualSseResult
        athlinksEventId={athlinksEventId}
        bib={bib}
        eventCourseId={eventCourseId}
        refetch={refetch}
      />}
      <IRPBodyContainerComponent
        {...props}
        certificate={certificate}
        courses={courses}
        fetching={isFetching}
        media={media}
        result={irpResult}
      />
    </>
  )
}
