import { Configure, FetchApi } from '../../anuket-http';
import { of as ObservableOf } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { callApi } from './helpers/HttpHelper';

export function getUniqueResultYears({eventId, beforeEpoch}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Race/Api/${eventId}/Course/0`;
  return FetchApi.memoizeFetch(10000)(url).pipe(
    take(1),
    map((msg) => {
      const races = (msg.Success && msg.Result && msg.Result.MasterEvent && msg.Result.MasterEvent.EventRaces) || [];

      const years = races.reduce((acc, curr) => {
        const date = new Date(curr.RaceDate);
        const epoch = date.getTime() / 1000;
        const year = date.getFullYear();
        if (!acc.haveYears[year] && epoch < beforeEpoch) {
          acc.haveYears[year] = true;
          acc.data.push({
            eventId: curr.RaceID,
            year: date.getFullYear()
          });
        }
        return acc;
      }, {haveYears: {}, data: []});

      return {
        isLoading: false,
        years: years.data
      };
    })
  );
}

export function getEventRaceDates({eventId}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Race/Api/${eventId}/Course/0`;
  return FetchApi.memoizeFetch(10000)(url).pipe(
    take(1),
    map((msg) => {
      const races = (msg.Success && msg.Result && msg.Result.MasterEvent && msg.Result.MasterEvent.EventRaces) || [];
      return {
        isLoading: false,
        dates: races.map((race) =>  ({
          eventId: race.RaceID,
          date: new Date(race.RaceDate).getTime()
        }))
      };
    })
  );
}

export function searchEventResults({eventId, search}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Race/Result/Api/${eventId}/Search?search=${search}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(take(1));
}

export function getFriendsEventResults({eventId, racerId, token}) {
  const url = Configure.getValue('ATHLINKS_API_HOST')
            + `/Events/Race/Result/Api/${eventId}/Friends/${racerId}`;
  const req = {headers: {'Authorization': `Bearer ${token}`}};
  return FetchApi.memoizeFetch(10000)(url, req).pipe(take(1));
}

export function getEntryResults({entryId, eventId, eventCourseId, bib, lastName, token}) {
  return FetchApi.memoizeFetch(10000)(
    (entryId) ?
      `${Configure.getValue('ATHLINKS_API_HOST')}/events/race/result/api/entry/${entryId}` :
      (eventCourseId) ?
        `${Configure.getValue('ATHLINKS_API_HOST')}/Events/Race/Result/Api/Event/${eventId}/Course/${eventCourseId}/Bib/${bib}` :
        (lastName) ?
          `${Configure.getValue('ATHLINKS_API_HOST')}/Events/Race/Result/Api/Event/${eventId}/Bib/${bib}/${lastName}` :
          `${Configure.getValue('ATHLINKS_API_HOST')}/Events/Race/Result/Api/Event/${eventId}/Bib/${bib}`,
    {headers: {'Authorization': `Bearer ${token}`}})
      .pipe(take(1));
}

export function logClaimIntent(eventCourseId, ctLiveEntryId, bib, fullName) {
  return callApi(
    'Claim/Api/LogClaimIntent',
    'post', {
      'Content-type': 'application/json;charset=UTF-8'
    },
    JSON.stringify({
      EventCourseID: eventCourseId,
      CTEntryID: ctLiveEntryId,
      BibNum: bib,
      FullName: fullName
    })
  );
}

export function getEventResults({eventId}) {
  const url = `${Configure.getValue('ATHLINKS_API_HOST')}/events/race/result/api/${eventId}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(take(1));
}

export function getEventCourseTotal({eventId, eventCourseId, divisionId, splitId, ...params}) {
  const url = `${Configure.getValue('ATHLINKS_API_HOST')}/events/race/result/api`
            + `/${eventId}/course/${eventCourseId}`
            + (divisionId ? `/division/${divisionId}` : '')
            + (splitId ? `/interval/${splitId}` : '')
            + '?total=true';
  return FetchApi.memoizeFetch(10000)(url).pipe(take(1));
}

export function getEventCourseResults({eventId, eventCourseId, divisionId, splitId, ...params}) {
  const url = `${Configure.getValue('ATHLINKS_API_HOST')}/events/race/result/api`
            + `/${eventId}/course/${eventCourseId}`
            + (divisionId ? `/division/${divisionId}` : '')
            + (splitId ? `/interval/${splitId}` : '')
            + `?skip=${params.from || 0}&limit=${params.topN || 20}`;
  return FetchApi.memoizeFetch(10000)(url).pipe(take(1));
}

export function getResultByEventAndBib({ event, bib }) {
  if (!event || !bib) {
    return ObservableOf({});
  } else {
    const url = Configure.getConfigs().chronotrackApiUrl + `/results/${event.ctliveId}/bib/${bib}`;
    return FetchApi.memoizeFetch(10000)(url).pipe(take(1));
  }
}
