import React from 'react';
import PropTypes from 'prop-types';
import {Sponsors} from '../../sponsors/Sponsors';
import {Banner} from '../../shared/Banner';
import {bannerStyle} from '../../../shared/styles';

export const Promo = ({sponsors, adUnits, isMobile, t}) => {
  const hasSponsors = sponsors.length > 0;

  if(hasSponsors) {
    return (
      <Sponsors
        enableTitleLogos={true}
        logos={sponsors}
        isMobile={isMobile}
        t={t}
      />
    );
  }
  else {
    return (
      <div>
        {
          adUnits.map((x) =>
            <div key={x.adKey} style={bannerStyle(isMobile)}>
              <Banner
                data-ad={x.adKey}
                adKey={x.adKey}
                divId={x.divId}
                style={{width: 300, height: 250, margin: '0 auto'}}/>
            </div>
          )
        }
      </div>
    );
  }
};

Promo.propTypes = {
  sponsors: PropTypes.array,
  adUnits: PropTypes.array,
  isMobile: PropTypes.bool.isRequired
};
