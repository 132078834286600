import { Theme, makeStyles } from '@material-ui/core';

import { fonts, loadingLight } from '../../shared/styles';

type StylesProps = {
  isOnIrp: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  container: {
    color: theme.palette.grey[800],
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  temperatureNumber: ({ isOnIrp }) => ({
    alignItems: 'center',
    display: 'flex',
    fontSize: isOnIrp ? 21 : 25,
    gap: '12px',
  }),
  temperatureUnit: {
    fontSize: 14,
    color: theme.palette.grey[800],
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.4,
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  iconContainer: {
    textAlign: 'center',
  },
  divider: {
    height: '20px',
    borderRight: `1px solid ${theme.palette.grey[100]}`,
  },
  summary: {
    textTransform: 'capitalize',
    fontSize:'10px',
  },
  icon: ({ isOnIrp }) => ({
    color: theme.palette.grey[800],
    display: 'inline-block',
    fontSize: isOnIrp ? 17 : 24,
  }),
  loadingWeather: {
    zIndex: 2,
    width: '80%',
    height: 40,
    pointerEvents: 'none',
    ...loadingLight,
    transition: 'opacity 0.3s',
  },
}));

export { useStyles };
