import { colors } from '../../shared/styles';

export const styles = {
  headerContainer: {
    fontSize: 16, 
    marginBottom: 24,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    color: colors.darkType,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '34px',
    fontWeight: 300,
    lineHeight: '41px',
    textAlign: 'center',
    paddingTop: '10px',
    paddingBottom: 10
  },
  headerSubText: {
    color: colors.darkType,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    maxWidth: '450px',
    paddintTop: 5
  },
  subHeader: {
    color: colors.darkType,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: '12px'
  },
  textSeparator: {
    boxSizing: 'border-box',
    height: '1px',
    border: `1px solid ${colors.greyLine}`,
    marginTop: '5px',
    marginBottom: '10px'
  },
  marker: {
    height: '5px',
    width: '5px',
    backgroundColor: colors.blue,
    borderRadius: '2.5px',
    marginLeft: 6,
    marginRight: 3,
    float: 'left'
  },
  listItem: {
    color: colors.darkType,
    fontFamily: 'ProximaNovaRegular',
    fontSize: '14px',
    marginLeft: '1em',
    lineHeight: '17px'
  },
  listItemContainer: {
    paddingTop: '15px',
    display: 'flex',
    alignItems: 'center'

  },
  buttonContainer: (active) => ({
    textAlign: 'center',
    marginTop: 100,
    cursor: 'pointer',
    pointerEvents: active ? 'auto' : 'none'
  }),
  button: (active, isPlain) => ({
    height: 45,
    width: 295,
    borderRadius: 3,
    backgroundColor: isPlain ? 'white' : active ? colors.darkBlue4 : colors.greyLine,
    boxShadow: '0 0 15px 0 rgba(0,0,0,0.08)',
    margin: '0 auto',
    border: isPlain ? `1px solid ${colors.grey}` : 'none'
  }),
  buttonText: (active, isPlain) => ({
    color: isPlain ? colors.grey : active ? colors.white : colors.grey,
    fontFamily: 'ProximaNovaBold',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    lineHeight: '17px',
    paddingTop: '1em',
    textTransform: 'uppercase'
  }),
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  kioskPrinterContainer: {
    float: 'right', 
    fontFamily: 'ProximaNovaRegular'
  },
  printIcon: {
    paddingBottom: '10px',
    paddingRight: '10px'
  }
}
