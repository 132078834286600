import React from 'react';
import { withTranslation } from 'react-i18next';
import { useGetIrpResultQuery } from '../../api/resultsApi';
import { IndividualKioskResultsComponent } from './IndividualKioskResultsComponent';

const PureIndividualKioskResults = (props) => {
  const {
    bib,
    entryId,
    eventCourseId,
    eventId,
  } = props
  const {
    data: irpResult,
    isFetching,
  } = useGetIrpResultQuery(
    {
      eventId,
      eventCourseId,
      bib,
      id: entryId,
    },
    {
      pollingInterval: 45_000, // poll every 45 seconds
      refetchOnMountOrArgChange: 60, // cache for 60 seconds 
    }
  )
  return <IndividualKioskResultsComponent
    {...props}
    isLoading={isFetching}
    result={irpResult}
  />
}

export const IndividualKioskResults = withTranslation()(PureIndividualKioskResults);
