import { colors, fonts } from '../../../shared/styles';

export const styles = {
  container: (isMobile) => ({
    marginLeft: isMobile ? 0 : 20
  }),
  dateLocation: {
    display: 'inline-flex',
    margin: 0,
    padding: 0
  },
  detailsContainer: {
    borderBottom: '1px solid '+ colors.greyLine,
    borderTop: '1px solid '+ colors.greyLine,
    margin: 0,
    padding: '10px 0 15px 0'
  },
  eventName: Object.assign({}, fonts.default, {
    color: colors.textColor,
    width: '100%',
    margin: 0,
    padding: 0,
    fontSize: 21,
    lineHeight: '25px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  location: Object.assign({}, fonts.default, {
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 12,
    color: colors.grey,
    margin: 0,
    padding: 0
  }),
  linkBox: {
    style: (isMobile) => ({
      height: 42,
      borderRadius: 3,
      fontSize: 16,
      fontFamily: fonts.default.fontFamily,
      width: isMobile ? '100%' : '420px',
      padding: '0px 0px 0px 15px'
    }),
    placeholder : {
      color: colors.grey
    },
    regular: {
      border: '1px solid ' + colors.greyLine,
      color: colors.textColor
    },
    error: {
      border: '1px solid ' + colors.error,
      color: colors.error
    }
  },
  message: {
    container: {
      height: 16
    },
    style: {
      color: colors.error,
      fontFamily: fonts.default.fontFamily,
      fontSize: 12,
      marginTop: 5
    }
  },
  question: {
    marginLeft: 5,
    height: 14,
    width: 14
  },
  star: {
    fontSize: 16,
    color: colors.blue,
    fontWeight: 600,
    paddingLeft: 5,
    fontFamily: fonts.default.fontFamily
  },
  title: {
    padding:0,
    margin: 0,
    fontSize: 10,
    color: colors.textColor,
    fontWeight: 600,
    letterSpacing: '0.5px',
    fontFamily: fonts.default.fontFamily,
    textTransform: 'uppercase'
  },
  titleContainer: {
    marginBottom: 7
  }
};
