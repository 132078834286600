import {streamHook, eventBus} from './Streams';
import { configs } from '../configs';
export const TERM_SEARCH = 'term-search-stream';

function searchTermLimit(term) {
  return term.substr(0, configs.searchCharLimit)
}

export const defaultTerm = '';

export function getSearchTerm() {
  return streamHook(TERM_SEARCH, defaultTerm);
}

export function getDefaultValue() {
  return streamHook(
    `term-default`, searchTermLimit(defaultTerm))
}

export function setValue(stream, value, isDefault = false) {
  eventBus.publish(stream, searchTermLimit(value));
  if(isDefault) {
    eventBus.publish(`term-default`, searchTermLimit(value));
  }
}

export function setTermValue(value, isDefault = false) {
  setValue(TERM_SEARCH, searchTermLimit(value), isDefault);
}
