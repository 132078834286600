import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';
import { KioskTimePace, KioskTimePaceLoader } from './KioskTimePace';
import {isGunTime} from '../../utils/ERPUtil';
import {showPace} from '../../utils/IRPUtil';
import {convertPace, convertTime} from '@eventops/athlinks-lib-units';
import {getLatestScoredInterval} from '../../utils/IRPUtil';
import _ from 'lodash';

/**
 * Renders IRP content for kiosk view
 * @param {Result} result
 * @param {MasterEvent} masterEvent
 * @param {Boolean} isMobile
 * @return {*}
 * @constructor
 */
export class IndividualKioskResultsContent extends Component {
  static propTypes = {
    isLightMode: PropTypes.bool,
    isMobile: PropTypes.bool,
    result: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  render() {
    const {
      eventCourseName,
      isLightMode,
      isMobile,
      result,
      t
    } = this.props;
    const useGunTime = isGunTime(result.brackets);
    const mostRecentInterval = getLatestScoredInterval(result.intervals);
    const latestIntervalName = _.get(mostRecentInterval, 'intervalName', '');
    const brackets = mostRecentInterval.brackets || [];
    const isFinisher = !['DNF', 'DQ'].includes(result.entryStatus);
    const hasPace = showPace(mostRecentInterval.pace);
    const pace = hasPace
      ? convertPace(mostRecentInterval.pace, {intervalRaceType: mostRecentInterval.intervalRaceType, capitalizeLabel: false})
      : {value: '', label: ''};
    const hasTime = _.get(mostRecentInterval, 'timeWithPenalties.timeInMillis', 0) > 0;
    const finishTime = hasTime ? convertTime(_.assign({}, mostRecentInterval.timeWithPenalties, {timeUnit: 'h'})).value : '';

    return (
      <div className='row' style={styles.resultsContentContainer}>
        {
          !result.isLoading &&
          <div style={styles.resultsEventInfo}>
            <div style={styles.resultsEventDetails(isMobile, isLightMode)}>
              <div style={styles.courseName(isLightMode)}>{eventCourseName}</div>
            </div>
          </div>
        }
        <div className='col-12' style={styles.timePaceContainer}>
          {
            !result.isLoading ?
            <KioskTimePace
              hasPace={hasPace}
              finishTime={finishTime}
              isGunTime={useGunTime}
              isLightMode={isLightMode}
              isMobile={isMobile}
              pace={pace.value}
              paceUnit={pace.label}
              ranks={brackets}
              result={{...result, isFinisher }}
              latestIntervalName={latestIntervalName}
              t={t}
            /> :
            <KioskTimePaceLoader isLightMode={isLightMode} />
          }
        </div>
      </div>
    );
  }
}

IndividualKioskResultsContent.propTypes = {
  result: PropTypes.object.isRequired,
};
