import { textStyles, colors, fonts } from '../../shared/styles';

const maxColWidth = 120;

export const styles = {
  tableHeaders: {
    display: 'flex',
    paddingBottom: 7,
    textAlign: 'center',
    ...textStyles.sectionHead,
    fontFamily: 'ProximaNovaBold',
    letterSpacing: .5,
    fontSize: 12,
    color: '#9b9b9b',
    borderBottom: '1px solid '+ colors.greyLine,
    backgroundColor: colors.white,
  },
  placeColHead: {
    display: 'inline-block',
    flex: '0 0 50px',
  },
  nameColHead: {
    textAlign: 'left',
  },
  headerCol: {
    maxWidth: maxColWidth
  },

  raceTitle: {
    marginBottom: 25,
    color: colors.textColor,
    backgroundColor: colors.white,
    zIndex: 6,
  },
  raceSubtitle: {
    ...textStyles.body1Grey,
    marginBottom: '16px'
  },
  courseNameHeader: {
    fontWeight: 'initial'
  },

  intervalTitle: {
    marginTop: 14,
    padding: '10px 10px 8px',
    ...fonts.heavy,
    textAlign: 'left',
    fontSize: 14,
    backgroundColor: colors.lightGrey,
    textTransform: 'uppercase',
    marginBottom: 14
  },

  tableRow: (isMobile, isClaimed) => ({
    height: isMobile && isClaimed ? 66 : null,
    background: isMobile && isClaimed ? 'linear-gradient(90deg, rgba(38,170,223,0.05) 0%, rgba(38,170,223,0) 100%)' : null,
    display: 'flex',
    alignItems: 'center',
    padding: !isMobile ? '15px 0px' : null,
    borderLeft: isMobile && isClaimed ? `3px solid ${colors.blue}` : null,
    color: colors.textColor,
    borderBottom: '1px solid '+ colors.greyLine,
    transition: 'all 0.3s',
    width: '100%',
    position: 'relative'
  }),
  midData: {
    alignItems: 'center',
  },
  dataColumn: {
    textAlign: 'center',
    fontSize: 21,
    maxWidth: maxColWidth,
    fontFamily: 'LatoBold',
    marginTop: -4
  },
  timeColumn: {
    textAlign: 'center',
    fontSize: 21,
    lineHeight: '1em',
    maxWidth: maxColWidth,
    fontFamily: 'LatoBold'
  },

  vrBadge: {
    textTransform: 'uppercase',
    fontSize: 10,
    color:'#00A8E3',
    fontWeight: 'bold',
    letterSpacing: '.025em',
    fontFamily: 'ProximaNovaBold',
  },
  vrBadeIcon: {
    fontSize: 12,
  },
  unclaimed: {
    fontSize: 11,
    color: colors.blue,
    fontFamily: 'ProximaNovaRegular',
    textAlign: 'center'
  },

  noResultRow: {
    textAlign: 'center',
    paddingTop: 30,
    color: colors.grey,
  },

  seeMoreRow: {
    textAlign: 'center',
    padding: '15px 0 0',
  },
  seeMoreButton: {
    display: 'inline-block',
    padding: '5px 15px',
    ...textStyles.textLink2Blue,
    lineHeight: '1em',
    border: 'none',
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  mobile: {
    titleContainer: {
      padding: 16
    },
    tableSubtitle: {
      marginTop: '8px',
      fontSize: '16px',
      color: colors.greyLine
    },
    sectionTitle: {
      textAlign: 'left',
      fontSize: 16,
      fontFamily: 'ProximaNovaRegular',
      letterSpacing: '0px',
      clear: 'both',
      width: '100%',
      padding: 0,
      marginTop: 30,
      color: colors.textColor
    },
    raceDate: {
      fontFamily: 'ProximaNovaRegular',
      color: colors.textColor,
      fontSize: 14,
      linHeight: '20px'
    },
    row: {
      boxSizing: 'border-box',
      position: 'relative',
      minHeight: 96,
      borderTop: `1px solid ${colors.lightGrey5}`,
      backgroundColor: colors.white
    },
    rowName: Object.assign({}, textStyles.body1Dark, {
      fontFace: 'ProximaNovaSemibold',
      fontSize: '17px',
      whiteSpace: 'wrap',
      textDecoration: 'none',
      letterSpacing: 'initial',
      textTransform: 'initial'
    }),
    rowContent: textStyles.captionGrey,
    detailsContainer: {
      backgroundColor: colors.white,
      paddingTop: 16,
      paddingBottom: 16,
      paddingRight: 8,
      marginLeft: 72,
      marginRight: 108,
      boxSizing: 'border-box',
      minHeight: 96
    },
    actionsContainer: {
      position: 'absolute',
      boxSizing: 'border-box',
      width: 96, right: 0, top: 0, bottom: 0,
      padding: '16px',
      textAlign: 'right',
      backgroundColor: colors.white
    },
    raceCourseName: {
      textAlign:'center',
      fontSize:'25px',
      color: colors.textColor,
      fontWeight: '300'
    },
    tableLabel: {
      height: 12,
      width: 46,
      color: colors.grey,
      fontFamily: 'ProximaNovaBold',
      fontSize: 12,
      lineHeight: '12px',
      textTransform: 'uppercase'
    },
    rowIndex: {
      color: colors.textColor,
      fontFamily: 'LatoBold',
      fontSize: 14,
      lineHeight: '17px',
      textAlign: 'center',
      padding: '0 4px 0 6px',
    },
    mainRow: {
      borderBottom: '2px solid lightgray',
      marginBottom: 10,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 16,
    },
    userRow: {
      width: '100%',
      minHeight: '66px',
      display: 'flex',
      alignItems: 'center'
    },
    leftCol: {
      float: 'left',
      marginRight: 10
    },
    index: {
      height: 12,
      width: 66,
      paddingLeft: 1,
      display: 'flex',
      alignItems: 'center',
      color: colors.blue,
      fontFamily: 'ProximaNovaBold',
      fontSize: 10,
      lineHeight: '12px'
    },
    image: {
      width: 50
    },
    name: {
      color: colors.textColor,
      fontFamily: 'ProximaNovaBold',
      fontSize: 16,
      lineHeight: '20px'
    },
    age: {
      color: colors.grey,
      fontSize: 11
    },
    separator: {
      padding: '0px 10px'
    },
    time: {
      height: 29,
      width: 66,
      color: colors.textColor,
      fontSize: 16,
      fontFamily: 'LatoBold',
      lineHeight:  '28px',
      textAlign: 'center'
    },
    footer: {
      backgroundColor: colors.white,
      padding: 16
    }
  },
  resultRow: {
    arrowContainer: {
      width: 45,
      color: colors.lightGrey4
    }
  },

  claimFlame: {
    desktop: {
      position: 'absolute',
      left: '-8px'
    },
    mobile: {
      position: 'absolute',
      left: '-12px',
      top: '0px',
      height: '29px'
    }
  },
  rowArrowColumn: {
    desktop: {
      width: 45,
      color: '#cccccc',
      textAlign: 'right',
      paddingRight: '10px',
      position: 'absolute',
      right: 10,
    },
    mobile: {
      left: '10px'
    }
  },
  noAvatar: {
    width: 48
  },
  pseudoStyles: () => `
    .resultArrow {
      display: none;
    }
    .link-to-irp:hover {
      background: ${colors.lightGrey} !important;
      cursor: pointer;
    }
    .link-to-irp:hover .resultArrow {
      display: block !important;
    }
    .link-to-irp:hover .athName {
      text-decoration: underline !important;
      text-decoration-color: ${colors.greyLine} !important;
      -webkit-text-decoration-color: ${colors.greyLine} !important;
    }
  `
};
