import React from 'react';
import { mainStyles } from '../../shared/styles';
import { styles } from './styles';

export const GetStarted = ({t}) => {
  return(
    <div className="row" id="get-started-banner" style={styles.getStartedBanner.joinDiv}>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9" id="get-started-image" style={mainStyles.floatLeftImg}>
        </div>
      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3" id="get-started-button" style={mainStyles.floatLeft}>
          <a
            id="get-start"
            href='/signup'
            style={styles.getStartedBanner.getStarted}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Get Started')}
          </a>
      </div>
      </div>
    </div>
  );
};
