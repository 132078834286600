import React  from 'react';
import PropTypes from 'prop-types';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';
import _ from 'lodash';

export const ELP = ({ event, sponsors = [] }) => {
  const location = event.location || {};
  const organization = event.organization || {};
  const { city, countryId, regionId, regionName, latitude, longitude } = location;
  const { email, name, url } = organization;

  const eventData = [].concat(
    !_.isEmpty(organization) ?
      [
        <Generic
        key='organizer'
        type='organizer'
        jsonldtype='Organization'
        schema={{
          email,
          name,
          url,
        }}
      />
    ] : [],
    !_.isEmpty(location) ?
      [
        <Generic
          key='location'
          type='location'
          jsonldtype='Place'
          schema={{
            name: [city, regionName].join(',')
          }}>
          {/*Address*/}
          <Generic
            key='address'
            type='address'
            jsonldtype='postalAddress'
            schema={{
              addressCountry: countryId,
              addressRegion: regionId,
              addressLocality: city,
            }}/>
          {/*Geo*/}
          <Generic
            key='geo'
            type='geo'
            jsonldtype='GeoCoordinates'
            schema={{
              latitude: latitude,
              longitude: longitude,
            }}/>
        </Generic>
      ] : [],
    !_.isEmpty(sponsors) ?
      [
        <GenericCollection
          key='sponsors'
          type='sponsor'>
          {
            sponsors.map(({ name, url }, ix) => (
              <Generic
                key={ix}
                jsonldtype='Organization'
                schema={{ name, url }}/>
            ))
          }
        </GenericCollection>
      ] : []
  );

  return (
    <JSONLD>
      {/*Event*/}
      <Generic
        type='event'
        jsonldtype='SportsEvent'
        schema={{
          name: event.name,
          startDate: event.startTime,
          endDate: event.endTime,
          description: event.description,
          image: event.imageUrl,
        }}>
        { eventData.map((_) => _) }
      </Generic>
    </JSONLD>
  );

};

ELP.propTypes = {
  event: PropTypes.object.isRequired,
  sponsors: PropTypes.array
};

ELP.defaultProps = {
  sponsors: []
};
