import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';

import { getToken } from '../utils/isLoggedIn';
import { fetchAthlete } from './AthleteStreams';

export async function setAthletePhoto(athleteId, dataURL) {
  const token = await getToken();
  const url = configs.ATHLINKS_API_HOST + '/Profile/api/logo';

  return new Promise((resolve, reject) => {
    FetchApi.doFetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          racerId: athleteId,
          data: dataURL
        })
      }
    ).subscribe(
      (msg) => {
        if (msg.errorMessage) {
          reject(msg.errorMessage);
        } else {
          fetchAthlete(athleteId);
          resolve(msg);
        }
      },
      (error) => {
        reject(error instanceof Error ? error.message : error);
      });
  });
}
