import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSearchParams } from './useSearchParams';

export type IPushHistory = ({ _page, _term, _pageSize, evid, ecid, divisionId }: any) => void;

export function usePushHistory({
  masterId,
  eventId,
  eventCourseId
}: {
  masterId: number
  eventId: number
  eventCourseId: number
}): IPushHistory {
  const history = useHistory();
  const { page, term, pageSize } = useSearchParams();

  const pushHistory = React.useCallback<IPushHistory>(({ _page, _term, _pageSize, evid, ecid, divisionId }) => {
    const base = new URL(window.location.href);

    // Path params:
    // '/event/:masterId/roster/Event/:eventId/Course/:courseId' OR
    // '/event/:masterId/roster/Event/:eventId/Course/:courseId/Division/:divisionId'
    const routeBase = ['event', masterId, 'roster']
    const route = [...routeBase, 'Event', evid || eventId, 'Course', ecid || eventCourseId];

    if (divisionId) {
      route.push('Division', divisionId);
    }
    base.pathname = '/' + route.join('/');

    const q = {
      page: (_page || page),
      term: typeof _term === 'string'
        ? _term
        : term,
      pageSize: (_pageSize || pageSize),
    };
    Object.keys(q).map(x => x as keyof typeof q).forEach(x => {
      // console.log('Setting', x, q[x])
      if (q[x] && String(q[x]).trim().length) {
        base.searchParams.set(x, `${q[x]}`.trim());
      }
      else {
        base.searchParams.delete(x);
      }
    });
    console.log('setting url', `${base.pathname}${base.search}`)
    history.push(`${base.pathname}${base.search}`);
  }, [masterId, eventId, eventCourseId, history, page, pageSize, term]);

  return pushHistory
}
