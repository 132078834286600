
const FilterIconMedium = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.54157 4.67467C5.2249 6.83301 8.33324 10.833 8.33324 10.833V15.833C8.33324 16.2913 8.70824 16.6663 9.16657 16.6663H10.8332C11.2916 16.6663 11.6666 16.2913 11.6666 15.833V10.833C11.6666 10.833 14.7666 6.83301 16.4499 4.67467C16.8749 4.12467 16.4832 3.33301 15.7916 3.33301H4.1999C3.50824 3.33301 3.11657 4.12467 3.54157 4.67467Z" fill="#0077b3"/>
  </svg>
)

const FilterIconBig = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.25018 5.61C6.27018 8.2 10.0002 13 10.0002 13V19C10.0002 19.55 10.4502 20 11.0002 20H13.0002C13.5502 20 14.0002 19.55 14.0002 19V13C14.0002 13 17.7202 8.2 19.7402 5.61C20.2502 4.95 19.7802 4 18.9502 4H5.04018C4.21018 4 3.74018 4.95 4.25018 5.61Z" fill="#757575"/>
  </svg>
)

export { FilterIconMedium, FilterIconBig }


