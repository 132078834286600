import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { getToken } from '../utils/isLoggedIn';

export async function createEvent(eventData) {
  const token = await getToken();
  const url = configs.ATHLINKS_API_HOST + `/Events/Api/CreatePending`;

  return new Promise((resolve, reject) => {
    FetchApi.doFetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(eventData)
      }
    ).subscribe(
      (msg) => {
        if (msg.errorMessage) {
          const modelErrors = (msg.result || {}).modelErrors || [];
          reject(modelErrors.length > 0
            ? modelErrors.map((err) => err.error)
            : msg.errorMessage
          );
        } else {
          resolve(msg);
        }
      },
      (error) => {
        reject(error instanceof Error ? error.message : error);
      });
  });
}
