import React, {Component, cloneElement} from 'react';
import PropTypes from 'prop-types';
import { withTranslation , Trans} from 'react-i18next';
import {
  GeoSuggestInput,
  googleApiKey
} from '../../components/shared/GeoSuggestInput';
import { Slider } from './Slider';
import FormControl from '@material-ui/core/FormControl';
import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox as CheckboxMaterial,
} from '@material-ui/core';
import { FormattedNumber } from 'react-intl';
import { colors, textStyles } from '../../shared/styles';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { configs } from '../../configs';
import { styles } from './styles';
import _ from 'lodash';

// export const ourTheme = getMuiTheme({
//   palette: {
//     primary1Color: colors.darkBlue,
//     primary2Color: colors.blue,
//     alternateTextColor: colors.white,
//     textColor: colors.textColor,
//     canvasColor: colors.white,
//   },
//   datePicker: {
//     color: colors.blue,
//   },
// });


/**
 * Renders Checkbox component
 */
export class CheckBox extends Component {

  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object,
    checked: PropTypes.bool,
  };

  static defaultProps = {
    checked: false
  };

  state = {
    checked: this.props.checked
  };

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.checked !== nextProps.checked){
      this.setState({checked: nextProps.checked});
    }
  }

  /**
   * Will set internal state and call on change callback
   * @param {boolean} checked
   */
  onChange = (checked) =>
    this.setState({checked}, () => this.props.onChange(checked));

  render() {
    const {
      label,
      size = 18,
      color = colors.blue,
      style = {},
      title,
    } = this.props;

    const {checked} = this.state;

    const styles = {
      label: {
        ...textStyles.body1Grey,
      },
      icon: {
        marginRight: 8,
        width: size,
        height: size,
        fill: color,
      }
    };

    return (
      <div style={style}>
        <CheckboxMaterial
          id={title}
          checked={checked}
          label={label}
          labelStyle={styles.label}
          iconStyle={styles.icon}
          disableTouchRipple={true}
          onCheck={(event, isChecked) => this.onChange(isChecked)}/>
      </div>
    );
  }
}



// export const DatePicker = ({label, value, labelColor = colors.gray, onChange, onClear, minDate, isMobile, ...otherProps}) => (
//
//   <div style={styles.datePicker.container}>
//     <MuiThemeProvider muiTheme={ourTheme}>
//       <MaterialDatePicker
//         className='date-picker'
//         locale='en-US'
//         floatingLabelText={label}
//         floatingLabelStyle={{fontFamily:fonts.default.fontFamily, color: labelColor}}
//         value={value}
//         minDate={minDate}
//         onChange={onChange}
//         textFieldStyle={{fontFamily:fonts.default.fontFamily, color:colors.textColor}}
//         dialogContainerStyle={{fontFamily:fonts.default.fontFamily}}
//         container={isMobile ? 'dialog' :  'inline'}
//         mode={isMobile ? 'portrait' : 'landscape'}
//         {...otherProps}
//       />
//     </MuiThemeProvider>
//     <div
//       onClick={onClear}
//       style={styles.datePicker.wrap(value)}
//     >
//       <CloseIcon
//         fill={colors.textColor}
//         style={styles.datePicker.closeIcon}
//       />
//     </div>
//   </div>
// );
//
// DatePicker.propTypes = {
//   label: PropTypes.string,
//   value: PropTypes.oneOfType([
//     PropTypes.instanceOf(Date),
//     PropTypes.string
//   ]),
//   onChange: PropTypes.func
// };
//
// DatePicker.defaultProps = {
//   onChange: () => {},
// };

// class DateRangeComp extends Component {
//
//   static propTypes = {
//     defaultValue: PropTypes.shape({
//       from: PropTypes.string,
//       to: PropTypes.string
//     }),
//     onChange: PropTypes.func,
//     expanded: PropTypes.bool,
//     t: PropTypes.func
//   };
//
//   static defaultProps = {
//     defaultValue: {from: null, to: null},
//     onChange: () => {},
//     expanded: true,
//   };
//
//   state = {
//     value: this.props.defaultValue || {}
//   };
//
//   onChange = (type, value = {}) => {
//     const newValue = (value instanceof Date) ?
//       `${value.getFullYear()}-${value.getMonth()+1}-${value.getDate()}`:
//       value;
//
//     const newState = {...this.state.value, [type]: newValue};
//
//     this.setState({value: newState},
//       () => this.props.onChange(this.state.value));
//   };
//
//
//   createDate(dateString) {
//     // This function is mainly used for IE
//     // dateString format: 'YYYY-(M)M-(D)D'
//
//     if (typeof dateString === 'string') {
//       let dateObject = new Date();
//       const dateParts = dateString.split('-');
//
//       if (dateParts.length === 3) {
//         dateObject.setUTCFullYear(
//           parseInt(dateParts[0], 10),
//           parseInt(dateParts[1]-1, 10),
//           parseInt(dateParts[2], 10)
//         );
//
//         return dateObject;
//       }
//     }
//
//     return null;
//   }
//
//   render() {
//     const {
//       t
//     } = this.props;
//
//     const { value: {
//       from,
//       to
//     }} = this.state;
//
//     // Convert date strings to object for datePicker
//     const fromVal = this.createDate(from);
//     const toVal = this.createDate(to);
//
//     const fromProps = {
//       id: 'date-from',
//       value: fromVal || null,
//       label: t('From'),
//       onChange: (e, value) => this.onChange('from', value),
//       onClear: () => this.onChange('from', null),
//       isMobile: this.props.isMobile
//     };
//
//     if (toVal) fromProps.maxDate = toVal;
//
//     const toProps = {
//       id: 'date-to',
//       value: toVal || null,
//       minDate: fromVal,
//       label: _.upperFirst(t('to')),
//       onChange: (e, value) => this.onChange('to', value),
//       onClear: () => this.onChange('to', null),
//       isMobile: this.props.isMobile
//     };
//
//     const format = (date) => {
//       return this.props.intl.formatDate(date);
//     };
//
//     return (
//       <div style={styles.dateRange.container(this.props.expanded)} id="date-picker-filter">
//         <DatePicker formatDate={format} {...fromProps} />
//         <DatePicker formatDate={format} {...toProps} />
//       </div>
//     );
//   }
//
// }
//
// export const DateRange = withTranslation()(injectIntl(DateRangeComp));

/**
 * This is wrapper component for elements that use checkboxes.
 */
export class WithCheckbox extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.object,
    checkBoxStyle: PropTypes.object,
  };

  static defaultProps = {
    onChange: () => {
    },
    value: {
      enabled: true
    }
  };

  state = this.props.value;

  componentDidMount(){
    this.setState({enabled: this.props.value.enabled, value: this.props.value.value})
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.value !== nextProps.value){
      this.setState({enabled: nextProps.value.enabled, value: nextProps.value.value})
    }
  }

  check = (enabled) => {
    this.setState({enabled},
      () => this.props.onChange(enabled ? this.state : {...this.state, value: this.props.value.value}));
  };

  select = (value) => this.setState({value},
    () => this.props.onChange(this.state));

  render() {
    const {
      label,
      id,
      children,
      checkBoxStyle,
    } = this.props;

    const {enabled, value} = this.state;
    return (
      <div id={id} className={'birthDateFilterCheckBox'}>
        <CheckBox
          label={label}
          onChange={this.check}
          checked={enabled}
          style={{marginBottom: 15, ...checkBoxStyle}}
        />
        <div style={styles.withCheckbox.toggleStyle(enabled)}>
          {cloneElement(children,{defaultValue: value, onChange: this.select})}
        </div>
      </div>
    );
  }

}

/**
 * Checkbox and range element.
 */
export const CheckBoxRange = ({min, max, pushable, ...rest}) => (
  <WithCheckbox {...rest}>
    <Slider
      id='age-range'
      min={min}
      max={max}
      pushable={pushable}
      range={true}/>
  </WithCheckbox>
);
CheckBoxRange.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  pushable: PropTypes.number,
  ...WithCheckbox.propTypes
};
CheckBoxRange.defaultProps = {
  min: 0,
  max: 100,
  pushable: 15,
  ...WithCheckbox.defaultProps
};

/**
 * Checkbox and option element. It uses multi select option element
 */
export const CheckBoxOption = ({options, isMultiSelect, ...rest}) => (
  <WithCheckbox {...rest} style={{overflowY: 'scroll'}}>
    <OptionButtons
      isMultiSelect={isMultiSelect}
      options={options}/>
  </WithCheckbox>
);
CheckBoxOption.propTypes = {
  options: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool,
  ...WithCheckbox.propTypes
};
CheckBoxOption.defaultProps = WithCheckbox.defaultProps;

/**
 * Wrapper for html input number element. Can accept validator method
 */
export class Number extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    validator: PropTypes.func,
    style: PropTypes.object,
    defaultValue: PropTypes.number
  };

  static defaultProps = {
    onChange: () => {},
    validator: () => null,
    style: {},
    defaultValue: 0
  };

  state = {
    errorMsg: null,
    value: this.props.defaultValue
  };

  UNSAFE_componentWillReceiveProps(nextProps){
    if(this.props.defaultValue !== nextProps.defaultValue){
      this.setState({value: nextProps.defaultValue});
    }
  }

  /**
   * Check if number value is between min and max inclusive.
   * @return {boolean}
   */
  isInRange() {
    const {value} = this.state;
    const {min, max} = this.props;
    return value >= min && value <= max;
  }

  /**
   * Call validator func from props.
   * If validation passes and if value is "in range" then call onChange
   * from props
   *
   * @param {number} value
   */
  onChange = (value) => {
    const {validator, min, max} = this.props;
    const errorMsg = validator(value, min, max);
    this.setState({value, errorMsg}, !errorMsg ?
      () => this.isInRange() && this.props.onChange(value) : () => {});
  };

  render() {
    const {id, min, max, style} = this.props;
    const {errorMsg, value} = this.state;
    return (
      <div style={style}>
        <TextField
          style={styles.numberInput}
          onChange={(ev, value) => this.onChange(parseInt(value))}
          type="number"
          id={id}
          name='birth-date-filter'
          min={min}
          max={max}
          underlineFocusStyle={{borderColor: colors.blue}}
          value={value}
          errorText={errorMsg}
        />
      </div>
    );
  }
}

/**
 * Checkbox with number input.
 */
export const CheckBoxNumber = (props) => {
  const {id, min, max, validator} = props;
  return (
    <WithCheckbox {...props}>
      <Number
        id={id}
        min={min}
        max={max}
        validator={validator}
        style={styles.checkBoxNumber}/>
    </WithCheckbox>
  );
};
CheckBoxNumber.propTypes = {
  ...Number.propTypes,
  ...WithCheckbox.propTypes
};
CheckBoxNumber.defaultProps = {
  ...Number.defaultProps,
  ...WithCheckbox.defaultProps
};

/**
 * Check box with location and range.
 */
export const CheckBoxLocation = (props) =>  (
  <WithCheckbox {...props}>
    <LocationElement
      shouldAutoPickLoc={props.shouldAutoPickLoc}
      wantsRange={props.wantsRange}
      defaultOptions={props.defaultOptions || []}
      onSearchingCurrentLocation={props.onSearchingCurrentLocation}
      expanded={props.value.enabled}/>
  </WithCheckbox>
);
CheckBoxLocation.propTypes = {
  ...WithCheckbox.propTypes,
  shouldAutoPickLoc: PropTypes.bool
};
CheckBoxLocation.defaultProps = WithCheckbox.defaultProps;

// export const CheckBoxDateRange = (props) => (
//   <WithCheckbox {...props}>
//     <DateRange expanded={props.value.enabled} isMobile={props.isMobile}/>
//   </WithCheckbox>
// );

/**
 * Checkbox and option element. It uses multi select option element
 */
export const CheckBoxMultiSelect = ({options, ...rest}) => (
  <WithCheckbox {...rest} style={{overflowY: 'scroll'}}>
    <MultiSelect options={options}/>
  </WithCheckbox>
);
CheckBoxMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  ...WithCheckbox.propTypes
};
CheckBoxMultiSelect.defaultProps = WithCheckbox.defaultProps;

/**
 * Multi-select location list for unclaimed results search
 */
export class MultiSelect extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })).isRequired,
    onChange: PropTypes.func,
    defaultValue: PropTypes.array,
    value: PropTypes.array
  };

  static defaultProps = {
    onChange: () => {},
    defaultValue: []
  };

  state = {
    values: this.props.defaultValue
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && nextProps.value !== this.state.selected) {
      this.setState({
        selected: nextProps.value
      })
    }

  }

  /**
   * Adds or remove element from selected elements list
   * Also call onChange from props
   *
   * @param {*} id
   */
  onClick = (id) => {
    let {values} = this.state;
    if(!values.includes(id)) values.push(id);
    else {
      values.splice(values.indexOf(id), 1);
    }
    this.setState({values}, () => this.props.onChange(values));
  };

  render() {
    const {options} = this.props;
    const {values} = this.state;

    return (
      <div style={styles.multiSelect.container} id={'locationMultiselect'}>
        {
          options.map(({value, label}, index) =>
            <div
              className={'locationMultiselectItem'}
              key={index}
              onClick={() => this.onClick(value)}
              style={{
                ...styles.multiSelect.buttonStyle,
                ...(values.includes(value) ? styles.multiSelect.blueStyle : styles.multiSelect.whiteStyle)
              }}>
              {label}
            </div>)
        }
      </div>
    );
  }

}

/**
 * Button group with multi select or single select options.
 * Looks like buttons array
 * Single select behaves like radio group
 * Multi select behaves like checkbox group
 */
export class OptionButtons extends Component {

  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })).isRequired,
    onChange: PropTypes.func,
    isMultiSelect: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([
      PropTypes.string, PropTypes.arrayOf(PropTypes.string)
    ]),
    value: PropTypes.oneOfType([
      PropTypes.string, PropTypes.arrayOf(PropTypes.string)
    ])
  };

  static defaultProps = {
    isMultiSelect: false,
    onChange: () => {
    }
  };

  state = {
    selected: this.props.value !== undefined ? this.props.value : this.props.defaultValue
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== undefined && nextProps.value !== this.state.selected) {
      this.setState({
        selected: nextProps.value
      })
    }
  }

  /**
   * Apply changes and update internal state. Also call callback function.
   *
   * @param {string} value
   */
  onSelect(value) {
    const {isMultiSelect, onChange} = this.props;
    const {selected} = this.state;
    let newSelected = null;
    if (!isMultiSelect && value !== selected) {
      newSelected = value;
    }
    else {
      newSelected = selected;
      if (selected.indexOf(value) >= 0) {
        newSelected = newSelected.filter((item) => item !== value);
      }
      else {
        newSelected.push(value);
      }
    }
    this.setState({selected: newSelected}, () => onChange(newSelected))
  }

  /**
   * Calculates button type. Right now only blue and white are supported
   * Blue if option is selected, white if not
   *
   * @param {string} value
   * @return {string} 'blue' or 'white'
   */
  getButtonType(value) {
    const {isMultiSelect} = this.props;
    const {selected} = this.state;
    return (isMultiSelect && selected.indexOf(value) >= 0) || value === selected ?
      'blue' : 'white'
  }

  render() {
    const {options} = this.props;
    return (
      <div>
        {
          options.map(({value, label}, index) =>
            <Button
              id={label}
              key={index}
              label={label}
              type={this.getButtonType(value)}
              onClick={this.onSelect.bind(this, value)}/>)
        }
      </div>
    );
  }
}

/**
 * Basic clickable button element.
 * Will not trigger onClick if disabled property is true
 *
 * @param {string} label
 * @param {object} style
 * @param {string} type
 * @param {function} onClick
 * @param {boolean} disabled
 * @return {XML}
 * @constructor
 */
export const Button = ({label, style = {}, type, onClick, disabled, id}) => {
  return (
    <div
      onClick={disabled ? () => {
      } : onClick}
      style={{
        ...styles.button.defaultStyles,
        ...(styles.button.types[type] || styles.button.types['white']),
        ...style,
        ...(disabled ? styles.button.types.disabled : {})
      }}
      id={id}
    >
      {label}
    </div>
  );
};

class LocationElementComponent extends Component {

  static propTypes = {
    defaultValue: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string,
    wantsRange: PropTypes.bool,
    defaultOptions: PropTypes.array,
    expanded: PropTypes.bool,
    shouldAutoPickLoc: PropTypes.bool,
    onSearchingCurrentLocation: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    onChange: () => {
    },
    onSearchingCurrentLocation: () => {},
    wantsRange: false,
    defaultOptions: [],
    expanded: true,
    shouldAutoPickLoc: false
  };

  state = {
    value: this.props.defaultValue,
  };

  onSelect = (location) => {
    let {value} = this.state;
    value.location = location;
    this.setState({value}, () => this.props.onChange(value));
  };

  onRangeChange = (range) => {
    let {value} = this.state;
    value.range = range;
    this.setState({value}, () => this.props.onChange(value));
  };

  onChange = (location) => {
    let { value } = this.state;
    value.location = location;
    this.setState({ value });
  };

  render() {
    const {value} = this.state;
    const {location, range} = value || {};
    const {
      label,
      wantsRange,
      defaultOptions,
      expanded,
      shouldAutoPickLoc,
      t
    } = this.props;

    return (
      <div>
        <div style={styles.locationElement.containerStyle(expanded, wantsRange)}>
          {renderLabel(label)}
          <GeoSuggestInput
            inputProps={{
              id: 'location-filters'
            }}
            term={typeof(location) === 'object' ? location.description : location}
            width='100%'
            useCurrentLocationOption={true}
            onChange={({target: {value}}) => this.onChange(value)}
            onPicked={(location) => this.onSelect(location)}
            onPickedCurrentLocation={({label}) => this.onSelect(label)}
            onSearchingCurrentLocation={this.props.onSearchingCurrentLocation}
            inputStyle={styles.locationElement.inputStyle}
            googleApiKey={googleApiKey}
            defaultOptions={defaultOptions}
            shouldAutoPickLoc={shouldAutoPickLoc}
          />
          <img src={`${configs.cdnUrl}/images/powered_by_google_on_white.png`} alt={''} />
        </div>
        {
          wantsRange && location &&
          <Slider
            id='location-range'
            min={0}
            max={100}
            defaultValue={range}
            onChange={this.onRangeChange}
            label={t('Range')}
            startLabel='0'
            unit='mi'
          />
        }
      </div>
    );
  }
}

export const LocationElement = withTranslation()(LocationElementComponent);

export const DropDown = ({id, label, value, disabled, onChange, options, className, inputStyle}) => {
  const ITEM_HEIGHT = 36;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8
      },
    },
  };

  return (
    <FormControl fullWidth className={className}>
      <InputLabel style={inputStyle} id={`${id}-label`}>{label}</InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
        MenuProps={MenuProps}
      >
        {
          options.map(({value: v, label, text}, index) =>
            <MenuItem
              key={`${v}-${index}`}
              value={v}
              id={`option-${index}`}
            >
              {label || text}
            </MenuItem>
          )
        }
      </Select>
    </FormControl>
  );
};

export function renderLabel(text) {
  if (!text) {
    return null;
  }

  return (
    <h6 style={styles.renderLabel}>
      {text}
    </h6>
  );
}

export class SearchField extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    darkScheme: PropTypes.bool,
    disableUnderline: PropTypes.bool,
    isMobile: PropTypes.bool
  };

  static defaultProps = {
    onChange: () => { },
    style: {},
    value: '',
    disableUnderline: false
  };

  /**
   * @TODO Check if we need this. If not then this component can be stateless
   */
  shouldComponentUpdate({value, placeholder, forceFocus}) {
    return value !== this.props.value
      || placeholder !== this.props.placeholder || forceFocus !== this.props.forceFocus ;
  }

  render() {
    const {
      placeholder,
      value,
      id,
      onChange,
      forceFocus,
      inputStyle,
      style,
      darkScheme = false,
      disableUnderline,
      iconTop,
      ...restProps
    } = this.props;

    const focusInputField = () => setTimeout(() => {this.txtField.focus()}, 0);
    if(forceFocus) {
      focusInputField();
    }

    return (
      <div style={styles.searchField.container}>
        <TextField
          style={styles.searchField.textField(style)}
          id={id}
          className={`cs-search-box ${darkScheme ? 'darkVersion' : ''}`}
          autoComplete="off"
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          ref={(element) => this.txtField = element}
          InputProps={{
            disableUnderline
          }}
          {...restProps}/>
        <img
          style={styles.searchField.img(iconTop)}
          src={`${configs.bragiUrl}/svg/search-icon${darkScheme ? '-white' : ''}.svg`}
          alt={''}
        />
      </div>
    );
  }
}

class MessageComponent extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    onSubmit: () => {
    },
    value: ''
  };

  state = {
    value: this.props.value
  };

  clear = () => this.setState({value: this.props.value});

  send = () => {
    trackGoogleEvent('Social', 'Click', 'Sent Message');
    this.props.onSubmit(this.state);
    this.setState({value: ''});
  };

  render() {
    const {
      t,
      id,
      placeholder = t('Type Message...'),
      title = t('Message'),
      isMobile
    } = this.props;
    const {value} = this.state;

    const i18nPlaceholder = placeholder ? placeholder : t('Type Message...');

    return (
      <div>
        <div style={styles.message.wrapper(isMobile)} id="message-input">
          <div style={styles.message.title}>{title}</div>
          <TextField
            id={id}
            rows={1}
            fullWidth={true}
            value={value}
            placeholder={i18nPlaceholder}
            onChange={(event, value) => this.setState({value})}
            InputProps={{
              multiline: true
            }}
          />
        </div>

        <div style={styles.message.buttonContainer}>
          <button
            id="clear-message"
            onClick={this.clear}
            style={{...styles.message.button, ...styles.message.buttonClear(isMobile)}}
          >
            {t('clear')}
          </button>
          <button
            disabled={value === '' ? 'disabled' : null}
            id="send-message"
            onClick={this.send}
            style={{...styles.message.button, ...styles.message.buttonSend(isMobile)}}
          >
            {t('send')}
          </button>
        </div>

      </div>
    );
  }
}

export const Message = withTranslation()(MessageComponent);

/**
 * Friends type toggle button element. Behaves like radio button
 */
class AthletesTypeComponent extends Component {

  static propTypes = {
    defaultValue: PropTypes.oneOf(['all', 'following']),
    onChange: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    defaultValue: 'all',
    onChange: () => {
    }
  };

  UNSAFE_componentWillReceiveProps({defaultValue}){
    if(this.props.defaultValue !== defaultValue) {
      this.setState({value: defaultValue});
    }
  }

  state = {
    value: this.props.defaultValue
  };

  onClick = (type) => this.setState({value: type},
    () => {
      if(this.props.location === 'start-list') {
        trackGoogleEvent('Social', 'Toggle', 'All / Following');
      } else {
        trackGoogleEvent('Social', 'Click', 'Following Toggle');
      }
      this.props.onChange(this.state.value);
    });

  render() {
    const {
      isMobile,
      t
    } = this.props;

    const {value} = this.state;

    const isAll = value === 'all';
    const isFollowing = value === 'following';

    return (
      <div style={styles.athletesType.container(isMobile)}>
        <button
          onClick={() => this.onClick('all')}
          style={{
            borderRadius: '3px 0px 0px 3px',
            ...(isAll ? styles.athletesType.activeButtonStyle(isMobile) : styles.athletesType.unActiveButtonStyle(isMobile))
          }}
        >
          {t('All')}
        </button>
        <button
          id='following-button-tab'
          onClick={() => this.onClick('following')}
          style={{
            borderRadius: '0px 3px 3px 0px',
            ...(isFollowing ? styles.athletesType.activeButtonStyle(isMobile) : styles.athletesType.unActiveButtonStyle(isMobile))
          }}
        >
          {t('Following')}
        </button>
      </div>
    );
  }

}

export const AthletesType = withTranslation()(AthletesTypeComponent);

const SearchPageHeaderComponent = (
  {
    filterStyles,
    processing,
    total,
    searchTerm,
    message,
    hasResults,
    title,
    t
  }) => {

  const showNoResults = hasResults.isLoading === false
    && !hasResults
    && searchTerm !== ''
    && !processing;

  return(
    <div style={{color:colors.textColor}}>
      <h1 id='title-of-search' style={textStyles.head1Grey}>
        {title}
      </h1>
      { hasResults && //TODO: fix this formatting
        <div style={filterStyles.summaryRow}>
          <div style={filterStyles.summaryToggleItem}>
            <div id='search-message'style={filterStyles.spanStyle(processing)}>
              <Trans count={total||0}>
                <FormattedNumber value={total||0} /> results
              </Trans>
            </div>
          </div>
          <div style={filterStyles.summaryToggleItem}>
            <div id='searching'style={filterStyles.spanStyle(!processing)}>
              {t('Searching...')}
            </div>
          </div>
        </div>
      }
      {
        _.size(searchTerm) < 4 && !hasResults &&
        <div id='no-search-term' style={filterStyles.spanStyle(processing)}>
          {message}
        </div>
      }
        <div style={filterStyles.noResults(showNoResults)}>
          {t('There are no results')}
        </div>
    </div>
  );
};

export const SearchPageHeader = withTranslation()(SearchPageHeaderComponent);

SearchPageHeader.propTypes = {
  message: PropTypes.string,
  processing: PropTypes.bool,
  isMobile: PropTypes.bool,
  filterStyles: PropTypes.object,
  styles: PropTypes.object,
  total: PropTypes.number,
  searchTerm: PropTypes.string,
  title: PropTypes.string,
  filters: PropTypes.object,
  hasResults: PropTypes.bool,
  showButtonToAddNew: PropTypes.bool,
  selectedLocations: PropTypes.array
};

SearchPageHeader.defaultProps = {
  hasResults: {isLoading : true}
};

export function renderOption({value, label, useMaterialUi, disabled, testClass}) {
  if (useMaterialUi) {
    return (
      <MenuItem key={value} value={value} primaryText={label} disabled={disabled} className={testClass}/>
    );
  }  else {
    return (
      <option key={value} value={value} disabled={disabled}>
        {label}
      </option>
    );
  }
}
