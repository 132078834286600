export const styles = {
  fullPage: {
    marginTop: 60
  },
  contentOnly: {
    padding: 15
  },
  privacyDownload: {
    display: 'inline-block',
    padding: '9px 11px 6px',
    fontSize: '12px',
    fontFamily: 'ProximaNovaSemiBold, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(22, 169, 225)',
    borderRadius: '2px'
  },
  tableCCPA: {
    textAlign: 'left',
    padding: '2rem',
    cat: {
      width: '20rem',
      padding: '1rem',
    },
    def: {
      padding: '1rem',
    }
  }
};
