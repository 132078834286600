import React  from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';

export const StandardHeaderTags = ({title = '', description = '', keywords = null}) => {

  const metaTags = [
    { name:'description', content: description },
    { name:'keywords', content: keywords },
  ];

  return (
    <MetaTags>
      <title>{title}</title>
      {
        metaTags.filter((item) => item.content).map((item) => {
          return (
            <meta
              id={item.name}
              name={item.name}
              content={item.content}
              key={item.name}
            />
          );
        })
      }
    </MetaTags>
  )
};

StandardHeaderTags.propTypes = {
  description: PropTypes.string,
};
