import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import {PersonalRecords, LoadingRecord} from './PersonalRecords';
import {Suggestions} from '../shared/Suggestions';
import {Banner, AthlinksAds} from '../shared/Banner';
import {bannerStyle} from '../../shared/styles';
import {prStyles, statStyles, followerStyles} from './styles';
import {isLoggedIn} from '../../utils/isLoggedIn';

AthlinksAds.init();

export const Statistics = ({distanceSummary, athleteId, suggested = {}, isMobile, fetching, t}) => {

  const adunits = AthlinksAds.adsForPage('Profile');

  return (
    <div className='row'>
      <div className={isMobile ? 'col-12 p-0' : 'col-8'}>
        <div style={statStyles.statPage(isMobile)}>
          <h2 style={statStyles.title(isMobile)} id="statistics-title">
            {t('Personal Records')}
          </h2>
          <PersonalRecords
            distanceSummary={distanceSummary}
            type="mainTab"
            isMobile={isMobile}
            fetching={fetching}
            t={t}
          />
        </div>
      </div>

      <div className={isMobile ? 'col-12 p-0' : 'col-4'}>
        { isLoggedIn() &&
          <Suggestions
            isMobile={isMobile}
            athleteId={athleteId}
            {...suggested}
          />
        }
        {adunits.map((x) => {
          return (
            <div key={x.adKey} style={bannerStyle(isMobile)}>
              <Banner
                data-ad={x.adKey}
                adKey={x.adKey}
                divId={x.divId}
                style={followerStyles.bannerStyle}/>
            </div>
          );
        })}
      </div>

    </div>
  )
};

Statistics.propTypes = {
  resultsSummary: PropTypes.object,
  distanceSummary: PropTypes.array,
  isMobile: PropTypes.bool,
  t: PropTypes.func
};

export const StatisticsSummary = ({ distanceSummary, TotalRaces, MilesRaced, direction, isMobile, fetching, t }) => {
  const topRanking = distanceSummary.reduce((sum, distance) => {
    return sum + distance.Rating.RatingO;
  }, 0);

  const topRankingAll = topRanking/distanceSummary.length;
  const className = direction === 'horizontal' ? 'col-4' : 'col-12';
  const statisticsTitle = isMobile ? t('Race Statistics')
    : t('Statistics');
  const totalMiles = MilesRaced ? Math.round(MilesRaced * 10) / 10 : '0';

  return (
    <div>
      <div style={prStyles.sectionTitle}>
        {statisticsTitle}
        <span style={prStyles.sectionTitleLoader(fetching)}/>
      </div>

      <div className="row" style={prStyles.recordRowContainer(true)}>

        <div className={className} style={prStyles.recordContainer(true)}>
          <div style={prStyles.title}>{t('Races')}</div>
          <div style={prStyles.bestTicks}>
            {TotalRaces && <FormattedNumber value={TotalRaces} />}
          </div>
        </div>

        <div className={className} style={prStyles.recordContainer(true)}>
          <div style={prStyles.title}>{t('Miles')}</div>
          <div style={prStyles.bestTicks}>
            {totalMiles && <FormattedNumber value={totalMiles} />}
          </div>
        </div>

        <div className={className} style={prStyles.recordContainer(true)}>
          <div style={prStyles.title}>{t('Athlinks Rank')}</div>
          <div style={prStyles.bestTicks}>
            {!isNaN(topRankingAll)
              ? t('Top {{number}}%', {number: Math.round(topRankingAll)})
              : t('No Rank')}
          </div>
        </div>

        {
          // Loading placeholder
        }
        <div style={prStyles.recordContainerLoading(fetching)}>
          <LoadingRecord size={className} isHeader={true} />
          <LoadingRecord size={className} isHeader={true} />
          <LoadingRecord size={className} isHeader={true} />
        </div>

      </div>
    </div>
  );
};

StatisticsSummary.propTypes = {
  TotalRaces: PropTypes.number,
  MilesRaced: PropTypes.number,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  isMobile: PropTypes.bool,
  distanceSummary: PropTypes.array,
  t: PropTypes.func
};

StatisticsSummary.defaultProps = {
  direction: 'vertical'
};
