import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DocTypes from './DocTypes';
import { NotFound } from '../notFound/NotFound'
import { PageTemplate } from '../home/PageTemplate';

const DocumentPageComponent = ({ docType, isMobile, displayType, t }) => {
  const Doc = DocTypes[docType];
  const contentOnly = displayType === 'content';

  if (contentOnly) return (
    <div className="container">
      { Doc ? <Doc isContentOnly={true} t={t} /> : <NotFound/> }
    </div>
  );
  else return (
    <PageTemplate
      unifiedSearchMode={true}
      headerIsFixed={true}
      paddingTop={0}
      bannerTopOffset={50}
      isMobile={isMobile}>

      <div className="container">
        { Doc ? <Doc t={t} /> : <NotFound/> }
      </div>
    </PageTemplate>
  );
};

export const DocumentPage = withTranslation()(DocumentPageComponent);

DocumentPage.propTypes = {
  docType: PropTypes.oneOf([
    'about',
    'advertise',
    'ccpa',
    'mhmd',
    'privacy',
    'terms',
    'accessibility',
    'gdpr',
  ]),
  displayType: PropTypes.string,
  isMobile: PropTypes.bool,
  t: PropTypes.func
};

DocumentPage.defaultProps = {
  displayType: ''
};
