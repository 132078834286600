import { ModalActionTypes as Actions } from './types'

export const showModal = ({ modalProps, modalType, pages }) => ({
    type: Actions.SHOW_MODAL,
    modalProps,
    modalType,
    pages
})

export const hideModal = () => ({
    type: Actions.HIDE_MODAL
})

export const goToPage = (page) => ({
    page,
    type: Actions.MODAL_CHANGE_PAGE
})
