import React, {cloneElement} from 'react'
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {AnimatedHeader} from '../../components/home/AnimatedHeader';
import {HomePageHeader, useSiteNotice} from './HomePageHeader';
import {Footer} from '../../components/footer/Footer';
import {pageTemplate as _s} from './styles'
import {MobileHeader} from '../../components/shared/MobileHeader';
import { CookiesAlert } from '../../components/shared/CookiesAlert';

const headerHeightWithSearchTab = 55;
const headerHeightMobile = 25;
const liveEventBanner = 30;

const showMobileNav = () => window.innerWidth < 800;

const PageTemplateComponent = (props) => {
  const siteNotice = useSiteNotice()
  const renderHeader = (headerProps) => {
    const {
      athlete,
      filters,
      hasLiveBanner,
      history,
      isLive,
      isProfile,
      liveEventURL,
      onHeaderFocusChange,
      onLocationInput,
      onSearchInput,
      searchTab,
      searchTerm,
      shouldHideLocationInput,
      showHeaderFilters,
      showSearchTab,
      t,
      unifiedSearchMode
    } = props;

    return (
      <HomePageHeader
        athlete={ athlete? athlete.athlete : null}
        filters={filters}
        hasLiveBanner={hasLiveBanner}
        history={history}
        isLive={isLive}
        isMobile={showMobileNav()}
        isProfile={isProfile}
        liveEventURL={liveEventURL ? liveEventURL : ''}
        onFocusChange={onHeaderFocusChange}
        onLocationInput={onLocationInput}
        onSearchInput={onSearchInput}
        searchTab={searchTab}
        searchTerm={searchTerm}
        shouldHideLocationInput={shouldHideLocationInput}
        showHeaderFilters={showHeaderFilters}
        showSearchTab={showSearchTab}
        t={t}
        unifiedSearchMode={unifiedSearchMode}
        {...headerProps}
      />
    );
  };

  const renderSearchBarHeader = () => renderHeader({showSearchBar: true});

  const {
    alwaysShowSearchBar,
    athlete,
    children,
    filters,
    hasLiveBanner,
    headerIsFixed,
    history,
    isLive,
    isMobile,
    isProfile,
    liveEventURL,
    neverShowSearchBar,
    noFooter,
    onHeaderFocusChange,
    onHomePage = false,
    onSearchInput,
    paddingTop,
    searchTab,
    showSearchTab,
    style = {},
    unifiedSearchMode
  } = props;

  const baseHeaderHeight = isMobile ? headerHeightMobile : headerHeightWithSearchTab;
  const headerHeight = baseHeaderHeight + (hasLiveBanner ? liveEventBanner : 0);
  const containerPaddingTop = (paddingTop !== undefined ? paddingTop : headerHeight);

  return (
    <section
      style={{
        ..._s.container,
        ...style,
        paddingTop: containerPaddingTop
      }}>
      {
        !alwaysShowSearchBar && !neverShowSearchBar && !showMobileNav() &&
        <div>
          <AnimatedHeader
            showSlideoutOverride={headerIsFixed}
            height={headerHeight + 5}
            hasLiveBanner={hasLiveBanner}
            renderNormal={renderHeader}
            renderSlideout={renderSearchBarHeader}
            isMobile={showMobileNav()}
            liveEventURL={liveEventURL ? liveEventURL : ''}
          />
        </div>
      }
      {
        showMobileNav() &&
        <MobileHeader
          athlete={ athlete? athlete.athlete : null}
          filters={filters}
          hasLiveBanner={hasLiveBanner}
          history={history}
          isLive={isLive}
          isMobile={isMobile}
          isProfile={isProfile}
          liveEventURL={liveEventURL ? liveEventURL : ''}
          onFocusChange={onHeaderFocusChange}
          onHomePage={onHomePage}
          onSearchInput={onSearchInput}
          searchTab={searchTab}
          showSearchTab={showSearchTab}
          unifiedSearchMode={unifiedSearchMode}
         />
      }
      {alwaysShowSearchBar && renderSearchBarHeader() }
      {neverShowSearchBar && renderHeader() }
      {siteNotice && siteNotice?.message && (
        <div  style={{height: isMobile ? 56 : 30}} />
      )}
      <div>
        {React.Children.map(children, (child) => cloneElement(child))}
      </div>
      {!noFooter &&
        <Footer {...props} />
      }
      {
        <CookiesAlert isMobile={isMobile}/>
      }
    </section>
  );
};

export const PageTemplate = withTranslation()(PageTemplateComponent);

PageTemplate.propTypes = {
  filters: PropTypes.object,
  shouldHideLocationInput: PropTypes.bool,
  alwaysShowSearchBar: PropTypes.bool,
  neverShowSearchBar: PropTypes.bool,
  showSearchTab: PropTypes.string,
  onSearchInput: PropTypes.func,
  scrollThreshold: PropTypes.number,
  onLocationInput: PropTypes.func,
  showUnifiedSearchDropdown: PropTypes.bool,
  unifiedSearchMode: PropTypes.bool,
  onHeaderFocusChange: PropTypes.func,
  headerIsFixed: PropTypes.bool,
  isMobile: PropTypes.bool,
  hasLiveBanner: PropTypes.bool,
  liveEventURL: PropTypes.string,
  hideSignUpBanner: PropTypes.bool,
  bannerTopOffset: PropTypes.number,
  onHomePage: PropTypes.bool,
  t: PropTypes.func
};

PageTemplate.defaultProps = {
  shouldHideLocationInput: false,
  scrollThreshold: 330,
  alwaysShowSearchBar: false,
  neverShowSearchBar: false,
  hideSignUpBanner: false,
  bannerTopOffset: 0,
  showUnifiedSearchDropdown: false,
  onHomePage: false
};
