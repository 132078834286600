import React from 'react';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

export const Tooltip = ({icon, tip, place = 'top', border= true, type = 'light', style = {}}) => {
  return (
    <div data-tip={_.join(tip.content, '<br />')} data-for={tip.id} style={style}>
      {icon}
      <ReactTooltip
        key={tip.id}
        id={tip.id}
        place={place}
        type={type}
        effect="float"
        border={border}
        multiline={true}
        class='extraClass'
      />
    </div>
  )
};

