import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import  ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right';
import { colors } from '../../shared/styles';
import { trackGoogleEvent } from '../../utils/googleEvents';

class EventRowComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      arrow: false
    }

  }

  onMouseEnter = () => {
    this.setState({arrow: true})
  };

  onMouseLeave = () => {
    this.setState({arrow: false})
  };

  render(){
    const {masterEventId, masterEventTitle, city, stateProv,styles, searchTerm, t} = this.props;
    const {arrow} = this.state;

    return (
      <div
        style={styles.resultRow(arrow)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={() => trackGoogleEvent('search', 'Event Chosen', masterEventTitle)}
      >
        <Link className='event-page-link'to={`/event/${masterEventId}?category=global&term=${searchTerm}`}>
          {/*<div style={styles.tempEvent}></div>*/}
          <div style={styles.resultName}>{masterEventTitle}</div>
          <div style={styles.resultDetails}>{t('{{city}}, {{stateProv}}', {city, stateProv})}</div>
          <div style={styles.rowArrowContainer}>
            {arrow && <ArrowRight size={65} style={{color: colors.lightGrey2}}/> }
          </div>
        </Link>
      </div>
    )
  }
}

export const EventRow = withTranslation()(EventRowComponent);
