export const distance_types = [
  {
    id: 1,
    name: 'Mi',
    converter: 1609.344,
    ctLiveUnit: 'miles'
  },
  {
    id: 2,
    name: 'in',
    converter: 0.0254,
    ctLiveUnit: 'inches'
  },
  {
    id: 3,
    name: 'ft',
    converter: 0.3048,
    ctLiveUnit: 'feet'
  },
  {
    id: 4,
    name: 'Yd',
    converter: 0.9144
  },
  {
    id: 6,
    name: 'M',
    converter: 1,
    ctLiveUnit: 'meters'
  },
  {
    id: 7,
    name: 'K',
    converter: 1000,
    ctLiveUnit: 'kilometers'
  }
];

export const getById = (id) => {
  return (distance_types||[]).find((x) => x.id === id);
}

export const getByCtLiveUnit = (unit) => {
  return (distance_types||[]).find((x) => x.ctLiveUnit === unit);
}

export const distanceTo = (distance, distance_id, to_id) => {
  if(to_id !== distance_id && distance_id !== 0) {
    const distance_type = getById(distance_id);
    const to_type = getById(to_id);

    return distance * (distance_type.converter / to_type.converter);
  }

  return distance;
}

export const getDistanceFromCTLiveRace = (unit, value) => {
  const distanceType = getByCtLiveUnit(unit) || getById(6);

  const distance = distanceTo(value, 6, distanceType.id);

  return {
    value: distance,
    unit: distanceType.name,
    displayValue: distance + distanceType.name
  };
}