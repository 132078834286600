import React from 'react';
import PropTypes from 'prop-types';

export const RowArrow = (props) => (
  <svg width="10px" height="19px" viewBox="0 0 10 19">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="List-View-claim-left" transform="translate(-1237.000000, -819.000000)" fill={props.fill}>
        <path d="M1233.14062,823.958984 C1233.14062,823.888672 1233.15527,823.81543 1233.18457,823.739258 C1233.21387,823.663086 1233.25781,823.595703 1233.31641,823.537109 C1233.43359,823.43164 1233.57422,823.378906 1233.73828,823.378906 C1233.90234,823.378906 1234.04297,823.43164 1234.16016,823.537109 L1242.10547,831.482422 L1249.92773,823.660156 C1250.04492,823.542968 1250.18555,823.484375 1250.34961,823.484375 C1250.51367,823.484375 1250.6543,823.542968 1250.77148,823.660156 C1250.88867,823.777344 1250.94727,823.917968 1250.94727,824.082031 C1250.94727,824.246095 1250.88867,824.386718 1250.77148,824.503906 L1242.52734,832.748047 C1242.41016,832.865235 1242.26953,832.923828 1242.10547,832.923828 C1241.94141,832.923828 1241.80078,832.865235 1241.68359,832.748047 L1233.31641,824.398438 C1233.25781,824.339843 1233.21387,824.272461 1233.18457,824.196289 C1233.15527,824.120117 1233.14062,824.041016 1233.14062,823.958984 Z" id="Arrow-down---simple-line-icons-Copy-17" transform="translate(1242.043945, 828.151367) rotate(-90.000000) translate(-1242.043945, -828.151367) "></path>
      </g>
    </g>
  </svg>
);

RowArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

RowArrow.defaultProps = {
  width: '10px',
  height: '19px',
  fill: '#CCCCCC'
};
