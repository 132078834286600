import { colors } from '../../shared/styles';
import {configs} from '../../configs';

export const styles = {
  container: {
    margin: 'auto',
    maxWidth: '1280px',
    textAlign: 'center',
  },
  messageContainer: {
    backgroundColor: colors.white,
    margin: '150px 0px',
  },
  message: {
    display: 'block',
    fontSize: 34,
    fontFamily: 'ProximaNovaRegular'
  },
  error: {
    color: colors.grey,
    lineHeight: 3,
    fontSize: '14px',
    fontFamily: 'ProximaNovaRegular'
  },
  pageTamplateStyle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${configs.cdnUrl}/images/error-page-background.jpg)`,
    position: 'fixed',
    marginTop: 50,
    top: 0,
    left: 0,
    minWidth: '100%',
    minHeight: '100%'
  },
  searchResultsContainer: (isMobile) => ({
    ...styles.messageContainer,
    padding: (isMobile) ? '10px' : '150px 0',
    width: (isMobile) ? 'inherit' : '700px'
  }),
};
