import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import _ from 'lodash';
import React, {Component, Children, cloneElement} from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {withTranslation} from 'react-i18next';
import { streamHook, eventBus } from '../../data/Streams';
import { styles } from './styles';

export const SEARCH_FILTERS_DATA = 'search-filters-data-stream';

class FiltersComp extends Component {

  static propTypes = {
    children: PropTypes.oneOfType(
      [PropTypes.array, PropTypes.object]).isRequired,
    defaultValues: PropTypes.object,
    onChange: PropTypes.func,
    rowStyle: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    defaultValues: {},
    onChange: () => {},
    rowStyle: {}
  };

  state = {
    values: this.props.defaultValues,
    reset: 0
  };

  reset = () => {
    const {defaultValues, onChange} = this.props;
    const {reset} = this.state;

    this.setState(
      {values: defaultValues, reset: reset + 1},
      () => onChange(defaultValues));
  };

  onChange(name, value) {
    const {values} = this.state;

    this.setState(
      {values: {...values, [name]: value}},
      () => this.props.onChange(this.state.values));
  }

  render() {
    const {
      children,
      rowStyle,
      isMobile,
      t
    } = this.props;

    const {values, reset} = this.state;

    return (
      <div style={styles.filters.main(isMobile)}>
        {
          isMobile &&
          <div style={styles.filters.headerRow}>
            <span>{t('Filter By')}</span>
          </div>
        }
        {
          Children.map(children, (child, index) => (
            <div key={`${index}-${reset}`} style={rowStyle}>
              {cloneElement(child, {
                value: values[child.props.id],
                onChange: this.onChange.bind(this, child.props.id)
              })}
            </div>
          ))
        }
      </div>
    );
  }
}

export const Filters = withTranslation()(FiltersComp);

/**
 * Filter component class
 * Will get values and default values from stream
 */
class FilterComponent extends Component {

  static propTypes = {
    streamId: PropTypes.string.isRequired,
    values: PropTypes.object,
    defaultValues: PropTypes.object,
    showTitle: PropTypes.bool,
    canHideFilters: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    values: null,
    defaultValues: {},
    showTitle: true,
  };

  state = {
    showFilters: false
  };

  UNSAFE_componentWillReceiveProps({values}) {
    /**
     * If new filter values are received but defaultValues are empty, then
     * set current values as default values
     */
    const hasNewValues = !_.isEqual(values, this.props.values);
    if(hasNewValues && _.isEmpty(this.props.defaultValues)) {
      setDefaultFilterValues(this.props.streamId, values);
    }
  }

  /**
   * Executes on change actions. Pushes new value to filter stream
   * @param key
   * @param value
   */
  onChange = (key, value) => {
    const {values, streamId} = this.props;
    setFilterValues(streamId, {...values, [key]: value.target.value});
  };

  /**
   * Resets filter values to default values
   */
  reset = () => setFilterValues(this.props.streamId, this.props.defaultValues);

  shouldShowFilters = () => {
    const {canHideFilters} = this.props;
    const {showFilters} = this.state;
    return !canHideFilters || showFilters;
  };

  render() {
    const {
      children,
      rowStyle = {},
      values,
      showTitle,
      isMobile,
      isHorizontal,
      t
    } = this.props;

    const shouldShowFilters = this.shouldShowFilters();
    const additionalRowStyle = isHorizontal ? {...rowStyle, display: 'inline-block', marginBottom: 25} : {};
    if(values === null) return null;
    else return (
      <div className='row' style={styles.main}>
        <div
          className={isHorizontal ? 'col-12 px-0' : 'col-12'}
          style={styles.filterComponent.toggleStyle(shouldShowFilters)}>
          { showTitle &&
          <div style={styles.filterComponent.headerRow}>
            <span>{t('Filter By')}</span>
          </div>
          }
          {
            Children.map(children, (child, index) => (
              <div key={`${index}-${0}`} style={additionalRowStyle} className={isMobile || !isHorizontal ? 'col-12 px-0' : 'col-4 pr-0'}>
                {cloneElement(child, {
                  value: values[child.props.id],
                  onChange: this.onChange.bind(this, child.props.id)
                })}
              </div>
            ))
          }
        </div>

      </div>
    );
  }

}

/**
 * FilterStream wrapper component
 */
export const FilterStream  = withTranslation()(connectStream({
  values: ({streamId}) => getFilterValues(streamId),
  defaultValues: ({streamId}) => getDefaultFilterValues(streamId)
})(FilterComponent));

/**
 * Retrieves latest values pushed to the filter value stream
 * @param {string} streamId
 * @return {*}
 */
export function getFilterValues(streamId) {
  const subject = streamHook(streamId);
  const parsed = queryString.parse(window.location.search);
  if (parsed.filters) {
    subject.next(JSON.parse(decodeURIComponent(parsed.filters)))
  }
  return subject;
}

/**
 * Retrieves latest default values pushed to the default filter value stream
 * @param {string} streamId
 * @return {*}
 */
export function getDefaultFilterValues(streamId) {
  return streamHook(`${streamId}-default`);
}

/**
 * Pushes filter values to browser history, pass nothing to reset.
 * @param {*} values
 * @param {*} history
 */
export function pushFilterHistory(values, history) {
  const parsed = queryString.parse(window.location.search);
  const filters = parsed.filters ? JSON.parse(parsed.filters) : {};
  const query = {
    ...parsed,
    filters: !values ? undefined : JSON.stringify({
      ...filters, // preserve existing filters
      ...values // overwrite specific filters
    }),
  };

  history.replace({
    pathname: window.location.pathname,
    search: queryString.stringify(query)
  })
}

/**
 * Sets filter vales. Also sets as default if 3rd param is true.
 * @param {string} streamId
 * @param {*} values
 * @param {boolean} setAsDefault
 */
export function setFilterValues(streamId, values, setAsDefault = false) {
  eventBus.publish(streamId, values);
  if(setAsDefault) {
    setDefaultFilterValues(streamId, values)
  }
}

/**
 * Sets default filter values
 * @param {string} streamId
 * @param {*} values
 */
export function setDefaultFilterValues(streamId, values) {
  eventBus.publish(`${streamId}-default`, values);
}

export const defaultFilterData = {
  activeTab: null,
  filters: {
    aliases: [],
    birthYear: '',
    fromAge: 16,
    gender: 'A',
    getDismissedResults: false,
    location: '',
    tempLocation: '',
    locations: [],
    endDate: '',
    startDate: '',
    toAge: 115,
    withinRange: 50,
    raceTypes: {
      running: {
        upTo5k: false,
        from5kTo15k: false,
        from15kToHalfMara: false,
        fromHalfMaraToMara: false,
        marathon: false,
        ultra: false
      },
      triathlon: {
        sprint: false,
        olympic: false,
        halfIronman: false,
        ironmanAndUp: false,
        aquathlon: false,
        aquabike: false,
        duathlon: false
      },
      more: {
        swim: false,
        mountainBike: false,
        cycling: false,
        snow: false,
        adventure: false,
        obstacle: false,
        other: false
      }
    }
  }
}

export function getSearchFiltersDataStream() {
  return streamHook(SEARCH_FILTERS_DATA, defaultFilterData);
}

export function setSearchFiltersDataStream(values) {
  eventBus.publish(SEARCH_FILTERS_DATA, values);
}
