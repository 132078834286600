import React from 'react';
import CloseIcon from 'react-icons/lib/md/close';
import {_filterTab} from './styles';

const MobileFilterHeader = ({title, onClose}) => {
  return (
    <div style={_filterTab.mobileHeader}>
      {title}
      <CloseIcon
        size={25}
        style={_filterTab.closeButton}
        onClick={() => onClose()}
      />
    </div>
  )
};

export default MobileFilterHeader;
