import { createTheme } from "@material-ui/core/styles";

interface BaseColors {
  brandColor: string,
  facebookColor: string,
  grey: {
    100: string,
    400: string,
    500: string,
    600: string,
    700: string,
    800: string,
  },
  lightBlue: {
    700: string,
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette extends BaseColors {}
  interface PaletteOptions extends BaseColors {}
}

export const theme = createTheme({
  palette: {
    brandColor: '#16A9E1',
    facebookColor: '#3b5998',
    grey: {
      100: '#F5F5F5',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
    },
    lightBlue: {
      700: '#0288D1',
    }
  }
})