import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
export {compose} from 'redux'

export const connectStream = (observableFnOrObj) => {
  const streamObj = (typeof observableFnOrObj === 'function')
    ? { anuket: observableFnOrObj }
    : observableFnOrObj;

  return (WrappedComponent) => {

    return class extends React.Component {
      static propTypes = {
        changedProps: PropTypes.array,
      }

      state = {};

      _calledComponentWillUnmount = false

      componentDidMount() {
        this._streamFn(this.props);
      }

      componentDidUpdate(prevProps) {
        const diffs = new Set()
        _.difference(Object.keys(prevProps), Object.keys(this.props)).forEach((x) => {
          diffs.add(x)
        })
        Object.keys(this.props).forEach((x) => {
          if (x in prevProps) {
            if (!_.isEqual(this.props[x], prevProps[x])) {
              diffs.add(x)
            }
          }
        })
        if (diffs.size > 0) {
          this._streamFn(this.props);
        }
      }

      shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual({...nextProps, ...nextState}, {...this.props, ...this.state})
      }

      componentWillUnmount() {
        this._disposeStream();
        this._calledComponentWillUnmount = true;
      }

      _handleAsyncForEach(obj, callback) {
        Promise.all(Object.keys(obj).map(async (x) => callback(obj[x], x, obj)))
      }

      async _streamFn(props) {
        this._disposeStream();

        await this._handleAsyncForEach(streamObj, async (val, key) => {
          let objVal = val(props);

          //If it's a promise, wait for it to resolve
          if(objVal && objVal.then && typeof(objVal.then) === 'function') {
            objVal = await objVal;
          }

          if (objVal && objVal.subscribe) {
            this.subscribe[key] = objVal.subscribe(
              (msg) => {
                if(msg !== null && !this._calledComponentWillUnmount) {
                  this.setState(
                    _.assign(
                      {},
                      { [key]: { isError: false }},
                      { [key]: msg }
                    )
                  );
                }
              },
              (err) => {
                if (!this._calledComponentWillUnmount) {
                  this.setState({ [key]: { isError: true, err }});
                }
              }
            );
          }
          else {
            if (!this._calledComponentWillUnmount) {
              this.setState(
                _.assign(
                  {},
                  { [key]: { isError: false }},
                  { [key]: objVal }
                )
              );
            }
          }
        });
      }

      _disposeStream() {
        if (!this.subscribe) {
          this.subscribe = {};
        }

        _.forEach(streamObj, (val, key) => {
          if(this.subscribe[key] && this.subscribe[key].unsubscribe) {
            this.subscribe[key].unsubscribe();
          }
        });

      }

      render() {
        return <WrappedComponent {...{...this.props, ...this.state}} />;
      }
    };
  };
};

export default connectStream;