import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';

/**
 * Event kiosk renderer
 * @param {string} logo
 * @param {string} title - Event name
 * @param {string} subtitle - Event start date
 * @param {boolean} isMobile
 * @return {*}
 * @constructor
 */
export const KioskHeader = ({ logo, title, subtitle, isMobile, isLightMode }) => {
  let element;
  return (
    <div style={styles.headerContainer}>
      <p style={styles.headerIconContainer}>
        <img
          ref={(img) => element = img}
          onError={() => element.style.display = 'none'}
          style={styles.headerIconImage}
          src={logo}
          alt={''}
        />
      </p>
      <h1
        style={styles.headerMainHeading(isMobile, isLightMode)}>
        {title}
      </h1>
      <p style={styles.headerSubHeading(isLightMode)}>{subtitle}</p>
    </div>
  );
};

KioskHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  logo: PropTypes.string,
};
