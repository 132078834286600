import React from 'react';
import { FormattedDate } from 'react-intl';
import { styles } from './styles';

// TODO: ask about this
export const Accessibility = ({t}) => (
  <div className="row p-0">
    <div className="col-12" style={styles.fullPage}>
      <h2>{t('Accessibility Policy')}</h2>
      <h3>
        <FormattedDate
          year='numeric'
          month='long'
          day='2-digit'
          value={new Date('2017-01-18')}>
          {(date) => <div>{t('Updated {{date}}', {date})}</div>}
        </FormattedDate>
      </h3>
      <p>
        {t(
          'Athlinks is committed to ensuring the accessibility of our website to '
            + 'persons with disabilities. If you have any questions or would like as'
            + 'sistance with access to our website, please email us at {{email}} or ca'
            + 'll {{phone}}, or for TTY, {{phoneTTY}}.',
          {
            email: 'accessibility@athlinks.com',
            phone: '720-931-5552',
            phoneTTY: '(844) 413 4855'
          })
        }
      </p>
    </div>
  </div>
);
