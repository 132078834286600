import { colors, fonts } from '../../shared/styles';

export const pageTemplate = {
  container: {
    position: 'relative',
    backgroundColor: colors.lightGrey,
  },
  toggleMenuContainer: {
    minHeight: 50,
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.24)'
  },
  toggleNav: {
    position: 'absolute',
    zIndex: '999',
    width: '100%',
    background: colors.white
  },
  toggleLogo: {
    paddingTop: 5,
    paddingLeft: 5
  },
  rotateToggle: {
    transform: 'rotate(180deg)',
    marginTop: 5,
    marginLeft: 5
  }
};

export const _header = {
  navContainer: (showSearchBar) => ({
    padding: showSearchBar ? '0 40px' : '5px 40px 0',
    background: showSearchBar ? colors.darkBlue4 : '',
    boxShadow: showSearchBar ? '0px 2px 3px rgba(136, 136, 136, 0.44)' : '',
    transition: 'all 0.3s',
    height: 50,
    position: 'relative',
  }),
  filterNavContainer: (showSearchBar) => ({
    padding: showSearchBar ? '0 40px' : '5px 40px 0',
    background: showSearchBar ? colors.white : '',
    boxShadow: showSearchBar ? '0px 2px 3px rgba(136, 136, 136, 0.44)' : '',
    transition: 'all 0.3s',
    height: 55,
    position: 'relative',
  }),
  topNav: {
    display: 'flex',
    width: '100%',
  },
  navLeft: {
    flex: '1',
    display: 'inline-block',
    padding: '1px 0',
  },
  navLeftIE: {
    flex: '1',
    display: 'inline-block',
    padding: '1px 0',
    maxHeight: 50,
    flexBasis: 'auto', // make IE happy
  },
  navCenter: (isloggedIn) => ({
    position: 'relative',
    flex: '1 1 100%',
    display: 'inline-block',
    maxWidth: isloggedIn ? 1105 : 970,
    paddingTop: '2px',
  }),
  navRight: {
    flex: '1 0 auto',
    display: 'inline-block',
    paddingTop: 9,
  },
  tabs: {
    backgroundColor: 'white',
    paddingLeft: 10,
    paddingRight: 10
  },

  logoLink: (isIE) => ({
    display: 'inline-block',
    marginRight: '40px',
    padding: '10px 2px 0',
    height: (isIE) ? '100%' : 'auto',
  }),
  actionContainer: (isMobile) => ({
    textAlign: (isMobile) ? 'center' : 'right',
  }),

  profPicContainer: {
    position: 'relative',
    top: '-1px',
    display:'inline-block',
    verticalAlign:'top',
    cursor: 'pointer'
  },
  profPicIMG: {
    display: 'inline-block',
    borderRadius: '50%',
    height: 36,
  },
  navButton: (isBlue) => ({
    display: 'inline-block',
    marginLeft: '15px',
    padding: '9px 11px 6px',
    fontSize: '12px',
    fontFamily: 'ProximaNovaSemiBold, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    color: colors.white,
    backgroundColor: (isBlue) ? colors.blue : 'transparent',
    borderRadius: '2px',
  }),

  dropMenu: (menuOpen) => ({
    position: 'absolute',
    zIndex: 12,
    top: (menuOpen) ? '100%' : '-300px',
    left: '-150px',
    display: 'inline-block',
    width: 200,
    textAlign:'left',
    backgroundColor: colors.white,
    boxShadow: '0px 2px 3px rgba(136, 136, 136, 0.44)',
    transition: 'all 0.3s',
  }),
  dropMenuItem: {
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    padding: '10px 20px',
    color: colors.textColor,
    cursor: 'pointer',
  },
  menuItemMobile: (isBlue = false) => ({
    boxSizing: 'border-box',
    ...fonts.default,
    display: 'block',
    width: '100%',
    padding: '10px 20px',
    textAlign: 'left',
    borderTop: `1px solid ${colors.greyLine}`,
    backgroundColor: (isBlue) ? colors.blue : colors.white,
    color: (isBlue) ? colors.white : colors.textColor,
  }),
  headerSearchField: (showSearchTab) => ({
    width: '100%',
    maxWidth: 370,
    border: '1px solid rgba(255,255,255,0.25)',
    borderLeft: showSearchTab ? 'none' : 'auto',
    height: '32px',
    marginTop: 7,
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: showSearchTab ? '0px 4px 4px 0px' : '4px',
    WebkitAppearance: 'none'
  }),
  liveRaceCustomStyling: {
    position: 'absolute',
    width: '100%',
    zIndex: 100
  }
};

export const _searchTab = {
  linkStyle: (isActive, isHover, isMobile) => ({
    display:'inline-block',
    marginRight: isMobile ? null : 40,
    padding: isMobile ? '7px 10px' : '7px 1px',
    textAlign:'center',
    fontFamily: 'ProximaNovaBold, sans-serif',
    fontSize: 12,
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
    color: (isActive || isHover) ? colors.blue : colors.textColor,
    borderBottom: (isActive) ? '3px solid '+ colors.blue : 'none',
    transition: 'all 0.3s',
  }),
};

export const _searchFilters = {
  wrapper: (isMobile, hasSelectedValues) => ({
    width: isMobile && hasSelectedValues ? 'calc(100% - 105px)' : '100%',
    height: '55px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: isMobile ? 'auto' : 0,
    whiteSpace: isMobile ? 'nowrap' : 'auto',
    overflowX: isMobile ? 'scroll' : 'none',
    position: isMobile ? 'relative' : 'static',
    marginLeft: isMobile && hasSelectedValues ? '0px' : 'auto',
    paddingLeft: isMobile ? 0 : 'auto'
  }),
  clearFiltersMobile: (isMobile) => ({
    position: isMobile ? 'fixed' : 'static',
    right: 30,
    height: 35,
    padding: isMobile ? '0px 20px' : '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#26aadf',
    width: isMobile ? '120px' : 'auto',
    paddingLeft: isMobile ? 65 : 10,
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+29&0.01+0,1+29 */
    background: 'linear-gradient(to right, rgba(255,255,255,0.01) 0%,rgba(255,255,255,1) 20%)', /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#03ffffff", endColorstr="#ffffff",GradientType=1)' /* IE6-9 */
  }),
  loader: {
    display: 'flex',
    width: 110,
    height: 40
  }
}

export const _filterTab = {
  wrapper:(active, hasSelectedValue) => ({
    width: 'auto',
    height: '34px',
    fontSize: 15,
    border: hasSelectedValue ? '1px solid #26aadf' : '1px solid #ddd',
    borderRadius: '4px',
    background: hasSelectedValue ? '#26aadf' : active ? '#f5f5f5' : 'white',
    marginRight: 10,
    color: active ? '#9b9b9b' : 'black',
    position: 'relative',
    transition: 'background 0.2s',
    ':hover': {
      background: hasSelectedValue ? '#26aadf' : '#f5f5f5'
    }
  }),
  filterTabClickable: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10
  },
  filterTabPseudoStyles: (hasSelectedValue) => `
    .filterTabWrapper:hover {
      background: ${hasSelectedValue ? '#26aadf !important' : '#f5f5f5 !important' };
    }
  `,
  label: (active, hasSelectedValue, disabled) => ({
    color: disabled ? '#ddd' : hasSelectedValue ? 'white' : active ? '#9b9b9b' : '#4a4a4a',
  }),
  content: (isMobile) => ({
    position: isMobile ? 'fixed' : 'absolute',
    top: isMobile ? '0px' : '40px',
    height: isMobile ? '100vh' : 'auto',
    zIndex: '99',
    width: isMobile ? '100%' : 'auto',
    maxHeight: '100vh',
    overflow: isMobile ? 'scroll' : 'auto',
    left: '0px',
    fontSize: 15,
    boxShadow: '0 9px 19px 0 rgba(0,0,0,0.08)'
  }),
  raceFilterWrap: (isMobile = false) => ({
    width: isMobile ? '100%' : 392,
    maxWidth: !isMobile ? 392 : '100%',
    background: 'white',
    borderRadius: '4px',
    border: '1px solid rgb(221, 221, 221)',
    height: isMobile ? '100vh' : 'auto',
    whiteSpace: 'pre-wrap'
  }),
  dateFilterWrap: (isMobile = false) => ({
    background: 'white',
    borderRadius: '4px',
    border: '1px solid rgb(221, 221, 221)',
    height: isMobile ? '100vh' : 'auto'
  }),
  locationSpinner: {
    position: 'absolute',
    right: 5,
    top: 10,
    height: 40,
    width: 40,
  },
  controlsWrap: {
    display: 'flex',
    flex: 1,
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingRight: '15px',
    paddingLeft: '15px',
    backgroundColor: 'white'
  },
  resetButton: {
    display: 'inline-block',
    fontFamily: 'ProximaNovaBold',
    flex: 1,
    border: 'none',
    height: '46px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#9b9b9b',
    maxWidth: '185px',
    cursor: 'pointer',
    backgroundColor: 'white',
    transition: 'all 0.1s',
    ':hover' : {
      color: '#696868'
    }
  },
  applyButton: {
    display: 'inline-block',
    fontFamily: 'ProximaNovaBold',
    flex: 1,
    fontSize: '16px',
    fontWeight: 'bold',
    background: '#2c314e',
    border: 'none',
    color: 'white',
    maxWidth: '185px',
    borderRadius: 4,
    cursor: 'pointer',
    transition: 'all 0.2s',
    ':hover': {
      background: '#565a73'
    }
  },
  editButton: {
    display: 'inline-block',
    flex: 1,
    fontSize: '16px',
    fontWeight: 'bold',
    background: '#2c314e',
    border: 'none',
    color: 'white',
    width: '100%',
    borderRadius: 4,
    cursor: 'pointer',
    height: 46,
    transition: 'all 0.2s',
    ':hover': {
      background: '#565a73'
    }
  },
  accordionWrap: {
    padding: 10
  },
  accordionHeader: (active) => ({
    cursor: active ? 'default' : 'pointer',
    borderBottom: '1px solid #dddddd',
    paddingBottom: 5,
    fontSize: 13,
    fontFamily: 'ProximaNovaBold',
    display: 'flex',
    justifyContent: 'space-between'
  }),
  accordionContent: {
    paddingTop: 10
  },
  geoSubmitWrapper: {
    marginTop: 10,
    marginRight: 10,
    marginLeft: 50
  },
  locationFilterWrap: {
    width: 392,
    maxWidth: 392,
    background: 'white',
    borderRadius: '4px',
    border: '1px solid rgb(221, 221, 221)',
    padding: 10
  },
  locationSearchFieldWrap: {
    paddingBottom: 10,
    border: '1px solid rgb(221, 221, 221)',
    borderRadius: 4,
    boxSizing: 'border-box',
    position: 'relative'
  },
  locationInputField: {
    border: '0px solid',
    width: '99%',
    height: 25,
    fontSize: 13
  },
  searchIcon: {
    position: 'absolute',
    top: 12,
    left: 10
  },
  clearIcon: (shouldShow) => ({
    display: shouldShow ? 'block' : 'none',
    position: 'absolute',
    top: 12,
    right: 10,
    color: '#dddddd'
  }),
  locationRowWrap: (isError) => ({
    width: '100%',
    paddingTop: isError ? 5 : 17,
    paddingBottom: isError ? 5 : 15,
    borderBottom: '1px solid rgb(221, 221, 221)',
    position: 'relative',
    transition: 'all 0.1s',
    ':hover' : {
      background: '#fbfbfb'
    }
  }),
  locationsCount: {
    display: 'inline-block',
    float: 'right',
    color: colors.darkType
  },
  locationRowButton: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    boxSizing: 'border-box',
    paddingLeft: 46,
    background: 'none',
    border: 'none',
    fontSize: 14,
    color: '#4a4a4a',
    cursor: 'pointer'
  },
  locationRowIcon: {
    verticalAlign: 'middle',
    position: 'absolute',
    left: 10,
    top: 15
  },
  rangeWrap: {
    width: '100%'
  },
  rangeData: {
    padding: '28px 0px 25px 5px'
  },
  rangeTitle: {
    fontSize: 15,
    color: '#4a4a4a'
  },
  rangeValue: {
    fontSize: 12,
    paddingTop: 5
  },
  sliderWrap: {
    padding: '0px 15px'
  },
  sliderHande: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: '14px',
    height: '14px',
    cursor: 'pointer',
    borderRadius: '50%',
    border: 'solid 2px #000',
    backgroundColor: '#fff'
  },
  handle: (offset) => ({
    width: 35,
    height: 35,
    background: 'white',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    borderRadius: '100%',
    left: `${offset}%`,
    boxShadow: '1px 1px 4px 0px rgba(119, 119, 119, 0.52)',
    MozBoxShadow: '1px 1px 4px 0px rgba(119, 119, 119, 0.52)',
    WebkitBoxShadow: '1px 1px 4px 0px rgba(119, 119, 119, 0.52)'
  }),
  mobileHeader: {
    display: 'flex',
    height: 80,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: '#4a4a4a'
  },
  closeButton: {
    position: 'absolute',
    right: 20
  },
  dateWrapper: (isMobile) => ({
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: isMobile ? 'column' : 'row'
  }),
  leftSection: (isMobile) => ({
    display: 'flex',
    flex: 1,
    maxWidth: isMobile ? '100%' : 195,
    height: 315,
    background: 'white',
    flexDirection: 'column',
    padding: '0px 15px 0px 0px'
  }),
  rightSection: {
    display: 'flex',
    flex: 1,
    height: 315,
    background: 'white'
  },
  dateOptionsHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingBottom: 7,
    borderBottom: '1px solid rgb(221, 221, 221)'
  },
  dateOptionItem: (active) => ({
    padding: '10px 0px',
    fontSize: 15,
    borderRadius: 4,
    color: active ? '#31b0e1' : '#4a4a4a',
    transition: 'all 0.1s',
    ':hover': {
      color: !active ? '#afafaf' : 'auto'
    }
  }),
  ageFilterWrap: (isMobile = false) => ({
    width: isMobile ? '100%' : 392,
    maxWidth: !isMobile ? 392 : '100%',
    background: 'white',
    borderRadius: '4px',
    border: '1px solid rgb(221, 221, 221)',
    height: isMobile ? '100vh' : 'auto'
  }),
  birthYearinput: {
    width: '100%',
    height: 50,
    borderRadius: 4,
    border: '1px solid #ddd',
    paddingLeft: 20,
    boxSizing: 'border-box',
    fontSize: 16
  },
  pseudoStyles: () => `
    .birthYearInput::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .birthYearInput::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  upIcon: {
    position: 'absolute',
    top: 17,
    right: 25
  },
  downIcon: {
    position: 'absolute',
    top: 37,
    right: 25
  },
  locationsHeader: {
    fontSize: 16,
    borderBottom: '1px solid #dddddd',
    paddingBottom: 10,
    paddingLeft: 2,
    marginBottom: 10
  },
  locationsMsg: {
    display: 'flex',
    minHeight: 150,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#585858'
  },
  checkContainer: {
    display: 'flex'
  },
  checkBox: (checked) => ({
    float: 'left',
    height: 25,
    width: 25,
    border: checked ? '1px solid #16a9e1' : '1px solid #9b9b9b',
    backgroundColor: checked ? '#16a9e11a' : '#fff',
    borderRadius: 3,
    color: checked ? '#16a9e1' : '#9b9b9b',
    marginBottom: 15
  }),
  checkMark: (checked) => ({
    height: 40,
    width: 40,
    position: 'relative',
    bottom: 8,
    right: 8,
    color: checked ? '#16a9e1' : '#9b9b9b',
    display: checked ? 'block' : 'none',
    zIndex: 5,
  }),
  checkLabel: (checked) => ({
    float: 'left',
    fontFamily: 'ProximaNovaRegular',
    fontSize: 15,
    color: checked ? '#16a9e1' : '#4a4a4a',
    whiteSpace: 'nowrap',
    paddingTop: 5,
    paddingLeft: 15
  })
}

export const searchTabStyle = {
  wrapper: (isActive, isHomePage, isDark, notFound, isMobile) => ({
    flex: '1 1 0%',
    float: isDark ? 'left' : 'none',
    background: isDark ? colors.darkBlue4
      : isActive ? '#f5f5f5' : 'white',
    height: !isHomePage ? '32px' : notFound ? '35px' : '45px',
    borderRadius: '2px 0px 0px 2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: isMobile ? 'auto' : 'pointer',
    position: 'relative',
    border: !isHomePage ? '1px solid rgba(255,255,255,0.25)' : notFound ? '2px solid rgb(22, 169, 225)' : 'none',
    transition: 'all 0.5s ease',
    marginTop: !isHomePage ? '7px' : 'auto',
    zIndex: 10
  }),
  selected: (isActive, isHomePage, isDark) => ({
    fontWeight: 'bold',
    color: isDark ? 'white' : isActive ? '#9b9b9b' : '#4a4a4a',
    transition: 'all 0.5s ease',
    paddingRight: '35px',
    paddingLeft: '10px',
    fontSize: !isHomePage ? '15px' : 'auto',
    width: '136px',
    textAlign: 'center'
  }),
  listWrapper: (isHomePage, isDark, notFound) => ({
    width: '100%',
    background: isDark ? colors.darkBlue4 : 'white',
    position: 'absolute',
    top: isHomePage ? notFound ? '32px' : '45px' : '30px',
    left: '0px',
    padding: '0px 10px',
    border: !isHomePage ? '1px solid rgba(255,255,255,0.25)' : '1px solid rgba(0,0,0,0.25)',
    borderTop: '1px solid #dddd',
    borderRadius: '0px 0px 4px 4px'
  }),
  listItem: (isHomePage, lastElem) => ({
    borderBottom: !lastElem ? '1px solid #dddddd' : 'none',
    padding: '10px 0px',
    fontSize: !isHomePage ? '15px' : 'auto',
    textAlign: 'center'
  }),
  pseudoStyle: (isHomePage, lastElem, isDark) => `
    .contextItem {
      border-bottom: ${!lastElem ? '1px solid rgba(255,255,255,0.25)' : 'none'};
      padding: 10px 0px;
      font-size: ${!isHomePage ? '15px' : 'auto'};
      color: ${isDark ? 'white' : '#4a4a4a'}
    }
    .contextItem:hover {
      color: ${isDark ? 'rgba(255,255,255,0.6)' : '#9b9b9b'};
    }
  `,
  expandButton: {
    position: 'absolute',
    right: '10px'
  }
};
