export const ClaimActionTypes = {
  AFTER_CLAIM_INDIVIDUAL_RESULTS_UPDATE: 'AFTER_CLAIM_INDIVIDUAL_RESULTS_UPDATE'
};

export const EventResultsFilterActionTypes = {
  FILTER_SET: 'FILTER_SET',
};

export const ModalActionTypes = {
  HIDE_MODAL: 'HIDE_MODAL',
  MODAL_CHANGE_PAGE: 'MODAL_CHANGE_PAGE',
  SHOW_MODAL: 'SHOW_MODAL'
}

export const ModalTypes = {
  ADD_EVENT_WIZARD: 'ADD_EVENT_WIZARD',
  CLAIM_ENTRY: 'CLAIM_ENTRY',
  UNCLAIM_ENTRY: 'UNCLAIM_ENTRY',
  UNFOLLOW_ATHLETE: 'UNFOLLOW_ATHLETE'
}

export const SearchActionTypes = {
  CONTEXT_SET: 'CONTEXT_SET'
};

export const PrinterActionTypes = {
  PRINTER_SET: 'PRINTER_SET'
};

export const UserPrefsActionTypes = {
  GET_USER_PREFS: 'GET_USER_PREFS',
  GET_USER_PREFS_COMPLETE: 'GET_USER_PREFS_COMPLETE',
  GET_USER_PREFS_ERROR: 'GET_USER_PREFS_ERROR',
  SET_USER_PREFS: 'SET_USER_PREFS',
  SET_USER_PREFS_COMPLETE: 'SET_USER_PREFS_COMPLETE',
  SET_USER_PREFS_ERROR: 'SET_USER_PREFS_ERROR'
}

export const MapTrackingActionTypes = {
  MAP_CHECK: 'MAP_CHECK',
  MAP_CHECK_COMPLETE: 'MAP_CHECK_COMPLETE',
  MAP_CHECK_ERROR: 'MAP_CHECK_ERROR'
}