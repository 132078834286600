import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'fast-deep-equal';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import Age from './Age';
import BirthYear from './BirthYear';
import clearSearchFilters from '../../utils/ClearSearchResults';
import clickOutside from '../../utils/clickOutsideDecorator';
import DateRange from './DateRange';
import { dateStringToObject } from './DateRange';
import {
  defaultFilterData,
  getSearchFiltersDataStream,
  setSearchFiltersDataStream as setFiltersData
} from '../../components/shared/Filters'
import { fetchAthleteKeycloak, getAthleteKeycloakStream, getAthleteStream } from '../../data/AthleteStreams';
import FilterTab from './FilterTab';
import Gender from './Gender';
import { getSearchTerm } from '../../data/SearchTerm';
import { getTokenRacerId } from '../../utils/isLoggedIn';
import { getUnclaimedResultsStream } from '../../data/UnclaimedSearchStreams';
import { Loading } from '../../components/shared/Loading';
import Location from './Location';
import Locations from './Locations';
import RaceTypes from './RaceTypes';
import { _searchFilters } from './styles';

const filterTypesMap = (filters = {}, props) => {
  if (!Object.keys(filters).length) {return {}}
  const raceTypeCount = filters.typeFilter
    ? Object.keys(filters.typeFilter).filter((_) =>
        filters.typeFilter[_] && !Object.keys(defaultFilterData.filters.raceTypes).includes(_)
      ).length
    : 0;
  return {
    'age': filters.ageFilter ? {
      inActiveDisplay: props.t('Age'),
      activeDisplay: `${filters.ageFilter.value[0]} - ${filters.ageFilter.value[1]}`,
      active: filters.ageFilter.enabled,
      component: Age,
      showOnPages: ['athlete'],
      disabled: false
    } : {},
    'birthdate': filters.birthDateFilter ? {
      inActiveDisplay: props.t('Birth Year'),
      activeDisplay: props.t('Birth Year') + ` - ${filters.birthDateFilter.value}`,
      active: filters.birthDateFilter.enabled,
      component: BirthYear,
      showOnPages: ['unclaimed'],
      disabled: false
    } : {},
    'gender': filters.genderFilter ? {
      inActiveDisplay: props.t('Gender'),
      activeDisplay: props.t('Gender') + ` - ` + props.t(`genderMap#${filters.genderFilter.value}`),
      active: filters.genderFilter.enabled && filters.genderFilter.value !== '',
      component: Gender,
      showOnPages: ['athlete'],
      disabled: false
    } : {},
    'daterange': filters.dateRangeFilter ? {
      inActiveDisplay: props.t('Date Range'),
      activeDisplay: [
        dateStringToObject(filters.dateRangeFilter.value.from).toLocaleDateString(),
        dateStringToObject(filters.dateRangeFilter.value.to).toLocaleDateString()
      ].join(' - '),
      active: filters.dateRangeFilter.enabled,
      component: DateRange,
      showOnPages: ['event'],
      disabled: false
    } : {},
    'location': filters.locationFilter ? {
      inActiveDisplay: props.t('Location'),
      activeDisplay: filters.locationFilter.value.location,
      active: filters.locationFilter.enabled && filters.locationFilter.value.location !== '',
      component: Location,
      showOnPages: ['athlete', 'event'],
      disabled: false
    } : {},
    'locations': filters.locationFilter ? {
      inActiveDisplay: props.t('Event Locations'),
      activeDisplay: props.t('Event Locations') + ` - ${filters.locationFilter.value.length}`,
      active: filters.locationFilter.enabled,
      component: Locations,
      showOnPages: ['unclaimed'],
      disabled: props.term === '' || (props.results && props.results.results ? !props.results.results.Success : true)
    } : {},
    'racetypes': filters.typeFilter ? {
      inActiveDisplay: props.t('Race Types'),
      activeDisplay: props.t('Race Types') + ` - ${raceTypeCount}`,
      active: raceTypeCount > 0,
      component: RaceTypes,
      showOnPages: ['athlete', 'event'],
      disabled: false
    } : {}
}};

class SearchFilters extends Component {
  static propTypes = {
    activeContext: PropTypes.string.isRequired,
    filters: PropTypes.object.isRequired,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    isMobile: false,
  };

  componentDidMount() {
    const racerId = getTokenRacerId();
    if (racerId) {
      fetchAthleteKeycloak(racerId)
    }
  }

  onClickOutside() {
    setFiltersData({activeTab: null})
  }

  // getting unnecessary updates from higher components
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props)
  }

  render() {
    const {
      filters,
      isMobile,
      activeContext,
      athleteKeycloak,
      searchFiltersData,
      athlete,
      t
    } = this.props;

    if(!searchFiltersData || !filters) return <div>Loading.....</div>;

    const {activeTab} = searchFiltersData;
    const isLoggedIn = getTokenRacerId();
    const filterTypes = filterTypesMap(filters, this.props);
    const hasSelectedValues = Object.keys(filterTypes)
      .map((key) => filterTypes[key])
      .some((val) => val.active);

    return (
      <div className="container" style={_searchFilters.wrapper(isMobile, hasSelectedValues)}>
        {
          Object.keys(filterTypes)
            .filter((type) => {
              const filter = filterTypes[type];
              return (filter.showOnPages || []).includes(activeContext);
            })
            .map((type) => {
              const filter = filterTypes[type];
              const Content = filter.component;
              if(
                isLoggedIn
                && (type === 'birthdate')
                && athleteKeycloak
                && athleteKeycloak.fetching
              ) {
                // Need to wait untill
                // data is returned to render Locations tab
                // for some reason stream won't re-render it itself
                return <div key={type} style={_searchFilters.loader}>
                  <Loading noTimeout={true} />
                </div>;
              }
              if(
                isLoggedIn
                && (type === 'location')
                && athlete
                && athlete.fetching
              ) {
                // Need to wait untill
                // data is returned to render Locations tab
                // for some reason stream won't re-render it itself
                return <div key={type} style={_searchFilters.loader}>
                  <Loading noTimeout={true} />
                </div>;
              }
              return(
                <FilterTab
                  key={type}
                  active={activeTab === type}
                  type={type}
                  disabled={filter.disabled}
                  onClickCallback={(type) => {
                    if (!filter.disabled){
                      setFiltersData({activeTab: activeTab === type ? null : type})}
                    }
                  }
                  content={
                    <Content
                      onClose={() => setFiltersData({activeTab: null})}
                      athlete={athlete}
                      t={t}
                      activeContext={activeContext}
                    />
                  }
                  isMobile={isMobile}
                  hasSelectedValue={filter.active}
                  label={filter.active ? filter.activeDisplay : filter.inActiveDisplay}
                />
              )
          })
        }
        {
          hasSelectedValues &&
          <div
            style={_searchFilters.clearFiltersMobile(isMobile)}
            onClick={() => clearSearchFilters(undefined, undefined, this.props.history)}
          >
            {this.props.t('Clear Filters')}
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(connectStream({
  searchFiltersData: getSearchFiltersDataStream,
  term: getSearchTerm,
  results: () => getUnclaimedResultsStream(),
  athlete: () => getAthleteStream(window.kc.tokenParsed ? window.kc.tokenParsed['athlinks-racer-id'] : 0),
  athleteKeycloak: () => getAthleteKeycloakStream(window.kc.tokenParsed ? window.kc.tokenParsed['athlinks-racer-id'] : 0),
})(withRouter(clickOutside(SearchFilters))));
