import { configs } from '../configs';
import { eventBus, streamHook } from './Streams';
import {setEventOptions} from './EventStreams';
import moment from 'moment';
import FetchApi from "../lib/anuket-http/FetchApi";

/**
 * Get races by master id
 * @param masterId
 * @returns {*}
 */
export function receiveAthlinksRacesByMasterId({ masterId }) {
  const streamId = `GetAthlinksRacesByMasterId-${masterId}`;
  const stream = streamHook(streamId, {});
  const url = `${configs.ATHLINKS_API_HOST}/MasterEvents/Api/${masterId}`;
  FetchApi.memoizeFetch(10000)(url).subscribe((msg) => {
    if (msg.success && msg.result) {
      setEventOptions(masterId, getEventRaces(msg.result.eventRaces));
      eventBus.publish(streamId, this.extractRaceCourses(msg));
    }
  }, (err) => console.log(err));
  return stream;
}

/**
 * Get races for aan event
 * @param events
 */
export function getEventRaces(events) {
  return events && events.map(({raceID, raceDate, eventCourses, isCurrentRace}) => ({
    isCurrentRace,
    id: raceID,
    date: raceDate,
    courses: eventCourses.map(({courseID, eventCourseID, courseName, coursePatternID, coursePattern}) => ({
      courseID, eventCourseID, courseName, coursePatternID, coursePattern
    }))
  }));
}

/**
 * Get courses using sockets
 * @param athlinksId
 * @returns {*}
 */
export function receiveAthlinksCourses({ athlinksId }) {
  const streamId = `GetAthlinksCourses-${athlinksId}`;
  let defaultMsg = {races: []};
  if(!!parseInt(athlinksId)) {
    const url = `${configs.ATHLINKS_API_HOST}/Events/Api/${athlinksId}/Courses`;
    defaultMsg = {isLoading: true};
    FetchApi.memoizeFetch(10000)(url).subscribe((msg) => {
      if (msg.result) {
        let courseNames = msg.result.courses.map((course) => {
          return {
            raceName: course.name,
            courseId: course.courseId,
            eventCourseId: course.eventCourseID
          };
        });

        let sorted = courseNames.sort((a, b) => a.raceName.localeCompare(b.raceName));
        eventBus.publish(streamId, {races: sorted});
      } else {
        eventBus.publish(streamId, {races: []});
      }
    });
  }

  return streamHook(streamId, defaultMsg);
}

/**
 * Get courses from an race
 * @param msg
 * @returns {*}
 */
export function extractRaceCourses(msg) {
  if (msg.success && msg.result && msg.result.eventRaces) {
    const eventCourses = msg.result.eventRaces.map((race) => {
      if (race.eventCourses.length) {
        return race.eventCourses.map((course) => {
          const epoch = moment.utc(race.raceDate).toDate().getTime();
          return {
            courseId: course.courseID,
            eventCourseId: course.eventCourseID,
            eventId: race.raceID,
            eventName: race.raceName,
            masterId: race.masterEventID,
            meters: course.distUnit,
            raceName: course.courseName,
            raceDate: race.raceDate,
            utcRaceDate: epoch,
            startEpoch: (epoch / 1000)
          }
        })
      }
      else {
        const epoch = moment.utc(race.raceDate).toDate().getTime();
        return [{
          eventId: race.raceID,
          eventName: race.raceName,
          masterId: race.masterEventID,
          raceDate: race.raceDate,
          utcRaceDate: epoch,
          startEpoch: (epoch / 1000)
        }]
      }

    });
    return {
      races: [].concat.apply([], eventCourses)
    }
  }
  else { return {races: []} }
}

export const RaceStreams = {
  receiveAthlinksCourses,
  receiveAthlinksRacesByMasterId,
  extractRaceCourses
};
