import * as AthletesNoble from './AthletesNoble'
import * as EventsNoble from './EventsNoble'
import * as MediaNoble from './MediaNoble'
import * as RacesNoble from './RacesNoble'
import * as ResultsNoble from './ResultsNoble'
import * as WeatherNoble from './WeatherNoble'

export const Nobles = {
  AthletesNoble,
  EventsNoble,
  MediaNoble,
  RacesNoble,
  ResultsNoble,
  WeatherNoble,
}