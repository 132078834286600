import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {
  searchAthletes,
  getAthleteSearchStream,
  getGender,
  getAge,
  getLocation
} from '../../data/SearchStreams';
import { NotFound } from '../../views/notFound/NotFound';
import {filterStyles, transition}  from '../../shared/styles';
import {AthleteList} from './AthletesList';
import {Pagination} from '../../components/shared/Pagination';
import { SearchPageHeader } from '../../components/shared/Elements';
import {_loading} from './styles';
import {isLoggedIn} from '../../utils/isLoggedIn';
import {
  getProfileSearchFilters
} from '../../data/SearchFilters';
import { setLastProfileSearchQuery } from '../../data/SearchStreams';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';

class AthleteSearchComponent extends Component {

  static propTypes = {
    results: PropTypes.object,
    searchTerm: PropTypes.string,
    lastProfileSearchQuery: PropTypes.object,
    isMobile: PropTypes.bool,
    onFilterChanged: PropTypes.func,
    afterFollow: PropTypes.func,
    filterValues: PropTypes.object,
    searchResults: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    afterFollow: () => {}
  };

  state = {
    page: 1,
    processing: false,
    canShowContent: false,
    initialLocation: false,
    willSearch: false,
    canSearch: _.size(this.props.searchTerm) > 3
  };

  componentDidMount() {
    this.showTimeout = window.setTimeout(() => {
      this.setState({canShowContent: true});
    }, 200);

    const {
      filterValues,
      searchTerm
    } = this.props;

    this.search({...filterValues, searchTerm});
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    const nextLocationFilter = nextProps.filterValues.locationFilter;


    if(nextLocationFilter.enabled && nextLocationFilter.value !== this.props.filterValues.locationFilter.value) {
      this.setState({initialLocation: true});
    }

    if(nextProps.searchResults === this.props.searchResults && this.state.willSearch) {
      this.setState({processing: true});
    } else {
      this.setState({processing: false, willSearch: false});
    }

    if(nextProps.searchTerm !== this.props.searchTerm) {
      const nextSearchTerm = nextProps.searchTerm;

      this.setState({
        canSearch: _.size(nextSearchTerm) > 3
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      filterValues: prevFilterValues,
      searchTerm: prevSearchTerm
    } = prevProps;

    const {
      filterValues,
      searchTerm
    } = this.props;

    if(prevFilterValues && this.didFiltersChanged(prevFilterValues)) {
      this.search({...filterValues, searchTerm});
    }
    else if(prevSearchTerm !== searchTerm) {
      this.searchDebounced({...filterValues, searchTerm});
    }
  }

  didFiltersChanged(oldFilterValues) {
    const {filterValues} = this.props;
    return !_.isEqual(filterValues, oldFilterValues);
  }

  /**
   * Perform search
   * @param query
   */
  search = (query) => {
    const {limit} = query;
    const {filterValues, searchTerm, lastProfileSearchQuery, searchResults, t} = this.props;
    const completeQuery = _.assign({}, filterValues, {searchTerm});

    if(((_.isEmpty(searchResults) || !_.isEqual(completeQuery, lastProfileSearchQuery)) && this.state.canSearch) || limit > 10) {
      this.setState({processing: true, willSearch: true});
      setLastProfileSearchQuery(completeQuery);
      searchAthletes(query, t);
    } else {
      this.setState({willSearch: false});
    }
  }

  searchDebounced = _.debounce(this.search, 1000, {
    leading: false,
  })

  componentWillUnmount() {
    this.searchDebounced.cancel();

    if (this.showTimeout) {
      window.clearTimeout(this.showTimeout);
    }

    this.setState({canShowContent: false});
  }

  loadMore = () => this.setState({page: this.state.page + 1}, () => {
    const {filterValues, searchTerm} = this.props;
    const {page} = this.state;
    this.search({...filterValues, limit:  page * 10, searchTerm});
  });

  isLoading = () => {
    const {friends: {fetching} = {}} = this.props;
    return isLoggedIn() && fetching === true;
  };


  buildSearchPageHeaderFilters = () => {
    const {filterValues} = this.props;
    return {
      gender: getGender(filterValues) || '',
      age: getAge(filterValues) || '',
      location: getLocation(filterValues) || ''
    };
  };

  render() {
    const {
      afterFollow,
      isMobile,
      searchTerm,
      searchResults: {
        total,
        athletes,
        error,
      } = {},
      t
    } = this.props;

    const {
      skip,
      processing,
      canShowContent,
      page,
      canSearch
    } = this.state;

    const errorStatus = error && '503';

    if(error){
      return (
        <NotFound errorStatus={errorStatus} />
      )
    }

    const limit = page * 10;
    const loadingNum = (page-1 || 0.5) * 10;
    const limitLengthArray = Array.from(Array(loadingNum));

    const searchMessage = !searchTerm
      ? t('Please enter a athlete name in the search box above to begin searching for athletes')
      : (searchTerm.length < 4
        ? t('Please narrow your search term to search for profiles') : '');

    return (
      <div style={filterStyles.contentBar}>
        <div className="container" style={filterStyles.content2(canShowContent)}>
          <div className="row">
            <div className="col-md-12 col-lg-12" style={ isMobile ? {padding: '0 4px'} : null}>
              <div style={{minHeight: '50px', marginBottom: '20px'}}>
                <SearchPageHeader
                  filterStyles={filterStyles}
                  processing={processing}
                  total={canSearch ? total : 0}
                  searchTerm={searchTerm}
                  filters={this.buildSearchPageHeaderFilters()}
                  title={t('Athletes')}
                  hasResults={canSearch}
                  showButtonToAddNew={false}
                  message={searchMessage}
                />
              </div>
              <div style={ transition.fade(!processing) }>
              {
                canSearch && athletes && !this.isLoading() && [
                  <AthleteList
                    key="athletes-list"
                    filters={this.state.filters}
                    athletes={athletes}
                    isMobile={this.props.isMobile}
                    friends={this.props.friends}
                    afterFollow={afterFollow}
                    t={t}
                  />,
                  <Pagination
                    key="athlete-pagination"
                    total={total}
                    isMobile={isMobile}
                    limit={limit}
                    skip={skip}
                    processing={processing}
                    onChange={this.loadMore}
                    t={t}
                  />
                ]
              }
              </div>

              {
                // Loading placeholder
              }
              {processing &&
                <div style={_loading.loadingContainer(processing)}>
                  {
                    limitLengthArray.map((item, index) =>
                      <LoadingAthlete key={`loader-${index}`} />
                    )
                  }
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    );
  }

}

export const AthleteSearch = withTranslation()(connectStream({
  filterValues: ({t}) => getProfileSearchFilters(t),
  searchResults: getAthleteSearchStream
})(AthleteSearchComponent));

const LoadingAthlete = () => (
  <div style={_loading.loadingRow} id='loading-athlete'>
    <div style={_loading.loadingAvatar}/>
    <div style={_loading.loadingDetailContainer}>
      <div style={_loading.loadingTitle}/>
      <div style={_loading.loadingSubtitle}/>
      <div style={_loading.loadingSubtitle}/>
    </div>
  </div>
);
