import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { fonts } from '../../shared/styles';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '24px',
  },
  title: {
    color: palette.grey[800],
    fontSize: 14,
    lineHeight: '21px',
    letterSpacing: 0.4,
    ...fonts.bold,
  },
  subtitle: {
    color: palette.grey[700],
    fontSize: 14,
    lineHeight: '21px',
    letterSpacing: 0.4,
    whiteSpace: 'nowrap',
    ...fonts.default,
  },
  button: {
    width: '250px',
    padding: '12px 0',
    backgroundColor: palette.common.white,
    borderRadius: 4,
    cursor: 'pointer',
    textAlign: 'center',
    border: `1px solid ${palette.brandColor}`,
    color: palette.brandColor,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'uppercase',
    marginTop: '12px',
    ...fonts.default,

    '&:hover': {
      backgroundColor: 'rgba(22, 169, 225, 0.05)',
    },

    [breakpoints.up('sm')]: {
      width: '100%',
    },
  }
}))

const AddEventResultsButton = ({
  message = null,
  callback,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography className={styles.title}>
        {t('Are we missing results?')}
      </Typography>

      <Typography className={styles.subtitle}>
        {t('Let us know and we\'ll get them added.')}
      </Typography>

      <button
        className={styles.button}
        onClick={callback}
      >
          {message || t('ADD MISSING RESULTS')}
      </button>
    </div>
  );
};

export { AddEventResultsButton };
