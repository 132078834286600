import { ReplaySubject } from 'rxjs';
import { distanceConverter } from '../lib/anuket';
import {
  fetchMasterEvent,
  getMasterEventStream
} from '../data/MasterEventStreams';

/**
 * @property {number} masterEventID
 * @property {[]} eventRaces
 * @property {Number} prevRaceID
 */
export class MasterEvent {
  constructor(data = {}) {
    Object.assign(this, data);
  }

  static get = (masterId) => {
    const subject = new ReplaySubject(1);
    getMasterEventStream(masterId).subscribe((masterEvent) => {
      subject.next(new MasterEvent(masterEvent));
    });
    fetchMasterEvent(masterId);
    return subject;
  };

  isLoaded = () => !!this.masterEventID;

  /**
   *
   * @param {number} eventId
   * @return {{raceID: *} | undefined}
   */
  getEvent = (eventId) => (this.eventRaces || [])
    .find(({ raceID }) => raceID === eventId);

  /**
   *
   * @param {number} eventId
   * @param {number} eventCourseId
   * @return {{eventCourseID: *} | undefined | *}
   */
  getEventCourse = (eventId, eventCourseId) => {
    const event = this.getEvent(eventId) || {};
    const { eventCourses = [] } = event;
    return eventCourses
      .find(({ eventCourseID }) => eventCourseID === eventCourseId)
  };

  /**
   * Retrieves course distance and converts it in miles
   * @param eventId
   * @param eventCourseId
   * @return {string}
   */
  getCourseDistance = (eventId, eventCourseId) => {
    const eventCourse = this.getEventCourse(eventId, eventCourseId) || {};
    const { distUnit = 0, distTypeID = 6 } = eventCourse;
    return distanceConverter.distanceTo(distUnit, distTypeID, 1).toFixed(1);
  };

  /**
   * Maps event course to course id. Needs for unofficial results mapping
   * @param {number} eventId
   * @return {*}
   */
  courseToEventCourseMapper = (eventId) => {
    const { eventCourses = [] } = (this.getEvent(eventId) || {});
    return eventCourses.reduce((mapped, ec) => {
      const { courseID } = ec;
      mapped[courseID] = ec;
      return mapped
    }, {});
  };
}
