import React from 'react';
import { withTranslation } from 'react-i18next';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { getEventByEventId } from '../../data/EventStreams'
import useEventMetadata from '../../hooks/useEventMetadata';
import { EventKioskResultsComponent } from './EventKioskResultsComponent';

export const FB_IMG_PATH = 'img/event/banner/facebook-icon/';

const PureEventKioskResults = (props) => {
  const athlinksEventId = props.eventId
  const eventMetadata = useEventMetadata(athlinksEventId, {
    skip: !athlinksEventId
  })
  return <EventKioskResultsComponent
    {...props}
    eventMetadata={eventMetadata}
  />
}

export const EventKioskResults = compose(
  withTranslation(),
  connectStream({
    event: ({ eventId }) => getEventByEventId(eventId)
  }),
  injectIntl,
)(PureEventKioskResults)
