import React from 'react'
import { withTranslation, Trans } from 'react-i18next'
import { summaryStyles } from './styles'
import { Weather } from '../weather/Weather'
import { connect } from 'react-redux'
import { convertDistance } from '@eventops/athlinks-lib-units'
import * as moment from 'moment-timezone'
import { LabelVerbosity } from '@eventops/athlinks-lib-units/dist/units'

const mapStateToProps = (state) => ({
  unitType: state.userPrefs.globalUnitType,
})

const RaceSummaryComponent = ({ isUpcoming, raceCardModel, t }) => {
  const {
    startDateTime: {
      timeInMilliseconds,
      timeZone
    },
    courses: [{
      distance: courseDistance, // only the first element
    }]
  } = raceCardModel

  const startDateTime = isNaN(timeInMilliseconds) ? undefined : moment.tz(parseInt(timeInMilliseconds), timeZone)
  const timeStart = startDateTime ? startDateTime.format('LTS').split(' ')[0] : undefined
  const timePeriod = startDateTime ? startDateTime.format('LTS').split(' ')[1] : t('No data available')
  const timeZoneAbbr = startDateTime ? moment.tz.zone(timeZone).abbr(timeInMilliseconds) : undefined
  const distanceDisplayData = convertDistance(courseDistance, {
    verbosity: LabelVerbosity.LONG,
    capitalize: true
  })
  const distance = distanceDisplayData.value ? distanceDisplayData.value.toString() : undefined
  const distanceLabel = distance ? distanceDisplayData.label : t('No data available')

  return (
    <div style={summaryStyles.summaryContainer}>
      <div style={summaryStyles.summaryTitle}>
        {t((isUpcoming ? 'Upcoming ' : '') + 'Race Info')}
      </div>
      <div>
        {
          <div style={summaryStyles.row}>
            <div className="col-4 px-0" style={summaryStyles.labelColumn}>
              {t('Start Time')}
            </div>
            <div className="col-7" style={summaryStyles.valueColumn}>
              {timeStart}
              <span style={summaryStyles.valueUnits(!!startDateTime)}> {timePeriod} {timeZoneAbbr}</span>
            </div>
          </div>
        }
        <div style={summaryStyles.row}>
          <div className="col-4 px-0" style={summaryStyles.labelColumn}>
            {t('Distance')}
          </div>
          <div className="col-7" style={summaryStyles.valueColumn}>
            <Trans>
              {distance}
              <span style={summaryStyles.valueUnits(!!distance)}> {distanceLabel}</span>
            </Trans>
          </div>
        </div>
        <div style={summaryStyles.lastRow}>
          <div className="col-4 px-0" style={summaryStyles.labelColumn}>
            {t('Weather')}
          </div>
          <div className="col-7" style={Object.assign({}, summaryStyles.valueColumn, { lineHeight: '2em' })}>
            <Weather event={{ startTime: timeInMilliseconds, timeZone }} type="resultsSummary" />
          </div>
        </div>
      </div>
    </div>
  )
};

const raceSummaryComponentConnected = connect(mapStateToProps)(RaceSummaryComponent);

export const RaceSummary = withTranslation()(raceSummaryComponentConnected);
