import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {AthletePhoto} from '../athlete/AthletePhoto';
import {Location} from './Location';
import {fetchAthleteSuggested} from '../../data/AthleteStreams';
import {suggestionStyles} from '../athlete/styles';
import {FollowButton} from '../shared/Follow';
import {isLoggedIn} from '../../utils/isLoggedIn';
import {fetchAthleteFollowing} from '../../data/AthleteStreams';
import {isAFriend} from '../../shared/util';
import { styles } from './styles';

class SuggestionsComponent extends Component {

  static propTypes = {
    athleteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    suggested: PropTypes.array,
    t: PropTypes.func
  };

  updateFollowersAndSuggestions = () => {
    fetchAthleteFollowing(this.props.athleteId);
    fetchAthleteSuggested(this.props.athleteId);
  };

  render() {
    const {
      suggested,
      athleteId,
      isMobile,
      t
    } = this.props;

    if (!suggested) return null;
    return (
      <div style={suggestionStyles.suggestionCard(isMobile)} id={'you-may-know'}>
        <div style={suggestionStyles.suggestionTitle} className="suggestions-title">
          {t('You May Know')}
        </div>
        {
          suggested
            .slice(0, 4)
            .map((item) => (
              <div key={item.RacerId} style={suggestionStyles.suggestionRow} className="row">
                <div className="col-9">
                  <div className="row">
                    <div style={suggestionStyles.followerRowPhotoCell} className="col-3">
                      <AthletePhoto
                        isMember={!!item.RacerId}
                        size='Small'
                        name={item.DisplayName}
                        photo={item.LogoUrls}
                        width={50}/>
                    </div>
                    <div style={suggestionStyles.suggestionRowInfo} className="col-9 suggestionRowInfo">
                      <Link to={`/athletes/${item.RacerId}`} style={suggestionStyles.suggestionRowName} className='athlete-name-suggestion'>
                        {item.DisplayName}
                      </Link>
                      <Location
                        city={item.City}
                        state={item.StateProvAbbrev}
                        country={'US'}
                        searchType={null}
                        style={{fontSize: 12}}
                        icon={null}/>
                    </div>
                  </div>
                </div>
                <div className="col-3" style={styles.followButton}>
                   <FollowButton
                    buttonType='rounded'
                    isFriend={isAFriend(suggested, athleteId)}
                    isLoggedIn={isLoggedIn()}
                    onChange={this.updateFollowersAndSuggestions}
                    CountryId3={item.CountryID3}
                    StateProvAbbrev={item.StateProvAbbrev}
                    City={item.City}
                    DisplayName={item.DisplayName}
                    Photos={item.LogoUrls}
                    isMobile={isMobile}
                    {...item}
                    />
                </div>
              </div>
            ))
        }
      </div>
    );
  }
}

export const Suggestions = withTranslation()(SuggestionsComponent);
