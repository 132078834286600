import React from 'react'

import { CuratedCategoryCarousel } from '../../components/home/curatedEvents/CuratedCategoryCarousel'

const CuratedCarouselContainer = ({children, ...props}) => {
  return (
    <CuratedCategoryCarousel {...props}>
      {children}
    </CuratedCategoryCarousel>
  );
};

export { CuratedCarouselContainer }
