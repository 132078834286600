import React, {Component} from 'react';
import {Range} from 'rc-slider';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';

import FilterControls from './FilterControls';
import MobileFilterHeader from './MobileFilterHeader';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters'
import {_filterTab} from './styles';
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';

const streamId = 'profile-search-filters-stream';

class Age extends Component {

  constructor(props) {
    super(props);
    this.fromAge = defaultFilterData.filters.fromAge;
    this.toAge = defaultFilterData.filters.toAge;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.values && nextProps.values) {
      this.fromAge = nextProps.values.ageFilter.value[0];
      this.toAge = nextProps.values.ageFilter.value[1];
    }
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const {fromAge, toAge} = this;
    const {values, history} = this.props;
    const filters = {
      ...values,
      ageFilter: {
        enabled: fromAge !== defaultFilterData.filters.fromAge || toAge !== defaultFilterData.filters.toAge,
        value: [fromAge, toAge]
      }
    };
    setFilterValues(streamId, filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  customHandle = ({index, offset}) => {
    return <div
      style={_filterTab.handle(offset)}
      key={index}
    />
  };

  render() {
    const {
      isMobile,
      onClose,
      t
    } = this.props;

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.ageFilterWrap(isMobile)}>
        {
          isMobile && <MobileFilterHeader
            title={t('Age')}
            onClose={onClose}
          />
        }
        <div style={{ padding: 12 }}>
          <div key='range' style={_filterTab.rangeWrap}>
            <div style={{..._filterTab.rangeData, paddingTop: 0}}>
              <div style={_filterTab.rangeTitle}>{t('Change Age Range')}</div>
              <div style={_filterTab.rangeValue}>{this.fromAge}-{this.toAge}</div>
            </div>
          </div>
          <div style={{paddingRight: 25, paddingLeft: 25}}>
            <Range
              min={defaultFilterData.filters.fromAge}
              max={defaultFilterData.filters.toAge}
              defaultValue={[this.fromAge, this.toAge]}
              value={[this.fromAge, this.toAge]}
              onChange={(value) => {
                this.fromAge = value[0];
                this.toAge = value[1];
                this.forceUpdate()
              }}
              handle={this.customHandle}
            />
          </div>
        </div>
        <FilterControls
          style={{ justifyContent: 'flex-end' }}
          onSave={this.submitFilter}
          onReset={() => {
            this.fromAge = defaultFilterData.filters.fromAge;
            this.toAge = defaultFilterData.filters.toAge;
            this.forceUpdate();
          }}
        />
      </div>
    )
  }
};

export default withTranslation()(connectStream({
  values: () => getFilterValues(streamId)
})(withRouter(Age)));
