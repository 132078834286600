import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/remote-config'
import { env } from '../../configs'

const firebaseConfig = env === 'production'
  ? {
    apiKey: "AIzaSyBg5nGnkDv2CJeVnrtaO_uwWUQfrhYbIjs",
    authDomain: "athlinks-159018.firebaseapp.com",
    projectId: "athlinks-159018",
    storageBucket: "athlinks-159018.appspot.com",
    messagingSenderId: "845497580406",
    appId: "1:845497580406:web:3b766daad83e3c09129857",
    measurementId: "G-2QPY4VKFP3"
  }
  : {
    apiKey: "AIzaSyAC7UceKwvM66UQFbf59ENyxUoIhuU9xh4",
    authDomain: "athlinks-staging.firebaseapp.com",
    projectId: "athlinks-staging",
    storageBucket: "athlinks-staging.appspot.com",
    messagingSenderId: "408563887489",
    appId: "1:408563887489:web:05619c6950e13b6ced4c80",
    measurementId: "G-85127F79ZD"
  }
const app = firebase.initializeApp(firebaseConfig)
app.analytics()
export default app