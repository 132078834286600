import React from 'react';
import HomeSectionTitle from './HomeSectionTitle';
import { homeHighlights as styles, homeText } from './styles';
import { Link } from 'react-router-dom';
import { SignUp } from '../modal/LogInOrSignUp';
import { signUp } from '../../utils/auth';
import { configs } from '../../configs';

const HighlightBlade = (props) => {
  const _s = styles(props);
  const textStyles = homeText(props);

  const {
    index,
    titleText, subtitleText,
    paragraphText,
    img,
    route,
    routeText,
    isSignUp,
    isLoggedIn,
    t
  } = props;

  const isEven = (index+2) % 2 === 0;

  const bodyStyle = Object.assign({}, textStyles.homeBody, {maxWidth: 450} );

  const titleID = titleText.toLowerCase().replace(' ', '-');

  return (
    <section style={ _s.homeBar(isEven) } id={`homepage-${titleID}`} className="blades">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div style={ _s.textContainer(isEven) }>
              <div style={{marginBottom: 11}}>
                <HomeSectionTitle
                  id={titleText.toLowerCase().replace(' ', '-')}
                  title={titleText}
                  subtitle={subtitleText}
                  {...props}
                />
              </div>
              <p id={`paragraph-${titleID}`} style={ bodyStyle }>{ paragraphText }</p>
             {
               !isLoggedIn && <div>
                 {
                    isSignUp ?
                    <SignUp style={{textTransform: 'capitalize'}} id={`search-${titleID}`} t={t} />
                    :
                    <Link to={ route } style={ _s.ctaLinkBtnStyle } id={`search-${titleID}`}>
                        { routeText }
                    </Link>
                  }
                </div>
             }
            </div>
            {isSignUp ?
              <a id={img ? `image-${titleID}` : ''} href={window.kc.createRegisterUrl()} style={ _s.ctaLinkBtnStyle }><img src={ img } alt={''} style={ _s.img(isEven) }/></a>
              :
              <Link to={ route } style={ _s.ctaLinkBtnStyle }><img src={ img } alt={''} style={ _s.img(isEven) } id={img ? `image-${titleID}` : ''} /></Link>
            }
          </div>
        </div>
      </div>
    </section>
  )
};


export const HighlightBlades = (props) => {
  const {
    t
  } = props;

  const searchLinks = [
    {
      titleText: t('Claim Results'),
      subtitleText: t('Your Times Are Timeless'),
      paragraphText: t('With the largest collection of results at your '
        + 'fingertips, let Athlinks do the tracking for you. If you\'ve crossed a '
        + 'finish line in the last 10 years, you\'ll find yours here. Athlinks makes'
        + ' finding, claiming and sharing all your hard-earned results easy.'),
      img: `${configs.cdnUrl}/images/home/home_1.jpg`,
      route: `${configs.bragiUrl}/signup?${signUp()}`,
      routeText: t('Sign Up'),
      isSignUp: true,
    },
    {
      titleText: t('Make Connections'),
      subtitleText: t('Connect With Friends and Rivals'),
      paragraphText: t('The Athlinks community helps you keep up with your '
        + 'friends, compare yourself to rivals and easily get to what matters most. '
        + 'Join friends at upcoming races, celebrate their finishes from afar and '
        + 'challenge each other to conquer new goals.'),
      img: `${configs.cdnUrl}/images/home/home_2.jpg`,
      route: `${configs.bragiUrl}/signup?${signUp()}`,
      routeText: t('Sign Up'),
      isSignUp: true,
    },
    {
      titleText: t('Discover Events'),
      subtitleText: t('Athlinks Exclusive Events'),
      paragraphText: t('Whether you\'re searching for races to add to your '
        + 'calendar, checking your race day results, or getting more information '
        + 'about an event you\'ve run, details about your events shouldn\'t be hard '
        + 'to find.'),
      img: `${configs.cdnUrl}/images/home/home_3.jpg`,
      route: `${configs.bragiUrl}/signup?${signUp()}`,
      routeText: t('Sign Up'),
      isSignUp: true,
    }
  ]

  return (
    <div>
      {
        searchLinks.map((block, idx) => (
          <HighlightBlade {...block} {...props} index={idx} key={idx} />
        ))
      }
    </div>
  )
};
