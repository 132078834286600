import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom';
import {DropDown} from '../../components/shared/Elements';
import _ from 'lodash';

const mapStateToProps = () => ({
  dispatch: undefined,
});

class EventResultsFiltersComponent extends Component {
  static propTypes = {
    masterId: PropTypes.number,
    masterData: PropTypes.array,
    courseData: PropTypes.array,
    eventResultsFilters: PropTypes.object.isRequired,
    from: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    metaLoading: PropTypes.bool,
    t: PropTypes.func,
  };

  componentWillUnmount() {
    const {
      onPage,
    } = this.props;

    onPage(0);
  }

  getEventDates(masterData) {
    return masterData
      .map((event) => ({value: event.value, text: event.text}));
  }

  getCourses(courseData = []) {
    const {t} = this.props;

    const allCourses = {value: 0, text: t('All Race Types')};
    const courses  = courseData
      .map((course) => ({value: course.eventCourseId, text: course.eventCourseName}));

    return [allCourses].concat(courses);
  }

  getDivisions(courseId, courseData = []) {
    const {t} = this.props;

    const course = courseData.find((course) => course.eventCourseId === courseId);
    const overallDivision = {value: 0, text: t('Overall')};

    return !course
      ? [overallDivision]
      : course.metadata.divisions.map((division) => ({value: division.id, text: division.name}));
  }

  getSplits(courseId, courseData = []) {
    const {t} = this.props;

    const course = courseData.find((course) => course.eventCourseId === courseId);
    const allSplits = {value: 0, text: t('All Splits')};
    const metaSplits = course
      ? course.metadata.intervals.map((interval) => ({value: interval.id, text: interval.name}))
      : [];

    return [allSplits].concat(metaSplits);
  }

  handleChange(ids) {
    this.props.onChange(ids);
  }

  renderDateFilter() {
    const {
      masterData,
      t,
      eventResultsFilters,
    } = this.props;

    const date = eventResultsFilters.date;

    const dateOptions = this.getEventDates(masterData);

    return (
      <DropDown
        id='eventDate'
        label={t('Event Date')}
        options={dateOptions}
        value={date}
        onChange={(e) => this.handleChange({date: e.target.value, course: undefined, division: undefined, split: undefined})}
        disabled={false}
        style={{width: '100%'}}
      />
    );
  }

  renderCourseFilters() {
    const {
      courseData,
      isCTLive,
      metaLoading,
      t,
      eventResultsFilters,
    } = this.props;

    if(metaLoading || !courseData) {
      return null;
    }

    const {date, course, division, split} = eventResultsFilters;

    const courseOptions = this.getCourses(courseData);
    const divisionOptions = this.getDivisions(course, courseData);
    const splitOptions = this.getSplits(course, courseData);

    const courseValue = course || 0;
    const divisionValue = division || divisionOptions[0].value;
    const splitValue = split || splitOptions[0].value;

    const courseDisabled = _.size(courseOptions) < 2;
    const divisionDisabled = !course || (_.size(divisionOptions) < 2);
    const splitDisabled = !course || (_.size(splitOptions) < 2) || !isCTLive;

    return (
      <>
        <DropDown
          id='race'
          label={t('Race')}
          options={courseOptions}
          value={courseValue}
          onChange={(e) => this.handleChange({date, course: e.target.value, division: undefined, split: undefined})}
          disabled={courseDisabled}
        />

        <DropDown
          id='division'
          label={t('Division')}
          options={divisionOptions}
          value={divisionValue}
          onChange={(e) => this.handleChange({date, course, division: e.target.value, split: splitValue})}
          disabled={divisionDisabled}
        />

        <DropDown
          id='split'
          label={t('Split')}
          options={splitOptions}
          value={splitValue}
          onChange={(e) => this.handleChange({date, course, division: divisionValue, split: e.target.value})}
          disabled={splitDisabled}
        />
      </>
    )
  }

  render() {
    const { masterData } = this.props;

    if (!masterData) {
      return null;
    }

    return (
      <>
        {this.renderDateFilter()}
        {this.renderCourseFilters()}
      </>
    );
  }
}

export const EventResultsFilters = connect(mapStateToProps)(withTranslation()(withRouter(EventResultsFiltersComponent)));
