import React from 'react';

import { AddFriend } from '../components/control/AddFriend';
import { ClaimResult } from '../components/control/ClaimResult';
import {Follow} from '../components/shared/Follow';

export const ControlFactory = {
  get: (modal, props) => {
    const key = modal.props.control;
    const controls = {
      addFriend: () => (<AddFriend modal={modal} {...props}/>),
      claimResult: () => (<ClaimResult modal={modal} {...modal.props} {...props}/>),
      follow: () => <Follow modal={modal} {...modal.props} {...props}/>
    };

    return controls[key] ? controls[key]() : null;
  }
};



