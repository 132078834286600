import { DivisionResultMetadata, EntryIntervalResult, IntervalResultMetadata, ReigniteResultsDataSource } from '../types.results.reignite'
import transformReigniteEntryIntervalPace from './transformReigniteEntryIntervalPace'
import transformReigniteEntryIntervalTime from './transformReigniteEntryIntervalTime'

export default function transformReigniteIntervalResults({
  division,
  interval,
  results,
  source,
}: {
  division: DivisionResultMetadata
  interval: IntervalResultMetadata
  results: EntryIntervalResult[]
  source: ReigniteResultsDataSource
}) {
  return results.map(({
    chipTimeInMillis,
    gunTimeInMillis,
    id,
    location,
    rankings,
    status,
    ...pii
  }) => ({
    ...location,
    ...pii,
    // do not pass AZP entry id's to props or claiming breaks
    entryId: source === 'mssql' ? id : 0,
    entryStatus: status,
    genderRank: rankings.gender,
    intervalRaceType: interval.raceType,
    overallRank: rankings.overall,
    pace: transformReigniteEntryIntervalPace({
      chipTimeInMillis,
      division,
      gunTimeInMillis,
      interval,
    }),
    primaryBracketRank: rankings.primary,
    time: transformReigniteEntryIntervalTime({
      chipTimeInMillis,
      division,
      gunTimeInMillis,
      interval,
    }),
    thirdPartyEntryId: source === 'mssql' ? 0 : id,
  }))
}
