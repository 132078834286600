import React  from 'react';
import PropTypes from 'prop-types';
import { _pagination } from '../../views/athleteSearch/styles';

/**
 * @deprecated use components/shared/Pager.js instead
 * @param {*} param0
 */
const Pagination = ({ total, limit, skip, onChange, processing, t }) => {
  const isLastPage = skip >= total - limit;
  return (
    <div style={ _pagination.moreContainer }>
    {
      !isLastPage &&
      <div
        id='load-more-link'
        style={ _pagination.moreButton(processing) }
        onClick={onChange.bind(null, limit )}
      >
         {processing ? t('loading...') : t('Load More') }
      </div>
    }
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  limit: PropTypes.number,
  skip: PropTypes.number,
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  limit: 10,
  skip: 0,
  onChange: () => {},
};

export { Pagination };
