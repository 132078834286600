import { colors} from '../../../shared/styles';
import {shouldShowSignUpBanner} from '../../../utils/isLoggedIn';

export const styles = {
    curatedEventCard: {
        content: (isMobile) => ({
            WebkitTouchCallout: isMobile ? 'none' : 'auto',
            color: colors.textColor,
            textAlign:'center',
            width:'100%',
            height:'100%'
          }),
          event: {
            position:'absolute',
            height:'100%',
            width:'100%',
            top:0,
            marginTop:-1,
            left:0,
            backgroundColor:'rgba(193,193,193,0.25)'
          },
          eventName: {
            position:'absolute',
            top:225,
            paddingLeft:24,
            width: '100%',
            zIndex: 1
        },
          city: {
            position:'absolute',
            fontSize: 18,
            backgroundColor:colors.white,
            color:colors.textColor,
            padding:10,
            textAlign:'center',
            textTransform:'uppercase',
            top: 80,
            width:'50%'
          },
          lightInfo: {
            padding: 10,
            fontSize: '13px',
            textAlign:'left',
            fontStyle: 'italic',
            color: colors.textColor,
            marginBottom: '8px',
          },
          heroContainer: (isActive) => ({
            zIndex: isActive ? 10 : 0,
            opacity: isActive ? 1 : 0.5,
            height: '100%'
          })
    },
    curatedEventDetail: {
        container: {
            position:'relative',
            width:'100%'
          },
          content: {
            position:'absolute',
            width:'100%',
            height:'100%',
            top:0,
            left:0,
            opacity:0.2,
          },
          main: {
            position:'relative',
            left:0,
            top:0,
            width:'100%'
          },
          friends: {
            textAlign:'left',
            verticalAlign:'top',
            maxWidth:300,
            display: 'inline-block'
          },
          pStyle: {
            color: colors.textColor,
            fontSize: 17,
            lineHeight: 1.5
          }
    },
    curatedEvents: {
        container: (scaledWidth, scaledHeight) => ({
            position:'relative',
            width:scaledWidth,
            height:scaledHeight
        }),
        cardContainer: (isMobile, isActive, marginLeft, paddingSizeleft) => ({
            position:'absolute',
            marginTop: isActive ? 0 : 15,
            backgroundColor:colors.lightGrey5,
            marginLeft: !isMobile ? marginLeft : paddingSizeleft,
            zIndex: isMobile && isActive ? 9999 : 1,
            width:'100%',
            height:'100%'
        }),
        row: {
            paddingTop:24
        },
        logo: {
            minWidth: '100px',
            maxWidth: '120px',
            marginBottom: '8px',
            height: 'auto',
            width: '100%',
            objectFit: 'contain',
        },
        buttonContainer: {
            width: 200,
            marginTop: 10,
            marginBottom: 30,
            bottom: 0
        },
        register: {
            minWidth:120,
            marginBottom: 20,
            textTransform: 'uppercase',
            height: 42,
            fontFamily: 'ProximaNovaRegular',
            color:colors.white,
            fontSize: 18,
            borderRadius: 2,
            backgroundColor: colors.blue
        },
        content: {
            backgroundColor:colors.lightGrey2,
            paddingBottom:40
          },
          rowContainer: {
            padding: 10,
            marginBottom: 20,
            background: colors.white,
            boxShadow: '0 1px 2px 0 rgba(0,0,0,0.15)'
          },
          nameContent: (isMobile, paragraphPaddingLeft, mobilePadding) => ({
            backgroundColor: !isMobile ? colors.white : 'transparent',
            paddingTop: isMobile ? 50 : 25,
            paddingBottom:25,
            marginTop: isMobile ? 30 : (shouldShowSignUpBanner(isMobile) ? 0 : 80),
            width: isMobile ? '100%' : 'auto',
            paddingLeft: !isMobile ? paragraphPaddingLeft : mobilePadding+10
          }),
          name: (isMobile) => ({
            fontWeight:'200',
            fontSize:'32px',
            lineHeight:'25px',
            paddingLeft: isMobile ? 0 : 30,
            color: colors.textColor,
            fontFamily: 'ProximaNovaRegular'
          }),
          loading: {
            marginTop:100,
            height: 100,
            textAlign:'center'
          },
          loadMore: {
            textAlign: 'center',
            width: '100%',
            background: colors.blue,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: '0%',
            marginBottom: 20
          },
          activeContent: {
            marginTop: 20
          },
          activeEvent: {
            width:'100%'
          },
          rightIcon: {
            marginTop:-2,
            width:17,
            height:17,
            color:'rgba(255,255,255,0.8)'
          },
          rightColumn: {
            boxShadow: '0 1px 2px 0 rgba(0,0,0,0.15)',
            backgroundColor: colors.white,
            padding: 20,
            marginBottom: 20
          },
          carouselContainer: (isMobile) => ({
            backgroundColor: !isMobile ? colors.white: null
          }),
          leftStyle: () => ({
            ...styles.navButtons,
            marginTop:277,
            marginLeft: -19
          }),
          leftSideMobile: () => ({
            ...styles.navButtons,
            marginLeft: -48,
            backgroundColor: 'transparent',
            color: colors.textColor,
            marginTop: 100
          }),
          rightSideMobile: () => ({
            ...styles.navButtons,
            marginRight: -50,
            backgroundColor: 'transparent',
            color: colors.textColor,
            marginTop: 100
          }),
          separatorLine: {
            width:'100%',
            height:1,
            backgroundColor:'lightgrey'
          },
          banner: {
            width: 300,
            height: 250
          },
          curatedEventsMap: {
            height: 250,
            width: '100%'
          }
    },
    curatedEventsMap: {
        circleWrap: (size, color) => ({
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            cursor: 'pointer',
            width: size,
            height: size,
            backgroundColor: color,
            borderRadius: '100%'
        }),
        locationMarkerWrap: (zIndex) => ({
            position: zIndex > 0? 'absolute': 'relative',
            zIndex
        }),
        googleMapContainer: {
            width:'100%',
            height:'100%'
        }
    },
    navButtons: {
        cursor: 'pointer',
        width: 50,
        height: 50,
        backgroundColor: colors.white,
        color:'lightgrey',
        zIndex:1
    }
}
