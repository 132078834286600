import React from 'react';
import Plus from 'react-icons/lib/fa/plus';
import {_filterTab} from './styles';

const Accordion = ({
  title,
  children,
  active,
  onHeaderClick,
  type,
  typeCount
  }) => {
  return (
    <div style={_filterTab.accordionWrap}>
      <div
        onClick={() => onHeaderClick(type)}
        style={_filterTab.accordionHeader(active)} className="header">
        {(title || '').toUpperCase()} { !!typeCount ? `- ${typeCount}` : '' }
        {!active ? <Plus size={15} /> : <div />}
      </div>
      {active && <div style={_filterTab.accordionContent} className="content">
        {children}
      </div>}
    </div>
  )
};

export default Accordion;
