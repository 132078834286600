import parseToken from '../lib/bastetjs/utils/parseToken';

export async function getToken() {
  const keycloak = window.kc;
  const current_time = new Date().getTime() / 1000;
  if (keycloak.tokenParsed && current_time > keycloak.tokenParsed['exp']) {
    await keycloak.updateToken(keycloak.timeSkew + 5);
  }
  return keycloak.token || '';
}

export function isLoggedIn(token) {
  /*
    If we use getToken() it'll return a promise, and we want to be able to
    tell if someone is logged in without an async function call
  */
  const current_time = new Date().getTime() / 1000;
  const racerIdValid = getTokenRacerId(token) > 0;
  const tokenValid = window.kc?.refreshTokenParsed?.['exp'] > current_time;

  //If token is passed in, treat it differently than if we rely on keycloak.js refresh token
  return racerIdValid && (!!token || tokenValid);
}

export const isAdmin = () => window.kc?.hasResourceRole('admin') || window.kc?.hasRealmRole('admin')

export const getUserBetaAccess = () => {
  return Object.keys(window.kc?.resourceAccess).reduce((a, c, i) => {
    return [
      ...a,
      ...window.kc.resourceAccess[c]?.roles,
    ]
  }, [])
}

export const hasBetaAccess = (featureName) => {
  if (!featureName || featureName.length === 0) return false
  const featureNameWithPrefix = featureName?.startsWith('beta-') ? featureName : `beta-${featureName}`
  return window.kc?.hasResourceRole(featureNameWithPrefix)
}

export function getTokenRacerId(token) {
  let racerId = parseInt(token && parseToken.parse(token)?.['athlinks-racer-id'])

  if (!racerId) {
    racerId = window.kc?.tokenParsed?.['athlinks-racer-id']
  }

  return racerId || 0
}

export function getClaim(key, token) {
  const keycloak = window.kc;
  token = token || keycloak.token || '';
  return token ? parseToken.parse(token)[key] : '';
}

/**
 * Check if sign up banner should be rendered. Not rendering for mobile
 * @TODO Showing only on ERP for now. Check references to this methods later
 * @return {boolean}
 */
export function shouldShowSignUpBanner() {
  const currentHref = window.location.href;
  const isErrorPage = !!currentHref.match('error');
  return !isLoggedIn() && !isErrorPage;
}
