import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { headerStyles } from './styles';

export class AnimatedHeader extends Component {
  
  scrollTracking = false;
  
  static propTypes = {
    scrollThreshold: PropTypes.number,
    height: PropTypes.number,
    hasLiveBanner: PropTypes.bool,
    renderNormal: PropTypes.func.isRequired,
    renderSlideout: PropTypes.func.isRequired,
    showSlideoutOverride: PropTypes.bool
  };

  static defaultProps = {
    scrollThreshold: 200
  };

  state = {
    showSlideout: false
  };

  componentDidMount() {
    if (!this.props.showSlideoutOverride) {
      this.scrollTracking = true;
      window.addEventListener('scroll', this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.scrollTracking) {
      window.removeEventListener('scroll', this.onScroll);
      this.scrollTracking = false;
    }
  }

  render() {
    const {
      height,
      renderNormal,
      renderSlideout,
      showSlideoutOverride
    } = this.props;

    const { showSlideout } = this.state;

    const shouldShowSlideout = showSlideoutOverride ? showSlideoutOverride : showSlideout;


    return (
      <div style={headerStyles.headerContainer} id="animated-header">

        {
          // render the static header
        }
        {!shouldShowSlideout &&
          <div>
              {renderNormal()}
          </div>
        }

        {
          //Animate in and out the slideout-header
        }
        <div style={headerStyles.headerOverlay}>
          <div style={{ ...headerStyles.headerSlideout(shouldShowSlideout, height)}}>
            { renderSlideout() }
          </div>
        </div>

      </div>
    );
  }


  onScroll = (e) => {
    if (this.scrollTracking) {
      const scrollTop = e.srcElement.body.scrollTop;

      const { scrollThreshold } = this.props;
      const { showSlideout } = this.state;

      const shouldShowSlideout = scrollTop >= scrollThreshold;

      if(!showSlideout && shouldShowSlideout) {
        this.setState({
          showSlideout: true
        });
      } else if (showSlideout && !shouldShowSlideout) {
        this.setState({
          showSlideout: false
        });
      }
    }
  }

}
