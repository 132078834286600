import React from 'react'
import { withTranslation } from 'react-i18next'

import { style } from '../arpModalStyles'

const ClaimModalSpinnerHeaderComponent = (props) => {
  const {
    t
  } = props

  return(
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Claiming')}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t('We are working on adding this result to your profile.')}
      </div>
    </div>
  )
}

export const ClaimModalSpinnerHeader = (withTranslation()(ClaimModalSpinnerHeaderComponent))