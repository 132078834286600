import { Configure } from '../anuket-http';

function _getBaseUrl(type) {
  const baseUrl = Configure.getConfigs().heroImageUrl || null;
  if (!baseUrl) {
    const prodUrl = 'http://athlinks-heroes.s3.amazonaws.com/';
    console.warn('The heroImageUrl has not been configured! Defaulting to ' + prodUrl);
    return prodUrl + type + '/';
  }
  return baseUrl + type + '/';
}

function _getRandomIntInclusive(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// TODO: dynamic image size as defined by caller
function _getImageUrl(type, range, size) {
  const url = `${_getBaseUrl(type)}${_getRandomIntInclusive(1, range)}-${size}.jpg?date=${Date.now()}`;
  return url;
}

export function getHeroImageUrl(type, size) {
    type = type ? type.toLowerCase() : null;

    const typeMapping = {
      '5k run': ['running'],
      'adventure racing': ['offroadrun'],  //bad ux (might want unique picture for this)
      aquabike: ['swimming', 'biking'],
      aquathlon: ['swimming', 'running'],
      'canoe/kayak': ['paddletriathlon'],
      criterium: ['biking'],
      cycling: ['biking'],
      cyclocross: ['offroadbike'],
      duathlon: ['triathlon-multisport'],  //bad ux (could show swimming for duathlon)
      'fun run': ['funrun'],
      'half-marathon': ['running'],
      marathon: ['running'],
      'mountain biking': ['offroadbike'],
      'mountain climb': ['offroadrun'],
      'non-athletic (untimed)': ['funrun'],
      'off-road aquabike': ['swimmingoutdoor', 'offroadbike'],
      'off-road duathlon': ['offroadrun', 'offroadbike'],
      'off-road triathlon': ['swimmingoutdoor', 'offroadbike', 'offroadrun'], //bad ux (show running and biking on pavement)
      orienteering: ['offroadrun'],
      'paddle triathlon': ['paddletriathlon'],
      paddleboarding: ['paddletriathlon'],
      'race walking': ['running'],
      'ski - cross country': ['crosscountryski'],
      'snowshoe running': ['running'],  //bad ux (show people running on pavement not snow)
      'trail running': ['offroadrun'],
      'trail running & hiking': ['offroadrun'],
      'triathlon & multisport': ['triathlon-multisport'],
      untimed: ['funrun'],
    }
    //types not accounted for (will use default 'other' type):
    //  Stair Climb, Wheelchair, Inline Skating, Roller Skiing

    const rangeMapping = {
      biking: 4,
      crosscountryski: 4,
      funrun: 3,
      obstacle: 4,
      offroadbike: 4,
      offroadrun: 2,
      other: 1,
      paddletriathlon: 1,
      running: 4,
      swimming: 6,
      swimmingindoor: 1,
      swimmingoutdoor: 6,
      'triathlon-multisport': 20,
    };

    const typeArray = typeMapping[type] || [];
    const typeString = typeArray.length > 0
      ? typeArray[Math.floor(Math.random() * typeArray.length)]
      : type;
    const mappedType = rangeMapping[typeString];

    return mappedType
      ? _getImageUrl(typeString, mappedType, size)
      : _getImageUrl('other', rangeMapping['other'], size);
}