export const KEYS = {
  '0': 48,
  '1': 49,
  '2': 50,
  '3': 51,
  '4': 52,
  '5': 53,
  '6': 54,
  '7': 55,
  '8': 56,
  '9': 57,
  backspace: 8,
  del: 46,
  delete: 46,
  tab: 9,
  enter: 13,
  return: 13,
  esc: 27,
  space: 32,
  pageUp: 33,
  pageDown: 34,
  end: 35,
  home: 36,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  ';': 186,
  '=': 187,
  ',': 188,
  '-': 189,
  '.': 190,
  '/': 191,
  '`': 192,
  '[': 219,
  '\\': 220,
  ']': 221,
  A: 65,
  a: 65,
  B: 66,
  b: 66,
  C: 67,
  c: 67,
  D: 68,
  d: 68,
  E: 69,
  e: 69,
  F: 70,
  f: 70,
  G: 71,
  g: 71,
  H: 72,
  h: 72,
  I: 73,
  i: 73,
  J: 74,
  j: 74,
  K: 75,
  k: 75,
  L: 76,
  l: 76,
  M: 77,
  m: 77,
  N: 78,
  n: 78,
  O: 79,
  o: 79,
  P: 80,
  p: 80,
  Q: 81,
  q: 81,
  R: 82,
  r: 82,
  S: 83,
  s: 83,
  T: 84,
  t: 84,
  U: 85,
  u: 85,
  V: 86,
  v: 86,
  W: 87,
  w: 87,
  X: 88,
  x: 88,
  Y: 89,
  y: 89,
  Z: 90,
  z: 90,
  F1: 111,
  F2: 112,
  F3: 113,
  F4: 114,
  F5: 115,
  F6: 116,
  F7: 117,
  F8: 118,
  F9: 119,
  F10: 120,
  F11: 121,
  F12: 122
}

export const MODIFIERS = {
  control: 'ctrl',
  ctrl: 'ctrl',
  shift: 'shift',
  meta: 'meta',
  cmd: 'meta',
  command: 'meta',
  option: 'alt',
  alt: 'alt'
};

/**
 * Example use: 
 * handleKeyDown = asKeyboardEventListener('keydown')(KEYS.enter)((e) => {
 *   e.preventDefault();
 *   e.stopImmediatePropagation();
 * })
 * @param {string} eventType 
 */
export const asKeyboardEventListener = (eventType) => (...keys) => (callback, passThruIfNoEvent = true) => {
  if (typeof callback !== 'function') {
    throw new Error('Last parameter must be a function')
  }
  return (event) => {
    if (event instanceof KeyboardEvent) {
      let matched = event.type === eventType
      if (matched && event.ctrlKey && keys.indexOf(MODIFIERS.ctrl) === -1) {
        matched = false
      }
      if (matched && event.shiftKey && keys.indexOf(MODIFIERS.shift) === -1) {
        matched = false
      }
      if (matched && event.altKey && keys.indexOf(MODIFIERS.alt) === -1) {
        matched = false
      }
      if (matched && event.metaKey && keys.indexOf(MODIFIERS.meta) === -1) {
        matched = false
      }
      
      const withoutMods = keys.filter((x) => Object.values(MODIFIERS).indexOf(x) === -1)
      if (matched && withoutMods.length > 0 && withoutMods.indexOf(event.keyCode) === -1) {
        matched = false
      }
      if (matched) {
        callback.length ? callback(event) : callback()
      }
    }
    else if (passThruIfNoEvent) {
      callback()
    }
  }
}
