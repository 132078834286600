import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { styles } from './styles'
import { colors } from '../../shared/styles'
import { styles as typeaheadStyles } from '../shared/styles'
import { TypeAhead } from '../shared/TypeAhead'
import SearchIcon from 'react-icons/lib/md/search'
import { TypeaheadOption } from './TypeaheadOption'
import { TypeaheadLoader } from './TypeaheadLoader'

export class KioskSearchFormComponent extends Component {
  static propTypes = {
    eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    setSearchTerm: PropTypes.func,
    searchTerm: PropTypes.string,
    t: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.documentRef = React.createRef()
    this.state = {
      doSearchAgain: false,
      emptySelected: false,
      isFirstSearch: true,
      staleSearch: true,
    }
  }

  componentDidUpdate() {
    const { isLoading, searchTerm, } = this.props
    const { doSearchAgain, emptySelected, isFirstSearch, staleSearch } = this.state
    console.debug('componentDidUpdate', {isLoading, searchTerm, doSearchAgain, emptySelected, isFirstSearch, staleSearch})
    if (isLoading && (isFirstSearch || doSearchAgain)) this.setState({ isFirstSearch: false, doSearchAgain: false, staleSearch: false })
    if (isLoading && staleSearch) this.setState({ staleSearch: false })
    if (emptySelected && searchTerm && !isLoading) this.selectBestMatch()
  }

  openFullscreen = async () => {
    const elem = window.document.documentElement
    if (elem.requestFullscreen && this.props.mode !== 'dev') {
      return await elem.requestFullscreen()
    }
  }

  render() {
    const {
      eventId,
      isLightMode,
      isLoading,
      results,
      searchTerm,
      t
    } = this.props

    let { emptySelected, staleSearch } = this.state
    let flattenedResults = results?.map(result => ({ ...result, eventId, })) || []

    return (
      <div
        style={styles.searchInputContainer}
        id='kioskInputContainer'
        ref={this.documentRef}
        onClick={this.openFullscreen}
      >
        <div
          style={{ width: '60em', ...styles.card(isLightMode) }}>
          <div>
            <h3 style={styles.searchFormHeading(isLightMode)}>
              {t('Find Your Result')}
            </h3>
          </div>
          <div style={{ position: 'relative' }}>
            <TypeAhead
              useMaterialUi={true}
              value={searchTerm}
              dropdownHeight={400}
              dropdownTop={50}
              hideDropdownOverride={!searchTerm || staleSearch}
              showDropdownOverride={emptySelected}
              width={'100%'}
              options={searchTerm ? flattenedResults : []}
              dropdownStyle={
                typeaheadStyles.typeAhead.infiniteScrollStyle(47, 'auto',
                  styles.searchSuggestionDropdown(isLightMode))
              }
              renderOption={props => <TypeaheadOption
                {...this.props}
                {...props}
              />}
              onChange={this.onTyped}
              onOptionSelected={this.onOptionSelected}
              searching={isLoading}
              keyboardNavigation={true}
              placeholder={t('Search for your Name or Bib Number')}
              inputStyle={styles.searchInput(isLightMode)}
              inputProps={{
                style: { display: 'flex', justifyContent: 'center' },
                className: `${isLightMode ? '' : 'darkVersion'}`,
                fullWidth: true,
                disableUnderline: true,
              }
              }
              loadingOption={() => <TypeaheadLoader
                {...this.props}
                {...this.state}
              />}
              onEmptySelection={this.onEmptySelection}
              autoFocus={true}
              InputIcon={() => <SearchIcon
                style={{
                  color: `${isLightMode ? colors.grey : 'white'}`,
                }}
                size={25}
              />}
            />

          </div>
        </div>
      </div>
    )
  }

  //text was typed into input
  onTyped = (e) => {
    this.props.setSearchTerm(e.target.value)
  }

  //clicked on an option or keyed Enter on a focused option
  // As long as the TypeAhead component is creating React Links in the dropdown list, this is an unecessary callback
  onOptionSelected = (option) => {
    this.props.onOptionSelected(option)
  }

  selectBestMatch = () => {
    const { results } = this.props
    if (results && results.length) {
      const { bib, eventCourseId } = this.findBestMatch()
      if (bib) {
        this.onOptionSelected({ bib, eventCourseId })
      }
    }
  }

  findBestMatch = () => {
    const { results = [], searchTerm, } = this.props
    const defaultReturn = { bib: null, displayName: null }

    const resultSearchExact = results.find(
      ({ bib, displayName }) => bib === searchTerm
        || displayName.toLowerCase() === searchTerm.toLowerCase()
    )

    if (resultSearchExact) {
      return resultSearchExact
    } else {
      const partialMatch = results.find(
        ({ displayName }) => displayName.toLowerCase().split(' ').includes(searchTerm.toLowerCase())
      )

      return partialMatch && results.length === 1 ? partialMatch : defaultReturn
    }

  }
}
