import React  from 'react';
import PropTypes from 'prop-types';
import UpcomingEvents from './UpcomingEvents';
import { styles } from './styles';

const Calendar = (
  {
    isMe,
    isMobile,
    races,
    calendar: {UpcomingRaces = [], SuggestedEvents=[]},
    type,
    title,
    liveEvent,
    noResultsMessage,
    fetching,
    athleteId,
    pendingClaims
  }) => {
  const data = (type === 'upcomingEvents' || type === 'liveEvents') ?
    UpcomingRaces : races;
  return (
    <div style={styles.calendarContainer(isMobile)} id={type}>
      <UpcomingEvents
        athleteId={athleteId}
        isMe={isMe}
        type={type}
        title={title}
        fetching={fetching}
        isMobile={isMobile}
        liveEvent={liveEvent}
        upcomingEvents={data}
        suggestedEvents={SuggestedEvents}
        noResultsMessage={noResultsMessage}
        races={races}
        pendingClaims={pendingClaims}
      />
    </div>
  );
};

Calendar.propTypes = {
  athleteId: PropTypes.string.isRequired,
  calendar: PropTypes.object,
  races: PropTypes.array,
  title: PropTypes.string.isRequired,
  friendsUpcoming: PropTypes.object,
  liveEvent: PropTypes.array,
  isMe: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  type: PropTypes.string.isRequired,
  noResultsMessage: PropTypes.string.isRequired,
  fetching: PropTypes.bool,
};

Calendar.defaultProps = {
  calendar: {},
  races: [],
  friendsUpcoming: {},
  fetching: false,
};

export default Calendar;
