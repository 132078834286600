/**
 * Builds date object
 * @param date
 * @return Date
 * @private
 */
export const _date = (date) => typeof date === Date ? date : new Date(date);

/**
 * Check if date is in past
 * @param date
 * @return boolean
 */
export const isInPast = (date) => _date(date) < new Date();

/**
 * Check if date is in future
 * @param date
 * @return boolean
 */
export const isInFuture = (date) => {
  return date ? _date(date).getTime() > new Date().getTime() : false;
};

export const isToday = (date) => {
  const todaysDate = new Date();
  return _date(date).setHours(0,0,0,0) === todaysDate.setHours(0,0,0,0);
};

/**
 * Check if date is today or in future
 * @param date
 * @return boolean
 */
export const isTodayOrFuture = (date) => {
  return isToday(date) || isInFuture(date);
};

/**
 * convert seconds to hh:mm:ss
 * @param {number} timeMs
 * @return {string}
 */
export const getGoalTime = (timeMs) => {
  const seconds = parseInt(timeMs / 1000 % 60);
  const minutes = parseInt((timeMs / 1000 / 60) % 60);
  const hours = parseInt(timeMs / 1000 / 3600);

  const hh = (hours < 10) ? '0' + hours : hours;
  const mm = (minutes < 10) ? '0' + minutes : minutes;
  const ss = (seconds < 10) ? '0' + seconds : seconds;

  return `${hh}:${mm}:${ss}`;
};

export const normalizeHHMMSS = (timeStr) => {
  const split = timeStr.split(':').map((val) => parseInt(val));
  const secs = (split[0] * 3600) + (split[1] * 60) + split[2];

  return getGoalTime(secs * 1000);
};

export const validateHHMMSS = (str) => {
  const re = /^[0-9]{2,}:[0-9]{2}:[0-9]{2}$/;
  return re.test(str);
};


export const dayDiffers = (start, end) => {
  return [
    spanDays(start, end),
    `${end[2]}, ${start[0]}`
  ].join(' - ');
};

export const shortDate = (date) => {
  const slice = date.slice(1);
  return `${slice[0]} ${slice[1]}, ${date[0]}`;
};

export const singleDate = (date) => {
  return `${date[3]}, ${date[1]} ${date[2]}, ${date[0]}`;
};

export const spanDays = (start, end) => {
  return `${start[3]} - ${end[3]}, ${start[1]} ${start[2]}`;
};

// export const time = (time) => {
//   return `${time[0]}:${time[1]}${time[2]}`;
// };

export const yearDiffers = (start, end) => {
  return [singleDate(start), singleDate(end)].join(' - ');
};

// takes a date an returns a string in the MM / DD / YYYY format
export const getDateInputString = (date) => {
  if(!date) {
    return undefined;
  }

  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return [month, day, year].join(' / ');
};

// takes a MM / DD / YYYY string and returns a date object without the local timezone applied
export const constructDateFromInputString = (dateString) => {
  const dateParts = (dateString || '').split(' / ');
  if (dateParts.length !== 3 || dateParts[2] < 1000) {
    return undefined;
  }

  const utcDate = new Date(Date.UTC(
    Number(dateParts[2]),
    Number(dateParts[0]) - 1,
    Number(dateParts[1]),
    0,
    0,
    0
  ));
  utcDate.setTime(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);

  return utcDate;
};

export const checkDateInputValue = (str, max) => {
  if (str.charAt(0) !== '0' || str === '00') {
    const parsedNum = parseInt(str);
    const num = isNaN(parsedNum) || parsedNum <= 0 || parsedNum > max ? 1 : parsedNum;

    return num > parseInt(max.toString().charAt(0)) && num.toString().length === 1 ? '0' + num : num.toString();
  }

  return str;
};
