import React from 'react';
import PropTypes from 'prop-types';
import {previewStyles} from './styles';
import {AthletePhoto} from './AthletePhoto';
import {Location} from '../shared/Location';

export const Preview = ({name, photo, city, state, country, isMobile}) => (
  <div
    style={previewStyles.previewContainer}>
    <div>
      <AthletePhoto
        size={isMobile ? 'Small' : 'Medium'}
        photo={photo}
        name={name}
        width={!isMobile ? 70 : ''}
      />
    </div>
    <div style={{marginLeft: 20}}>
      <div style={{fontSize: isMobile ? 18 : 24}}>{name}</div>
      <div>
        <Location
          city={city}
          state={state}
          country={country}
          searchType={null}
          icon={null}
          style={previewStyles.locationStyle(isMobile)}/>
      </div>
    </div>
  </div>
);

Preview.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.object,
  city: PropTypes.string,
  state: PropTypes.string,
  country: PropTypes.string,
  isMobile: PropTypes.bool.isRequired
};

