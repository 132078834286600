import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { BigRivalHand } from '../../../shared/icons'

const mapStateToProps = () => ({
  dispatch: undefined
})

const UnfollowAthleteSuccessComponent = (
  props
) => {
  const { t } = props
  return (
    <div style={style.medalContainer}>
    <div style={style.medal}>
      <BigRivalHand />
    </div>
    <div style={style.modalTitle}>
      {t('Athlete unfollowed!')}
    </div>
    <div style={{ ...style.modalSubtitle, ...style.subMessage }}>
      {t(`But can you still be friends?`)}
    </div>
  </div>
  )
}

export const UnfollowAthleteSuccess = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteSuccessComponent)

const UnfollowAthleteSuccessFooterComponent = (
  props
) => {
    const {
      dispatch,
      isMobile,
      t
    } = props

    const footerStyle = isMobile ? style.footerMobile : {}

    return (
      <div style={footerStyle}>
        <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
          <div style={style.doneButtonText}>
            {t('Done')}
          </div>
        </button>
      </div>
    )
}
export const UnfollowAthleteSuccessFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnfollowAthleteSuccessFooterComponent);