import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { styles } from '../privateProfile/styles';
import { PageTemplate } from '../home/PageTemplate';
import {configs} from '../../configs';
import MetaTags from 'react-meta-tags'

class PrivateProfileComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  render() {
    const {
      t
    } = this.props;

    return (
      <PageTemplate
        unifiedSearchMode={true}
        headerIsFixed={true}
        paddingTop={0}
        style={styles.container}
        noFooter={true}>
        <MetaTags>
          <meta name="prerender-status-code" content={403} />
        </MetaTags>
        <div className="container">
          <div className="row">
            <div style={styles.wrapper} id="private-profile">
              <img src={`${configs.cdnUrl}/images/private-profile.png`} alt={''} style={styles.img} />
              <span id="private-profile-label" style={styles.message}>
                {t('This athlete\'s profile is private. ')}
              </span>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export const PrivateProfile = withTranslation()(PrivateProfileComponent);
