import React from 'react';
import { FormattedDate } from 'react-intl';
import { BackButton } from './BackButton';
import { styles } from './styles';

export const CardHeader = ({ eventMetadata, eventDate, isMobile, isLightMode, t, onBack }) => (
    <div style={styles.cardHeader}>
        <div style={styles.resultBackButtonContainer}>
            <BackButton 
                isLightMode={isLightMode}
                label={t('Back to Search')}
                onBack={onBack}
            />
        </div>
        <div style={styles.headerMainHeading(isMobile, isLightMode, true)}>
            <div>
                {eventMetadata.eventName}
            </div>
            <div style={styles.headerSubHeading(isLightMode)}>
                <FormattedDate
                    year='numeric'
                    month='long'
                    day='2-digit'
                    value={eventDate}
                />
            </div>
        </div>
    </div>
);