import { streamHook, streamFail, eventBus } from './Streams';
import { getToken } from '../utils/isLoggedIn';
import { configs } from '../configs';
import { FetchApi } from '../lib/anuket-http';

export const STREAM_GET_ENTRY_DETAIL = 'getEntryDetail-'
export const STREAM_GET_VR_ENTRY_FOR_POST = 'getVrEntryForPost-'

export const saveEntryDetailUnofficialOrVirtualTime = async ({
  eventCourseId,
  entryUniqueId,
  unofficialTicks,
  detailUri,
}) => {
  const token = await getToken()
  if (!token) {
    throw new Error("Must be signed in")
  }
  if (!parseInt(eventCourseId) || !unofficialTicks) {
    throw new Error('Missing eventCourseId or entryUniqueId or unofficialTicks')
  }
  return new Promise((resolve, reject) => {
    const url = `${configs.ATHLINKS_API_HOST}/athletes/api/races/UnofficialOrVirtualTime`
    FetchApi.doFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        eventCourseId,
        entryUniqueId,
        unofficialTicks,
        detailUri,
      })
    }).subscribe((msg) => {
      if (!msg.Success) {
        reject(msg.ErrorMessage);
      }
      else {
        resolve(msg.Result);
      }
    })
  })
}

export const saveEntryDetail = async ({
  EntryUniqueID,
  EventCourseID,
  GoalTicks,
  SystemPredictedTicks = -1,
  UnofficialTicks = -1,
  Importance,
  Rating,
  Goals,
  Notes,
}) => {
  const token = await getToken()
  if (!token) {
    throw new Error('Must be signed in')
  }
  if (!parseInt(EventCourseID) || !EntryUniqueID) {
    throw new Error('Missing EventCourseID or EntryUniqueID')
  }
  return new Promise((resolve, reject) => {
    const url = `${configs.ATHLINKS_API_HOST}/athletes/api/races/EntryDetail`
    FetchApi.doFetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        EntryUniqueID,
        EventCourseID,
        GoalTicks,
        SystemPredictedTicks,
        UnofficialTicks,
        Importance,
        Rating,
        Goals,
        Notes,
      })
    }).subscribe((msg) => {
      if (!msg.Success) {
        reject(msg.ErrorMessage)
      }
      else {
        resolve(msg.Result);
      }
    })
  })
}

export const getEntryDetail = async ({racerId, eventCourseId, entryUniqueId}) => {
  racerId = isNaN(parseInt(`${racerId}`, 10)) ? 0 : parseInt(`${racerId}`, 10)
  eventCourseId = isNaN(parseInt(`${eventCourseId}`, 10)) ? 0 : parseInt(`${eventCourseId}`, 10)
  if ((racerId && eventCourseId) || entryUniqueId) {
    const streamId = `${STREAM_GET_ENTRY_DETAIL}-${racerId}-${eventCourseId}-${entryUniqueId}`
    const stream = streamHook(streamId, {fetching: true})
    const u = new URL(`${configs.ATHLINKS_API_HOST}/athletes/api/${racerId}/races/EntryDetail`)
    if (eventCourseId) u.searchParams.set('eventCourseId', eventCourseId)
    if (entryUniqueId) u.searchParams.set('entryUniqueId', entryUniqueId)
    console.log({EntryDetail: u.toString()})
    streamFail(
      streamId,
      FetchApi.memoizeFetch(10000)(u.toString(), {
        method: 'GET',
      }),
      ((msg) => {
        eventBus.publish(streamId, msg.Success ? {fetching: false} : msg.Result);
        return msg.Result;
      }),
    )
    return stream
  }
}

export const getVrEntryForPost = ({entryUniqueId}) => {
  if (entryUniqueId) {
    const streamId = `${STREAM_GET_VR_ENTRY_FOR_POST}${entryUniqueId}`
    const stream = streamHook(streamId, {fetching: true})
    streamFail(
      streamId,
      FetchApi.memoizeFetch(10000)(`${configs.ATHLINKS_API_HOST}/entry/api/${entryUniqueId}`, {
        method: 'GET',
      }),
      (msg) => {
        const output = msg.Success ? msg.Result : {errorMessage: msg.ErrorMessage}
        eventBus.publish(streamId, output)
        return output
      },
    )
    return stream
  }
}

export const saveVREntryTimeUnauthenticated = async ({eventCourseId, entryUniqueId, ticks, detailUri}) => {
  return new Promise((resolve, reject) => {
    if (entryUniqueId && ticks && eventCourseId) {
      FetchApi.doFetch(`${configs.ATHLINKS_API_HOST}/athletes/api/races/VirtualTimeNonMember`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entryUniqueId,
          eventCourseId,
          detailUri,
          unofficialTicks: ticks
        }),
      })
      .subscribe((msg) => {
        if (msg?.Success || msg?.success) {
          resolve(msg?.Result || msg?.result)
        }
        else {
          reject(msg?.ErrorMessage || msg?.errorMessage || msg)
        }
      })
    }
    else {
      console.log({eventCourseId, entryUniqueId, ticks, detailUri})
      reject('Incomplete request', {eventCourseId, entryUniqueId, ticks, detailUri})
    }
  })
}
