import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { streamHook, streamFail, eventBus } from './Streams';
import { of as ObservableOf, combineLatest, timer } from 'rxjs';
import moment from 'moment'
import { getSearchTerm } from './SearchTerm';
import { trackGoogleEvent } from '../utils/googleEvents';
import {
  debounce,
  filter,
  tap,
  map,
  flatMap
} from 'rxjs/operators'

import {
  RESULT_CONTEXT,
  EVENT_CONTEXT,
  ATHLETE_CONTEXT
} from './SearchContext';

const NUM_SUGGESTIONS = 3;

/**
 * Get value from input field
 * @param e
 */
export function getInputText(e) {
  return (e.target.value);
}

const unifiedEventResultsStream = 'unified-event-results-stream';
const unifiedAthleteResultsStream = 'unified-athlete-results-stream';
const unifiedUnclaimedResultsStream = 'unified-unclaimed-results-stream';

const unifiedEventResultsFetching = 'unified-event-results-fetching';
const unifiedAthleteResultsFetching = 'unified-athlete-results-fetching';
const unifiedUnclaimedResultsFetching = 'unified-unclaimed-results-fetching';

/**
 * Get events by search term and location
 * @param term
 * @param locationTerm
 * @returns {*}
 */
function eventsResults(term, locationTerm) {
  const locationQuery = locationTerm ? `&location=${locationTerm}` : '';
  const qs = `term=${encodeURIComponent(term)}&limit=${NUM_SUGGESTIONS}${locationQuery}`;
  const fromDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
  const toDate = moment().add(1, 'years').format('YYYY-MM-DD');
  // Predefined startDate and endDate
  const eventUrl = `${configs.ATHLINKS_API_HOST}/events/race/api/find?startDate=${fromDate}&endDate=${toDate}&${qs}`;
  eventBus.publish(unifiedEventResultsFetching, {fetching: true});
  streamFail(
    unifiedEventResultsStream,
    FetchApi
      .memoizeFetch(10000)(eventUrl, {})
      .pipe(map(({ result }) => {
        return result;
      })),
    ((msg) => {
      eventBus.publish(unifiedEventResultsFetching, {fetching: false});
      return msg;
    })
  );

  return streamHook(unifiedEventResultsStream);
}

/**
 * Get athletes by search term and location
 * @param term
 * @param locationTerm
 * @returns {*}
 */
function athletesResults(term, locationTerm) {
  const locationQuery = locationTerm ? `&location=${locationTerm}` : '';
  const qs = `term=${encodeURIComponent(term)}&limit=${NUM_SUGGESTIONS}${locationQuery}`;
  let athletesUrl = `${configs.ATHLINKS_API_HOST}/athletes/api/find?${qs}`;
  eventBus.publish(unifiedAthleteResultsFetching, {fetching: true});
  streamFail(
    unifiedAthleteResultsStream,
    FetchApi
      .memoizeFetch(10000)(athletesUrl, {})
      .pipe(map(({ result }) => {
        return result;
      })),
    ((msg) => {eventBus.publish(unifiedAthleteResultsFetching, {fetching: false}); return msg;})
  );

  return streamHook(unifiedAthleteResultsStream);
}

/**
 * Get unclaimed results by search term and location
 * @param term
 * @param locationTerm
 * @returns {*}
 */
function unclaimedResults(term, locationTerm) {
  //TODO check if location term is supported in /events/race/result/api/find
  const locationQuery = locationTerm ? `&location=${locationTerm}` : '';
  const qs = `term=${encodeURIComponent(term)}&limit=${NUM_SUGGESTIONS}${locationQuery}`;
  let resultsUrl = `${configs.ATHLINKS_API_HOST}/events/race/result/api/find?${qs}`;
  eventBus.publish(unifiedUnclaimedResultsFetching, {fetching: true});
  streamFail(
    unifiedUnclaimedResultsStream,
    FetchApi
      .memoizeFetch(10000)(resultsUrl, {})
      .pipe(map(({ result }) => {
        return result;
      })),
    ((msg) => {eventBus.publish(unifiedUnclaimedResultsFetching, {fetching: false}); return msg;})
  );

  return streamHook(unifiedUnclaimedResultsStream);
}

/**
 * Combine results for events, athletes and unclaimed results for input searched term
 */
export function allResults() {
  return getCombinedFilters()
    .pipe(
      debounce(() => timer(1000)),
      filter(({term, locationTerm, context}) => term && term.length > 3),
      tap(({term, locationTerm, context}) => trackGoogleEvent('search', context, term)),
      flatMap(({term, locationTerm, context}) => combineLatest(
        context === EVENT_CONTEXT ? eventsResults(term, locationTerm) : ObservableOf({}),
        context === ATHLETE_CONTEXT ? athletesResults(term, locationTerm) : ObservableOf({}),
        context === RESULT_CONTEXT ? unclaimedResults(term, locationTerm) : ObservableOf({}),
        enterPressed(),
        (eventsRes, athletesRes, resultsRes, enter) => ({eventsRes, athletesRes, resultsRes, enter, term})
      )),
    )
}

export function getUnifiedSearchFetching() {
  return combineLatest(
    streamHook(unifiedEventResultsFetching),
    streamHook(unifiedAthleteResultsFetching),
    streamHook(unifiedUnclaimedResultsFetching),
    (eventsFetching, athletesFetching, resultsFetching) => (
      eventsFetching.fetching || athletesFetching.fetching || resultsFetching.fetching
    )
  );
}

export function pressArrowKey( selectedIndex = 0, isTop = false, preventScroll = false ) {
  if(!preventScroll) {
    const scrollableElement = document.getElementById(`list${selectedIndex}`);
    if(scrollableElement) scrollableElement.scrollIntoView(isTop);
  }
  eventBus.publish('search-list-selected', selectedIndex)
}

export function listElemSelected() {
  return streamHook('search-list-selected', -1);
}

export function enterPressed() {
  return streamHook('search-enter-pressed', false);
}

export function getLocationTerm() {
  return streamHook('location-term', '');
}

export function setLocationTerm(locationTerm) {
  eventBus.publish('location-term', locationTerm);
}

export function getSearchContext() {
  return streamHook('search-context', RESULT_CONTEXT);
}

export function resultsLoading() {
  return streamHook(unifiedUnclaimedResultsFetching, false);
}

export function eventsLoading() {
  return streamHook(unifiedEventResultsFetching, false);
}

export function athletesLoading() {
  return streamHook(unifiedAthleteResultsFetching, false);
}

function getCombinedFilters() {
  return combineLatest(
      getSearchTerm(),
      getLocationTerm(),
      getSearchContext(),
      (term, locationTerm, context) => ({term, locationTerm, context})
    );
}

export const SearchStream = {
  getInputText,
  allResults
};
