import { RtkQueryEventResults } from './RtkQueryEventResults';
import { EventResultsProps } from '../../utils/types';
import { configs } from '../../configs';
import { ReigniteRaceDivisionIntervalResults } from './ReigniteRaceDivisionIntervalResults';
import { ReigniteRaceLeaderboardResults } from './ReigniteRaceLeaderboardResults';
import { ReigniteEventWideLeaderboardResults } from './ReigniteEventWideLeaderboardResults';
import useReigniteResults from '../../lib/firebase/useReigniteResults';

export const ReigniteEventResults = (props: EventResultsProps) => {
  const athlinksEventId = props.eventResultsFilters.date
  const usingReigniteResults = useReigniteResults(athlinksEventId);

  const divisionId = props.eventResultsFilters.division
  const eventCourseId = props.eventResultsFilters.course
  const intervalId = props.eventResultsFilters.split
  const isLive = props.isEventLive || false
  const page = props.page || props.eventResults.args.fro || 0
  const limit = eventCourseId ? configs.numResultsPerPage : configs.numEventWideLeaderboardResultsPerPage
  const from = page * limit

  if (usingReigniteResults) {
    if (eventCourseId && !isNaN(eventCourseId)) {
      if (divisionId && !isNaN(divisionId) && intervalId && !isNaN(intervalId)) {
        return <ReigniteRaceDivisionIntervalResults
          {...props}
          athlinksEventId={athlinksEventId}
          divisionId={divisionId}
          eventCourseId={eventCourseId}
          intervalId={intervalId}
          from={from}
          isLive={isLive}
          limit={limit}
        />
      }

      return <ReigniteRaceLeaderboardResults
        {...props}
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        from={from}
        isLive={isLive}
        limit={limit}
      />
    }

    return <ReigniteEventWideLeaderboardResults
      {...props}
      athlinksEventId={athlinksEventId}
      from={from}
      isLive={isLive}
      limit={limit}
    />
  }

  return <RtkQueryEventResults
    {...props}
    athlinksEventId={athlinksEventId}
    divisionId={divisionId}
    eventCourseId={eventCourseId}
    intervalId={intervalId}
    from={from}
    isLive={isLive}
    limit={limit}
  />
}
