export const timeZones = {
'Africa/Abidjan': '+0000',
'Africa/Accra': '+0000',
'Africa/Addis_Ababa': '+0300',
'Africa/Algiers': '+0100',
'Africa/Asmara': '+0300',
'Africa/Asmera': '+0300',
'Africa/Bamako': '+0000',
'Africa/Bangui': '+0100',
'Africa/Banjul': '+0000',
'Africa/Bissau': '+0000',
'Africa/Blantyre': '+0200',
'Africa/Brazzaville': '+0100',
'Africa/Bujumbura': '+0200',
'Africa/Cairo': '+0200',
'Africa/Casablanca': '+0000',
'Africa/Ceuta': '+0100',
'Africa/Conakry': '+0000',
'Africa/Dakar': '+0000',
'Africa/Dar_es_Salaam': '+0300',
'Africa/Djibouti': '+0300',
'Africa/Douala': '+0100',
'Africa/El_Aaiun': '+0000',
'Africa/Freetown': '+0000',
'Africa/Gaborone': '+0200',
'Africa/Harare': '+0200',
'Africa/Johannesburg': '+0200',
'Africa/Juba': '+0300',
'Africa/Kampala': '+0300',
'Africa/Khartoum': '+0300',
'Africa/Kigali': '+0200',
'Africa/Kinshasa': '+0100',
'Africa/Lagos': '+0100',
'Africa/Libreville': '+0100',
'Africa/Lome': '+0000',
'Africa/Luanda': '+0100',
'Africa/Lubumbashi': '+0200',
'Africa/Lusaka': '+0200',
'Africa/Malabo': '+0100',
'Africa/Maputo': '+0200',
'Africa/Maseru': '+0200',
'Africa/Mbabane': '+0200',
'Africa/Mogadishu': '+0300',
'Africa/Monrovia': '+0000',
'Africa/Nairobi': '+0300',
'Africa/Ndjamena': '+0100',
'Africa/Niamey': '+0100',
'Africa/Nouakchott': '+0000',
'Africa/Ouagadougou': '+0000',
'Africa/Porto-Novo': '+0100',
'Africa/Sao_Tome': '+0000',
'Africa/Timbuktu': '+0000',
'Africa/Tripoli': '+0200',
'Africa/Tunis': '+0100',
'Africa/Windhoek': '+0100',
'America/Adak': '-1000',
'America/Anchorage': '-0900',
'America/Anguilla': '-0400',
'America/Antigua': '-0400',
'America/Araguaina': '-0300',
'America/Argentina/Buenos_Aires': '-0300',
'America/Argentina/Catamarca': '-0300',
'America/Argentina/ComodRivadavia': '-0300',
'America/Argentina/Cordoba': '-0300',
'America/Argentina/Jujuy': '-0300',
'America/Argentina/La_Rioja': '-0300',
'America/Argentina/Mendoza': '-0300',
'America/Argentina/Rio_Gallegos': '-0300',
'America/Argentina/Salta': '-0300',
'America/Argentina/San_Juan': '-0300',
'America/Argentina/San_Luis': '-0300',
'America/Argentina/Tucuman': '-0300',
'America/Argentina/Ushuaia': '-0300',
'America/Aruba': '-0400',
'America/Asuncion': '-0400',
'America/Atikokan': '-0500',
'America/Atka': '-1000',
'America/Bahia': '-0300',
'America/Bahia_Banderas': '-0600',
'America/Barbados': '-0400',
'America/Belem': '-0300',
'America/Belize': '-0600',
'America/Blanc-Sablon': '-0400',
'America/Boa_Vista': '-0400',
'America/Bogota': '-0500',
'America/Boise': '-0700',
'America/Buenos_Aires': '-0300',
'America/Cambridge_Bay': '-0700',
'America/Campo_Grande': '-0400',
'America/Cancun': '-0500',
'America/Caracas': '-0430',
'America/Catamarca': '-0300',
'America/Cayenne': '-0300',
'America/Cayman': '-0500',
'America/Chicago': '-0600',
'America/Chihuahua': '-0700',
'America/Coral_Harbour': '-0500',
'America/Cordoba': '-0300',
'America/Costa_Rica': '-0600',
'America/Creston': '-0700',
'America/Cuiaba': '-0400',
'America/Curacao': '-0400',
'America/Danmarkshavn': '+0000',
'America/Dawson': '-0800',
'America/Dawson_Creek': '-0700',
'America/Denver': '-0700',
'America/Detroit': '-0500',
'America/Dominica': '-0400',
'America/Edmonton': '-0700',
'America/Eirunepe': '-0500',
'America/El_Salvador': '-0600',
'America/Ensenada': '-0800',
'America/Fort_Nelson': '-0700',
'America/Fort_Wayne': '-0500',
'America/Fortaleza': '-0300',
'America/Glace_Bay': '-0400',
'America/Godthab': '-0300',
'America/Goose_Bay': '-0400',
'America/Grand_Turk': '-0400',
'America/Grenada': '-0400',
'America/Guadeloupe': '-0400',
'America/Guatemala': '-0600',
'America/Guayaquil': '-0500',
'America/Guyana': '-0400',
'America/Halifax': '-0400',
'America/Havana': '-0500',
'America/Hermosillo': '-0700',
'America/Indiana/Indianapolis': '-0500',
'America/Indiana/Knox': '-0600',
'America/Indiana/Marengo': '-0500',
'America/Indiana/Petersburg': '-0500',
'America/Indiana/Tell_City': '-0600',
'America/Indiana/Vevay': '-0500',
'America/Indiana/Vincennes': '-0500',
'America/Indiana/Winamac': '-0500',
'America/Indianapolis': '-0500',
'America/Inuvik': '-0700',
'America/Iqaluit': '-0500',
'America/Jamaica': '-0500',
'America/Jujuy': '-0300',
'America/Juneau': '-0900',
'America/Kentucky/Louisville': '-0500',
'America/Kentucky/Monticello': '-0500',
'America/Knox_IN': '-0600',
'America/Kralendijk': '-0400',
'America/La_Paz': '-0400',
'America/Lima': '-0500',
'America/Los_Angeles': '-0800',
'America/Louisville': '-0500',
'America/Lower_Princes': '-0400',
'America/Maceio': '-0300',
'America/Managua': '-0600',
'America/Manaus': '-0400',
'America/Marigot': '-0400',
'America/Martinique': '-0400',
'America/Matamoros': '-0600',
'America/Mazatlan': '-0700',
'America/Mendoza': '-0300',
'America/Menominee': '-0600',
'America/Merida': '-0600',
'America/Metlakatla': '-0900',
'America/Mexico_City': '-0600',
'America/Miquelon': '-0300',
'America/Moncton': '-0400',
'America/Monterrey': '-0600',
'America/Montevideo': '-0300',
'America/Montreal': '-0500',
'America/Montserrat': '-0400',
'America/Nassau': '-0500',
'America/New_York': '-0500',
'America/Nipigon': '-0500',
'America/Nome': '-0900',
'America/Noronha': '-0200',
'America/North_Dakota/Beulah': '-0600',
'America/North_Dakota/Center': '-0600',
'America/North_Dakota/New_Salem': '-0600',
'America/Ojinaga': '-0700',
'America/Panama': '-0500',
'America/Pangnirtung': '-0500',
'America/Paramaribo': '-0300',
'America/Phoenix': '-0700',
'America/Port_of_Spain': '-0400',
'America/Port-au-Prince': '-0500',
'America/Porto_Acre': '-0500',
'America/Porto_Velho': '-0400',
'America/Puerto_Rico': '-0400',
'America/Rainy_River': '-0600',
'America/Rankin_Inlet': '-0600',
'America/Recife': '-0300',
'America/Regina': '-0600',
'America/Resolute': '-0600',
'America/Rio_Branco': '-0500',
'America/Rosario': '-0300',
'America/Santa_Isabel': '-0800',
'America/Santarem': '-0300',
'America/Santiago': '-0400',
'America/Santo_Domingo': '-0400',
'America/Sao_Paulo': '-0300',
'America/Scoresbysund': '-0100',
'America/Shiprock': '-0700',
'America/Sitka': '-0900',
'America/St_Barthelemy': '-0400',
'America/St_Johns': '-0330',
'America/St_Kitts': '-0400',
'America/St_Lucia': '-0400',
'America/St_Thomas': '-0400',
'America/St_Vincent': '-0400',
'America/Swift_Current': '-0600',
'America/Tegucigalpa': '-0600',
'America/Thule': '-0400',
'America/Thunder_Bay': '-0500',
'America/Tijuana': '-0800',
'America/Toronto': '-0500',
'America/Tortola': '-0400',
'America/Vancouver': '-0800',
'America/Virgin': '-0400',
'America/Whitehorse': '-0800',
'America/Winnipeg': '-0600',
'America/Yakutat': '-0900',
'America/Yellowknife': '-0700',
'Antarctica/Casey': '+0800',
'Antarctica/Davis': '+0700',
'Antarctica/DumontDUrville': '+1000',
'Antarctica/Macquarie': '+1100',
'Antarctica/Mawson': '+0500',
'Antarctica/McMurdo': '+1200',
'Antarctica/Palmer': '-0400',
'Antarctica/Rothera': '-0300',
'Antarctica/South_Pole': '+1200',
'Antarctica/Syowa': '+0300',
'Antarctica/Troll': '+0000',
'Antarctica/Vostok': '+0600',
'Arctic/Longyearbyen': '+0100',
'Asia/Aden': '+0300',
'Asia/Almaty': '+0600',
'Asia/Amman': '+0200',
'Asia/Anadyr': '+1200',
'Asia/Aqtau': '+0500',
'Asia/Aqtobe': '+0500',
'Asia/Ashgabat': '+0500',
'Asia/Ashkhabad': '+0500',
'Asia/Baghdad': '+0300',
'Asia/Bahrain': '+0300',
'Asia/Baku': '+0400',
'Asia/Bangkok': '+0700',
'Asia/Barnaul': '+0700',
'Asia/Beirut': '+0200',
'Asia/Bishkek': '+0600',
'Asia/Brunei': '+0800',
'Asia/Calcutta': '+0530',
'Asia/Chita': '+0900',
'Asia/Choibalsan': '+0800',
'Asia/Chongqing': '+0800',
'Asia/Chungking': '+0800',
'Asia/Colombo': '+0530',
'Asia/Dacca': '+0600',
'Asia/Damascus': '+0200',
'Asia/Dhaka': '+0600',
'Asia/Dili': '+0900',
'Asia/Dubai': '+0400',
'Asia/Dushanbe': '+0500',
'Asia/Gaza': '+0200',
'Asia/Harbin': '+0800',
'Asia/Hebron': '+0200',
'Asia/Ho_Chi_Minh': '+0700',
'Asia/Hong_Kong': '+0800',
'Asia/Hovd': '+0700',
'Asia/Irkutsk': '+0800',
'Asia/Istanbul': '+0200',
'Asia/Jakarta': '+0700',
'Asia/Jayapura': '+0900',
'Asia/Jerusalem': '+0200',
'Asia/Kabul': '+0430',
'Asia/Kamchatka': '+1200',
'Asia/Karachi': '+0500',
'Asia/Kashgar': '+0600',
'Asia/Kathmandu': '+0545',
'Asia/Katmandu': '+0545',
'Asia/Khandyga': '+0900',
'Asia/Kolkata': '+0530',
'Asia/Krasnoyarsk': '+0700',
'Asia/Kuala_Lumpur': '+0800',
'Asia/Kuching': '+0800',
'Asia/Kuwait': '+0300',
'Asia/Macao': '+0800',
'Asia/Macau': '+0800',
'Asia/Magadan': '+1000',
'Asia/Makassar': '+0800',
'Asia/Manila': '+0800',
'Asia/Muscat': '+0400',
'Asia/Nicosia': '+0200',
'Asia/Novokuznetsk': '+0700',
'Asia/Novosibirsk': '+0600',
'Asia/Omsk': '+0600',
'Asia/Oral': '+0500',
'Asia/Phnom_Penh': '+0700',
'Asia/Pontianak': '+0700',
'Asia/Pyongyang': '+0830',
'Asia/Qatar': '+0300',
'Asia/Qyzylorda': '+0600',
'Asia/Rangoon': '+0630',
'Asia/Riyadh': '+0300',
'Asia/Saigon': '+0700',
'Asia/Sakhalin': '+1100',
'Asia/Samarkand': '+0500',
'Asia/Seoul': '+0900',
'Asia/Shanghai': '+0800',
'Asia/Singapore': '+0800',
'Asia/Srednekolymsk': '+1100',
'Asia/Taipei': '+0800',
'Asia/Tashkent': '+0500',
'Asia/Tbilisi': '+0400',
'Asia/Tehran': '+0330',
'Asia/Tel_Aviv': '+0200',
'Asia/Thimbu': '+0600',
'Asia/Thimphu': '+0600',
'Asia/Tokyo': '+0900',
'Asia/Ujung_Pandang': '+0800',
'Asia/Ulaanbaatar': '+0800',
'Asia/Ulan_Bator': '+0800',
'Asia/Urumqi': '+0600',
'Asia/Ust-Nera': '+1000',
'Asia/Vientiane': '+0700',
'Asia/Vladivostok': '+1000',
'Asia/Yakutsk': '+0900',
'Asia/Yekaterinburg': '+0500',
'Asia/Yerevan': '+0400',
'Atlantic/Azores': '-0100',
'Atlantic/Bermuda': '-0400',
'Atlantic/Canary': '+0000',
'Atlantic/Cape_Verde': '-0100',
'Atlantic/Faeroe': '+0000',
'Atlantic/Faroe': '+0000',
'Atlantic/Jan_Mayen': '+0100',
'Atlantic/Madeira': '+0000',
'Atlantic/Reykjavik': '+0000',
'Atlantic/South_Georgia': '-0200',
'Atlantic/St_Helena': '+0000',
'Atlantic/Stanley': '-0300',
'Australia/ACT': '+1000',
'Australia/Adelaide': '+0930',
'Australia/Brisbane': '+1000',
'Australia/Broken_Hill': '+0930',
'Australia/Canberra': '+1000',
'Australia/Currie': '+1000',
'Australia/Darwin': '+0930',
'Australia/Eucla': '+0845',
'Australia/Hobart': '+1000',
'Australia/LHI': '+1030',
'Australia/Lindeman': '+1000',
'Australia/Lord_Howe': '+1030',
'Australia/Melbourne': '+1000',
'Australia/North': '+0930',
'Australia/NSW': '+1000',
'Australia/Perth': '+0800',
'Australia/Queensland': '+1000',
'Australia/South': '+0930',
'Australia/Sydney': '+1000',
'Australia/Tasmania': '+1000',
'Australia/Victoria': '+1000',
'Australia/West': '+0800',
'Australia/Yancowinna': '+0930',
'Brazil/Acre': '-0500',
'Brazil/DeNoronha': '-0200',
'Brazil/East': '-0300',
'Brazil/West': '-0400',
'Canada/Atlantic': '-0400',
'Canada/Central': '-0600',
'Canada/Eastern': '-0500',
'Canada/East-Saskatchewan': '-0600',
'Canada/Mountain': '-0700',
'Canada/Newfoundland': '-0330',
'Canada/Pacific': '-0800',
'Canada/Saskatchewan': '-0600',
'Canada/Yukon': '-0800',
'CET': '+0100',
'Chile/Continental': '-0400',
'Chile/EasterIsland': '-0600',
'CST6CDT': '-0600',
'Cuba': '-0500',
'EET': '+0200',
'Egypt': '+0200',
'Eire': '+0000',
'EST': '-0500',
'EST5EDT': '-0500',
'Etc/GMT': '+0000',
'Etc/GMT+0': '+0000',
'Etc/GMT+1': '-0100',
'Etc/GMT+10': '-1000',
'Etc/GMT+11': '-1100',
'Etc/GMT+12': '-1200',
'Etc/GMT+2': '-0200',
'Etc/GMT+3': '-0300',
'Etc/GMT+4': '-0400',
'Etc/GMT+5': '-0500',
'Etc/GMT+6': '-0600',
'Etc/GMT+7': '-0700',
'Etc/GMT+8': '-0800',
'Etc/GMT+9': '-0900',
'Etc/GMT0': '+0000',
'Etc/GMT-0': '+0000',
'Etc/GMT-1': '+0100',
'Etc/GMT-10': '+1000',
'Etc/GMT-11': '+1100',
'Etc/GMT-12': '+1200',
'Etc/GMT-13': '+1300',
'Etc/GMT-14': '+1400',
'Etc/GMT-2': '+0200',
'Etc/GMT-3': '+0300',
'Etc/GMT-4': '+0400',
'Etc/GMT-5': '+0500',
'Etc/GMT-6': '+0600',
'Etc/GMT-7': '+0700',
'Etc/GMT-8': '+0800',
'Etc/GMT-9': '+0900',
'Etc/Greenwich': '+0000',
'Etc/UCT': '+0000',
'Etc/Universal': '+0000',
'Etc/UTC': '+0000',
'Etc/Zulu': '+0000',
'Europe/Amsterdam': '+0100',
'Europe/Andorra': '+0100',
'Europe/Astrakhan': '+0400',
'Europe/Athens': '+0200',
'Europe/Belfast': '+0000',
'Europe/Belgrade': '+0100',
'Europe/Berlin': '+0100',
'Europe/Bratislava': '+0100',
'Europe/Brussels': '+0100',
'Europe/Bucharest': '+0200',
'Europe/Budapest': '+0100',
'Europe/Busingen': '+0100',
'Europe/Chisinau': '+0200',
'Europe/Copenhagen': '+0100',
'Europe/Dublin': '+0000',
'Europe/Gibraltar': '+0100',
'Europe/Guernsey': '+0000',
'Europe/Helsinki': '+0200',
'Europe/Isle_of_Man': '+0000',
'Europe/Istanbul': '+0200',
'Europe/Jersey': '+0000',
'Europe/Kaliningrad': '+0200',
'Europe/Kiev': '+0200',
'Europe/Lisbon': '+0000',
'Europe/Ljubljana': '+0100',
'Europe/London': '+0000',
'Europe/Luxembourg': '+0100',
'Europe/Madrid': '+0100',
'Europe/Malta': '+0100',
'Europe/Mariehamn': '+0200',
'Europe/Minsk': '+0300',
'Europe/Monaco': '+0100',
'Europe/Moscow': '+0300',
'Europe/Nicosia': '+0200',
'Europe/Oslo': '+0100',
'Europe/Paris': '+0100',
'Europe/Podgorica': '+0100',
'Europe/Prague': '+0100',
'Europe/Riga': '+0200',
'Europe/Rome': '+0100',
'Europe/Samara': '+0400',
'Europe/San_Marino': '+0100',
'Europe/Sarajevo': '+0100',
'Europe/Simferopol': '+0300',
'Europe/Skopje': '+0100',
'Europe/Sofia': '+0200',
'Europe/Stockholm': '+0100',
'Europe/Tallinn': '+0200',
'Europe/Tirane': '+0100',
'Europe/Tiraspol': '+0200',
'Europe/Ulyanovsk': '+0400',
'Europe/Uzhgorod': '+0200',
'Europe/Vaduz': '+0100',
'Europe/Vatican': '+0100',
'Europe/Vienna': '+0100',
'Europe/Vilnius': '+0200',
'Europe/Volgograd': '+0300',
'Europe/Warsaw': '+0100',
'Europe/Zagreb': '+0100',
'Europe/Zaporozhye': '+0200',
'Europe/Zurich': '+0100',
'GB': '+0000',
'GB-Eire': '+0000',
'GMT': '+0000',
'GMT+0': '+0000',
'GMT0': '+0000',
'GMT-0': '+0000',
'Greenwich': '+0000',
'Hongkong': '+0800',
'HST': '-1000',
'Iceland': '+0000',
'Indian/Antananarivo': '+0300',
'Indian/Chagos': '+0600',
'Indian/Christmas': '+0700',
'Indian/Cocos': '+0630',
'Indian/Comoro': '+0300',
'Indian/Kerguelen': '+0500',
'Indian/Mahe': '+0400',
'Indian/Maldives': '+0500',
'Indian/Mauritius': '+0400',
'Indian/Mayotte': '+0300',
'Indian/Reunion': '+0400',
'Iran': '+0330',
'Israel': '+0200',
'Jamaica': '-0500',
'Japan': '+0900',
'Kwajalein': '+1200',
'Libya': '+0200',
'MET': '+0100',
'Mexico/BajaNorte': '-0800',
'Mexico/BajaSur': '-0700',
'Mexico/General': '-0600',
'MST': '-0700',
'MST7MDT': '-0700',
'Navajo': '-0700',
'NZ': '+1200',
'NZ-CHAT': '+1245',
'Pacific/Apia': '+1300',
'Pacific/Auckland': '+1200',
'Pacific/Bougainville': '+1100',
'Pacific/Chatham': '+1245',
'Pacific/Chuuk': '+1000',
'Pacific/Easter': '-0600',
'Pacific/Efate': '+1100',
'Pacific/Enderbury': '+1300',
'Pacific/Fakaofo': '+1300',
'Pacific/Fiji': '+1200',
'Pacific/Funafuti': '+1200',
'Pacific/Galapagos': '-0600',
'Pacific/Gambier': '-0900',
'Pacific/Guadalcanal': '+1100',
'Pacific/Guam': '+1000',
'Pacific/Honolulu': '-1000',
'Pacific/Johnston': '-1000',
'Pacific/Kiritimati': '+1400',
'Pacific/Kosrae': '+1100',
'Pacific/Kwajalein': '+1200',
'Pacific/Majuro': '+1200',
'Pacific/Marquesas': '-0930',
'Pacific/Midway': '-1100',
'Pacific/Nauru': '+1200',
'Pacific/Niue': '-1100',
'Pacific/Norfolk': '+1100',
'Pacific/Noumea': '+1100',
'Pacific/Pago_Pago': '-1100',
'Pacific/Palau': '+0900',
'Pacific/Pitcairn': '-0800',
'Pacific/Pohnpei': '+1100',
'Pacific/Ponape': '+1100',
'Pacific/Port_Moresby': '+1000',
'Pacific/Rarotonga': '-1000',
'Pacific/Saipan': '+1000',
'Pacific/Samoa': '-1100',
'Pacific/Tahiti': '-1000',
'Pacific/Tarawa': '+1200',
'Pacific/Tongatapu': '+1300',
'Pacific/Truk': '+1000',
'Pacific/Wake': '+1200',
'Pacific/Wallis': '+1200',
'Pacific/Yap': '+1000',
'Poland': '+0100',
'Portugal': '+0000',
'PRC': '+0800',
'PST8PDT': '-0800',
'ROC': '+0800',
'ROK': '+0900',
'Singapore': '+0800',
'Turkey': '+0200',
'UCT': '+0000',
'Universal': '+0000',
'US/Alaska': '-0900',
'US/Aleutian': '-1000',
'US/Arizona': '-0700',
'US/Central': '-0600',
'US/Eastern': '-0500',
'US/East-Indiana': '-0500',
'US/Hawaii': '-1000',
'US/Indiana-Starke': '-0600',
'US/Michigan': '-0500',
'US/Mountain': '-0700',
'US/Pacific': '-0800',
'US/Pacific-New': '-0800',
'US/Samoa': '-1100',
'UTC': '+0000',
'WET': '+0000',
'W-SU': '+0300',
'Zulu': '+0000',
}
