import { updatePending } from "./updatePending"

const canFollow = (
  following
) => ({
  following,
  followable: true,
  unfollowable: false
})

const canUnfollow = (
  _following
) => ({
  following: true,
  followable: false,
  unfollowable: true
})

const cantFollow = (
  following
) => ({
  following,
  followable: false,
  unfollowable: false
})

export const computeFollowable = (
  following,
  isMe,
  isLoggedIn
) =>
  (following && isMe && isLoggedIn ? cantFollow :
    following && isMe && !isLoggedIn ? canFollow : // impossible state
      following && !isMe && !isLoggedIn ? canFollow : // impossible state
        following && !isMe && isLoggedIn ? canUnfollow :
          !following && isMe && isLoggedIn ? cantFollow :
            !following && isMe && !isLoggedIn ? canFollow : // impossible state
              !following && !isMe && isLoggedIn ? canFollow :
                !following && !isMe && !isLoggedIn ? canFollow :
                  cantFollow)(following)

export const updateFollowPending =
  updatePending('athleteCardModel.followingPending')
export const updateUnfollowingPending =
  updatePending('athleteCardModel.unfollowingPending')
