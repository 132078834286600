import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  latestSsePayload,
  getEventResultsSelector
} from '../../reducers/reigniteSseSlice'
import ReigniteServerSentEvents from '../../components/shared/ReigniteServerSentEvents'
import { ReigniteSsePayload } from '../../utils/types'

export function ReigniteEventSseResults(props: {
  athlinksEventId: number
  divisionId?: number
  eventCourseId?: number
  intervalId?: number
  refetch: () => void
}) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    intervalId,
    refetch,
  } = props

  const path = athlinksEventId ?
    eventCourseId
      ? divisionId
        ? intervalId
          ? `event/${athlinksEventId}/race/${eventCourseId}/division/${divisionId}/interval/${intervalId}`
          : `event/${athlinksEventId}/race/${eventCourseId}/division/${divisionId}`
        : `event/${athlinksEventId}/race/${eventCourseId}`
      : `event/${athlinksEventId}`
    : ''

  const sseState = useSelector(getEventResultsSelector(path))

  React.useEffect(() => {
    console.log('sseState', {
      athlinksEventId,
      eventCourseId,
      divisionId,
      intervalId,
      sseState,
    })
    if (sseState?.lastUpdated &&
      sseState.lastPayload?.type === 'results'
    ) {
      if (( // event-wide or race-specific leaderboards
        !eventCourseId || eventCourseId === sseState.lastPayload.payload.raceId
      ) && ( // division-specific leaderboard
          (!divisionId || isNaN(divisionId)) || (divisionId === sseState.lastPayload.payload.divisionId)
        ) && ( // interval-specific results page (rbi)
          (!intervalId || isNaN(intervalId)) || (intervalId === sseState.lastPayload.payload.intervalId)
        )) {
        try {
          refetch()
        }
        catch (err) {
          console.warn(err)
        }
      }
    }
  }, [
    athlinksEventId,
    eventCourseId,
    divisionId,
    intervalId,
    refetch,
    sseState,
  ])
  const dispatch = useDispatch()

  const onMessage = React.useCallback(
    (data: ReigniteSsePayload) =>
      dispatch(
        latestSsePayload({
          path,
          ...data
        })
      ),
    [
      dispatch,
      path,
    ]
  )
  return (
    <>
      {path && <ReigniteServerSentEvents
        onMessage={onMessage}
        path={path}
      />}
    </>
  )
}
