import { Theme, makeStyles } from '@material-ui/core/styles';

import { loadingLight, loadingDark, fonts } from '../../shared/styles';

interface StylesProps {
  isFetching: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(({ palette }) => ({
  container: ({ isFetching }) => ({
    position: 'relative',
    minHeight: isFetching ? 116 : 0,
    marginBottom: 40,
    transition: 'all 0.3s',
  }),
  title: {
    color: palette.grey[800],
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.4,
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  name: {
    color: palette.grey[800],
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.17,
  },
  loadingContainer: ({ isFetching }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    pointerEvents: 'none',
    opacity: isFetching ? 1 : 0,
    transition: 'opacity 0.3s',
  }),
  loadingTitle: {
    width: '50%',
    height: 16,
    marginBottom: 8,
    pointerEvents: 'none',
    ...loadingDark,
  },
  loadingDistance: {
    height: 12,
    marginBottom: 5,
    pointerEvents: 'none',
    ...loadingLight,
  },
}));

export { useStyles };
