import React from 'react'

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'

import _ from 'lodash'

import * as sharedStyles from '../../../shared/styles'

export const defaultStyles = {
  headerRow: sharedStyles.textStyles.sectionHead,
  headerCell: sharedStyles.textStyles.sectionHead,
  cell: {
    fontSize: 18,
    lineHeight: '1em',
  },
  table: {},
  row: {
    '&:hover': {
      backgroundColor: `${sharedStyles.colors.lightGreyHover} !important`,
    },
  },
}

const useStyles = makeStyles({
  headerRow: (props) => ({ ...defaultStyles.headerRow, ...props?.headerRow }),
  headerCell: (props) => ({ ...defaultStyles.headerCell, ...props?.headerCell }),
  cell: (props) => ({ ...defaultStyles.cell, ...props?.cell }),
  table: (props) => ({ ...defaultStyles.table, ...props?.table }),
  row: (props) => ({
    ...defaultStyles.row,
    ...props?.row,
  }),
})
/**
 * Standard table
 *
 * @param {*} {
 *   dataSource,
 *   emptyPlaceholder = '--',
 *   columns: Array of
 *    {
 *      key: string (can use . notation) | function(row, index): string,
 *      label: string | function | Cell | React.Node
 *      itemProp?: string // see schema.org
 *      align: 'left' , 'right', 'center' // Default center
 *    }
 *    OR
 *    {
 *      label: string | function(row, index): String | Cell | React.Node,
 *      renderer: function(row, index): React.Node | Cell | React.Node,
*     },
 *   tableStyles = defaultStyles,
 *   onRowClick = (ev, data, index) => {},
 * }
 */
export const StandardTable = ({
  columns,
  dataSource,
  emptyPlaceholder = '--',
  onRowClick = (ev, data, index) => { },
  tableStyles = defaultStyles,
  itemScopeType,
  itemProp,
}) => {
  const styles = useStyles(tableStyles)
  const createRowClickHandler = (data, index) => (ev) => {
    if (onRowClick) {
      onRowClick(ev, data, index)
    }
  }
  let itemScope = {}
  if (itemScopeType) {
    itemScope = {
      itemScope: true,
      itemType: itemScopeType
    }
  }
  return (
    <Table className={styles.table} aria-label="paging table" itemProp={itemProp}>
      <TableHead>
        <TableRow className={styles.headerRow}>
          {(columns || []).map((x, i) => {
            if (typeof x.label === 'string') {
              return <TableCell align='center' key={'column-' + i} className={styles.headerCell}>{x.label}</TableCell>
            }
            return x
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {(dataSource || []).map((row, i) => (
          <TableRow hover key={'row-' + i} className={`${styles.row} ${styles.hover}`}
            {...itemScope}
            onClickCapture={createRowClickHandler(row, i)}>
            {(columns || []).map((cell, ix) => {
              let content
              if (typeof cell.renderer === 'function') {
                const R = cell.renderer
                content = <R row={row} index={ix} key={`row-${i}-column-${ix}`} />
              }
              else if (typeof cell.key === 'string') {
                content = _.get(row, cell.key, emptyPlaceholder)
              }
              else if (typeof cell.key === 'function') {
                content = cell.key(row, ix)
              }
              if ((
                typeof content !== 'object' || content === null
              ) && typeof content !== 'function') {
                content = content === null || typeof content === 'undefined' ? null : `${content}`
                const C = cell.align === 'right' ? RightCell
                  : cell.align === 'left' ? LeftCell
                    : CenterCell
                return (
                  <C key={`row-${i}-column-${ix}`}
                    className={styles.cell}
                    itemProp={cell.itemProp}>{content?.length ? content : emptyPlaceholder}
                  </C>
                )
              }
              return content
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export const PagedTable = ({
  dataSource,
  columns,
  emptyPlaceholder = '--',
  tableStyles = defaultStyles,
  onRowClick = (ev, data, index) => { },
  pageSize,
  rowCount,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  itemScopeType,
  itemProp,
}) => {
  return (
    <div>
      <StandardTable
        dataSource={dataSource}
        columns={columns}
        tableStyles={tableStyles}
        onRowClick={onRowClick}
        emptyPlaceholder={emptyPlaceholder}
        itemScopeType={itemScopeType}
        itemProp={itemProp}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={rowCount}
        rowsPerPage={pageSize}
        page={currentPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </div>
  )
}

export const Cell = ({ align, children, className = undefined, itemProp = undefined }) => {
  const styles = useStyles()
  return <TableCell align={align} className={className || styles.cell} itemProp={itemProp}>{children}</TableCell>
}

export const LeftCell = ({ children, className, itemProp }) => {
  const styles = useStyles()
  return <TableCell align={'left'} className={className || styles.cell} itemProp={itemProp}>{children}</TableCell>
}

export const RightCell = ({ children, className, itemProp }) => {
  const styles = useStyles()
  return <TableCell align={'right'} className={className || styles.cell} itemProp={itemProp}>{children}</TableCell>
}

export const CenterCell = ({ children, className, itemProp }) => {
  const styles = useStyles()
  return <TableCell align={'center'} className={className || styles.cell} itemProp={itemProp}>{children}</TableCell>
}
