import React from 'react';
import { goalStyles as styles } from './styles';
import { convertTime } from '@eventops/athlinks-lib-units'

export const Goals = ({raceCardModel: rcm, claimed, isMobile, t}) => {
  const {
    horizontalSeparator,
    labelStyle,
    timeStyle
  } = styles
  const goalTime = convertTime(rcm.courses[0].goalTime)
  const goalPace = convertTime(rcm.courses[0].goalPace.time)

  return (
    <div className={'container'}>
      <div className={'row'}>
        <div className={'col-6'}>
          <div style={labelStyle}>{t('Goal Time')}</div>
          <div><hr style={horizontalSeparator} /></div>
          {claimed && <div style={timeStyle(isMobile)}>{goalTime.value}</div>}
        </div>
        <div className={'col-6'}>
          <div style={labelStyle}>{t('Goal Pace')}</div>
          <div><hr style={horizontalSeparator}/></div>
          {claimed && <div style={timeStyle(isMobile)}>{goalPace.value}</div>}
        </div>
      </div>
    </div>
  )
};
