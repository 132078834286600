import { FetchApi } from '../../lib/anuket-http';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import React from 'react';
import { Link } from 'react-router-dom';

import { configs } from '../../configs';
import { streamHook, streamFail } from '../../data/Streams';
import { styles } from './styles';
import { isLoggedIn } from '../../utils/isLoggedIn';
import {isInFuture} from '../../utils/date';

function getHeaders() {
  return {
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + window.kc.token
  };
}

function ctliveTokens() {
  const streamId = 'CtLiveTokens';
  const stream = streamHook(streamId, {isLoading: true});

  if (isLoggedIn()) {
    stream.subscribe((msg) => {
      if ((msg || {}).isLoading) {
        //eventBus.publish(streamId, {});
        const url = `${configs.ATHLINKS_API_HOST}/account/api/getctlivetokens`;
        const req = {
          method: 'post',
          headers: getHeaders(),
        };

        return streamFail(streamId, FetchApi.doFetch(url, req, streamId), (msg) => msg.Result);
      }
    });
  }

  return stream;
}

function hasThirdParty(event) {
  return !!String(event.thirdPartyRegLink || '').length;
}

function hasRegOptions(regOptions) {
  return !!(regOptions || []).length;
}

export const RegBoxComponent = (
  {
    ctliveTokens,
    event,
    isMobile,
    regOptions,
    t
  }) =>  {

  const getRegisterUrl = (regOptionId) => {
    const key = ctliveTokens.key;
    const hash = ctliveTokens.hash;
    const urlBase = `${configs.chronotrackRegisterUrl}/r/${event.ctliveId}`;
    return urlBase
      + (regOptionId ? `?regOptionID=${regOptionId}` : '')
      + (key && hash ? `${regOptionId ? '&' : '?'}e=${encodeURIComponent(key)}&p=${encodeURIComponent(hash)}` : '');
  };

  if(event.isPublished && isInFuture(event.startTime) && hasThirdParty(event)) {
    return (
      <div style={isMobile ? styles.mobile.container : styles.container}>
        <div>
          <Link style={styles.nativeLinkStyle} to={event.thirdPartyRegLink}>
            {t('Register Now')}
          </Link>
        </div>
      </div>
    );
  }
  else if (event.isPublished && isInFuture(event.startTime) && hasRegOptions(regOptions)) {
    return (
      <div style={isMobile ? styles.mobile.container : styles.container}>
        <div style={styles.nativeLinkWrap(isMobile)}>
          <Link style={styles.nativeLinkStyle} to={getRegisterUrl()}>
            {t('Register Now')}
          </Link>
        </div>
      </div>
    );
  }
  else {
    return null;
  }
};

RegBoxComponent.defaultProps = {
  regOptions: [],
  ctliveTokens: {}
};

export const RegBox = connectStream({ctliveTokens})(RegBoxComponent);
