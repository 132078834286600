import { Box } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// @ts-ignore
import MarkerIcon from 'react-icons/lib/ti/location-outline';

import { EventResultsSearch } from '../eventResultsSearch/EventResultsSearch';
import { DateTime } from '../shared/DateTime';
import { Social } from '../social/Social';

import { useStyles } from './styles';

interface EventDetailsProps {
  actionButton: ReactNode;
  curatedMode?: boolean;
  excludeSearch?: boolean;
  isMobile?: boolean;
  ctliveId?: number;
  master: {
    imageUrl?: string;
    masterName: string;
    startEpoch: number;
    city: string;
    region: string;
    country: string;
    masterId: string;
    event: {
      masterId: number;
    };
  };
  type?: string;
  searchQuery?: string;
  eventId?: string;
}

const EventDetails = ({
  actionButton,
  ctliveId,
  master,
  type,
  searchQuery,
  eventId,
  excludeSearch = false,
  curatedMode = false,
  isMobile = false,
}: EventDetailsProps) => {
  const { t } = useTranslation();
  const styles = useStyles({});

  const [imageUrl, setImageUrl] = useState<string | null>(master?.imageUrl || null);

  const getSearch = () => {
    if (isMobile || excludeSearch) {
      return null;
    }

    return (
      <Box position='relative'>
        <EventResultsSearch
          searchQuery={searchQuery}
          eventId={eventId}
          masterEventId={master.event.masterId}
        />
      </Box>
    );
  };

  return (
    <div className={styles.detailsWrapper}>
      <div itemScope itemType="http://schema.org/Event" className={styles.detailsContainer}>
        <div className={styles.detailsLeftSide}>
          {imageUrl && ctliveId !== 0 && (
            <img
              src={imageUrl}
              alt='Event Logo'
              className={styles.logo}
              onError={() => setImageUrl(null)}
            />
          )}

          <div>
            <h1 className={styles.name} itemProp="name">
              {master.masterName}
            </h1>

            {type !== 'eventHeader' && (
              <div className={styles.mainTextStyle}>
                {curatedMode && (
                  <div className={styles.eventDetailsDate}>
                    <span itemProp="startDate" className={styles.startDate}>
                      <DateTime icon={null} date={master.startEpoch * 1000} showTime={false} />
                    </span>

                    <Box 
                      itemProp="location"
                      itemScope
                      itemType="http://schema.org/Place"
                      component='span'
                      paddingX={0}
                    >
                      <span itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                        <span itemProp="addressLocality">{master.city}</span>
                        <span>, </span>
                        <span itemProp="addressRegion">{master.region}</span>
                        <span>, </span>
                        <span itemProp="addressRegion">{master.country}</span>
                      </span>
                    </Box>

                    <Box marginTop={24}>
                      <Social event={master} t={t} />
                    </Box>

                    {isMobile && (
                      <div className={styles.loadMore}>
                        <Link to={`/event/${master.masterId}`}>
                          <span className={styles.register}>{t('Learn More')}</span>
                        </Link>
                      </div>
                    )}
                  </div>
                )}

                {!curatedMode && (
                  <Box style={{ clear: 'both' }}>
                    <span itemProp="startDate" className={styles.startDate}>
                      <DateTime date={master.startEpoch * 1000} showTime={false} defaultIcon={true} />
                    </span>

                    <span
                      itemProp="location"
                      itemScope
                      itemType="http://schema.org/Place"
                      className={styles.location}
                    >
                      <span itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                        <MarkerIcon className={{ paddingBottom: 3 }} />
                        {' '}
                        <span itemProp="addressLocality">{master.city}</span>
                        <span>, </span>
                        <span itemProp="addressRegion">{master.region}</span>
                        <span>, </span>
                        <span itemProp="addressRegion">{master.country}</span>
                      </span>
                    </span>
                  </Box>
                )}
              </div>
            )}
          </div>
        </div>

        {actionButton}
      </div>

      {getSearch()}
    </div>
  );
};

export { EventDetails };
