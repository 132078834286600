import { configs } from '../configs';

export function profileImageUrl(racerId, size='t') {
  return racerId !== 0 && `${configs.bragiUrl}/aws/racer/${size}/${racerId}/`;
}

export function profileUserImageUrl(racerId, size='t', isMember=true, isTransparent=false) {
  const fallbackImage = isTransparent
    ? `${configs.cdnUrl}/images/1x1.png`
    : isMember
      ? `${configs.cdnUrl}/images/default-athlete-blue.jpg`
      : `${configs.cdnUrl}/images/default-athlete-gray.jpg`;
  return racerId > 0
    ? `${configs.bragiUrl}/aws/racer/${size}/${racerId}/${fallbackImage}`
    : fallbackImage;
}
