import React from 'react'
import { getEventByEventId } from '../../data/EventStreams'
import {
  numericCharFilter,
  fieldNavHandler,
  formatTime,
  isValidActivityUrl,
} from '../../components/IRP/body/VRPostTimeModal'
import ApprovedTrackersModal from '../../components/IRP/body/VRApprovedTrackerModal'
import { saveVREntryTimeUnauthenticated } from '../../data/VRStreams'
import { withRouter } from 'react-router'
import {
  withStyles,
  TextField,
  Button,
  Container,
  Card,
  CardActions,
  CardMedia,
  CardContent,
  CardHeader,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import connectStream from '../../lib/bastetjs/utils/connectStream'
import { isAdmin } from '../../utils/isLoggedIn'
import { useGetEventCoursesQuery } from '../../api/alaskaApi'

const styles = {
  postTimeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  postTimeInputs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 16,
  },
  dialogIcon: {
    marginRight: '1rem'
  },
  timeLabel:{
    fontSize: 14,
    fontFamily: 'ProximaNovaSemibold',
    color:'#4a4a4a',
    textTransform: 'uppercase',
    marginTop: 16,
    marginBottom: 8,
  },
  dialogContent: {
    paddingTop: 0,
  },
  postTimeBtn: {
    width: '100%'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  dividers: {
    fontSize: 34,
    fontFamily: 'ProximaNovaBold',
    color: '#4a4a4a',
    marginRight: 6,
    marginLeft: 6,
  },
  inputValue: {
    fontSize: 24,
    fontFamily: 'ProximaNovaBold',
    color: '#4a4a4a',
    width: '4ch'
  },
  athleteName: {
    fontSize: 24,
    color: 'rgb(74, 74, 74)',
    fontFamily: 'ProximaNovaBold',
  },
  athleteInfo: {
    fontFamily: 'ProximaNovaRegular, helvetica, arial, sans-serif',
    color: 'rgb(155, 155, 155)',
    fontSize: 14,
  },
  courseName: {
    fontFamily: 'ProximaNovaRegular, helvetica, arial, sans-serif',
    color: 'rgb(155, 155, 155)',
    fontSize: 14,
  },
  activityLink: {
    fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
  },
}

const PrimaryButton = withStyles({
  root: {
    background: '#2B314E',
    width: 300,
    height: 35,
    borderRadius: 3,
    border: 0,
    color: 'white',
    padding: '0 30px',
    '&:hover': {
      background: '#2B314E',
      border: 'none'
    },
  },
  label: {
    textTransform: 'uppercase',
    color: '#ffffff'
  },
})(Button);

/**
 * Text Inputs
 */
const TextInput = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: 57,
      backgroundColor: '#FCFCFC',
      height: 45,
    },
    '& .MuiError': {
      borderColor: 'red',
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 10,
      // marginRight: 18
    }
  },
})(TextField);

const TextInputLong = withStyles({
  root: {
    '& .MuiOutlinedInput-root':{
      borderRadius: 2,
      borderColor: '#dddddd',
      width: '100%',
      backgroundColor: '#FCFCFC',
      height: 45,
    },
    '& .MuiError': {
      borderColor: 'red',
    },
    '& .MuiInputBase-input': {
      fontSize: 24,
      fontFamily: 'ProximaNovaBold',
      color: '#4a4a4a',
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      color: '#4a4a4a',
      fontFamily: 'ProximaNovaBold',
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
    }
  },
})(TextField);

function VRTimeEntryComponent({
  t,
  vrEntry,
  eventId,
  location,
  courses,
  history,
  event,
  entryUniqueId,
}) {
  // console.log({event, vrEntry})
  const [isValid, setIsValid] = React.useState(true);
  const [isTrackersOpen, setIsTrackersOpen] = React.useState(false)
  const [detailUrlValue, setDetailUrlValue] = React.useState('')
  const formHours = React.useRef(null)
  const formMinutes = React.useRef(null)
  const formSeconds = React.useRef(null)
  const formDetailEntry = React.useRef(null)
  const [closing, setClosing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [$eventCourseId, $setEventCourseId] = React.useState(vrEntry?.eventCourseId);

  const getCurrentCourse = () => courses?.MasterEvent?.EventRaces?.find((r) => r.RaceID === eventId)?.EventCourses?.find((c) => c.EventCourseID === $eventCourseId)

  const getVal = (ref) => {
    return (isNaN(parseInt(ref.current.value)) ? 0
      : parseInt(ref.current.value))
  }

  const goToResult = () => {
    if (vrEntry.bib) {
      history.replace(
        `/event/${vrEntry.masterEventId}/results/Event/${vrEntry.eventId}/Course/${$eventCourseId}/Bib/${vrEntry.bib}`,
      )
    }
    else {
      history.replace(
        `/event/${vrEntry.masterEventId}/results/Event/${vrEntry.eventId}/Course/${$eventCourseId}/Entry/${vrEntry.entryId}`,
      )
    }
  }

  const checkTicksAndMaybeRedirect = () => {
    if (!isAdmin() && vrEntry && vrEntry.ticks) {
      setImmediate(goToResult)
    }
  }

  checkTicksAndMaybeRedirect()

  const saveTimeAndRedirect = async () => {
    setClosing(true)
    const course = getCurrentCourse()
    const maxAllowedTicks = course?.MaxAllowedTicks || Number.POSITIVE_INFINITY
    const minAllowedTicks = course?.MinAllowedTicks || 0
    // console.log({course, courses, minAllowedTicks, maxAllowedTicks})
    const h = getVal(formHours) * 60 * 60
    const m = getVal(formMinutes) * 60
    const s = getVal(formSeconds)
    const ticks = (h + m + s) * 1000
    const detail = !!isValidDetailUrl(detailUrlValue)
    if (detail && !isNaN(ticks) && ticks >= minAllowedTicks && ticks <= maxAllowedTicks && $eventCourseId) {
      setIsValid(true)
      setErrorMessage(null)
      const body = {
        entryUniqueId,
        eventCourseId: $eventCourseId,
        detailUri: detailUrlValue,
        ticks,
      }
      // console.log({body, minAllowedTicks, maxAllowedTicks})
      try {
        await saveVREntryTimeUnauthenticated(body)
        setImmediate(goToResult)
      }
      catch (e) {
        setIsValid(false)
        setClosing(false)
        setErrorMessage(e?.Message ?? 'An error occurred.')
      }
    }
    else {
      const eMessage = ticks < minAllowedTicks ?
        `You must specify a time greater than ${formatTime(minAllowedTicks / 1000)}` :
        ticks > maxAllowedTicks ? `You must specify a time less than ${formatTime(maxAllowedTicks / 1000)}` :
        null
      setErrorMessage(eMessage)
      setClosing(false)
      setIsValid(false)
    }
  }

  const endDateTimeMS = (Number(event?.event?.endEpoch || vrEntry?.eventEndEpochTime) * 1000) || Date.now()
  const endDateTime = new Date(endDateTimeMS)

  const gender = vrEntry.gender && vrEntry.gender.toLowerCase().trim() === 'm' ? 'M' :
    vrEntry.gender && vrEntry.gender.toLowerCase().trim() === 'f' ? 'F' :
    'U'
  const athleteLocation = [
    vrEntry.city,
    vrEntry.stateProv,
    vrEntry.country,
  ].filter((x) => x && `${x}`.trim() !== '').join(', ')

  const athleteInfo = [
    vrEntry.age,
    gender,
    athleteLocation,
  ].filter((x) => x && `${x}`.trim() !== '').join(' | ')

  const eventInfo = [
    event?.event?.city,
    event?.event?.region,
    event?.event?.countryId,
  ].filter((x) => x && `${x}`.trim() !== '').join(', ')

  const isValidDetailUrl = (x) => {
    if (!x) return true
    if (!x.length) return true
    return isValidActivityUrl(x)
  }

  const allowCourseChange = courses?.EventCoursesDropDown?.length > 0 && event?.event?.allowCourseChange
  // console.log({allowCourseChange, event, courses})

  return !isAdmin() && (!vrEntry || vrEntry.ticks || !event?.event) ? null : (
    <Container style={{marginTop: 16}}>
      <ApprovedTrackersModal
        aria-labelledby="supported-trackers"
        aria-describedby="list-of-supported-activity-trackers"
        open={isTrackersOpen}
        handleClose={() => setIsTrackersOpen(false)}
      />
      <Card style={{padding: 16}} variant="outlined" raised>
        <CardHeader
          title={event?.event?.name}
          subheader={eventInfo}
          action={isAdmin() ? (
            <div>Admin</div>
          ) : undefined}
        />
        <CardMedia>
          <div style={{backgroundColor: 'black', height: 8}} />
        </CardMedia>
        <CardContent>
          <div style={{marginBottom: 16}}>
            <div style={styles.courseName}>{vrEntry.courseName}</div>
            <div style={styles.athleteName}>{vrEntry.displayName}</div>
            <div style={styles.athleteInfo}>{athleteInfo}</div>
          </div>
          {endDateTime.getTime() > Date.now() || isAdmin() ? (
            <div style={{marginBottom: 16}}>
              <Typography variant="body1" component="p">
                Enter your time here for your Virtual Race. If you have any questions, <a
                  href="https://athlinks.zendesk.com/hc/en-us/articles/360045124774-VIRTUAL-RACE-FAQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >check out our FAQ</a>.
              </Typography>

              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 16,
              }}>
                <Typography align="center" variant="caption">Times must be entered by <time style={{whiteSpace: 'nowrap'}}>{endDateTime.toLocaleString()}</time></Typography>
              </div>

              <Container maxWidth="xs">
                {allowCourseChange ? (
                  <div>
                    <div style={styles.timeLabel}>{t('Course')}</div>
                    <Select value={$eventCourseId} style={{
                        width: '100%',
                      }}
                      onChange={(ev) => $setEventCourseId(ev.target.value)}>
                      {(courses?.EventCoursesDropDown || []).map((x) => {
                        const ecid = parseInt(x.Value.split(':')[1])
                        return <MenuItem key={x.Value} value={ecid}>{x.Text}</MenuItem>
                      })}
                    </Select>
                  </div>
                ): null}
                <div style={styles.timeLabel}>
                  {t('Final Time')}
                </div>
                <div style={styles.postTimeContainer}>
                  <form noValidate autoComplete="off">
                    <div style={styles.postTimeInputs} >
                      <TextInput variant="outlined" helperText="HH"
                        inputRef={formHours}
                        maxLength={2}
                        onKeyDownCapture={numericCharFilter}
                        onKeyUp={fieldNavHandler(null, formMinutes.current)}
                        error={!isValid}
                        inputProps={{
                          maxLength: 2,
                        }}/>
                      <span style={styles.dividers}>:</span>
                      <TextInput variant="outlined" helperText="MM"
                        error={!isValid}
                        maxLength={2}
                        onKeyUp={fieldNavHandler(formHours.current, formSeconds.current)}
                        onKeyDownCapture={numericCharFilter}
                        inputRef={formMinutes}
                        inputProps={{
                          maxLength: 2
                        }}/>
                      <span style={styles.dividers}>:</span>
                      <TextInput variant="outlined" helperText="SS"
                        inputRef={formSeconds}
                        maxLength={2}
                        onKeyUp={fieldNavHandler(formMinutes.current, null)}
                        onKeyDownCapture={numericCharFilter}
                        error={!isValid}
                        inputProps={{
                          maxLength: 2
                        }}/>
                    </div>
                    <div style={{display: isValid ? 'none' : 'block', color: 'red', fontSize: '1rem', marginBottom: 16}}>
                      {errorMessage}
                    </div>
                    <div>
                      <div style={styles.timeLabel}>{t('Activity Link - Optional')}</div>
                      <TextInputLong variant="outlined"
                        style={{
                          width: '100%',
                        }}
                        type="url"
                        helperText={<Button variant="text" onClick={() => setIsTrackersOpen(true)}>
                          {t('(Click here for supported links)')}
                        </Button>}
                        inputRef={formDetailEntry}
                        onChange={(e) => setDetailUrlValue(e.target.value)}
                        error={!isValidDetailUrl(detailUrlValue)}
                        />
                    </div>
                  </form>
                </div>

              </Container>
            </div>
          ) : (
            <Typography align="center" variant="caption">{t('This Virtual Race has ended.')}</Typography>
          )}
        </CardContent>
        <CardActions>
            <div style={{
              position: 'relative',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
        {endDateTime.getTime() > Date.now() || isAdmin() ? (
            <PrimaryButton autoFocus onClick={saveTimeAndRedirect} color="primary" disabled={closing}>
              {t('Submit')}
            </PrimaryButton>
        ) : (
          <PrimaryButton autoFocus onClick={goToResult} color="primary">
            {t('View Result')}
          </PrimaryButton>
        )}
          </div>
        </CardActions>
      </Card>

    </Container>
  )
}

const PureVRTimeEntryComponent = props => {
  const athlinksEventId = props.vrEntry?.eventId
  const { data: eventCourses } = useGetEventCoursesQuery(athlinksEventId, {
    skip: !athlinksEventId
  })
  return <VRTimeEntryComponent
    {...props}
    courses={eventCourses}
  />
}

export const VRTimeEntry = compose(
  withTranslation(),
  withRouter,
  connectStream({
    event: ({vrEntry: {eventId}}) => getEventByEventId(eventId),
  })
)(PureVRTimeEntryComponent)
