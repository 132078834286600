import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import { connectStream } from '../../lib/bastetjs/utils/connectStream';
import { getResultsUrl } from '../../utils/resultsHelpers';
import _ from 'lodash';
import {
  fetchAthlete,
  fetchAthleteSummary,
  fetchAthleteCalendar,
  fetchAthleteRaces,
  fetchAthleteFollowing,
  fetchAthleteFriendsUpcoming,
  getAthleteStream,
  getAthleteSummaryStream,
  getAthleteCalendarStream,
  getAthleteRacesStream,
  getAthleteSuggestedStream,
  getAthleteFollowingStream,
  getAthleteFriendsUpcomingStream,
  fetchAthleteRivals,
  fetchAthleteSuggested,
  getAthleteRivalsStream,
  fetchVs,
  fetchVsDetails
} from '../../data/AthleteStreams';
import {
  setAthletePhoto
} from '../../data/EditProfileApi';
import {
  isLoggedIn as amILoggedIn,
  getTokenRacerId
} from '../../utils/isLoggedIn';
import {
  fetchFollowers,
  getFollowersStream
} from '../../data/ProfileStreams';
import { PageTemplate } from '../home/PageTemplate';
import { NotFound } from '../../views/notFound/NotFound';
import { Athlete } from '../../components/athlete/Athlete';
import {
  searchUnclaimed,
  getUnclaimedResultsStream
} from '../../data/UnclaimedSearchStreams';
import { isAFriend } from '../../shared/util';
import {AthlinksAds} from '../../components/shared/Banner';
import { PrivateProfile } from '../privateProfile/privateProfile';
import {fetchPendingClaims, getPendingClaims} from '../../data/UnclaimedSearchStreams';
import {
  getLiveEvent,
  setTab,
  tabTypes,
  getNumberOfUnclaimedResults
} from '../../utils/ProfileUtil';

const wtfProps = (props, nextProps, label) => {
  const currentPropKeys = Object.keys(props).sort()
  const nextPropKeys = Object.keys(nextProps).sort();
  if (_.isEqual(currentPropKeys, nextPropKeys)) {
  // return !_.isEqual(props, nextProps);
    // const diffs = {}
    const areEqual = currentPropKeys.reduce((a, c) => {
      const same = _.isEqual(nextProps[c], props[c])
      // if (!same) {
      //   diffs[c] = {next: nextProps[c], current: props[c], diff: _.difference(props[c], nextProps[c])}
      // }
      return a && same
    }, true);
    // if (Object.keys(diffs).length) {
    //   console.log('Differences', label, diffs)
    // }
    return !areEqual;
  }
  // else {
  //   console.log(label, 'Keys dont line up', _.difference(currentPropKeys, nextPropKeys))
  // }
  return true
}

const messagesPerTab = (t) => ({
  overview: {
    title: (name) => name ? t('{{name}}\'s Athlinks Profile', {name})
      : t('Private Athlinks Profile'),
    description: (name) => name
      ? t('View {{name}}\'s race history and times on Athlinks', {name})
      : t('View race history and times on Athlinks')
  },
  results: {
    title: (name) => t('{{name}}\'s Race History and Times', {name}),
    description: (name) =>
      t('View {{name}}\'s race history and times on Athlinks', {name})
  },
  following: {
    title: (name) => t('{{name}}\'s Athlinks Friends', {name}),
    description: (name) =>
      t('View {{name}}\'s racing friends on Athlinks', {name})
  },
  rivals: {
    title: (name, rivalName) => !!rivalName
      ? t('{{name}}\'s Rival Comparison', {name})
      : t('{{name}}\'s Rivals', {name}),
    description: (name, rivalName) => !!rivalName ? t(
      'View {{name}}\'s race record against {{selectedRival}}',
      {name, selectedRival: rivalName}
    ) : t('View how {{name}} stacks up against the competition', {name})
  },
  statistics: {
    title: (name) => t('{{name}}\'s Race Statistics', {name}),
    description: (name) =>
      t('View {{name}}\'s race statistics and PRs on Athlinks', {name})
  }
});

class AthletePageComponent extends Component {
  static propTypes = {
    athleteId: PropTypes.string.isRequired,
    selectedRival: PropTypes.string,
    tab: PropTypes.oneOf(tabTypes),
    athlete: PropTypes.object,
    summary: PropTypes.object,
    calendar: PropTypes.object,
    races: PropTypes.object,
    rivals: PropTypes.object,
    suggested: PropTypes.object,
    followings: PropTypes.array,
    auth: PropTypes.object,
    isMobile: PropTypes.bool,
    windowWidth: PropTypes.number,
    pendingClaims: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    tab: 'overview',
    following: []
  };

  state = {
    tab: setTab(this.props.tab)
  };

  constructor(props, context) {
    super(props, context);
    AthlinksAds.init();
  }

  componentDidMount() {
    const { athleteId } = this.props;

    if (athleteId) {
      // console.log('CDM has an athlete id', athleteId)
      this.fetch(athleteId, true, getTokenRacerId());
    }
    // else {
    //   console.log('CDM does not has an athlete id', athleteId)
    // }

    fetchPendingClaims();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tab !== this.state.tab) {
      this.setState({tab: setTab(nextProps.tab)});
    }
  }

  componentDidUpdate(prevProps) {
    // const callId = Math.random()
    const prevRacerID = parseInt(prevProps?.athleteId);
    const RacerID = parseInt(this.props?.athleteId);
    // console.log('CDU-' + callId, prevRacerID, RacerID);
    // wtfProps(prevProps, this.props, 'CDU-' + callId)
    if (prevRacerID !== RacerID) {
      // console.log('CDU-' + callId, 'athlete updated')
      this.fetch(RacerID, !_.isEqual(prevProps.auth, this.props.auth), getTokenRacerId())
      fetchPendingClaims();
    }
    else if (RacerID === parseInt(this.props.athlete?.athlete?.RacerID) &&
    RacerID !== parseInt(prevProps.athlete?.athlete?.RacerID)) {
      this.fetchUnclaimedResults();
    }
    // else {
    //   console.log('CDU-' + callId, 'hmm... havent loadeded a new athlete', prevRacerID, RacerID)
    // }
  }

  /**
   * Search unclaimed results for athletes display name
   */
  fetchUnclaimedResults = () => {
    if (this.isMe()) {
      const {athlete: {athlete: {DisplayName} = {}} = {}} = this.props;
      searchUnclaimed({searchTerm: DisplayName}, false);
    }
  };

  /**
   * Check if it is mine profile.
   * @return {boolean}
   */
  isMe = () => getTokenRacerId() === parseInt(this.props.athleteId);

  /**
   * @TODO After removing i18n object this component will not re-render itself if language is changed
   */
  shouldComponentUpdate(nextProps) {
    return wtfProps(this.props, nextProps, 'shouldComponentUpdate')
  }

  render() {
    const {
      tab
    } = this.state;

    const {
      athleteId,
      selectedRival,
      athlete = {},
      summary = {},
      calendar = {},
      races = {},
      rivals = {},
      suggested = {},
      following = {},

      //logged in user's friends (NOT the athlete whose profile we're viewing)
      friends = {},
      isMobile,
      windowWidth,
      friendsUpcoming = {},
      pendingClaims,
      unclaimedResults,
      t
    } = this.props;

    if(
      friends.fetching ||
      typeof friends.list === 'undefined'
    ) {
      return null;
    }

    const hasLiveBanner = calendar && calendar.calendar && getLiveEvent(calendar.calendar).length > 0;
    const liveEvent = hasLiveBanner && getLiveEvent(calendar.calendar);

    const liveEventURL = liveEvent ? getResultsUrl(liveEvent[0].MasterId, liveEvent[0].RaceId) : null;

    const isMe = this.isMe();
    const isLoggedIn = amILoggedIn();

    const isFriend = isAFriend(friends.list, athleteId);
    const hasKeys = Object.keys(athlete).length;

    const fetching = athlete.fetching || summary.fetching || races.fetching;
    const emptyResults = !fetching && !!hasKeys && !athlete.athlete;
    const noResultsDesc = emptyResults ? this.getPrivateAccountDesc() : '';

    const error = athlete.error || summary.error || races.error;

    if (noResultsDesc) {
      return (
        <PrivateProfile />
      )
    }

    if (error) {
      return (
        <NotFound errorStatus={400} />
      )
    }

    const displayName = athlete.fetching
      ? ''
      : athlete.athlete ? athlete.athlete.DisplayName || '' : '';
    const photo = athlete.fetching
      ? ''
      : athlete.athlete ? athlete.athlete.LogoUrls.Large || '' : '';

    const photoHeight = !!photo ? 200 : '';
    const photoWidth = !!photo ? 200 : '';

    const rivalId = parseInt(selectedRival || '');
    const selectedRivalObj = !!selectedRival && !isNaN(rivalId) && Array.isArray(rivals.rivals)
      ? rivals.rivals.find(({RacerID}) => rivalId === RacerID) : {};

    const ogMessageSet = messagesPerTab(t)[tab] || messagesPerTab(t).overview;
    const ogTitle = !!displayName ? ogMessageSet.title(displayName)
      : t('Private Athlinks Profile');
    const ogDescription = ogMessageSet.description(
      displayName,
      !!selectedRival && !!selectedRivalObj.displayName
        ? selectedRivalObj.displayName : ''
    );

    return (
      <PageTemplate
        unifiedSearchMode={true}
        hasLiveBanner={hasLiveBanner}
        liveEventURL={liveEventURL ? liveEventURL : ''}
        headerIsFixed={true}
        paddingTop={0}
        isMobile={isMobile}
        bannerTopOffset={50}
        {...this.props}>
        <OpenGraphTags
          ogType='profile'
          title={ogTitle}
          description={ogDescription}
          image={photo}
          imageHeight={photoHeight}
          imageWidth={photoWidth}/>
        <StandardHeaderTags
          title={ogTitle}
          description={ogDescription}
        />
        <Athlete
          athleteId={athleteId}
          tab={tab}
          liveEvent={getLiveEvent(calendar.calendar)}
          hasLiveBanner={hasLiveBanner}
          onTabSelected={this.onTabSelected}
          athlete={athlete}
          summary={summary}
          calendar={calendar.calendar}
          races={races}
          rivals={rivals}
          suggested={suggested}
          friends={friends.list}
          following={following}
          selectedRival={selectedRival}
          fetchAthlete={fetchAthlete}
          setAthletePhoto={setAthletePhoto}
          friendsUpcoming={friendsUpcoming.result && friendsUpcoming.result.EventsWithFriends}
          fetchAthleteRivals={fetchAthleteRivals}
          fetchAthleteSuggested={fetchAthleteSuggested}
          fetchVs={fetchVs}
          fetchVsDetails={fetchVsDetails}
          fetchAthleteCalendar={fetchAthleteCalendar}
          fetchAthleteFollowing={fetchAthleteFollowing}
          isMe={isMe}
          isLoggedIn={isLoggedIn}
          isFriend={isFriend}
          isMobile={isMobile}
          windowWidth={windowWidth}
          unclaimedResultsCount={getNumberOfUnclaimedResults(
            unclaimedResults, athlete
          )}
          pendingClaims={pendingClaims}
          t={t}
        />
      </PageTemplate>
    );
  }

  getPrivateAccountDesc = () => {
    const {
      t
    } = this.props;

    return t('This athlete\'s profile is private. ');
  };


  fetch = (athleteId, shouldFetchFriends, loggedInRacerId) => {
    // console.log('bout to do a whole lotta fetching', athleteId, shouldFetchFriends, loggedInRacerId)
    fetchAthlete(athleteId);
    fetchAthleteSummary(athleteId);
    fetchAthleteCalendar(athleteId);
    fetchAthleteRaces(athleteId);
    fetchAthleteFollowing(athleteId);
    fetchAthleteRivals(athleteId);
    fetchAthleteFriendsUpcoming(athleteId);
    fetchAthleteSuggested(athleteId);
    if (shouldFetchFriends && loggedInRacerId) {
      fetchFollowers('followers', loggedInRacerId);
    }
  };

  onTabSelected = (tab) => {
    this.setState({tab});
  };
}

export const AthletePage = withTranslation()(connectStream({
  athlete: ({athleteId}) => getAthleteStream(athleteId),
  summary: ({athleteId}) => getAthleteSummaryStream(athleteId),
  calendar: ({athleteId}) => getAthleteCalendarStream(athleteId),
  races: ({athleteId}) => getAthleteRacesStream(athleteId),
  rivals: ({athleteId}) => getAthleteRivalsStream(athleteId),
  suggested: () => getAthleteSuggestedStream(getTokenRacerId()),
  following: ({athleteId}) => getAthleteFollowingStream(athleteId),
  friends: () => getFollowersStream('followers', getTokenRacerId()),
  friendsUpcoming: ({athleteId}) => getAthleteFriendsUpcomingStream(athleteId),
  unclaimedResults: getUnclaimedResultsStream,
  pendingClaims: getPendingClaims
})(AthletePageComponent));
