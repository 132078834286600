import React, { Component } from 'react'
import { withTranslation , Trans} from 'react-i18next';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import HomeSectionTitle from '../HomeSectionTitle';
import sliderSettings from '../sliderSettings';

import { homeCarouselStyles } from '../styles';

import { categories } from '../../../views/home/mockCuratedData';


class CategoryCardComponent extends Component {

  state = {
    cardHovered: false,
    ctaHovered: false
  };

  render() {
    const {
      id,
      imageUrl,
      name,
      description,
      isMobile,
      t
    } = this.props;

    const {
      cardHovered,
      ctaHovered
    } = this.state;

    const {
      slide,
      slideImg,
      slideTitleContainer,
      slideTitleIntro,
      slideTitle,
      slideCopy,
      slideCta
    } = homeCarouselStyles;

    return (
      <div
        className={`curated-${id}`}
        style={ slide(cardHovered) }
        onMouseEnter={() => this.setState({cardHovered: true})}
        onMouseLeave={() => this.setState({cardHovered: false})}
      >

        <div style={ slideImg(imageUrl) }>
          <div style={ slideTitleContainer } >
            <Trans>
              <h3 style={ slideTitleIntro(cardHovered) }>
                Best Events For
              </h3>
              <h4 style={ slideTitle } className={`current-title-${id}`}>{{name}}</h4>
            </Trans>
          </div>
        </div>

        {
        //the followind div is shown/hidden by slick classes, found in /static/scss/base/_curated-carousel.scss
        }
        <div className='carousel-content'>
          <p style={ slideCopy(isMobile) }>
            { description }
          </p>

          <Link
            className={`current-link-${id}`}
            style={ slideCta(ctaHovered) }
            to={ `/home/curatedEvents/${id}` }
            onMouseEnter={() => this.setState({ctaHovered: true})}
            onMouseLeave={() => this.setState({ctaHovered: false})}
          >
            {t('View Events')}
          </Link>
        </div>

      </div>
    )
  }
}

export const CategoryCard = withTranslation()(CategoryCardComponent);

const CuratedCategoryCarousel = (props) => {
  const {
    t
  } = props;

  return (
    <section
      className="container"
      id='curated-category-carousel'
      style={ homeCarouselStyles.carouselContainer }>
      <div style={ homeCarouselStyles.carouselTitleContainer }>
        <HomeSectionTitle
          id="curated-event"
          title={t('Featured Events (in beta)')}
          subtitle={t('Let Us Find Your Next Event')}
        />
      </div>

      <div className='home-carousel' style={ homeCarouselStyles.carouselWrapper }>
        <Slider { ...sliderSettings }>

          { categories.map((category) => (
            <div key={ `entry-${category.id}` }>
              <CategoryCard
                { ...category }
                { ...props }
              />
            </div>
          )) }

        </Slider>
      </div>
    </section>
  );
};

export { CuratedCategoryCarousel }
