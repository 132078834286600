import { UserPrefsActionTypes } from './types'

export const getUserPrefsAction = () => ({
  type: UserPrefsActionTypes.GET_USER_PREFS,
})

export const getUserPrefsActionComplete = (prefs) => ({
  type: UserPrefsActionTypes.GET_USER_PREFS_COMPLETE,
  ...prefs
})

export const getUserPrefsActionError = (prefs) => ({
  type: UserPrefsActionTypes.GET_USER_PREFS_ERROR,
  ...prefs
})

export const setUserPrefsAction = (prefs) => ({
  type: UserPrefsActionTypes.SET_USER_PREFS,
  ...prefs
})

export const setUserPrefsActionComplete = (prefs) => ({
  type: UserPrefsActionTypes.SET_USER_PREFS_COMPLETE,
  ...prefs
})

export const setUserPrefsActionError = (prefs) => ({
  type: UserPrefsActionTypes.SET_USER_PREFS_Error,
  ...prefs
})