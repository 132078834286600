import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { eventStyles } from '../styles';
import {
  calculatePaceArrForResult,
  getResultUrl,
  getResultsUrlForBib
} from '../../../utils/resultsHelpers';
import {ViewAll} from '../UpcomingEvents';
import {EventInfo, Goal, Socials, EventHeader, Goals} from './UpcomingEvent';
import {colors} from '../../../shared/styles';
import Style from 'style-it';
import _ from 'lodash';

class LatestUpdatesComponent extends Component {

  static propTypes = {
    isMobile: PropTypes.bool,
    liveEvent: PropTypes.array,
    type: PropTypes.string,
    event: PropTypes.object,
    claimStatus: PropTypes.string,
    t: PropTypes.func
  };

  state = {
    editing: false
  };

  /**
   * Builds IRP result for latest update. Bib has precedence over result id
   * @return {String}
   */
  getIrpUrl = () => {
    const {
      event: {
        race: {
          masterEventID,
          raceID
        } = {},
        bibNum,
        eventCourseID,
        entryID,
      }
    } = this.props;

    if(!raceID) {
      return null;
    }
    else if(bibNum) {
      return getResultsUrlForBib(raceID, eventCourseID, bibNum, masterEventID);
    }

    return getResultUrl(raceID, eventCourseID, entryID, { masterId: masterEventID });
  };

  render() {
    const {
      type,
      event,
      isMobile,
      raceRankings,
      claimStatus,
      t
    } = this.props;

    const racePace = _.get(raceRankings, 'pace', '');

    const {
      race,
      isUnofficial,
      name,
      ticks,
      gender,
      countO,
      countA,
      countG,
      className,
      unofficialTime,
      tickString,
      ticksString,
      stateProv,
      country,
      raceGoals,
    } = event;
    const {
      masterEventID,
      raceName,
      courses,
      raceDate,
      city,
      stateProvAbbrev,
      countryID,
    } = race;

    const Gender = gender ? t(`genderMap#${gender}`) : t('Gender');

    let pace = [];
    if (race) {
      (courses).forEach((course) => {
        const raceCatDesc = course.raceCatDesc;
        const distUnit = course.distUnit;

        if(ticks !== 0) {
          pace.push(calculatePaceArrForResult({
            raceCatDesc, distUnit, ticks
          }));
        }
        else {
          pace.push([racePace.split(' ')[0], racePace.split(' ')[1]]);
        }
      });
    }

    const irpUrl = this.getIrpUrl();
    const goalTitle = (type === 'liveEvents') ? t('Time') : t('Final Time');

    const clickHandler = (ev) => {
      if (!ev.isDefaultPrevented()) {
        // Don't mess with the native function of anchor tags
        if (ev.currentTarget.contains(ev.target) && (ev.target.tagName !== 'A' && ev.target.tagName !== 'BUTTON')) {
          ev.preventDefault()
          // console.log({... ev})
          this.props.history.push({
            pathname: irpUrl
          })
        }
      }
    }

    if (!isMobile) {
      return (
        Style.it(eventStyles.pseudoStyles(),

        <div
          onClick={clickHandler}
          style={{padding: '15px 0'}}
          className="row mx-0 latest-event" >
          <div style={{width: '100%'}}>
            <EventHeader
              Name={raceName}
              StartDate={raceDate}
              City={city}
              StateProv={stateProvAbbrev}
              Country={countryID}
              isMobile={isMobile}
            />
            <div style={eventStyles.eventBorderContainer}>
              <div style={eventStyles.eventMain} className="row">
                <div className="col-12 col-md-9">
                  <EventInfo
                    type={type}
                    CountO={countO}
                    CountA={countA}
                    CountG={countG}
                    Gender={Gender}
                    ClassName={className}
                    pace={pace}
                    RaceGoals={raceGoals}
                    isMobile={isMobile}
                    rankA={_.get(raceRankings, 'rankA', '')}
                    rankG={_.get(raceRankings, 'rankG', '')}
                    rankO={_.get(raceRankings, 'rankO', '')}
                    t={t}
                  />
                </div>
                <div style={eventStyles.goals(isMobile)} className="col-12 col-md-3">
                  {(type === 'latestUpdate') ?
                    <Goals
                      RaceGoals={raceGoals}
                      title={goalTitle}
                      titleStyle={eventStyles.goalTitleCenter}
                      goalResStyle={eventStyles.goalNumberStyle(colors.black)}
                      value={isUnofficial && unofficialTime !== '' ? unofficialTime : (tickString || ticksString)}
                      type={type}
                      isMobile={isMobile}
                      isUnofficial={isUnofficial}
                      claimStatus={claimStatus}
                    />
                    : null
                  }
                </div>

              </div>
            </div>

            {
              (!claimStatus || claimStatus === 'ClaimPendingSync') && raceRankings &&
              <Socials
                event={event}
                props={this.props}
                pace={pace}
                raceRankings={raceRankings.isUnofficial}
                type={type}
                race={race}
              />
            }
            {
              (type === 'liveEvents' ) &&
              <ViewAll
                messages={t('View Today\'s Event')}
                type='liveEvents'
                link={`/Master/${masterEventID}/EventResults`}
                isMobile={isMobile}
              />
            }
          </div>
        </div>
        )
      );

    } else { // Is mobile...

      const [value] = pace[0] || [];
      return (
        <div onClick={clickHandler} style={eventStyles.updateContainer}>
          <div style={{marginBottom: '10px'}}>
            {
              (type !== 'latestUpdate') ?
                <EventHeader
                  Name={name}
                  StartDate={raceDate}
                  City={city}
                  StateProv={stateProv}
                  Country={country}
                  isMobile={isMobile}
                />
                :
                <EventHeader
                  Name={raceName}
                  StartDate={raceDate}
                  City={city}
                  StateProv={stateProvAbbrev}
                  Country={countryID}
                  isMobile={isMobile}
                />
            }
          </div>

          <div style={eventStyles.eventBorderContMobile}>
            <div style={{display: 'flex'}}>
              <Goal title={t('Overall')}
                    className={'col-4 col-lg-4'}
                    value={_.get(raceRankings, 'rankO', '')}
                    footer={countO ? `of ${countO}` : ''}
                    isMobile={isMobile}
                    isTime={false}
                    claimStatus={claimStatus}
              />
              <Goal title={t('Pace')}
                    className={'col-4 col-lg-4'}
                    value={value}
                    footer={'Min/Mi'}
                    style={eventStyles.goalPaceStyle}
                    isMobile={isMobile}
                    isTime={true}
                    claimStatus={claimStatus}
              />
              <Goal title={goalTitle}
                    className={'col-4 col-lg-4'}
                    value={tickString || ticksString || unofficialTime || ''}
                    isMobile={isMobile}
                    isTime={true}
                    isFinalTime={true}
                    claimStatus={claimStatus}
              />
            </div>
          </div>
          <div style={eventStyles.socialContainer}>
            {
              (claimStatus === 'Claimed' || claimStatus === 'ClaimPendingSync') &&
              <Socials
                event={event}
                props={this.props}
                pace={pace}
                type={type}
                raceRankings={(raceRankings || {}).isUnofficial}
                isMobile={isMobile}
                race={race}
              />
            }
          </div>
          {
            (type === 'liveEvents' ) &&
            <ViewAll
              messages={t('View Today\'s Event')}
              type='liveEvents'
              link={`/Master/${masterEventID}/EventResults`}
              isMobile={isMobile}
            />
          }
        </div>
      );
    }
  }
}

export default withTranslation()(withRouter(LatestUpdatesComponent))
