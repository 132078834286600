import React from 'react';

import {SignUpBannerRow} from '../shared/SignUpBanner';

export const SignUpBannerSearch = ({isMobile, style = {}, t}) => (
  <div
    id="sign-up-banner-search"
    style={{
      background: 'linear-gradient(180deg, #07668B 0%, #014661 100%)',
      color: '#ffffff',
      marginLeft: isMobile ? -15 : 15,
      marginRight: isMobile ? -15 : 15,
      ...style,
    }}>
    <SignUpBannerRow
      title={t('all your results in one place')}
      body={t('Create a profile, claim all your hard-earned race results, and connect with friends and events')}
      isMobile={isMobile}
      isSearch={true}
      className=''
      buttonWrapperStyle={{
        paddingBottom: 0,
      }}
      style={{
        display: isMobile? 'block' : 'flex',
        alignItems: 'center',
        padding: 20,
      }}
      t={t}
    />
  </div>
);
