import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withTranslation , Trans} from 'react-i18next';
import {Suggestions} from '../shared/Suggestions'
import {SearchField} from '../shared/Elements';
import {Location} from '../shared/Location';
import { bannerStyle } from '../../shared/styles';
import {AthletePhoto} from './AthletePhoto';
import {Link} from 'react-router-dom';
import {followerStyles, suggestionStyles} from './styles';
import {Banner, AthlinksAds} from '../shared/Banner';
import {FollowButton} from '../shared/Follow';
import {isLoggedIn} from '../../utils/isLoggedIn';
import {fetchAthleteFollowing} from '../../data/AthleteStreams';
import { Pagination } from '../../components/shared/Pagination';
import { colors } from '../../shared/styles';
import Style from 'style-it';
import {
  getAthletesGrouped,
  getFilteredAthletes
} from '../../utils/ProfileUtil';

class FollowersComponent extends Component {

  static propTypes = {
    athlete: PropTypes.object.isRequired,
    athleteId: PropTypes.string.isRequired,
    type: PropTypes.string,
    isMe: PropTypes.bool.isRequired,
    following: PropTypes.array,
    followers: PropTypes.array,
    isMobile: PropTypes.bool,
    friends: PropTypes.array,
    fetching: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    type: 'followers',
    following: [],
    fetching: false,
  };

  state = {
    type: this.props.type,
    searchTerm: '',
    numOfShowedFollowers: 30
  };

  constructor(props, context) {
    super(props, context);
    AthlinksAds.init();
  }

  /**
   * Callback method when search filter is changed
   * @param {object} event
   * @param {string} searchTerm
   */
  onSearch = ({ target: { value } }) => {
    this.setState({
      searchTerm: value
    });
  };

  /**
   * Returns followers or followings array based on type selected.
   * Array is ordered by athlete's DisplayName
   *
   * @param {string} type
   */
  athletes = (type) => (this.props[type] || [])
    .sort(({DisplayName: nameA}, {DisplayName: nameB}) =>
      nameA.localeCompare(nameB));

  /**
   * Refresh followers list
   */
  refreshFollowers = () => fetchAthleteFollowing(this.props.athleteId);

  loadMore = () => this.setState({numOfShowedFollowers: this.state.numOfShowedFollowers + 30});

  render() {
    const {
      athlete,
      athleteId,
      suggested,
      isMobile,
      isMe,
      friends,
      fetching,
      t
    } = this.props;
    const {type, searchTerm} = this.state;
    const {FName} = athlete;

    const athletes = this.athletes(type);
    const filteredAthletes = getFilteredAthletes(athletes, searchTerm);
    const athletesGrouped = filteredAthletes && getAthletesGrouped(filteredAthletes.slice(0, this.state.numOfShowedFollowers));

    const allNum = athletes.length;
    const filteredNum = filteredAthletes.length;
    const resNum = allNum === filteredNum ? t('All') : filteredNum;
    const adunits = AthlinksAds.adsForPage('Profile');

    return (
      <div className='row'>
        <div className={isMobile ? '' : 'col-8'} id='following-tab'>
          <div style={followerStyles.followersContent(isMobile)} id={'following-component'}>
            <div className='row'>
              <div className='col-sm-6 col-lg-7'>
                  <h2 style={followerStyles.followingTitle(isMobile)} id="following-title">
                    {t('Following')}
                  </h2>

                  { !isMobile &&
                    <div style={{position:'relative'}}>
                      <div
                        id={'showingFollowing'}
                        style={followerStyles.showFollowing}>
                        <Trans>
                          Showing <span style={{color: colors.black}}>{{resNum}}</span> racers that {{name: FName}} is following
                        </Trans>
                      </div>
                      <div style={followerStyles.showFollowingLoading(fetching)}/>
                    </div>
                  }

                  { !fetching &&
                    <div style={followerStyles.reportMessage} id={'reportMessage'}>
                      {allNum === 0 && !isMe &&
                        t('{{name}} isn\'t following anyone yet.', {name: this.props.athlete.DisplayName})
                      }
                      {allNum === 0 && isMe &&
                        t('You are not following anyone yet.')
                      }
                      {filteredNum === 0 && this.state.searchTerm !== '' &&
                        t('No followers match your search')
                      }
                    </div>
                  }

              </div>
              <div className='col-sm-6 col-lg-5' style={{paddingBottom: isMobile ? 15 : ''}}>
                { !fetching &&
                  <SearchField
                    id='searchResults'
                    placeholder={t('Search by athlete name')}
                    onChange={this.onSearch}
                    name='searchResults'
                    value={searchTerm}
                  />
                }
              </div>
            </div>

            <div style={followerStyles.followerGroupContainer(fetching)}>

              { // Loading placeholder
              }
              {fetching && <div style={followerStyles.loadingContainer(fetching)}>
                <div style={followerStyles.loadingHeader}/>
                <FollowerLoadingItem />
                <FollowerLoadingItem />
                <FollowerLoadingItem />
                <FollowerLoadingItem />
              </div>
              }

              {
                Object.keys(athletesGrouped).map((letter) =>
                  <FollowersGroup
                    key={letter}
                    letter={letter}
                    followers={athletesGrouped[letter]}
                    friends={friends}
                    isMe={this.props.isMe}
                    onFollow={this.refreshFollowers}
                    isMobile={isMobile}
                  />)
              }
              { (this.state.numOfShowedFollowers < filteredAthletes.length) &&
                <Pagination
                  key="pagination"
                  total={filteredAthletes.length}
                  isMobile={isMobile}
                  limit={this.state.numOfShowedFollowers}
                  skip={0}
                  onChange={this.loadMore}
                  t={t}
                />
              }
            </div>
          </div>
        </div>
        <div className={isMobile ? 'col-12 p-0' : 'col-4'} style={suggestionStyles.suggestionColumn(isMobile)}>
          { isLoggedIn() &&
            <Suggestions
              isMobile={isMobile}
              athleteId={athleteId}
              suggested={suggested}
            />
          }
          {adunits.map((x) => {
            return (
              <div key={x.adKey} style={bannerStyle(isMobile)}>
                <Banner
                  data-ad={x.adKey}
                  adKey={x.adKey}
                  divId={x.divId}
                  style={followerStyles.bannerStyle}/>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

}

export const Followers = withTranslation()(FollowersComponent);

/**
 * Stateless component that simply renders followers group.
 * They are grouped by first letter of display name
 *
 * @param {string} letter
 * @param {[]} followers
 * @param {bool} isMe
 * @param {bool} isMobile
 * @param {function} onFollow
 * @param {[]} friends
 * @return {XML}
 * @constructor
 */
export const FollowersGroup = ({letter, followers, isMe, isMobile, onFollow, friends = []}) => {

  const isMyFriend = (athleteId) =>
    isMe || !!friends.find(({RacerId}) => RacerId === athleteId);

  return (
    <div className={`${letter}-section`}>
      <div style={followerStyles.followerGroupLetterRow} className='letter'>
        {letter}
      </div>

      { followers.map((follower) =>
          <FollowerItem
            key={follower.RacerId}
            follower={follower}
            isFriend={isMyFriend(follower.RacerId)}
            athleteId={follower.RacerId}
            isMe={isMe}
            onFollow={onFollow}
            isMobile={isMobile}/>)
      }
    </div>
  );
};

/**
 * Renders single following.
 * This is only for followings.
 *
 * @param {object} follower
 * @param {number} athleteId
 * @param {boolean} isFriend
 * @param {boolean} isMobile
 * @param onFollow
 * @return {XML}
 * @constructor
 */
export const FollowerItem = ({follower, athleteId, isFriend = true, isMobile, onFollow}) => {

  const {
    RacerId,
    DisplayName,
    City,
    StateProvAbbrev,
    CountryID3,
    LogoUrls
  } = follower;

  return (
    Style.it(followerStyles.pseudoStyles(isMobile),
    <div style={followerStyles.followerRow} className='followerRow' id={athleteId} >
      <div style={followerStyles.followerRowPhotoCell}>
        <Link  to={`/athletes/${athleteId}`}>
        <AthletePhoto
          isMember={!!RacerId}
          photo={LogoUrls}
          size='Medium'
          name={DisplayName}
          width={isMobile ? 50 : 65}
          height={isMobile ? 50 : 65}
        />
        </Link>
      </div>
      <div style={followerStyles.followerRowInfoCell} className={'display-name'}>
        <Link  to={`/athletes/${athleteId}`} className="followers-info">
        <div style={followerStyles.followerName(isMobile)} className="follower-name">
          {DisplayName}
        </div>
        <Location
          city={City}
          state={StateProvAbbrev}
          country={CountryID3}
          searchType={null}
          icon={null}
          style={followerStyles.followerDetail}
        />
        </Link>
      </div>
      <div style={followerStyles.followerRowPhotoCell}>
        <FollowButton
          isMobile={isMobile}
          isLoggedIn={isLoggedIn()}
          isFriend={isFriend}
          Photos={LogoUrls}
          CountryId3={CountryID3}
          onChange={onFollow}
          {...follower}
        />
      </div>
    </div>
    )
  );
};


const FollowerLoadingItem = () => (
  <div style={followerStyles.loadingFollowerRow}>
    <div style={followerStyles.loadingAvatar}/>
    <div style={followerStyles.loadingNameCell}>
      <div style={followerStyles.loadingName}/>
      <div style={followerStyles.loadingDetails}/>
    </div>
    <div style={followerStyles.loadingButton}/>
  </div>
);
