import React from 'react';
import { SignUp } from '../modal/LogInOrSignUp';
import { styles } from './styles';
import { colors } from '../../shared/styles';

export const SignUpBanner = (
  {
    t,
    style = {},
    title = t('Sign up for free.'),
    body = t('Create a profile and claim all your hard-earned race results, compare yourself to rivals, and discover upcoming events from your friends.'),
    isMobile,
  }) => {
  return (
    <div
      style={styles.signUpBanner.wrap(style)}>
      <div
        className='container'
        style={styles.signUpBanner.container(isMobile)}
      >
        <SignUpBannerRow
          isMobile={isMobile}
          title={title}
          body={body}
          t={t}
        />
      </div>
    </div>
  );
};

export const SignUpBannerRow = (
  {
    t,
    title = t('Sign up for free.'),
    body = t('Create a profile and claim all your hard-earned race results, compare yourself to rivals, and discover upcoming events from your friends.'),
    isMobile,
    style = {},
    buttonWrapperStyle,
    className = 'row',
    isSearch = false
  }) => (
  <div
    className={className}
    style={{
      paddingRight: isMobile ? 25 : 30,
      paddingLeft: isMobile ? 25 : 86,
      justifyContent: isMobile ? 'center' : 'normal',
      ...style,
    }}>
    <div
      style={{
        display: 'flex',
        textAlign: isMobile ? 'center' : 'left',
        width: !isMobile ? '70%' : 'inherit'
      }}>
      <div>
        <h1
          id="banner-search-title"
          style={styles.signUpBanner.title(isMobile)}>
          {title}
        </h1>
        <p
          id="banner-search-paragraph"
          style={styles.signUpBanner.body(isMobile)}>
          {body}
        </p>
      </div>
    </div>
    <div
      style={{
        ...styles.signUpBanner.buttonWrapper(isMobile),
        ...buttonWrapperStyle,
      }}>
      <SignUp
        id="banner-sign-up"
        hoverColor={colors.blue}
        labelStyle={{
          textTransform: 'uppercase',
          fontSize: 18,
          fontFamily: 'ProximaNovaRegular'
        }}
        style={{
          width: isMobile
            ? '100%'
            : isSearch ? 250: 269,
          height: 44,
          maxWidth: 330,
        }}
        t={t}
      />
    </div>
  </div>
);
