import React from 'react';
import { styles } from './styles';
import moment from 'moment';

export const TimeSlot = (
  {
    race: {
      plannedStartTime,
      startTime,
      plannedEndTime,
      endTime,
      timeZone,
      raceId,
      name
    }
  }) => {
  const isNumeric = (n) => !isNaN(parseFloat(n)) && isFinite(n);
  const startTimestamp = plannedStartTime || startTime;
  const endTimestamp = plannedEndTime || endTime;
  let times = [moment(1000 * startTimestamp).tz(timeZone)];
  if(!!endTimestamp && !isNumeric(raceId)) {
    times.push(
      moment(1000 * endTimestamp).tz(timeZone)
    );
  }
  return (
    <div className="row mx-0" style={styles.container}>
      <div
        className="col-4 px-0"
        style={styles.time}>
        {times.filter(Boolean).map((time) => time.format('h:mm A z')).join(' - ')}
      </div>
      <div className="col-8" style={styles.name}>{name}</div>
    </div>
  );
};
