import { configs } from '../../configs';

export const categories = [
  {
    id: 0,
    idName: 'FirstTimersUSA', //used in API call
    name: 'First-Timers',
    imageUrl: `${configs.cdnUrl}/images/curated/first-timers.png`,
    description: 'For those considering their first timed race, these events offer a great introduction to the sport.'
  }, {
    id: 1,
    idName: 'PartyTimeUSA',
    name: 'Partying',
    imageUrl: `${configs.cdnUrl}/images/curated/party.png`,
    description: 'Some events are as much entertaining as competitive. Browse through some of the best party events in the industry.'
  }, {
    id: 2,
    idName: 'BestUSABeach',
    name: 'Beaches',
    imageUrl: `${configs.cdnUrl}/images/curated/beach.png`,
    description: 'A beautiful view and a low elevation makes these events some of the premier destinations for events in the United States.'
  }, {
    id: 3,
    idName: 'SetPrUSA',
    name: 'Setting Personal Records',
    imageUrl: `${configs.cdnUrl}/images/curated/personal-records.png`,
    description: 'If you are qualifying for an event or just trying to achieve a personal goal, check out these PR-friendly events.'
  }, {
    id: 4,
    idName: 'UpAndComingUSA',
    name: 'New Destinations',
    imageUrl:`${configs.cdnUrl}/images/curated/on-the-rise.png`,
    description: 'Year over year these events are attracting larger audiences and growing their reputation.'
  }
];

export const homeCarousel = [
  {
    id: 0,
    imageUrl: `${configs.cdnUrl}/images/slideshow/cyclist@2x.jpg`,
    name: 'Build a profile'
  },
  {
    id: 1,
    imageUrl: `${configs.cdnUrl}/images/slideshow/marathon@2x.jpg`,
    name: `Find Races`
  },
  {
    id: 2,
    imageUrl: `${configs.cdnUrl}/images/slideshow/runner@2x.jpg`,
    name: `Make Connections`
  },
  {
    id: 3,
    imageUrl: `${configs.cdnUrl}/images/slideshow/swimmer@2x.jpg`,
    name: `Claim Results`
  }
];

export function getCategoryById(id) {
  const idInt = parseInt(id);
  return categories.find((cat) => cat.id === idInt);
}
