import isSseBlacklisted from "./isSseBlacklisted";
import isSseWhitelisted from "./isSseWhitelisted";
import { useFirebaseConfig } from "./remote-config";

export default function useSse(athlinksEventId: number) {
  const firebaseConfig = useFirebaseConfig()
  const allowSse = firebaseConfig.config.useSse?.asBoolean() ?? false

  return allowSse
    && isSseWhitelisted(firebaseConfig, athlinksEventId)
    && !isSseBlacklisted(firebaseConfig, athlinksEventId)
}