import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { athleteCard, styles } from './styles'
import { profileUserImageUrl } from '../../utils/profileImageUrl'
import { Bib } from '../../components/shared/athlete/Bib'
import { AthletePhoto } from '../../components/athlete/AthletePhoto'
import { goToPage, showModal } from '../../actions/modalActions'
import { Loading } from '../../components/shared/Loading'
import { ModalTypes } from '../../actions/types'
import { LogInOrSignUpIndependent } from '../../components/modal/LogInOrSignUpIndependent'
import { getToken } from '../../utils/isLoggedIn'

const AthleteRacePageCardComponent = (props) => {
  const [followPending, setFollowPending] = useState(false)
  const [timeoutHandle, setTimeoutHandle] = useState(null)
  const [unfollowPending, setUnfollowPending] = useState(false)
  const [unfollowResponse, setUnfollowResponse] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [mouseOverFollowButton, setMouseOverFollowButton] = useState(false)

  const {
    dispatch,
    follow,
    isMobile,
    isPublicProfile,
    t,
    unfollow
  } = props

  const athleteCardModel = props.athleteCardModel || {}

  const {
    ageOnRaceDate,
    bib,
    claimed,
    firstName,
    gender,
    followable,
    following,
    isLoggedIn,
    lastName,
    location,
    racerId,
    unfollowable
  } = athleteCardModel

  useEffect(() => {
    if (
      (followPending && following) ||
      (unfollowPending && !following)
    ) {
      clearTimeout(timeoutHandle)
      setTimeoutHandle(null)
      setFollowPending(false)
      setUnfollowPending(false)
    }
  }, [followPending, following, unfollowPending, timeoutHandle])

  if(unfollowResponse && !unfollowPending) {
    if(unfollowResponse.success) {
      dispatch(
        goToPage(2)
      )
    } else {
      dispatch(
        goToPage(3)
      )
    }
    setUnfollowResponse(undefined)
  }

  const followEntry = async () => {

    if (isLoggedIn) {
      setFollowPending(true)
      setTimeoutHandle(
        setTimeout(() => {
          setTimeoutHandle(null)
          setFollowPending(false)
        }, 30000)
      )
      const token = await getToken()
      const followResponse = await follow(
        token
      )

      if (!followResponse.success) {
        clearTimeout(timeoutHandle)
        setTimeoutHandle(null)
        setFollowPending(false)
      }
    } else {
      setShowSignUpModal(true)
    }
  }

  const unfollowEntry = async () => {
    if(unfollowable && !unfollowPending) {
      setUnfollowPending(true)
      unfollowEntryModal(async () => {
        setTimeoutHandle(
          setTimeout(() => {
            setTimeoutHandle(null)
            setUnfollowPending(false)
            dispatch(goToPage(3))
          }, 10000)
        )
        const token = await getToken()
        try {
          setUnfollowPending(true)
          dispatch(goToPage(1))
          const unfollowConfirmation = await unfollow(token, () => {
            setUnfollowPending(false)
          })
          setUnfollowResponse(unfollowConfirmation)
        } catch (err) {
          console.error(err)
          dispatch(goToPage(3)) 
        }
      })
    }
  }

  const unfollowEntryModal = (
    unfollowWrapper
  ) => {
    dispatch(showModal({
      modalProps: {
        athleteCardModel,
        cancel: () => { setUnfollowPending(false)},
        currentPage: 0,
        noScroll: true,
        unfollow: unfollowWrapper
      },
      modalType: ModalTypes.UNFOLLOW_ATHLETE,
      pages: 4 
    }))
  }

  const getFollowButton = () => {
    const visible = (followable || unfollowable) && claimed
    const disabled = (!followable || !unfollowable) && (unfollowPending || followPending)
    const clickAction = following ? unfollowEntry : followEntry
    const text = following ? 'following' : 'follow'
    return(
      <div style={styles.followButtonContainer(visible, isMobile)}>
        {followPending ? <Loading/>
          : <button
            disabled={disabled}
            onClick={ async () => await clickAction()}
            onMouseOver={() => setMouseOverFollowButton(true)}
            onMouseOut={() => setMouseOverFollowButton(false)}
            style={styles.claimOrFollowButton(followable, unfollowable, isMobile, mouseOverFollowButton)}
          >{t(text).toUpperCase()}</button>
        }
      </div>
    )
  }

  const profPicUrl = profileUserImageUrl(racerId, 't', !!racerId, true)
  const displayName = firstName + ' ' + lastName
  const genderAndAge = (_.startCase(gender) || '') +
    (gender && ageOnRaceDate ? ', ' : '') +
    (ageOnRaceDate || '')

    const homeTown = `${location.city}, ${location.region}, ${location.country}`
    const imgDim = isMobile ? {width: 50, height: 50} : {width: 86, height: 86}

  const profileImg = (
    <div style={athleteCard.photoBox}>
      <AthletePhoto
        isMember={false /* This should be claimed. Hard coded for conference */}
        size={'AthleteRacePageCard'}
        photo={profPicUrl}
        name={displayName}
        containerStyle={{
          margin: '0 auto',
          display: 'inline-block'}}
        styles={{...imgDim}}
      />
    </div>
  )

  const profileUrl = `/athletes/${racerId}`
  const profileElement = !isPublicProfile || !racerId
    ? profileImg
    : <Link
        to={profileUrl}
        style={{display:'block', textDecoration:'none'}}
        href={profileUrl}
      >
        {profileImg}
      </Link>

  return (
    <div itemScope itemType="http://schema.org/Person"
          style={athleteCard.contentContainer}>

      {showSignUpModal && <LogInOrSignUpIndependent
            closeAction={() => setShowSignUpModal(false) }
            open={showSignUpModal}
            isMobile={isMobile}
            t={t}
          />
      }

      <div style={athleteCard.photoContainer}>
        {profileElement}
      </div>

      <div style={{textAlign: isMobile ? 'left' : ''}}>
        <Link
          to={profileUrl}
          style={{display:'block', textDecoration:'none', color: '#4a4a4a'}}
          href={profileUrl}
        >
          {displayName}
        </Link>
        </div>

      <div style={athleteCard.details(isMobile)}>
        <div itemProp="gender" style={athleteCard.firstDetail} id="ageGender">
            {genderAndAge}
        </div>
        <div itemProp="homeLocation" style={isMobile && bib ? athleteCard.firstDetail : athleteCard.secondDetail} id="IRPUserLocation">
          {homeTown}
        </div>
        {isMobile && <div itemProp="bib" style={athleteCard.secondDetail}>
        {bib ? `#${bib}` : ''}
        </div> }
      </div>
      {  !isMobile && bib &&
        <div style={athleteCard.bibContainer}>
          <div style={{...athleteCard.bibTitle, color: '#4a4a4a'}}>
            {t('BIB')}
          </div>
          <Bib bib={bib || ''} />
        </div>
      }
      {getFollowButton()}
    </div>
  )
}

const mapStateToProps = () => ({
  dispatch: undefined
})

export const AthleteRacePageCard = compose(
  connect(mapStateToProps),
  withTranslation()
)(AthleteRacePageCardComponent)
