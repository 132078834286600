import { Theme, makeStyles } from '@material-ui/core';

import { textStyles, loadingLight, fonts } from '../../shared/styles';

type StylesProps = {
  isFetching: boolean;
  showLoading: boolean;
};

const useStyles = makeStyles<Theme, StylesProps>(({ palette }) => ({
  title: {
    color: palette.grey[800],
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0.4,
    marginBottom: '8px',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  loadingPlaceholder: {
    height: 44,
    marginBottom: 5,
    pointerEvents: 'none',
    ...loadingLight,
  },
  dateWrapper: {
    marginBottom: 17,
  },
  date: {
    marginBottom: 5,
    ...textStyles.body1Dark,
  },
  disclaimer: {
    fontSize: 12,
    color: palette.grey[500],
  },
}));

export { useStyles };
