import React from 'react';
import {FormattedDate, FormattedTime, injectIntl} from 'react-intl';
import CalendarIcon from 'react-icons/lib/fa/calendar';

// TODO: needs to convert to typescript and fix the errors, postponed for now

// /**
//  * Prints date and time in given format
//  *
//  * @param {string|Date} date
//  * @param {string} year [OPTIONAL] numeric
//  * @param {string} month [OPTIONAL] long
//  * @param {string} day [OPTIONAL] 2-digit
//  * @param {boolean} showTime [OPTIONAL] true
//  * @param {boolean} hideYear [OPTIONAL] false
//  * @param {boolean} asUTC [OPTIONAL] false
//  * @param {object} style [OPTIONAL] {}
//  * @param {XML} icon [OPTIONAL] Component Icon
//  * @param {boolean} defaultIcon [OPTIONAL] false
//  * @return {XML}
//  * @constructor
//  */
export const DateTime = ({
  date,
  year = 'numeric',
  month = 'long',
  day = '2-digit',
  showTime = true,
  hideYear = false,
  asUTC = false,
  style = {},
  icon = null,
  defaultIcon = false
}) => {
  const value = typeof(date) === Date ? date : new Date(date);
  const timeOffsetInHours = (new Date().getTimezoneOffset()/60);
  if (asUTC) {
    value.setHours(value.getHours() + timeOffsetInHours)
  }
  const dateProp = hideYear ? { month, day, value} : { year , month, day, value};
  return (
    <span style={style} id="date-value">
      {defaultIcon && <CalendarIcon style={{paddingBottom: 3}} />}
      {!defaultIcon && !!icon && icon}
      {' '}
      <FormattedDate {...dateProp}/>
      {showTime && ' - '}
      {showTime && <FormattedTime value={value}/>}
    </span>
  );
};

/**
 * Prints time in h:m:s format
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 *
 * @param {number|Date} value
 * @param {string} timezone
 * @param {boolean} hour12
 * @param {string} hour
 * @param {string} minute
 * @param {string} second
 * @return {XML}
 * @constructor
 */
export const Time = ({
  value,
  timezone = 'UTC',
  hour12 = false,
  hour = 'numeric',
  minute = 'numeric',
  second = 'numeric'
}) => {
  return <FormattedTime
    value={typeof(value) === Date ? value : new Date(value)}
    timeZone={timezone}
    hour12={hour12}
    hour={hour}
    minute={minute}
    second={second} />
};

/**
 * Formats relative time
 *
 * 0-59 minutes                       -> (0-59)m
 * 1-24 hours                         -> (1 - 24)hr
 * Over 24 hours, but on previous day -> Yesterday, 2:21am
 * Occurred before previous day        -> Feb 12, 2017, 2:21am
 *
 * @param {Date} from
 * @param {Date} to   Default is now
 */
export const RelativeTime = injectIntl(({from, to = null, intl, t}) => {
  to = to || new Date();
  const diff = Math.abs(to - from);
  const minutes = Math.floor(diff / 6e4);
  const hours = Math.floor(diff / 36e5);
  const isYesterday = from.getDate() === to.getDate() - 1;
  if(minutes < 60) {
    return <span>{t('{{minutes}}m ago', {minutes: Math.floor(minutes)})}</span>;
  }
  if(hours < 24) {
    return <span>{t('{{hours}}hr ago', {hours: Math.floor(hours)})}</span>;
  }
  const {formatTime, formatRelative, formatDate} = intl;
  const time = formatTime(from).replace(' ', '').toLowerCase();
  if(isYesterday) {
    return (
      <span style={{textTransform: 'capitalize'}}>
        {formatRelative(to - 24 * 36e5)}, {time}
      </span>
    );
  }
  return (
    <span>
      {
        formatDate(from, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        })
      }
      {', '}{time}
    </span>
  );
});
