import { FetchApi } from '../lib/anuket-http';
import { eventBus } from './Streams';
import _ from 'lodash';
import { of as ObservableOf } from 'rxjs';

import {
    streamFail,
    streamHook
} from './Streams';

import { configs } from '../configs';
import { getToken } from '../utils/isLoggedIn';
import { take } from 'rxjs/operators';

export const eventSearchStreamId = 'FollowersList';

function getStreamId(type, profileId) {
    return [eventSearchStreamId, type, profileId].join('-');
}

/**
 * Get list of followers, following or rivals for the selected profile
 *
 * @param {string} type - Type of the records to return, e.g.: followers, following or rivals
 * @param {number} profileId - Profile ID for which to return the data
 * @param {function} success - Function to call of result is successful
 */
function fetchFollowers(type = 'followers', profileId, success = _.noop) {

  //Optimization for components calling fetchFollowers multiple times:
  // only fetch if nothing in the stream yet ({fetching: true})
  getFollowersStream(type, profileId)
    .pipe(take(1))
    .subscribe(async (result) => {
      const streamId = getStreamId(type, profileId);

      //Already got a result, don't fetch
      if (!result.fetching) {
        return;
      }

      if (result.fetching) {
        //Don't have a result yet, fetch it

        //Clear fetching to we don't fetch again
        eventBus.publish(streamId, {});

        let url = `${configs.ATHLINKS_API_HOST}/Links/Api/${profileId}`;

        switch (type) {
          case 'followers':
            // nothing to do
            break;

          // case 'following':
          //     url += '/Suggested';
          //     break;

          case 'rivals':
            url += '/HeadToHead';
            break;

          default:
            // throw new Error(`Invalid request type: '${type}'`);
            // TODO: How do I fail the stream here?
            break;
        }
        const token = await getToken();
        const req = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        streamFail(
          streamId,
          FetchApi.memoizeFetch(10000)(url, req),
          (msg) => {
            success();

            if (msg.ErrorMessage) {
              return {
                error: msg.ErrorMessage,
              };
            }

            return {
              list: msg.Result,
              total: msg.Result.length,
            };
          }
        );
      }
    });
}

function getFollowersStream(type, profileId) {
    const streamId = [eventSearchStreamId, type, profileId].join('-');

    return profileId
      ? streamHook(streamId, { fetching: true }) : ObservableOf({list:[],total:0});
}

export { fetchFollowers, getFollowersStream };
