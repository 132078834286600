import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';
import { withTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { QuestionMark } from '../icons';
import { urlRegex } from '../../../utils/regex';

export class ResultsLinkComponent extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired
  };

  state = {
    url: '',
    urlInvalid: true,
    renderInvalid: false,
    wasBlurred: false
  };

  onChange(e) {
    const { callback } = this.props;
    const { wasBlurred } = this.state;
    const url = e.target.value;
    const urlValid = url.length > 0 && !url.includes('athlinks.com') ? urlRegex.test(url) : false;

    this.setState({
      renderInvalid: wasBlurred && !urlValid,
      url,
      urlInvalid: !urlValid
    });

    callback(urlValid ? url : undefined);
  }

  onBlur() {
    const { url, wasBlurred, urlInvalid } = this.state;

    if (url && !wasBlurred) {
      this.setState({
        renderInvalid: urlInvalid,
        wasBlurred: true
      })
    }
  }

  renderErrorMessage() {
    const { t } = this.props;
    const _styles = styles.message;

    return (
      <div style={_styles.container}>
        <span style={_styles.style}>
          {t('Please ensure this is a valid web link. Ex: www.event.com/results')}
        </span>
      </div>
    );
  }

  toolTip(isMobile) {
    const { t } = this.props;
    const headerStyle = 'lineHeight: 17px; font-size: 14px; margin: 0; padding: 10px 0 0 0; font-weight: bold;';
    const theRestStyle = 'lineHeight: 15px; font-size: 12px; margin: 0; padding: 0;';
    const content =
      `<p style="${headerStyle}">${t('What is a results link?')}</p>` +
      `<p style="${theRestStyle} margin-top: 10px;">${t('A url link to the official event results')}</p>` +
      `<p style="${theRestStyle}">${t('that have been posted online. In order')}</p>` +
      `<p style="${theRestStyle}">${t('for Athlinks to validate results in a')}</p>` +
      `<p style="${theRestStyle}">${t('timely manner, we need a link to all of')}</p>` +
      `<p style="${theRestStyle} padding-bottom: 10px;">${t('the official results for an event.')}</p>`;

    return (
      <span data-tip={content} data-for={'add-event-tooltip'} style={styles.question}>
        <QuestionMark />
        <ReactTooltip
          key={'add-event-tooltip'}
          id={'add-event-tooltip'}
          html={true}
          place={'right'}
          type={'dark'}
          effect={'float'}
          border={false}
          multiline={true}
          class='extraClass'
          clickable={isMobile}
          globalEventOff={'touchend'}
        />
      </span>
    );
  };

  render() {
    const { isMobile, t } = this.props;
    const { url, renderInvalid } = this.state;

    const placeHolderStyle = !url
      ? styles.linkBox.placeholder
      : {};
    const errorStyle = renderInvalid
      ? styles.linkBox.error
      : styles.linkBox.regular;

    const inputStyle = {
      ...styles.linkBox.style(isMobile),
      ...placeHolderStyle,
      ...errorStyle
    };

    return (
      <div className={isMobile ? 'col-12 pl-0' : ''} style={styles.container(isMobile)}>
        <div style={styles.titleContainer}>
          <span style={styles.title}>
            {t('Event Results Link')}
          </span>
          <span style={styles.star}>
            {'*'}
          </span>
          {this.toolTip(isMobile)}
        </div>
        <div className={isMobile ? 'row m-0' : ''} style={isMobile ? { paddingRight: 15 } : {}}>
          <input
            onBlur={() => this.onBlur()}
            onChange={(e) => this.onChange(e)}
            placeholder={'www.event.com/results'}
            style={inputStyle}
            spellCheck='off'
            autoComplete='off'
            autoCorrect='off'
          />
          {renderInvalid && this.renderErrorMessage()}
        </div>
      </div>
    )
  }
}

export const ResultsLink = withTranslation()(ResultsLinkComponent);
