import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configs } from '../configs'
import { extractSearchParams } from '../utils/extractSearchParams'
import { EventMetadata, EventResult, IrpMedia, IrpResult } from '../utils/types'

export const resultsApi = createApi({
  reducerPath: 'resultsApi',
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: configs.resultsApiUrl }),
    {
      maxRetries: configs.rtkQueryMaxRetries
    }
  ),
  endpoints: (builder) => ({
    getEventMetadata: builder.query<EventMetadata, number>({
      query: (
        athlinksEventId,
      ) => `/metadata/event/${athlinksEventId}`
    }),
    getEventResults: builder.query<EventResult[], {
      athlinksEventId: number
      eventCourseId?: number
      divisionId?: number
      intervalId?: number
      from?: number
      limit?: number
    }>({
      query: ({
        athlinksEventId,
        ...params
      }) => `/event/${athlinksEventId}?${extractSearchParams(params)}`
    }),
    getIrpResult: builder.query<IrpResult, {
      eventId: number
      eventCourseId: number
      bib?: string
      id?: number
    }>({
      query: ({
        ...params
      }) => `/individual?${extractSearchParams(params)}`
    }),
    getIrpMedia: builder.query<IrpMedia, {
      thirdPartyEntryId: number
      thirdPartyEventId: number
    }>({
      query: ({
        thirdPartyEntryId,
        thirdPartyEventId,
      }) => `/media/${thirdPartyEventId}?id=${thirdPartyEntryId}`
    })
  })
})

export const {
  useGetEventMetadataQuery,
  useGetEventResultsQuery,
  useGetIrpMediaQuery,
  useGetIrpResultQuery,
} = resultsApi
