import React, { useEffect, useState }from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { countdownStyles as styles } from './styles'
import { colors } from '../../shared/styles';

export const Countdown = ({irp, isMobile, startDateTime, t}) => {

  const calculateTimeLeft = () => {
    const startTime = parseInt(startDateTime.timeInMilliseconds) ?
      moment.tz(parseInt(startDateTime.timeInMilliseconds), startDateTime.timeZone).valueOf()
      : 0
    const difference = startTime - (+new Date())
    return moment.duration(difference > 0 ? difference : 0)
  }
  
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    let interval = null
    interval = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 60000)

    return () => clearInterval(interval)
  })

  const resultsLink = !timeLeft.asMinutes() && !!irp ?
    <div className={'col-12'} style={styles.irpContainer(false)}>
      {t('Race will begin soon.')}{t(' Check here for ')}
      <Link
        to={irp}
        style={{display:'inline-block', color: colors.blue}}
        href={irp}
      >
        {t('results')}
      </Link>
  </div> : <div/>

  return (
    <div className={'container'}>
      <div className={'col-12'} style={styles.titleStyle}>
        {t('Countdown to Race Start')}
      </div>
      <div style={styles.countdownContainer(!!timeLeft.asMinutes())}>
        <div style={styles.countdownColumn}>
          <div style={{display:'inline-block'}}>
            <div style={styles.countdownBigNum(Math.trunc(timeLeft.asDays()), isMobile)}>
              {('0' + Math.trunc(timeLeft.asDays())).slice(timeLeft.asDays() >= 100 ? -3 : -2)}</div>
            <div className={'col-12'} style={styles.labelStyle}>{t('days')}</div>
          </div>
          <div style={styles.separator} />
        </div>
        <div style={styles.countdownColumn}>
          <div style={{display:'inline-block'}}>
            <div style={styles.countdownBigNum(Math.trunc(timeLeft.asHours()), isMobile)}>
              {('0' + timeLeft.hours()).slice(-2)}</div>
            <div className={'col-12'} style={styles.labelStyle}>{t('hours')}</div>
          </div>
          <div style={styles.separator} />
        </div>
        <div style={styles.countdownColumn}>
          <div style={{display:'inline-block'}}>
            <div style={styles.countdownBigNum(Math.trunc(timeLeft.asMinutes()), isMobile)}>
              {('0' + timeLeft.minutes()).slice(-2)}</div>
            <div className={'col-12'} style={styles.labelStyle}>{t('minutes')}</div>
          </div>
        </div>
      </div>
      {resultsLink}
      
    </div>
  )
};
