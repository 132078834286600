import React from 'react';
import {styles} from './styles';
import {Link} from 'react-router-dom';
import {DateTime} from '../../shared/DateTime';
import {Location} from '../../shared/Location';
import ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right';

export const EventHeader = ({resultsUrl, event, t}) => {
  const regionArray = event.regionId ? event.regionId.split('-') : [];
  const state = regionArray.length > 1 ? regionArray[1] : '';

  return (
    <Link to={resultsUrl}>
      <div className='row' style={styles.eventNameContainer}>
        <div className='col-9'>
          <div className='row'>
            <div className='col-12' id='event-name' style={{fontSize: 18}}>
              {event.name}
            </div>
            <div className='col-12' style={{display: 'inline-flex'}}>
              <DateTime
                date={event.startEpoch * 1000}
                showTime={false}
                icon={null}
                style={styles.dateTime}
              />
              <Location
                city={event.city}
                state={state}
                country={event.countryId}
                icon={null}
                searchType={null}
              />
            </div>
          </div>
        </div>
        <div className='col-3 px-0' id='all-results' style={styles.allResults}>
          <span>
            {t('All results')}
          </span>
          <ArrowRight size={20} style={{color: '#16A9E1'}}/>
        </div>
      </div>
    </Link>
  );
};
