import React from 'react';
import { configs } from '../../configs';
import { inIframe } from '../../utils/inIframe';
import { isReferrerValid, isRedirectUrlValid } from '../../utils/auth';

export class SignUpPage extends React.Component {

  returnUrl = () => {
    const {referrerPath} = this.props;
    if (referrerPath) return configs.bragiUrl + (isReferrerValid(referrerPath) ? referrerPath : '/');
    else {
      const currentLocation = document.location.href;
      return isRedirectUrlValid(currentLocation) ? currentLocation : configs.bragiUrl;
    }
  };

  componentDidMount() {

    if (inIframe()) {
      window.open(`${configs.bragiUrl}/signup`, 'signup')
    }
    else {
      const keycloak = window.kc;
      const currentLocation = this.returnUrl();
      keycloak.login({
        redirectUri: currentLocation,
        action: 'register'
      });
    }
  }

  render() {
    return null;
  }
}
