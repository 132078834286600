import { ofType } from 'redux-observable'
import { catchError, mergeMap, map } from 'rxjs/operators'
import { MapTrackingActionTypes } from '../actions/types'
import { mapCheckActionComplete, mapCheckActionError } from '../actions/mapTrackingAction'
import { ajax } from 'rxjs/ajax'
import {configs} from '../configs'

const buildBaseRequest = (
  method,
  url
) => ({
  url,
  crossDomain: true,
  withCredentials: false,
  method
})

const buildRequest = (
  method
) => (
  url
) => buildBaseRequest(method, url)

const buildGetRequest = buildRequest('HEAD')

const ajaxGet = (
  url
) => ajax(
  buildGetRequest(url)
)

const getUrl = (eventCourseId) =>
  `https://s3.amazonaws.com/${configs.mapS3BucketName}/${eventCourseId}/map.geojson`

export const handleResponse = (
  {status}
) => mapCheckActionComplete(status === 200)

export const mapCheckEpic = (actions$) => {
  return actions$.pipe(
    ofType(MapTrackingActionTypes.MAP_CHECK),
    mergeMap((action) =>
      ajaxGet(getUrl(action.eventCourseId)).pipe(
        map(handleResponse),
        catchError(() => [mapCheckActionError()])
      )
    )
  )
}

