import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import {ResultRow} from './ResultRow'

export const ResultsComponent = (props) => {
  const slice = props.slice || 3;
  const {
    results,
    searchTerm,
    t
  } = props;

  const shownResults = results.slice(0, slice);
  const searchAllResultsLink = `/search/unclaimed/?category=unclaimed&term=${searchTerm}`;

  return(
    <div>
      <Link to={searchAllResultsLink}>
        <div style={styles.topLabel}>
          {t('Unclaimed Results')}
        </div>
      </Link>

      <div style={styles.resultContent}>

        {
          shownResults.map(({ entryId, bibNum, raceTitle, eventId, eventCourseId, athleteTime, raceCity, raceStateProvAbbrev, athleteName,athleteAge,athleteGender}) => (
            <ResultRow entryId={entryId}
                       bibNum={bibNum}
                       raceTitle={raceTitle}
                       eventId={eventId}
                       eventCourseId={eventCourseId}
                       athleteTime={athleteTime}
                       searchTerm={searchTerm}
                       raceCity={raceCity}
                       raceStateProvAbbrev={raceStateProvAbbrev}
                       athleteName = {athleteName}
                       athleteAge={athleteAge}
                       athleteGender={athleteGender}
                       styles={styles}
                       key={entryId}
            />
          ))
        }

        <Link to={searchAllResultsLink}>
          {
            <span style={styles.moreLink}>
              {t('See all unclaimed results for "{{searchTerm}}"', {searchTerm})}
            </span>
           }
        </Link>
      </div>
    </div>
  );
};
