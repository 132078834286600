import { Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ProfileRenderer } from '../../components/eventRoster/ProfileRenderer';
import { StandardTable, Cell } from '../../components/shared/tables/Table';
import { toNameCase } from '../../utils/stringUtils';
import { RosterEntry } from '../../types.rosters';

type Props = {
  isMobile: boolean,
  pageSize: number,
  roster?: RosterEntry[],
}
export function RosterTable({
  isMobile,
  pageSize,
  roster,
}: Props) {
  const { t } = useTranslation();

  const profileColumnRenderer = (x: RosterEntry, i: string) => {

    const teamUrl = () => {
      if (x.team && x.team.trim().length) {
        const u = new URL(window.location.href);
        u.searchParams.set('term', x.team);
        u.searchParams.set('page', '1');
        u.searchParams.set('pageSize', String(pageSize));
        return u.pathname + u.search;
      }
      return undefined;
    };
    return (
      <Cell key={'profile-cell' + i} align="left">
        <ProfileRenderer
          age={x.age}
          avatarUrl={''}
          bib={x.bib}
          city={x.city}
          country={x.country}
          displayName={!!x.private ? toNameCase(t('Unknown Racer')) : toNameCase(x.displayName || t('Unknown Racer'))}
          gender={x.gender}
          href={null}
          isMember={false}
          isPrivate={!!x.private}
          racerId={0}
          region={x.region}
          team={x.team}
          teamLink={teamUrl()}
          useItemScope />
      </Cell>
    );
  };

  const locationColumnRenderer = (x: Pick<RosterEntry, 'city' | 'country' | 'region'>, i: string) => {
    const location = [
      toNameCase(x.city),
      x.region?.toUpperCase() || '',
      x.country?.toUpperCase() || '',
    ].filter((j) => {
      const isNumeric = !isNaN(parseInt(j));
      return !!j && !isNumeric;
    })
      .join(', ');

    return (
      <Cell key={'location-cell' + i} align="right">
        <address itemProp={'homeLocation'}>{location}</address>
      </Cell>
    );
  };

  const teamRenderer = (x: Pick<RosterEntry, 'team'>, i: string) => {
    const buildTeamUrl = () => {
      if (x.team?.trim().length) {
        const u = new URL(window.location.href);
        u.searchParams.set('term', x.team);
        u.searchParams.set('page', '1');
        u.searchParams.set('pageSize', String(pageSize));
        return u.pathname + u.search;
      }
      return undefined;
    };
    const teamUrl = buildTeamUrl()
    return (
      <Cell key={'team-cell' + i} align="center">
        {teamUrl ? (
          <Link component={RouterLink} to={teamUrl}>{x.team}</Link>
        ) : '--'}
      </Cell>
    );
  };

  const columns = isMobile ? [
    {
      label: t('Athlete'),
      key: profileColumnRenderer
    },
    // {
    //   label: t('Location'),
    //   key: locationColumnRenderer,
    // },
  ] :
    [
      {
        label: t('Athlete'),
        key: profileColumnRenderer
      },
      {
        label: t('Bib'),
        key: 'bib',
        itemProp: 'identifier',
      },
      {
        label: t('Age'),
        key: (x: Pick<RosterEntry, 'age'>) => !!x.age ? x.age : '--',
      },
      {
        label: t('Gender'),
        key: (x: Pick<RosterEntry, 'gender'>) => ['M', 'F', 'NB', 'U'].includes(x.gender || 'U')
          ? x.gender
          : 'U',
        itemProp: 'gender',
      },
      {
        label: t('Team'),
        key: teamRenderer,
        itemProp: 'team',
      },
      {
        label: t('Location'),
        key: locationColumnRenderer,
      }
    ];

  return <StandardTable
    columns={columns}
    dataSource={roster}
    itemScopeType={'http://schema.org/Person'}
    itemProp={'attendees'} />;
}
