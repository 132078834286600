import { eventBus } from '../../../anuket-http';

/**
 * @param {string} topic A stream topic name.
 * @param {function} callback A data-fetching function, e.g., AthletesNoble.getProfile.
 * @param {object|null} The arguments for callback (possibly null).
 * @return {mixed} The message or error returned by callback().
 * @see FetchApi.doDataFetch.
 */
export function retryHelper(topic, callback, args) {
  function publishToTopic() {
    callback(args).subscribe((_) => eventBus.publish(topic, _));
  }

  let data = eventBus.getTopic(topic);
  eventBus.getTopic('retry-' + topic).subscribe((_) => publishToTopic());
  publishToTopic();
  return data;
}
