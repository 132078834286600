import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {
  Link,
  withRouter
} from 'react-router-dom';
import { styles } from './styles';
import LatestUpdate from './upcomingEvents/LatestUpdate';
import UpcomingEvent from './upcomingEvents/UpcomingEvent';
import {textStyles, colors} from '../../shared/styles';
import { eventSearchClick } from '../shared/QuickLinks';
import {getPendingStatusForResult} from '../../utils/ClaimUtil';

/**
 * Upcoming events component
 */
class UpcomingEventsComponent extends Component {

  static propTypes = {
    upcomingEvents: PropTypes.array.isRequired,
    suggestedEvents: PropTypes.array.isRequired,
    isMe: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    liveEvent: PropTypes.array,
    athleteId: PropTypes.string.isRequired,
    isMobile: PropTypes.bool,
    noResultsMessage: PropTypes.string.isRequired,
    races: PropTypes.array,
    fetching: PropTypes.bool,
    pendingClaims: PropTypes.object,
    t: PropTypes.func
  };

  state = {
    viewAllEvents: false
  };

  /**
   * View all events on upcoming events section
   */
  viewAllEvents() {
    this.setState({
      viewAllEvents: true
    });
  }

  raceFinished(race) {
    return race.ticksString || race.unofficialTicks;
  }

  render() {
    const {
      type,
      title,
      fetching,
      noResultsMessage,
      isMobile,
      isMe,
      athleteId,
      upcomingEvents = [],
      suggestedEvents = [],
      races = [],
      liveEvent,
      pendingClaims,
      history,
      t
    } = this.props;

    const {
      viewAllEvents
    } = this.state;

    // TODO: refactor lines below, it works but very confusing
    const useLatest = (!viewAllEvents && type === 'latestUpdate');
    const validEvents = !useLatest
      ? upcomingEvents
      : upcomingEvents.filter(this.raceFinished);

    return (
      <div>
        <OverviewEvents
          title={title}
          noEventMessage={noResultsMessage}
          type={type}
          isMobile={isMobile}
          athleteId={athleteId}
          liveEvent={liveEvent}
          isMe={isMe}
          suggested={suggestedEvents}
          events={useLatest ? validEvents.slice(0, 3) : validEvents}
          races={races.filter(this.raceFinished)}
          fetching={fetching}
          pendingClaims={pendingClaims}
          t={t}
        />
        {
          (!viewAllEvents && type === 'upcomingEvents' ) && !isMobile &&
           <ViewAll
              messages={_.isEmpty(upcomingEvents)
                ? t('Browse Events')
                : t('Find More Events')
              }
              type='upcomingEvents'
              onClickAction={(ev) => eventSearchClick(ev, t, history)}
              isMobile={isMobile}
           />
        }
      </div>
    );
  }
}

export default withTranslation()(withRouter(UpcomingEventsComponent))

/**
 * Show upcoming events, live events and latest update events content
 *
 * @param title
 * @param noEventMessage
 * @param type
 * @param events
 * @param athleteId
 * @param races
 * @param isMobile
 * @param liveEvent
 * @param fetching
 * @param pendingClaims
 * @param t
 * @param history
 * @returns {XML}
 * @constructor
 */
const OverviewEventsComponent = ({title, noEventMessage, type, events, athleteId, isMobile, races, liveEvent,
                                fetching, pendingClaims, t, history}) => {
  const color = type === 'liveEvents' ? colors.orange : colors.textColor;
  const today = new Date();

  return (
    <div style={styles.overviewEventsContainer(fetching)}>
      {
        // Loading Placeholder
      }
      {
        fetching &&
        <div style={styles.upcomingLoader(fetching)}>
          <div style={styles.upcomingTitleLoader(true)}/>
          <EventPlaceholder />
          <EventPlaceholder />
          <EventPlaceholder />
        </div>
      }

      <h2 style={{...styles.boxTitle(isMobile), color}} id={`section-${type}`}>
        {title}
      </h2>

      {
        events.length === 0 && !fetching  ?
        <div>
          <p style={styles.noUpcoming} id="noUpcomingEvent">{noEventMessage}</p>
          {
            type === 'latestUpdate' &&
            <ViewAll
              messages={t('Find My Results')}
              type='latestUpdate'
              link='/search/unclaimed'
              isMobile={isMobile}
              history={history}
            />
          }
        </div>
        :
        type === 'latestUpdate' ?
          events.map((event, index) => {
            const result = event.race.courses;
            const claimStatus = (pendingClaims && !pendingClaims.fetching &&
              getPendingStatusForResult(pendingClaims.result, event)) || '';

            return (
              <LatestUpdate key={`${type}-${index}`}
                            event={event}
                            type={type}
                            race={event.race}
                            result={result}
                            isMobile={isMobile}
                            raceRankings={races[index]}
                            liveEvent={liveEvent}
                            claimStatus={claimStatus}
              />
            )
          })
          :
          events.map((event, index) => {
            if(type === 'upcomingEvents' && (new Date(event.StartDate).setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0))){
              return (
                <UpcomingEvent key={`${type}-${index}`}
                               event={event}
                               type={type}
                               isMobile={isMobile}
                               athleteId={parseInt(athleteId)}
                />
              )
            } else if(type === 'liveEvents' && (new Date(event.StartDate).setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0))){
              return (
                <UpcomingEvent key={`${type}-${index}`}
                               event={event}
                               type={type}
                               isMobile={isMobile}
                />
              )
            }

            return null
          })
      }
      <div>
        {
          type !== 'latestUpdate' && isMobile && !fetching &&
          <ViewAll
            messages={t('Find More Events')}
            type='upcomingEvents'
            onClickAction={(ev) => eventSearchClick(ev, t, history)}
            isMobile={isMobile}
          />
        }

        {
          !isMobile && (events.length === 0) && !fetching &&
          <div style={{textAlign: 'center'}}>
          {
            (type === 'latestUpdate' && events.length !== 0) &&
            <Link to={'/athletes/' + athleteId + '/results'} style={textStyles.textLink2Blue}>
              {t('VIEW ALL')}
            </Link>
          }
          </div>
        }
      </div>
    </div>

  );
};

export const OverviewEvents = withRouter(OverviewEventsComponent);

/**
 *
 * Buttons for find more events, watch live and
 *
 * @param messages
 * @param type
 * @param link
 * @param isMobile
 * @param onClickAction
 * @param history
 * @returns {XML}
 * @constructor
 */
export const ViewAll = ({messages, type, link, isMobile, onClickAction, history}) => {
  let height,fontSize;

  if(isMobile) {
    height = '50px';
    fontSize= '20px';
  }

  const width = isMobile || type === 'searchMembers' ? '81%' : !isMobile && type === 'liveEvents' ? '45%' : '40%';
  const backgroundColor = type !== 'liveEvents' ? colors.blue : colors.orange;
  const marginTop = type === 'liveEvents' ? 60 : 15;

  return (
      <div style={styles.viewAllResults(backgroundColor, marginTop, width, height, fontSize, isMobile)}>
          {
            onClickAction &&
            <div id='browse-button' onClick={onClickAction} style={styles.viewAllText}>{messages}</div>
          }
          {
            !onClickAction &&
            <div id='browse-button' onClick={() => history.push(link)} style={styles.viewAllText}>{messages}</div>
          }
      </div>
  );
};


const EventPlaceholder = () => (
  <div style={styles.eventPlaceholder}>
    <div style={styles.eventTitleLoader}/>
    <div style={styles.eventDetailLoader}/>
    <div className='row' style={styles.loaderStandingsRow}>
      <div className='col-12 col-md-6 px-0' style={{display:'flex'}}>
        <StandingPlaceholder className='col-12 col-md-4' />
        <StandingPlaceholder className='col-12 col-md-4' />
        <StandingPlaceholder className='col-12 col-md-4' />
      </div>
      <div className='col-12 col-md-6 px-0' style={{display:'flex'}}>
        <StandingPlaceholder className='col-6' />
        <StandingPlaceholder className='col-6' />
      </div>
    </div>
    <div style={styles.eventSocialLoader}/>
  </div>
);


const StandingPlaceholder = ({className, style = {}}) => (
  <div className={className} style={style}>
    <div style={styles.standingLoaderTitle}/>
    <div style={styles.standingLoaderUnderline}/>
    <div style={styles.standingLoaderMetric}/>
  </div>
);
