import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DateTime } from "../../components/shared/DateTime";
import { _date, isInFuture } from "../../utils/date";
import { trackGoogleEvent } from "../../utils/googleEvents";
import { useStyles } from "./styles";
import useEventMetadata from '../../hooks/useEventMetadata'

interface LearnMoreOrRegisterButtonProps {
  CTLiveLink: string;
  masterId: number;
  realURL?: string;
  startTime: string
  athlinksId: number
}

export const LearnMoreOrRegisterButton = ({
  CTLiveLink,
  masterId,
  realURL,
  startTime,
  athlinksId
}: LearnMoreOrRegisterButtonProps) => {
  const forceRegisterHideArray = [115192];
  const forceHidingRegisterButton = forceRegisterHideArray.includes(masterId);
  const { data: eventMetadata, isLoading } = useEventMetadata(athlinksId)
  const isRegPublished = eventMetadata.registration?.published ?? true

  const { t } = useTranslation();
  const styles = useStyles();

  const isInFutureVal = isInFuture(startTime);

  const showRegisterButton = CTLiveLink && isInFutureVal && !forceHidingRegisterButton && isRegPublished;
  const showLearnMoreButton = !showRegisterButton && !!realURL;

  if ((!showRegisterButton && !showLearnMoreButton) || isLoading) {
    return null;
  }

  return (
    <Box className={styles.actionButtonsContainer}>
      {showRegisterButton && (
        <a
          href={CTLiveLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackGoogleEvent('Race', 'Click', 'Register')}
          className={styles.actionButtonLinkRegister}
        >
          {t('Register')}
          <br />
          <Typography component='span' className={styles.actionButtonNote}>
            <DateTime
              date={_date(startTime)}
              showTime={false}
              icon={null}
            />
          </Typography>
        </a>
      )}

      {showLearnMoreButton && (
        <a
          href={realURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Learn More')}
          className={styles.actionButtonLinkLearnMore}
        >
          {t('LEARN MORE')}
        </a>
      )}
    </Box>
  );
};