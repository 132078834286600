import React, {Component} from 'react';
import PropTypes from 'prop-types';

import GoogleMap from 'google-map-react';

import { styles } from './styles';

import {
  googleApiKey
} from '../../shared/GeoSuggestInput';

const { curatedEventsMap } = styles;

const Circle = ({color, size}) => {
  return (
    <div style={curatedEventsMap.circleWrap(size, color)}
    />
  );
};

const LocationMarker = ({color, zIndex, size, onSelected}) => {
  return (
    <div onClick={onSelected} style={curatedEventsMap.locationMarkerWrap(zIndex)}>
      <Circle
        size={size}
        color={color}
      />
    </div>
  )
};

function findEventWithLatLng(events) {
  return (events || []).find((e) => e.latitude && e.longitude);
}

export class CuratedEventsMap extends Component {
  static propTypes = {
    events: PropTypes.array,
    defaultMapCenter: PropTypes.object,
    activeEvent: PropTypes.object,
    onEventSelected: PropTypes.func
  };

  static defaultProps = {
    defaultMapCenter: {lat: 35.3629, lng: -77.2816} //somewhere in USA
  };

  constructor(props) {
    super(props);

    //Set center in map only 1 once first mount
    const firstEventWithLatLong = findEventWithLatLng(props.events);

    const center = firstEventWithLatLong
      ?  {lat: firstEventWithLatLong.latitude, lng: firstEventWithLatLong.longitude}
      :  props.defaultMapCenter;

    this.state = {
      center
    }
  }

  renderEventMarkers() {
    const {
      events,
      activeEvent,
      onEventSelected
    } = this.props;

    return (events || [])
      .filter((e) => !!e.latitude && !!e.longitude)
      .map((e) => {
      const isActive = e.masterId === (activeEvent || {}).masterId;
      return (
        <LocationMarker
          key={e.masterId}
          lat={e.latitude}
          lng={e.longitude}
          onSelected={() => onEventSelected(e)}
          zIndex={isActive ? 1 : 0}
          size={isActive ? 20 : 15}
          color={isActive ? 'red' : 'blue'}
          />
      );
    });
  }

  render() {
    //const center = {lat: activeEvent.latitude, lng: activeEvent.longitude};
    const {
      center
    } = this.state;

    return (
      <div style={curatedEventsMap.googleMapContainer}>
        <GoogleMap
          bootstrapURLKeys={{
          key: googleApiKey,
          language: 'en'
        }}
          defaultCenter={center}
          zoom={3}>

          {this.renderEventMarkers()}

        </GoogleMap>
      </div>
    );
  }
}
