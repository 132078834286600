import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { PageTemplate } from '../home/PageTemplate';
import { OpenGraphTags } from '../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../shared/StandardHeaderTags';
import { AthleteSearch } from './AthleteSearch';
import { setTermValue, getSearchTerm } from '../../data/SearchTerm';
import { getLastProfileSearchQuery } from '../../data/SearchStreams';
import {
  fetchAthleteFollowing,
  getAthleteFollowingStream
} from '../../data/AthleteStreams';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import {defaultProfileFilters, getProfileSearchFilters} from '../../data/SearchFilters';
import {connect} from 'react-redux';
import {SearchActionTypes} from '../../actions/types';
import {eventBus} from '../../data/Streams';
import {ATHLETE_CONTEXT} from '../../data/SearchContext';

const mapStateToProps = (state) => ({
  dispatch: undefined,
  context: state.search.context
});

class AthleteSearchPageComponent extends React.Component {
  static propTypes = {
    filters: PropTypes.object,
    searchTerm: PropTypes.string,
    context: PropTypes.string,
    getLastProfileSearchQuery: PropTypes.object,
    isMobile: PropTypes.bool,
    t: PropTypes.func
  };

  componentDidMount() {
    const {
      searchTerm,
      context,
      dispatch
    } = this.props;

    if(searchTerm){
      setTermValue(searchTerm);
    }

    if(context !== ATHLETE_CONTEXT) {
      dispatch({
        type: SearchActionTypes.CONTEXT_SET,
        context: ATHLETE_CONTEXT
      });
      eventBus.publish('search-context', ATHLETE_CONTEXT);
    }

    this.fetchFriends();
  }

  fetchFriends = () => {
    const tokenRacerId = getTokenRacerId();
    tokenRacerId && fetchAthleteFollowing(tokenRacerId);
  };

  render() {
    const {
      isMobile,
      friends,
      searchTerm,
      lastProfileSearchQuery,
      quickSearchEvents,
      t,
      profileData
    } = this.props;
    const defaultFilters = defaultProfileFilters(t);
    const filters = {
      ...defaultFilters,
      ...this.props.filters || profileData,
    };

    const ogTitle = searchTerm
      ? t('Athlinks Athlete Search: {{searchTerm}}', {searchTerm})
      : t('Athlinks Athlete Search');
    const ogDescription = searchTerm ? t(
      'Search Athlinks for athletes that match {{searchTerm}}',
      {searchTerm}
    ) : t('Search and find athletes on Athlinks');

    return (
      <PageTemplate
        filters={filters}
        searchTerm={searchTerm}
        headerIsFixed={true}
        isMobile={isMobile}
        showSearchTab={'athletes'}
        showHeaderFilters={true}
        onSearchInput={this.onSearchInput}
        onLocationInput={this.onLocationInput}
        searchTab="athlete">
        <StandardHeaderTags
          title={ogTitle}
          description={ogDescription}
        />
        <OpenGraphTags
          ogType='website'
          title={ogTitle}
          description={ogDescription}
        />
        <AthleteSearch
          isMobile={isMobile}
          searchTerm={searchTerm}
          lastProfileSearchQuery={lastProfileSearchQuery}
          friends={friends}
          afterFollow={this.fetchFriends}
          quickSearchEvents={quickSearchEvents}
          filterValues={filters}
        />
      </PageTemplate>
    );
  }

  onSearchInput = ({target: {value}}) => {
    setTermValue(value);
  };
}

export const AthleteSearchPage = connect(mapStateToProps)(withTranslation()(connectStream({
  friends: () => getAthleteFollowingStream(getTokenRacerId()),
  searchTerm: getSearchTerm,
  profileData: ({ t }) => getProfileSearchFilters(t),
  lastProfileSearchQuery: getLastProfileSearchQuery
})(AthleteSearchPageComponent, 'athletes')));

