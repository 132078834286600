import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configs } from '../configs'

export const finisherCertificateApi = createApi({
  reducerPath: 'finisherCertificateApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: configs.finisherCertificateUrl,
    })
  ),
  endpoints: (builder) => ({
    getFinisherCertificate: builder.query<string, {
      athlinksEventId: number
      bib?: string
      entryId?: number
      eventCourseId?: number
      thirdPartyEntryId?: number
    }>({
      query: ({
        athlinksEventId,
        bib,
        entryId,
        eventCourseId,
        thirdPartyEntryId,
      }) => ({
        url: !!bib
          ? `?bib=${bib}&eventCourseId=${eventCourseId}&eventId=${athlinksEventId}`
          : `?id=${thirdPartyEntryId || entryId}&eventCourseId=${eventCourseId}&eventId=${thirdPartyEntryId}`,
        redirect: 'follow',
        responseHandler: (response: Response) => response.url,
      }),
    }),
  })
})

export const {
  useGetFinisherCertificateQuery,
} = finisherCertificateApi
