import FetchApi from '../lib/anuket-http/FetchApi';
import { Nobles } from '../lib/anuket';
import { configs } from '../configs';
import { eventBus, streamHook } from './Streams';
import { getImage } from '../utils/heroImageUrl';

/**
 * Get event status
 * @param id
 * @param masterId
 * @param eventId
 * @returns {*}
 */
export function getEventStatus(id, {masterId, eventId}) {
  const streamId = id || `EventStatus-${masterId}-${eventId}`;
  const stream = streamHook(streamId, {isLoading: true});
  const url = configs.ATHLINKS_API_HOST
    + `/Events/Race/Result/Api/ActivationStatus?masterEventId=${masterId}&raceId=${eventId}`;
  FetchApi.memoizeFetch(10000)(url).subscribe((msg) => {
    if (msg.success && msg.result) {
      eventBus.publish(streamId, (msg.result && msg.result.length) ? msg.result[0] : { activatesIn:-1, deactivatesIn:-1, isLive:false })
    }
  }, (err) => console.log(err));
  return stream;
}

/**
 * Get event by id
 * @param eventId
 * @param id
 * @returns {*}
 */
export function getEventByEventId(eventId, id) {
  const streamId = id || `EventInfo-${eventId}`;
  if (!isNaN(parseInt(eventId))) {
    const stream = streamHook(streamId, {isLoading: true});
    Nobles.EventsNoble.getEventByEventId({eventId}).subscribe((msg) => {
      if (msg && !msg.err) {eventBus.publish(streamId, msg)}
    });
    return stream;
  }
  else {
    streamHook(streamId, {error: 'invalid event id: ' + eventId})
  }
}

/**
 * Check hero image existence
 * @param eventId
 * @param id
 * @returns {*}
 */
export function checkHeroImage(affiliateRaceId, masterEventId, eventType, isMobile, hasHeroImage) {
  const size = isMobile ? 600 : 1600;
  const heroImageUrl = `https://d1wtzsjnbruk95.cloudfront.net/img/event/banner/hero-image/${affiliateRaceId}-${size}.jpg`;
  FetchApi.memoizeRawFetch(10000)(
    heroImageUrl,
    { method: 'HEAD' },
    false,
    false
  ).subscribe((msg) => {
    if(msg.meta.status === 200 && hasHeroImage) {
      setHeroImage(masterEventId, heroImageUrl);
    }
    else {
      setHeroImage(masterEventId, getImage(eventType, size))
    }
  }, () => {
    setHeroImage(masterEventId, getImage(eventType, size))
  });
}

export function getHeroImage(masterEventId) {
  return streamHook(`HeroImageCheck-${masterEventId}`, {isLoading: true});
}

export function setHeroImage(masterEventId, data) {
  eventBus.publish(`HeroImageCheck-${masterEventId}`, data)
}

/**
 * Get event by id
 * @param eventId
 * @param id
 * @returns {*}
 */
export function receiveEventByEventId({eventId, id}) {
  const streamId = id || `EventByEventId-${eventId}`;
  const stream = streamHook(streamId, {isLoading: true});
  const url = `${configs.ATHLINKS_API_HOST}/Events/Api/Merged/${eventId}`;
  FetchApi.memoizeFetch(10000)(url).subscribe((msg) => {
    if (msg.success && msg.result) {
      const res = msg.result;
      eventBus.publish(streamId, Nobles.EventsNoble.mapEvents(res.athlinksId ? [res] : [])[0]);
    }
  }, (err) => console.log(err));
  return stream;
}

export const EventStreams = {
  getEventStatus,
  getEventByEventId,
  receiveEventByEventId
};

export function getEventOptions(masterId) {
  return streamHook(`event-options-${masterId}`, []);
}

export function setEventOptions(masterId, eventOpts) {
  eventBus.publish(`event-options-${masterId}`, eventOpts)
}
