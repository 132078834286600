import { TimeUnit } from '@eventops/athlinks-results-model'
import { TimeUnits } from "../types.results";

export default function convertTimeUnitsToOldTimeUnit(timeUnits: TimeUnits): TimeUnit {
  switch (timeUnits) {
    case 'hr':
      return TimeUnit.HOURS
    case 'min':
      return TimeUnit.MINUTES
    case 's':
      return TimeUnit.SECONDS
    default:
      return TimeUnit.MILLI_SECONDS
  }
}