import {FetchApi, eventBus} from '../lib/anuket-http';
import {configs} from '../configs';
import {
  streamFail,
  streamHook
} from './Streams';
import {getToken, isLoggedIn, getTokenRacerId} from '../utils/isLoggedIn';

const fetchAthleteStreamId = 'FetchAthlete';
const fetchAthleteSummaryStreamId = 'FetchAthleteSummary';
const fetchAthleteCalendarStreamId = 'FetchAthleteCalendar';
const fetchAthleteRacesStreamId = 'FetchAthleteRaces';
const fetchAthleteRivalsStreamId = 'FetchAthleteRivals';
const fetchAthleteVsRivalStreamId = 'FetchAthleteVsRival';
const fetchAthleteVsRivalDetailsStreamId = 'FetchAthleteVsRivalCourse';
const fetchAthleteSuggestedStreamId = 'FetchAthleteSuggested';
const fetchAthleteFollowingStreamId = 'FetchAthleteFollowing';
const fetchAthleteFriendsUpcomingStreamId = 'fetchAthleteFriendsUpcoming';
const fetchAthleteKeycloakStreamId = 'FetchAthleteKeycloak';
/**
 * Fetch athlete by athlete id
 * @param athleteId
 */
export function fetchAthlete(athleteId) {
  // console.log('fetchAthlete', arguments)
  if (!athleteId) {
    return;
  }

  getToken().then((token) => {
    const url = configs.ATHLINKS_API_HOST + `/Athletes/Api/${athleteId}`;

    const streamId = fetchAthleteStreamId + '-' + athleteId;

    streamFail(
      streamId,
      FetchApi.memoizeFetch(10000)(
        url,
        {
          method: 'GET',
          headers: isLoggedIn() ? { 'Authorization': `Bearer ${token}` } : {}
        }
      ),
      (msg) => {
        return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {athlete: athleteResult(msg)});
      }
    );
  });
}

/**
 * Fetch athlete from keycloak
 */
export function fetchAthleteKeycloak(athleteId) {
  // console.log('fetchAthleteKeycloak', arguments)
  if (!isLoggedIn()) {
    return;
  }

  getToken().then((token) => {
    const url = configs.keycloakUrl + `/auth/realms/athlinks/protocol/openid-connect/userinfo`;
      const streamId = fetchAthleteKeycloakStreamId + '-' + athleteId;

      streamFail(
        streamId,
        FetchApi.memoizeFetch(10000)(
          url,
          {
            method: 'GET',
            headers: { 'Authorization': `Bearer ${token}` }
          }
        ),
        (msg) => {
          return (msg.ErrorMessage ? {error: msg.ErrorMessage} : msg);
        }
      );
  });
}

/**
 * Get athlete personal record and statistics data
 * @param athleteId
 */
export function fetchAthleteSummary(athleteId) {
  // console.log('fetchAthleteSummary', arguments)
  if (!athleteId) {
    return;
  }

  const url = configs.ATHLINKS_API_HOST + `/Athletes/Api/${athleteId}/Summary`;

  const streamId = fetchAthleteSummaryStreamId + '-' + athleteId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {summary: athleteSummaryResult(msg)});
    }
  );
}

/**
 * Build camel case string
 * @param key
 * @param value
 * @returns {*}
 */
function camelCaseReviver(key, value) {
  if (value && typeof value === 'object') {
    for (let k in value) {
      // noinspection JSUnfilteredForInLoop
      // noinspection JSUnfilteredForInLoop
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        // noinspection JSUnfilteredForInLoop
        // noinspection JSUnfilteredForInLoop
        // noinspection JSUnfilteredForInLoop
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        // noinspection JSUnfilteredForInLoop
        delete value[k];
      }
    }
  }
  return value;
}

/**
 * Get athlete races by athleteId
 * @param athleteId
 * @param shouldBustCache
 */
export function fetchAthleteRaces(athleteId, shouldBustCache = false) {
  // console.log('fetchAthleteRaces', athleteId, shouldBustCache)
  const streamId = fetchAthleteRacesStreamId + '-' + athleteId;

  if (!athleteId) {
    eventBus.publish(streamId, {})
    return streamHook(streamId, {});
  }

  getToken().then((token) => {
    const bustCache = shouldBustCache
      ? isLoggedIn() ? '&busted=1' : '?busted=1'
      : '';
    const url = isLoggedIn()
      ? configs.ATHLINKS_API_HOST + `/athletes/api/${athleteId}/Races?spectatorId=${getTokenRacerId()}${bustCache}`
      : configs.ATHLINKS_API_HOST + `/athletes/api/${athleteId}/Races${bustCache}`;
      console.log(url);
    const streamId = fetchAthleteRacesStreamId + '-' + athleteId;
    streamFail(
      streamId,
      FetchApi.memoizeFetch(10000)(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        },
        false
      ),
      (msg) => {
        const parsed = JSON.parse(msg, camelCaseReviver);
        return (parsed.errorMessage ? {error: parsed.errorMessage} : {races: athleteRacesResult(parsed)});
      }
    );
  });
}

/**
 * Get athlete followings
 * @param athleteId
 */
export function fetchAthleteFollowing(athleteId) {
  // console.log('fetchAthleteFollowing', arguments)
  if (!athleteId) {
    return;
  }
  const url = `${configs.ATHLINKS_API_HOST}/Links/Api/${athleteId}`;
  const streamId = `${fetchAthleteFollowingStreamId}-${athleteId}`;
  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(url, {method: 'GET'}),
    (msg) => msg.ErrorMessage ?
      ({error: msg.ErrorMessage}) : ({result: msg.Result, fetching: false})
  );
}

/**
 * Get upcoming events for an athlete
 * @param athleteId
 */
export function fetchAthleteCalendar(athleteId) {
  // console.log('fetchAthleteCalendar', arguments)
  if (!athleteId) {
    return;
  }

  const url = configs.ATHLINKS_API_HOST + `/Athletes/Api/${athleteId}/Calendar`;

  const streamId = fetchAthleteCalendarStreamId + '-' + athleteId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {calendar: athleteSummaryResult(msg)});
    }
  );
}

/**
 * Get athlete rivals
 * @param athleteId
 */
export function fetchAthleteRivals(athleteId) {
  // console.log('fetchAthleteRivals', arguments)
  if (!athleteId) {
    return;
  }

  const url = configs.ATHLINKS_API_HOST + `/Links/Api/${athleteId}/HeadToHead`;

  const streamId = fetchAthleteRivalsStreamId + '-' + athleteId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {rivals: msg.Result});
    }
  );
}

/**
 * Compare athlete results with his rival by ids
 * @param athleteId
 * @param rivalId
 * @param courseIds
 */
export function fetchVs(athleteId, rivalId, courseIds) {
  // console.log('fetchVs', arguments)
  const queryCourseIds = (courseIds || []).length > 0 ? `?courseIDs=${courseIds.join(',')}` : '';

  const url = configs.ATHLINKS_API_HOST + `/Links/Api/${athleteId}/Vs/${rivalId}${queryCourseIds}`;

  const streamId = fetchAthleteVsRivalStreamId + '-' + athleteId + '-' + rivalId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {vs: msg.Result});
    }
  );
}

/**
 * Get details of comparison of two rivals
 * @param athleteId
 * @param rivalId
 * @param courseId
 */
export function fetchVsDetails(athleteId, rivalId, courseId) {
  // console.log('fetchVsDetails', arguments)
  const queryCourseId = courseId ? `?courseID=${courseId}` : '';

  const url = configs.ATHLINKS_API_HOST + `/Links/Api/${athleteId}/Vs/${rivalId}/Details${queryCourseId}`;

  const streamId = fetchAthleteVsRivalDetailsStreamId + '-' + athleteId + '-' + rivalId + '-' + courseId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {vsDetails: msg.Result});
    }
  );
}

/**
 * Get list of suggested athlete to follow
 */
export function fetchAthleteSuggested() {
  // console.log('fetchAthleteSuggested', arguments)
  const athleteId = getTokenRacerId();
  const url = configs.ATHLINKS_API_HOST + `/Links/Api/${athleteId}/Suggested`;

  const streamId = fetchAthleteSuggestedStreamId + '-' + athleteId;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {suggested: msg.Result});
    }
  );
}

/**
 * Get friend's upcoming events list
 * @param athleteId
 */
export function fetchAthleteFriendsUpcoming(athleteId) {
  // console.log('fetchAthleteFriendsUpcoming', arguments)
  const viewerId = getTokenRacerId();
  const url = configs.ATHLINKS_API_HOST + `/Athletes/Api/${athleteId}/CalendarAndLive?raceridviewer=${viewerId}`;
  const streamId = `${fetchAthleteFriendsUpcomingStreamId}-${athleteId}`;

  streamFail(
    streamId,
    FetchApi.memoizeFetch(10000)(
      url,
      {method: 'GET'}
    ),
    (msg) => {
      return (msg.ErrorMessage ? {error: msg.ErrorMessage} : {result: msg.Result});
    }
  );
}

//Cache all latest athlete API results to immediately display info if we ever fetched it before
//TODO: how do we clear memory?

export function getAthleteStream(athleteId) {
  return streamHook(fetchAthleteStreamId + '-' + athleteId, {fetching: true});
}

export function getAthleteSummaryStream(athleteId) {
  return streamHook(fetchAthleteSummaryStreamId + '-' + athleteId, {fetching: true});
}

export function getAthleteCalendarStream(athleteId) {
  return streamHook(fetchAthleteCalendarStreamId + '-' + athleteId, {fetching: true});
}

export function getAthleteRacesStream(athleteId) {
  return streamHook(fetchAthleteRacesStreamId + '-' + athleteId, {fetching: true});
}

export function getAthleteRivalsStream(athleteId) {
  return streamHook(fetchAthleteRivalsStreamId + '-' + athleteId, {fetching: true});
}

export function getAthleteVsRivalStream(athleteId, rivalId) {
  return streamHook(fetchAthleteVsRivalStreamId + '-' + athleteId + '-' + rivalId, {fetching: true});
}

export function getAthleteVsRivalDetailsStream(athleteId, rivalId, courseId) {
  return streamHook(fetchAthleteVsRivalDetailsStreamId + '-' + athleteId + '-' + rivalId + '-' + courseId, {fetching: true});
}

export function getAthleteSuggestedStream(id) {
  return streamHook(fetchAthleteSuggestedStreamId + '-' + id);
}

export function getAthleteFollowingStream(athleteId) {
  return streamHook(`${fetchAthleteFollowingStreamId}-${athleteId}`, {fetching: true});
}

export function getAthleteFriendsUpcomingStream(athleteId) {
  return streamHook(`${fetchAthleteFriendsUpcomingStreamId}-${athleteId}`, {fetching: true});
}

export function getAthleteKeycloakStream(athleteId) {
  return streamHook(fetchAthleteKeycloakStreamId + '-' + athleteId, {fetching: true});
}


//Main parsing/transformation to data coming in from API
function athleteResult(json) {
  return json.Result;
}

function athleteSummaryResult(json) {
  return json.Result;
}

/**
 * Official and unofficial race results for an athlete
 * @param json
 * @returns {Array.<*>}
 */
function athleteRacesResult(json) {
  const result = json.result || {};
  const races1 = (result.raceEntries || {}).list || [];
  const races2 = (result.unofficialEntries || {}).list || [];
  const races3 = (result.inReviewRaceEntries || {}).list || [];

  const officialRaces = (races1 || []).map((race) => transformRace(race));
  const unofficialRaces = (races2 || []).map((race) => transformRace(race, true));
  const inReviewRaces = (races3 || []).map((race) => transformRace(race));

  return officialRaces.concat(inReviewRaces).concat(distinctUnofficial(unofficialRaces)).sort(
    ({race: {raceDate: d1}}, {race: {raceDate: d2}}) => new Date(d2) - new Date(d1)
  );
}

/**
 * Removes duplicates from unofficial results array
 * @param {[]} results
 * @return {[]} distinct results
 */
const distinctUnofficial = (results) => Object.values(
  results.reduce((acc, result) => {
    const {iD, race: {raceID} = {}} = result;
    acc[`${iD}-${raceID}`] = result;
    return acc;
  }, {}));

function transformRace(race, isUnofficial = false) {
  race = {...race};

  race.isUnofficial = isUnofficial;

  if (isUnofficial) {
    race.entryID = race.id;
  }

  return race;
}
