export function extractSearchParams(params: {
  [key: string]: any;
}) {
  const search = new URLSearchParams(
    Object.keys(params)
      .reduce(
        (a, c) => ({
          ...a,
          [c]: String(
            isNaN(params[c])
              ? params[c] || ''
              : params[c]
          )
        }),
        {}
      )
  );
  return String(search);
}
