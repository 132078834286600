import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { ReigniteSsePayload, SseState } from '../utils/types'

export const DEFAULT_SSE_STATE: SseState = {
  sse: {
    eventResults: {},
    irpResult: {}
  }
}

const initialState = DEFAULT_SSE_STATE

export const reigniteSseSlice = createSlice({
  name: 'reigniteSse',
  initialState,
  reducers: {
    latestSsePayload: (
      state: Draft<SseState>,
      action: PayloadAction<{ path: string } & ReigniteSsePayload>
    ) => {
      const { payload } = action
      const { path } = payload
      const resultsType = payload.payload.type === 'irp' ? 'irpResult' : 'eventResults'
      state.sse[resultsType] = {
        [path]: {
          lastPayload: payload,
          lastUpdated: Date.now(),
        },
      }
    }
  }
})

const { latestSsePayload } = reigniteSseSlice.actions

export { latestSsePayload }

export const getEventResultsSelector = (path: string) =>
  (state: {
    reigniteSse: SseState
  }) => path
      ? state.reigniteSse.sse?.eventResults[path]
      : undefined

export const getIrpResultsSelector = (path: string) =>
  (state: {
    reigniteSse: SseState
  }) => state.reigniteSse.sse?.irpResult[path]

export default reigniteSseSlice.reducer
