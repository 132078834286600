import { Box, Button, Chip, Dialog, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { useState } from "react";

import { useStyles } from "./styles";
import { FilterIconMedium, FilterIconBig } from "./FilterIcon";

type SelectedFiltersItem = {
  label: string
  value: string
  isRemovable?: boolean
}

type InputWithFiltersProps = {
  selectedFilters: SelectedFiltersItem[]
  onClickApply: () => void
  onClickDeleteFilter: (filterValue: string) => void
  renderInput: () => JSX.Element
  renderFilters: () => JSX.Element
  isFullScreen?: boolean
  onOpenFilters?: () => void
}

const InputWithFilters = ({
  selectedFilters,
  onClickApply,
  onClickDeleteFilter,
  renderInput,
  renderFilters,
  isFullScreen,
  onOpenFilters,
}: InputWithFiltersProps) => {
  const styles = useStyles()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onClickFilterButton = () => {
    setIsDialogOpen(true)

    if (onOpenFilters) {
      onOpenFilters()
    }
  }

  const onClickApplyButton = () => {
    setIsDialogOpen(false)
    onClickApply()
  }

  return (
    <div>
      <div className={styles.container}>
        <Box flex={1}>
          {renderInput()}
        </Box>

        <Button
          startIcon={<FilterIconMedium />}
          variant="outlined"
          className={styles.filterButton}
          onClick={onClickFilterButton}>
          Filter
        </Button>
      </div>

      {!!selectedFilters.length && (
        <div className={styles.chipsContainer}>
          {selectedFilters.map((filter) => (
            <Chip
              key={filter.value}
              className={styles.chipsItem}
              label={filter.label}
              variant="outlined"
              onClick={onClickFilterButton}
              onDelete={filter.isRemovable ? () => onClickDeleteFilter(filter.value) : undefined}
            />
          ))}
        </div>
      )}

      <Dialog fullScreen={isFullScreen} open={isDialogOpen} onClose={setIsDialogOpen}>
        <div className={styles.dialogContainer}>
          <div className={styles.dialogHeaderContainer}>
            <Typography className={styles.dialogHeaderTitle}>
              Event Filter
              <FilterIconBig />
            </Typography>

            <CloseIcon
              className={styles.dialogHeaderCloseIcon}
              onClick={() => setIsDialogOpen(false)}
            />
          </div>

          <div className={styles.dialogFiltersContainer}>
            {renderFilters()}
          </div>

          <div className={styles.dialogFooterContainer}>
            <Button
              className={styles.dialogFooterButton}
              onClick={() => setIsDialogOpen(false)}>
              Close
            </Button>

            <Button
              className={`${styles.dialogFooterButton} ${styles.dialogFooterApplyButton}`}
              onClick={onClickApplyButton}>
              Apply Filter
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export { InputWithFilters }

export type { SelectedFiltersItem }