import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import { filterStyles, transition }  from '../../shared/styles';
import { Pagination } from '../../components/shared/Pagination';
import { RaceResultList } from './RaceResultList';
import { SearchPageHeader } from '../../components/shared/Elements';
import { styles } from './styles';

const STARTING_LIMIT = 30;

/**
 * @TODO: Maybe we can move state and componentDidMount logic to parent so
 * @TODO: we can make this component stateless
 */
class UnclaimedSearchComponent extends Component {
  static propTypes = {
    allLocationsToggled: PropTypes.func.isRequired,
    allRaceResultsToggled: PropTypes.func.isRequired,
    birthYear: PropTypes.number,
    birthYearTyped: PropTypes.func.isRequired,
    blockResultEntries: PropTypes.func,
    canSearch: PropTypes.bool.isRequired,
    filterValues: PropTypes.object,
    isMobile: PropTypes.bool.isRequired,
    locationToggled: PropTypes.func.isRequired,
    nameTyped: PropTypes.func.isRequired,
    onFilterChanged: PropTypes.func,
    pendingClaims: PropTypes.object,
    raceResults: PropTypes.array.isRequired,
    raceResultToggled: PropTypes.func.isRequired,
    searchButtonPressed: PropTypes.func.isRequired,
    searchFilters: PropTypes.object,
    searching: PropTypes.bool.isRequired,
    searchTerm: PropTypes.string,
    selectedRaces: PropTypes.array.isRequired,
    t: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      limit: STARTING_LIMIT
    }
  }

  /**
   * Validates birth year. Do not start validation until 4 year digit is entered
   * @param {number} value
   * @param {number} min
   * @param {number} max
   * @return {string|null}
   */
  birthYearValidator = (value, min, max) => {
    const {
      t
    } = this.props;

    if (value < 1000) return null;
    else if (value < min) return t('Birth year must be greater than 1900');
    else if (value > max) return t('Birth year cannot be in future');
    else return null;
  };

  /**
   * Get location name label from result locations
   * @param defaultLocationOptions
   * @param selectedLocations
   * @returns {*}
   */
  getSelectedLocationName(defaultLocationOptions, selectedLocations){
    const {enabled = false, value = []} = selectedLocations;
    if(!enabled) return [];
    return defaultLocationOptions.filter((location) =>
      location !== undefined && value.includes(location.value));
  }

  sortByLocationName = ({label: t1}, {label: t2}) => {
    return t1.toUpperCase().localeCompare(t2.toUpperCase());
  };

  loadMore = () => {
    const {limit} = this.state;
    this.setState({limit: limit + 20})
  };

  render() {
    const {
      searching,
      raceResults,
      resultLocations,
      raceResultToggled,
      allRaceResultsToggled,
      selectedRaces,
      isMobile,
      filterValues,
      searchTerm,
      canSearch,
      blockResultEntries,
      t
    } = this.props;

    const {
      limit
    } = this.state;

    const numResults = (canSearch && raceResults) ? raceResults.length : 0;
    const defaultLocationOptions = resultLocations.map(
      ({LocationString, Id}) => ({value: Id, label: LocationString})).sort(this.sortByLocationName);

    const searchMessage = !searchTerm
      ? t('Enter an athlete name in the search box above to begin searching for athletes')
      : (searchTerm.length < 4
        ? t('Please narrow your search term to search for athletes') : '');

    const selectedLocations = filterValues &&
      this.getSelectedLocationName(defaultLocationOptions, filterValues.locationFilter);
    const allSelectedLocations = (selectedLocations || []).filter(
      (location) => location !== undefined).map((location) => location);

    const LoaderToShow = isMobile ? LoadingResultMobile : LoadingResult;
    const loadingNum = limit <= STARTING_LIMIT ? 5 : limit;
    const limitLengthArray = Array.from(Array(loadingNum));

    return (
      <div className="container" style={filterStyles.content}>
        <div className='row'>
          <div className="col-md-12 col-lg-12">
            <div key="total-records-div">
              <SearchPageHeader
                isMobile={isMobile}
                filterStyles={filterStyles}
                processing={searching}
                styles={filterStyles}
                total={numResults}
                searchTerm={this.props.searchTerm}
                filters={{}}
                canSearch={canSearch}
                selectedLocations={allSelectedLocations}
                hasResults={canSearch}
                title={t('Unclaimed Results')}
                showButtonToAddNew={false}
                message={searchMessage}
              />

              <div style={ transition.fade(!searching) }>
                {
                  canSearch && numResults > 0 &&
                  <div>
                    <RaceResultList
                      raceResults={raceResults.slice(0, limit)}
                      raceResultToggled={raceResultToggled}
                      allRaceResultsToggled={allRaceResultsToggled}
                      selectedRaces={selectedRaces}
                      blockResultEntries={blockResultEntries}
                      isMobile={isMobile}
                    />
                    <Pagination
                      key="pagination"
                      total={numResults}
                      isMobile={isMobile}
                      limit={limit}
                      processing={searching}
                      onChange={this.loadMore}
                      t={t}
                    />
                  </div>
                }
              </div>

              {
                // Loading placeholder
              }
              {
                searching &&
                <div style={styles.loadingContainer(searching, isMobile)}>
                  {
                    limitLengthArray.map((item, index) =>
                      <LoaderToShow key={`loader-${index}`} />
                    )
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export const UnclaimedSearch = withTranslation()(UnclaimedSearchComponent);

const LoadingResult = () => (
  <div style={styles.loadingRow}>
    <div style={styles.loadingDetailContainer}>
      <div style={styles.loadingTitle}/>
      <div style={styles.loadingSubtitle}/>
      <div style={styles.loadingSubtitle2}/>
    </div>
    <div style={styles.loadingTimeBox}/>
    <div style={styles.loadingButton}/>
  </div>
);

//Mobile results are laid out differently
const LoadingResultMobile = () => (
  <div style={styles.loadingRowMobile}>
    <div style={styles.loadingDetailContainer}>
      <div style={styles.mobile.loadingTitle()}/>
      <div style={styles.mobile.loadingSubtitle()}/>
      <div style={styles.mobile.loadingSubtitle2()}/>
    </div>
    <div style={styles.mobile.loadingTimeBox()}/>
    <div style={styles.mobile.loadingButton()}/>
  </div>
);
