import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { hideModal } from '../../../../actions/modalActions'
import { style } from '../arpModalStyles'
import { RightChevronRegular } from '../../../shared/icons'

const mapStateToProps = () => ({
  dispatch: undefined
})

const UnclaimEntryConfirmComponent = (
  props
) => {
  const {
    isMobile
  } = props

  return (
    <div style={style.container(isMobile)}>
      <div className='row' style={style.detailsRow}>
        <div className={`col-12 ${isMobile ? 'p-0' : 'pl-0'}`}>
          Are you sure you want to unclaim this result?
        </div>
      </div>
      <div className='row' style={style.dateLinkRow}>
        It may take a moment for this update to appear in our system.
      </div>
    </div>
  )
}

export const UnclaimEntryConfirm = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnclaimEntryConfirmComponent)

const UnclaimEntryConfirmFooterComponent = (
  props
) => {
  const {
    dispatch,
    isMobile,
    setUnclaiming,
    unclaim,
    t
  } = props

  const footerStyle = isMobile ? style.footerMobile : {}
  const footerClass = isMobile ? '' : 'row align-items-end justify-content-end'
  const nextText = isMobile ? t('No, Keep This Result') : t('Yes, Unclaim');

  return (
    <div style={ footerStyle }>
      <div className={footerClass} style={isMobile ? {} : {marginRight: 30}}>
        <button
          disabled={false}
          onClick={() => {
            setUnclaiming(false)
            dispatch(hideModal())
          }}
          style={style.cancelButton}
          tabIndex={-1}
        >
          {t('Cancel')}
        </button>
        <button
          disabled={false}
          onClick={async () => {
            await unclaim()
          }}
          style={style.nextButton(false, isMobile)}
        >
          <div style={ style.nextButtonInner }>
            <div style={ style.nextButtonText(isMobile) }>
              {nextText}
            </div>
            <RightChevronRegular fill='#fff' height={14} style={ style.rightChevron(isMobile) } width={14}/>
          </div>
        </button>
      </div>
    </div>
  )
}

export const UnclaimEntryConfirmFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(UnclaimEntryConfirmFooterComponent)

const UnclaimEntryConfirmHeaderComponent = ({ t }) => {
  return (
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Unclaim Result')}
      </div>
    </div>
  )
}

export const UnclaimEntryConfirmHeader = withTranslation()(UnclaimEntryConfirmHeaderComponent)