import React from 'react';
import { styles } from './styles';
import { Loading } from '../shared/Loading';

export function TypeaheadLoader(props) {
  const { isLightMode, isLoading, t, doSearchAgain, isFirstSearch } = props;
  console.debug('TypeaheadLoader', { isLightMode, isLoading, doSearchAgain, isFirstSearch })
  return isLoading || isFirstSearch || doSearchAgain ?
    (
      <div
        style={styles.searchInputLoader}>
        <Loading />
      </div>
    ) : (
      <div
        id='kiosk-suggestion-dropdown'
        style={{
          ...styles.searchSuggestionDropdown(isLightMode),
        }}>
        <span
          style={styles.noResultsTextContainer(isLightMode)}>
          {t('We couldn\'t find your result. Please check with the timer.')}
        </span>
      </div>
    )
}
