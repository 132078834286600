import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import ExpandMore from 'react-icons/lib/md/expand-more';
import ExpandLess from 'react-icons/lib/md/expand-less';
import {withTranslation} from 'react-i18next';
import {eventBus} from '../../data/Streams';
import {searchTabStyle} from './styles';
import {
  EVENT_CONTEXT,
  ATHLETE_CONTEXT,
  RESULT_CONTEXT
} from '../../data/SearchContext';
import {SearchActionTypes} from '../../actions/types';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import Style from 'style-it';
import clearSearchFilters from '../../utils/ClearSearchResults'

const mapStateToProps = (state) => ({
  dispatch: undefined,
  context: state.search.context
});

const tabNameMap = {
  [EVENT_CONTEXT]: 'Events',
  [ATHLETE_CONTEXT]: 'Athletes',
  [RESULT_CONTEXT]: 'Results'
};

class SearchTabComponent extends Component {

  static propTypes = {
    searchTerm: PropTypes.string,
    context: PropTypes.string,
    isHomePage: PropTypes.bool,
    onFocusChange: PropTypes.func,
    t: PropTypes.func
  };

  static defaultProps = {
    isHomePage: false,
    onFocusChange: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      listActive: false,
    };
  }

  /*
   Toggle Options list
  */
  toggleList() {
    !this.state.listActive && this.props.onFocusChange(true)
    this.setState((prevState) => ({
      listActive: !prevState.listActive
    }));
  }

  /*
   Remove on clicking outside of component
  */
  handleClickOutside = (e) => {
    this.props.onFocusChange(false, e)
    this.setState({ listActive: false })
  };

  setContext = (context) => {
    eventBus.publish('search-context', context);
    clearSearchFilters(null, null, this.props.history)
    this.props.dispatch({
      type: SearchActionTypes.CONTEXT_SET,
      context
    })
  };

  render() {
    const {
      context,
      searchTerm,
      isHomePage,
      isMobile,
      styleMode,
      notFound,
      history,
      t
    } = this.props;

    const isSearchPage = window.location.href.indexOf('/search/') !== -1;

    const { listActive } = this.state;
    const query = searchTerm ? `term=${encodeURIComponent(searchTerm)}` : '';
    const searchEventsLink = searchTerm ? `/search/events?category=events&${query}` : `/search/events?${query}`;
    const searchAthletesLink = searchTerm ? `/search/athletes?category=athletes&${query}` : `/search/athletes?${query}`;
    const searchUnclaimedResultsLink = searchTerm ? `/search/unclaimed?category=unclaimed&${query}` : `/search/unclaimed?${query}`;

    const options = [
      {
        value: {context: RESULT_CONTEXT, url: searchUnclaimedResultsLink},
        label: t('Results')
      },
      {
        value: {context: EVENT_CONTEXT, url: searchEventsLink},
        label: t('Events')
      },
      {
        value: {context: ATHLETE_CONTEXT, url: searchAthletesLink},
        label: t('Athletes')
      }
    ];

    return (
      <div
        style={searchTabStyle.wrapper(listActive, isHomePage, styleMode === 'dark', notFound, isMobile)}
        onClick={() => this.toggleList()}
      >
        <div style={searchTabStyle.selected(listActive, isHomePage, styleMode === 'dark')}>
          {t(tabNameMap[context])}
          {
            !listActive
              ? <ExpandMore
                  style={searchTabStyle.expandButton}
                  size="20"
                />
              : <ExpandLess
                  style={searchTabStyle.expandButton}
                  size="20"
                />
          }
        </div>
        {
          listActive &&
          <div style={searchTabStyle.listWrapper(isHomePage, styleMode === 'dark', notFound)}>
            {
              options.map((option, index) => {
                const lastElem = options.length === index + 1;
                return (
                  <div key={index}>
                    {
                      Style.it(searchTabStyle.pseudoStyle(isHomePage, lastElem, styleMode === 'dark'),
                        <div
                          className="contextItem"
                          onClick={() => {
                            this.setContext(option.value.context);

                            if(isSearchPage) {
                              history.push(option.value.url)
                            }
                          }}
                          style={{textAlign: 'center'}}
                        >
                          {option.label}
                        </div>
                      )
                    }
                  </div>
                )
              })
            }
          </div>
        }
      </div>
    );
  }
}

export const SearchTab = connect(mapStateToProps)(withTranslation()(
  withRouter(onClickOutside(SearchTabComponent))
));
