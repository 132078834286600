import React from 'react';
import { styles } from './styles';
import { configs } from '../../configs';
import { Link } from 'react-router-dom';

export const CCPA = (props) => {
  const { isContentOnly = false, showDownloadLink = true } = props;
  const containerStyle = isContentOnly ? styles.contentOnly : styles.fullPage;

  return (
    <div className="row" style={containerStyle}>
      <div>
        <h1 style={{ marginBottom: 5 }}>Athlinks - ChronoTrack Privacy Notice for California Consumers</h1>
        {showDownloadLink && <a style={styles.privacyDownload} download href={`${configs.cdnUrl}/policy/Athlinks_CCPA.pdf`}>Download</a>}
        <h5>
          <time>Last Updated: January 1, 2020</time>
        </h5>
        <p>
          Athlinks and ChronoTrack including ChronoTrack Systems Corp. and its affiliates and subsidiaries (collectively,
          <b>“Athlinks,” “we,” “us,”</b> or <b>“our(s)”</b>) provides this Privacy Notice for California Consumers
          (the “CCPA Privacy Notice”)
          to supplement the information contained in the
          {<Link to="/home/privacy">
            &nbsp;Athlinks - ChronoTrack Privacy Policy&nbsp;
          </Link>}
          This CCPA Privacy Notice sets forth   our privacy practices as required by the California Consumer Privacy Act of 2018 (<b>“CCPA”</b>).
        </p>
        <p>
          The CCPA Privacy Notice applies only to individuals residing in the State of California who are considered
          “<b>Consumers</b>” under the CCPA and from whom we collect “Personal Information” as described in the CCPA (“Consumers”).
          If you are an Athlinks - ChronoTrack employee, employee applicant, or contractor, please refer to the Athlinks -
          ChronoTrack Privacy Notice for California Consumers (Employees).
        </p>
        <h3>Information We Collect</h3>
        <p>
          We may collect Personal Information from you in a variety of different situations, including, but not limited to on our
          website, your mobile device, through email, in physical locations like on-site at events, through the mail, and/or over
          the telephone. More specifically, we may collect the following categories of Personal Information from our Consumers,
          which will depend on the particular Business Purpose for which we collect it:
        </p>
        <table style={styles.tableCCPA} cellSpacing='0' cellPadding='0'>
          <tbody>
            <tr>
              <th>Category</th>
              <th>Examples of Personal Information Collected </th>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>A. Identifiers.</td>
              <td style={styles.tableCCPA.def}>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address,
                phone number, email address, account name, date of birth.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
              <td style={styles.tableCCPA.cat}>A name, signature, physical characteristics or description, address, telephone number, date of birth, financial account
                information, credit card number, debit card number, medical information.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>C. Protected classification characteristics under California or federal law.</td>
              <td style={styles.tableCCPA.cat}>Age, marital status, medical condition, physical disability, sex (including gender, gender identity, gender expression,
                pregnancy or childbirth and related medical conditions), veteran or military status.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>D. Commercial information.</td>
              <td style={styles.tableCCPA.cat}>Records of products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>E. Biometric information.</td>
              <td style={styles.tableCCPA.cat}>Sleep, health, or exercise data.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>F. Internet or other similar network activity..</td>
              <td style={styles.tableCCPA.cat}>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>G. Geolocation data.</td>
              <td style={styles.tableCCPA.cat}>Physical location or movements.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>H. Sensory data.</td>
              <td style={styles.tableCCPA.cat}>Audio, visual.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>I. Professional or employment-related information.</td>
              <td style={styles.tableCCPA.cat}>Current or past job history.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>J. Education information, as defined by the Family Educational Rights and Privacy Act.</td>
              <td style={styles.tableCCPA.cat}>None.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>K. Inferences drawn from other personal information.</td>
              <td style={styles.tableCCPA.cat}>Profile reflecting a person’s preferences, characteristics, behavior.</td>
            </tr>
          </tbody>
        </table>
        <p>
          Personal information does not include deidentified or aggregated consumer information.
        </p>
        <p>
        We obtain the categories of Personal Information listed above from the following categories of sources:
        </p>
        <ul>
          <li>Directly from you. For example, from forms you complete or products and services you use and/or purchase.</li>
          <li>Indirectly from you. For example, from observing your actions on our website or from information your computer
            or mobile device transmits when interacting with our Website or App, among other things.</li>
          <li><b>From public event results posted on third-party websites as well as integrations with third party race services
             who share data in the normal course of conducting race registration, timing, scoring, photography, or other event services function.</b>
          </li>
        </ul>
        <h3>How We Use Personal Information</h3>
        <p>
          We may use or disclose the Personal Information we collect from you or about you to do one or more of the following:
        </p>
        <ul>
          <li>
            To fulfill or meet the purpose for which you provided the information. For example, if you share your name and contact
            information to ask us a question or inquire about our products or services, we will use that personal information to respond
            to your inquiry. If you provide your personal information to purchase a membership or other product or service, we will use that
            information to process your payment. When you participate in an event, we may also save your information to facilitate communication
            about that event.
          </li>
          <li>
            To contact you and to inform you about products, services, promotions, special offers, and/or events that may interest you, to
            which you have the option to opt-out of such contact.
          </li>
          <li>
            To inform you about products and services you have purchased from us such as providing you updates and information about events
            that you have registered for or participated in.
          </li>To provide, support, personalize, and develop our website, products, and services.
          <li>To create, maintain, customize, and secure your account with us.
          </li>
          <li>
            To process your requests, purchases, transactions, and payments and prevent transactional fraud.
          </li>
            To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor
            and improve our responses.
          <li>
            To personalize your App or Website experience and to deliver content and product and service offerings relevant to your interests,
            including targeted offers and ads through our Website, App, third-party sites, and via email or text message (with your consent, where required by law).
          </li>
          <li>
            To help maintain the safety, security, and integrity of our App or Website, products and services, databases and other technology assets, and business.
          </li>
          <li>
            For research, analysis, and business development, including to develop and improve our website, products, and services.
          </li>
          <li>
            To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.
          </li>
          <li>
            As described to you when collecting your personal information or as otherwise set forth in the CCPA or subsequently agreed to by you.
          </li>
          <li>
            To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about
            our Consumers is among the assets transferred.
          </li>
        </ul>
        <h3>How We Share Personal Information</h3>
        <p>
          We may disclose your Personal Information to a third party for a business purpose, including to our service provider partners in order to
          conduct the normal function of event production such as registration, timing, scoring, or results display.
        </p>
        <p>We share your personal information with the following categories of third parties:
        </p>
        <ul>
          <li>Service providers.
          </li>
          <li>Third parties with whom you direct us to share your personal information, for example when you participate in an athletic event such as a race,
            you intentionally interact with certain third parties associated with that event to whom you direct us to share your personal information.
          </li>
        </ul>
        <h3><i>Disclosures of Personal Information for a Business Purpose</i></h3>
        <p>
          In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:
        </p>
        <table style={styles.tableCCPA} cellSpacing='0' cellPadding='0'>
          <tbody>
            <tr>
              <td style={styles.tableCCPA.cat}>Category A:</td>
              <td style={styles.tableCCPA.def}>Identifiers.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category B:</td>
              <td style={styles.tableCCPA.cat}>California Customer Records personal information categories.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category C:</td>
              <td style={styles.tableCCPA.cat}>Protected classification characteristics under California or federal law.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category D:</td>
              <td style={styles.tableCCPA.cat}>Commercial information.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category F:</td>
              <td style={styles.tableCCPA.cat}>Internet or other similar network activity.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category G:</td>
              <td style={styles.tableCCPA.cat}>Geolocation data.</td>
            </tr>
            <tr>
              <td style={styles.tableCCPA.cat}>Category K:</td>
              <td style={styles.tableCCPA.cat}>Inferences drawn from other personal information.</td>
            </tr>
          </tbody>
        </table>
        <p>
          We disclose your personal information for a business purpose to the following categories of third parties:
        </p>
        <ul>
          <li>Service providers.
          </li>
          <li>Third parties with whom you direct us to share your personal information, for example when you participate in an athletic event such as a race,
            you intentionally interact with certain third parties associated with that event to whom you direct us to share your personal information.
          </li>
        </ul>
        <h3>Sales of Personal Information</h3>
        <p>
          In the preceding twelve (12) months, we have not sold personal information.
        </p>
        <h3>Your Consumer Rights and Choices</h3>
        <p>
          The CCPA provides Consumers with specific rights regarding their Personal Information. This section describes
          your CCPA rights and explains how to exercise those rights
        </p>
        <h3><i>Access to Specific Information and Data Portability Rights</i></h3>
        <p>
        You have the right to request that we delete any of your Personal Information that we
        collected from you and retained, subject to certain exceptions. Once we receive and
        confirm your verifiable consumer request (see <a href='#eadpdr'>Exercising Access, Data Portability,
        and Deletion Rights</a>), we will delete (and direct our service providers to delete)
        your Personal Information from our records, unless an exception applies.
        In accordance with the CCPA, we may deny your deletion request under certain circumstances,
        and will inform you of the basis for the denial, which may include, but is not limited to,
        if retaining the information is necessary for us or our service provider(s) to:
        </p>
        <ul>
          <li>The categories of Personal Information we collected about you.
          </li>
          <li>The categories of sources for the Personal Information we collected about you.
          </li>
          <li>Our business or commercial purpose for collecting that Personal Information.
          </li>
          <li>The categories of third parties with whom we share that Personal Information.
          </li>
          <li>The specific pieces of Personal Information we collected about you.
          </li>
          <li>If we disclosed your Personal Information for a business purpose, a list
            identifying the Personal Information categories that each category of recipient obtained.
          </li>
        </ul>
        <h3><i>Deletion Request Rights</i></h3>
        <p>
          You have the right to request that we delete any of your Personal Information that we
          collected from you and retained, subject to certain exceptions. Once we receive and confirm
          your verifiable consumer request (see <a href='#eadpdr'>Exercising Access, Data Portability, and Deletion Rights</a>),
          we will delete (and direct our service providers to delete) your Personal Information from our
          records, unless an exception applies. In accordance with the CCPA, we may deny your deletion
          request under certain circumstances, and will inform you of the basis for the denial, which may
          include, but is not limited to, if retaining the information is necessary for us or our service provider(s) to:
        </p>
        <ul>
          <li>Complete the transaction for which we collected the Personal Information, provide
            a good or service that you requested, take actions reasonably anticipated within the context
            of our ongoing business relationship with you, or otherwise perform our contract with you.
          </li>
          <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity,
            or prosecute those responsible for such activities.
          </li>
          <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.
          </li>
          <li>Comply with a legal obligation.
          </li>
          <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.
          </li>
        </ul>
        <br id='eadpdr'/>
        <h3><i>Exercising Access, Data Portability, and Deletion Rights</i></h3>
        <p>
          To exercise the access, data portability, and deletion rights described above, please submit a
          verifiable consumer request to us by either:
        </p>
        <ul>
          <li>Calling us at <b>720-931-5552</b>
          </li>
          <li>Emailing us at <a href="mailto:privacy@athlinks.com">privacy@athlinks.com </a>
          </li>
        </ul>
        <p>
          Only you, or a person registered with the California Secretary of State that you authorize
          to act on your behalf, may make a verifiable consumer request related to your Personal Information.
          You may also make a verifiable consumer request on behalf of your minor child.
        </p>
        <p>
          You may only make a verifiable consumer request for access or data portability twice within a
          12-month period. The verifiable consumer request must:
        </p>
        <ul>
          <li>Provide sufficient information that allows us to reasonably verify you are the person about whom
            we collected Personal Information or an authorized representative.
          </li>
          <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
          </li>
        </ul>
        <p>
          We cannot respond to your request or provide you with Personal Information if we cannot verify your identity
          or authority to make the request and confirm the Personal Information relates to you. Making a verifiable consumer
          request does not require you to create an account with us. However, in order to facilitate the secure the electronic
          delivery of any Personal Information you may request to access, you may be asked to create an account.
        </p>
        <p>
          We will only use Personal Information provided in a verifiable consumer request to verify the requestor's
          identity or authority to make the request.
        </p>
        <h3><i>Response Timing and Format</i></h3>
        <p>
        We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt.
        If we require more time (up to an additional 45 days), we will inform you of the reason and extension period in writing.
        </p>
        <p>
          If you have an account with us, we will deliver our written response to that account or an account you create specifically
          to facilitate the secure delivery of electronic information. If you do not have an account with us, or you opt out of
          electronic access, we will deliver our written response by mail at your option.
        </p>
        <p>
          Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt.
          The response we provide will also explain the reasons we cannot comply with a request, if applicable.
        </p>
        <p>
          If we determine that the request warrants a fee, we will tell you why we made that decision and provide
          you with a cost estimate before completing your request.
        </p>
        <h3>Other California Privacy Rights</h3>
        <p>
          California's “Shine the Light” law (Civil Code Section § 1798.83) permits users of our website that are California
          residents to request certain information regarding our disclosure of Personal Information to third parties for
          their direct marketing purposes. We, however, do not disclose your Personal Information to third parties for their
          direct marketing purposes.
        </p>
        <h3>Changes to Our CCPA Privacy Notice</h3>
        <p>
          We reserve the right to amend this privacy notice at our discretion and at any time. When we make changes to this
          privacy notice, we will post the updated notice on the App and Website and update the notice's Effective Date above.
          <b>Your continued use of our App or Website or interaction with us through other methods following the posting of changes
          and/or our directing you to the updated CCPA Privacy Notice constitutes your acceptance of such changes.</b>
        </p>
        <h3>Contact Information</h3>
        <p>
          If you have any questions or comments about this notice, the ways in which we collect and use your information described
          above, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not
          hesitate to contact us at:
        </p>
        <p><b>Phone: 720-931-5552</b></p>
        <p><b>Email: <a href="mailto:privacy@athlinks.com">privacy@athlinks.com </a></b></p>
        <p><b>Postal Address:</b></p>
        <p><b>Athlinks Inc. and Chronotrack Systems Corp.</b><br/>2902 Corporate Place<br/>Chanhassen, MN 55317</p>
      </div>
    </div>
  );
};
