import {streamHook, eventBus} from './Streams';
import _ from 'lodash';

const NOTIFICATION_STREAM = 'notification-stream';
const LAST_NOTIFICATION_STREAM = 'last-notification-stream';
const ACTION_STREAM = 'snackbar-action-stream';

// a stream of an array of notification objects
export function getNotifications() {
  return streamHook(NOTIFICATION_STREAM, []);
}

// add a single notification
export function setNotifications(notifications) {
  eventBus.publish(NOTIFICATION_STREAM, notifications);
  if(notifications.length > 0) {
    setLastNotification(_.head(notifications));
  }
}

// a stream of the last notifications
export function getLastNotification() {
  return streamHook(LAST_NOTIFICATION_STREAM, {});
}

// add the last notification
export function setLastNotification(notification) {
  eventBus.publish(LAST_NOTIFICATION_STREAM, notification);
}

// a stream of an array of selected actions from the snackbar
export function getActions() {
  return streamHook(ACTION_STREAM, []);
}

export function setActions(actions) {
  eventBus.publish(ACTION_STREAM, actions);
}

// notification types
export const ERROR = 'error-notification';
export const WARNING = 'warning-notification';
export const SUCCESS = 'success-notification';

// snackbar actions
export const RELOAD = 'reload';
export const CANCEL = 'cancel';
export const CONTINUE = 'continue';
