import React from 'react';
import PropTypes from 'prop-types';
import { homeText } from './styles';

const HomeSectionTitle = (props) => {

	const {
	  id,
		title,
		titleStyle,
		subtitle,
		subtitleStyle
	} = props;

	const textStyles = homeText(props);
	const newTitleStyle = Object.assign({}, textStyles.homeSectionTitle, titleStyle);
	const newSubtitleStyle = Object.assign({}, textStyles.homeSectionHead, subtitleStyle);

	return (
		<div>
		{ title ?
			<div id={`${id}-title`} style={ newTitleStyle }>{ title }</div>
			: null
		}
		{ subtitle ?
      <h3 id={`${id}-subtitle`} style={ newSubtitleStyle }>{ subtitle }</h3>
      : null
    }
		</div>
	)
};

HomeSectionTitle.defaultProps = {
	title: '',
	titleStyle: {},
	subTitle: '',
	subtitleStyle: {},
};

HomeSectionTitle.propTypes = {
	title: PropTypes.string,
	titleStyle: PropTypes.object,
	subTitle: PropTypes.string,
	subtitleStyle: PropTypes.object,
};

export default HomeSectionTitle;
