import { Configure, FetchApi } from '../../anuket-http';
import { map } from 'rxjs/operators';

export function getRaceTimesByCTLiveId(eventId) {
  if (!eventId) {
    return null
  }
  const url = `${window.location.origin}/ctl-api/event/${eventId}/race`;

  return FetchApi.memoizeFetch(10000)(url).pipe(
    map((msg) => {
      if (msg.event_race) {
        return {
          races: msg.event_race.map((race) => {
            return {
              eventId: parseInt(race.event_id),
              id: parseInt(race.race_id),
              name: race.race_name,
              plannedEndTime: parseInt(race.race_planned_end_time),
              plannedStartTime: parseInt(race.race_planned_start_time),
              subType: race.race_subtype,
              type: race.race_type
            }
          }).sort((a, b) => {
            return a.plannedStartTime < b.plannedStartTime ? -1
              : a.plannedStartTime > b.plannedStartTime ? 1
              : 0;
          })
        }
      }
      else {
        return {
          races: []
        }
      }
    })
  );
}

export function getAthlinksCourses(athlinksId) {
  const url = `${Configure.getValue('ATHLINKS_API_HOST')}/Events/Api/${athlinksId}/Courses`;

  return FetchApi.memoizeFetch(10000)(url).pipe(
    map((msg) => {
      if (msg.result) {
        return {
          races: msg.result.courses.map((course) => {
            return {raceName: course.name}
          }).sort((a, b) => {
            return a.raceName < b.raceName ? -1
              : a.raceName > b.raceName ? 1
              : 0;
          })
        }
      }
      else {
        return {races: []}
      }
    })
  );
}

export function extractCourses(msg) {
  if (msg.success && msg.result && msg.result.eventRaces) {
    const eventCourses = msg.result.eventRaces.map((race) => {
      return race.eventCourses.map((course) => {
        return {
          courseId: course.courseID,
          eventCourseId: course.eventCourseID,
          eventId: race.raceID,
          eventName: race.raceName,
          masterId: race.masterEventID,
          meters: course.distUnit,
          raceName: course.courseName,
          startEpoch: (new Date(race.raceDate).getTime() / 1000)
        }
      })
    });
    return {
      races: [].concat.apply([], eventCourses)
    }
  }
  else { return {races: []} }
}
