import { areObjectsDifferent } from './areObjectsDifferent'
import { logInfo, logWarning } from './Logging'
import { getArpWSClient } from './getArpWSClient'

export const asyncWSRequest = (
  args,
  getWSClient = getArpWSClient,
  tokenRequired = true
) => (
  fieldName,
  updatePending,
  action
) => async (
  newToken,
  callback,
  ...theRest
) => {
  if (tokenRequired && !newToken) {
    logWarning('Token required for call to update', fieldName)
    return {
      success: false,
      authenticationRequired: true
    }
  }
  const {
    arp,
    entryId,
    setARP
  } = args
  const isTargetUpdated = areObjectsDifferent(arp, fieldName)

  const arpUpdate = (
    newArp
  ) => {
    if (isTargetUpdated(newArp)) {
      setARP(
        updatePending(newArp, false)
      )
      if (callback) {
        callback()
      }
      return true
    }
    return false
  }

  const initialUpdate = () => {
    logWarning('Setting to pending')
    setARP(
      updatePending(arp, true)
    )
  }
  logInfo('Adding update to perhaps new WS connection')
  getWSClient({
    entryId,
    setARP: arpUpdate,
    handleOpen: initialUpdate,
    token: newToken
  }, true)
  logInfo('Firing the action', action, entryId, ...theRest)
  return await action(arp, newToken, ...theRest)
}