import React from 'react';
import PropTypes from 'prop-types';
import {Header} from './Header';
import {Results} from './Results';
import {Rivals} from './Rivals';
import {Followers} from './Followers';
import {styles, riStyles} from './styles';
import {AthleteOverview} from './AthleteOverview';
import {GetStarted} from '../shared/GetStartedBanner';
import {Loading} from '../shared/Loading';
import {Statistics} from './Statistics';
import {Overview} from './Overview';
import {UnclaimedResultsAlert} from './UnclaimedResultsAlert';

export const Athlete = (
  {
    athleteId,
    selectedRival,
    athlete: {fetching, athlete = {}},
    summary,
    calendar,
    friendsUpcoming,
    races,
    suggested,
    following,
    rivals,
    friends,
    fetchAthlete,
    setAthletePhoto,
    fetchAthleteCalendar,
    fetchAthleteFollowing,
    hasLiveBanner,
    liveEvent,
    isMe,
    isFriend,
    isLoggedIn,
    isMobile,
    windowWidth,
    tab,
    unclaimedResultsCount,
    pendingClaims,
    t
  }) => {

  const selectedRivalId = !!selectedRival ? parseInt(selectedRival) : null;
  const athleteIdNum = parseInt(athleteId);
  const athleteRelatedProps = {
    following,
    followers: following,
    rivals
  };

  let marginTop = isMobile ? 70 : hasLiveBanner ? 120 : 75;

  if(!athlete || !(athlete && Object.keys(athlete).length)) {
    return <div style={{
                  width: '100%',
                  height: 70,
                  marginTop: 100
                }}><Loading /></div>
  }

  const {DisplayName} = athlete;

  const renderUnclaimedResultsAlert = () => {
    if(!['overview', 'results'].includes(tab)) return null;
    else if(!unclaimedResultsCount) return null;
    else return (
      <div
        className={isMobile ? 'row px-0' : 'row mx-0'}
        style={{
          marginBottom: 28,
        }}>
        <div
          className='col-12'
          style={{
            ...riStyles.resPage(isMobile),
            padding: 20,
          }}>
          <UnclaimedResultsAlert
            searchTerm={DisplayName}
            unclaimedResultsCount={unclaimedResultsCount}
            birthYear={new Date().getFullYear() - athlete.Age}
            isMobile={isMobile}
            t={t}
          />
        </div>
      </div>
    );
  };
  // console.log(`Current tab: ${tab}`)
  return (
    <div
      style={styles.athleteMainContainer(marginTop)}>
      <div
        style={styles.overviewContainer}>
        <AthleteOverview
          athleteId={athleteId}
          athlete={athlete}
          fetching={fetching}
          summary={summary}
          isMe={isMe}
          isFriend={isFriend}
          isLoggedIn={isLoggedIn}
          windowWidth={windowWidth}
          fetchAthlete={fetchAthlete}
          setAthletePhoto={setAthletePhoto}
          isMobile={isMobile}
          t={t}
        />
        <Header
          active={'overview'}
          athleteId={athleteId}
          isMe={isMe}
          athlete={athlete}
          isMobile={isMobile}
          isFriend={isFriend}
          windowWidth={windowWidth}
          fetchAthlete={fetchAthlete}
          setAthletePhoto={setAthletePhoto}
          isLoggedIn={isLoggedIn}
          unclaimedResultsCount={unclaimedResultsCount}
        />
      </div>
      <div style={{padding: isMobile ? '12px' : '', paddingTop: isMobile ? '20px' : ''}}>
        <div className='container' style={{paddingTop: isMobile ? '' : '20px'}}>
            {renderUnclaimedResultsAlert()}
            {
              tab === 'overview' &&
              <Overview
                athleteRelatedProps={athleteRelatedProps}
                athleteId={athleteId}
                hasLiveBanner={hasLiveBanner}
                athlete={athlete}
                liveEvent={liveEvent}
                races={races}
                calendar={calendar}
                friendsUpcoming={friendsUpcoming}
                fetchAthleteCalendar={fetchAthleteCalendar}
                suggested={suggested}
                isMobile={isMobile}
                pendingClaims={pendingClaims}
                t={t}
              />
            }
            {
              tab === 'results' &&
              <Results
                athleteId={athleteId}
                athlete={athlete}
                races={races.races}
                fetching={races.fetching}
                isMobile={isMobile}
                isMe={isMe}
              />
            }
            {
              (tab === 'followers' || tab === 'following') &&
              <Followers
                athleteId={athleteId}
                athlete={athlete}
                followers={[]}
                following={following.result}
                fetching={following.fetching}
                friends={friends}
                type={tab}
                isMe={isMe}
                {...suggested}
                fetchAthleteFollowing={fetchAthleteFollowing}
                isMobile={isMobile}
                isFriend={isFriend}
                windowWidth={windowWidth}
                fetchAthlete={fetchAthlete}
                setAthletePhoto={setAthletePhoto}
                isLoggedIn={isLoggedIn}
              />
            }
            {
              tab === 'rivals' &&
              <Rivals
                athleteId={isNaN(athleteIdNum) ? athleteId : athleteIdNum}
                athlete={athlete}
                rivals={rivals.rivals}
                fetching={rivals.fetching}
                selectedRivalId={selectedRivalId}
                suggested={suggested}
                isMobile={isMobile}
                isMe={isMe}
              />
            }
            {
              tab === 'statistics' &&
              <Statistics
                {...summary.summary}
                fetching={summary.fetching}
                athleteId={athleteId}
                suggested={suggested}
                isMobile={isMobile}
                t={t}
              />
            }
            {
              !isLoggedIn && !isMobile &&
              <GetStarted t={t} />
            }
        </div>
      </div>
    </div>
  );
};

Athlete.propTypes = {
  athleteId: PropTypes.string.isRequired,
  selectedRival: PropTypes.string,
  tab: PropTypes.string,
  athlete: PropTypes.object,
  summary: PropTypes.object,
  calendar: PropTypes.object,
  friendsUpcoming: PropTypes.object,
  races: PropTypes.object,
  rivals: PropTypes.object,
  suggested: PropTypes.object,
  friends: PropTypes.array,
  liveEvent: PropTypes.array,
  fetchAthlete: PropTypes.func.isRequired,
  setAthletePhoto: PropTypes.func.isRequired,
  fetchAthleteRivals: PropTypes.func.isRequired,
  fetchVs: PropTypes.func.isRequired,
  fetchVsDetails: PropTypes.func.isRequired,
  fetchAthleteCalendar: PropTypes.func.isRequired,
  isMe: PropTypes.bool.isRequired,
  isFriend: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  windowWidth: PropTypes.number,
  unclaimedResultsCount: PropTypes.number,
  t: PropTypes.func
};

Athlete.defaultProps = {
  athlete: {
    fetching: true,
    athlete: {
      DisplayName: '',
      RacerID: '',
      LogoUrls: '',
      City: '',
      StateProvAbbrev: '',
      CountryId3: '',
    },
    unclaimedResultsCount: 0
  }
};
