import {withTranslation} from 'react-i18next';
import React, { Component } from 'react';
import Lottie from 'lottie-react-web';
import deepEqual from 'fast-deep-equal'
import PropTypes from 'prop-types';
import {colors} from '../../shared/styles';
import LightDarkBubble from '../../json/bubble_lightdark.json'
import {styles} from './styles';
import {
  getTimeLabel,
} from '../../utils/IRPUtil';


/**
 * Renders BracketBubble
 * @param {json} animation
 * @param {number} width
 * @param {number} startSegment
 * @param {number} endSegment
 * @return {*}
 * @constructor
 */

class BracketBubble extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return !deepEqual(nextProps, this.props) ||
      !deepEqual(nextState, this.state)
  }

  render() {
    const { animation, width, startSegment, endSegment } = this.props;
    return(
      <Lottie
        options={{
          animationData: animation,
          loop: false,
          autoplay: true
        }}
        width={width}
        forceSegments={true}
        segments={[Number(startSegment), Number(endSegment)]}
      />
    )
  }
}

/**
 * Renders single bracket data
 * @param {string} label - Overall, gender, ...
 * @param {number} value
 * @param {number} count
 * @param {boolean} skipFormat
 * @param {*} isLightMode
 * @param {*} t
 * @return {*}
 * @constructor
 */

class BracketRowComponent extends Component {

  state = {
    startSegment: 0,
    endSegment: 0,
  };

  calculateEndSegment(value, count) {
    const endValue = Number((100 - ((100 * value) / count)).toFixed(1));
    if(value === 1) {
      return 100;
    }
    /*
      If endSegment is < 1 but not 0 -> [0, 0.78] Lottie won't display at all
      So setting it to minimal value of 2 for displaying minimal count as in mobile app
    */
    if(endValue < 1 && endValue !== 0) {
      return 2;
    }
    /*
      When direction is set to 1,
      [0,0] Lottie will render full animation, so setting it
      to 1 to get needed result
    */
    if(endValue === 0) {
      return 1;
    }
    return endValue;
  }

  componentDidMount() {
    const { value, count } = this.props;
    const endSegment = this.calculateEndSegment(value, count);
    /*
      Doing this because Lottie will initially render full animation
      even when default segments are passed
      and then calling setState to update segments will trigger animation
    */
    this.setState({ endSegment })
  }

  render() {
    const { startSegment, endSegment } = this.state;
    const { value, count, skipFormat, label, isLightMode} = this.props;
    return(
      <div style={styles.timePaceWrap} >
        <div style={{ position: 'relative' }}>
          <BracketBubble
            animation={LightDarkBubble}
            width={130}
            startSegment={startSegment}
            endSegment={endSegment}
          />
          <div style={styles.paceRankWrap}>
            <span style={styles.timePaceRank(isLightMode)}>{value.toLocaleString() || '--'}</span>
            <span style={styles.timePaceRankTotal(isLightMode)}>{count.toLocaleString()}</span>
          </div>
      </div>
      <h4 style={styles.timePaceHeader(isLightMode, true)}>
       {skipFormat
         ? <span>{label.toUpperCase()}</span>
         : <span>{label.toLowerCase()}</span>
          }
      </h4>
      </div>
    )
  }
}

const BracketRow = withTranslation()(BracketRowComponent);


BracketRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
};

/**
 * Renders overall, gender and age bracket info
 * @param {*} brackets
 * @param {*} isLightMode
 * @param {boolean} isMobile
 * @param {*} t
 * @return {*}
 * @constructor
 */
const BracketsInfo = ({brackets, isLightMode, isMobile, t}) => {

  const getBracketCount = (bracket) => {
    const { bracketType, totalInBracket, totalAthletes } = bracket;
    if (bracketType === 'OPEN'){
      return totalInBracket && totalInBracket > totalAthletes ? totalInBracket : totalAthletes
    }
    return totalInBracket && totalInBracket < totalAthletes ? totalInBracket : totalAthletes
  }

  return (
    <div style={styles.timePaceCenterContent}>
      {
        brackets.map((bracket, index) => {
        if(index === 6) return null;
        return(
          bracket.rank &&
            <div
              style={styles.bracketRowWrap(isMobile)}
              key={bracket.bracketName}
            >
            <BracketRow
              isLightMode={isLightMode}
              label={bracket.bracketName}
              value={bracket.rank}
              count={getBracketCount(bracket)}
              t={t}/>
           </div>
          )
        })
      }
    </div>
  )
};

BracketsInfo.propTypes = {
  overall: PropTypes.object,
  gender: PropTypes.object,
  age: PropTypes.object,
};

/**
 * Finish time, pace data and ranking data renderer
 * @param {string} finishTime
 * @param {string} pace
 * @param {string} paceUnit
 * @param {*} ranks
 * @param {Result} result
 * @param {string} latestIntervalName
 * @param {boolean} isLightMode
 * @param {boolean} isMobile
 * @param {boolean} isGunTime
 * @param {boolean} hasPace
 * @param {*} t
 * @return {*}
 * @constructor
 */
export const KioskTimePace = (
  {
    finishTime,
    hasPace,
    isLightMode,
    isGunTime,
    isMobile,
    pace,
    paceUnit,
    ranks,
    result,
    latestIntervalName,
    t
  }) => (
  <div>
    <div className='row' style={styles.timePaceCenterContent}>
      <div className='col-sm-12 col-md-8 col-lg-7'>
        {
          !result.isFinisher &&
          <div style={{...styles.notFinisher(isLightMode), textAlign: 'center'}}>
            <NotFinisher
              status={result.entryStatus}
              t={t}
            />
          </div>
        }
        {
          result.isFinisher &&
          <div className='row mb-4'>
            <div className={`${hasPace ? 'col-7' : 'col-md-12'}`}
                 style={styles.timePaceItemContainer}>
              <h4 style={styles.timePaceHeader(isLightMode)}>
                {`${getTimeLabel(result, isGunTime, t)} ${latestIntervalName}`}
              </h4>
              <div style={styles.timePaceNumbers(isMobile, isLightMode)}>
                {finishTime}
              </div>
            </div>
            {
              hasPace && <div className='col-5'
                              style={styles.timePaceItemContainer}>
                <h4 style={styles.timePaceHeader(isLightMode)}>
                  {t('Pace {{unit}}', { unit: paceUnit })}
                </h4>
                <div style={styles.timePaceNumbers(isMobile, isLightMode)}>
                  {pace}
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
    {
      result.isFinisher &&
      <div className='row' style={styles.timePaceCenterContent}>
        <div
          className="col-12"
          style={{ marginTop: -16, padding: 0 }}>
          <BracketsInfo
            isLightMode={isLightMode}
            brackets={ranks}
            isMobile={isMobile}
            t={t}
          />
        </div>
      </div>
    }
  </div>
);

KioskTimePace.propTypes = {
  finishTime: PropTypes.string.isRequired,
  pace: PropTypes.string.isRequired,
  paceUnit: PropTypes.string.isRequired,
  ranks: PropTypes.array.isRequired,
  t: PropTypes.func
};

export const NotFinisher = ({ status, t }) => {
  switch (status) {
    case 'DNF':
      return <span>{t('did not finish')}</span>;
    case 'DQ':
    default:
      return <span>{t('disqualified')}</span>;
  }
};

/**
 * Loader rectangle helper
 * @param {string|number} width
 * @param {string|number} height
 * @return {*}
 */
const loader = (width, height, isLightMode = true) => (
  <div>
    <span
      style={{
        width,
        height,
        backgroundColor: isLightMode ? '#f4f4f4' : colors.darkGrey4,
        display: 'inline-block',
      }}/>
  </div>
);

/**
 * Loader component for KioskTimePace component
 * @return {*}
 * @constructor
 */
export const KioskTimePaceLoader = ({ isLightMode }) => (
  <div>
    <div className='row' style={styles.timePaceCenterContent}>
      <div className='col-sm-12 col-md-8 col-lg-7'>
        <div className='row'>
          <div className='col-sm-12 col-md-7' style={styles.timePaceItemContainer}>
            <h4 style={styles.timePaceHeader(isLightMode)}>
              {loader('50%', 20, isLightMode)}
            </h4>
            <div>
              {loader('40%', 35, isLightMode)}
            </div>
          </div>
          <div className='col-sm-12 col-md-5' style={styles.timePaceItemContainer}>
            <h4 style={styles.timePaceHeader(isLightMode)}>
              {loader('50%', 20, isLightMode)}
            </h4>
            <div>
              {loader('40%', 30, isLightMode)}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='row' style={styles.timePaceCenterContent}>
      <div className="col-8" style={{ marginTop: 50 }}>
        <div className='row'>
          {
            // brackets loader
            Array(3).fill(1).map((_, i) => (
              <div className='col-sm-12 col-md-4' key={i} style={styles.timePaceItemContainer}>
                <div>
                  <h4 style={styles.timePaceHeader(isLightMode)}>
                    {loader('50%', 20, isLightMode)}
                  </h4>
                  <div>
                    {loader(30, 30, isLightMode)} {loader(20, 20, isLightMode)}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  </div>
);
