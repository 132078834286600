import React from 'react';
import { Link } from 'react-router-dom';
import { styles } from './styles';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { AthletePhoto } from '../athlete/AthletePhoto';

export const AthletesComponent = (props) => {
  let slice = 3;
  if(props.slice) {
    slice = props.slice;
  }
  const {
    searchTerm,
    athletes,
    locationTerm,
    t
  } = props;
  let shownAthletes = athletes.slice(0, slice);
  const searchQuery = searchTerm
    ? locationTerm
      ? `?locationTerm=${locationTerm}&term=${encodeURIComponent(searchTerm)}`
      : `?category=athletes&term=${encodeURIComponent(searchTerm)}`
    : locationTerm
      ? `?locationTerm=${locationTerm}`
      : '';
  const searchAllAthletesLink = `/search/athletes/${searchQuery}`;

  return(
    <div>
      <Link to={searchAllAthletesLink} className="athletes-component">
        <div style={styles.topLabel}>
          <span style={styles.spanSectionLabel}>{t('Athletes')}</span>
        </div>
      </Link>

      <div style={styles.resultContent}>
        {
          shownAthletes.map(({ racerId, displayName, avatarUrl, city, stateProv, age, gender}) => (
            <div
              style={styles.resultRow(false)}
              key={racerId}
              onClick={() => trackGoogleEvent('search', 'Athlete Chosen', displayName)}
            >
              <Link to={`/athletes/${racerId}?category=global&term=${searchTerm}`} className="profile-page-link">

                <div style={styles.athleteImg}>
                  <AthletePhoto
                    width={40}
                    isMember={true}
                    size={'small'}
                    name={displayName}
                    photo={{
                      HasImage: !!avatarUrl,
                      small: avatarUrl
                    }}
                  />
                </div>

                <div style={styles.resultName}>{displayName}</div>
                <div style={styles.resultDetails}>
                  <span style={styles.pipeLeft}>{t('{{city}}, {{stateProv}}', {city: city.trim(), stateProv: stateProv.trim()})}</span>
                  |
                  <span style={styles.pipeRight}>{t('{{age}}{{gender}}', {age, gender: gender.toUpperCase()})}</span>
                </div>

              </Link>
            </div>
          ))
        }

        <Link to={searchAllAthletesLink}>
          {
            <span style={styles.moreLink}>
              {t('See all athletes for "{{searchTerm}}"', {searchTerm})}
            </span>
          }
        </Link>

      </div>
    </div>
  );
};
