import convertPaceUnitsToPaceType from './convertPaceUnitsToPaceType';
import convertPaceUnitsToTimeUnits from './convertPaceUnitsToTimeUnits';
import convertTimeUnitsToOldTimeUnit from './convertTimeUnitsToOldTimeUnit';
import { DivisionResultMetadata, IntervalResultMetadata } from '../types.results.reignite';
import convertPaceUnitsToDistanceUnits from './convertPaceUnitsToDistanceUnits';

export default function transformReigniteEntryIntervalPace({
  chipTimeInMillis,
  division,
  gunTimeInMillis,
  interval,
}: {
  chipTimeInMillis: number;
  division: DivisionResultMetadata;
  gunTimeInMillis: number;
  interval: IntervalResultMetadata;
}) {
  if (interval.paceUnits === 'none') {
    return undefined
  }
  return {
    distance: {
      distanceInMeters: interval.distance.meters,
      distanceUnit: convertPaceUnitsToDistanceUnits(interval.paceUnits),
    },
    paceType: convertPaceUnitsToPaceType(interval.paceUnits),
    time: {
      timeInMillis: division.rankType === 'chip'
        ? chipTimeInMillis
        : gunTimeInMillis,
      timeUnit: convertTimeUnitsToOldTimeUnit(
        convertPaceUnitsToTimeUnits(interval.paceUnits)
      ),
    },
  };
}
