import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {DialogModal} from '../modal/common/DialogModal';
import {Preview} from '../athlete/Preview';
import {LogInOrSignUp} from '../modal/LogInOrSignUp';
import {FollowStream} from '../../data/FollowStream';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {Loading} from './Loading';
import PlusIcon from 'react-icons/lib/md/add';
import CheckIcon from 'react-icons/lib/md/check';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import { trackGoogleEvent } from '../../utils/googleEvents';
import {colors, fonts} from '../../shared/styles';
import {Button} from '../shared/Elements';

const style = {
  button: (isFriend) => ({
    position: 'relative',
    display: 'inline-block',
    boxShadow: 'none',

    padding: '5px 28px 2px 43px',
    ...fonts.heavy,
    textAlign: 'center',
    fontSize: 13,
    color: (isFriend) ? colors.blue : '#fff',
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    backgroundColor: (isFriend) ? 'transparent' : colors.blue,
    borderRadius: 2,
    border: '2px solid '+ colors.blue,
    cursor: 'pointer',
  }),

  followIcon: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    display: 'inline-block',
    height: 16,
    width: 16,
  },

  loadingContainer: (isRoundedBtn) => ({
    display: 'inline-block',
    height: (isRoundedBtn) ? 34 : 29,
    width: (isRoundedBtn) ? 46 : 130,
    paddingTop: (isRoundedBtn) ? 4 : 3,
    border: (isRoundedBtn) ? '2px solid '+ colors.blue : 'none',
    borderRadius: '3px',
    textAlign: 'center',
    opacity: 0.8,
  }),
  loadingPosition: () => ({
    display: 'inline-block',
    height: 24,
  }),

  buttonContainer: {
    display: 'inline-block',
    cursor: 'pointer'
  },

  transitionBase: {
    transition: 'all 0.3s',
  },
  tinyIcon: (showing) => ({
    opacity: (showing) ? 1 : 0,
    transition: 'all 0.3s',
  }),
};


class IconButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  render() {
    const {
      isFollowing,
    } = this.props;

    const {
      hover,
    } = this.state;

    const borderColor = (hover && !isFollowing) ? colors.darkBlue : colors.blue;
    const backgroundColor = (isFollowing && !hover) ? '#fff' : (hover && !isFollowing) ? colors.darkBlue : colors.blue;
    const contentColor = ( isFollowing && !hover ) ? colors.blue : '#fff';

    return (
      <svg
        width="48" height="36" viewBox="0 0 48 36"
        onMouseEnter={() => this.setState({hover: true})}
        onMouseLeave={() => this.setState({hover: false})}
      >
        <g fill="none" fillRule="evenodd">
          {
            // Box
          }
          <rect fill={backgroundColor} stroke={borderColor} strokeWidth="2" x="1" y="1" width="46" height="34" rx="3" style={style.transitionBase}/>

          {
            // Little dude
          }
          <g>
            <path d="M26.25 18c2.07 0 3.75-1.68 3.75-3.75 0-2.07-1.68-3.75-3.75-3.75-2.07 0-3.75 1.68-3.75 3.75 0 2.07 1.68 3.75 3.75 3.75z" fill={contentColor} style={style.transitionBase}/>
            <path d="M26.25 19.88c-2.5 0-7.5 1.25-7.5 3.75v1.87h15v-1.87c0-2.5-5-3.75-7.5-3.75z" fill={contentColor} style={style.transitionBase}/>
          </g>

          {
            // Plus sign
          }
          <path d="M17.44 16.13V13.3h-1.88v2.83h-2.8V18h2.8v2.8h1.88V18h2.8v-1.87" fill={contentColor} style={style.tinyIcon(!isFollowing)} />

          {
            // Check mark
          }
          <path stroke={contentColor} style={style.tinyIcon(isFollowing && !hover)} strokeWidth="2.5" d="M11.7 17.4l2.33 2.4 5.32-5.4"/>

          {
            // Minus
          }
          <path d="M11.44 16.5h6.12" stroke={contentColor} style={style.tinyIcon(isFollowing && hover)} strokeWidth="2" strokeLinecap="square"/>
        </g>
      </svg>
    )
  }
}



class FollowComponent extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    isFriend: PropTypes.bool.isRequired,
    RacerId: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    DisplayName: PropTypes.string.isRequired,
    Photos: PropTypes.object.isRequired,
    City: PropTypes.string.isRequired,
    StateProvAbbrev: PropTypes.string.isRequired,
    CountryId3: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
    buttonType: PropTypes.oneOf(['rounded', 'rectangle', 'circle']),
    t: PropTypes.func
  };

  static defaultProps = {
    onChange: () => {},
    buttonType: 'rounded'
  };

  state = {
    open: false,
    processing: false,
  };

  UNSAFE_componentWillReceiveProps({isFriend}) {
    if(this.props.isFriend !== isFriend) {
      this.setState({processing: false}, this.onChange);
    }
  }

  onClick = () => {
    const {isLoggedIn, isFriend} = this.props;
    if(!isLoggedIn) this.props.modal.handleTouchTap();
    else if(isFriend) this.setState({open: true});
    else this.changeFollowStatus();
  };

  onChange = () => this.setState({open: false},
    () => this.props.onChange(this.props.isFriend));

  changeFollowStatus = () => {
    const {isFriend, RacerId} = this.props;
    this.setState({processing: true},
      () => {
        trackGoogleEvent(
          'Social',
          'Click',
          isFriend ? 'Unfollow Athlete' : 'Follow Athlete');
        FollowStream.toggleFollowing(!isFriend, RacerId);
    })
  };

  rectangleButton = (isFriend) => {
    const {
      t
    } = this.props;

    return (
      <div style={style.button(isFriend)}>
        <span>
          {!isFriend ?
            <PlusIcon style={{...style.followIcon, left: 23}} size={17}/> :
            <CheckIcon style={{...style.followIcon, left: 23}} size={17}/>
          }
        </span>
        <span>
          {!isFriend ? t('Follow') : t('Following')}
        </span>
      </div>
    );
  };

  circleButton = (isFriend) => {
    return (
      <div style={{...style.button(isFriend), padding: 0, width: 21, height: 21, float: 'left', borderRadius: '50%'}}>
        <span>
          {!isFriend ?
            <PlusIcon size={17}/> :
            <CheckIcon style={{...style.followIcon, left: 0}} size={17}/>
          }
        </span>
      </div>
    );
  };

  button = (isFriend) => {
    const {processing} = this.state;
    const {
      buttonType,
      t
    } = this.props;

    if (processing) {
      return (
        <div style={style.loadingContainer(buttonType === 'rounded')}>
          <div style={style.loadingPosition(buttonType === 'rounded')} id="processing">
            <Loading timeoutMessage={t('Still thinking...')} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={style.buttonContainer}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            this.onClick();
          }}
          id='follow-button'
          className={`div-follow-button follow-button ${isFriend ? 'is-friend' : ''}`}
        >
          { buttonType === 'rounded' &&
            <IconButton isFollowing={isFriend} />
          }
          { buttonType === 'rectangle' &&
            this.rectangleButton(isFriend)
          }
          { buttonType === 'circle' &&
            this.circleButton(isFriend)
          }
        </div>
      );
    }
  };

  modalActions = () => {
    const {
      t
    } = this.props;

    const {processing} = this.state;

    if (processing) {
      return (<div style={{height:30}}>
        <Loading timeoutMessage={t('Still thinking...')}/>
      </div>);
    }
    else return (
      <div>
        <span
          onClick={() => this.setState({open: false})}
          style={{
            cursor: 'pointer',
            marginRight: 20,
            textTransform: 'uppercase',
            fontSize: 14,
            display: 'inline-block',
            color: '#4a4a4a',
            fontFamily: 'ProximaNovaSemibold'
          }}
          id={'unfollow-cancel'}>
          {t('cancel')}
        </span>
        <Button
          id='unfollow-button'
          type='blueAthlinksXL'
          label={t('unfollow')}
          primary={true}
          onClick={(e) => {this.changeFollowStatus(); e.preventDefault()}}
          style={{
            textTransform: 'uppercase',
            fontSize: 14,
            fontFamily: 'ProximaNovaSemibold'
          }}
        />
      </div>
    );
  };

  render() {
    const {
      isFriend,
      isLoggedIn,
      isMobile,
      DisplayName,
      Photos,
      City,
      StateProvAbbrev,
      CountryId3,
      RacerId,
      t
    } = this.props;

    const {open} = this.state;
    if(isLoggedIn && getTokenRacerId() === RacerId) return null;
    else if(open) return (
      <DialogModal
        paperStyle={{
          minWidth: '0px',
          width: '100vw'
        }}
        isMobile={isMobile}
        title={
          <div
            id='unfollow-modal-title'
            style={{
              fontSize : 25,
              fontFamily: 'ProximaNovaRegular',
              color: '#4A4A4A'
            }}>
            {t('Are you sure you want to unfollow {{athleteName}}?', {athleteName: DisplayName})}
          </div>
        }
        open={this.state.open}
        onRequestClose={() => this.setState({open: false})}
        body={
          <div style={{
            borderTop: '1px solid #ddd',
            borderBottom: '1px solid #ddd',
            padding: '10px 0'
          }}>
            <Preview
              isMobile={isMobile}
              name={DisplayName}
              photo={Photos}
              city={City}
              state={StateProvAbbrev}
              country={CountryId3}/>
          </div>
        }
        actions={this.modalActions()}
        actionsContainerStyle={{
          borderTop: 'none',
          padding: '55px 40px 35px 40px'
        }}
        bodyStyle={{marginLeft: '12px'}}
      />
    );
    else return this.button(isLoggedIn && isFriend)
  };
}

export const Follow = withTranslation()(connectStream({
  isFriend: FollowStream.followAthleteStream
})(FollowComponent));

export const FollowButton = ({isMe, ...props}) =>
  isMe ? null :
  <LogInOrSignUp
    control='follow'
    {...props}
  />;
