import { FetchApi } from '../lib/anuket-http';
import { configs } from '../configs';
import { streamHook, streamFail, eventBus } from './Streams';
import { getToken, isLoggedIn } from '../utils/isLoggedIn';
import _ from 'lodash';
import moment from 'moment';
import { RESULT_CONTEXT } from './SearchContext';
import { trackGoogleEvent } from '../utils/googleEvents';
import { of as ObservableOf } from 'rxjs';

const birthYearRange = 5;
const unclaimedSearchResultsStreamId = 'UnclaimedSearchResults';
const checkEntriesStreamId = (entryId, ctEntryId) => `checkEntries-${entryId}-${ctEntryId}`;

/**
 * Get query from filters
 * @param searchTerm
 * @param alias
 * @param location
 * @returns {string}
 */
function queryFromFilters({searchTerm, alias, location}) {
  let query = `searchTerm=${encodeURIComponent(searchTerm)}`;
  if (alias && Object.keys(alias).length > 0) {
    Object.keys(alias).forEach((alias) => {
      query += `&alias[]=${encodeURIComponent(alias)}`
    });
  }
  if (location && Object.keys(location).length > 0) {
    Object.keys(location).forEach((location) => {
      query += `&location[]=${encodeURIComponent(location)}`
    });
  }
  return query;
}

export function getUnclaimedResultsStream() {
  return streamHook(unclaimedSearchResultsStreamId);
}

/**
 * Search unclaimed results using filters
 * This doesn't return a stream, but just publishes a single fetch response to eventBus
 * searchTerm is required, aliasArr & locationArr are optional.
 *
 * @param searchTerm
 * @param alias
 * @param location
 * @param isRefined
 */

export function searchUnclaimed({searchTerm, alias, location}, isRefined) {
  trackGoogleEvent('search', RESULT_CONTEXT, searchTerm)
  getToken().then((token) => {
    let query = queryFromFilters({searchTerm, alias, location, limit: 10, skip: 0});
    const url = configs.ATHLINKS_API_HOST + `/Result/api/Search?${query}`;

    if(query.location === '' && query.searchTerm === ''){ return ObservableOf([]); }

    eventBus.publish(unclaimedSearchResultsStreamId, {searching: true});
    streamFail(
      unclaimedSearchResultsStreamId,
      FetchApi.memoizeFetch(10000)(
        url,
        {
          method: 'GET',
          headers: isLoggedIn() ? { 'Authorization': `Bearer ${token}` } : {}
        }
      ),
      (msg) => {
        if (msg.ErrorMessage) {
          return {error: msg.ErrorMessage};
        }

        return {
          results: msg,
          isRefined,
          lastSearchedTerm: searchTerm
        };
      }
    );
  });
}

/**
 * Get unclaimed results
 * @param results
 * @returns {{raceResults: Array, locations: (*|Array), aliases: (*|Array)}}
 */
//Main parsing/transformation to data coming in from API
export function getUnclaimedResults(results) {

  results = (results || {}).Result || {};
  return {
    raceResults: getRaceResults(results),
    locations:results.Locations || [],
    aliases: results.Aliases || []
  };
}

/**
 * Get race results
 * @param results
 * @returns {Array}
 */
function getRaceResults(results) {
  const raceArrays = (results || {}).RaceList || [];
  const raceResults = [];

  //flatten array of arrays & inject derived fields into each raceResult
  raceArrays.forEach((raceArray) => {
    (raceArray || []).forEach((raceResult) => {
      //Copy, don't change original data
      raceResult = Object.assign({}, raceResult);

      // Format the datetime to be usable by javascript
      const d = parseDate(raceResult.StartDateTime);
      raceResult.year = d ? d.format('YYYY') : '';
      raceResult.FormatDate = d ? d.format('MMM D, YYYY') : '';

      if (raceResult.Age && raceResult.Age !== -1) {
        try {
          const raceDate = new Date(raceResult.StartDateTime);
          raceResult.racerYob = raceDate.getFullYear() - raceResult.Age;
        } catch (e) {
        }
      }

      raceResults.push(raceResult);
    });
  });

  return raceResults;
}

/**
 * Parse data
 *
 * @todo - verify - Copied from start/Athlinks.Alaska
 * @param date
 * @returns {*}
 */
function parseDate(date) {
  // Parse the date
  try {
    return (date.startsWith('/Date(')) ?
      moment(parseInt(date.replace(/[a-zA-Z/)(]/g, '')))
      : moment(date);
  } catch (e) {
    return null;
  }
}

/**
 * Filter, all locations selected
 * @param filteredLocations
 * @returns {{}}
 */
export function allLocationsSelected(filteredLocations) {
  const selectedLocations = {};
  (filteredLocations || []).forEach((location) => {
    selectedLocations[location.Id] = 1;
  });
  return selectedLocations;
}

/**
 * Filter all race result selected
 * @param filteredRaceResults
 * @returns {{}}
 */
export function allRaceResultsSelected(filteredRaceResults = []) {
  return filteredRaceResults.map((raceResult) => raceResult.EntryId)
}

export function raceResultSelected(selectedRaces, EntryId){
  if(selectedRaces.includes(EntryId)){
    selectedRaces.splice(selectedRaces.indexOf(EntryId), 1);
  } else {
    selectedRaces.push(EntryId);
  }
  return selectedRaces;
}

/**
 * Filtering unclaimed results
 *
 * @param raceResults
 * @param locations
 * @param filters
 * @returns {{raceResults, locations: Array.<T>}}
 */

export function getFiltered(raceResults, locations, filters) {
  const {locationFilter: {enabled, value: location} = {}} = filters;
  const filteredStates = {};
  const filteredCountries = {};

  const filteredRaceResults = raceResults.filter(({StateProvId, Country}) => {
    filteredStates[Country] = 1;
    filteredStates[StateProvId] = 1;
    if(!enabled || !location || location.length === 0) return true;
    else return !!location[StateProvId] || !!location[Country];
  });

  //Filter locations by already-filtered race results
  const filteredLocations = (locations || [])
    .filter((item) => !!item)
    .filter(({Id}) => {
      return !!filteredStates[Id] || !!filteredCountries[Id];
    });

  return {
    raceResults: filteredRaceResults,
    locations: filteredLocations
  }
}

/**
 * Filter by birth date should calculate age relative to race start time
 * @param startDateTime
 * @param age
 * @param birthDate
 */
export function checkYearMatch(startDateTime, age, birthDate) {
  const calcAge = new Date(startDateTime).getFullYear() - birthDate.value;
  return !(birthDate.enabled && birthDate.value !== 1900
    && (age < calcAge - birthYearRange || age > calcAge + birthYearRange));
}

/**
 * Stream Id for blocking entries
 * @param racerID
 */
const blockEntryStreamId = (racerID) => `block-entry-${racerID}`;

const unBlockEntryStreamId = (racerID) => `unblock-entry-${racerID}`;

/**
 * Block entries from unclaimed search
 * @param racerId
 * @param blockData
 * @returns {*}
 */
export async function blockEntries(racerId, blockData){
  const token = await getToken();
  const url = `${configs.ATHLINKS_API_HOST}/Result/api/BlockEntries`;
  const blockedEntries = _.isArray(blockData) ? blockData : [blockData];
  const data = {racerId, blockedEntries};
  const streamID = blockEntryStreamId(racerId);
  streamFail(
    streamID,
    FetchApi.doFetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }
    ),
    (msg) => {
      const {ErrorMessage, Success} = msg;

      if (ErrorMessage) {
        return {error: ErrorMessage};
      }

      return {Success};
    }
  );
  return streamHook(streamID, { blockResultsFetching: true });
}

/**
 * unBlock entries from unclaimed search
 * @param racerId
 * @param blockData
 * @returns {*}
 */
export async function unBlockEntries(racerId, blockData){
  const token = await getToken();
  const url = `${configs.ATHLINKS_API_HOST}/Result/api/UnBlockEntries`;
  const blockedEntries = _.isArray(blockData) ? blockData : [blockData];
  const data = {racerId, blockedEntries};
  const streamID = unBlockEntryStreamId(racerId);
  streamFail(
    streamID,
    FetchApi.doFetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(data)
      }
    ),
    (msg) => {
      const {ErrorMessage} = msg;
      if (ErrorMessage) return {error: ErrorMessage};

    }
  );
  return streamHook(streamID, { blockResultsFetching: true });
}

/**
 * If is active age filter get locations from results for that age
 * @param results
 * @param birthDate
 * @returns {{raceResults}}
 */
export function getLocationsForYear(results, birthDate){
  const filteredRaceResults = results.filter((result) => {
    const {Age, StartDateTime} = result;
    return checkYearMatch(StartDateTime, Age, birthDate);
  });
  return {raceResults: filteredRaceResults};
}

/**
 * Filter results by all parameters
 * @param results
 * @param filters
 * @returns {{raceResults}}
 */
export function filterResults(results, filters) {
  const {birthDateFilter = {}, locationFilter = {}} = filters || {};
  const filteredRaceResults = results.filter((result) => {
    const {Age, Country, StateProvId, StartDateTime} = result;

    if (!checkYearMatch(StartDateTime, Age, birthDateFilter)) { return false; }

    return !(locationFilter.enabled && locationFilter.value.length > 0 &&
      !(locationFilter.value.indexOf(StateProvId) >= 0 || locationFilter.value.indexOf(Country) >= 0));
  });
  return {raceResults: filteredRaceResults};
}

/**
 * Check entries
 * @param entryId - athlinks entry id, defaults to 0 if we don't have it
 * @param ctEntryId - ct live entry id, defaults to 0 if we don't have it
 */
export async function checkEntries(entryId, ctEntryId) {
  const token = await getToken();
  const payload = {AthlinksEntryId: entryId, CTLiveEntryId: ctEntryId};
  const url = `${configs.ATHLINKS_API_HOST}/Claim/api/CheckResultEntriesMatches`;

  streamFail(
    checkEntriesStreamId(entryId, ctEntryId),
    FetchApi.doFetch(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify([payload])
      }
    ),
    (msg) => {
      if (msg.ErrorMessage) {
        return {error: msg.ErrorMessage};
      }

      return {
        results: msg,
      };
    }
  );
}

export function getCheckedEntriesStream(entryId, ctEntryId) {
  return streamHook(checkEntriesStreamId(entryId, ctEntryId), {checking: true});
}

/**
 * New claim entry
 */

const claimEntryStreamId = (entryId, ctEntryId) => `claim-entry-${entryId}-${ctEntryId}`;

/**
 * Claim single entry
 * @param entryId
 * @param ctEntryId
 * @param eventCourseId
 * @param fullName
 * @param bib
 * @param isMergeRequest
 */
export function claimEntry(entryId, ctEntryId, eventCourseId, fullName, bib, isMergeRequest) {
  getToken().then((token) => {
    const url = `${configs.ATHLINKS_API_HOST}/Claim/Api/ClaimEntry`;
    const data = {entryId, ctEntryId, eventCourseId, fullName, bib, isMergeRequest};

    streamFail(
      claimEntryStreamId(entryId, ctEntryId),
      FetchApi.doFetch(
        url,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(data)
        }
      ),
      (msg) => {
        const {ErrorMessage} = msg;
        if (ErrorMessage) return {error: ErrorMessage};
        else {
          return {results: msg};
        }
      }
    );
  })
}

export function getClaimEntryStream(entryId, ctEntryId) {
 return streamHook(claimEntryStreamId(entryId, ctEntryId), {claiming: true});
}

// for saving the last query executed (filters & search term)
const LAST_UNCLAIMED_SEARCH_QUERY = 'last-unclaimed-search-query';

export function getLastUnclaimedSearchQuery() {
  return streamHook(LAST_UNCLAIMED_SEARCH_QUERY, {});
}

export function setLastUnclaimedSearchQuery(query) {
  eventBus.publish(LAST_UNCLAIMED_SEARCH_QUERY, query)
}

const pendingClaimsStreadId = 'pending-claims';

export function fetchPendingClaims() {
  getToken().then((token) => {
    if (!token) {
      eventBus.publish(pendingClaimsStreadId, {result:[]})
      return streamHook(pendingClaimsStreadId, {result:[]})
    }
    const url = `${configs.ATHLINKS_API_HOST}/Claim/Api/PendingClaimsForRacer`;

    streamFail(
      pendingClaimsStreadId,
      FetchApi.memoizeFetch(1000)(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      ),
      (msg) => {
        if (msg.ErrorMessage) {
          return {error: msg.ErrorMessage};
        }

        return {result: msg.PendingClaims};
      }
    );
  });
}

export function getPendingClaims() {
  return streamHook(pendingClaimsStreadId, {fetching: true});
}

export const ClaimStatus = {
  unofficial: 'ClaimedUnofficial',
  pending: 'ClaimPendingSync',
  inReview: 'ClaimInReview',
  claimed: 'Claimed',
  claimable: 'Claimable',
  virtual: 'ClaimedVirtual',
};
