import React, { Component } from 'react';
import {DateRangePicker} from '@eventops/athlinks-react-date-range';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import {asKeyboardEventListener} from '../../utils/KeyboardHelper';
import moment from 'moment';

import FilterControls from './FilterControls';
import MobileFilterHeader from './MobileFilterHeader';
import {_filterTab} from './styles';
import {compose} from 'lodash/fp';
import {staticRanges} from '../../utils/staticRanges';
import {
  getFilterValues,
  setFilterValues,
  pushFilterHistory
} from '../../components/shared/Filters';
import {
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData
} from '../../components/shared/Filters';
import {
  EVENT_SEARCH
} from '../../data/SearchFilters';

export const dateStringToObject = (date) => {
  const dateParts = date.split('-');
  return new Date(dateParts[0], Number(dateParts[1]) - 1, dateParts[2])
};

class DateRange extends Component {

  constructor(props) {
    super(props);
    this.submitFilter = this.submitFilter.bind(this);
    const value = ((props.values || {}).dateRangeFilter || {}).value || {};
    this.startDate = (value.from || '') === '' ? defaultFilterData.filters.startDate : dateStringToObject(value.from); // only in safari
    this.endDate = (value.to || '') === '' ? defaultFilterData.filters.endDate : dateStringToObject(value.to);
  }

  UNSAFE_componentWillReceiveProps(nextProps) { //only works in chrome
    if(!this.props.values && nextProps.values) {
      const value = nextProps.values.dateRangeFilter.value;
      this.startDate = value.from === '' ? '' : dateStringToObject(value.from);
      this.endDate = value.to === '' ? '' : dateStringToObject(value.to);
    }
  }

  convertDate(date) {
    if (date === '') return date;
    return moment(date).format('YYYY-MM-DD')
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')(() => {
    const filters = {
      ...this.props.values,
      dateRangeFilter: {
        enabled: this.startDate !== defaultFilterData.filters.startDate
              || this.endDate !== defaultFilterData.filters.startDate,
        value: {
          from: this.convertDate(this.startDate),
          to: this.convertDate(this.endDate)
        }
      }
    };
    setFilterValues(EVENT_SEARCH, filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, this.props.history);
  }, true)

  render() {
    const {
      isMobile,
      onClose,
      i18n,
      t
    } = this.props;
    const aditionalProps = isMobile ? { shownDate: new Date() } : null;
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={_filterTab.dateFilterWrap(isMobile)}>
        {
          isMobile && <MobileFilterHeader
            title={t('Date Range')}
            onClose={onClose}
          />
        }
        <div style={_filterTab.dateWrapper(isMobile)}>
          <DateRangePicker
            onChange={(range) => {
              this.startDate = range.selection.startDate ? new Date(range.selection.startDate) : '';
              this.endDate = range.selection.endDate ? new Date(range.selection.endDate) : '';
              this.forceUpdate()
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            inputRanges={[]}
            staticRanges={staticRanges(t)}
            className={'PreviewArea'}
            months={1}
            isMobile={isMobile}
            athlinksCustom={true}
            locale={i18n.locale}
            ranges={[{
              startDate: this.startDate === '' || this.startDate === null ? null : new Date(this.startDate),
              endDate: this.endDate === '' || this.endDate === null ? null : new Date(this.endDate),
              key: 'selection'}]}
            direction='horizontal'
            {...aditionalProps}
          />
        </div>
        <FilterControls
          style={{ justifyContent: 'flex-end', paddingTop: '0px'}}
          onSave={this.submitFilter}
          onReset={() => {
            this.startDate = defaultFilterData.filters.startDate;
            this.endDate = defaultFilterData.filters.endDate;
            this.forceUpdate()
          }}
        />
        <div style={{backgroundColor: 'white', height: isMobile ? 80 : 0}}/>
      </div>
    )
  }
}

export default compose(
  withTranslation(),
  connectStream({
    values: () => getFilterValues(EVENT_SEARCH)
  }),
  withRouter,
)(DateRange);
