import {SearchState} from '../model/searchState';
import {SearchActionTypes} from '../actions/types';

export default (state = SearchState, action) => {
  switch(action.type) {
    case SearchActionTypes.CONTEXT_SET:
      return {
        ...state,
        context: action.context
      };
    default:
      return state;
  }
}
