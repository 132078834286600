import React from 'react'
import RightIcon from 'react-icons/lib/fa/angle-right'
import LeftIcon from 'react-icons/lib/fa/angle-left'
import { sliderSettings } from './styles'

const PrevNavButton = (props) => {
  const propClone = {...props};
  delete propClone.currentSlide;
  delete propClone.slideCount;
  return (<LeftIcon { ...propClone } style={{ ...sliderSettings.navButtonStyle, left: '-50px' }} />);
};
  // <LeftIcon { ...props } style={{ ...navButtonStyle, left: '-50px' }} />;

const NextNavButton = (props) => {
  const propClone = {...props};
  delete propClone.currentSlide;
  delete propClone.slideCount;
  return (<RightIcon { ...propClone } style={{ ...sliderSettings.navButtonStyle, right: '-50px' }} />);
};
 

const slickSettings = {
  dots: false,
  infinite: true,
  vertical: false,
  centerMode: true,
  centerPadding: '0px',
  draggable: false,
  focusOnSelect: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextNavButton/>,
  prevArrow: <PrevNavButton/>,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '20px',
      },
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '150px',
      },
    },
  ],
};

export default slickSettings;