import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {connectStream} from '../../lib/bastetjs/utils/connectStream';

import Checkbox from './Checkbox';
import FilterControls from './FilterControls';
import Accordion from './Accordion'
import MobileFilterHeader from './MobileFilterHeader';
import {eventTypeMapping, EVENT_SEARCH, PROFILE_SEARCH} from '../../data/SearchFilters';
import {_filterTab} from './styles';
import {
  getFilterValues,
  setFilterValues,
  setSearchFiltersDataStream as setFiltersData,
  defaultFilterData,
  pushFilterHistory
} from '../../components/shared/Filters';
import { asKeyboardEventListener } from '../../utils/KeyboardHelper';

const mapStreamIds = {
  'event': EVENT_SEARCH,
  'athlete': PROFILE_SEARCH
};

class RaceTypes extends Component {
  constructor(props) {
    super(props);
    this.activeTab = 'running';
    this.raceTypes = defaultFilterData.filters.raceTypes;
    if ((props.values || {}).typeFilter) {
      this.raceTypes = this.transformValues(props.values.typeFilter)
    }
  }

  transformValues(typesList = {}) {
    return Object.keys(this.raceTypes).reduce((acc, curr) => {
      Object.keys(acc[curr]).forEach((key) => {
        if (key in typesList) {
          acc[curr][key] = typesList[key]
        }
      });
      return acc
    }, {
      running: {...defaultFilterData.filters.raceTypes.running},
      triathlon: {...defaultFilterData.filters.raceTypes.triathlon},
      more: {...defaultFilterData.filters.raceTypes.more}
    })
  }

  countType(typeData) {
    const count = Object.keys(typeData)
      .map((key) => typeData[key])
      .filter((v) => v).length;
    return count === 0 ? '' : count;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.props.values && nextProps.values) {
      this.raceTypes = this.transformValues(nextProps.values.typeFilter)
    }
  }

  selectAll(category) {
    const itemsInCategory = this.raceTypes[category];
    const notAllSelected = Object.keys(itemsInCategory).filter((value) => !itemsInCategory[value]).length;
    Object.keys(itemsInCategory).forEach((key) => {
      this.raceTypes[category][key] = !!notAllSelected
    });
    this.forceUpdate()
  }

  submitFilter = asKeyboardEventListener('keydown')('enter')((e) => {
    const {activeContext, values, history} = this.props;
    const { running, more, triathlon } = this.raceTypes;
    const hasRunningValues = Object.keys(running).some((value) => running[value]);
    const hasMoreValues = Object.keys(more).some((value) => more[value]);
    const hasTriathlonValues = Object.keys(triathlon).some((value) => triathlon[value]);
    const typeFilter = {
      ...this.raceTypes.running,
      ...this.raceTypes.more,
      ...this.raceTypes.triathlon,
      running: hasRunningValues,
      more: hasMoreValues,
      triathlon: hasTriathlonValues
    };
    const filters = {
      ...values,
      typeFilter: Object.keys(typeFilter)
        .reduce((acc, curr) => {
          // omit all falsey type filters
          if (typeFilter[curr]) {
            acc[curr] = typeFilter[curr];
          }
          return acc;
        }, {})
    };
    setFilterValues(mapStreamIds[activeContext], filters);
    setFiltersData({
      activeTab: null
    });
    pushFilterHistory(filters, history);
  }, true)

  render() {
    const {
      isMobile,
      onClose,
      t
    } = this.props;
    const eventTypesMapping = eventTypeMapping(t);
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
        style={_filterTab.raceFilterWrap(isMobile)}>
        {
          isMobile && <MobileFilterHeader
            title={t('Race Types')}
            onClose={onClose}
          />
        }
        {
          eventTypesMapping.map((type) =>
            <Accordion
              key={type.key}
              title={type.title}
              type={type.key}
              typeCount={this.countType(this.raceTypes[type.key])}
              onHeaderClick={(type) => {
                this.activeTab = type;
                this.forceUpdate()
              }}
              active={type.key === this.activeTab}
            >
              <div
                key={`${type.key}-all`}
                style={{color: '#26AAFC', paddingBottom: 10, cursor: 'pointer'}}
                onClick={() => this.selectAll(type.key)}
              >
                {
                  Object.keys(this.raceTypes[type.key]).filter((value) => !this.raceTypes[type.key][value]).length
                    ? t('Select All')
                    : t('Clear All')
                }
              </div>
              {
                type.children.map((child) => <Checkbox
                  key={child.key}
                  onCheck={() => {
                    this.raceTypes = {
                      ...this.raceTypes,
                      [type.key]: {
                        ...this.raceTypes[type.key],
                        [child.key]: !this.raceTypes[type.key][child.key]
                      }
                    };
                    this.forceUpdate()
                  }}
                  label={child.title}
                  checked={this.raceTypes[type.key][child.key]}
                />)
              }
            </Accordion>
        )}
        <FilterControls
          onSave={this.submitFilter}
          onReset={() => {
            this.raceTypes = defaultFilterData.filters.raceTypes;
            this.forceUpdate()
          }}
        />
      </div>
    )
  }
}

export default withTranslation()(connectStream({
  values: ({activeContext}) => getFilterValues(mapStreamIds[activeContext])
})(withRouter(RaceTypes)));
