import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import  ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right'
import { colors } from '../../shared/styles';
import { trackGoogleEvent } from '../../utils/googleEvents';
import {
  getResultsUrlForBib,
  getResultUrl
} from '../../utils/resultsHelpers';

class ResultRowComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      arrow: false
    }
  }

  onMouseEnter = () => {
    this.setState({arrow: true})
  };

  onMouseLeave = () => {
    this.setState({arrow: false})
  };

  render() {
    const {
      entryId,
      bibNum,
      raceTitle,
      eventId,
      eventCourseId,
      athleteTime,
      raceCity,
      athleteName,
      athleteAge,
      athleteGender,
      raceStateProvAbbrev,
      styles,
      t
    } = this.props;
    const {arrow} = this.state;

    const resultUrl = bibNum
      ? getResultsUrlForBib(eventId, eventCourseId, bibNum, null, entryId)
      : getResultUrl(eventId, eventCourseId, entryId);

    return (
      <div
        style={styles.resultRow(arrow)}
        key={entryId}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={() => trackGoogleEvent('search', 'Result Chosen', raceTitle)}
      >
        <Link to={resultUrl}>
          <div style={styles.athleteTimeContainer}>
            <div style={styles.athleteTime}>{athleteTime}</div>
          </div>
          <div style={styles.resultsContainer}>
            <div style={styles.resultName}>{raceTitle}</div>
            <div style={styles.resultDetails}>{t('{{city}}, {{stateProvAbbrev}}', {city: raceCity, stateProvAbbrev: raceStateProvAbbrev})}</div>
            <div style={styles.resultDetails}>{t('{{athleteName}} | {{athleteAge}}{{athleteGender}}', {athleteName, athleteAge, athleteGender})}</div>
          </div>
          <div style={styles.rowArrowContainer}>
            {arrow && <ArrowRight size={65} style={{color: colors.lightGrey2}}/> }
          </div>
        </Link>
      </div>
    )
  }
}

export const ResultRow = withTranslation()(ResultRowComponent);
