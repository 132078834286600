import i18n from '../../utils/i18n';
import React from 'react';
import { connect } from 'react-redux';
import {Configure} from '../../lib/anuket-http';
import {trackGoogleEvent} from '../../utils/googleEvents';
import FooterLanguageSwitcher from './FooterLanguageSwitcher';
import {availableLanguages} from '../../data/LanguageStreams';
import { setUserPrefsAction } from '../../actions/userPrefsAction';

const mapStateToProps = () => ({
  dispatch: undefined
});

const LanguageSwitcherComponent = ({isKiosk, dispatch}) => {
  const currentLang = i18n.language.split('-')[0];

  return (
    <FooterLanguageSwitcher
      value={currentLang}
      isKiosk={isKiosk}
      languages={availableLanguages}
      onChange={(lang) => {
        i18n.changeLanguage(lang);
        Configure.setConfigs({locale: lang});
        dispatch(setUserPrefsAction({ language: lang }));
        trackGoogleEvent('Footer', 'Toggle', 'Language');
      }}
    />
  )
};

export const LanguageSwitcher = connect(mapStateToProps)(LanguageSwitcherComponent);
