import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal } from '../../actions/modalActions'
import { addEventWizardModal as sharedStyle, addEventSuccess as style } from './addEventStyles'
import { AthlinksMedal } from '../shared/icons';

const mapStateToProps = () => ({
  dispatch: undefined
});

class AddEventSuccessComponent extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div style={style.medalContainer}>
        <div style={style.medal}>
          <AthlinksMedal />
        </div>
        <div style={sharedStyle.modalTitle}>
          {t('Results Requested!')}
        </div>
        <div style={{ ...sharedStyle.modalSubtitle, ...style.subMessage }}>
          {t(`Gathering results can sometimes take up to a week but we'll do our best to get them added ASAP. Thanks for submitting!`)}
        </div>
      </div>
    )
  }
}
export const AddEventSuccess = connect(mapStateToProps, null)(withTranslation()(AddEventSuccessComponent));

export class SuccessFooterComponent extends React.Component {
  render() {
    const {
      dispatch,
      isMobile,
      t
    } = this.props;

    const footerStyle = isMobile ? sharedStyle.footerMobile : {};

    return (
      <div style={footerStyle}>
        <button onClick={() => dispatch(hideModal())} style={style.doneButton}>
          <div style={style.doneButtonText}>
            {t('Done')}
          </div>
        </button>
      </div>
    )
  }
}
export const SuccessFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(SuccessFooterComponent);
