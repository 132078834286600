import LocalStreamingLog from './LocalStreamingLog'
import { map } from 'rxjs/operators'

/**
 * Usage:
 * eventBus.getTopic("myEvent").subscribe(message => doThingWith(message));
 * ... meanwhile, elsewhere ...
 * eventBus.publish("myEvent", { some: "Interesting", data: 4, which: 2, do: "Things with" });
 */
class EventBus {

  static log = new LocalStreamingLog();

  static _instance = new EventBus();

  static getInstance = () => {
    if (!EventBus._instance) {
      EventBus._instance = new EventBus();
    }
    return EventBus._instance
  }

  get id() { return this._id; }

  constructor() {
    this._id = Math.random();
  }
  publish(topic, message) {
    // console.log(`${this.id} publishing ${message} on ${topic}`)
    EventBus.log.publish(topic, JSON.stringify(message));
  }
  getTopic(topic) {
    return EventBus.log.getTopic(topic).pipe(map((msg) => {
      // console.log(`${id} got ${msg} on ${topic}`)
      return JSON.parse(msg);
    }));
  }
}

export default EventBus.getInstance();
