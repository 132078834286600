import Axios from 'axios'
import { logInfo } from './Logging'
import { configs } from '../configs'
import { getCORSConfig } from './getCORSConfig'

export const followAthlete = (
  {
    athleteCardModel: {
      racerId: athleteId
    }
  },
  token
) => new Promise(async (resolve, _reject) => {
  const data = {
    athleteId // the followedAthleteId that you think it should use is wrong
  }
  const url = `${configs.followingApiBaseUrl}/following`
  logInfo('hitting', url)
  Axios.post(url, data, getCORSConfig(token))
    .then(response => {
      resolve({
        success: response.status === 200
      })
    }).catch((error) => {
      if (error.response?.status === 403) {
        resolve({ success: false })
      }
      resolve({
        success: false,
        error: `Following failed for "${athleteId}": "${JSON.stringify(error.response?.data)}"`
      })
    })
})