import { heroImageUrl } from '../../utils/heroImageUrl';

import { useStyles } from './styles';

interface HeroImageProps {
  isMobile: boolean;
  type?: string;
  isPublished: boolean;
  hasHeroImage: boolean;
  ctliveEventId?: string;
}

const HeroImage = ({
  isMobile,
  type = 'Running',
  isPublished,
  hasHeroImage,
  ctliveEventId,
}: HeroImageProps) => {
  const withCustomHeroImage = isPublished && hasHeroImage;

  const styles = useStyles({ withCustomHeroImage });

  const imgUrl = heroImageUrl(
    type,
    isMobile,
    withCustomHeroImage && ctliveEventId
  );

  return (
    <img src={imgUrl} alt='Hero pic' className={styles.heroImage} />
  );
};

export { HeroImage };
