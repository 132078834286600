import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { styles } from './styles';
import { ResultsComponent } from './ResultsComponent';
import { AthletesComponent } from './AthletesComponent';
import { EventComponent } from './EventsComponent';
import { Loading } from '../../components/shared/Loading';
import { imageStyles } from '../../shared/styles';
import {NoResults} from './NoResults'

class AllResultsComponent extends Component {
  static propTypes = {
    t: PropTypes.func
  };

  state = {
    location: ''
  };

  buildSearchQuery(searchTerm, locationTerm, type) {
    const searchQuery = searchTerm
    ? locationTerm
      ? `?locationTerm=${locationTerm}&term=${encodeURIComponent(searchTerm)}`
      : `?category=${type}&term=${encodeURIComponent(searchTerm)}`
    : locationTerm
      ? `?locationTerm=${locationTerm}`
      : '';
    return `/search/${type}/${searchQuery}`;
  }

  render() {
    const {
      notFound,
      isMobile,
      searchDestination,
      t
    } = this.props;

    let parentProps = this.props;
    const {fetching, onUserChooseResult} = this.props;
    const eventsRes = parentProps.props.results.eventsRes;
    const athletesRes = parentProps.props.results.athletesRes;
    const resultsRes = parentProps.props.results.resultsRes;
    const locationTerm = parentProps.props.locationTerm;
    const searchTerm = this.props.searchTerm;
    const hideResults =  !this.props.hideSearchInputs ? !this.props.hideResults : true;

    return (
      <div style={ styles.allResults(!hideResults || !searchTerm.length, notFound, isMobile, window.innerHeight) }>
        {
          // Results
        }
        { hideResults && (athletesRes || eventsRes || resultsRes) && (searchTerm.length >= 4) &&
          <div onClick={onUserChooseResult && onUserChooseResult}>
            { searchDestination === 'unclaimed' ? resultsRes.total > 0 ?
              <ResultsComponent results={resultsRes.unclaimedResults}
                                slice='3'
                                total={resultsRes.total}
                                searchTerm={resultsRes.searchTerm}
                                t={t}
              />
              :
              <NoResults link={this.buildSearchQuery(searchTerm, locationTerm, 'unclaimed')}
                         styles={styles}
                         messagesLink={t('Unclaimed Results')}
                         messgesBody={t(
                           '0 unclaimed results match your search for  "{{searchTerm}}"',
                           {searchTerm}
                         )}
                         resultsRes={resultsRes}/>
              : <div/>
            }
            { searchDestination === 'athletes' ? athletesRes.total > 0 ?
              <div>
                <AthletesComponent athletes={athletesRes.athletes}
                                   slice='3'
                                   total={athletesRes.total}
                                   locationTerm={locationTerm}
                                   searchTerm={athletesRes.searchTerm}
                                   t={t}
                />
              </div>
              :
              <NoResults link={this.buildSearchQuery(searchTerm, locationTerm, 'athletes')}
                         styles={styles}
                         messagesLink={t('Athletes')}
                         messgesBody={t(
                           '0 athletes match your search for  "{{searchTerm}}"',
                           {searchTerm}
                         )}
                         resultsRes={resultsRes}/>
              : <div/>
            }
            { searchDestination === 'events' ? eventsRes.total > 0 ?
              <div>
                <EventComponent events={eventsRes.events}
                                slice='3'
                                total={eventsRes.total}
                                locationTerm={locationTerm}
                                searchTerm={eventsRes.searchTerm}
                                t={t}
                />
              </div>
              :
              <NoResults id='no-results-events'
                         link={this.buildSearchQuery(searchTerm, locationTerm, 'events')}
                         styles={styles}
                         messagesLink={t('Events')}
                         messgesBody={t(
                           '0 events match your search for  "{{searchTerm}}"',
                           {searchTerm}
                         )}
                         resultsRes={resultsRes}/>
              : <div/>
            }
          </div>
        }
        {
          // Searching...
        }
        { searchTerm && searchTerm.length < 4 &&
            <div id='search-narrow'style={ styles.searchingOverlay }>
              {t('Please narrow your search')}
            </div>
        }

        {
          // Loader
        }
        {fetching && <div style={styles.loadingOverlay(fetching)}>
          <div style={imageStyles.loadingPosition}>
            <Loading timeoutMessage={t('Still thinking...')}/>
          </div>
        </div>}

      </div>
    );
  }
}

export const AllResults = withTranslation()(AllResultsComponent);
