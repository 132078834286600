import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputWithFilters, SelectedFiltersItem } from "../../components/inputWithFilters/InputWithFilters";
import { DropDown } from "../../components/shared/Elements";

import { TermSearch } from "./TermSearch";

type EventRosterSearchProps = {
  divisions: {
    id: number
    name: string
  }[]
  divisionId?: number
  eventId: number
  eventCourseId: number
  isMobile: boolean
  races: {
    raceID: number
    raceDate: string
    eventCourses: { eventCourseID: number; courseName: string }[]
  }[]
  pushHistory: (args: { evid?: number; ecid?: number; divisionId?: number; _page: number }) => void
}

const formatEventDate = (date: string) => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const dateObj = new Date(date)

  return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`
}

const EventRosterSearch = ({
  divisions,
  divisionId,
  eventId,
  eventCourseId,
  isMobile,
  pushHistory,
  races,
}: EventRosterSearchProps) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState({ eventCourseId, eventId, divisionId })

  const onOpenFilters = useCallback(() => {
    setFormValues({ eventCourseId, eventId, divisionId })
  }, [divisionId, eventCourseId, eventId])

  const applyFilters = useCallback((values: typeof formValues) => {
    const { eventId, eventCourseId, divisionId } = values

    pushHistory({ evid: eventId, ecid: eventCourseId, divisionId: divisionId, _page: 1 })
  }, [pushHistory])

  const getCourses = useCallback((eventId: number) => {
    const courses = (races || []).find((race) => race.raceID === eventId)?.eventCourses || []

    console.assert(courses.length > 0, 'No courses found for event')

    return courses
  }, [races])

  const onChangeEvent = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const eventId = e.target.value as unknown as number

    const courses = getCourses(eventId)

    setFormValues({ eventId, eventCourseId: courses[0].eventCourseID, divisionId: undefined })
  }, [getCourses])

  const onClickDeleteFilter = useCallback((filterValue: string) => {
    if (filterValue === 'division') {
      applyFilters({ ...formValues, divisionId: undefined })
    }
  }, [applyFilters, formValues])

  const courseOptions = useMemo(() => {
    const courses = getCourses(formValues.eventId)

    return courses.map((course) => ({
      label: course.courseName,
      value: course.eventCourseID
    }))
  }, [formValues.eventId, getCourses])

  const divisionOptions = useMemo(() => {
    return divisions.map((division) => ({
      label: division.name,
      value: division.id
    }))
  }, [divisions])

  const eventOptions = useMemo(() => {
    const sortedRaces = (races || []).sort((a, b) => {
      const first = Date.parse(a.raceDate);
      const second = Date.parse(b.raceDate);
      return first > second ? 1 : second > first ? -1 : 0;
    })

    return sortedRaces.map((race) => ({
      label: formatEventDate(race.raceDate),
      value: race.raceID
    }))
  }, [races])

  const selectedFilters = useMemo<SelectedFiltersItem[]>(() => {
    const selectedEvent = eventOptions.find((event) => event.value === eventId)

    const courses = getCourses(eventId)
    const selectedCourse = courses.find((course) => course.eventCourseID === eventCourseId)

    if (!selectedEvent || !selectedCourse) {
      return []
    }

    const filters: SelectedFiltersItem[] = [
      { label: formatEventDate(selectedEvent.label), value: 'event' },
      { label: selectedCourse.courseName, value: 'course' }
    ]

    const selectedDivision = divisions.find((division) => division.id === divisionId)
    const isSelectedDivisionDefault = !!divisions.length && divisionId === divisions[0].id

    if (selectedDivision && !isSelectedDivisionDefault) {
      filters.push({ label: selectedDivision.name, value: 'division', isRemovable: true })
    }

    return filters
  }, [divisionId, divisions, eventCourseId, eventId, eventOptions, getCourses])

  return (
    <InputWithFilters
      isFullScreen={isMobile}
      selectedFilters={selectedFilters}
      onClickApply={() => applyFilters(formValues)}
      onClickDeleteFilter={onClickDeleteFilter}
      onOpenFilters={onOpenFilters}
      renderInput={() => (
        <TermSearch pushHistory={pushHistory} />
      )}
      renderFilters={() => (
        <>
          <DropDown
            id='event'
            label={t('Event')}
            value={formValues.eventId}
            disabled={false}
            onChange={onChangeEvent}
            options={eventOptions}
            className={undefined}
            inputStyle={undefined}
          />

          <DropDown
            id='course'
            label={t('Race')}
            value={formValues.eventCourseId}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormValues((prev) => ({ ...prev, eventCourseId: e.target.value as unknown as number }))}
            options={courseOptions}
            className={undefined}
            inputStyle={undefined}
          />

          <DropDown
            id='division'
            label={t('Division')}
            value={formValues.divisionId || divisions[0]?.id}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormValues((prev) => ({ ...prev, divisionId: e.target.value as unknown as number }))}
            options={divisionOptions}
            className={undefined}
            inputStyle={undefined}
          />
        </>
      )}
    />
  );
}

export { EventRosterSearch }

