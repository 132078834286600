import { UserPrefsState } from '../model/userPrefsState'
import { checkAction, actionPayloadOnly } from '../utils/reducerHelper'
import { UserPrefsActionTypes } from '../actions/types'
import { isEmpty } from 'lodash'

const updateStateOnThisAction = checkAction(
  UserPrefsActionTypes.GET_USER_PREFS_COMPLETE,
  UserPrefsActionTypes.SET_USER_PREFS_COMPLETE,
 )

const errorStateOnThisAction = checkAction(
  UserPrefsActionTypes.GET_USER_PREFS_ERROR,
  UserPrefsActionTypes.SET_USER_PREFS_ERROR
)

export default (state = UserPrefsState, action) => {
  if(updateStateOnThisAction(action)) {
    const userPrefs = actionPayloadOnly(action)
    // If the data was not written to local store we would expect this to be empty and shouldn't overwrite defaults
    return isEmpty(userPrefs) ? state : userPrefs
  }
  if(errorStateOnThisAction(action)) {
    console.warn('COULD NOT ACCESS PREFS FROM LOCAL STORE', action)
    return UserPrefsState
  }
  return state
}
