import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FriendsAttending } from './FriendsAttending';
import { FriendsRacing } from '../../ELP/friendsRacing/FriendsRacing';
import { styles } from './styles';

export class CuratedEventDetail extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired
  };

  render() {
    const {
      event,
      isMobile
    } = this.props;

    const { curatedEventDetail } = styles;

    return (

      <div style={curatedEventDetail.container}>
      <div style={curatedEventDetail.content}/>
        <div style={curatedEventDetail.main}>
          <div style={{ textAlign:'left', }} dangerouslySetInnerHTML={{__html:event.masterCuratedDescription}}>
          </div>
          <div style={curatedEventDetail.friends}>
            <FriendsAttending event={event} />
            <FriendsRacing
              type="ELP"
              isMobile={isMobile}
              event={event}
            />
          </div>
        </div>
      </div>
    );
  }
}
