
import { ofType } from 'redux-observable'
import { catchError, mapTo } from 'rxjs/operators'
import { flow } from 'lodash'
import { UserPrefsActionTypes } from '../actions/types'
import { getUserPrefs } from '../utils/userPrefsStorageHelper'
import { getUserPrefsActionComplete, getUserPrefsActionError } from '../actions/userPrefsAction'

export const getUserPrefsEpic = (actions$) => {
  return actions$.pipe(
    ofType(UserPrefsActionTypes.GET_USER_PREFS),
    mapTo(
      flow(
        getUserPrefs,
        getUserPrefsActionComplete
      )()
    ),
    catchError(getUserPrefsActionError)
  )
}