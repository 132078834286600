import { MapTrackingActionTypes } from './types'

export const mapCheckAction = (eventCourseId) => ({
  type: MapTrackingActionTypes.MAP_CHECK,
  eventCourseId: eventCourseId
})

export const mapCheckActionComplete = (mapPresent) => ({
  type: MapTrackingActionTypes.MAP_CHECK_COMPLETE,
  mapPresent: mapPresent
})

export const mapCheckActionError = () => ({
  type: MapTrackingActionTypes.MAP_CHECK_ERROR
})