import { loadingDark, loadingLight, transition, fonts, colors } from '../../shared/styles';

export const styles = {
  loadingContainer: (canShow, isMobile) => ({
    position: 'absolute',
    top: 90,
    left: 0,
    width: '100%',
    height: '100%',
    paddingLeft: 15,
    paddingRight: isMobile ? 15 : 80,
    opacity: (canShow) ? 0.6 : 0,
    pointerEvents: 'none',
    transition: 'all 0.4s',
  }),
  loadingRow: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 63,
    pointerEvents: 'none',
  },
  loadingRowMobile: {
    marginTop: 20,
    marginBottom: 38,
    pointerEvents: 'none',
  },
  loadingDetailContainer: {
    flex: '1 1 auto',
    display: 'inline-block',
  },
  loadingTitle: {
    height: 22,
    width: '80%',
    minWidth: 230,
    marginBottom: 12,
    ...loadingDark, // Animation
  },
  loadingSubtitle: {
    height: 14,
    width: '50%',
    minWidth: 150,
    marginBottom: 12,
    ...loadingLight, // Animation
  },
  loadingSubtitle2: {
    height: 14,
    width: '30%',
    minWidth: 120,
    marginBottom: 12,
    ...loadingLight, // Animation
  },
  loadingTimeBox: {
  	flex: '0 0 auto',
  	height: 55,
  	width: 120,
  	marginTop: 8,
  	marginRight: 20,
    ...loadingDark, // Animation
  },
  loadingButton: {
  	flex: '0 0 auto',
  	height: 55,
  	width: 136,
  	margin: '8px 0 0',
  	borderRadius: '3px',
    ...loadingDark, // Animation
  },
  refineCheckbox: {
    textAlign:'left',
    paddingLeft:'10px',
    whiteSpace:'nowrap'
  },
  refineButton: {
      width: '100%',
      maxWidth: 392,
      letterSpacing: 1,
      marginTop:'20px'
  },
  refineItemsWrap: {
    display:'inline-block',
    marginTop:'20px'
  },
  resStyle: {
    pseudoStyles: () => `
      .unclaimed-result-row:hover {
        background: ${colors.lightGrey} !important;
        cursor: pointer;
      }
      .unclaimed-result-row:hover .unclaimed-results-event-url a{
        text-decoration: underline !important;
        text-decoration-color: ${colors.greyLine} !important;
        -webkit-text-decoration-color: ${colors.greyLine} !important;
      }
    `,
    row: (isMobile) => ({
      ...transition.fadeIn,
      display: 'flex',
      alignItems: 'center',
      marginBottom: isMobile ? 40 : 65
    }),
    rowChild: {
      paddingLeft:0,
      paddingRight:20,
      display:'flex'
    },
    iconContainer: {
      textAlign: 'center',
      display: 'inline-block',
      paddingRight: 20,
      paddingTop: 2,
      flex: '0 0 auto',
    },
    icon: {
      display: 'inline-block',
      width: '100%',
    },
    resultInfoContainer: {
      width: '100%',
    },
    dateRow: (isMobile) => ({
      marginBottom: 8,
      color: colors.grey,
      fontSize: isMobile ? 12 : 14,
    }),
    athleteRow: (isMobile) => ({
      paddingTop: 8,
      color: colors.grey,
      fontSize: isMobile ? 12 : 14,
      borderTop: `1px solid ${colors.greyLine}`,
    }),
    padding0: {
      float: 'left',
      paddingLeft: 0
    },
    datumLeft: {
      display: 'inline-block',
      paddingRight: 20,
    },
    datum: {
      display: 'inline-block',
      padding: '0 20px',
      borderLeft: `1px solid ${colors.greyLine}`,
    },
    timeLabel: {
      ...fonts.heavy,
      color: colors.grey,
      fontSize: 10,
      letterSpacing: '0.5px',
      textTransform: 'uppercase'
    },
    time: (blocked) => ({
      ...fonts.number,
      fontSize: 45,
      lineHeight: '1em',
      margin: 0,
      color: (blocked) ? colors.grey : colors.textColor
    }),
    timeContainer: {
      position: 'relative',
      top: 6,
      textAlign: 'center'
    },
    ctaContainer: {
      textAlign: 'center',
      padding: '30px 0 0',
    },
    notMine: {
      color: '#16A9E1',
      fontFamily: 'ProximaNovaRegular',
      fontSize: 14,
      lineHeight: '17px',
      cursor: 'pointer'
    },
    claimedIcon: {
      position: 'absolute',
      top: '22px',
      left: '10px',
    }
  },
  mobile : {
    loadingTitle: () => ({
      ...styles.loadingTitle,
      minWidth: 270,
      marginBottom: 0
    }),
    loadingSubtitle: () => ({
      ...styles.loadingSubtitle,
      marginTop: 6,
      marginBottom: 20
    }),
    loadingSubtitle2: () => ({
      ...styles.loadingSubtitle2,
      marginBottom: 0
    }),
    loadingTimeBox: () => ({
      ...styles.loadingTimeBox,
      margin: '0 auto'
    }),
    loadingButton: () => ({
      ...styles.loadingButton,
      marginTop: 3,
      width: '100%',
      height: 54
    })
  },
  addButton: {
    height: 36,
    width: 212,
    borderRadius: 2,
    backgroundColor: '#16A9E1',
    display: 'flex',
    alignItems: 'center'
  },
  buttonText: {
    height: 20,
    width: 164,
    color: '#FFFFFF',
    fontFamily: 'ProximaNovaRegular',
    fontSize: 16,
    lineHeight: '20px',
    textAlign: 'center',
    margin: '0 auto'
  },
  allNotMine: {
    height: 17,
    width: 203,
    color: '#16A9E1',
    fontFamily: 'ProximaNovaRegular',
    fontSize: 14,
    lineHeight: '34px',
    textAlign: 'center'
  },
};
