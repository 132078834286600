import { useRouteMatch } from 'react-router-dom';

export function useEventCourseIdentifiers({
  races
}: {
  races: any[]
}) {
  const route = useRouteMatch<{
    courseId: string
    eventId: string
    divisionId: string
    masterId: string
  }>();
  const {
    masterId: $masterId, eventId: $eventId, courseId: $eventCourseId, divisionId: $divisionId
  } = route.params;
  let masterId = parseInt($masterId) ?? undefined;
  let eventId = parseInt($eventId) ?? undefined;
  let eventCourseId = parseInt($eventCourseId) ?? undefined;
  let divisionId = parseInt($divisionId) ?? undefined;
  // console.log({masterId, eventId, eventCourseId, route})
  if (!eventId && races?.length) {
    // console.log('Guess eventId is missing...', eventId)
    eventId = races.reduce((a, c, i) => {
      if (c.isCurrentRace) {
        return c.raceID;
      }
      else if (!a) {
        return c;
      }
      return a;
    }, 0);
  }
  if (!eventCourseId && races?.length && eventId) {
    eventCourseId = races.find((x) => x.raceID === eventId)?.eventCourses[0]?.eventCourseID;
  }
  return {
    eventCourseId,
    divisionId,
    eventId,
    masterId
  };
}
