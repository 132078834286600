import React, {cloneElement, Children} from 'react';
import { ResizingContainer } from '../resizingContainer/ResizingContainer';

export const BragiResizingContainer = ({children, ...props}) => {
  return (
    <ResizingContainer mobileBreakPoint={1024} tabletBreakPoint={-1}>
      {Children.map(children, (child) => cloneElement(child, props))}
    </ResizingContainer>
  );
};
