import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {styles} from './styles';
import _ from 'lodash';
import {getBracketRank, getResultsUrlForBib} from '../../utils/resultsHelpers';
import {convertTime} from '@eventops/athlinks-lib-units';
import {Link} from 'react-router-dom';
import {AthlinksClaimedFlame} from '../shared/AthlinksClaimedFlame';
import {RowArrow} from '../shared/RowArrow';
import {getCourseDetail, verifyGender} from '../../utils/ERPUtil';
import {isLoggedIn, getTokenRacerId} from '../../utils/isLoggedIn';
import {VRBadgeLink} from '../shared/VRBadge';

class EventResultsIntervalMobileComponent extends Component {
  static propTypes = {
    eventMetadata: PropTypes.object,
    masterId: PropTypes.string,
    interval: PropTypes.object,
    result: PropTypes.object,
    results: PropTypes.array,
    leaderboard: PropTypes.bool,
    showSignUpBanner: PropTypes.bool,
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    tokenRacerId: PropTypes.number,
    t: PropTypes.func
  };

  state = {
    tokenRacerId: getTokenRacerId()
  }

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  render() {
    const {
      masterId,
      interval,
      eventMetadata,
      result,
      results,
      t
    } = this.props;
    const {
      eventId,
      eventCourseId
    } = result

    const courseDetail = getCourseDetail(eventCourseId, eventMetadata);

    const {
      isVirtual
    } = courseDetail;

    return (
      <div key={interval.order}>
        <div style={{
          ...styles.intervalTitle,
          fontSize: 12,
          marginBottom: 20
        }}>
          {!results[0].thirdParySystemRaceId ? t('Full Course') : interval.name || t('Full Course')}
        </div>
        {
          interval.results.map((row, idx) => {
            const {
              bib,
              entryId,
              entryStatus,
              age,
              gender,
              displayName,
              time,
              racerId,
              locality
            } = row;

            const showGender = _.get(row, 'entryType', '').toLowerCase() !== 'team';
            const irpUrlBib = getResultsUrlForBib(eventId, eventCourseId, bib, masterId, entryId);
            const racerName =
              <span style={styles.mobile.name}>
                          {displayName}
                        </span>;

            const hasStatusDNForDQ = entryStatus === 'DNF' || entryStatus === 'DQ';
            const timeToShow = convertTime({timeInMillis: time.timeInMillis, timeUnit: 'h'}).value;
            const bibText = bib || '';
            const showSeparator = bib && (showGender || age || locality);
            const ageGenderLocationText = showGender ? `${verifyGender(age, gender, t)} ${age || ''}` : locality || '';
            let bracketRank = getBracketRank(result.bracketName, row);
            if (bracketRank === 999999) {
                bracketRank = '--';
            }

            const {
              tokenRacerId
            } = this.state
            const claimed = !!racerId;

            let actionOrTimeLabel = timeToShow
            if (isVirtual) {
              if (claimed && isLoggedIn() && racerId === tokenRacerId && timeToShow === '0:00') {
                actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>{this.props.t('Post Time')}</span>
              }
              else if (claimed && isLoggedIn() && racerId !== tokenRacerId && timeToShow === '0:00') {
                actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>--</span>
              }
              else if (!claimed && timeToShow === '0:00') {
                actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>--</span>
              }
              else if (!isLoggedIn() && timeToShow === '0:00') {
                actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>{this.props.t('Post Time')}</span>
              }
            }

            return (
              <div key={`result-row-${idx}`}>
                <Link
                  to={irpUrlBib}
                  style={styles.tableRow(true, !!racerId)}
                  key={idx}
                >
                  <div className='row mx-0' style={styles.mobile.userRow}>
                    <div className='col-sm-2 col-2' style={styles.mobile.rowIndex}>
                      {bracketRank}
                    </div>
                    <div className='col-sm-6 col-6 p-0'>
                      <div style={{paddingLeft: '6px'}}>
                                  <span>
                                    {racerName}
                                  </span> <br/>
                        <span style={styles.mobile.age}>
                                    {`${bibText}`}
                                  </span>
                        {
                          showSeparator ?
                            <span style={styles.mobile.separator}>
                                        {'|'}
                                      </span> : ''
                        }
                        <span style={styles.mobile.age}>
                                    {`${ageGenderLocationText}`}
                                  </span>
                      </div>
                    </div>
                    <div className='col-sm-3 col-2 p-0'>
                      <div style={styles.mobile.time}>
                        {
                          hasStatusDNForDQ
                            ? entryStatus
                            : actionOrTimeLabel 
                        }
                        {
                          <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}> 
                          {
                             isVirtual === true ? <VRBadgeLink props={this.props} hideToolTip={true}/> : null
                          }
                          </div>
                        }
                        {
                          racerId ?
                            <AthlinksClaimedFlame width='14px' height='14px' style={styles.claimFlame.mobile}/> : null
                        }
                      </div>
                    </div>
                    <div className='col-sm-1 col-1' style={styles.rowArrowColumn.mobile}>
                      <RowArrow />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })
        }
      </div>
    )
  }
}

export const EventResultsIntervalMobile =  withTranslation()(EventResultsIntervalMobileComponent);
