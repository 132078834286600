import { useGetIrpMediaQuery } from '../api/resultsApi'

export default function useIrpMedia({
  thirdPartyEntryId,
  thirdPartyEventId,
}: {
  thirdPartyEntryId: number
  thirdPartyEventId: number
}) {
  const {
    data,
    isError,
    isLoading,
  } = useGetIrpMediaQuery({
    thirdPartyEntryId,
    thirdPartyEventId
  }, {
    skip: !thirdPartyEntryId || !thirdPartyEventId
  })

  return {
    ...data,
    isError,
    isLoading,
  }
}
