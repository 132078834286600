import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import { compose } from 'redux';
import {findDOMNode} from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';

import {SearchField} from './Elements';
import {SearchResults} from '../../views/searchResults/SearchResults';
import {goToCategory} from '../../data/SearchNavigator';

const goToSearch = _.debounce(goToCategory, 1000)
/**
 * This component renders search field in animated header.
 */
export class HeaderSearchFieldComponent extends Component {

  static propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    style: PropTypes.object,
    isMobile: PropTypes.bool,
    onFocusChange: PropTypes.func,
    unifiedSearchMode: PropTypes.bool,
    onSearchFocus: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
    onFocusChange: () => {},
    onSearchFocus: () => {},
    unifiedSearchMode: false,
    style: {}
  };

  state = {
    searchFocused: false,
    dropdownFocused: false,
    resultsTopOffset: 0,
  };

  componentWillUnmount() {
    this.unmounted = true;
    this.setBlurState.cancel();
  }

  componentDidUpdate() {
    this.setResultsTopOffset();
  }

  componentDidMount() {
    this.setResultsTopOffset();
  }

  /**
   * Determines top offset for search results drop down
   * Set is set only for mobile devices and only if offset is changed
   * There shouldn't be more then one setState calling
   */
  setResultsTopOffset = () => {
    const {isMobile} = this.props;
    if(isMobile) {
      const {bottom} = findDOMNode(this).getBoundingClientRect();
      const newOffset = bottom - 1.5;
      const {resultsTopOffset} = this.state;
      if(resultsTopOffset !== newOffset) {
        this.setState({resultsTopOffset: newOffset});
      }
    }
  };

  /**
   * On escape hide drop down.
   * @param {*} e - Event
   */
  onKeyUp = (e) => {
    this.onSearchInputFocus();
    const {value, context, history, location} = this.props;
    goToSearch.cancel();
    if (e.key === 'Escape') {
      this.onDropdownBlur();
    }
    else if(e.key === 'Enter'){
      goToCategory(value, context, history);
    }
    else if (location.pathname.startsWith('/search/')) {
      goToSearch(value, context, history)
    }
  };

  /**
   * Check if search field or results drop down are focused.
   * @return {boolean}
   */
  areAnyFocused = () => {
    const {searchFocused, dropdownFocused} = this.state;
    return searchFocused || dropdownFocused;
  };

  /**
   * Callback for text input focus
   */
  onSearchInputFocus = () => {
    this.props.onSearchFocus(true);
    this.setBlurState({
      searchFocused: true,
      dropdownFocused: false,
    });
  }

  /**
   * Callback for text input blur
   */
  onSearchInputBlur = () => {
    this.props.onSearchFocus(false);
    this.setBlurState({
      searchFocused: false,
      dropdownFocused: false,
    });
  }

  /**
   * Callback on results dropdown focus
   * @TODO: I do not think we need this one. We do not use it
   */
  onDropdownFocus = () => this.setBlurState({
    searchFocused: true,
    dropdownFocused: true,
  });

  /**
   * Callback on results dropdown blur/
   * This one is passed as handleClickOutside prop for
   * react-onclickoutside component
   */
  onDropdownBlur = () => this.setBlurState({
    searchFocused: false,
    dropdownFocused: false,
  });

  /**
   * Helper function for setting focus/blur state.
   * Calls onFocusChange cb from props
   * @param newState
   */
  setBlurState = _.debounce((newState) => {
    if (this.unmounted) return;
    this.setState(newState, () => {
      const {onFocusChange} = this.props;
      onFocusChange(this.areAnyFocused());
    });
  }, 200);

  /**
   * Check if search results list should be shown.
   * List is not shown only on search pages
   * @return {boolean}
   */
  showResultsList = () => {
    const {unifiedSearchMode} = this.props;
    return unifiedSearchMode && this.areAnyFocused();
  };

  render() {
    const {
      isMobile,
      forceFocus,
      onChange,
      value,
      ...props
    } = this.props;
    const {
      searchFocused,
      resultsTopOffset
    } = this.state;
    const searchFieldProps = _.omit(props, [
      'onFocusChange',
      'unifiedSearchMode',
      'dispatch',
      'context',
      'onSearchFocus',
      'staticContext'
    ]);

    return (
      <div>
        <form action="#" onSubmit={(event) => event.preventDefault()}>
          <SearchField
            onKeyUp={this.onKeyUp}
            onFocus={this.onSearchInputFocus}
            onBlur={this.onSearchInputBlur}
            onChange={onChange}
            forceFocus={forceFocus}
            darkScheme={true}
            autoComplete={'off'}
            value={value}
            disableUnderline={true}
            iconTop={isMobile ? 9 : 16}
            {...searchFieldProps}
          />
          {
            this.showResultsList() &&
            <div
              style={{position: 'relative'}}>
              <FocusTrackingSearchResults
                top={resultsTopOffset}
                handleClickOutside={this.onDropdownBlur}
                hideSearchInputs={true}
                searchFocused={searchFocused}
                isMobile={isMobile}
              />
            </div>
          }
        </form>
      </div>
    );
  }

}

export const HeaderSearchField = compose(
  connect((state) => ({
    context: state.search.context
  })),
  withRouter,
)(HeaderSearchFieldComponent);

class FocusTrackingSearchResultsComponent extends Component {
  render() {
    const {isMobile, handleClickOutside, top} = this.props
    const searchInput = document.getElementsByClassName('cs-search-box')[0];
    const searchBoxWidth = searchInput.offsetWidth;

    return (
      <div
        style={{
          position: isMobile ? 'fixed' : 'absolute',
          left: 0,
          top: isMobile ? top : '100%',
          zIndex: 2,
          width: isMobile ? window.innerWidth : searchBoxWidth,
          minHeight: 400,
        }}>
        <SearchResults
          {...this.props}
          onUserChooseResult={handleClickOutside}
          type="home"
        />
      </div>
    )
  }
}

/**
 * Wrapper for rendering search results list.
 *
 * Note: It is bit tricky to render for mobile devices.
 * That is why we use window inner width since results list should have
 * maximum width. Top position may vary depending if smart app banner is shown.
 */
export const FocusTrackingSearchResults = onClickOutside(FocusTrackingSearchResultsComponent)
