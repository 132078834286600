import { makeStyles } from '@material-ui/core';

import {
  textStyles,
  loadingLight,
} from '../../shared/styles';

const title = {
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '0.15px',
  margin: 0,
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  loader: {
    height: '40px',
    marginBottom: '16px',
    pointerEvents: 'none',
    ...loadingLight,
  },
  rosterViewFiltersContainer: {
    display: 'flex',
    gap: '16px',
    flexDirection: 'column',

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: '32px',
    }
  },
  rosterResultsContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
  },
  pageViewTitle: {
    ...title,
    color: palette.grey[800],
    flex: 1,
  },
  pageViewContainer: {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '24px',

    [breakpoints.up('sm')]: {
      gap: '32px',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  },
  pageViewWrapper: {
    padding: '12px',

    [breakpoints.up('sm')]: {
      padding: '12px 24px 36px',
    }
  },
  searchResultTitle: {
    ...title,
    color: palette.grey[800],
    marginTop: '24px',
  },
  searchResultMessage: {
  	...textStyles.body1Grey,
  },
}));

export { useStyles };
